import { Button, Form, Modal, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';
import { IMoneyReceiptPost } from '../../../common/types/commonInterfaces';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import InvoiceMoneyReceipt from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceSubTotal from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import {
  IInvoiceOtherFormattedValueType,
  IOtherInvoiceFormValue,
} from '../../Invoice(Other)/Type/Invoce.other.interface';
import {
  useLazyGetSingleQuotationQuery,
  usePostQuotationConfirmationMutation,
} from '../Api/Endpoints/QuotationEndpoints';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';

type Props = { id: number; disabled: boolean };

export default function Confirm_quotation_modal({ id, disabled }: Props) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [singleQuotation, { data: quotationData, isLoading, isFetching }] =
    useLazyGetSingleQuotationQuery();

  const [
    posQTConfirmation,
    { isError, isSuccess, isLoading: postLoading, error, data },
  ] = usePostQuotationConfirmationMutation();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success(data?.message);
      // form.resetFields();
      handleCancel();
    }
  }, [isSuccess, isError]);

  // @set previus field values
  useEffect(() => {
    form.setFieldsValue({
      invoice_combclient_id: quotationData?.data?.client_id,
      invoice_no: quotationData?.data?.quotation_no,
      invoice_sales_date: dayjs(quotationData?.data?.quotation_date),

      billing_information: quotationData?.data?.billInfo.map((item) => ({
        billing_product_id: item.product_id,
        pax_name: undefined,
        billing_description: item.description,
        billing_quantity: item.quantity,
        billing_unit_price: item.unit_price,
      })),

      invoice_discount: quotationData?.data?.quotation_discount_total,
      invoice_note: quotationData?.data?.quotation_note,
    });
  }, [quotationData]);

  useEffect(() => {
    singleQuotation(id);
  }, [id]);

  const user = useSelector(selectUser);
  const onFinish = async (values: IOtherInvoiceFormValue) => {
    const money_receipt: IMoneyReceiptPost = {
      receipt_total_amount: values.receipt_total_amount,
      receipt_payment_type: values.receipt_payment_type,
      account_id: values.account_id,
      charge_amount: values.charge_amount,
      cheque_bank_name: values.cheque_bank_name,
      cheque_number: values.cheque_number,
      receipt_money_receipt_no: values.receipt_money_receipt_no,
      receipt_note: values.receipt_note,
      trans_no: values.trans_no,

      receipt_payment_date: values.receipt_payment_date
        ? dayjs(values.receipt_payment_date).format('YYYY-MM-DD')
        : undefined,
    };

    const billing_information = values.billing_information.map((item) => {
      return {
        billing_subtotal: item.billing_subtotal,
        billing_profit: item.billing_profit,
        billing_product_id: item.billing_product_id,
        pax_name: item.pax_name || undefined,
        billing_quantity: item.billing_quantity,
        billing_unit_price: item.billing_unit_price,
        billing_cost_price: item.billing_cost_price,
        billing_comvendor: item.billing_comvendor,
        billing_total_sales: item.billing_subtotal,
        billing_description: item?.description,
      };
    });

    const body: IInvoiceOtherFormattedValueType = {
      ...values,
      invoice_created_by: user?.user_id as number,
      money_receipt,
    };
    body.billing_information = billing_information;

    await posQTConfirmation({ body, quotation_id: id }).then((res: any) => {
      // if (res.data.data.invoice_id)
      //   navigate(`/invoiceother/details/${res.data.data.invoice_id}`, {
      //     state: location.pathname,
      //   });
    });
  };

  const onOkFinish = () => {
    form.submit();
  };

  const invoice_net_total = useWatch(['invoice_net_total'], form);

  return (
    <>
      <Button
        disabled={disabled}
        type='primary'
        size='small'
        onClick={showModal}
      >
        Confirm
      </Button>
      {open && (
        <Modal
          title='Quotation confirmation'
          open={open}
          onOk={onOkFinish}
          confirmLoading={postLoading}
          onCancel={handleCancel}
          width={1200}
          okText={'Confirm'}
          forceRender
        >
          <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
            style={{ marginTop: 40 }}
          >
            {isLoading || isFetching ? <LoadingIndicator /> : ''}
            <InvoiceHeaderForm
              form={form}
              invoice_net_totalFieldName={['invoice_net_total']}
              invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
              isQuotation
            />
            <InvoiceBillingInfo form={form} />

            <InvoiceSubTotal form={form} />
            <InvoiceMoneyReceipt
              form={form}
              netTotal={Number(invoice_net_total || 0)}
            />
          </Form>
        </Modal>
      )}
    </>
  );
}
