import { FormInstance } from 'antd/lib';
import SelectPayment from '../../../common/utils/SelectPayment';
import {
  DateInput,
  FormInput,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectEmployee } from '../../../components/common/FormItem/SelectCustomFeilds';
import { useWatch } from 'antd/es/form/Form';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useEffect, useState } from 'react';
import { employeeDataType } from '../../Configuration/employee/types/employeeTypes';

type Props = {
  form: FormInstance<any>;
  editMode?: true;
};

const PayrollAdvanceForm = ({ form, editMode }: Props) => {
  const available_balance = useWatch('available_balance', form);
  const [selectedEmployee, setSelectedEmployee] = useState<employeeDataType>();

  const tea_employee_id_edit = form.getFieldValue('tea_employee_id');

  useEffect(() => {
    if (selectedEmployee?.employee_salary)
      form.setFieldsValue({
        employee_salary: Fixed2(
          selectedEmployee?.employee_salary
        ).toLocaleString(),
      });
  }, [selectedEmployee]);

  return (
    <>
      <SelectEmployee
        required
        name={'tea_employee_id'}
        label='Select Employee:'
        placeholder='Select Employee'
        size={24}
        offDropDown
        setSelectedEmployee={setSelectedEmployee}
        editId={tea_employee_id_edit}
      />

      <FormInput
        name={'employee_salary'}
        label='Employee Salary'
        size={24}
        readonly
      />

      <SelectPayment
        form={form}
        pay_type_name='tea_pay_type'
        pay_acc_id_name='tea_acc_id'
        cheque_no_name='tea_cheque_no'
        cheque_bank_name='tea_bank_name'
        trans_charge_name='tea_trans_charge'
        trans_no_name='tea_trans_no'
        cheque_withdraw_date_name='tea_withdraw_date'
      />

      <NumberInput
        name={'tea_amount'}
        label='Advance Amount:'
        required
        size={24}
        rules={[
          {
            validator(rule, value) {
              if (Fixed2(value) > Fixed2(available_balance)) {
                return Promise.reject(
                  'Amount must be lower than available balance'
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      />

      <DateInput
        name={'tea_month'}
        label='Advance Month'
        size={24}
        required
        month
      />
      <DateInput name={'tea_date'} label='Payment Date' size={24} required />

      <TextAreaInput name={'tea_note'} label='Note: ' size={24} />
    </>
  );
};

export default PayrollAdvanceForm;
