import { Rule } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib/form";
import { Dispatch, SetStateAction } from "react";
import { ISelectClientDetails } from "../../modules/Invoice_Air_Ticket/types/invoiceAirTicketTypes";

export type ISetState<T> = Dispatch<SetStateAction<T>>;

export type perProps = {
  permission?: IPermission;
  isAdmin?: string[] | undefined;
};
export interface HTTPResponse<T> {
  success: boolean;
  count?: number;
  data?: T;
  message?: string;
}

export interface ErrorResponse {
  status?: number;
  data?: {
    message?: string;
    success?: boolean;
    type?: string;
  };
}

export interface voidType {
  invoice_has_deleted_by: number | undefined;
  void_charge: number;
  id: number;
}

export type EdataType = {
  message: string;
  status: number;
  success: boolean;
  type: string;
};

export interface IPermission {
  "create:any": ["*"];
  "update:any": ["*"];
  "read:any": ["*"];
  "delete:any": ["*"];
}

export interface ISelectData {
  title: string;
  id: number | string;
  color?: string;
}

// SELECT NUMBER RANGE
type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

export type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export enum crud {
  create = "create:any",
  read = "read:any",
  update = "update:any",
  delete = "delete:any",
}

export type getInvoiceType =
  | "AIT"
  | "ANC"
  | "ARI"
  | "ITP"
  | "IO"
  | "IV"
  | "IHP"
  | "IH"
  | "IU"
  | "MR"
  | "VPY"
  | "QT"
  | "EXP"
  | "ATR"
  | "OTR"
  | "TPR";

export type commonProps = {
  onSelect?: (e: any) => void;
  offDropDown?: boolean;
  setCombId?: React.Dispatch<React.SetStateAction<string>>;
  showAll?: boolean;
  name?: NamePath | undefined;
  label?: string;
  disabled?: boolean;
  initialValue?: string;
  defaultValue?: number | string;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  placeholder?: string;
  required?: boolean;
  size?: number;
  smSize?: number;
  xlSize?: number;
  mdSize?: number;
  xsSize?: number;
  xxl?: number;
  small?: boolean;
  rules?: Rule[];
  searchValue?: string;
  dependencies?: NamePath[];
  mode?: "multiple" | "tags" | undefined;
  setInstanceClientSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setInstanceSalesEmpSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  loading?: boolean;
  setProductId?: React.Dispatch<React.SetStateAction<number | undefined>>;

  // WHEN AUTO FILLUP SOME FIELDS GIVE from AS PROPS
  form?: FormInstance<any>;
  autoFillup?: boolean;
  allowClear?: boolean;
  onClear?: () => void;
  setClientDetails?: React.Dispatch<
    React.SetStateAction<ISelectClientDetails | undefined>
  >;
  refItem?: React.LegacyRef<any>;
};

export type rolePermissionType = {
  dashboard?: IPermission;
  invoice_airticket?: IPermission;
  invoice_non_commission?: IPermission;
  invoice_reissue?: IPermission;
  invoice_other?: IPermission;
  invoice_visa?: IPermission;
  invoice_tour_package?: IPermission;
  invoice_hajj_pre_reg?: IPermission;
  invoice_hajj?: IPermission;
  invoice_ummrah?: IPermission;
  hajji_management?: IPermission;
  hajji_management_client_to_client?: IPermission;
  hajji_management_group_to_group?: IPermission;
  hajji_management_transfer_inout?: IPermission;
  hajji_management_cancel_pre_reg?: IPermission;
  refund_module?: IPermission;
  refund_airticket?: IPermission;
  refund_other_invoice?: IPermission;
  refund_tour_package?: IPermission;
  money_receipt?: IPermission;
  money_receipt_advr?: IPermission;
  accounts_module?: IPermission;
  account_opening_balance?: IPermission;
  account_balance_transfer?: IPermission;
  account_non_invoice_income?: IPermission;
  account_investments?: IPermission;
  account_bill_adjustment?: IPermission;
  cheque_management?: IPermission;
  payroll?: IPermission;
  expense?: IPermission;
  loan_management_module?: IPermission;
  loan_management_authority?: IPermission;
  loan_management_loan?: IPermission;
  loan_management_payment?: IPermission;
  loan_management_receive?: IPermission;
  sms_system?: IPermission;
  clients?: IPermission;
  combined_clients?: IPermission;
  vendors?: IPermission;
  vendors_payment?: IPermission;
  vendor_advr?: IPermission;
  agents?: IPermission;
  quotation?: IPermission;
  passport_management?: IPermission;
  report_module?: IPermission;
  report_ledgers?: IPermission;
  report_total_due_advance?: IPermission;
  sales_report?: IPermission;
  profit_loss_report?: IPermission;
  expense_report?: IPermission;
  passport_report?: IPermission;
  passenger_list_report?: IPermission;
  vendor_wise_purchase_payment?: IPermission;
  client_discount?: IPermission;
  journey_date_wise_report?: IPermission;
  ait_report?: IPermission;
  loan_report?: IPermission;
  accounts_report?: IPermission;
  refund_report?: IPermission;
  preregistration_report?: IPermission;
  summary?: IPermission;
  country_wise_report?: IPermission;
  user_login_history?: IPermission;
  audit_trail?: IPermission;
  configuration_module?: IPermission;
  client_category?: IPermission;
  airports?: IPermission;
  products?: IPermission;
  visa_type?: IPermission;
  departments?: IPermission;
  room_types?: IPermission;
  transport_types?: IPermission;
  designations?: IPermission;
  employee?: IPermission;
  users?: IPermission;
  users_role?: IPermission;
  tour_itinerary?: IPermission;
  passport_status?: IPermission;
  groups?: IPermission;
  maharam?: IPermission;
  agency?: IPermission;
  airline?: IPermission;
  expense_head?: IPermission;
  companies?: IPermission;
  database?: IPermission;
  database_backup?: IPermission;
  database_reset?: IPermission;
  gds_report?: IPermission;
  database668?: IPermission;
};
