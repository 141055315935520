import { Card, Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../app/hooks';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
  FormInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectOptionsInput,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import {
  useGetInvoiceAirTicketTaxRefundInfoQuery,
  usePostInvoiceAirTicketTaxRefundInfoMutation,
} from '../../../Invoice_Air_Ticket/api/endpoints/airticketInvoiceEndpoints';
import {
  IInvoiceAirTaxRefundPost,
  IInvoiceAirticketTaxRefundInfo,
} from '../../../Invoice_Air_Ticket/types/invoiceAirTicketTypes';
import SelectPaymentTypeAndAccount from '../SelectPaymentTypeAndAccount';
import InvoiceAirTicketInputItems from './InvoiceAirTicketInputItems';

type Props = {
  form: FormInstance<any>;
  handleCancel: () => void;
  invoiceId: number;
  invoiceNo: string;
};

const InvoiceAirTicketModalContent = ({
  form,
  handleCancel,
  invoiceId,
  invoiceNo,
}: Props) => {
  const params = useParams();
  const user = useAppSelector((state) => state.user);

  const [clientAccountSize, setClientAccountSize] = useState(12);
  const [vendorAccountSize, setVendorAccountSize] = useState(12);

  const [postRefund, { isSuccess, isError }] =
    usePostInvoiceAirTicketTaxRefundInfoMutation();

  const { data, isSuccess: prevDataSuccess } =
    useGetInvoiceAirTicketTaxRefundInfoQuery(invoiceId);

  // GET DATA FROM APIs
  const ticketInfo = data?.data as IInvoiceAirticketTaxRefundInfo[];

  const formattedTicketInfo =
    ticketInfo?.length &&
    ticketInfo?.map((item) => {
      return {
        airticket_client_price: Fixed2(item.airticket_client_price),
        airticket_purchase_price: Fixed2(item.airticket_purchase_price),
        comb_vendor: item.comb_vendor,
        airticket_id: item.airticket_id,
        airticket_tax: item.airticket_tax,
      };
    });

  useEffect(() => {
    form.setFieldsValue({
      comb_client: ticketInfo?.length && ticketInfo[0]?.comb_client,
      airticket_client_price:
        ticketInfo?.length && Fixed2(ticketInfo[0].airticket_client_price),
      invoice_no: invoiceNo,
      client_refund_type: 'Adjust',
      vendor_refund_type: 'Adjust',
      refund_date: dayjs(),
    });

    form.setFieldValue('ticket_info', formattedTicketInfo);
  }, [prevDataSuccess]);

  const ticketInfos = useWatch('ticket_info', form);

  //   CLIENT AND VENDOR TOTAL REFUND AMOUNT
  useEffect(() => {
    if (ticketInfos) {
      form.setFieldsValue({
        vendor_total_tax_refund: ticketInfos?.reduce(
          (a: any, b: any) => a + Fixed2(b?.refund_tax_amount),
          0
        ),
      });
    }
  }, [ticketInfos]);

  const onFinish = async (values: SubmitType) => {
    const body: IInvoiceAirTaxRefundPost = {
      ...values,
      created_by: user?.user_id!,
      refund_invoice_id: params.id!,
      comb_client: values.comb_client,
      client_account_id: values.client_account_id,
      ticket_info: values.ticket_info.map((item) => ({
        airticket_id: item.airticket_id,
        comb_vendor: item.comb_vendor,
        refund_tax_amount: item.refund_tax_amount,
      })),
      client_pay_type: values.client_pay_type,
      client_refund_type: values.client_refund_type,
      client_total_tax_refund: values.client_total_tax_refund,
      vendor_total_tax_refund: values.vendor_total_tax_refund,
      refund_date: values.refund_date,
      vendor_account_id: values.vendor_account_id,
      vendor_pay_type: values.vendor_pay_type,
      vendor_refund_type: values.vendor_refund_type,
    };

    await postRefund(body);
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (isSuccess) {
      message.success('Refund has been successful');
      form.resetFields();
      handleCancel();
    } else if (isError) {
      message.error('Something happening wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Card size='small' style={{ width: '100%' }}>
        <Row gutter={20}>
          <SelectClients
            label='Client'
            name={'comb_client'}
            disabled
            size={6}
          />
          <FormInput
            label='Client Price'
            name={'airticket_client_price'}
            readonly
            size={6}
          />

          <FormInput
            label='Invoice No.'
            name={'invoice_no'}
            readonly
            size={6}
          />

          <DateInput
            label='Refund Date.'
            name={'refund_date'}
            size={6}
            required
          />

          {/* Nest Form.List */}
          <Col lg={24}>
            <Form.Item>
              <Form.List name={['ticket_info']}>
                {(subFields, subOpt) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 12,
                    }}
                  >
                    {subFields?.map((subField, index) => (
                      <InvoiceAirTicketInputItems
                        key={index}
                        subField={subField}
                        subOpt={subOpt}
                        form={form}
                      />
                    ))}
                  </div>
                )}
              </Form.List>
            </Form.Item>
          </Col>

          {/* VENDOR REFUND INFORMATION */}
          <FormInput
            required
            readonly
            name={'vendor_total_tax_refund'}
            label='Vendor Total Tax Refund'
            size={vendorAccountSize}
          />
          <SelectOptionsInput
            size={vendorAccountSize}
            options={['Adjust', 'Return']}
            label='Vendor Refund Type'
            name={'vendor_refund_type'}
            onSelect={(value) =>
              setVendorAccountSize(value === 'Adjust' ? 12 : 6)
            }
          />
          {vendorAccountSize === 6 && (
            <>
              <SelectPaymentTypeAndAccount
                size={vendorAccountSize}
                form={form}
                payMethodName='vendor_pay_type'
                accountName='vendor_account_id'
              />
            </>
          )}

          {/* CLIENT REFUND INFORMATION */}
          <NumberInput
            required
            label='Client Total Tax Refund'
            name={'client_total_tax_refund'}
            size={clientAccountSize}
          />
          <SelectOptionsInput
            size={clientAccountSize}
            options={['Adjust', 'Return']}
            label='Client Refund Type'
            name={'client_refund_type'}
            onSelect={(value) =>
              setClientAccountSize(value === 'Adjust' ? 12 : 6)
            }
          />
          {clientAccountSize === 6 && (
            <>
              <SelectPaymentTypeAndAccount
                size={clientAccountSize}
                form={form}
                payMethodName='client_pay_type'
                accountName='client_account_id'
              />
            </>
          )}
        </Row>
        <FormButton label='Submit' />
      </Card>
    </Form>
  );
};

export default InvoiceAirTicketModalContent;

export interface SubmitType {
  comb_client: string;
  airticket_client_price: number;
  invoice_no: string;
  refund_date: string;
  ticket_info: TicketInfo[];
  client_total_tax_refund: number;
  client_refund_type: string;
  vendor_total_tax_refund: number;
  vendor_refund_type: string;
  client_pay_type: number;
  vendor_pay_type: number;
  client_account_id: number;
  vendor_account_id: number;
  created_by: number;
  refund_invoice_id: string;
}

export interface TicketInfo {
  airticket_client_price: number;
  airticket_purchase_price: number;
  comb_vendor: string;
  refund_tax_amount: number;
  airticket_id: number;
  airticket_tax: string;
}
