import { Col, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import React from 'react';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig } from '../type/appConfig_Type';

type props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};
const AutoMail = ({ configDetails }: props) => {
  const [editPost] = usePostEditAppConfigMutation();
  const defaultValue = configDetails?.tac_auto_email;

  const handleChange = (e: RadioChangeEvent) => {
    const body = {
      ...configDetails,
      tac_auto_email: e.target.value,
    };

    editPost(body);
  };
  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={4}>Auto Email:</Typography.Title>
          <Typography.Text type='secondary'>
            Useful for sending automated EMAIL to your users
          </Typography.Text>
        </Col>
        <Col>
          <Radio.Group onChange={handleChange} value={defaultValue}>
            <Space direction='vertical'>
              <Radio value={0}>Auto Email Off</Radio>
              <Radio value={1}>Auto Email On</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AutoMail;
