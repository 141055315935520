import Table, { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../layout/components/SearchInvoices';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { useGetVendorPaymentDetailsQuery } from '../api/endpoints/vendorPaymentEndpoints';
import { IVendorPaymentDetails } from '../types/vendor.interfaces';

type Props = {
  id: string | undefined;
};
const VendorPaymentViewDetails = (props: Props) => {
  const id = props.id;
  const [details, setDetails] = useState<IVendorPaymentDetails[]>();

  const fetchData = async () => {
    if (id) {
      try {
        const { data } = await useGetVendorPaymentDetailsQuery(Number(id)); // don't remove await

        if (data?.data) {
          setDetails(data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  fetchData();

  const vendorPaymentCostColumn: ColumnsType<IVendorPaymentDetails> = [
    { title: 'SL', render: (_, data, index) => <> {index + 1} </> },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      dataIndex: 'vendor_name',
    },
    {
      title: 'Vendor Mobile',
      key: 'vendor_mobile',
      dataIndex: 'vendor_mobile',
    },
    {
      title: 'Payment Amount',
      key: 'invendorpay_amount',
      dataIndex: 'invendorpay_amount',
    },
    {
      title: 'Invoice No',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      render: (_, data) => (
        <Link
          to={
            '../' +
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },
    {
      title: 'Total Purchase',
      key: 'invoice_total_vendor_price',
      dataIndex: 'invoice_total_vendor_price',
    },
  ];
  return (
    <div
      className='my-20'
      style={{ backgroundColor: '#FFFFFF', padding: '20px', minWidth: '100vh' }}
    >
      <FormHeaderTitle title='PAYMENT DETAILS' />
      <Table
        size='small'
        bordered
        rowKey={(e) => e.invendorpay_id}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={details}
        columns={vendorPaymentCostColumn}
        pagination={false}
        style={{ marginBottom: '20px' }}
      />
    </div>
  );
};

export default VendorPaymentViewDetails;
