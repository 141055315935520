import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import BloodSelect from '../components/BloodSelect';
import DepartmentSelect from '../components/DepartmentSelect';
import DesignationSelect from '../components/DesignationSelect';
import { useEditEmployeeMutation } from '../endpoints/employeeEndpoints';
import { employeeDataType } from '../types/employeeTypes';

interface IProps {
  info: employeeDataType;
  setNull: ISetState<employeeDataType | null>;
  isLoading?: boolean;
}
const EmployeeEditModal = ({
  info,
  setNull,
  isLoading: editDataIsLoading,
}: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  useEffect(() => {
    form.setFieldsValue({
      employee_card_id: info.employee_card_id,
      employee_full_name: info.employee_full_name,
      employee_email: info.employee_email,
      employee_mobile: info.employee_mobile,
      employee_birth_date: info.employee_birth_date
        ? dayjs(info.employee_birth_date)
        : undefined,
      employee_apppoint_date: info.employee_apppoint_date
        ? dayjs(info.employee_apppoint_date)
        : undefined,
      employee_joining_date: info.employee_joining_date
        ? dayjs(info.employee_joining_date)
        : undefined,
      employee_designation_id: info.designation_id,
      employee_bloodgroup_id: info.group_id ? info.group_id : undefined,
      employee_address: info.employee_address,
      employee_department_id: info.department_id,
      employee_status: info.employee_status,
      employee_salary: info.employee_salary,
      employee_commission: Number(info.employee_commission || 0),
    });
  }, [info]);
  const [editEmployee, { isError, isSuccess, isLoading }] =
    useEditEmployeeMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Employee Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async (values: employeeDataType) => {
    const body: employeeDataType = {
      ...values,
      employee_id: info.employee_id,
      employee_birth_date: dayjs(values.employee_birth_date).format(
        'YYYY-MM-DD'
      ),
      employee_apppoint_date: dayjs(values.employee_apppoint_date).format(
        'YYYY-MM-DD'
      ),
      employee_joining_date: dayjs(values.employee_joining_date).format(
        'YYYY-MM-DD'
      ),
    };
    await editEmployee(body);
    setNull(null);
    dispatch(setModal(false));
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

  return (
    <Form
      labelAlign='left'
      {...layout}
      onFinish={handleSubmit}
      form={form}
      validateMessages={validateMessages}
    >
      {(editDataIsLoading || isLoading) && <LoadingIndicator />}
      <Form.Item
        name='employee_card_id'
        label='ID Card No:'
        rules={[
          { required: true, message: 'Please input Employee Id!' },
          { max: 30, message: 'Maximum 30 characters' },
        ]}
      >
        <Input placeholder='Employee ID Card No' />
      </Form.Item>

      <Form.Item
        name='employee_full_name'
        label='Employee Name :'
        rules={[
          { required: true, message: 'Please input Employee Name!' },
          { max: 45, message: 'Maximum 45 characters' },
        ]}
      >
        <Input placeholder='Employee Name' />
      </Form.Item>
      <DepartmentSelect />
      <DesignationSelect />
      <BloodSelect />
      <Form.Item required label='Salary' name='employee_salary'>
        <InputNumber
          placeholder='Salary'
          style={{ width: '100%' }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        />
      </Form.Item>
      <Form.Item label='Commission' name='employee_commission'>
        <InputNumber
          placeholder='Commission'
          style={{ width: '100%' }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        />
      </Form.Item>
      <Form.Item
        name='employee_email'
        label='Email :'
        rules={[{ type: 'email', message: 'Please input valid email' }]}
      >
        <Input placeholder='Email' type='email' />
      </Form.Item>
      <Form.Item
        name='employee_mobile'
        label='Mobile :'
        rules={[
          { required: true, message: 'Please input Employee Mobile!' },
          { max: 20, message: 'Maximum 20 characters' },
        ]}
      >
        <Input placeholder='Mobile' />
      </Form.Item>
      <Form.Item name='employee_birth_date' label='Birth Date :'>
        <DatePicker
          format={'DD-MM-YYYY'}
          style={{ width: '100%' }}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item name='employee_apppoint_date' label='Appointment Date :'>
        <DatePicker
          format={'DD-MM-YYYY'}
          style={{ width: '100%' }}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item name='employee_joining_date' label='Joining Date :'>
        <DatePicker
          format={'DD-MM-YYYY'}
          style={{ width: '100%' }}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item name='employee_address' label='Address :'>
        <TextArea placeholder='Address' maxLength={55} />
      </Form.Item>
      <Form.Item
        name='employee_status'
        label='Status :'
        rules={[{ required: true, message: 'Please input status!' }]}
      >
        <Radio.Group>
          <Radio value={1}>Active</Radio>
          <Radio value={0}>Inactive</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmployeeEditModal;
