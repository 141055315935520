import { message } from 'antd';
import { api } from '../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../common/types/commonInterfaces';
import { HTTPResponse, voidType } from '../../../common/types/commonTypes';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../Reports/Api/constants';
import {
  IGetAllInvoiceTourDataType,
  IInvoiceTour,
  IInvoiceTourBilling,
  IInvoiceTourDeleteAndRestoreType,
  IInvoiceTourEditData,
  IInvoiceTourInvoices,
  IInvoiceTourPost,
  IInvoiceTourPostEditDataType,
  IInvoiceTourPostEndPoint,
  ITourSingleTypes,
  ITourViewActivityLogs,
} from '../Types/InvoiceTourTypes';
import { MONEY_RECEIPT_TAG } from '../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';

interface IGenerateInvoiceDataType {
  invoice_no: string;
}
export const GenerateInvoiceEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL TOURINVOICE
    getAllInvoiceTour: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/tour-package?${query ? query : ''}`,
      }),
      providesTags: () => [
        { type: 'INVOICETOUR' },
        'invoices',
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    getTourBillingInfo: build.query<
      HTTPResponse<{
        invoice_combclient_id: string;
        tourBilling: IInvoiceTourBilling[];
        tourTransports: boolean;
        tourFoods: boolean;
        tourAccms: boolean;
        tourOtherTrans: boolean;
        tourGuide: boolean;
        tourTicket: boolean;
      }>,
      // HTTPResponse<any>,
      number
    >({
      query: (id) => ({
        url: `tour-package/tour/billing/${id}`,
      }),
      providesTags: () => [{ type: 'INVOICETOUR' }],
    }),

    //CREATE TOURINVOICE
    createInvoiceTour: build.mutation<void, IInvoiceTourPostEndPoint>({
      query: (body) => ({
        url: `/tour-package`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [
        { type: 'INVOICETOUR' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //ADD COSTING
    addCosting: build.mutation<void, { body: IInvoiceTour; id: number }>({
      query: ({ body, id }) => ({
        url: `/tour-package/costing/add/${id}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [
        { type: 'INVOICETOUR' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //EDIT TOURINVOICE
    getInvoiceTourEditById: build.query<
      HTTPResponse<IInvoiceTourEditData>,
      string
    >({
      query: (tour_invoice_id) => ({
        url: `/tour-package/${tour_invoice_id}`,
      }),
      providesTags: () => [{ type: 'INVOICETOUR' }],
    }),
    //updatetour
    updateInvoiceTour: build.mutation<
      void,
      { body: IInvoiceTourPostEditDataType; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/tour-package/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => [
        { type: 'INVOICETOUR' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
    //deleteTour
    deleteInvoiceTour: build.mutation<void, IInvoiceTourDeleteAndRestoreType>({
      query: (body) => ({
        url: `/tour-package/${body.tour_invoice_id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [
        { type: 'INVOICETOUR' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //Get all trash
    getTrashedTour: build.query<HTTPResponse<IAllInvoices[]>, void>({
      query: () => ({
        url: `/tour-package?trash=true`,
      }),
      providesTags: () => [{ type: 'INVOICETOUR' }],
    }),
    //get activity logs
    getActivityLogsInvoiceTour: build.query<
      HTTPResponse<ITourViewActivityLogs[]>,
      number
    >({
      query: (id) => ({
        url: `tour-package/logs/${id}`,
      }),
      providesTags: () => [{ type: 'INVOICETOUR' }],
    }),
    //View TOURINVOICE
    viewInvoiceTourById: build.query<
      HTTPResponse<IViewInvoiceDetails>,
      number | string
    >({
      query: (tour_invoice_id) => ({
        url: `/tour-package/view/${tour_invoice_id}`,
      }),
      providesTags: () => [{ type: 'INVOICETOUR' }],
    }),

    // Void Invoice Visa
    voidTourInvoiceTicket: build.mutation<void, voidType>({
      query: (body) => ({
        url: `/tour-package/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'invoices',
        'INVOICETOUR',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  useLazyGetActivityLogsInvoiceTourQuery,
  useLazyGetTrashedTourQuery,
  useDeleteInvoiceTourMutation,
  useLazyGetInvoiceTourEditByIdQuery,
  useLazyViewInvoiceTourByIdQuery,
  useViewInvoiceTourByIdQuery,
  useGetAllInvoiceTourQuery,
  useLazyGetAllInvoiceTourQuery,
  useCreateInvoiceTourMutation,
  useUpdateInvoiceTourMutation,
  useGetTourBillingInfoQuery,
  useLazyGetTourBillingInfoQuery,
  useAddCostingMutation,
  useVoidTourInvoiceTicketMutation,
} = GenerateInvoiceEndpoints;
