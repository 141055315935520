import { ConfigProvider, theme } from 'antd';
import React from 'react';
import { IViewInvoiceDetails } from '../../../../common/types/commonInterfaces';
import ClientInfo from '../ClientInfo';
import { invoiceViewStyle } from './CommonViewInvoice';

type Props = {
  invoiceDetails?: IViewInvoiceDetails;
  viewInvoiceCostDetailsRef: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
};

const TabDetailsView = ({
  invoiceDetails,
  viewInvoiceCostDetailsRef,
  children,
}: Props) => {
  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            minHeight: '11.5in',
            width: '8.27in',
            background: '#fff',
            padding: '10px',
          }}
          ref={viewInvoiceCostDetailsRef}
        >
          <ClientInfo invoice_info={invoiceDetails as IViewInvoiceDetails} />

          {children}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TabDetailsView;
