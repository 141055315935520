import { Button, Col, Form, Popconfirm } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useCreateAirTicketInfoMutation } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import AirticketVendorRefund from '../../Components/Airticket/AirticketVendorRefund';
import {
  IAirTicketGetRefundForm,
  IAirTicketInfoByNo,
  IAirTicketRefundForm,
} from '../../RefundTypes/RefundTypes';
import AirticketClientRefund from './AirticketClientRefund';

type Props = {
  client_id: string;
  form: FormInstance<any>;
  clientRefundTotal?: number;
  clientRefundChargeTotal?: number;
  tableData?: IAirTicketInfoByNo[];
  refund_date?: string;
  setAirTicketIsVisible_vendorRefund?: (arg: boolean) => void;
  airTicketIsVisible_vendorRefund?: boolean;
  invoiceId: number | undefined;
};

const AirticketPreviewForm = ({
  client_id,
  form,
  clientRefundTotal,
  clientRefundChargeTotal,
  tableData,
  invoiceId,
  refund_date,
  setAirTicketIsVisible_vendorRefund,
  airTicketIsVisible_vendorRefund,
}: Props) => {
  const user = useSelector(selectUser);
  const [addAirTicket, { isError, isSuccess, isLoading }] =
    useCreateAirTicketInfoMutation();
  const navigate = useNavigate();

  function getProfit() {
    const { client_charge, vrefund_charge_amount }: any = tableData?.reduce(
      (acc, curr) => {
        return {
          client_charge: acc.client_charge + curr.client_charge,
          vrefund_charge_amount:
            acc.vrefund_charge_amount + curr.vrefund_charge_amount,
        };
      },
      { client_charge: 0, vrefund_charge_amount: 0 }
    );

    return client_charge - vrefund_charge_amount;
  }

  const onRefundFormFinish = async () => {
    const vouchar_no: string = 'AT_REF_' + dayjs().unix();
    const values: IAirTicketGetRefundForm = form.getFieldsValue();
    const body: IAirTicketRefundForm = {
      ...values,
      vouchar_no,
      comb_client: client_id as string,
      invoice_id: invoiceId || null,
      created_by: user?.user_id as number,
      date: dayjs(refund_date).format('YYYY-MM-DD'),
      client_refund_info: Object.assign({}, ...values.client_refund_info),
      profit: getProfit() || 0,
    };

    body.client_refund_info.advance_amount = Number(
      body.client_refund_info.advance_amount
    );
    if (body.client_refund_info.crefund_adjust) {
      body.client_refund_info.crefund_adjust = Number(
        body.client_refund_info.crefund_adjust
      );
    }
    body.client_refund_info.crefund_charge_amount = Number(
      body.client_refund_info.crefund_charge_amount
    );
    body.client_refund_info.crefund_return_amount = Number(
      body.client_refund_info.crefund_return_amount
    );
    body.client_refund_info.crefund_total_amount = Number(
      body.client_refund_info.crefund_total_amount
    );

    body.client_refund_info.crefund_date = dayjs(
      body.client_refund_info.crefund_date
    ).format('YYYY-MM-DD');
    body.client_refund_info.withdraw_date = dayjs(
      body.client_refund_info.withdraw_date
    ).format('YYYY-MM-DD');

    body.vendor_refund_info.map((item) => {
      item.advance_amount = Number(item.advance_amount);
      item.return_amount = Number(item.return_amount);
      item.vrefund_adjust = Number(item.vrefund_adjust);
      item.vrefund_charge_amount = Number(item.vrefund_charge_amount);
      item.vrefund_return_amount = Number(item.vrefund_return_amount);
      item.vrefund_total_amount = Number(item.vrefund_total_amount);
      item.vrefund_date = dayjs(item.vrefund_date).format('YYYY-MM-DD');
      item.withdraw_date = dayjs(item.withdraw_date).format('YYYY-MM-DD');
      item.airticket_ticket_no = item.airticket_ticket_no;
      item.invoice_category_id = item.invoice_category_id;
    });

    // delete unnecessary fields from body

    delete body.client_refund_info.airticketClientInitialValues;
    delete body.client_refund_info.advance_amount;

    body.vendor_refund_info.map((item) => {
      if (item.airticket_vendor_combine_id) {
        item.airticket_vendor_id = `combined-${item.airticket_vendor_combine_id}`;
      } else if (item.airticket_vendor_id) {
        item.airticket_vendor_id = `vendor-${item.airticket_vendor_id}`;
      }
      if (Number(item.vendor_last_balance) < 0) {
        item.vrefund_payment_type === 'ADJUST';
      }
      item.airticket_combvendor = item.airticket_vendor_id;

      delete item.airticket_vendor_combine_id;
      delete item.airline_name;
      delete item.airticket_airline_id;
      delete item.airticket_client_price;
      delete item.airticket_discount_total;
      delete item.airticket_profit;
      delete item.key;
      delete item.last_balance;
      delete item.return_amount;
      delete item.vendor_last_balance;
      delete item.vendor_name;
      delete item.passport_name;
      delete item.airticket_pnr;
      delete item.airticket_purchase_price;
      delete item.airticket_vendor_id;
    });

    await addAirTicket(body);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/refund/history/airticket`);
    }
  }, [isSuccess]);

  return (
    <>
      <Form form={form} layout='vertical'>
        <AirticketClientRefund
          form={form}
          airticketClientInitialValues={tableData}
          clientRefundTotal={clientRefundTotal}
          clientRefundChargeTotal={clientRefundChargeTotal}
        />
        <Button
          type='primary'
          onClick={() => {
            setAirTicketIsVisible_vendorRefund &&
              setAirTicketIsVisible_vendorRefund(true);
          }}
          style={{ marginBottom: '1rem', marginTop: '1rem' }}
          disabled={isLoading}
        >
          Check And Confirm To Vendor Payment
        </Button>

        {airTicketIsVisible_vendorRefund && (
          <>
            <FormHeaderTitle title='Vendor Refund Information:' />

            <AirticketVendorRefund
              form={form}
              airticketVendorInitialValues={tableData}
            />
            <Col span={6} xs={24} sm={24} md={24} lg={24}>
              <Popconfirm
                title='Sure to Confirm Refund?'
                onConfirm={() => {
                  onRefundFormFinish();
                }}
              >
                <Button
                  style={{ marginTop: '1rem' }}
                  type='primary'
                  htmlType='submit'
                  loading={isLoading}
                >
                  Confirm
                </Button>
              </Popconfirm>
            </Col>
          </>
        )}
      </Form>
    </>
  );
};

export default AirticketPreviewForm;
