import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IAirTicketInfo } from '../../../common/types/commonInterfaces';
import { Fixed2, pagination } from '../../../common/utils/common.utils';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';

type Props = { tickets?: IAirTicketInfo[] };

const AirTicketCostInfo = ({ tickets }: Props) => {
  const flightsColumn: ColumnsType<IAirTicketInfo> = [
    {
      title: 'Sl',

      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Ticket No.',

      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'Pax Name	',

      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'PNR',

      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Gross Fare',

      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Base Fare',
      dataIndex: 'airticket_base_fare',
      key: 'airticket_base_fare',

      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Tax',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',

      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'AIT',
      dataIndex: 'airticket_ait',
      key: 'airticket_ait',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'VAT',
      dataIndex: 'airticket_vat',
      key: 'airticket_vat',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },
    {
      title: 'Commission',
      dataIndex: 'airticket_commission_percent',
      key: 'airticket_commission_percent',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },

    {
      title: 'Net Commission',
      dataIndex: 'airticket_commission_percent_total',
      key: 'airticket_commission_percent_total',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Other Bonus',
      dataIndex: 'airticket_other_bonus_total',
      key: 'airticket_other_bonus_total',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Other Expenses',
      dataIndex: 'airticket_other_expense',
      key: 'airticket_other_expense',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Discount',
      dataIndex: 'airticket_discount_total',
      key: 'airticket_discount_total',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Extra Fees',
      dataIndex: 'airticket_extra_fee',
      key: 'airticket_extra_fee',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'Segment',
      dataIndex: 'airticket_segment',
      key: 'airticket_segment',
    },

    {
      title: 'BD',
      dataIndex: 'airticket_bd_charge',
      key: 'airticket_bd_charge',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'UT',
      dataIndex: 'airticket_ut_charge',
      key: 'airticket_ut_charge',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'ES',
      dataIndex: 'airticket_es_charge',
      key: 'airticket_es_charge',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },
    {
      title: 'XT',
      dataIndex: 'airticket_xt_charge',
      key: 'airticket_xt_charge',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
    },

    {
      title: 'Sold Price',
      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
      align: 'right',
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render(value, record, index) {
        return Fixed2(value || 0);
      },
      align: 'right',
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      render: (value) => (
        <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
          {Number(value || 0)}
        </span>
      ),
      align: 'right',
    },
  ];

  const getSoldPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Fixed2(b.airticket_gross_fare || 0), 0)
      : 0;
  };
  const getPurchasePrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Fixed2(b.airticket_purchase_price || 0), 0)
      : 0;
  };
  const getProfitPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Fixed2(b.airticket_profit || 0), 0)
      : 0;
  };

  return (
    <div className='mt-20'>
      <FormHeaderTitle title='COST INFORMATION' />
      <Table
        size='small'
        bordered
        rowKey={(e) => e.airticket_ticket_no}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={tickets}
        columns={flightsColumn.slice(0, Math.floor(flightsColumn.length / 2))}
        pagination={pagination(tickets?.length)}
        style={{ marginBottom: '20px' }}
      />
      <Table
        size='small'
        bordered
        rowKey={(e) => e.airticket_ticket_no}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={tickets}
        columns={[
          flightsColumn[0],
          ...flightsColumn.slice(Math.floor(flightsColumn.length / 2)),
        ]}
        summary={() => {
          const totalSold = getSoldPrice(tickets);
          const totalPurchese = getPurchasePrice(tickets);
          const totalProfit = getProfitPrice(tickets);
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={Math.floor(flightsColumn.length / 2) - 1}
                >
                  <Typography
                    style={{
                      textAlign: 'right',
                      marginRight: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    Total:
                  </Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography
                    style={{
                      textAlign: 'right',
                    }}
                  >{`${Fixed2(totalSold)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography
                    style={{
                      textAlign: 'right',
                    }}
                  >{`${Fixed2(totalPurchese)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Typography
                    style={{
                      textAlign: 'right',
                    }}
                  >{`${Fixed2(totalProfit)}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        pagination={false}
      />
    </div>
  );
};

export default AirTicketCostInfo;
