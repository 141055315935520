import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { IVendorPurchasePaymentReport } from '../types/VendorWisePurchase_PaymentTypes';

export const VendorWisePurchase_PaymentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getVendorWisePurchaseReportExcel: build.query<
      any,
      {
        formDate: string;
        todDate: string;
        id: string;
      }
    >({
      query: ({ formDate, todDate, id }) => ({
        url: `report/sales/vendorWisePurchasePaymentReport/${id}?from_date=${formDate}&to_date=${todDate}`,
        responseHandler: async (response) => {
          const filename = `vendor_wise_purchase_and_payment.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),

    getVendorWisePurchaseReport: build.query<
      HTTPResponse<IVendorPurchasePaymentReport>,
      {
        todDate: string;
        formDate: string;
        current: number;
        pageSize: number;
        vendor?: string;
      }
    >({
      query: ({ current, formDate, pageSize, todDate, vendor }) => {
        let url = `/report/vendors-purchase-payment/${vendor}?from_date=${formDate}&to_date=${todDate}&page=${current}&size=${pageSize}`;

        return {
          url,
          method: 'GET',
        };
      },

      providesTags: [{ type: 'invoice' }],
    }),
  }),
});

export const {
  useGetVendorWisePurchaseReportQuery,
  useLazyGetVendorWisePurchaseReportExcelQuery,
} = VendorWisePurchase_PaymentEndpoints;
