import { useAppSelector } from '../../../../app/hooks';

type Props = {};

const InvoiceAppNoteInfo = (props: Props) => {
  const appConfig = useAppSelector((state) => state.configSlice);

  const { tac_invoice_footer_note } = appConfig;

  return (
    <div
      style={{ marginTop: 5, color: 'black' }}
      dangerouslySetInnerHTML={{ __html: tac_invoice_footer_note as any }}
    />
  );
};

export default InvoiceAppNoteInfo;
