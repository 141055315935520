import { Form, Select, Col, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { useLazyGetAllCombBySearchQuery } from '../../Client/combined_clients/api/endpoints/clientCombineEndpoints';

type Props = {
  show_balance?: boolean;
  setBalance?: (arg: number) => void;
  form?: FormInstance<any>;
  NewBalance?: number;
};

function Select_Combine({ show_balance, setBalance, form }: Props) {
  const [fetchComb, { data: allCombine }] = useLazyGetAllCombBySearchQuery();

  let timeoutId: NodeJS.Timeout | null = null;
  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchComb(value);
    }, 300);
  };

  useEffect(() => {
    fetchComb('');
  }, []);

  return (
    <>
      <Col span={6} xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: `Combined is required!`,
            },
          ]}
          name='combine_id'
          label='Combined'
        >
          <Select
            onSearch={handleSearch}
            showSearch
            placeholder={`Select Combined`}
            allowClear
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {allCombine &&
              allCombine.data?.map((item: any, index: number) => {
                return (
                  <Select.Option value={item.combine_id} key={index}>
                    {item.combine_name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

export default Select_Combine;
