import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import MoneyReceiptForm from '../../Components/MoneyReceiptForm';

type Props = { reason: 'ADD_NEW' | 'EDIT' };
const New_Money_Receipt = ({ reason }: Props) => {
  return (
    <>
      <BreadCrumb arrOfOption={['Add Money Receipt']} />
      <MoneyReceiptForm reason={reason} />
    </>
  );
};

export default New_Money_Receipt;
