import React, { useState } from 'react';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { Col, Row, Select, Table } from 'antd';
import VisaProcessingColumn from '../utils/VisaProcessingColumn';
import { useGetAllVisaprocessQuery } from '../api/visaProcessingEndpoints';
import EditVisaAssign from '../components/EditVisaAssign';
import {
  SelectCountry,
  SelectEmployee,
} from '../../../components/common/FormItem/SelectCustomFeilds';

const VisaProcessing = () => {
  const [tvw_id, settvw_id] = useState<number>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [filterData, setFilterData] = useState<{
    country: string;
    status: string;
    employee: string;
  }>({
    country: '',
    status: '',
    employee: '',
  });
  const { data } = useGetAllVisaprocessQuery({
    country: filterData.country,
    employee: filterData.employee,
    status: filterData.status,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const handleSearch = (
    values: any,
    searchCategory?: 'country' | 'status' | 'employee'
  ) => {
    if (searchCategory === 'country') {
      if (values === undefined) {
        setFilterData({
          country: '',
          employee: filterData.employee,
          status: filterData.status,
        });
      } else {
        setFilterData({
          country: values,
          employee: filterData.employee,
          status: filterData.status,
        });
      }
    } else if (searchCategory === 'status') {
      if (values === undefined) {
        setFilterData({
          status: '',
          employee: filterData.employee,
          country: filterData.country,
        });
      } else {
        setFilterData({
          status: values,
          employee: filterData.employee,
          country: filterData.country,
        });
      }
    } else if (searchCategory === 'employee') {
      if (values === undefined) {
        setFilterData({
          employee: '',
          status: filterData.status,
          country: filterData.country,
        });
      } else {
        setFilterData({
          employee: values,
          status: filterData.status,
          country: filterData.country,
        });
      }
    }
  };
  return (
    <>
      <BreadCrumb arrOfOption={['Visa Processing']} />
      <>
        <Row gutter={10}>
          <SelectCountry
            required
            size={4}
            onChange={(e) => handleSearch(e, 'country')}
          />
          <Col lg={4}>
            <Select
              onChange={(e) => handleSearch(e, 'status')}
              placeholder={'Select Status'}
              showSearch
              allowClear
              style={{
                padding: '0',
                margin: '0',
                border: '0',
                width: '100%',
              }}
              options={[
                {
                  label: 'Pending',
                  value: 'PENDING',
                },
                {
                  label: 'Approved',
                  value: 'APPROVED',
                },
                {
                  label: 'Delivered',
                  value: 'DELIVERED',
                },
                {
                  label: 'Rejected',
                  value: 'REJECTED',
                },
              ]}
            />
          </Col>
          <SelectEmployee
            required
            size={4}
            offDropDown
            onChange={(e) => handleSearch(e, 'employee')}
          />
        </Row>

        <Table
          size='small'
          bordered
          rowKey={(e) => e.tvw_id}
          columns={VisaProcessingColumn({
            pagination,
            settvw_id,
          })}
          dataSource={data?.data}
          scroll={{ x: true }}
          pagination={
            data?.count !== undefined && data?.count < 20
              ? false
              : {
                  ...pagination,
                  total: data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
          }
        />
      </>
      <EditVisaAssign tvw_id={tvw_id} />
    </>
  );
};

export default VisaProcessing;
