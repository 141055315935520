import type { RadioChangeEvent } from 'antd';
import { Col, Radio, Row, Space, Typography } from 'antd';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';

type Props = {
  configDetails: IGetAppConfig | undefined;
};

const InvoiceCalculation = ({ configDetails }: Props) => {
  const [postEdit] = usePostEditAppConfigMutation();

  const defaultValue = configDetails?.tac_ait_cal;

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_ait_cal: e.target.value,
    };

    postEdit(body);
  };
  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={4}>
            {' '}
            AIT Tax Calculation Presets:
          </Typography.Title>
          <Typography.Text type='secondary'>
            Add extra input tax field while creating invoice
          </Typography.Text>
        </Col>
        <Col>
          <Radio.Group onChange={onChange} value={defaultValue}>
            <Space direction='vertical'>
              <Radio value={'1'}>Default</Radio>
              <Radio value={'TAX_1_EXTRA_FIELD'}> Extra Field (Tax 1)</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceCalculation;
