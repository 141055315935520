import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewHajjiInfo,
} from '../../../common/types/commonInterfaces';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { Fixed2, pagination } from '../../../common/utils/common.utils';
import getInvoiceViewPageController from '../../Invoice_Air_Ticket/utils/ts/getInvoiceViewPageController';
import { useEffect, useRef, useState } from 'react';
import { HeightRefsType } from '../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';

type Props = {
  billing_information: IViewBillingInfo[];
  haji_information: IViewHajjiInfo[];
  heightRefs: HeightRefsType;
};
const ViewInvoiceHajjPreRegBilling = ({
  billing_information,
  haji_information,
  heightRefs,
}: Props) => {
  const billingColumn: ColumnsType<IViewBillingInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: 'Pax Name',
      dataIndex: 'pax_name',
      key: 'pax_name',
      align: 'center',
    },

    ...(billing_information.some((item) => item.billing_description)
      ? ([
          {
            title: 'Description',
            dataIndex: 'billing_description',
            key: 'billing_description',
            align: 'center',
          },
        ] as ColumnsType<IViewBillingInfo>)
      : []),

    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
      align: 'right',
    },
    {
      title: 'Unit Price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      align: 'right',
    },
    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      align: 'right',
    },
  ];
  const hajiColoumn: ColumnsType<IViewHajjiInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Product Name',
      dataIndex: 'hajiinfo_name',
      key: 'hajiinfo_name',
      align: 'center',
    },
    {
      title: 'NID',
      dataIndex: 'hajiinfo_nid',
      key: 'hajiinfo_nid',
      align: 'right',
    },

    {
      title: 'Mobile',
      dataIndex: 'hajiinfo_mobile',
      key: 'hajiinfo_mobile',
      align: 'right',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
      align: 'right',
    },
    {
      title: 'Registration',
      dataIndex: 'haji_info_reg_year',
      key: 'haji_info_reg_year',
      align: 'right',
    },
  ];

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const haji_ref = useRef<HTMLDivElement>(null);
  const billing_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const haji_ref_height = Fixed2(haji_ref.current?.clientHeight) / 96; // value in inch
    const billing_ref_height = Fixed2(billing_ref.current?.clientHeight) / 96; // value in inch

    const header_height =
      Fixed2(heightRefs.header_ref.current?.clientHeight) / 96; // value in inch
    const signiture_height =
      Fixed2(heightRefs.signiture_ref.current?.clientHeight) / 96; // value in inch
    const subtotal_height =
      Fixed2(heightRefs.subtotal_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: header_height,
      footerHeight: subtotal_height + signiture_height,
      pageHeight: 11.7,
      items: [
        {
          height: haji_ref_height,
          identifier: 'haji_info',
        },
        {
          height: billing_ref_height,
          identifier: 'billing',
        },
        {
          height: subtotal_height + signiture_height,
          identifier: 'footer',
        },
      ],
    });

    setPageController(pageControl);
  }, [haji_information, billing_information]);

  return (
    <div>
      <div ref={haji_ref}>
        {haji_information.length ? (
          <>
            <FormHeaderTitle title='Haji Information' />
            <Table
              size='small'
              bordered
              rowKey={(e) => e.hajiinfo_serial}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={haji_information}
              columns={hajiColoumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        ref={billing_ref}
        style={{
          // billing margin control
          marginTop:
            pageController && pageController[1].identifier === 'billing'
              ? `${pageController[1].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[1].identifier === 'billing' &&
            pageController[1].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[1].identifier === 'billing' &&
            pageController[1].marginTop
              ? '0.5in'
              : '',
        }}
      >
        <FormHeaderTitle title='BILLING INFORMATION' />
        <Table
          size='small'
          bordered
          rowKey={(e) => e.pax_name}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          dataSource={billing_information}
          columns={billingColumn}
          pagination={false}
        />
      </div>

      <div
        style={{
          // footer margin control
          marginTop:
            pageController && pageController[2]?.identifier === 'footer'
              ? `${pageController[2].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[2].identifier === 'footer' &&
            pageController[2].marginTop
              ? '4px dashed lightgray'
              : '',
          paddingTop:
            pageController &&
            pageController[2].identifier === 'footer' &&
            pageController[2].marginTop
              ? '0.5in'
              : '',
        }}
      ></div>
    </div>
  );
};

export default ViewInvoiceHajjPreRegBilling;
