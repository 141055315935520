import { Button, Popconfirm, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import { IPermission, crud } from '../../../common/types/commonTypes';
import { Fixed2 } from '../../../common/utils/common.utils';
import {
  useDeleteQuotationMutation,
  useRestoreQuotationMutation,
} from '../Api/Endpoints/QuotationEndpoints';
import Confirm_quotation_modal from '../Components/Confirm_quotation_modal';
import { IQuotationDataType } from '../QuotationTypes/QuotationTypes';
import { getFirst100WordsFromHTML } from '../../../auth/utils/lib';

type Props = {};

export const QuotationUtils = (
  pagination: {
    current: number;
    pageSize: number;
  },
  permission?: IPermission
): ColumnsType<IQuotationDataType> => {
  const user = useAppSelector(selectUser);
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [deleteQuotation, { isLoading, isSuccess, isError }] =
    useDeleteQuotationMutation();
  const [restoreQuotation] = useRestoreQuotationMutation();

  const handleQuotationDelete = async (
    values: IQuotationDataType,
    index: number
  ) => {
    setColumnIndex(index);
    await deleteQuotation({
      quotation_id: values.quotation_id,
      quotation_deleted_by: user?.user_id as number,
    });
  };
  const handleQuotationRestore = async (values: IQuotationDataType) => {
    await restoreQuotation({
      quotation_id: values.quotation_id,
      quotation_deleted_by: user?.user_id as number,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'quotation_date',
      render: (_, record) => {
        return `${dayjs(record.quotation_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Quotation No',
      dataIndex: 'quotation_no',
      key: 'quotation_no',
    },
    {
      title: 'Client Name',
      key: 'client_id',
      dataIndex: 'client_name',
      render: (_, record) => {
        return (
          <Link
            to={
              record?.quotation_client_id
                ? `/clients/details/client-${record?.quotation_client_id}`
                : `/combineClients/details/${record?.quotation_combined_id}`
            }
          >
            <span>{record?.client_name}</span>
          </Link>
        );
      },
    },

    {
      title: 'Discount',
      dataIndex: 'quotation_discount_total',
      key: 'quotation_discount_total',
      align: 'right',
      render: (curr) => <span>{Fixed2(curr)}</span>,
    },
    {
      title: 'Net Total',
      dataIndex: 'quotation_net_total',
      key: 'quotation_net_total',
      align: 'right',
      render: (curr) => <span>{Fixed2(curr)}</span>,
    },

    {
      title: 'Note',
      dataIndex: 'quotation_note',
      key: 'quotation_note',
      width: 500,
      render: (curr) => <span>{getFirst100WordsFromHTML(curr, 250)}</span>,
    },

    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => (
        <Space size='small'>
          <Link
            to={`/quotation/details/${record.quotation_id}`}
            state={location.pathname}
          >
            <Button size='small' type='primary'>
              View
            </Button>
          </Link>

          <Confirm_quotation_modal
            disabled={
              permission?.[crud.update] && record?.quotation_is_confirm === 1
                ? true
                : false
            }
            id={record.quotation_id}
          />

          <Button
            disabled={
              permission?.[crud.update] && record?.quotation_is_confirm === 1
            }
            size='small'
            type='primary'
          >
            <Link
              to={`/quotation/edit/${record.quotation_id}`}
              state={location.pathname}
            >
              Edit
            </Link>
          </Button>
          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => handleQuotationDelete(record, index)}
          >
            <Button
              disabled={
                permission?.['delete:any'] && record.quotation_is_deleted === 1
              }
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
