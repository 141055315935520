import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  theme,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import { Fixed2 } from '../../../common/utils/common.utils';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { portraitPageStyle } from '../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import NumToWord from '../../../components/number_to_words/NumToWord';
import { useGetPayrollAdvanceDetailsQuery } from '../api/endpoints/AdvancePayrollEndpoints';

type Props = {};

const PayrollAdvanceView = (props: Props) => {
  const { Text } = Typography;
  const { id } = useParams();

  const printRef = React.useRef<HTMLDivElement>(null);

  const { isLoading, data } = useGetPayrollAdvanceDetailsQuery(
    { id: id! },
    { skip: !id }
  );

  const payrollData = data?.data;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: portraitPageStyle,
  });

  return (
    <>
      <BreadCrumb arrOfOption={['Payroll Advance View']} />
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Space style={{ marginBottom: '1rem' }}>
          <Link to='/payroll'>
            <Button type='primary'>
              <ArrowLeftOutlined />
              Return to Payroll List
            </Button>
          </Link>
          <Button type='primary' onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
        </Space>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
        >
          <div
            ref={printRef}
            style={{
              width: '8.27in',
              minHeight: '11.50in',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0px 1rem',
              paddingBottom: '0.5rem',
              borderRadius: '.3rem',
              position: 'relative',
            }}
          >
            <div style={{}}>
              <InvoiceHeader />

              <TitleCenter title='Payroll Advance' />

              {/* END HEADER */}
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    marginLeft: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text className='changeFont'>
                    <b>Employee Name:</b> {payrollData?.employee_full_name}
                  </Text>
                  <Text className='changeFont'>
                    <b>Designation:</b> {payrollData?.designation_name}
                  </Text>

                  <Text className='changeFont'>
                    <b> Phone No:</b> {payrollData?.employee_mobile}
                  </Text>

                  <Text className='changeFont'>
                    <b> Address:</b> {payrollData?.employee_address}
                  </Text>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text className='changeFont'>
                    <b>Payroll Advance Month.:</b>{' '}
                    {dayjs(payrollData?.tea_month).format('MMMM-YYYY')}
                  </Text>
                </div>
              </Row>
              {/* END EMPLOYEE DETAILS */}
              <div style={{ marginTop: '40px' }}>
                <Table
                  size='small'
                  bordered
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  style={{ backgroundColor: 'white' }}
                  pagination={false}
                  columns={[
                    {
                      title: 'Date',
                      dataIndex: 'tea_date',
                      align: 'center',
                      render: (curr) =>
                        curr ? dayjs(curr).format('DD-MM-YYYY') : 'N/A',
                    },
                    {
                      title: 'Account Name',
                      dataIndex: 'account_name',
                      align: 'center',
                    },

                    {
                      title: 'Advance Amount',
                      dataIndex: 'tea_amount',
                      key: 'tea_amount',
                      align: 'center',
                      render: (curr) => <>{Fixed2(curr)}</>,
                    },
                  ]}
                  dataSource={payrollData ? [payrollData] : []}
                />

                <Row style={{ margin: '30px' }} justify={'space-between'}>
                  <Col xxl={12}>
                    <Text
                      strong
                      style={{
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      {payrollData && (
                        <NumToWord number={Number(payrollData?.tea_amount)} />
                      )}
                    </Text>
                  </Col>
                  <Col xxl={12}>
                    <Row justify={'end'}>
                      <Text
                        strong
                        style={{
                          fontSize: '12px',
                          fontFamily: "'Source Sans Pro', sans-serif",
                        }}
                      >
                        Note: {payrollData?.tea_note}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            {/* END TABLE CONTENTS */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0px 30px 40px 30px',
              }}
            >
              <Text
                style={{
                  borderTop: '1px dashed black',
                  paddingTop: '5px',
                  fontSize: '12px',
                }}
              >
                Employee Signature
              </Text>

              <Text
                style={{
                  marginTop: '10px',
                  fontSize: '10px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]')}
              </Text>

              <Text
                style={{
                  borderTop: '1px dashed black',
                  paddingTop: '5px',
                  fontSize: '12px',
                }}
              >
                Authorized Signature
              </Text>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </>
  );
};

export default PayrollAdvanceView;
