import { Card, Checkbox, Col, Form, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import { SelectPassportStatus } from '../../../../components/common/FormItem/SelectCustomFeilds';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useChangePassportStatusMutation } from '../../Api/Endpoints/PassportStatusEndpoints';
import {
  IPassportDataType,
  IPassportStatusFormDataType,
} from '../../PassportTypes/PassportTypes';

interface IProps {
  info: IPassportDataType;
  setNull: ISetState<IPassportDataType | null>;
}

const PassportStatusModal = ({ info, setNull }: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [passportHolderStatus, setPassportHolderStatus] =
    useState<boolean>(false);
  const [clientHolderStatus, setClientHolderStatus] = useState<boolean>(false);

  const checkboxChangePassport = (e: any) => {
    setPassportHolderStatus(!passportHolderStatus);
  };
  const checkboxChangeClient = (e: any) => {
    setClientHolderStatus(!clientHolderStatus);
  };

  useEffect(() => {
    form.setFieldsValue({
      client_id: `${info.client_name} [${info.passport_client_id}]`,
      client_number: info?.client_mobile,
      passport_holder_number: info.passport_mobile_no,
    });
  }, [info]);

  const [changePassportStatus, { isError, isSuccess, isLoading }] =
    useChangePassportStatusMutation();

  const onFinish = async (values: IPassportStatusFormDataType) => {
    const body: IPassportStatusFormDataType = {
      ...values,
      passport_id: info.passport_id,
      status_create_date: dayjs(values.status_create_date).format('YYYY-MM-DD'),
      status_created_by: user?.user_id as number,
      sms_passport_holder:
        values.sms_passport_holder == false
          ? undefined
          : values.sms_passport_holder,
      sms_client: values.sms_client == false ? undefined : values.sms_client,
    };

    await changePassportStatus(body);
    setPassportHolderStatus(false);
    setClientHolderStatus(false);
  };
  if (isSuccess) {
    form.resetFields();
    dispatch(setModal(false));
    setNull(null);
  }
  // set form value
  useEffect(() => {
    form.setFieldsValue({
      status_create_date: dayjs(),
    });
  }, []);

  return (
    <Form layout='vertical' form={form} onFinish={onFinish}>
      {isLoading ? <LoadingIndicator /> : ''}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <SelectPassportStatus
          name={'status_pstatus_id'}
          label='Select Passport Status'
          required
          size={12}
        />

        {/* <DateInput name='status_create_date' label='Date' size={12} required /> */}
        <DateInput
          name={'status_create_date'}
          label='Date: '
          required
          size={12}
        />
      </Row>
      <Card title='Send SMS'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={6} xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label='SMS (Passport Holder)'
              name='sms_passport_holder'
              valuePropName='checked'
            >
              <Checkbox
                checked={passportHolderStatus}
                onChange={checkboxChangePassport}
              />
            </Form.Item>
          </Col>
          {passportHolderStatus && (
            <>
              <FormInputItem
                label='Mobile Number'
                name='passport_holder_number'
                size={12}
                disabled
              />

              <FormInputItem label='SMS' name='status_sms_content' size={12} />
            </>
          )}
          <Col span={6} xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label='SMS (Client)'
              name='sms_client'
              valuePropName='checked'
            >
              <Checkbox
                checked={clientHolderStatus}
                onChange={checkboxChangeClient}
              />
            </Form.Item>
          </Col>
          {clientHolderStatus && (
            <>
              <FormInputItem
                label='Mobile Numbers'
                name='client_number'
                size={24}
                disabled
              />

              <FormInputItem
                label='Client'
                name='client_id'
                size={12}
                disabled
              />

              <FormInputItem
                label='SMS'
                name='status_sms_content_client'
                size={12}
              />
            </>
          )}
        </Row>
        <FormButton label='Change Status' loading={isLoading} />
      </Card>
    </Form>
  );
};

export default PassportStatusModal;
