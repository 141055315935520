import {
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Typography,
} from 'antd';
import styled from 'styled-components';

import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteHajjCancelRegMutation,
  useGetAllHajjCancelRegQuery,
} from '../../API/Endpoints/CancelHajjRegEndpoint';
import { IGetHajjCancelReg } from '../../Types/HajjiManagement.interface';

type Props = { permission?: IPermission };

const CancelHajjRegAll = ({ permission }: Props) => {
  const user = useSelector(selectUser);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const {
    data: cancelPreReg,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllHajjCancelRegQuery({ ...pagination });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const allCancelPreReg = cancelPreReg?.data;
  const [
    deleteCancelPreReg,
    { isError, isLoading: deleteLoading, isSuccess, error },
  ] = useDeleteHajjCancelRegMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Deleted Successfully!');
    }
    if (isError) {
      message.error('There was an error!');
    }
  }, [deleteLoading]);

  const columns: ColumnsType<IGetHajjCancelReg> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: 'Date',
      dataIndex: 'cl_created_dates',
      key: 'cl_created_dates',
      render: (cl_created_dates) =>
        dayjs(cl_created_dates).format('DD-MM-YYYY'),
    },
    {
      title: 'Office Charge',
      dataIndex: 'cl_office_charge',
      key: 'cl_office_charge',
    },
    {
      title: 'Govt Charge',
      dataIndex: 'cl_govt_charge',
      key: 'cl_govt_charge',
    },
    {
      title: 'Total Charge',
      dataIndex: 'cl_total_charge',
      key: 'cl_total_charge',
    },
    {
      title: 'Tracking No',
      dataIndex: 'tracking_no',
      key: 'tracking_no',
    },

    {
      title: 'Action',
      key: 'operation',
      width: 100,

      render: (_, data) => (
        <Popconfirm
          onConfirm={() =>
            deleteCancelPreReg({
              id: data?.cl_id,
              deleted_by: user?.user_id!,
            })
          }
          title='Sure to delete?'
          disabled={!permission?.['delete:any']}
        >
          {permission?.['delete:any'] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#e90800', border: 'none' }}
              loading={deleteLoading}
            >
              <Typography style={{ color: '#fff' }}>Delete</Typography>
            </Button>
          )}
        </Popconfirm>
      ),
    },
  ];
  return (
    <ViewInvoiceStyle>
      <BreadCrumb
        arrOfOption={['Hajji Pre Registration', 'List']}
        refetch={refetch}
        reloaderSize='small'
      />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col
          span={6}
          xs={24}
          sm={24}
          md={24}
          lg={19}
          style={{ marginBottom: '1rem' }}
        >
          {permission?.['create:any'] && (
            <Link
              to='/haji/transfer/cancel_hajj_registration'
              state={location.pathname}
            >
              <Button type='primary'>Create</Button>
            </Link>
          )}
        </Col>

        <Col span={6} xs={24} sm={24} md={24} lg={5}>
          <Input placeholder='🔍 Search' />
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowKey={(e) => e.cl_id}
        columns={columns}
        dataSource={allCancelPreReg}
        scroll={{ x: true }}
        pagination={
          cancelPreReg?.count !== undefined && cancelPreReg?.count < 20
            ? false
            : {
                ...pagination,
                total: cancelPreReg?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </ViewInvoiceStyle>
  );
};

export default CancelHajjRegAll;

const ViewInvoiceStyle = styled.div`
  .transfer-in-list {
    border: 1px solid lightgray;
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 10px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 7px !important;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
