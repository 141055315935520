import { FormInstance } from 'antd';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { AitCalculationProps } from '../../pages/NewInvoiceAirTicket';

// @SET AIRTICKET_AIT
export const setAit = (
  airticket_gross_fare: number,
  form: FormInstance<any>
) => {
  if (airticket_gross_fare) {
    const countryTaxes = getCountryTaxesSum({ form });
    const countryTaxes_ait = countryTaxes * 0.003;

    // @WHEN EXTRA FIELD TAX_1 THEN USE airticket_tax1, tax1_ait
    const airticket_tax1 = form.getFieldValue('airticket_tax1');
    const tax1_ait = Fixed2(airticket_tax1) * 0.003;

    form.setFieldsValue({
      airticket_ait: Math.round(
        Fixed2(
          Fixed2((Number(airticket_gross_fare) / 100) * 0.3, 4) -
            tax1_ait -
            countryTaxes_ait,
          4
        )
      ),
    });
  } else {
    form.setFieldsValue({
      airticket_ait: 0,
    });
  }
};

// @SET CLIENT PRICE
interface setClientPriceType {
  form: FormInstance<any>;
  handleCalculation: ({}: AitCalculationProps) => void;
  setDiscountPercentValue: (value: React.SetStateAction<number>) => void;
  isAitManual?: boolean;
}

// @SET CLIENT PRICE
export const setClientPrice = ({
  form,
  handleCalculation,
  setDiscountPercentValue,
  isAitManual,
}: setClientPriceType) => {
  const airticket_ait_from = form.getFieldValue('airticket_ait_from');
  const airticket_base_fare = Fixed2(form.getFieldValue('airticket_base_fare'));
  const airticket_gross_fare = Fixed2(
    form.getFieldValue('airticket_gross_fare')
  );
  const airticket_discount_total = Fixed2(
    form.getFieldValue('airticket_discount_total')
  );
  const airticket_discount_type = form.getFieldValue('airticket_discount_type');
  const airticket_extra_fee = Fixed2(form.getFieldValue('airticket_extra_fee'));

  if (airticket_extra_fee < 0) {
    form.setFieldsValue({
      airticket_extra_fee: undefined,
    });
    handleCalculation({ isAitManual: true, isTaxManual: true });
  } else {
    if (airticket_discount_type === 'amount') {
      if (airticket_discount_total < 0) {
        form.setFieldsValue({
          airticket_discount_total: '',
        });
        handleCalculation({ isAitManual: true, isTaxManual: true });
      } else {
        const airticket_client_price =
          airticket_ait_from === 'Client'
            ? airticket_gross_fare +
              airticket_extra_fee -
              airticket_discount_total +
              getAit({ form })
            : // + getCountryTaxesSum({ form })
              airticket_gross_fare +
              airticket_extra_fee -
              airticket_discount_total;
        // + getCountryTaxesSum({ form });
        form.setFieldsValue({
          airticket_client_price: Fixed2(airticket_client_price),
        });
      }
    } else if (airticket_discount_type === 'percentClientTotal') {
      if (airticket_discount_total > 100 || airticket_discount_total < 0) {
        form.setFieldsValue({
          airticket_discount_total: undefined,
        });
        handleCalculation({ isAitManual: true, isTaxManual: true });
      } else {
        const discountPrice =
          (airticket_gross_fare / 100) * airticket_discount_total;

        const airticket_client_price =
          airticket_ait_from === 'Client'
            ? airticket_gross_fare +
              airticket_extra_fee -
              discountPrice +
              getAit({ form })
            : // + getCountryTaxesSum({ form })
              airticket_gross_fare + airticket_extra_fee - discountPrice;
        // + getCountryTaxesSum({ form });

        form.setFieldsValue({
          airticket_client_price: Fixed2(airticket_client_price),
        });
        setDiscountPercentValue(discountPrice);
      }
    } else if (airticket_discount_type === 'percentBaseFareTotal') {
      if (airticket_discount_total > 100 || airticket_discount_total < 0) {
        form.setFieldsValue({
          airticket_discount_total: '',
        });
        handleCalculation({ isAitManual: true, isTaxManual: true });
      } else {
        const discountPrice =
          (airticket_base_fare / 100) * airticket_discount_total;
        const airticket_client_price =
          airticket_ait_from === 'Client'
            ? airticket_gross_fare +
              airticket_extra_fee -
              discountPrice +
              getAit({ form })
            : // + getCountryTaxesSum({ form })
              airticket_gross_fare + airticket_extra_fee - discountPrice;
        // + getCountryTaxesSum({ form });

        form.setFieldsValue({
          airticket_client_price: Fixed2(airticket_client_price),
        });
        setDiscountPercentValue(discountPrice);
      }
    }
  }
};

// @SET TAX
interface setTaxType {
  form: FormInstance<any>;
}
//  @SET AIRTICKET_TAX
export const setTax = ({ form }: setTaxType) => {
  const airticket_base_fare = Fixed2(form.getFieldValue('airticket_base_fare'));
  const airticket_gross_fare = Fixed2(
    form.getFieldValue('airticket_gross_fare')
  );
  const tax = Fixed2(airticket_gross_fare || 0) - Fixed2(airticket_base_fare);
  form.setFieldsValue({
    airticket_tax: Fixed2(tax),
  });
};

// @SET COMMISSION
interface setCommissionType {
  commissionType?: 'manual' | 'percentage';
  form: FormInstance<any>;
}

export const setCommission = ({ commissionType, form }: setCommissionType) => {
  const airticket_commission_percent_total = Fixed2(
    form.getFieldValue('airticket_commission_percent_total')
  );

  const airticket_commission_percent = Fixed2(
    form.getFieldValue('airticket_commission_percent')
  );
  const airticket_base_fare = Fixed2(form.getFieldValue('airticket_base_fare'));
  const airticket_ait_from = form.getFieldValue('airticket_ait_from');

  if (
    (airticket_commission_percent || airticket_commission_percent === 0) &&
    commissionType
  ) {
    const ait = airticket_ait_from !== 'Client' ? getAit({ form }) : 0;

    if (commissionType === 'manual') {
      form.setFieldsValue({
        airticket_net_commssion: Math.round(
          Fixed2(airticket_commission_percent_total - ait)
        ),
      });
    } else if (commissionType === 'percentage') {
      form.setFieldsValue({
        airticket_commission_percent_total: Fixed2(
          (airticket_base_fare / 100) * airticket_commission_percent
        ),
        airticket_net_commssion: Fixed2(
          (airticket_base_fare / 100) * airticket_commission_percent - ait
        ),
      });
    }
  }
};

// @SET PURCHASE PRICE
interface setPurchasePriceType {
  form: FormInstance<any>;
  handleCalculation: ({}: AitCalculationProps) => void;
  setDiscountOtherBonusValue: (value: React.SetStateAction<number>) => void;
  isAitManual?: boolean;
}
export const setPurchasePrice = ({
  form,
  handleCalculation,
  setDiscountOtherBonusValue,
  isAitManual,
}: setPurchasePriceType) => {
  const airticket_other_bonus_type = form.getFieldValue(
    'airticket_other_bonus_type'
  );

  const airticket_other_expense = Fixed2(
    form.getFieldValue('airticket_other_expense')
  );
  const commission = Fixed2(
    form.getFieldValue('airticket_commission_percent_total')
  );
  const airticket_other_bonus_total = Fixed2(
    form.getFieldValue('airticket_other_bonus_total')
  );
  const airticket_base_fare = Fixed2(form.getFieldValue('airticket_base_fare'));
  const airticket_tax = Fixed2(form.getFieldValue('airticket_tax'));
  const airticket_ait = Math.round(Fixed2(form.getFieldValue('airticket_ait')));

  if (airticket_other_bonus_type === 'amount') {
    const airticket_purchase_price =
      airticket_base_fare +
      airticket_tax +
      airticket_other_expense -
      commission -
      airticket_other_bonus_total +
      airticket_ait;

    form.setFieldsValue({
      airticket_purchase_price: Fixed2(airticket_purchase_price),
    });
  } else if (airticket_other_bonus_type === 'percentage') {
    if (airticket_other_bonus_total > 100 || airticket_other_bonus_total < 0) {
      form.setFieldsValue({
        airticket_other_bonus_total: '',
      });
      handleCalculation({ isAitManual: true, isTaxManual: true });
    } else {
      const discountCalc =
        ((airticket_base_fare - commission) / 100) *
        airticket_other_bonus_total;
      const airticket_purchase_price =
        airticket_base_fare +
        airticket_tax +
        airticket_other_expense +
        airticket_ait -
        commission -
        discountCalc;

      form.setFieldsValue({
        airticket_purchase_price: Fixed2(airticket_purchase_price),
      });
      setDiscountOtherBonusValue(discountCalc);
    }
  }
};

// @SET PROFIT
interface setProfitType {
  form: FormInstance<any>;
}
export const setProfit = ({ form }: setProfitType) => {
  const airticket_client_price =
    Fixed2(form.getFieldValue('airticket_client_price')) || 0;
  const airticket_purchase_price =
    Fixed2(form.getFieldValue('airticket_purchase_price')) || 0;
  const airticket_vat = Fixed2(form.getFieldValue('airticket_vat')) || 0;
  const airticket_ait_from = form.getFieldValue('airticket_ait_from');

  if (airticket_ait_from === 'Client') {
    form.setFieldsValue({
      airticket_profit: Fixed2(
        airticket_client_price - airticket_purchase_price - airticket_vat
        //- getCountryTaxesSum({ form })
      ),
    });
  } else {
    form.setFieldsValue({
      airticket_profit: Fixed2(
        airticket_client_price - airticket_purchase_price - airticket_vat
        //- getCountryTaxesSum({ form })
      ),
    });
  }
};

// @GET ALL AIT SUMMATION
export const getAit = ({ form }: { form: FormInstance<any> }) => {
  const airticket_ait = Fixed2(form.getFieldValue('airticket_ait'));
  const countryCharge = form.getFieldsValue([
    'airticket_bd_charge',
    'airticket_es_charge',
    'airticket_ut_charge',
    'airticket_xt_charge',
    'airticket_e5_charge',
    'airticket_g4_charge',
    'airticket_ow_charge',
    'airticket_p7_charge',
    'airticket_p8_charge',
    'airticket_pz_charge',
    'airticket_qa_charge',
    'airticket_r9_charge',
  ]);
  const bdCg = Fixed2(countryCharge?.airticket_bd_charge);
  const esCg = Fixed2(countryCharge?.airticket_es_charge);
  const utCg = Fixed2(countryCharge?.airticket_ut_charge);
  const xtCg = Fixed2(countryCharge?.airticket_xt_charge);

  const e5Cg = Fixed2(countryCharge?.airticket_e5_charge);
  const g4Cg = Fixed2(countryCharge?.airticket_g4_charge);
  const owCg = Fixed2(countryCharge?.airticket_ow_charge);
  const p7Cg = Fixed2(countryCharge?.airticket_p7_charge);
  const p8Cg = Fixed2(countryCharge?.airticket_p8_charge);
  const pzCg = Fixed2(countryCharge?.airticket_pz_charge);
  const qaCg = Fixed2(countryCharge?.airticket_qa_charge);
  const r9Cg = Fixed2(countryCharge?.airticket_r9_charge);

  const c_charge =
    bdCg +
    esCg +
    utCg +
    xtCg +
    e5Cg +
    g4Cg +
    owCg +
    p7Cg +
    p8Cg +
    pzCg +
    qaCg +
    r9Cg;

  // @ALL AIT SUM
  return Fixed2(c_charge * 0.003) + Fixed2(airticket_ait);
};

// @GET ALL COUNTRY TAXES SUMMATION
export const getCountryTaxesSum = ({ form }: { form: FormInstance<any> }) => {
  const countryCharge = form.getFieldsValue([
    'airticket_bd_charge',
    'airticket_es_charge',
    'airticket_ut_charge',
    'airticket_xt_charge',
    'airticket_e5_charge',
    'airticket_g4_charge',
    'airticket_ow_charge',
    'airticket_p7_charge',
    'airticket_p8_charge',
    'airticket_pz_charge',
    'airticket_qa_charge',
    'airticket_r9_charge',
  ]);
  const bdCg = Fixed2(countryCharge?.airticket_bd_charge);
  const esCg = Fixed2(countryCharge?.airticket_es_charge);
  const utCg = Fixed2(countryCharge?.airticket_ut_charge);
  const xtCg = Fixed2(countryCharge?.airticket_xt_charge);

  const e5Cg = Fixed2(countryCharge?.airticket_e5_charge);
  const g4Cg = Fixed2(countryCharge?.airticket_g4_charge);
  const owCg = Fixed2(countryCharge?.airticket_ow_charge);
  const p7Cg = Fixed2(countryCharge?.airticket_p7_charge);
  const p8Cg = Fixed2(countryCharge?.airticket_p8_charge);
  const pzCg = Fixed2(countryCharge?.airticket_pz_charge);
  const qaCg = Fixed2(countryCharge?.airticket_qa_charge);
  const r9Cg = Fixed2(countryCharge?.airticket_r9_charge);

  const c_charge =
    bdCg +
    esCg +
    utCg +
    xtCg +
    e5Cg +
    g4Cg +
    owCg +
    p7Cg +
    p8Cg +
    pzCg +
    qaCg +
    r9Cg;

  // @ALL AIT SUM
  return Fixed2(c_charge);
};
