import { Col, Form, Modal, Row, Select, message } from 'antd';
import React, { useEffect } from 'react';
import {
  SelectCountry,
  SelectEmployee,
  SelectPassport,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import {
  DateInput,
  FormButton,
  FormInputItem,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { useAppDispatch } from '../../../app/hooks';
import { setModal } from '../../../common/slices/commonSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { IVisaAssignType } from '../types/visa_processingType';
import {
  useEditVisaprocessMutation,
  useGetViewVisaprocessQuery,
} from '../api/visaProcessingEndpoints';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import { removeUndefinedAndNull } from '../../../common/utils/removeUndefinedAndNull';
import FileUpload from '../../../common/utils/FileUpload';

type IProps = {
  tvw_id: number | undefined;
};
const EditVisaAssign = ({ tvw_id }: IProps) => {
  const [form] = useForm();
  const { data: editData } = useGetViewVisaprocessQuery(tvw_id as number, {
    skip: !tvw_id,
  });
  const dispatch = useAppDispatch();
  const modalSlice = useSelector((state: RootState) => state.commonSlice);
  const [editVisaProcess, { data, isSuccess, isLoading, isError }] =
    useEditVisaprocessMutation();
  const onFinish = (values: IVisaAssignType) => {
    const formdata = new FormData();
    const { doc, ...all } = values as IVisaAssignType;
    const dataFormate = removeUndefinedAndNull({
      ...all,
      tvw_invoice_id: editData?.data?.tvw_invoice_id,
      tvw_visa_deliver_date:
        values.tvw_visa_deliver_date &&
        dayjs(values.tvw_visa_deliver_date).format('YYYY-MM-DD'),
      tvw_visa_expire_date:
        values.tvw_visa_expire_date &&
        dayjs(values.tvw_visa_expire_date).format('YYYY-MM-DD'),
      tvw_remarks: values.tvw_remarks,
    });
    if (doc && doc.length) {
      formdata.append('doc', doc[0].originFileObj);
    }
    for (const key of Object.keys(dataFormate)) {
      formdata.append(
        key,
        String(dataFormate[key as keyof typeof dataFormate])
      );
    }
    editVisaProcess({
      id: editData?.data?.tvw_id as number,
      body: formdata as any,
    });
  };

  useEffect(() => {
    if (editData?.data) {
      form.setFieldsValue({
        ...editData.data,
        tvw_visa_deliver_date:
          editData?.data?.tvw_visa_deliver_date &&
          dayjs(editData?.data?.tvw_visa_deliver_date),
        tvw_visa_expire_date:
          editData?.data?.tvw_visa_expire_date &&
          dayjs(editData?.data?.tvw_visa_expire_date),
      });
    }
  }, [editData]);
  useEffect(() => {
    if (isSuccess) {
      message.success('Edit successfully Done');
      dispatch(setModal(false));
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Modal
        title='Edit Assign Employee'
        open={modalSlice.isModal}
        onCancel={() => dispatch(setModal(false))}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout='vertical'>
          <Row gutter={5}>
            <SelectEmployee
              name='tvw_assign_by'
              label='Select Employee'
              required
              size={12}
              offDropDown
            />
            <SelectPassport
              name={'tvw_passport_id'}
              label='Passport No'
              size={12}
              placeholder='select passport'
            />
            <FormInputItem
              name={'tvw_passport_quantity'}
              label='Passport Quantity'
              size={12}
              placeholder='passport quantity'
            />
            <SelectCountry label='Country' name={'tvw_country_id'} size={12} />
            <DateInput
              label='Visa Delivery Date'
              name='tvw_visa_deliver_date'
              size={12}
            />
            <DateInput
              label='Visa Expire Date'
              name='tvw_visa_expire_date'
              size={12}
            />

            <Col span={6} xs={24} sm={12} md={8} lg={12}>
              <Form.Item label='Status' name='tvw_status'>
                <Select
                  placeholder={'Select employee'}
                  showSearch
                  allowClear
                  style={{ padding: '0', margin: '0', border: '0' }}
                  options={[
                    {
                      label: 'Pending',
                      value: 'PENDING',
                    },
                    {
                      label: 'Approved',
                      value: 'APPROVED',
                    },
                    {
                      label: 'Delivered',
                      value: 'DELIVERED',
                    },
                    {
                      label: 'Rejected',
                      value: 'REJECTED',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <TextAreaInput
              row={1}
              size={12}
              label='Remarks'
              name='tvw_remarks'
            />
            <Col span={6} xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                label='Upload Docs'
                name={'doc'}
                valuePropName='payroll_image_url'
              >
                <FileUpload
                  size={8}
                  smSize={24}
                  mdSize={24}
                  name='doc'
                  msg='Doc image'
                  noBorder
                  imagURL={editData?.data?.tvw_doc}
                />
              </Form.Item>
            </Col>
          </Row>

          <FormButton textAlign='left' label='Submit' loading={isLoading} />
        </Form>
      </Modal>
    </>
  );
};

export default EditVisaAssign;
