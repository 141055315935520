import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { selectUser } from '../../../../auth/states/userSlice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useAddClientMutation } from '../api/endpoints/clientEndpoints';
import Client_Add_Edit from '../components/common/Client_Add_Edit';
import { IClientAddFormData } from '../types/clientInterfaces';

const CreateClient = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [form] = Form.useForm();

  const [addClient, { isError, isSuccess, isLoading }] = useAddClientMutation();

  const AddClientSubmitHandle = (values: IClientAddFormData) => {
    const body = {
      values,
      user_full_name: user?.user_full_name,
    };

    body.values.client_created_by = user?.user_id as number;
    addClient(body);
  };

  const path = location?.state || '/clients';
  useEffect(() => {
    if (isSuccess) {
      navigate(path);
    }
  }, [isLoading]);

  isLoading && <LoadingIndicator />;

  return (
    <ClientAddStyle>
      <BreadCrumb arrOfOption={['Add Client']} />

      <Col style={{ marginBottom: '1rem' }}>
        <Link to='/clients'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Client List
          </Button>
        </Link>
      </Col>

      <Form layout='vertical' form={form} onFinish={AddClientSubmitHandle}>
        <Client_Add_Edit form={form} />

        <Row justify={'end'}>
          <Button
            loading={isLoading}
            className='mt-5'
            type='primary'
            htmlType='submit'
          >
            Add Client Information
          </Button>
        </Row>
      </Form>
    </ClientAddStyle>
  );
};

export default CreateClient;

const ClientAddStyle = styled.div``;
