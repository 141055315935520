import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';
import { pagination } from '../../../common/utils/common.utils';
import { useSendSMSMutation } from '../../../modules/SMS System/Api/Endpoints/SMSEndpoints';
import { ISMSFormData } from '../../../modules/SMS System/SMSTypes/SMSTypes';
import BreadCrumb from '../../common/breadCrumb/BreadCrumb';
import { useGetAllExpiresPassportNotificationsQuery } from '../Api/NotificationEndpoints';
import SendSmsButton from '../Components/SendSmsButton';
import { IPassportExpire } from '../Interfaces/Notification.interface';
import { loadingIndicator } from '../../common/spinner/LoadingIndicator';

const ExpirePassports = () => {
  const user = useSelector(selectUser);
  const { data: expireNotification, isLoading } =
    useGetAllExpiresPassportNotificationsQuery();
  const [sendSMS, { isError }] = useSendSMSMutation();
  const [isButton, setIsButton] = React.useState<boolean>(false);

  const handleOnSubmit = async (values: ISMSFormData) => {
    const body: ISMSFormData[] = [
      {
        client_mobile: values.client_mobile,
        client_id: values.client_id,
        text_type: 'TEXT',
        client_category_id: values.client_category_id,
        message: values.message,
        date: dayjs().format('YYYY-MM-DD'),
        created_by: user?.user_id as number,
      },
    ];

    await sendSMS(body);
  };

  const column: ColumnsType<IPassportExpire> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Passport No.',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Birth Date',
      key: 'passport_date_of_birth',
      render: (_, data) => (
        <> {dayjs(data.passport_date_of_birth).format('DD MMM YYYY')} </>
      ),
    },
    {
      title: 'Issue Date',
      key: 'passport_date_of_issue',
      render: (_, data) => (
        <> {dayjs(data.passport_date_of_issue).format('DD MMM YYYY')} </>
      ),
    },
    {
      title: 'Expire Date',
      key: 'passport_date_of_expire',
      render: (_, data) => (
        <> {dayjs(data.passport_date_of_expire).format('DD MMM YYYY')} </>
      ),
    },
    {
      title: 'Created Date',
      key: 'passport_create_date',
      render: (_, data) => (
        <> {dayjs(data.passport_create_date).format('DD MMM YYYY')} </>
      ),
    },

    {
      title: 'Action',
      dataIndex: 'collected_by',
      key: 'collected_by',

      render: (_, data) => {
        return <SendSmsButton data={data} handleOnSubmit={handleOnSubmit} />;
      },
    },
  ];
  return (
    <div>
      <BreadCrumb arrOfOption={['Expire passport']} />

      <Table
        size='small'
        bordered
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        dataSource={expireNotification?.data?.passports}
        columns={column}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={pagination(expireNotification?.data?.passports?.length)}
      />
    </div>
  );
};

export default ExpirePassports;
