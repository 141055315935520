import { AccessControl } from 'accesscontrol';
import { Button, Col, Form, Row, Select, Tree, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../app/hooks';
import { moduleType, selectUser } from '../../../../auth/states/userSlice';
import { FormInput } from '../../../../components/common/FormItem/FormItems';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
import {
  useAddRoleMutation,
  useLazyRoleNameIsUniqueQuery,
} from '../endpoints/userEndpoints';
import { RoleResources } from '../types/role.enums';
import { TreeData } from '../types/user.types';
import { actions } from '../utils/constants';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
};

const AddRole = ({ setOpen, form }: Props) => {
  const [keys, setKeys] = useState<string[]>([]);
  const [addRole, { isLoading, isSuccess, isError }] = useAddRoleMutation();
  const user = useAppSelector(selectUser);
  const getTreeData = (): DataNode[] => {
    //module wise permissions
    let modules = user?.modules;

    const hasPermission = (module: moduleType) => {
      return modules?.includes(module);
    };
    // constant role
    const rolePermissions: TreeData[] = [
      // invoices
      hasPermission(RoleResources.invoice_airticket) && {
        title: 'Invoice (Air Ticket)',
        key: RoleResources.invoice_airticket,
      },

      hasPermission(RoleResources.invoice_non_commission) && {
        title: 'Invoice (Non commission)',
        key: RoleResources.invoice_non_commission,
      },
      hasPermission(RoleResources.invoice_reissue) && {
        title: 'Re Issue - Air Ticket',
        key: RoleResources.invoice_reissue,
      },
      hasPermission(RoleResources.invoice_other) && {
        title: 'Invoice (Other)',
        key: RoleResources.invoice_other,
      },
      hasPermission(RoleResources.invoice_visa) && {
        title: 'Invoice (Visa)',
        key: RoleResources.invoice_visa,
      },
      hasPermission(RoleResources.invoice_tour_package) && {
        title: 'Tour package',
        key: RoleResources.invoice_tour_package,
      },
      hasPermission(RoleResources.invoice_hajj_pre_reg) && {
        title: 'Invoice hajj pre registration',
        key: RoleResources.invoice_hajj_pre_reg,
      },
      hasPermission(RoleResources.invoice_hajj) && {
        title: 'Invoice hajj ',
        key: RoleResources.invoice_hajj,
      },
      hasPermission(RoleResources.invoice_hajj) && {
        title: 'Invoice ummrah',
        key: RoleResources.invoice_ummrah,
      },

      // hajji management
      hasPermission(RoleResources.hajji_management) && {
        title: 'Hajji management module',
        key: 'hajjimanagement668@',
        children: [
          {
            title: 'Hajji management',
            key: RoleResources.hajji_management,
          },
          {
            title: 'Client to client trnsfr',
            key: RoleResources.hajji_management_client_to_client,
          },
          {
            title: 'Group to group trnsfr',
            key: RoleResources.hajji_management_group_to_group,
          },
          {
            title: 'Transfer in/out',
            key: RoleResources.hajji_management_transfer_inout,
          },
          {
            title: 'Cancel pre registration',
            key: RoleResources.hajji_management_cancel_pre_reg,
          },
        ],
      },

      // refund
      hasPermission(RoleResources.refund_module) && {
        title: 'Refund module',
        key: 'refund668@',
        children: [
          {
            title: 'Refund',
            key: RoleResources.refund_module,
          },
          {
            title: 'Airticket refund',
            key: RoleResources.refund_airticket,
          },
          {
            title: 'Other refund',
            key: RoleResources.refund_other_invoice,
          },
          {
            title: 'Tour package refund',
            key: RoleResources.refund_tour_package,
          },
        ],
      },

      // money receipt
      hasPermission(RoleResources.money_receipt) && {
        title: 'Money receipt module',
        key: 'moneyreceipt668@',
        children: [
          {
            title: 'Money receipt',
            key: RoleResources.money_receipt,
          },
          {
            title: 'Money receipt advance return',
            key: RoleResources.money_receipt_advr,
          },
        ],
      },

      // accounts
      hasPermission(RoleResources.accounts_module) && {
        title: 'Accounts module',
        key: 'account668@',
        children: [
          {
            title: 'Accounts',
            key: RoleResources.accounts_module,
          },
          {
            title: 'Account opening balance',
            key: RoleResources.account_opening_balance,
          },
          // {
          //   title: 'Account opening balance',
          //   key: RoleResources.account_opening_balance,
          // },
          {
            title: 'Account balance transfer',
            key: RoleResources.account_balance_transfer,
          },
          {
            title: 'Account non invoice income',
            key: RoleResources.account_non_invoice_income,
          },
          {
            title: 'Account investments',
            key: RoleResources.account_investments,
          },
          {
            title: 'Account bill adjustment',
            key: RoleResources.account_bill_adjustment,
          },
        ],
      },

      hasPermission(RoleResources.cheque_management) && {
        title: 'Cheque management',
        key: RoleResources.cheque_management,
      },
      hasPermission(RoleResources.payroll) && {
        title: 'Payroll',
        key: RoleResources.payroll,
      },
      hasPermission(RoleResources.expense) && {
        title: 'Expense',
        key: RoleResources.expense,
      },

      // loan module
      hasPermission(RoleResources.loan_management_module) && {
        title: 'Loan management module',
        key: 'loanmsgt668@',
        children: [
          {
            title: 'Loan management',
            key: RoleResources.loan_management_module,
          },
          {
            title: 'Loan management authority',
            key: RoleResources.loan_management_authority,
          },
          {
            title: 'Loan management loan',
            key: RoleResources.loan_management_loan,
          },
          {
            title: 'Loan management payment',
            key: RoleResources.loan_management_payment,
          },
          {
            title: 'Loan management receive',
            key: RoleResources.loan_management_receive,
          },
        ],
      },

      hasPermission(RoleResources.sms_system) && {
        title: 'SMS system',
        key: RoleResources.sms_system,
      },
      hasPermission(RoleResources.clients) && {
        title: 'Clients',
        key: RoleResources.clients,
      },
      hasPermission(RoleResources.combined_clients) && {
        title: 'Combined clients',
        key: RoleResources.combined_clients,
      },
      // vendors
      hasPermission(RoleResources.vendors) && {
        title: 'Vendors module',
        key: 'vendor668@',
        children: [
          {
            title: 'Vendors',
            key: RoleResources.vendors,
          },
          {
            title: 'Vendors payment',
            key: RoleResources.vendors_payment,
          },
          {
            title: 'Vendors advr',
            key: RoleResources.vendor_advr,
          },
        ],
      },

      hasPermission(RoleResources.agents) && {
        title: 'Agents',
        key: RoleResources.agents,
      },
      hasPermission(RoleResources.quotation) && {
        title: 'Quotation',
        key: RoleResources.quotation,
      },
      hasPermission(RoleResources.passport_management) && {
        title: 'Passport management',
        key: RoleResources.passport_management,
      },

      // RECRUITMENTS
      {
        title: 'Recruitments',
        key: 'rec668@',
        children: [
          {
            title: 'Invoices',
            key: RoleResources.r_invoices,
          },
          {
            title: 'Clients',
            key: RoleResources.r_client,
          },
          {
            title: 'Data entry',
            key: RoleResources.r_data_entry,
          },
          {
            title: 'Delegate',
            key: RoleResources.r_delegate,
          },
          {
            title: 'Money receipt',
            key: RoleResources.r_money_receipt,
          },
          {
            title: 'Sponsor',
            key: RoleResources.r_sponsor,
          },
          {
            title: 'Visa form',
            key: RoleResources.r_visa_form,
          },
        ],
      },

      // report module
      {
        title: 'Report module',
        key: 'reports668',
        children: [
          {
            title: 'Reports',
            key: RoleResources.report_module,
          },
          {
            title: 'Report ledgers',
            key: RoleResources.report_ledgers,
          },
          {
            title: 'Report total due advance',
            key: RoleResources.report_total_due_advance,
          },
          {
            title: 'Sales report',
            key: RoleResources.sales_report,
          },
          {
            title: 'Profit loss report',
            key: RoleResources.profit_loss_report,
          },
          {
            title: 'Expense report',
            key: RoleResources.expense_report,
          },
          {
            title: 'Passport report',
            key: RoleResources.passport_report,
          },
          {
            title: 'Passenger list report',
            key: RoleResources.passenger_list_report,
          },
          {
            title: 'Vendor wise purchase payment',
            key: RoleResources.vendor_wise_purchase_payment,
          },
          {
            title: 'Client discount',
            key: RoleResources.client_discount,
          },
          {
            title: 'Journey date wise report',
            key: RoleResources.journey_date_wise_report,
          },
          {
            title: 'Ait report',
            key: RoleResources.ait_report,
          },
          {
            title: 'Loan report',
            key: RoleResources.loan_report,
          },
          {
            title: 'Online Transaction Charge Report',
            key: RoleResources.online_transaction_charge_report,
          },
          {
            title: 'Accounts report',
            key: RoleResources.accounts_report,
          },
          {
            title: 'Refund report',
            key: RoleResources.refund_report,
          },
          {
            title: 'Summary',
            key: RoleResources.summary,
          },
          {
            title: 'Country wise report',
            key: RoleResources.country_wise_report,
          },
          {
            title: 'Pre Registration report',
            key: RoleResources.preregistration_report,
          },
          {
            title: 'Trash list',
            key: RoleResources.trash_list,
          },
          {
            title: 'User login history',
            key: RoleResources.user_login_history,
          },
          {
            title: 'Audit trail',
            key: RoleResources.audit_trail,
          },
        ],
      },

      // configuration
      {
        title: 'Configuration module',
        key: 'configuration668',
        children: [
          // {
          //   title: 'Configuration',
          //   key: RoleResources.configuration_module,
          // },
          {
            title: 'Configurations',
            key: RoleResources.configuration_module,
          },
          {
            title: 'Client category',
            key: RoleResources.client_category,
          },
          {
            title: 'Airports',
            key: RoleResources.airports,
          },
          {
            title: 'Products',
            key: RoleResources.products,
          },
          {
            title: 'Visa type',
            key: RoleResources.visa_type,
          },
          {
            title: 'Departments',
            key: RoleResources.departments,
          },
          {
            title: 'Room types',
            key: RoleResources.room_types,
          },
          {
            title: 'Transport types',
            key: RoleResources.transport_types,
          },
          {
            title: 'Designations',
            key: RoleResources.designations,
          },
          {
            title: 'Employee',
            key: RoleResources.employee,
          },
          {
            title: 'Users',
            key: RoleResources.users,
          },
          {
            title: 'Users role',
            key: RoleResources.users_role,
          },
          {
            title: 'Tour itinerary',
            key: RoleResources.tour_itinerary,
          },
          {
            title: 'Passport status',
            key: RoleResources.passport_status,
          },
          {
            title: 'Gorups',
            key: RoleResources.groups,
          },
          {
            title: 'Maharams',
            key: RoleResources.maharam,
          },
          {
            title: 'Agency',
            key: RoleResources.agency,
          },
          {
            title: 'Airline',
            key: RoleResources.airline,
          },
          {
            title: 'Expense head',
            key: RoleResources.expense_head,
          },
          {
            title: 'Companies',
            key: RoleResources.companies,
          },
        ],
      },

      //database
      {
        title: 'Database module',
        key: 'database668',
        children: [
          {
            title: 'Database backup',
            key: RoleResources.database_backup,
          },
        ],
      },

      {
        title: 'Disable Edit Previous Billing',
        key: 'disableEdit668',
        children: [
          {
            title: 'Invoice Other (Billing Information)',
            key: RoleResources.invoice_other_billing,
          },

          {
            title: 'Money Receipt (Specific Invoice)',
            key: RoleResources.mony_receipt_specific_invoice,
          },

          {
            title: 'Money Receipt (Specific Ticket)',
            key: RoleResources.mony_receipt_specific_ticket,
          },

          {
            title: 'Vendor Payment (Specific Vendor)',
            key: RoleResources.vendor_payment_specific,
          },
        ],
      },
    ].filter(Boolean) as TreeData[];

    const data: DataNode[] = [];

    rolePermissions.forEach((parent) => {
      const parentNode: DataNode = {
        title: parent.title,
        key: parent.key,
      };

      if (parent.children) {
        const childNode: DataNode[] = [];
        parent.children.forEach((child) => {
          if (
            [
              'database',

              RoleResources.invoice_other_billing,
              RoleResources.mony_receipt_specific_invoice,
              RoleResources.mony_receipt_specific_ticket,
              RoleResources.vendor_payment_specific,

              RoleResources.database_backup,
              RoleResources.report_module,
              RoleResources.report_ledgers,
              RoleResources.report_total_due_advance,
              RoleResources.sales_report,
              RoleResources.profit_loss_report,
              RoleResources.expense_report,
              RoleResources.passport_report,
              RoleResources.passenger_list_report,
              RoleResources.vendor_wise_purchase_payment,
              RoleResources.client_discount,
              RoleResources.journey_date_wise_report,
              RoleResources.ait_report,
              RoleResources.accounts_report,
              RoleResources.online_transaction_charge_report,
              RoleResources.loan_report,
              RoleResources.preregistration_report,
              RoleResources.refund_report,
              RoleResources.summary,
              RoleResources.country_wise_report,
              RoleResources.trash_list,
              RoleResources.user_login_history,
              RoleResources.audit_trail,
              RoleResources.configuration_module,
              RoleResources.recruitments,
            ].includes(child?.key)
          ) {
            childNode.push({
              title: child.title,
              key: child.key,
              // children: actions(child.key),
            });
          } else {
            childNode.push({
              title: child.title,
              key: child.key,
              children: actions(child.key),
            });
          }
        });

        parentNode.children = childNode;
      } else {
        parentNode.children = actions(parent.key);
      }

      data.push(parentNode);
    });

    return data;
  };

  const treeData: DataNode[] = [
    {
      title: 'Select all',
      key: 'parent',
      children: [
        {
          title: 'Dashboard',
          key: RoleResources.dashboard,
        },
        ...getTreeData(),
      ],
    },
  ];

  const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      form.resetFields();
      navigate('/role/view');
    }
  }, [isLoading]);

  const handleFinish = async (value: any) => {
    const ac = new AccessControl();

    keys.forEach((item) => {
      const splittedKeys = item.split(':');
      const action = splittedKeys[1] as
        | 'create'
        | 'read'
        | 'update'
        | 'delete'
        | undefined;

      if (action) {
        ac.grant(value.role_name)[action](splittedKeys[0]);
      } else {
        ac.grant(value.role_name)['read'](item);
      }
    });

    const roleInfo = {
      ...value,
      role_permissions: JSON.stringify(ac.getGrants()),
    };

    await addRole(roleInfo);
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {};

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    setKeys(checkedKeys as string[]);
  };

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      form.resetFields();
      navigate('/role/view');
    } else if (isError) {
      message.error('An error occured. Please try again later.');
    }
  }, [isLoading]);

  // @validate role name is unique
  const [isUnique, { data: isUniqueData }] = useLazyRoleNameIsUniqueQuery();
  const role_name: string = useWatch(['role_name'], form);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (role_name)
        isUnique(role_name).then(() => {
          form.validateFields(['role_name']);
        });
    }, 500);
    return () => clearTimeout(timeout);
  }, [role_name]);

  return (
    <>
      <Form onFinish={handleFinish} layout='vertical' form={form}>
        <Row gutter={[12, 12]} align={'middle'} justify={'space-between'}>
          <FormInput
            label='Role name'
            name={'role_name'}
            required
            size={8}
            rules={[
              {
                validator(rule, value, callback) {
                  if (isUniqueData?.data?.is_uniqe) {
                    return Promise.reject('Role name already exist!');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />

          <Col lg={8}>
            <Form.Item
              name='user_role'
              label='Role Type'
              rules={[{ required: true }]}
            >
              <Select placeholder='Select a option' allowClear>
                <Select.Option value='ACCOUNT'>ACCOUNT</Select.Option>
                <Select.Option value='EMPLOYEE'>EMPLOYEE</Select.Option>
                <Select.Option value='ADMIN'>ADMIN</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Form.Item label={[]}>
            <Button htmlType='submit' type='primary' loading={isLoading}>
              Add role
            </Button>
          </Form.Item>
        </Row>

        {treeData.length ? (
          <Tree
            checkable
            // defaultExpandAll
            defaultExpandedKeys={['parent']}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={treeData}
          />
        ) : (
          loadingIndicator
        )}
      </Form>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default AddRole;
