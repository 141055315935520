import { List } from 'antd';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import DashboardTitle from './DashboardTitle';
import { Fixed2 } from '../../../common/utils/common.utils';

const BestEmployeeYear = ({ data, isDarkMode }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const oneYearAgo = dayjs().subtract(1, 'year');

  const handleNavigate = (id: string) => {
    navigate(
      `/reports/sales_man_collection_report?employee_id=${id}&from_date=${oneYearAgo}&to_date=${dayjs()}`,
      {
        state: location.pathname,
      }
    );
  };

  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle
        title='BEST EMPLOYEE OF THE YEAR'
        link='/reports/sales_man_collection_report'
      />

      <div
        className='custom-scrollbar'
        style={{
          height: 280,
          overflow: 'auto',
          marginTop: '18px',
          padding: '10px 20px',
        }}
      >
        <List
          itemLayout='horizontal'
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                padding: '10px',
              }}
            >
              <List.Item.Meta
                title={
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleNavigate(item.employee_id)}
                  >
                    {item.employee_full_name}
                  </div>
                }
                description={
                  <div>
                    Total Sales:{' '}
                    {Fixed2(item.invoice_net_total).toLocaleString()}
                  </div>
                }
              />
              <div style={{ color: '#6c757d', fontSize: 12, flex: 0.8 }}>
                <div>Mobile: {item.employee_mobile}</div>
                <div>Email: {item.employee_email}</div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default BestEmployeeYear;
