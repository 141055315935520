import { Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useLazyGetClLastBalanceQuery } from '../../../modules/Client/Client/api/endpoints/clientEndpoints';
import { DateInput, FormInputItem } from '../FormItem/FormItems';
import {
  SelectAgent,
  SelectClients,
  SelectEmployee,
} from '../FormItem/SelectCustomFeilds';

type Props = {
  isEdit?: boolean;
  clientOnChange?: Function;
  form: FormInstance<any>;
  invoice_agent_com_amountFieldName: NamePath;
  invoice_net_totalFieldName: NamePath;
  isQuotation?: boolean;
};

function InvoiceHeaderForm({
  isEdit,
  clientOnChange,
  form,
  invoice_agent_com_amountFieldName,
  invoice_net_totalFieldName,
  isQuotation,
}: Props) {
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  const [instanceAgentSelect, setInstanceAgentSelect] = useState<number>();
  // get client last balance
  const [getClientLastBalance, { data: clientLBlance, isSuccess }] =
    useLazyGetClLastBalanceQuery();
  const invoice_combclient_id = useWatch(['invoice_combclient_id'], form);
  useEffect(() => {
    if (invoice_combclient_id !== undefined) {
      getClientLastBalance(invoice_combclient_id);
    }
  }, [invoice_combclient_id]);

  useEffect(() => {
    form?.setFieldsValue({
      invoice_client_previous_due: clientLBlance?.data?.client_prev_due,
      client_present_balance: clientLBlance?.data?.client_last_balance,
    });

    if (isSuccess && clientOnChange) {
      clientOnChange();
    }
  }, [clientLBlance]);
  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgentSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgentSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    }
  }, [instanceClientsSelect, instanceAgentSelect, instanceSalesEmpSelect]);

  return (
    <>
      <Row
        className='border p-5 my-20'
        gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
        align='middle'
      >
        <SelectClients
          name='invoice_combclient_id'
          label='Select Client :'
          required
          disabled={isEdit || isQuotation}
          placeholder='Select client'
          size={4}
          mdSize={8}
          smSize={12}
          setInstanceClientSelect={setInstanceClientSelect}
        />

        <SelectEmployee
          name='invoice_sales_man_id'
          label='Sales By'
          required
          size={4}
          setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
        />
        <FormInputItem
          label='Invoice no'
          name='invoice_no'
          size={4}
          readOnly
          required
          disabled={isQuotation}
        />

        <DateInput
          label='Sales Date'
          name='invoice_sales_date'
          required
          size={4}
          disableFeatureDate
        />
        <DateInput label='Due Date:' name='invoice_due_date' size={4} />
        <SelectAgent
          label='Select Agent:'
          name='invoice_agent_id'
          size={4}
          setInstanceAgentSelect={setInstanceAgentSelect}
          isPercent
        />
      </Row>
    </>
  );
}

export default InvoiceHeaderForm;
