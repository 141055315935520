import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ISetState } from '../../../../common/types/commonTypes';
import { IProduct } from '../../../Configuration/Products/types/productTypes';
const CheckboxGroup = Checkbox.Group;

type Props = {
  products: IProduct[];
  setSelectProducts: ISetState<number[]>;
  selectedVAlue?: number[];
  commission?: boolean;
  openFrom?: 'CombineClient' | 'Client';
};

const CombineClientProducts = ({
  products,
  setSelectProducts,
  selectedVAlue,
  commission,
  openFrom,
}: Props) => {
  const [showCommissionRate, setShowCommissionRate] = useState<boolean>(false);

  useEffect(() => {
    commission && setShowCommissionRate(commission);
  }, [commission]);

  // =============== products handle ==============

  const productsId = products?.map((item) => item.product_id);
  const [checkedList, setCheckedList] = useState<number[]>(selectedVAlue || []);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (selectedVAlue) setCheckedList(selectedVAlue);
  }, [selectedVAlue]);

  const onChange = (list: any[]) => {
    setCheckedList(list as number[]);
    setIndeterminate(!!list.length && list.length < productsId.length);
    setCheckAll(list.length === productsId.length);
    setShowCommissionRate(list.includes(106));
    setSelectProducts(list as number[]);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? productsId : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setShowCommissionRate(e.target.checked);
  };

  useEffect(() => {
    setSelectProducts(checkedList);
  }, [checkedList, selectedVAlue]);

  return (
    <Col span={6} xs={24} sm={24} md={24} lg={24}>
      <Form.Item required label={'Products'}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className='mb-10'
        >
          CHECK ALL
        </Checkbox>
        <CheckboxGroup value={checkedList} onChange={onChange}>
          <Row gutter={[48, 16]}>
            {products?.map((item) => {
              return (
                <Col key={item.product_id} span={8}>
                  <Checkbox value={item.product_id}>
                    {item.product_name}
                    {item.product_id == 106 && showCommissionRate ? (
                      <Form.Item
                        style={{
                          display: 'inline-block',
                          margin: '0',
                          padding: '0',
                        }}
                        name={
                          openFrom
                            ? 'combine_commission_rate'
                            : 'vendor_commission_rate'
                        }
                        rules={[
                          () => ({
                            validator: (_, value) => {
                              if (value > 100) {
                                return Promise.reject();
                              } else if (value < 0) {
                                return Promise.reject();
                              }
                              return Promise.resolve();
                            },
                            message: '0 >= value <= 100',
                          }),
                        ]}
                      >
                        <Input
                          placeholder='Ticket Commission'
                          size='small'
                          min={'0'}
                          max={'100'}
                          required={showCommissionRate}
                          style={{
                            marginLeft: '20px',
                            width: '150px',
                          }}
                          type={'number'}
                          step={'0.01'}
                        />
                      </Form.Item>
                    ) : null}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </CheckboxGroup>
      </Form.Item>
    </Col>
  );
};

export default CombineClientProducts;
