import { Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { useEffect } from 'react';
import {
  FormInput,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { log } from 'console';
import { Fixed2 } from '../../../common/utils/common.utils';

type Props = {
  form: FormInstance<any>;
  clientPriceSum?: Number;
  clientPriceSumNonComission?: Number;
  clientPriceSumReIssue?: Number;
  clientPriceSumUpdate?: Number;
  isAgentComission?: boolean;
};

function InvoiceAirSubTotal({
  form,
  clientPriceSum,
  clientPriceSumNonComission,
  clientPriceSumReIssue,
  clientPriceSumUpdate,
  isAgentComission,
}: Props) {
  const billingForm = useWatch(['billing_information'], form);

  const invoice_sub_total = useWatch(['invoice_sub_total'], form);
  const discount: number = useWatch(['invoice_discount'], form);
  const serviceCharge: number = useWatch(['invoice_service_charge'], form);
  const vat: number = useWatch(['invoice_vat'], form);

  let TotalClientPrice: any =
    clientPriceSumUpdate ||
    clientPriceSum ||
    clientPriceSumNonComission ||
    clientPriceSumReIssue;

  const netTotal = Fixed2(
    Fixed2(TotalClientPrice || 0) -
      Fixed2(discount || 0) +
      Fixed2(vat || 0) +
      (Fixed2(serviceCharge) || 0)
  );

  useEffect(() => {
    form?.setFieldValue('invoice_net_total', netTotal);
  }, [
    billingForm,
    invoice_sub_total,
    discount,
    vat,
    serviceCharge,
    clientPriceSum,
    TotalClientPrice,
    clientPriceSumUpdate,
    clientPriceSumReIssue,
    netTotal,
  ]);

  return (
    <Row gutter={2}>
      <NumberInput
        min='0'
        size={12}
        label={'Discount'}
        name={'invoice_discount'}
        maxChar={10}
      />

      <NumberInput
        size={12}
        label={'Service Charge'}
        name={'invoice_service_charge'}
        min='0'
        maxChar={10}
      />

      <NumberInput
        min='0'
        size={12}
        label='Vat / Tax'
        name='invoice_vat'
        maxChar={10}
      />
      <NumberInput
        size={12}
        label='Net total'
        name={'invoice_net_total'}
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        size={12}
        label='Agent Commission'
        name={'invoice_agent_com_amount'}
        min='0'
        maxChar={10}
        disabled={isAgentComission}
      />
      {/* <TextAreaInput size={12} label='Note' name='invoice_note' /> */}
      <FormInput size={12} label='Reference' name='invoice_reference' />

      <TextAreaInput size={24} label='Note' name='invoice_note' />
    </Row>
  );
}

export default InvoiceAirSubTotal;
