import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import moment from "moment";
import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ReceiptHeader } from "../../../../common/Invoice/InvoiceHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import NumToWord from "../../../../components/number_to_words/NumToWord";
import { IClientView } from "../../AccountsTypes/AccountsTypes";
import { useGetViewClientIncentiveIncomeQuery } from "./endpoints/client_IncentiveIncomeEndpoints";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";

const ViewIncentiveIncome = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetViewClientIncentiveIncomeQuery(Number(id));
  const { Text, Title } = Typography;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `BalanceTranfer`,
    pageStyle: "",
    // '@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }',
  });
  const a4sizeStyle: React.CSSProperties = {
    minHeight: "11.5in",
    width: "8.27in",
    fontSize: "11px",
    background: "#fff",
    margin: "10px auto",
  };
  const column: ColumnsType<IClientView> = [
    {
      title: "SL.",
      dataIndex: "key",
      key: "key",
      render: (_, data, index) => index + 1,
    },

    {
      title: "Date",
      key: "incentive_date",
      render: (_, record) => {
        return `${dayjs(record.incentive_date).format("DD MMM YYYY")}`;
      },
    },

    {
      title: "Transaction Type",
      key: "transaction_type",
      dataIndex: "trxntype_name",
    },
    {
      title: "Transaction Details",
      key: "transaction_details",
      render: (_, record) => {
        return (
          <>
            {record.account_name}
            {record.account_branch_name
              ? ", " + record.account_branch_name
              : "N/A"}
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "actransaction_amount",
      key: "actransaction_amount",
      render: (_, record) => Number(record?.incentive_amount),
    },
  ];
  return (
    <>
      <BreadCrumb arrOfOption={["Accounts", "Client Incentive Income"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Link to="/accounts/incentive">
          <Button type="primary">
            <ArrowLeftOutlined />
            Return to Incentive List
          </Button>
        </Link>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Card hoverable ref={componentRef} style={a4sizeStyle}>
          <header>
            <Row justify={"space-between"} align="middle">
              <ReceiptHeader />
            </Row>

            <Typography.Title
              level={5}
              style={{
                border: "2px solid #041E42",
                padding: "7px",
                borderRadius: "4px",
                textAlign: "center",
                width: "180px",
                margin: "10px auto",
                color: "#041E42",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Incentive Income
            </Typography.Title>
          </header>

          {data?.data && data.data[0]?.client_name && (
            <Col>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Name : {data?.data && data.data[0]?.client_name}
              </Typography>
            </Col>
          )}

          <Table
            size="small"
            bordered
            pagination={false}
            columns={column}
            dataSource={data?.data}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            style={{ marginTop: "20px" }}
            loading={{ spinning: isLoading, indicator: <LoadingIndicator /> }}
          />

          <Row
            justify={"space-around"}
            align={"middle"}
            style={{
              margin: "25px 0",
            }}
          >
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Note : {data?.data && data.data[0]?.incentive_note}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {data?.data && (
                <NumToWord
                  number={Number(data.data[0]?.incentive_amount || 0)}
                />
              )}
            </Typography>
          </Row>
          {/* ================== signature ===================== */}
          <Row
            className="signature"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "120px",
            }}
          >
            <Typography.Text
              style={{
                borderTop: "1px solid black",
                color: "black",
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Received By
            </Typography.Text>
            <Typography.Text
              style={{
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {moment().format("ll LTS")}
            </Typography.Text>
            <Typography.Text
              style={{
                borderTop: "1px solid black",
                color: "black",
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Approved By
            </Typography.Text>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

export default ViewIncentiveIncome;
