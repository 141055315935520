import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, InputNumber, Row, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormInputTransaction,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../Accounts/Api/Endpoints/accountsEndpoints';
import { useGetAccountByTypeQuery } from '../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../vendor/components/AccountLastBalanceInput';
import { layout } from '../../vendor/pages/AdvanceReturnEdit';
import {
  useEditExpenseMutation,
  useGetSingleExpenseQuery,
} from '../Api/Endpoints/ExpenseEndpoints';
import Expense_Info_FormList from '../Component/Expense_info_FormList';
import {
  IAmountTotal,
  IExpenseEditFormData,
} from '../ExpenseTypes/Expensetypes';
import FileUpload from '../../../common/utils/FileUpload';

type Props = {};

const EditExpense = (props: Props) => {
  const isChanged = useRef(false);
  const user = useSelector(selectUser);
  const [paymentType, setPaymentType] = useState<string>();
  const [balance, setBalance] = useState<number>();

  const [paymentMethod, setPaymentMethod] = useState(0);
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const [account, setAccount] = useState<string>();

  const { data: accounts } = useGetAccountByTypeQuery(Number(payMethodCatId));

  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const { data: expenseData } = useGetSingleExpenseQuery(Number(id));

  const expense_price = Form.useWatch('expense_details', form);
  let totalPrice: number = 0;

  useEffect(() => {
    form.setFieldsValue({
      expense_payment_type: expenseData?.data?.expense_payment_type,
      expense_accategory_id: expenseData?.data?.acctype_id,
      expense_accounts_id: expenseData?.data?.account_id,
      expense_total_amount: expenseData?.data?.expense_total_amount,
      expense_cheque_no: expenseData?.data?.expense_cheque_no,
      expcheque_bank_name: expenseData?.data?.expcheque_bank_name,
      expense_vouchar_no: expenseData?.data?.expense_vouchar_no,
      expense_date: dayjs(expenseData?.data?.expense_date),
      charge_amount: expenseData?.data?.charge_amount,
      expcheque_withdraw_date:
        expenseData?.data?.expcheque_withdraw_date &&
        dayjs(expenseData?.data?.expcheque_withdraw_date),
      expense_note: expenseData?.data?.expense_note,

      card_trans_charge: expenseData?.data?.card_trans_charge,
      card_trans_charge_type: expenseData?.data?.card_trans_charge_type,
      card_trans_no: expenseData?.data?.card_trans_no,
    });
    if (expenseData?.data?.acctype_id) {
      setPaymentMethod(expenseData?.data?.acctype_id);
    }
    setPayMethodCatId(Number(expenseData?.data?.expense_payment_type) || 0);
    setPaymentType(expenseData?.data?.expense_payment_type);
    setAccount(expenseData?.data?.account_id?.toString());
  }, [expenseData, id]);

  useEffect(() => {
    if (expense_price) {
      expense_price.forEach((element: IAmountTotal) => {
        if (element && element.expdetails_amount) {
          totalPrice = totalPrice + Number(element.expdetails_amount);
        }
      });
    }
  }, [expense_price]);

  useEffect(() => {
    if (totalPrice) {
      form.setFieldValue('expense_total_amount', totalPrice);
    }
  }, [expense_price]);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ expense_accounts_id: undefined });
      setBalance(undefined);

      return;
    }
  }, [paymentType, paymentMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    if (account) {
      fetchBalance(Number(account));
    }
  }, [account, expenseData]);

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  // form submit handle
  const [editExpense, { isError, isLoading, isSuccess }] =
    useEditExpenseMutation();

  const accountId = form.getFieldValue('expense_accounts_id');

  const onFinish = async (values: IExpenseEditFormData) => {
    const {
      expense_voucher_url_1,
      expense_voucher_url_2,
      expense_details,
      // expcheque_withdraw_date,
      expense_cheque_no,
      expcheque_bank_name,
      ...others
    } = values;

    const formData = new FormData();

    for (const key in others) {
      const value = values[key as keyof IExpenseEditFormData];

      if (value !== undefined && value !== null) {
        formData.append(key, value.toString());
      }
    }
    formData.append('expense_created_by', String(user?.user_id));
    formData.append('expense_details', JSON.stringify(values?.expense_details));

    // if (values.expcheque_withdraw_date) {
    //   formData.append(
    //     'expcheque_withdraw_date',
    //     dayjs(values.expcheque_withdraw_date).format('YYYY-MM-DD')
    //   );
    // }

    if (values.expense_cheque_no) {
      formData.append('expense_cheque_no', values.expense_cheque_no);
    }

    if (values.expcheque_bank_name) {
      formData.append('expcheque_bank_name', values.expcheque_bank_name);
    }

    if (values.expense_voucher_url_1) {
      formData.append(
        'expense_voucher_url_1',
        values?.expense_voucher_url_1[0]?.originFileObj
      );
    }

    if (values.expense_voucher_url_2) {
      formData.append(
        'expense_voucher_url_2',
        values?.expense_voucher_url_2[0]?.originFileObj
      );
    }

    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    await editExpense({ data: formData, id: Number(id) });

    return;

    // formData.append('expense_payment_type', values?.expense_payment_type);
    // formData.append('expense_date', values?.expense_date);

    // if (values.expense_accounts_id) {
    //   formData.append(
    //     'expense_accounts_id',
    //     values.expense_accounts_id?.toString()
    //   );
    // }

    // formData.append(
    //   'expense_total_amount',
    //   values?.expense_total_amount.toString()
    // );

    // if (values?.expense_note) {
    //   formData.append('expense_note', values?.expense_note);
    // }

    // if (values?.expense_note) {
    //   formData.append('expense_note', values?.expense_note);
    // }

    // if (values.expcheque_withdraw_date) {
    //   formData.append(
    //     'expcheque_withdraw_date',
    //     dayjs(values.expcheque_withdraw_date).format('YYYY-MM-DD')
    //   );
    // }

    // return;

    // const body: IExpenseEditFormData = {
    //   ...values,
    //   expense_id: Number(id),
    //   expense_date: dayjs(values.expense_date).format('YYYY-MM-DD'),
    //   expcheque_withdraw_date: dayjs(values.expcheque_withdraw_date).format(
    //     'YYYY-MM-DD'
    //   ),
    //   expense_accounts_id: accountId,
    //   expense_created_by: user?.user_id as number,
    // };
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Expense Edited Successfully');

      navigate('/expense');
    } else if (isError) {
      message.error('Expense cannot added!');
    }
  }, [isLoading]);

  return (
    <div>
      <BreadCrumb arrOfOption={['Edit Expense']} />

      <Link to='/expense'>
        <Button type='primary'>
          <ArrowLeftOutlined />
          Return to Expense List
        </Button>
      </Link>

      <Form {...layout} labelAlign='left' form={form} onFinish={onFinish}>
        <div className='border p-5' style={{ marginTop: '0.5rem' }}>
          {/* Expense information */}
          {expenseData?.data?.expense_details ? (
            <Row justify={'center'}>
              <Card
                style={{
                  background: 'transparent',
                  width: '80%',
                }}
              >
                <Expense_Info_FormList
                  form={form}
                  expenseInitialValues={expenseData?.data?.expense_details}
                />
              </Card>
            </Row>
          ) : (
            loadingIndicator
          )}

          <Row
            style={{ padding: '10px', marginTop: '20px' }}
            gutter={[16, 16]}
            justify='space-between'
          >
            <Col span={11}>
              <SelectPaymentMethod
                required
                name='expense_payment_type'
                label='Payment Method '
                onChange={(value: number) => {
                  setPayMethodCatId(value);
                  setPaymentMethod(value);
                  if (!isChanged.current) isChanged.current = true;
                }}
                cheque
                size={24}
              />
              {Number(payMethodCatId) == 4 && (
                <>
                  <FormInputItem
                    label='Cheque No'
                    name='expense_cheque_no'
                    required
                    size={24}
                  />

                  <DateInput
                    name='expcheque_withdraw_date'
                    label='Withdraw Date'
                    required
                    size={24}
                  />

                  <FormInputItem
                    name={'expcheque_bank_name'}
                    label='Bank Name'
                    required
                    onChange={(value) => setAccount(String(value))}
                    size={24}
                  />
                </>
              )}
              {Number(payMethodCatId) !== 4 && (
                <>
                  <AccountLastBalanceInput
                    accountsLastBalance={accounts ? accounts.data : []}
                    name='expense_accounts_id'
                    label='Account:'
                    onSelect={(value: number) => setAccount(String(value))}
                    required
                    size={24}
                  />
                </>
              )}
              {Number(payMethodCatId) === 3 && (
                <>
                  <FormInputItem
                    name={'charge_amount'}
                    label='Transaction Charge :'
                    size={24}
                  />

                  <FormInputItem
                    label='Receipt/Trans No:'
                    name='trans_no'
                    size={24}
                  />
                </>
              )}

              {Number(payMethodCatId) === 5 && (
                <>
                  <FormInputTransaction
                    chargeTypeName='card_trans_charge_type'
                    name='card_trans_charge'
                    label='Transaction charge '
                    size={24}
                  />
                  <FormInputItem
                    name='card_trans_no'
                    label='Receipt/Trans No : '
                    size={24}
                  />
                </>
              )}
              <DateInput label='Date' name='expense_date' required size={24} />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  gap: 10,
                }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    labelAlign='left'
                    name={'expense_voucher_url_1'}
                    valuePropName='upload_photo'
                  >
                    <FileUpload
                      size={24}
                      smSize={24}
                      mdSize={24}
                      name='expense_voucher_url_1'
                      msg='Voucher image 1'
                      noBorder
                      imagURL={expenseData?.data?.expense_voucher_url_1}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    name={'expense_voucher_url_2'}
                    valuePropName='upload_photo'
                  >
                    <FileUpload
                      size={24}
                      smSize={24}
                      mdSize={24}
                      name='expense_voucher_url_2'
                      msg='Voucher image 2'
                      noBorder
                      imagURL={expenseData?.data?.expense_voucher_url_2}
                    />
                  </Form.Item>
                </Col>
              </div>
            </Col>

            <Col span={11}>
              {Number(payMethodCatId) !== 4 && (
                <Col span={6} xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Available Balance' required>
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled
                      value={balance}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={5} xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label='Total Amount'
                  name='expense_total_amount'
                  rules={[
                    { required: true, message: 'Amount is required' },
                    // {
                    //   validator(_, value) {
                    //     if (value && balance) {
                    //       if (Number(balance) < value) {
                    //         return Promise.reject(
                    //           'Expense amount cannot be more than balance!!'
                    //         );
                    //       } else {
                    //         return Promise.resolve();
                    //       }
                    //     } else {
                    //       return Promise.resolve();
                    //     }
                    //   },
                    // },
                  ]}
                >
                  <InputNumber
                    disabled
                    style={{ width: '100%' }}
                    max={10}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <FormInputItem
                name='expense_vouchar_no'
                label='Vouchar No.'
                required
                size={24}
                readOnly
              />
              <TextAreaInput label='Note' name='expense_note' size={24} />{' '}
            </Col>
          </Row>

          <FormButton label='Edit Expense' loading={isLoading} />
        </div>
      </Form>
    </div>
  );
};

export default EditExpense;
