import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { EMPLOYEEEXPENSEREPORT } from '../../Api/constants';
import { IEmployeeExpenseReportDataType } from '../types/EmployeeExpenseReportTypes';

export const EmployeeExpenseReportEndPoint = api.injectEndpoints({
  endpoints: (build) => ({
    // get all EmployeExpenseReport
    getAllEmployeExpenseReport: build.query<
      HTTPResponse<IEmployeeExpenseReportDataType[]>,
      {
        employee_id: string | number;
        query: string;
      }
    >({
      query: ({ employee_id, query }) => ({
        url: `report/employe-expense/${employee_id}${query}`,
      }),
      providesTags: [
        { type: 'EmployeeExpenseReport', id: EMPLOYEEEXPENSEREPORT },
      ],
    }),
  }),
});

export const { useLazyGetAllEmployeExpenseReportQuery } =
  EmployeeExpenseReportEndPoint;
