import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteAirlineMutation } from '../Endpoints/airlineEndpoints';
import { AirlineDataType } from '../types/AirlinesTypes';

type Props = {
  setEditInfo: ISetState<AirlineDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const airlinesColumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<AirlineDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);
  const handleClick = (record: AirlineDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteAirline, { isError, isSuccess, isLoading }] =
    useDeleteAirlineMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Airline Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleDelete = async (values: AirlineDataType, index: number) => {
    setColumnIndex(index);
    await deleteAirline({ ...values, deleted_by: user?.user_id });
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      align: 'center',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Airline code',
      dataIndex: 'airline_code',
      key: 'airline_code',
      align: 'center',
    },
    {
      title: 'Airline name',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
  ];
};
