import { ConfigProvider, Table, theme } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import {
  IViewInvoiceDetails,
  IViewMoneyReceipt,
} from '../../../../common/types/commonInterfaces';
import ClientInfo from '../ClientInfo';
import { invoiceViewStyle } from './CommonViewInvoice';
import { Link } from 'react-router-dom';

type Props = {
  invoiceDetails: IViewInvoiceDetails | undefined;
  viewInvoicePaymentsRef: React.RefObject<HTMLDivElement>;
};
const TabPaymentView = ({ invoiceDetails, viewInvoicePaymentsRef }: Props) => {
  const moneyReceiptColumn: ColumnsType<IViewMoneyReceipt> = [
    {
      title: 'Sl',

      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Client Name',
      align: 'center',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Money Recipt No.',
      dataIndex: 'receipt_money_receipt_no',
      align: 'center',
      render: (mr_no, data) => (
        <Link
          to={`/moneyreceipt/view/${data.invclientpayment_moneyreceipt_id}`}
        >
          {mr_no}
        </Link>
      ),
    },
    {
      title: 'Payment To',
      align: 'center',
      dataIndex: 'receipt_payment_to',
      key: 'receipt_payment_to',
    },
    {
      title: 'Method',
      dataIndex: 'acctype_name',
      key: 'acctype_name',
      align: 'center',
    },
    {
      title: 'Note',
      dataIndex: 'receipt_note',
      key: 'receipt_note',
      align: 'center',
    },

    {
      title: 'Amount',
      dataIndex: 'receipt_total_amount',
      key: 'receipt_total_amount',
      align: 'right',
      render(value, record, index) {
        return Number(value || 0);
      },
    },

    {
      title: 'Received By',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
    },

    {
      title: 'Receipt Date',
      key: 'crefund_total_amount',
      align: 'right',
      render: (_, data) => (
        <> {dayjs(data.receipt_payment_date).format('DD MMM YYYY')} </>
      ),
    },
  ];

  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            width: '8.27in',
            height: '11.69in',
            background: '#fff',
            padding: '10px',
          }}
          ref={viewInvoicePaymentsRef}
        >
          {invoiceDetails && <ClientInfo invoice_info={invoiceDetails} />}

          <h3 className='text-center border'>PAYMENT DETAILS</h3>

          {invoiceDetails?.payments?.length ? (
            <Table
              size='small'
              bordered
              rowKey={(e) => e.receipt_money_receipt_no}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={invoiceDetails?.payments}
              columns={moneyReceiptColumn}
              pagination={false}
            />
          ) : (
            <>No Data Found</>
          )}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TabPaymentView;
