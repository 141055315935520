import { DatePicker, Form, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { perProps } from '../../../common/types/commonTypes';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllVendorPaymentReportQuery } from '../../vendor/api/endpoints/vendorPaymentEndpoints';
import { IVendorPaymentList } from '../../vendor/types/vendor.interfaces';
import { rangePresets } from '../../../common/utils/common.utils';

const PurchasedReport = ({ permission }: perProps) => {
  const [form] = Form.useForm();
  const [getData, SetGetData] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const [formDate, setFromDate] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  // Set Default Value
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: allVendors,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllVendorPaymentReportQuery(
    {
      ...pagination,
      from_date: dayjs(formDate).format('YYYY-MM-DD'),
      to_date: dayjs(toDate).format('YYYY-MM-DD'),
    },
    { skip: getData }
  );

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get('daily');

    const monthFormDate = searchParams.get('from_date');
    const monthToDate = searchParams.get('to_date');

    const yearFormDate = searchParams.get('y_from_date');
    const yearToDate = searchParams.get('y_to_date');

    if (dailyValue) {
      handleFinish({
        date_range: [dailyValue, dailyValue],
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue('date_range', [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      handleFinish({
        date_range: [monthFormDate, monthToDate],
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue('date_range', [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      handleFinish({
        date_range: [yearFormDate, yearToDate],
      });
    }
  }, [location.search]);

  const handleFinish = (value: { date_range: string[] }) => {
    setFromDate(value.date_range[0]);
    setToDate(value.date_range[1]);

    SetGetData(false);

    setPagination((prev) => ({ ...prev, current: 1, pageSize: 20 }));
  };

  return (
    <>
      <BreadCrumb
        arrOfOption={['List Of Vendor Payments']}
        refetch={refetch}
        reloaderSize='small'
      />

      <Form onFinish={handleFinish} form={form} layout='vertical'>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Form.Item label='Date Range' name='date_range'>
            <DatePicker.RangePicker 
            presets={rangePresets}
            format={'DD-MM-YYYY'} allowClear={false} />
          </Form.Item>

          <FormButton label='Search' icon textAlign='left' />
        </div>
      </Form>

      <Table
        bordered
        size='small'
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        rowKey={(e) => e.vpay_id}
        columns={columns({ pagination })}
        dataSource={allVendors?.data}
        scroll={{ x: true }}
        pagination={
          allVendors?.count !== undefined && allVendors?.count < 20
            ? false
            : {
                ...pagination,
                total: allVendors?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </>
  );
};

export default PurchasedReport;

const columns = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IVendorPaymentList> => [
  {
    title: 'SL',
    key: 'key',
    render: (_, data, index) => (
      <>
        {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
          1 +
          index}
      </>
    ),
  },
  {
    title: 'Date',
    key: 'payment_date',
    align: 'center',
    render: (_, e) => (
      <>{e.payment_date && dayjs(e.payment_date).format('DD-MM-YYYY')}</>
    ),
  },
  {
    title: 'Voucher No',
    dataIndex: 'vouchar_no',
    key: 'vouchar_no',
    align: 'center',

    render: (_, data) => (
      <Link to={`/vendors/payment/${data.vpay_id}`} state={'/vendors/payment'}>
        {data.vouchar_no}
      </Link>
    ),
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
  },
  {
    title: 'Account',
    dataIndex: 'account_name',
    key: 'account_name',
  },
  {
    title: 'Total Payment Amount',
    dataIndex: 'payment_amount',
    key: 'payment_amount',
    align: 'center',
    render: (_, e) => <>{Math.abs(e.payment_amount)}</>,
  },
];
