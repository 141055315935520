import { Col, Form, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
  FormInputItem,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { useGetAccountByTypeQuery } from '../../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../../vendor/components/AccountLastBalanceInput';
import { useEditLoanMutation } from '../../Api/Endpoints/LoanEndpoints';
import SelectAuthority from '../../Components/SelectAuthority';
import {
  LoanDataType,
  LoanFormDataType,
  loan_type_options,
} from '../../LoanTypes/LoanTypes';
import {
  setEditAlreadyGivenLoanModal,
  setEditAlreadyTakenLoanModal,
  setEditGivingLoanModal,
  setEditTakingLoanModal,
} from '../../Loan_State/loanSlice';
import { Fixed2 } from '../../../../common/utils/common.utils';

interface IProps {
  info: LoanDataType;
  setNull: ISetState<LoanDataType | null>;
}
const EditLoanModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const isChanged = useRef(false);

  const [paymentType, setPaymentType] = useState<string>();
  const [loanType, setLoanType] = useState<string>('TAKING');
  const [accountID, setAccountID] = useState<number>();
  const [loanAmount, setLoanAmount] = useState<number | null>();
  const [interest, setInterest] = useState<number | null>();
  const [pay_recieve_amount, setPay_recieve_amount] = useState<number>();
  const [installment, setInstallment] = useState<string>();
  const [installmentType, setInstallmentType] = useState<string>();
  const [duration, setDuration] = useState<number | null>();
  const [per_installment, setPer_installment] = useState<number>();

  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>();
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<number>();

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );
  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  useEffect(() => {
    form.setFieldsValue({
      authority_id: info.loan_authority_id,
      name: info.loan_name,
      type: info.loan_type,
      amount: info.loan_amount,
      interest_percent: info.loan_interest_percent,
      installment: info.loan_installment,
      installment_type: info.loan_installment_type,
      installment_duration: info.loan_installment_duration,
      payment_type: info.loan_payment_type,
      accategory_id: info.loan_accategory_id,
      account_id: info.loan_account_id,
      cheque_no: info.loan_cheque_no,
      withdraw_date: info.loan_withdraw_date && dayjs(info.loan_withdraw_date),
      bank_name: info.loan_bank_name,
      date: dayjs(info.loan_date),
      loan_note: info.loan_note,
    });

    setAccount(info.loan_account_id?.toString());

    setPayMethodCatId(
      info.loan_payment_type ? Number(info.loan_payment_type) : undefined
    );

    setInterest(Number(info.loan_interest_percent));
    setLoanAmount(Number(info.loan_amount));
    setPaymentType(info.loan_payment_type);
    setPay_recieve_amount(info.loan_payable_amount);
    setInstallment(info.loan_installment);
    setInstallmentType(info.loan_installment_type);
    setDuration(info.loan_installment_duration);
    setAccountID(info.loan_account_id);
    if (info.loan_installment_per_day) {
      setPer_installment(info.loan_installment_per_day);
    } else if (info.loan_installment_per_month) {
      setPer_installment(info.loan_installment_per_month);
    }
    if (info.loan_accategory_id) {
      setPaymentMethod(info.loan_accategory_id);
    }
  }, [info]);

  // calculate payable/receivable amount
  useEffect(() => {
    if (interest != undefined && loanAmount != undefined) {
      setPay_recieve_amount((interest / 100) * loanAmount + loanAmount);

      form.setFieldValue(
        'pay_amount',
        (interest / 100) * loanAmount + loanAmount
      );
      form.setFieldValue(
        'receive_amount',
        (interest / 100) * loanAmount + loanAmount
      );
    }
  }, [loanAmount, interest, pay_recieve_amount]);

  //calculate per month/daily installment amount
  useEffect(() => {
    if (pay_recieve_amount != undefined && duration != undefined) {
      setPer_installment(pay_recieve_amount / duration);
      form.setFieldValue(
        'installment_per_month',
        pay_recieve_amount / duration
      );
      form.setFieldValue('installment_per_day', pay_recieve_amount / duration);
    }
  }, [duration, pay_recieve_amount, per_installment]);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });
      setBalance(undefined);
      return;
    }
  }, [paymentType, paymentMethod]);

  useEffect(() => {
    if (account) {
      fetchBalance(Number(account));
    }
  }, [account, info]);

  const [editLoan, { isSuccess, isLoading }] = useEditLoanMutation();

  const handleSubmit = async (values: LoanFormDataType) => {
    const body: LoanFormDataType = {
      ...values,
      loan_id: info.loan_id,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      withdraw_date: dayjs(values.withdraw_date).format('YYYY-MM-DD'),
      loan_created_by: user?.user_id as number,
      loan_actransaction_id: info.loan_actransaction_id,
    };

    form.setFieldsValue({ account_id: info.loan_account_id });

    await editLoan(body);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setInstallment('NO');
      setNull(null);
      dispatch(setEditTakingLoanModal(false));
      dispatch(setEditGivingLoanModal(false));
      dispatch(setEditAlreadyTakenLoanModal(false));
      dispatch(setEditAlreadyGivenLoanModal(false));
    }
  }, [isSuccess]);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <SelectAuthority disabled />

          <FormInputItem label='Loan Name' name='name' required size={12} />
          <Col span={6} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              initialValue={'TAKING'}
              name='type'
              label='Loan Type'
              rules={[{ required: true, message: 'Loan Type is required!!' }]}
            >
              <Select
                disabled
                allowClear
                placeholder='Select Payment Method'
                style={{ width: '100%' }}
                onChange={(e: string) => {
                  setLoanType(e);
                }}
              >
                {loan_type_options.map((item) => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label='Loan Amount'
              name='amount'
              rules={[
                { required: true, message: 'Loan Amount is required!!' },
                {
                  validator(_, value) {
                    const balance =
                      accountBalance?.data && accountBalance?.data.amount;

                    if (value && balance) {
                      if (
                        Fixed2(balance) < Fixed2(value) &&
                        loanType == 'GIVING'
                      ) {
                        return Promise.reject('Not available balance!!');
                      } else {
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                onChange={(e: number | null) => {
                  setLoanAmount(Number(e));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label='Interest(%)'
              name='interest_percent'
              rules={[{ required: true, message: 'Interest(%) is required!!' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                onChange={(e: number | null) => {
                  setInterest(Number(e));
                }}
              />
            </Form.Item>
          </Col>

          {loanType == 'TAKING' || loanType == 'ALREADY_TAKEN' ? (
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                label='Payable Amount'
                name='pay_amount'
                rules={[
                  { required: true, message: 'Payable Amount is required!!' },
                ]}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          ) : (
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                label='Receivable Amount'
                name='receive_amount'
                rules={[
                  {
                    required: true,
                    message: 'Receivable Amount is required!!',
                  },
                ]}
              >
                <InputNumber
                  disabled
                  value={pay_recieve_amount}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={6} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              name='installment'
              label='Installment'
              rules={[{ required: true, message: 'Installment is required!!' }]}
            >
              <Select
                allowClear
                placeholder='Select Installment'
                style={{ width: '100%' }}
                onChange={(e: string) => setInstallment(e)}
              >
                <Select.Option key={'YES'} value={'YES'}>
                  YES
                </Select.Option>
                <Select.Option key={'NO'} value={'NO'}>
                  NO
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {installment == 'YES' && (
            <>
              <Col span={6} xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name='installment_type'
                  label='Installment Type'
                  rules={[
                    {
                      required: true,
                      message: `Installment Type is required!`,
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder='Select Installment Type'
                    style={{ width: '100%' }}
                    onChange={(e: string) => setInstallmentType(e)}
                  >
                    <Select.Option key={'MONTHLY'} value={'MONTHLY'}>
                      MONTHLY
                    </Select.Option>
                    <Select.Option key={'DAILY'} value={'DAILY'}>
                      DAILY
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label='Duration'
                  name='installment_duration'
                  rules={[
                    {
                      required: true,
                      message: `Duration is required!`,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    onChange={(e: number | null) => {
                      setDuration(Number(e));
                    }}
                  />
                </Form.Item>
              </Col>

              {installmentType == 'MONTHLY' ? (
                <Col span={6} xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label='Per Month'
                    name='installment_per_month'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      disabled
                      precision={2}
                      // value={pay_recieve_amount}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6} xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label='Per Day'
                    name='installment_per_day'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      disabled
                      precision={2}
                      // value={pay_recieve_amount}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          )}

          <SelectPaymentMethod
            required
            name='payment_type'
            label='Payment Method '
            onChange={(value: number) => {
              setPayMethodCatId(value);
              setPaymentMethod(value);
              if (!isChanged.current) isChanged.current = true;
            }}
            cheque
            size={12}
          />
          {Number(payMethodCatId) === 4 && (
            <>
              <FormInputItem
                label='Cheque No'
                name='cheque_no'
                required
                size={12}
              />

              <DateInput
                name='withdraw_date'
                label='Withdraw Date'
                required
                size={12}
              />

              <FormInputItem
                name={'bank_name'}
                label='Bank Name'
                required
                onChange={(value) => setAccount(String(value))}
                size={12}
              />
            </>
          )}
          {Number(payMethodCatId) !== 4 && (
            <>
              <AccountLastBalanceInput
                accountsLastBalance={accounts ? accounts.data : []}
                name='account_id'
                label='Account:'
                // disabled={paymentMethod || reason === 'EDIT' ? false : true}
                onSelect={(value: number) => {
                  form.validateFields(['amount']);
                  setAccount(String(value));
                }}
                required
                size={12}
              />
            </>
          )}
          {Number(payMethodCatId) === 3 && (
            <>
              <FormInputItem
                name={'charge_amount'}
                label='Transaction Charge :'
                size={12}
              />

              <FormInputItem
                label='Receipt/Trans No:'
                name='trans_no'
                size={12}
              />
            </>
          )}

          {Number(payMethodCatId) !== 4 && (
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item label='Available Balance' required>
                <InputNumber
                  style={{ width: '100%' }}
                  disabled
                  value={balance}
                />
              </Form.Item>
            </Col>
          )}

          <DateInput label='Date' name='date' size={12} />
          <TextAreaInput label='Note' name='note' size={24} />
        </Row>
        <FormButton label='Edit Loan' loading={isLoading} />
      </Form>
    </>
  );
};

export default EditLoanModal;
