import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { selectCommon, setModal } from '../../../common/slices/commonSlice';
import { HTTPResponse } from '../../../common/types/commonTypes';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import AddEmployeeModalContent from '../components/AddPayrollContent';
import { IPayrollGetForEdit } from '../types/PayrollTypes';

type Props = {
  setEditOrAdd: React.Dispatch<
    React.SetStateAction<{
      reason: 'ADD' | 'EDIT';
      id?: number | undefined;
    }>
  >;
  editOrAdd: {
    reason: 'ADD' | 'EDIT';
    id?: number | undefined;
  };
  getForEditData: HTTPResponse<IPayrollGetForEdit> | undefined;
};

export default function AddPayroll({
  setEditOrAdd,
  editOrAdd,
  getForEditData,
}: Props) {
  const dispatch = useAppDispatch();
  const commonState = useSelector(selectCommon);

  const showModal = () => {
    dispatch(setModal(true));
  };

  const handleOk = () => {
    dispatch(setModal(false));
  };

  const handleCancel = () => {
    dispatch(setModal(false));
  };

  return (
    <div>
      <Button
        type='primary'
        onClick={() => {
          setEditOrAdd({ reason: 'ADD' });
          showModal();
        }}
      >
        Add Employee Salary
      </Button>
      <Modal
        open={commonState.isModal}
        onOk={handleOk}
        width='1100px'
        onCancel={handleCancel}
        style={{ top: 20 }}
        footer={false}
        forceRender
      >
        <FormHeaderTitle
          title={
            editOrAdd.reason === 'ADD'
              ? 'Add Employee Salary'
              : 'Update Employee Salary'
          }
        />
        <AddEmployeeModalContent
          editOrAdd={editOrAdd}
          getForEditData={getForEditData}
        />
      </Modal>
    </div>
  );
}
