import { Col, Form, message, Row, Typography } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import { FormatDateV1 } from '../../../common/utils/common.utils';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import { SelectDataInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import {
  useLazyGetExistingClientsQuery,
  usePostExistingClientMutation,
} from '../api/endpoints/reIssueExistingAirTicketEndpoints';
import NewBillingInfoForm from '../Components/NewBillingInfoForm';
import NewOrExistingBtn from '../Components/NewOrExistingBtn';
import PreviousTicketInfo, {
  IExistingClients,
} from '../Components/PreviousTicketInfo';
import { IInvoice_info_Existing } from '../Type/InoviceReissue.interface';

type Props = {};

const ExistingInvoiceReissue = ({ }: Props) => {
  const [form] = useForm();

  // get invoice id
  const { data: invoiceIdData } = useGetInvoiceNoQuery('ARI');
  const invoiceIdServer = invoiceIdData?.data;
  useEffect(() => {
    if (invoiceIdServer) {
      form.setFieldsValue({
        invoice_no: String(invoiceIdServer),
        invoice_sales_date: dayjs(),
      });
    }
  }, [invoiceIdServer]);

  // ================ existing clients data ==================
  const [exClientDataGet, { data: exClientsData, isLoading, isSuccess }] =
    useLazyGetExistingClientsQuery();

  const existingClient = exClientsData?.data;

  const [existingTicketNo, setExistingTicketNo] = useState<
    { id: number; title: string }[]
  >([]);

  useEffect(() => {
    const filteredList =
      existingClient
        ?.filter((item) => item.airticket_id)
        .map((item) => ({
          id: item.airticket_id,
          title: item.airticket_ticket_no,
        })) || [];
    setExistingTicketNo(filteredList);
  }, [existingClient]);

  // ======================= get form value ===================
  const [selectedClient, setSelectedClient] = useState<IExistingClients[]>([]);
  const handleSelectTicket = () => {
    const ticketNo = form.getFieldValue('ticket_no');
    const selectedTicket = existingClient?.find(
      (item) => item.airticket_id === ticketNo
    );
    if (selectedTicket) {
      setSelectedClient([selectedTicket]);
    }
  };

  const handleClientChange = () => {
    const client_id = form.getFieldValue('invoice_combclient_id');
    if (client_id) {
      exClientDataGet(client_id);
    }
  };

  // @Set form value
  const airticket_client_charge = useWatch(['airticket_client_charge'], form);
  const airticket_vendor_charge = useWatch(['airticket_vendor_charge'], form);
  const airticket_service_charge = useWatch(['airticket_service_charge'], form);
  useEffect(() => {
    form.setFieldsValue({
      airticket_profit:
        Number(airticket_client_charge || 0) -
        Number(airticket_vendor_charge || 0) +
        Number(airticket_service_charge || 0),
    });
  }, [
    airticket_client_charge,
    airticket_vendor_charge,
    airticket_service_charge,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      passport_name: selectedClient[0]?.passport_name,
    });
  }, [selectedClient]);

  const [
    postExistingClient,
    {
      isLoading: postLoading,
      isError: postError,
      isSuccess: postSuccess,
      error,
      data,
    },
  ] = usePostExistingClientMutation();
  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  const user = useSelector(selectUser);

  // @Form submit
  const onFinish = (values: any) => {
    const invoice_info: IInvoice_info_Existing = {
      airticket_ticket_no: values?.airticket_ticket_no,
      invoice_combclient_id: values?.invoice_combclient_id,
      invoice_sales_man_id: values?.invoice_sales_man_id,
      invoice_sales_date: values?.invoice_sales_date,
      invoice_due_date: values?.invoice_due_date,
      airticket_profit: values?.airticket_profit,
      invoice_no: values?.invoice_no,
      invoice_created_by: user?.user_id as number,
      airticket_comvendor: values?.airticket_comvendor,
      airticket_journey_date: FormatDateV1(values?.airticket_journey_date),
      airticket_return_date: FormatDateV1(values?.airticket_return_date),
      airticket_vendor_charge: values?.airticket_vendor_charge || 0,
      airticket_client_charge: values?.airticket_client_charge || 0,
      airticket_service_charge: values?.airticket_service_charge || 0,
      invoice_note: values?.invoice_note,
      airticket_existing_invoiceid: selectedClient[0].airticket_id,
      passport_name: selectedClient[0].passport_name,
      airticket_pnr: selectedClient[0].airticket_pnr as string,
      existing_ticket_no: selectedClient[0].airticket_ticket_no,
    };

    const body = {
      invoice_info,
    };

    checkCreditLimit({
      amount: invoice_info?.airticket_client_charge,
      combClient: invoice_info.invoice_combclient_id,
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        postExistingClient(body);
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (postSuccess) {
      toasterNotification(
        'success',
        'Invoice airticket existing client added successfully!'
      );
      navigate(`/reissueairticket/details/${data?.data?.invoice_id}`);
    }
    if (postError) {
      toasterNotification('error', 'Thare was an error');
    }
  }, [postLoading]);
  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice (Air Ticket)', 'Existing']} />

      {/* @NEW CLIENT OR EXISTING CLIENT */}
      <Row justify='center'>
        <NewOrExistingBtn />
      </Row>

      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
        layout='vertical'
      >
        <InvoiceHeaderForm
          clientOnChange={() => {
            handleClientChange();
          }}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        {/* =================== select ticket no input ============= */}

        <Row justify='center' className='border p-10'>
          <SelectDataInput
            label='Search Ticket'
            data={existingTicketNo}
            size={8}
            placeholder='Search By Ticket No.'
            onChange={() => handleSelectTicket()}
            name='ticket_no'
            required
          />
        </Row>

        {/* ===================== existing client new form ============ */}
        <div className='my-20'>
          <PreviousTicketInfo selectClient={selectedClient} />

          <NewBillingInfoForm form={form} />

          <FormButton label='Submit' />
        </div>
      </Form>
    </ClientsStyle>
  );
};
export default ExistingInvoiceReissue;
const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .topLeftButton {
    margin-right: 0.5rem;
    margin-bottom: auto;
  }
`;
