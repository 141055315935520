import { ColumnsType } from 'antd/lib/table';
import { IAirTicketInfoByNo } from '../../../RefundTypes/RefundTypes';
import { Input, Form } from 'antd';
import { Fixed2 } from '../../../../../common/utils/common.utils';

export const AirTicketInfoUtils = (
  onTableClientRefundChange: Function,
  onTableVendorRefundChange: Function
): ColumnsType<IAirTicketInfoByNo> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Pax Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      render: (value) => (
        <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
          {Number(value || 0)}
        </span>
      ),
    },
    {
      title: 'Sell Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render(value, record, index) {
        return value ? Fixed2(value) : '';
      },
    },
    {
      title: 'Discount',
      dataIndex: 'airticket_discount_total',
      key: 'airticket_discount_total',
    },
    {
      title: 'Refund Charge From Client',
      key: 'operation',
      dataIndex: 'client_charge',
      render: (text, record, index) => (
        <Form.Item
          label={[]}
          name={[index, 'single_client_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (value && record.airticket_client_price) {
                  if (value > Number(record.airticket_client_price)) {
                    return Promise.reject(
                      'Client charge cannot be more than sell price'
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableClientRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_charge !== prevRecord.client_charge;
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },
    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (_, data) => Number(data.airticket_purchase_price),
      align: 'center',
    },
    {
      title: 'Refund Charge Taken By Vendor',
      key: 'operation',
      dataIndex: 'vrefund_charge_amount',
      render: (text, record, index) => (
        <Form.Item
          label={[]}
          name={[index, 'single_vendor_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (value && record.airticket_purchase_price) {
                  if (value > Number(record.airticket_purchase_price)) {
                    return Promise.reject(
                      'Vendor charge cannot be more than purchase price'
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableVendorRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return (
          record.vrefund_charge_amount !== prevRecord.vrefund_charge_amount
        );
      },
    },
  ];
};
