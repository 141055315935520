import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { IAccountInvestments } from '../../AccountsTypes/AccountsTypes';
import { useGetAllInvestmentsQuery } from '../../Api/Endpoints/investmentsEndpoints';
import Investments_Add_modal from '../../modals/Investments/Investments_Add_modal';
import Investments_Edit_modal from '../../modals/Investments/Investments_Edit_modal';
import { ListOfInvestmentsColumn } from '../../utils/Investments/ListOfInvestmentsColumn';
import { rangePresets } from '../../../../common/utils/common.utils';

const Investment = ({ permission }: perProps) => {
  const [editInfo, setEditInfo] = useState<IAccountInvestments | null>(null);
  const dispatch = useAppDispatch();

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const { data, isLoading, isFetching, refetch } = useGetAllInvestmentsQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Accounts', 'Investments']}
        refetch={() => refetch()}
        reloaderSize='small'
      />
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          {permission?.[crud.create] && (
            <Col>
              <Button type='primary' onClick={showModal}>
                <PlusOutlined />
                Add Investment
              </Button>
            </Col>
          )}
        </Row>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonTableHeader
        title=''
        element={
          editInfo ? (
            <Investments_Edit_modal info={editInfo} setNull={setEditInfo} />
          ) : (
            <Investments_Add_modal />
          )
        }
        setNull={setEditInfo}
      />
      <Table
        size='small'
        bordered
        columns={ListOfInvestmentsColumn({
          setEditInfo,
          showModal,
          permission,
          pagination,
        })}
        dataSource={data?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </div>
  );
};

export default Investment;
