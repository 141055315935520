import moment from 'moment';

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function disabledFeatureDate(current: any) {
  // Disable dates after today
  return current && current > moment().endOf('day');
}
