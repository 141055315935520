import { Button, Col, Form, FormInstance, Row } from 'antd';

import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Flight_Details_List_Info from '../../Invoice_Air_Ticket/Components/Flight_Details_List_Info';
import { PlusOutlined } from '@ant-design/icons';

type Props = { form: FormInstance<any> };

export default function InvNonComFlightDetails({ form }: Props) {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Flight Details (Optional) ✈️' />
      <Form.List
        name={['flight_details']}
        initialValue={[
          {
            fltdetails_from_airport_id: undefined,
            fltdetails_to_airport_id: undefined,
            fltdetails_airline_id: undefined,
            fltdetails_flight_no: undefined,
            fltdetails_fly_date: undefined,
            fltdetails_departure_time: undefined,
            fltdetails_arrival_time: undefined,
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index, arr) => (
              <Flight_Details_List_Info
                add={add}
                field={field}
                index={index}
                remove={remove}
                key={index}
                form={form}
                length={arr.length}
              />
            ))}

            {fields.length == 0 && (
              <Row justify={'center'}>
                <Col span={8}>
                  <Form.Item label={[]}>
                    <Button onClick={() => add()} block icon={<PlusOutlined />}>
                      Add New Pax Details
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
}
