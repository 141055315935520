import { ColumnsType } from 'antd/lib/table/interface';

import dayjs from 'dayjs';
import { IClientAitReportDataType } from '../endpoints/ClientAitReportEndpoints';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

const ClientAitReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IClientAitReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Sales Date',
      key: 'date',
      render: (_, record) => {
        return `${dayjs(record.airticket_sales_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.airticket_client_id
              ? `/clients/details/client-${record.airticket_client_id}`
              : `/combineClients/details/${record.airticket_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },

    // {
    //   title: 'Invoice No',
    //   dataIndex: 'invoice_no',
    //   key: 'invoice_no',
    // },

    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
              state={location.pathname}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'AirTicket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'AirTicket Gross Fare',
      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      render: (airticket_gross_fare) => Fixed2(airticket_gross_fare),
    },

    {
      title: 'AirTicket AIT',
      dataIndex: 'airticket_ait',
      key: 'airticket_ait',
    },

    {
      title: 'AirTicket Client Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render: (airticket_client_price) => Fixed2(airticket_client_price),
    },
  ];
};

export default ClientAitReportColumn;
