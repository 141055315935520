import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Fixed2, FormatDate } from '../../../../common/utils/common.utils';
import { SingleLoanDataType } from '../../LoanTypes/LoanTypes';

export const ViewLoanList = (): ColumnsType<SingleLoanDataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, record, index) => {
        return `${index + 1}`;
      },
    },

    {
      title: 'Date',
      key: 'loan_date',
      render: (_, record) => {
        return `${dayjs(record.loan_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Loan Name',
      dataIndex: 'loan_name',
      key: 'loan_name',
    },

    {
      title: 'Loan Type',
      dataIndex: 'loan_type',
      key: 'loan_type',
    },

    {
      title: 'Installment',
      key: 'loan_installment',
      render: (_, record) => {
        const {
          loan_type,
          loan_installment_duration,
          loan_installment_per_day,
          loan_installment_per_month,
          loan_installment_type,
        } = record;

        function installment() {
          if (loan_installment_type === 'MONTHLY') {
            return (
              Number(loan_installment_per_month) +
              ' Tk' +
              '/' +
              'months ' +
              ' (' +
              Number(loan_installment_duration) +
              ' Months)'
            );
          } else if (loan_installment_type === 'DAILY') {
            return (
              Number(loan_installment_per_day) +
              ' Tk' +
              '/' +
              'day ' +
              ' (' +
              Number(loan_installment_duration) +
              ' Days)'
            );
          } else {
            return 'NO';
          }
        }

        return installment();
      },
    },

    {
      title: 'Interest (%)',
      dataIndex: 'loan_interest_percent',
      key: 'loan_interest_percent',
      render: (loan_interest_percent) => {
        return Number(loan_interest_percent) || 0;
      },
    },

    {
      title: 'Loan Amount',
      dataIndex: 'loan_payable_amount',
      align: 'right',
      key: 'loan_payable_amount',
      // render: (text) => (text ? Fixed2(text).toLocaleString() : 'N/A'),
      render(_, record) {
        return `${
          record.loan_payable_amount != undefined
            ? Fixed2(record.loan_payable_amount).toLocaleString()
            : Fixed2(record.loan_receivable_amount).toLocaleString()
        }`;
      },
    },

    /*    {
      title: 'Due Amount',
      dataIndex: 'loan_due_amount',
      key: 'loan_due_amount',
      align: 'right',
      render(_, record) {
        return `${
          record.loan_due_amount != undefined
            ? Fixed2(record.loan_due_amount).toLocaleString()
            : Fixed2(record.loan_due_amount).toLocaleString()
        }`;
      },
    }, */
  ];
};

export const viewLoanPaymentItem: ColumnsType<any> = [
  {
    title: <span className='table-column-title'>Sl.</span>,
    render(value, record, index) {
      return <>{index + 1}</>;
    },
    align: 'center',
  },

  {
    title: <span className='table-column-title'> Date </span>,
    key: 'date',
    dataIndex: 'date',
    render: (curr) => <span className='table-column'>{FormatDate(curr)}</span>,
  },
  {
    title: <span className='table-column-title'> Authority</span>,
    key: 'authority_name',
    dataIndex: 'authority_name',
  },

  {
    title: <span className='table-column-title'>Account Name</span>,
    dataIndex: 'account_name',
    key: 'account_name',
  },

  {
    title: <span className='table-column-title'>Payment Method</span>,
    dataIndex: 'method',
    key: 'method',
    align: 'left',
    render: (text: string) => {
      let method = 'CASH';
      if (+text === 2) {
        method = 'BANK';
      } else if (+text === 3) {
        method = 'MOBILE BANKING';
      }

      return <span className='table-column'>{method} </span>;
    },
  },

  {
    title: <span className='table-column-title'> Total Amount </span>,
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    render: (curr) => Fixed2(curr).toLocaleString(),
  },
];
