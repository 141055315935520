import { Button, Drawer, Form, message, Row, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import {
  useGetPayrollAdvanceDetailsQuery,
  usePatchPayrollAdvanceMutation,
} from '../api/endpoints/AdvancePayrollEndpoints';
import { IPayrollAdvanceInfo } from '../Pages/PayrollAdvance';
import {
  IPayrollAdvanceCreate,
  IPayrollAdvancePatch,
} from '../types/PayrollTypes';
import PayrollAdvanceForm from './PayrollAdvanceForm';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';

type Props = {
  setInfo: React.Dispatch<React.SetStateAction<IPayrollAdvanceInfo>>;
  info: IPayrollAdvanceInfo;
};

const EditEmployeeAdvance = ({ info, setInfo }: Props) => {
  const [form] = useForm();

  const [
    patchEdit,
    { isSuccess: patchSucc, isError: patchErr, isLoading: patchLoading },
  ] = usePatchPayrollAdvanceMutation();

  const { isLoading, data, isSuccess } = useGetPayrollAdvanceDetailsQuery(
    {
      id: String(info.id),
    },
    { skip: !info.id }
  );

  useEffect(() => {
    if (isSuccess && data) {
      const details = data.data;

      form.setFieldsValue({
        ...details,
        tea_date: dayjs(details?.tea_date),
        tea_month: dayjs(details?.tea_month),
        tea_withdraw_date: dayjs(details?.tea_withdraw_date),
      });
    }
  }, [data, isSuccess]);

  const onClose = () => {
    setInfo((prev) => ({ ...prev, id: undefined, open: false }));
  };

  const handleFinished = (e: IPayrollAdvanceCreate) => {
    const body: IPayrollAdvancePatch = {
      ...e,
      tea_id: Number(info.id),
    };
    patchEdit(body);

    // createPayAdv(e);
  };

  useEffect(() => {
    if (patchSucc) {
      message.success('Payroll updated');
      onClose();
    } else if (patchErr) {
      message.error('Something went wrong');
    }
  }, [patchSucc, patchErr]);

  return (
    <Drawer
      width={450}
      title='Edit Payroll Advance'
      onClose={onClose}
      open={info.open}
    >
      {patchLoading && <LoadingIndicator />}
      <Form
        disabled={isLoading}
        form={form}
        layout='vertical'
        onFinish={handleFinished}
      >
        <PayrollAdvanceForm form={form} editMode />

        <Row justify={'end'}>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Update
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditEmployeeAdvance;
