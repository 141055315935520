import { BookFilled } from '@ant-design/icons';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  ALLTICKETNO,
  OVERALLPROFITLOSS,
  PRODUCTWISEPROFITLOSS,
  SESSIONWISEPROFITLOSS,
  SINGALPRODUCTWISEPROFITLOSS,
  TICKETWISEPROFITLOSS,
  VISAWISEPROFITLOSS,
} from '../../Api/constants';
import {
  IAllProductWiseProfitDataType,
  IAllProductWiseProfitFormDataType,
} from '../types/allProductWiseProfitLossTypes';
import {
  IProfitLossDataType,
  IProfitLossFormData,
} from '../types/overAllprofitLossTypes';
import {
  ISessionProfitFormDataType,
  ISessionWiseProfitLossDataType,
} from '../types/sessionWiseProfitLossTypes';
import {
  IProductWiseProfitLoseDataType,
  IProfitLossSingleProductFormData,
} from '../types/singalProductWiseProfitLos';
import {
  ITicketDataType,
  ITicketWiseProfitLossDataType,
  ITicketWiseProfitLossFormDataType,
} from '../types/ticketWiseProfitLossTypes';
import {
  IVisaWiseFormData,
  IVisawiseProfitLoss,
} from '../types/visaWiseProfitLossTypes';
import { api } from './../../../../app/baseQuery';
export const profitLossEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get Over all profit loss reports
    getOverAllProfitloss: build.query<
      HTTPResponse<IProfitLossDataType>,
      IProfitLossFormData
    >({
      query: (body) => ({
        url: `/report/overall-profit-loss?${body.date_range}`,
      }),
      providesTags: [{ type: 'OverAllProfitLoss', id: OVERALLPROFITLOSS }],
    }),
    //get all visa wise profitLoss
    getAllVisaWiseProfitloss: build.query<
      HTTPResponse<IVisawiseProfitLoss[]>,
      {
        visa_id: string | number;
        query: string;
      }
    >({
      query: ({ visa_id, query }) => ({
        url: `/report/visa-profit-loss/${visa_id}${query}`,
      }),
      providesTags: [{ type: 'VisaWiseProfitLoss', id: VISAWISEPROFITLOSS }],
    }),

    // get all single product wise profit loss
    getSingleProductProfitloss: build.query<
      HTTPResponse<IProductWiseProfitLoseDataType[]>,
      IProfitLossSingleProductFormData
    >({
      query: (body) => ({
        url: `/report/product-profit-loss/${body.product_id}?${body.date_range}`,
        method: 'GET',
      }),
      providesTags: [
        { type: 'SingalProductWiseProfit', id: SINGALPRODUCTWISEPROFITLOSS },
      ],
    }),

    // get all Product wise profit loss
    getAllProductWiseProfitLoss: build.query<
      HTTPResponse<IAllProductWiseProfitDataType[]>,
      IAllProductWiseProfitFormDataType
    >({
      query: (body) => ({
        url: `/report/all-product-wise-profit-loss?${body.date_range}`,
      }),
      providesTags: [
        { type: 'ProductWiseProfitLoss', id: PRODUCTWISEPROFITLOSS },
      ],
    }),
    // session wise ProfitLoss
    getSessionWiseProfitLoss: build.query<
      HTTPResponse<ISessionWiseProfitLossDataType>,
      ISessionProfitFormDataType
    >({
      query: (body) => ({
        url: `/report/session-wise-profit-loss?year=${body.year}`,
      }),
      providesTags: [
        { type: 'SessionWiseProfitLoss', id: SESSIONWISEPROFITLOSS },
      ],
    }),
    // Ticket wise ProfitLoss
    getTicketWiseProfit: build.query<
      HTTPResponse<ITicketWiseProfitLossDataType[]>,
      ITicketWiseProfitLossFormDataType & {
        current?: number;
        pageSize?: number;
      }
    >({
      query: (body) => ({
        url: `/report/ticket-wise-profit-loss/${body.ticket_id}?${body.date_range}&page=${body.current}&size=${body.pageSize}`,
      }),
      providesTags: [
        { type: 'TicketWiseProfitLoss', id: TICKETWISEPROFITLOSS },
      ],
    }),
    //get all Ticket
    getAllTicketNo: build.query<HTTPResponse<ITicketDataType[]>, void>({
      query: (body) => ({
        url: `/report/all-ticket-info`,
      }),
      providesTags: [{ type: 'AllTicketNo', id: ALLTICKETNO }, 'invoices'],
    }),
    //get all Visa
    getAllVisaList: build.query<
      HTTPResponse<
        {
          invcat_id: number;
          invcat_parentcat: string;
          invcat_title: string;
        }[]
      >,
      void
    >({
      query: (body) => ({
        url: `/report/visa-list`,
      }),
      providesTags: [{ type: 'AllTicketNo', id: ALLTICKETNO }, 'invoices'],
    }),

    getExcelOverAllProfitLoss: build.query<any, { query: string }>({
      query: ({ query }) => ({
        url: `/report/profitLoss/overAllProfitLoss?${query}`,
        responseHandler: async (response) => {
          const filename = `overallProfitLoss.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),

    getGroupWiseProfitLoss: build.query<
      HTTPResponse<IVisawiseProfitLoss[]>,
      {
        group_id: string | number;
        query: string;
      }
    >({
      query: ({ group_id, query }) => ({
        url: `/report/group-profit-loss/${group_id}${query}`,
      }),
      providesTags: [{ type: 'VisaWiseProfitLoss', id: VISAWISEPROFITLOSS }],
    }),
  }),
});

export const {
  useLazyGetOverAllProfitlossQuery,
  useLazyGetAllVisaWiseProfitlossQuery,
  useLazyGetSingleProductProfitlossQuery,
  useLazyGetAllProductWiseProfitLossQuery,
  useLazyGetSessionWiseProfitLossQuery,
  useLazyGetTicketWiseProfitQuery,
  useGetTicketWiseProfitQuery,
  useGetAllTicketNoQuery,
  useGetAllVisaListQuery,
  useLazyGetExcelOverAllProfitLossQuery,
  useLazyGetGroupWiseProfitLossQuery,
} = profitLossEndpoints;
