import asyncWrapper from '../../../../app/asyncWrapper';
import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { CHEQUE_PAYMENT } from '../../../Cheque_Management/api/Constants/constants';
import {
  IOthersRefundClientDataType,
  IOthersRefundClientLogsType,
  IOthersRefundClientSingleDataType,
  IOthersRefundForm,
  IOthersRefundInfo,
  IOthersRefundInvoicebyID,
  IRefundDeleteRestoreBody,
  IRefundRestoreBody,
} from '../../RefundTypes/RefundTypes';
import {
  Other_Refund_CLIENT_LOGS,
  OTHERS_REFUND_CLIENT,
} from '../Constants/Contstants';

export const othersRefundClientEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all Others Refund by client ID
    getAllOthersRefundByID: build.query<
      HTTPResponse<IOthersRefundInvoicebyID[]>,
      string
    >({
      query: (id) => ({ url: `/refund/other-refund-client/${id}` }),
      providesTags: [
        { type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT },
        { type: 'invoice-other' },
      ],
    }),

    //search others refund info by others invoice id
    searchRefundInfo: build.query<
      HTTPResponse<{
        billing_info: IOthersRefundInfo[];
        invoice_category_id: number;
      }>,
      number
    >({
      query: (id) => ({
        url: `/refund/other-billing-info/${id}`,
      }),
      providesTags: () => [
        { type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT },
      ],
    }),

    // get all others refund client
    getAllOthersRefundClient: build.query<
      HTTPResponse<IOthersRefundClientDataType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `/refund/other-refund?${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
      }),
      providesTags: [{ type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT }],
    }),

    // get single others refund by id
    getSingleOthersRefundClient: build.query<
      HTTPResponse<IOthersRefundClientSingleDataType>,
      number
    >({
      query: (id) => ({ url: `/refund/other-refund/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'OthersRefundClient', id: id } as const,
      ],
    }),

    // get all others client trashed Refunds
    getAllTrashedOthersRefundClient: build.query<
      HTTPResponse<IOthersRefundClientDataType[]>,
      void
    >({
      query: () => ({ url: `/refund/other-refund/trashed` }),
      providesTags: [{ type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT }],
    }),

    //get others refund client logs
    getOthersRefundClientLogs: build.query<
      HTTPResponse<IOthersRefundClientLogsType[]>,
      number
    >({
      query: (id) => ({ url: `/refund/other-refund-client/logs/${id}` }),
      providesTags: [
        { type: 'OthersRefundClient', id: Other_Refund_CLIENT_LOGS },
      ],
    }),

    //create others refund client
    createOthersRefundClient: build.mutation<void, IOthersRefundForm>({
      query: (body) => ({
        url: '/refund/other-refund',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [
        { type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT },
        { type: 'OthersRefundClient', id: Other_Refund_CLIENT_LOGS },
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
      ],
    }),

    //delete others refund client
    deleteOthersRefundClient: build.mutation<void, IRefundDeleteRestoreBody>({
      query: (body) => ({
        url: `/refund/other-refund/${body.refund_id}`,
        method: 'DELETE',
        body: { refund_deleted_by: body.deleted_by },
      }),

      onQueryStarted: (arg, { queryFulfilled }) => {
        asyncWrapper(async () => {
          await queryFulfilled;
        });
      },
      invalidatesTags: () => [
        { type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT },
        { type: 'OthersRefundClient', id: Other_Refund_CLIENT_LOGS },
        { type: 'Clients', id: 'CLIENT' },
      ],
    }),

    //restore others refund client
    restoreOthersRefundClient: build.mutation<void, IRefundRestoreBody>({
      query: (body) => ({
        url: `/refund/other-refund/${body.refund_id}`,
        method: 'PATCH',
        body: { refund_restored_by: body.refund_restored_by },
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification(
            'success',
            'Others Refund(Client) Restored Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'OthersRefundClient', id: OTHERS_REFUND_CLIENT },
        { type: 'OthersRefundClient', id: Other_Refund_CLIENT_LOGS },
        { type: 'Clients', id: 'CLIENT' },
      ],
    }),
  }),
});

export const {
  useGetAllOthersRefundByIDQuery,
  useLazyGetAllOthersRefundByIDQuery,
  useGetAllOthersRefundClientQuery,
  useLazyGetAllOthersRefundClientQuery,
  useGetSingleOthersRefundClientQuery,
  useGetOthersRefundClientLogsQuery,
  useLazyGetAllTrashedOthersRefundClientQuery,
  useLazySearchRefundInfoQuery,
  useCreateOthersRefundClientMutation,
  useDeleteOthersRefundClientMutation,
  useRestoreOthersRefundClientMutation,
} = othersRefundClientEndpoints;
