import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';

export const VoidApiEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all loan
    getDetailsForVoid: build.query<HTTPResponse<voidItemsList>, string>({
      query: (id) => ({
        url: `/invoice-air-ticket/invoice-details-for-void/${id}`,
      }),
    }),

    postVoid: build.mutation<
      HTTPResponse<void>,
      { body: IPostVoid; id: string | number }
    >({
      query: ({ id, body }) => ({
        url: `/invoice-air-ticket/void/${id}`,
        body: body,
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: 'Void', id: 'LIST' }, 'invoices'],
    }),
  }),
});

export const { useLazyGetDetailsForVoidQuery, usePostVoidMutation } =
  VoidApiEndpoints;

export interface voidItemsList {
  invoice_id: number;
  invoice_no: string;
  client_name: string;
  net_total: string;
  vendors: voidItemsVendor[];
}

export interface voidItemsVendor {
  vendor_name: string;
  comb_vendor: string;
  cost_price: string;
  airticket_ticket_no: string;
}

export interface IPostVoid {
  client_charge: number;
  invoice_vendors: {
    comb_vendor: string;
    vendor_charge: number;
  }[];
}
