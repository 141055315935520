import { Col, Form, Input } from 'antd';
import React from 'react';

type Props = {
  name: string;
  required?: boolean;
  number?: boolean;
  defaultValue?: string | number;
  label: string;
};

const VendorFormItem = ({ name, label, required, number }: Props) => {
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={6}>
      <Form.Item
        name={name}
        label={`${label}:`}
        rules={[
          { required: required || false, message: `${label} is required!` },
          number
            ? {
                message: 'Only digits allowed',
                validator: (_, value) => {
                  if (/^[0-9]*$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Non-numeric values are not allowed');
                  }
                },
              }
            : {},
        ]}
      >
        <Input placeholder={label} />
      </Form.Item>
    </Col>
  );
};

export default VendorFormItem;
