import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  AirTicket_InvoicePostType,
  GetAllInvoicesDataType,
  IInvoiceAirTaxRefundPost,
  IInvoiceAirticketTaxRefundInfo,
  ISearchBody,
  ISearchInvoiceData,
  getDataByPnr,
} from '../../types/invoiceAirTicketTypes';

export const airticketEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // SEARCH INVOICE
    searchInvoice: build.mutation<
      HTTPResponse<ISearchInvoiceData[]>,
      ISearchBody
    >({
      query: (body) => ({
        url: 'invoice-air-ticket/search-invoice-data',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['invoices'],
    }),

    getAllAirticketInvoice: build.query<
      HTTPResponse<GetAllInvoicesDataType[]>,
      void
    >({
      query: () => ({ url: '/invoice-air-ticket/all_by_cate/1' }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    // Get Data By PNR....

    getDataByPnr: build.query<HTTPResponse<getDataByPnr>, string>({
      query: (pnr) => ({
        url: `/invoice-air-ticket/pnr-details/${pnr}`,
      }),
    }),

    // TICKET EXIST OR NOT
    ticketIsExist: build.query<HTTPResponse<boolean>, string>({
      query: (airticket_ticket_no) => ({
        url: `/invoice-air-ticket/is-exist/${airticket_ticket_no}`,
      }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    /**
     *
     * GET VIEW INVOICE DETAILS
     */
    getViewInvoice: build.query<HTTPResponse<IViewInvoiceDetails>, number>({
      query: (id) => ({
        url: `/invoice-air-ticket/view/${id}`,
      }),
      providesTags: () => ['invoice-hajji', 'invoices', 'AirticketInvoice'],
    }),

    getSimilarAirticketInvoice: build.query<
      HTTPResponse<AirTicket_InvoicePostType>,
      string | Number | undefined
    >({
      query: (id) => ({
        url: '/invoice-air-ticket/' + id,
      }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    // DELETE
    deleteAirticket: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/invoice-air-ticket/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    /**
     * @COMMON_INVOICE_QUERY
     *
     */

    commonInvoiceNo: build.query<HTTPResponse<{ invoice_no: number }>, number>({
      query: (categoryId) => ({
        url: `/invoice-air-ticket/generate-invoice-no/${categoryId}`,
      }),
      providesTags: () => ['invoices', 'InvoiceHajj'],
    }),

    getAllInvoices: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/invoice-air-ticket?${query || ''}`,
      }),
      providesTags: () => [
        'notifications',
        'invoices',
        'InvoiceHajj',
        'invoices_from_money_receipt',
      ],
    }),

    getIsTicketNumberExist: build.query<HTTPResponse<boolean>, string>({
      query: (ticketNumber) => ({
        url: `/invoice-air-ticket/is-exist/${ticketNumber}`,
      }),
      providesTags: () => ['AirticketInvoice'],
    }),

    postInvoiceAirticket: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      AirTicket_InvoicePostType
    >({
      query: (body) => ({
        url: '/invoice-air-ticket',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'invoices',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: MONEY_RECEIPT_TAG },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),
    editInvoiceAirticket: build.mutation<
      HTTPResponse<void>,
      { body: AirTicket_InvoicePostType; airticketInvoiceId: string }
    >({
      query: ({ body, airticketInvoiceId }) => ({
        url: `/invoice-air-ticket/${airticketInvoiceId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        'invoices',
      ],
    }),

    getInvoiceActivityLog: build.query<
      HTTPResponse<
        {
          user_full_name: string;
          history_activity_type: string;
          history_create_date: string;
          invoice_no: string;
          invoicelog_content: string;
        }[]
      >,
      number
    >({
      query: (id) => ({
        url: `/invoice-air-ticket/view-invoice-activity/${id}`,
      }),
      providesTags: () => ['invoices'],
    }),

    // Void Invoice AirTicket
    voidInvAirTicket: build.mutation<
      void,
      {
        body: {
          invoice_has_deleted_by: number | undefined;
          void_charge: number;
          id: number;
        };
      }
    >({
      query: ({ body }) => ({
        url: `/invoice-air-ticket/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        'invoices',
      ],
    }),

    //COMMON DELETE INVOICE
    CommonDeleteInvoice: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number; url?: string }
    >({
      query: ({ id, user_id, url }) => ({
        url: `/${url}/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'AirticketInvoice',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    sendEmail: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      { email: string; id: number }
    >({
      query: (arg) => ({
        url: `invoice-air-ticket/send-email/${arg.id}`,
        method: 'POST',
        body: { email: arg.email },
      }),
    }),

    // ----------------------INVOICE AIR-TICKET TAX REFUND INFO -----------------

    getInvoiceAirTicketTaxRefundInfo: build.query<
      HTTPResponse<IInvoiceAirticketTaxRefundInfo[]>,
      number
    >({
      query: (id) => ({ url: `invoice-air-ticket/tax-refund/${id}` }),
      providesTags: ['AirticketInvoice', 'invoices'],
    }),

    postInvoiceAirTicketTaxRefundInfo: build.mutation<
      HTTPResponse<void>,
      IInvoiceAirTaxRefundPost
    >({
      query: (body) => ({
        url: `invoice-air-ticket/tax-refund`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['AirticketInvoice', 'invoices'],
    }),
  }),
});

export const {
  useGetAllAirticketInvoiceQuery,
  useLazyTicketIsExistQuery,
  useGetSimilarAirticketInvoiceQuery,
  useLazyGetSimilarAirticketInvoiceQuery,
  useDeleteAirticketMutation,
  useGetAllInvoicesQuery,
  useLazyGetAllInvoicesQuery,
  useCommonInvoiceNoQuery,
  useLazyGetIsTicketNumberExistQuery,
  usePostInvoiceAirticketMutation,
  useGetViewInvoiceQuery,
  useEditInvoiceAirticketMutation,
  useGetInvoiceActivityLogQuery,
  useSearchInvoiceMutation,
  useVoidInvAirTicketMutation,
  useCommonDeleteInvoiceMutation,
  useSendEmailMutation,
  useLazyGetDataByPnrQuery,
  useGetInvoiceAirTicketTaxRefundInfoQuery,
  usePostInvoiceAirTicketTaxRefundInfoMutation,
} = airticketEndpoints;
