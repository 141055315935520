import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewHajjiInfo,
  IViewInvoiceDetails,
} from '../../../common/types/commonInterfaces';
import ClientInfo from '../../../components/common/Invoice/ClientInfo';
import CostViewSubTotal from '../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { pagination } from '../../../common/utils/common.utils';
import CostViewSubTotalUmrahHajjPreReg from './CostViewSubTotalHajjPreReg';

type Props = {
  invoiceData: IViewInvoiceDetails;
};

const hajjiColumn: ColumnsType<IViewHajjiInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Name',
    dataIndex: 'hajiinfo_name',
    key: 'hajiinfo_name',
  },
  {
    title: 'Mobile',
    dataIndex: 'hajiinfo_mobile',
    key: 'hajiinfo_mobile',
    align: 'right',
  },
  {
    title: 'Tracking',
    dataIndex: 'hajiinfo_tracking_number',
    key: 'hajiinfo_tracking_number',
    align: 'right',
  },
  {
    title: 'Vouchar',
    dataIndex: 'haji_info_vouchar_no',
    key: 'haji_info_vouchar_no',
    align: 'right',
  },
  {
    title: 'Serial',
    dataIndex: 'hajiinfo_serial',
    key: 'hajiinfo_serial',
    align: 'right',
  },
  {
    title: 'Pre Reg. Year',
    dataIndex: 'haji_info_reg_year',
    key: 'haji_info_reg_year',
    align: 'right',
  },
  {
    title: 'Possible Hajj Year',
    dataIndex: 'haji_info_possible_year',
    key: 'haji_info_possible_year',
    align: 'right',
  },
];

const billingColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: 'SL',
    render: (_, data, index) => <> {index + 1} </>,
    align: 'center',
  },
  {
    title: 'Product Name',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'Pax Name',
    dataIndex: 'pax_name',
    key: 'pax_name',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'billing_quantity',
    key: 'billing_quantity',
    align: 'right',
  },
  {
    title: 'Unit price',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    width: '150px',
    align: 'right',
  },

  {
    title: 'Total Sales',
    dataIndex: 'billing_subtotal',
    key: 'billing_subtotal',
    align: 'right',
  },
  {
    title: 'Cost Price',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    align: 'right',
    render: (_, data) => Number(data.billing_cost_price),
  },
  {
    title: 'Total Cost',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    align: 'right',
    render: (text, record) => {
      const quantity = record.billing_quantity;
      const costPrice = record.billing_cost_price;
      if (costPrice != null) {
        const totalPrice = quantity * costPrice;
        return <span>{totalPrice}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },

  {
    title: 'Profit',
    dataIndex: 'billing_profit',
    key: 'billing_profit',
    align: 'right',
    render: (value) => (
      <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
        {Number(value || 0)}
      </span>
    ),
  },
  {
    title: 'Vendor Name',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    align: 'center',
  },
];

const ViewInvoiceHajjPreRegDetails = ({ invoiceData }: Props) => {
  const { haji_information, billing_information } = invoiceData;
  return (
    <>
      {haji_information?.length ? (
        <>
          <FormHeaderTitle title='HAJJI INFORMATION' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.hajiinfo_tracking_number}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            pagination={false}
            dataSource={haji_information}
            columns={hajjiColumn}
          />
        </>
      ) : (
        <></>
      )}
      {billing_information && (
        <>
          <FormHeaderTitle title='BILLING INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.product_name}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={billing_information}
            pagination={false}
            columns={billingColumn}
            style={{ marginBottom: '1rem' }}
          />
        </>
      )}

      <CostViewSubTotalUmrahHajjPreReg invoice_info={invoiceData} />
    </>
  );
};

export default ViewInvoiceHajjPreRegDetails;
