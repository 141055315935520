import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../auth/states/userSlice';
import useGetSearchColumnProps from '../../../../../../common/hooks/SearchData';
import {
  crud,
  IPermission,
  ISetState,
} from '../../../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../../../common/utils/ToasterNotification';
import {
  IAccountIncentiveIncome,
  IAccountIncentiveIncomeDelResBody,
} from '../../../../AccountsTypes/AccountsTypes';
import {
  useDeleteIncentiveIncomeMutation,
  useRestoreIncentiveIncomeMutation,
} from '../../endpoints/incentiveIncomeEndpoints';
import { Link } from 'react-router-dom';
import Incentive_Income_Edit_Modal from '../../modal/vendor/Incentive_Income_Edit_Modal';

export const ListOfIncentiveIncomeColumn = ({
  queryData,
  setEditInfo,
  showModal,
  checkButtonState,
  permission,
}: {
  setEditInfo: ISetState<IAccountIncentiveIncome | null>;
  showModal: () => void;
  permission?: IPermission;
  checkButtonState?: boolean;
  queryData: {
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAccountIncentiveIncome> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const searchColumnProps = useGetSearchColumnProps<IAccountIncentiveIncome>();
  const user = useSelector(selectUser);
  const [
    deleteIncentive,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteIncentiveIncomeMutation();
  const [restoreIncentive, { isError: isRestoreError }] =
    useRestoreIncentiveIncomeMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isDeleteError) {
      setColumnIndex(null);
      toasterNotification('error', 'Something went wrong');
    }
  }, [isDeleteError, isSuccess]);

  const handleDelete = async (
    values: IAccountIncentiveIncome,
    index: number
  ) => {
    const body: IAccountIncentiveIncomeDelResBody = {
      incentive_id: values.incentive_id,
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteIncentive(body);
  };
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'incentive_date',
      render: (_, record) => {
        return `${dayjs(record.incentive_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Vouchar No',
      key: 'incentive_vouchar_no',
      dataIndex: 'incentive_vouchar_no',
    },
    {
      title: 'Transaction Type',
      key: 'transaction_type',
      render: () => {
        return `Incentive Income`;
      },
    },
    {
      title: 'Transaction Type',
      key: 'transaction_type',
      render: () => {
        return `Incentive Income`;
      },
    },
    {
      title: 'Transaction Details',
      key: 'transaction_details',
      render: (_, record) =>
        record.incentive_adjust_bill == 'NO' ? (
          ` ${record.account_name}  ${
            record.account_branch_name ? ', ' + record.account_branch_name : ''
          }`
        ) : (
          <>Bill Adjustment</>
        ),
    },
    {
      title: 'Amount',
      dataIndex: 'incentive_amount',
      key: 'incentive_amount',
    },
    {
      title: 'Note',
      dataIndex: 'incentive_note',
      key: 'incentive_note',
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {!checkButtonState && (
            <Link
              to={`/accounts/incentive/vendor/view/${record.incentive_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}
          {!checkButtonState && permission?.[crud.update] && (
            <Incentive_Income_Edit_Modal id={record.incentive_id} />
          )}

          {permission?.[crud.delete] && record.incentive_is_deleted === 0 && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
