import React from 'react';
import { useBestClientQuery } from '../Api/Endpoints/DashboardEndpoints';
import BestClientMonth from './BestClientMonth';
import { Col, Row } from 'antd';
import BestClientYear from './BestClientYear';

const BestClient = ({ isDarkMode }: any) => {
  const { data } = useBestClientQuery();

  return (
    <>
      <Col xs={24} sm={24} md={12} xl={8}>
        <BestClientMonth data={data?.data?.monthly} isDarkMode={isDarkMode} />
      </Col>
      <Col xs={24} sm={24} md={24} xl={8}>
        <BestClientYear data={data?.data?.yearly} isDarkMode={isDarkMode} />
      </Col>
    </>
  );
};

export default BestClient;
