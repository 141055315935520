import { Card, Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { RootState } from '../../../../app/store';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  FormButton,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectAgency } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetForEditTransferInQuery,
  usePostTransferOutMutation,
  useUpdateTransferOutMutation,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import HajiInfoList from '../../Components/TransferInOut/HajiInfoList';
import {
  HajiTransferInPostType,
  HajjInformationSubmitAPI,
} from '../../Types/HajjiManagement.interface';

type Props = {
  reason: 'ADD_NEW' | 'EDIT';
  openFrom: 'TRANSFER_IN' | 'TRANSFER_OUT';
};

function TransferOut_Add({ reason, openFrom }: Props) {
  const user = useSelector((state: RootState) => state.user);
  const [form] = Form.useForm();

  // Transfer Out Api's------------------------------
  const [
    postTransferOut,
    { isSuccess: isSuccessTransferOut, isLoading: isLoadingTransferOut },
  ] = usePostTransferOutMutation();

  const [
    updateTransferOut,
    {
      isSuccess: isSuccessTransferOutUpdate,
      isLoading: isLoadingTransferOutUpdate,
    },
  ] = useUpdateTransferOutMutation();

  const [getForEditTransferIn, { data: editableDataTransferIn, isSuccess }] =
    useLazyGetForEditTransferInQuery();

  const { transferInId } = useParams();

  const onFinish = (values: HajiTransferInPostType) => {
    // const formateData =

    const body: HajjInformationSubmitAPI = {
      transfer_created_by: user?.user_id,
      transfer_agent_id: values?.transfer_agent_id,
      transfer_charge: values?.transfer_charge,
      haji_informations: values?.haji_informations?.map((hajiInfo) => {
        return {
          transfertrack_tracking_no:
            hajiInfo.transfertrack_tracking_no.toString(),
          transfertrack_passport_id: hajiInfo.transfertrack_passport_id,
          transfertrack_maharam_id: hajiInfo.transfertrack_maharam_id,
        };
      }),
    };

    switch (reason) {
      case 'ADD_NEW':
        postTransferOut(body);

        break;
      case 'EDIT':
        updateTransferOut({ id: transferInId, data: body });

        break;
      default:
        break;
    }
  };

  const onFinishFailed = (values: any) => {

  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccessTransferOut) {
      form.resetFields();
      toasterNotification('success', 'Successfully Added');
      navigate('/haji/transfer/out_list');
    }

    if (isSuccessTransferOutUpdate) {
      form.resetFields();
      toasterNotification('success', 'Successfully Updated');
      navigate('/haji/transfer/out_list');
    }
  }, [isLoadingTransferOut, isLoadingTransferOutUpdate]);

  useEffect(() => {
    if (reason === 'EDIT' && transferInId) {
      getForEditTransferIn({ id: Number(transferInId) });
    }
  }, [transferInId]);

  useEffect(() => {
    if (reason === 'EDIT' && editableDataTransferIn?.data) {
      form.setFieldsValue({
        ...editableDataTransferIn.data,
      });
    }
  }, [editableDataTransferIn, isSuccess]);

  if (isLoadingTransferOut) {
    return loadingIndicator;
  }
  return (
    <Style>
      <BreadCrumb
        arrOfOption={[
          openFrom === 'TRANSFER_IN' ? 'Transfer In' : 'Transfer Out',
        ]}
      />
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Card>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Row gutter={[10, 5]}>
                <SelectAgency
                  required
                  label='Agency'
                  name={['transfer_agent_id']}
                  size={10}
                  xsSize={24}
                  smSize={12}
                />
                <NumberInput
                  name='transfer_charge'
                  label='Transfer Charge'
                  size={10}
                  smSize={12}
                />
              </Row>
            </Col>
          </Row>
        </Card>

        <Card
          style={{ marginTop: '1rem', padding: '0px' }}
          title='Haji Information'
        >
          {reason === 'ADD_NEW' && (
            <HajiInfoList form={form} reason={'ADD_NEW'} />
          )}
          {reason === 'EDIT' && (
            <HajiInfoList
              form={form}
              reason={'EDIT'}
              editableDataTransferIn={editableDataTransferIn}
            />
          )}
        </Card>
        {reason === 'ADD_NEW' && (
          <FormButton
            label='Save Transfer Data'
            loading={isLoadingTransferOut}
          />
        )}
        {reason === 'EDIT' && (
          <FormButton
            label='Update Transfer Data'
            loading={isLoadingTransferOut}
          />
        )}
      </Form>
      {isLoadingTransferOut && <LoadingIndicator />}
    </Style>
  );
}

export default TransferOut_Add;

const Style = styled.div``;
