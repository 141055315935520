import { Col, Form, InputNumber, Row, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { Fixed2, getImageUrl } from "../../../../common/utils/common.utils";
import {
  DateInput,
  FormInput,
  NumberInput,
  NumberInputCurrencyFormat,
} from "../../../../components/common/FormItem/FormItems";
import {
  SelectAirline,
  SelectAirport,
  SelectAirportV1,
  SelectOptionsInput,
  SelectProductWiseVendors,
} from "../../../../components/common/FormItem/SelectCustomFeilds";
import { useGetAllAirlinesQuery } from "../../../Configuration/airline/Endpoints/airlineEndpoints";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { useGetProductWiseVendorsAndCombinedQuery } from "../../../vendor/api/endpoints/vendorEndpoints";
import { useLazyTicketIsExistQuery } from "../../api/endpoints/airticketInvoiceEndpoints";
import { AitCalculationProps } from "../../pages/NewInvoiceAirTicket";
import {
  AirTicketInvoiceData,
  SeperateValueObject,
} from "../../types/invoiceAirTicketTypes";
import {
  setAit,
  setClientPrice,
  setCommission,
  setProfit,
  setPurchasePrice,
  setTax,
} from "../../utils/ts/handleCalculation";

import { useParams } from "react-router";
import InvoicesCountryCharge from "../../utils/tsx/InvoicesCountryCharge";

type Props = {
  form: FormInstance<any>;
  invoices: AirTicketInvoiceData[];
  editIndex?: number;
  isLoading?: boolean;
};

export default function AirticketTicketDetails({
  form,
  invoices,
  editIndex,
  isLoading,
}: Props) {
  const handleTicketNo = useWatch("airticket_ticket_no", form);
  const { airticketInvoiceId } = useParams();

  const [vendorChange, setVendorChange] = useState(false);
  const [commissionChg, setCommissionChg] = useState(false);

  const [discountPercentValue, setDiscountPercentValue] = useState<number>(0.0);
  const [discountOtherBonusValue, setDiscountOtherBonusValue] =
    useState<number>(0.0);
  const airticket_comvendor = useWatch(["airticket_comvendor"], form);

  const airticket_discount_type:
    | "amount"
    | "percentClientTotal"
    | "percentBaseFareTotal" = useWatch(["airticket_discount_type"], form);

  // VALIDATE INVOICE NO
  const [ticketIsExist, { data: ticketIsExisting }] =
    useLazyTicketIsExistQuery();

  const vendorIdTitle: SeperateValueObject[] = [];
  const { data: vendorData, refetch: refetchVendorData } =
    useGetProductWiseVendorsAndCombinedQuery(106);

  const vendorAllData = vendorData?.data;
  vendorAllData?.forEach((em) =>
    vendorIdTitle.push({
      id: em.vendor_id,
      title: em.vendor_name,
      commission: em?.commission_rate,
      optionalField: em?.vendor_type,
    })
  );

  useEffect(() => {
    const ticketFetcing = setTimeout(async () => {
      if (handleTicketNo) {
        await ticketIsExist(handleTicketNo);
        const ticket = form.getFieldValue("airticket_ticket_no");
        if (ticket) {
          form.validateFields(["airticket_ticket_no"]);
        }
      }
    }, 800);
    return () => clearTimeout(ticketFetcing);
  }, [handleTicketNo]);

  const airelineData = useGetAllAirlinesQuery();
  const aireline = airelineData?.data?.data;
  const airLineId: string[] = [];
  const airlineTitleAndId: SeperateValueObject[] = [];

  aireline?.forEach((el) => {
    airlineTitleAndId.push({ id: el.airline_id, title: el.airline_name });
    airLineId.push(String(el.airline_id));
  });

  useEffect(() => {
    let airticket_airline_id;
    if (airlineTitleAndId.length) {
      const airlineFind = airlineTitleAndId.find(
        (item) =>
          item.title.split("-").slice(-1)[0] ===
          String(handleTicketNo).substring(0, 3)
      );
      if (airlineFind?.id) {
        airticket_airline_id = Fixed2(airlineFind?.id);
      } else {
        airticket_airline_id = form.getFieldValue("airticket_airline_id");
      }
    } else {
      airticket_airline_id = undefined;
    }
    form.setFieldsValue({ airticket_airline_id });
  }, [handleTicketNo]);

  const [vendorSelectsInstanct, setInstanceVendorSelect] = useState<number>();
  useEffect(() => {
    if (vendorSelectsInstanct) {
      form.setFieldValue(
        "airticket_comvendor",
        "vendor-" + vendorSelectsInstanct
      );
    }
  }, [vendorSelectsInstanct]);

  const airticket_commission_percent_from_server = form.getFieldValue(
    "airticket_commission_percent_from_server"
  );

  const airticket_commission_percent_total_server = form.getFieldValue(
    "airticket_commission_percent_total_server"
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (airticket_comvendor) {
      const vendorCommission = vendorIdTitle?.filter(
        (el) =>
          (airticket_comvendor as string).split("-")[0] === el?.optionalField &&
          el?.id == (airticket_comvendor as string).split("-")[1]
      )[0];

      if (airticketInvoiceId && !vendorChange) {
        form.setFieldsValue({
          airticket_commission_percent: vendorCommission?.commission
            ? airticket_commission_percent_from_server
            : 0,
        });
      } else {
        form.setFieldsValue({
          airticket_commission_percent: vendorCommission?.commission
            ? vendorCommission?.commission
            : 0,
        });
      }

      if (airticketInvoiceId && !commissionChg) {
        form.setFieldsValue({
          airticket_commission_percent_total:
            airticket_commission_percent_total_server,
        });
      } else {
        timeoutId = setTimeout(() => {
          handleCalculation({
            commissionType: "percentage",
            isAitManual: true,
            isTaxManual: true,
          });
        }, 100);
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [airticket_comvendor, vendorAllData]);

  // @HANDLE CALCULATION
  const handleCalculation = ({
    commissionType,
    isAitManual,
    isTaxManual,
  }: AitCalculationProps) => {
    const airticket_gross_fare = Fixed2(
      form.getFieldValue("airticket_gross_fare")
    );

    const airticket_base_fare = Fixed2(
      form.getFieldValue("airticket_base_fare")
    );

    // @SET AIT
    if (airticket_gross_fare) {
      if (!isAitManual) setAit(airticket_gross_fare, form);

      // @SET CLIENT PRICE
      setClientPrice({
        form,
        handleCalculation,
        setDiscountPercentValue,
        isAitManual,
      });
    }

    // @SET TAX
    if (
      (airticket_gross_fare || airticket_gross_fare == 0) &&
      (airticket_base_fare || airticket_base_fare == 0)
    ) {
      if (!isTaxManual) setTax({ form });

      // @SET COMMISSION
      setCommission({ commissionType, form });

      // @SET PURCHASE PRICE
      setPurchasePrice({ form, handleCalculation, setDiscountOtherBonusValue });
    }

    // @SET PROFIT
    setTimeout(() => {
      setProfit({ form });
    }, 100);
  };

  useEffect(() => {
    if (typeof editIndex === "number") {
      handleCalculation({});
    }
  }, [editIndex]);

  return (
    <>
      <div className="border p-5 mt-10">
        <FormHeaderTitle title="Ticket Details  🎟️" />
        <Row gutter={[8, 0]} style={{ width: "100%" }}>
          <FormInput
            name="airticket_ticket_no"
            label="Ticket No:"
            required
            size={6}
            mdSize={8}
            smSize={12}
            rules={[
              {
                validator: async (_, value) => {
                  if (
                    (editIndex || editIndex === 0) &&
                    invoices?.length &&
                    value
                  ) {
                    if (invoices[editIndex].airticket_ticket_no === value) {
                      return Promise.resolve();
                    }
                  } else if (invoices?.length && value) {
                    const find = invoices.find(
                      (item) => item?.airticket_ticket_no === value
                    )?.airticket_ticket_no;
                    if (find) {
                      return Promise.reject("Ticket already exist in list!");
                    }
                  } else if (ticketIsExisting?.data && value) {
                    return Promise.reject("Ticket already exist!");
                  } else return Promise.resolve();
                },
              },
            ]}
          />

          <NumberInputCurrencyFormat
            label="Gross Fare (Sale):"
            name="airticket_gross_fare"
            required
            size={6}
            mdSize={8}
            smSize={12}
            onChange={() => handleCalculation({})}
            maxChar={14}
          />

          <NumberInputCurrencyFormat
            label="Base Fare (Buy):"
            name="airticket_base_fare"
            required
            size={6}
            mdSize={8}
            smSize={12}
            onChange={(value) =>
              setTimeout(() => {
                handleCalculation({});
              }, 200)
            }
            maxChar={14}
          />

          <SelectProductWiseVendors
            setInstanceVendorSelect={setInstanceVendorSelect}
            onChange={(e) => setVendorChange(true)}
            label="Vendor:"
            productId={106}
            name="airticket_comvendor"
            required
            dependencies={["invoice_combclient_id"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value: string) {
                  if (getFieldValue("invoice_combclient_id") === value) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                },
                message: "Client and vendor can't be same!",
              }),
            ]}
            size={6}
            isPercentage
          />

          <SelectAirline
            label={"Airline:"}
            name="airticket_airline_id"
            required
            size={6}
          />

          <NumberInput
            label="Commission %:"
            name="airticket_commission_percent"
            placeholder="commission in %"
            size={6}
            onChange={() =>
              handleCalculation({
                commissionType: "percentage",
                isAitManual: true,
                isTaxManual: true,
              })
            }
            required
            min="0"
            maxChar={2}
          />

          <NumberInput
            label="Commission Amount:"
            name="airticket_commission_percent_total"
            placeholder="commission Amount"
            required
            size={6}
            smSize={12}
            onChange={() => {
              setCommissionChg(true);
              handleCalculation({
                commissionType: "manual",
                isAitManual: true,
                isTaxManual: true,
              });
            }}
            min="0"
            maxChar={14}
          />

          <NumberInput
            label="Net Commission:"
            required
            name="airticket_net_commssion"
            readOnly
            size={6}
            onChange={() =>
              handleCalculation({ isAitManual: true, isTaxManual: true })
            }
            min="0"
            maxChar={14}
          />

          <Col lg={8} md={8} sm={12}>
            <Form.Item
              label="AIT:"
              name="airticket_ait"
              required
              rules={[
                {
                  validator: (_, value) => {
                    if (value || value === 0) {
                      value = value + "";
                      const maximum = "9".repeat(14);

                      if (Fixed2(value || 0) <= Fixed2(maximum)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(`Maximum ${14} digit allowed`);
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={() => {
                  handleCalculation({ isAitManual: true, isTaxManual: true });
                }}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                addonBefore={
                  <Form.Item
                    className="commonBox"
                    name={["airticket_ait_from"]}
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Select
                      onSelect={(e: "Client" | "Profit") => {
                        handleCalculation({
                          isAitManual: true,
                          isTaxManual: true,
                          commissionType: "manual",
                        });
                      }}
                      bordered={false}
                      dropdownMatchSelectWidth={100}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <Select.Option value="Client">Client</Select.Option>
                      <Select.Option value="Profit">Profit</Select.Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </Col>

          <Col lg={8} md={8} sm={12}>
            <Form.Item
              name={["airticket_discount_total"]}
              label={
                airticket_discount_type === "amount"
                  ? "Discount: "
                  : airticket_discount_type === "percentClientTotal"
                  ? "Discount: " + Fixed2(discountPercentValue) + "/-"
                  : airticket_discount_type === "percentBaseFareTotal" &&
                    "Discount: " + Fixed2(discountPercentValue) + "/-"
              }
            >
              <InputNumber
                style={{ width: "100%" }}
                type="number"
                onChange={() =>
                  handleCalculation({ isAitManual: true, isTaxManual: true })
                }
                min={0}
                addonBefore={
                  <Form.Item
                    name={["airticket_discount_type"]}
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Select
                      bordered={false}
                      style={{ margin: 0, padding: 0 }}
                      dropdownMatchSelectWidth={170}
                    >
                      <Select.Option value="amount">Amount</Select.Option>
                      <Select.Option value="percentClientTotal">
                        % Gross Fare(Sale)
                      </Select.Option>
                      <Select.Option value="percentBaseFareTotal">
                        % Base Fare(Buy)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                }
              ></InputNumber>
            </Form.Item>
          </Col>

          <Col lg={2} md={8} xl={8}>
            <Form.Item
              name={["airticket_other_bonus_total"]}
              label="Other Bonus"
            >
              <InputNumber
                style={{ width: "100%" }}
                type="number"
                onChange={() => {
                  handleCalculation({});
                }}
                min={0}
                addonBefore={
                  <Form.Item
                    name={["airticket_other_bonus_type"]}
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Select
                      onSelect={(e: "amount" | "percentage") => {
                        handleCalculation({
                          isAitManual: true,
                          isTaxManual: true,
                        });
                      }}
                      bordered={false}
                      style={{ margin: 0, padding: 0 }}
                      dropdownMatchSelectWidth={100}
                    >
                      <Select.Option value="amount">Amount</Select.Option>
                      <Select.Option value="percentage">
                        Percent(%)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                }
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
          className={"invoice_calculation"}
        >
          <Row
            className="invoice_calculation_left"
            style={{ width: "80%" }}
            gutter={[8, 0]}
          >
            <NumberInput
              label="Extra Fee: "
              name="airticket_extra_fee"
              size={6}
              onChange={() =>
                handleCalculation({ isAitManual: true, isTaxManual: true })
              }
              min="0"
            />
            <NumberInput
              label="Other Expense:"
              name="airticket_other_expense"
              size={6}
              onChange={() =>
                handleCalculation({ isAitManual: true, isTaxManual: true })
              }
              min="0"
              maxChar={10}
            />
            <NumberInput
              onChange={() =>
                handleCalculation({ isAitManual: true, isTaxManual: true })
              }
              label="Vat:"
              name="airticket_vat"
              size={6}
              min="0"
              maxChar={10}
            />

            <NumberInput
              label="Tax:"
              name="airticket_tax"
              size={6}
              min="0"
              maxChar={14}
              onChange={() => {
                handleCalculation({ isAitManual: true, isTaxManual: true });
              }}
            />

            <SelectAirport
              label="Route/Sector:"
              name="airticket_route_or_sector"
              mode="multiple"
              size={6}
              form={form}
              fullNamePath={["airticket_route_or_sector"]}
            />

            {/* <SelectAirportV1
              label='Route/Sector:'
              name='airticket_route_or_sector'
              mode='multiple'
              size={6}
              form={form}
              fullNamePath={['airticket_route_or_sector']}
            /> */}
            <FormInput
              label="PNR:"
              name="airticket_pnr"
              size={6}
              rules={[
                { min: 5, message: "Invalid pnr" },
                { max: 10, message: "Invalid pnr" },
              ]}
            />

            <SelectOptionsInput
              label="GDS :"
              name="airticket_gds_id"
              options={["Sabre", "Galileo", "Amadeus"]}
              size={6}
            />

            <NumberInput
              label="Segment:"
              name="airticket_segment"
              placeholder="Enter Segment Number"
              size={6}
              min="0"
              maxChar={10}
            />

            <DateInput
              label="Issue Date:"
              name="airticket_issue_date"
              size={6}
            />
            <DateInput
              label="Journey Date:"
              name="airticket_journey_date"
              size={6}
            />
            <DateInput
              label="Return Date:"
              name="airticket_return_date"
              size={6}
            />

            <SelectOptionsInput
              label="Select air ticket class"
              name="airticket_classes"
              options={["Economy", "Premium Economy", "Business", "First"]}
              size={6}
            />

            <SelectOptionsInput
              label="Select ticket type"
              name="airticket_ticket_type"
              options={[
                "SEAT UPGRADE",
                "SEAT ASSIGN",
                "COD",
                "EXCESS BAGGAGE",
                "NAME CHNG",
                "STPC",
                "EXCHANG TKT",
                "NEW TKT",
              ]}
              size={6}
            />
          </Row>

          <Row
            style={{
              width: "20%",
              marginLeft: "20px",
              marginBottom: "20px",
              border: "1px solid lightgray",
              padding: "0px 10px",
              borderRadius: "10px",
            }}
            className="invoice_calculation_right"
          >
            <NumberInput
              readOnly
              label="Client Price:"
              name="airticket_client_price"
              placeholder="Client price here"
              size={24}
              min="0"
              maxChar={14}
            />
            <NumberInput
              readOnly
              label="Purchase Price:"
              name="airticket_purchase_price"
              placeholder="Purchase price here"
              size={24}
              min="0"
              maxChar={14}
            />
            <NumberInput
              readOnly
              label="Profit:"
              name="airticket_profit"
              placeholder="Profit here"
              size={24}
              min="0"
              maxChar={14}
            />
          </Row>
        </div>
      </div>

      {/* YR TAXES */}

      {/* INVOICE COUNTRY CHARGE */}
      <InvoicesCountryCharge
        gutter={24}
        form={form}
        bd_name={"airticket_bd_charge"}
        es_name={"airticket_es_charge"}
        ut_name={"airticket_ut_charge"}
        xt_name={"airticket_xt_charge"}
        e5_name={"airticket_e5_charge"}
        g4_name={"airticket_g4_charge"}
        ow_name={"airticket_ow_charge"}
        p7_name={"airticket_p7_charge"}
        p8_name={"airticket_p8_charge"}
        pz_name={"airticket_pz_charge"}
        qa_name={"airticket_qa_charge"}
        r9_name={"airticket_r9_charge"}
        handleCalculation={handleCalculation}
      />
    </>
  );
}
