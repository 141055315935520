import { Image } from 'antd';
import { getImageUrl } from './common.utils';

type Props = {
  url: string;
  width?: number;
};

const CheckImageOrPdf = ({ url, width }: Props) => {
  const isPDF = url?.endsWith('.pdf');

  if (isPDF) {
    return (
      <div style={{ padding: 2 }}>
        <a href={url} target='_blank' rel='noopener noreferrer'>
          Open PDF
        </a>
      </div>
    );
  } else {
    return (
      <div style={{ padding: 2 }}>
        <Image
          style={{ borderRadius: '10%' }}
          src={getImageUrl(url)}
          width={width || 30}
        ></Image>
      </div>
    );
  }
  return <div>Something Wrong in Your File</div>;
};

export default CheckImageOrPdf;
