import { Button, Col, Row, Typography } from 'antd';
import 'antd/dist/reset.css';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import { api } from '../../app/baseQuery';
import { useAppDispatch } from '../../app/hooks';
import plane from '../../components/images/expire-plane.json';
import { logout } from '../states/userSlice';
import './login.css';

type Props = {};

const Expire = (props: Props) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(api.util.resetApiState());
    dispatch(logout());
  };
  return (
    <Style>
      <div className='login-page'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            width: '950px',
          }}
          className='login-box'
        >
          <Row
            style={{
              marginTop: '-3px',
              marginLeft: '-5px',
              marginRight: '-5px',
            }}
            justify='center'
          >
            <Lottie animationData={plane} />
          </Row>

          <Row style={{ padding: 10 }}>
            <Col lg={12}>
              <Typography.Title className='changeExpireFont' level={5}>
                Subscription Period Expired
              </Typography.Title>
              <Typography.Text className='subscription-text'>
                Your subscription has expired. To continue enjoying our premium
                content and benefits please contact us today at 09638336699. For
                any assistance or query, contact our support team email or phone
                number. Contact Details:- Phone: 09638-336699, 01958398325,
                01958398313 01958398335 (10:00 AM to 06:00 PM). Email:
                sup.m360ict@gmail.com.
              </Typography.Text>
            </Col>

            <Col lg={12}>
              <Typography.Title className='changeExpireFont' level={5}>
                সাবস্ক্রিপশনের মেয়াদ শেষ
              </Typography.Title>
              <Typography.Text className='subscription-text'>
                আপনার সাবস্ক্রিপশনের মেয়াদ শেষ হয়েছে। আমাদের প্রিমিয়াম
                সামগ্রী এবং সুবিধা উপভোগ করা চালিয়ে যেতে, অনুগ্রহ করে আজই
                যোগাযোগ করুন। যোগাযোগের বিবরণ:- ফোন: ০১৯৫৮৩৯৮৩২৫, ০১৯৫৮৩৯৮৩২৮,
                ০১৯৫৮৩৯৮৩৪৪ (সকাল ১০:০০ থেকে বিকাল ০৬:০০)।
                <p>
                  ই-মেইল:
                  <a style={{ margin: '5px' }}>sup.m360ict@gmail.com</a>
                </p>
              </Typography.Text>
            </Col>
          </Row>
          <Button
            type='primary'
            onClick={() => handleLogout()}
            style={{
              borderRadius: 0,
              marginBottom: 5,
              background: 'rgb(2, 178, 232)',
            }}
          >
            Manage my account
          </Button>
        </div>
      </div>
    </Style>
  );
};

export default Expire;

const Style = styled.div`
  .carousel {
    @media only screen and (max-width: 1023px) {
      display: none;
      width: 80%;
    }
  }
`;
