import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

import {
  AirlineDataType,
  IAirlineEditFormData,
  IAirlineFormData,
} from '../types/AirlinesTypes';

const AIRLINE = 'AIRLINE';
export const airlineEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all airlines
    getAllAirlines: build.query<HTTPResponse<AirlineDataType[]>, void>({
      query: () => ({ url: `/configuration/airlines/read` }),
      providesTags: () => [{ type: 'Airline', id: AIRLINE }],
    }),

    // get all airlines
    getAllAirlinesWithPagination: build.query<
      HTTPResponse<AirlineDataType[]>,
      { current: number; pageSize: number; search: string }
    >({
      query: (arg) => ({
        url: `/configuration/airlines?page=${arg.current}&size=${arg.pageSize}&search=${arg.search}`,
      }),
      providesTags: () => [{ type: 'Airline', id: AIRLINE }],
    }),

    // create airline
    createAirline: build.mutation<void, IAirlineFormData>({
      query: (body) => ({
        url: `/configuration/airlines/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Airline', id: AIRLINE }],
    }),

    //edit airline
    editAirline: build.mutation<void, IAirlineEditFormData>({
      query: (body) => ({
        url: `/configuration/airlines/update/${body.airline_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Airline', id: AIRLINE }],
    }),

    //delete airline
    deleteAirline: build.mutation<void, AirlineDataType>({
      query: (body) => ({
        url: `/configuration/airlines/delete/${body.airline_id}`,
        method: 'DELETE',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Airline', id: AIRLINE }],
    }),
  }),
});

export const {
  useGetAllAirlinesQuery,
  useCreateAirlineMutation,
  useEditAirlineMutation,
  useDeleteAirlineMutation,
  useGetAllAirlinesWithPaginationQuery,
} = airlineEndpoints;
