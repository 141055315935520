import { Button, Form, Modal, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { IPostInvNonComPartialCost } from '../../../../common/types/commonInterfaces';
import {
  useLazyGetNonCommissionPartialCostQuery,
  usePostNonCommissionPartialCostMutation,
} from '../../../../modules/Invoice_Non_Comission/api/endpoints/airTicketNonCommissionEndpoints';
import LoadingIndicator from '../../spinner/LoadingIndicator';
import PartialPaymentInputField from './PartialPaymentInputField';
import dayjs from 'dayjs';

type Props = {
  invoice_id: number;
};

const AddPartialCostPayment = ({ invoice_id }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const [getPartialCost, { isFetching, isLoading, data }] =
    useLazyGetNonCommissionPartialCostQuery();

  const [
    postPartialPayment,
    { isLoading: postLoading, isSuccess: postSuccess, isError },
  ] = usePostNonCommissionPartialCostMutation();

  const showModal = () => {
    if (invoice_id) getPartialCost(invoice_id, true);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    form.setFieldValue('partial_cost', data?.data);
  }, [isFetching, isLoading, data]);

  const onFinish = async (values: Submit) => {
    const body: IPostInvNonComPartialCost[] = values.partial_cost.map(
      (item) => ({
        airticket_id: Number(item.airticket_id),
        comb_vendor: item.comb_vendor,
        partial_cost_amount: Number(item.partial_cost_amount),
        // airticket_date: dayjs(item.airticket_date),
      })
    );

    await postPartialPayment({ body: body, id: invoice_id });
  };

  useEffect(() => {
    if (postSuccess) {
      message.success('Partial Cost Added');
      handleOk();
      form.resetFields();
    } else if (isError) {
      message.error('Something happened to wrong');
    }
  }, [postSuccess, isError]);

  return (
    <>
      <Button size='small' type='primary' onClick={showModal}>
        Partial Cost
      </Button>

      <Modal
        forceRender
        title={`Add Vendor Partial Payment`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form onFinish={onFinish} layout='vertical' form={form}>
          {(isFetching || isLoading) && <LoadingIndicator />}
          <Form.List name='partial_cost'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <PartialPaymentInputField name={name} key={key} form={form} />
                ))}
              </>
            )}
          </Form.List>
          <Row justify={'end'}>
            <Button type='primary' htmlType='submit' loading={postLoading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddPartialCostPayment;

export interface Submit {
  partial_cost: PartialCost[];
}

export interface PartialCost {
  comb_vendor: string;
  vendor_name: string;
  airticket_id: number;
  airticket_ticket_no: string;
  airticket_client_price: string;
  airticket_date: string;
  airticket_purchase_price: string;
  airticket_partial_cost: string;
  airticket_profit: string;
  partial_cost_amount: string;
}
