import {
  ExceptionOutlined,
  PrinterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Typography } from 'antd';
import Table from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Fixed2, rangePresets } from '../../../../common/utils/common.utils';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  useGetAirTicketSummaryQuery,
  useLazyGetAirTicketExcelQuery,
} from '../Endpoint/AirTicketReport.endpoint';
import { AirTicketSummaryCol } from '../Utils/AirTicketSummryColumn';

type Props = {};

function AirTicketSummary({}: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const toDate = dayjs().format('YYYY-MM-DD');
  const [date, setDate] = useState({ toDate: toDate, fromDate: toDate });
  const [client, setClient] = useState('');

  const tableRef = useRef(null);

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isSuccess, isFetching } =
    useGetAirTicketSummaryQuery({
      ...pagination,
      formDate: date.fromDate,
      todDate: date.toDate,
      client: client,
    });

  useEffect(() => {
    if (searchParams && location.search) {
      const formDate = searchParams.get('from_date');
      const toDate = searchParams.get('to_date');
      form.setFieldValue('date_range', [dayjs(formDate), dayjs(toDate)]);
      form.setFieldValue('client', 'All');
      form.submit();
    } else {
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
      form.setFieldValue('client', 'All');
    }
  }, []);

  const [getExcel] = useLazyGetAirTicketExcelQuery();

  const downloadExcel = () => {
    getExcel({ formDate: date.fromDate, todDate: date.toDate });
  };

  const onFinish = async (values: any) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setClient(values.client);
    setDate({ fromDate: setFrom_date, toDate: setTo_date });
  };

  const downloadPdf = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: `AirTicket Summary`,
  });

  const jsx_com = (
    <Table
      bordered
      size='small'
      scroll={{ x: 1100 }}
      dataSource={data?.data}
      columns={AirTicketSummaryCol({ pagination })}
      loading={{ spinning: isFetching, indicator: <LoadingIndicator /> }}
      summary={(pageData) => {
        let total_gross_fare_sum = 0;
        let commission_sum = 0;
        let ait_sum = 0;
        let total_gross_profit = 0;
        let total_purchase_sum = 0;
        let total_discount = 0;
        let total_overall_discount = 0;
        let total_net_profit = 0;
        let total_client_price = 0;
        let total_received_amount = 0;
        let total_due = 0;

        pageData.forEach(
          ({
            total_gross_fare,
            net_commission,
            total_purchase,
            ait,
            gross_profit,
            discount,
            client_amount,
            overall_discount,
            receive_amount,
            net_profit_loss,
          }) => {
            total_gross_fare_sum += Number(total_gross_fare);
            commission_sum += Number(net_commission);
            ait_sum += Number(ait);
            total_gross_profit += Number(gross_profit);
            total_purchase_sum += Number(total_purchase);
            total_discount += Number(discount);
            total_overall_discount += Number(overall_discount);
            total_net_profit += Number(net_profit_loss);
            total_client_price += Number(client_amount);
            total_received_amount += Number(receive_amount);

            total_due +=
              Number(client_amount) - Number(receive_amount) > 0
                ? Number(client_amount) - Number(receive_amount)
                : 0;
          }
        );

        return (
          <Table.Summary.Row style={{ background: '#031c291f', height: 40 }}>
            <Table.Summary.Cell index={0} colSpan={5}>
              Summary
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {total_gross_fare_sum.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {(commission_sum || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {(ait_sum || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              {(total_gross_profit || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {(total_purchase_sum || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
              {(total_discount || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}>
              {(total_overall_discount || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell className='font-success font-bold' index={8}>
              {(total_net_profit || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9}>
              {(total_client_price || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10}>
              {(total_received_amount || 0).toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell className='font-warning font-bold' index={11}>
              {(total_due || 0).toFixed(2)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
      pagination={
        data?.count !== undefined && data?.count < 10
          ? false
          : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
      }
    />
  );

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={tableRef}
        title={{
          info_title: '',
          title: 'Air Ticket Summary',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Air Ticket Summary']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col sm={24} md={12} lg={12}>
            <div style={{ display: 'flex', gap: 10 }}>
              <SelectClients
                showAll
                size={6}
                name={'client'}
                offDropDown
                placeholder='Select Client'
              />

              

              <Form.Item name='date_range'>
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                />
              </Form.Item>

              <Button
                type='primary'
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </div>
          </Col>

          <Col style={{ display: 'flex', gap: 10 }}>
            <Button type='primary' onClick={downloadPdf}>
              <PrinterOutlined />
              Print
            </Button>
            <Button onClick={downloadExcel} type='primary'>
              <ExceptionOutlined />
              Excel
            </Button>
          </Col>
        </Row>
      </Form>

      <Typography.Text style={{ margin: '5px 0px' }}>
        You have total: {data?.count} rows
      </Typography.Text>

      {jsx_com}

      {print_content}
    </>
  );
}

export default AirTicketSummary;
