import { ConfigProvider, Typography, theme } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import {
  IReissueRefundData,
  IReissueRefundItems,
} from '../../../../modules/Invoice_Re_Issue/Type/InoviceReissue.interface';
import { useReIssueRefundDetailsQuery } from '../../../../modules/Invoice_Re_Issue/api/endpoints/reIssueAirTicketInvoiceEndpoints';
import { invoiceViewStyle } from './CommonViewInvoice';
import dayjs from 'dayjs';

type Props = {
  viewInvoicePaymentsRef: React.RefObject<HTMLDivElement>;
};

const TabRefundDetails = ({ viewInvoicePaymentsRef }: Props) => {
  const { id } = useParams();


  const { data, isLoading } = useReIssueRefundDetailsQuery(id, {
    skip: id ? false : true,
  });

  const refundData: ColumnsType<IReissueRefundData> = [
    {
      title: 'Sl',

      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: 'Date',
      dataIndex: 'refund_date',
      key: 'refund_date',
      render: (refund_date) => dayjs(refund_date).format('DD-MM-YYYY'),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Voucher',
      dataIndex: 'refund_voucher',
      key: 'refund_voucher',
    },
    {
      title: 'C. Total',
      dataIndex: 'refund_client_total',
      key: 'refund_client_total',
    },
    {
      title: 'C. Refund Type',
      dataIndex: 'refund_client_type',
      key: 'refund_client_type',
    },

    {
      title: 'Vendor Total',
      dataIndex: 'refund_vendor_total',
      key: 'refund_vendor_total',
    },

    {
      title: 'CL. Refund Acc.',
      dataIndex: 'client_refund_account',
      key: 'client_refund_account',
    },
    {
      title: 'V. Refund Acc.',
      dataIndex: 'vendor_refund_account',
      key: 'vendor_refund_account',
    },
  ];

  const refundItems: ColumnsType<IReissueRefundItems> = [
    {
      title: 'Purchase',
      dataIndex: 'ritem_purchase',
      key: 'ritem_purchase',
    },

    {
      title: 'Sales',
      dataIndex: 'ritem_sales',
      key: 'ritem_sales',
    },
    {
      title: 'Client Charge',
      dataIndex: 'ritem_client_charge',
      key: 'ritem_client_charge',
    },
    {
      title: 'Client Refund',
      dataIndex: 'ritem_client_refund',
      key: 'ritem_client_refund',
    },

    {
      title: 'Vendor Charge',
      dataIndex: 'ritem_vendor_charge',
      key: 'ritem_vendor_charge',
    },
    {
      title: 'Vendor Refund',
      dataIndex: 'ritem_vendor_refund',
      key: 'ritem_vendor_refund',
    },
  ];

  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            width: '8.27in',
            height: '11.69in',
            background: '#fff',
            padding: '10px',
          }}
          ref={viewInvoicePaymentsRef}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginTop: 50,
            }}
          >
            <div>
              <Typography.Title level={5} className='text-center border'>
                Refund Data Details
              </Typography.Title>

              <Table
                size='small'
                bordered
                rowKey={(e) => e.refund_voucher}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={data?.data?.refundData}
                columns={refundData}
                pagination={false}
              />
            </div>

            <div>
              <Typography.Title level={5} className='text-center border'>
                Refund Items Details
              </Typography.Title>

              <Table
                size='small'
                bordered
                rowKey={(e) => e.ritem_id}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={data?.data?.refundItems}
                columns={refundItems}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TabRefundDetails;
