import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
} from '../../../../components/common/FormItem/FormItems';
import { SelectHajjigroup } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetGroupWiseProfitLossQuery } from '../endpoints/profitLossEndpoints';
import { IGroupWiseFormData } from '../types/visaWiseProfitLossTypes';
import { GroupWiseProfitLossColumn } from '../utils/GroupWiseProfitLossColumn';

const Group_wise_profit_loss = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  let saleSum: number = 0;
  let costSum: number = 0;
  let profit_Loss_Sum: number = 0;
  const [fetchVisaProfitLoss, { data, isLoading }] =
    useLazyGetGroupWiseProfitLossQuery();

  if (data?.data) {
    for (let i = 0; i < data?.data.length; i++) {
      const element = data?.data[i];
      saleSum += Number(element.costitem_sale_price);
      costSum +=
        Number(element.costitem_cost_price) * Number(element.billing_quantity);
      profit_Loss_Sum += Number(
        Number(element.costitem_sale_price) -
          Number(element.costitem_cost_price) * Number(element.billing_quantity)
      );
    }
  }
  const onFinish = async (values: IGroupWiseFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: IGroupWiseFormData = {
      date_range: date_range,
      group_id: values.group_id,
    };
    await fetchVisaProfitLoss({
      group_id: body.group_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.group_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  const toDayDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `from_date=${toDayDate}&to_date=${toDayDate}`;

    setExcelQuery({
      Id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IGroupWiseFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IGroupWiseFormData = {
      date_range: date_range,
      group_id: values.group_id,
    };

    fetchVisaProfitLoss({
      group_id: body.group_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.group_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Group Wise Profit Loss`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <Table
        size='small'
        bordered
        pagination={count > 20 ? tableParams.pagination : false}
        columns={GroupWiseProfitLossColumn(queryData)}
        dataSource={data?.data}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Group Wise Profit/Loss Ledger' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb
        arrOfOption={['Reports', 'ProfitLoss', 'Group Wise Profit/Loss Ledger']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        {/* <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'profitLoss/visaWiseProfitLoss',
              excelName: 'visa_wise_profit',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button> */}
      </Space>
      <FormHeaderTitle title='Group Wise Profit/Loss Ledger' />
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={5}>
          <Col xxl={4}>
            <SelectHajjigroup
              required
              name={'group_id'}
              label='Select group: '
              placeholder='Select Hajj/Umrah Group'
              size={24}
            />
          </Col>
          <Col xxl={4}>
            <DateInput
              required
              rangePicker
              name={'date_range'}
              label='Select Date:'
              size={24}
            />
          </Col>

          <FormButton label='Search' icon textAlign='left' />
        </Row>
      </Form>
      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}
      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default Group_wise_profit_loss;
