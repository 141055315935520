import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IVisawiseProfitLoss } from '../types/visaWiseProfitLossTypes';
import { Fixed2 } from '../../../../common/utils/common.utils';

export const GroupWiseProfitLossColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IVisawiseProfitLoss> => {
  // const searchColumnProps = useGetSearchColumnProps<IVisawiseProfitLoss>();
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (_, record) => {
        return `${dayjs(record.invoice_sales_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Group Type',
      dataIndex: 'group_type',
      key: 'group_type',
    },
    {
      title: 'Sale Price',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      align: 'right',
      render: (curr) => {
        return Fixed2(curr);
      },
    },
    {
      title: 'Cost Price',
      dataIndex: 'invoice_total_vendor_price',
      key: 'invoice_total_vendor_price',
      align: 'right',
      render: (curr, record) => {
        return Number(curr);
      },
    },
    {
      title: 'Profit/Loss',
      dataIndex: 'invoice_total_profit',
      key: 'invoice_total_profit',
      align: 'right',
      render: (value) => (
        <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
          {Number(value || 0)}
        </span>
      ),
    },
  ];
};
