import { Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { IBillInfo } from '../QuotationTypes/QuotationTypes';
import Billing_Info from './Billing_Info';

type Props = {
  form: FormInstance<any>;
  billInitialValues?: IBillInfo[];
  vendorField?: boolean;
};
const Billing_Info_FormList = ({
  billInitialValues,
  form,
  vendorField,
}: Props) => {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Billing Information 💵' />
      <Form.List name='bill_info' initialValue={billInitialValues || [{}]}>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            return (
              <Row
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
                gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
              >
                <Billing_Info
                  form={form}
                  field={field}
                  billInitialValues={billInitialValues}
                  vendorField={vendorField}
                  add={add}
                  remove={remove}
                  index={index}
                  total={fields?.length}
                  description={
                    billInitialValues?.length
                      ? billInitialValues[index]?.description
                      : undefined
                  }
                />
              </Row>
            );
          });
        }}
      </Form.List>
    </div>
  );
};

export default Billing_Info_FormList;
