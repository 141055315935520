import { Button, Popconfirm, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ViewRoleDrawer from '../components/ViewRoleDrawer';
import { UserResponse } from '../types/user.types';

type Props = {
  deleteRole: any;
  pagination: {
    current: number;
    pageSize: number;
  };
  isDeleteLoading: boolean;
};

export const RoleUtils = ({
  pagination,
  deleteRole,
  isDeleteLoading,
}: Props): ColumnsType<UserResponse> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Role Name',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'Developer',
      render: (_, data) => {
        return <>{data?.role_is_developer === 0 ? 'No' : 'Yes'}</>;
      },
      key: 'role_is_developer',
    },

    {
      title: 'Status',
      dataIndex: 'role_status',
      key: 'role_status',
      render: (_, { role_status }) => (
        <Tag
          color={role_status === 0 ? 'red' : 'green'}
          key={role_status}
        >
          {role_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, { role_id }) => (
        <>
          <ViewRoleDrawer role_id={role_id} />

          <Space size='small'>
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => deleteRole(role_id)}
            >
              <Button
                danger
                size='small'
                type='primary'
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];
};
