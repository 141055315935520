import type { RadioChangeEvent } from 'antd';
import { Col, Radio, Row, Space, Typography } from 'antd';
import {
  usePostEditAppConfigMutation,
  usePostEditAppConfigSignatureMutation,
} from '../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';
import { useState } from 'react';
import FileUpload from '../../../../common/utils/FileUpload';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { getImageUrl } from '../../../../common/utils/common.utils';

type Props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};

const InvoiceWatermark = ({ configDetails, setConfigDetails }: Props) => {
  const [image, setImage] = useState('');

  const [postEdit] = usePostEditAppConfigMutation();
  const [postEditSignature] = usePostEditAppConfigSignatureMutation();

  const defaultValue = configDetails?.tac_wtr_mark;

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_wtr_mark: e.target.value,
    };

    postEdit(body);
  };

  const handleImageUpload = (img: any) => {
    const formData = new FormData();
    if (img) formData.append('tac_wtr_mark_url', img[0]?.originFileObj);

    postEditSignature(formData);
  };

  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={4}>Invoice Watermark:</Typography.Title>
          <Typography.Text type='secondary'>
            Change watermark in your Invoice with customized logo
          </Typography.Text>
        </Col>
        <Col>
          <Radio.Group onChange={onChange} value={defaultValue}>
            <Space direction='vertical'>
              <Radio value={0}>No Watermark</Radio>
              <Radio value={1}>Watermark (Default Logo)</Radio>
            </Space>
            <div style={{ marginTop: 10 }}>
              <FileUpload
                noBorder
                size={24}
                msg='Upload Watermark'
                onChange={(img) => handleImageUpload(img)}
                imagURL={getImageUrl(configDetails?.tac_wtr_mark_url)}
              />
            </div>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceWatermark;
