import {
  ArrowLeftOutlined,
  FileTextOutlined,
  PrinterFilled,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useAppSelector } from '../../../../app/hooks';
import { Fixed2, rangePresets } from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { landscapePageStyle } from '../../../Reports/ledgers/pages/ClientLedgers';
import { IAccountStateFormType } from '../../AccountsTypes/AccountsTypes';
import {
  useGetSingleAccountQuery,
  useLazyGetAccountStatementExcelQuery,
  useLazyGetAccountStatementQuery,
} from '../../Api/Endpoints/accountsEndpoints';
import { ListOfAccountsStatementColumn } from '../../utils/Add_ListOfAccounts/ListOfAccountsStatementColumn';

const { Text, Title } = Typography;

const AccountStatement = () => {
  const [form] = Form.useForm();
  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const [from, setFrom] = useState(dayjs().format('YYYY-MM-DD'));
  const [to, setTo] = useState(dayjs().format('YYYY-MM-DD'));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [
    fetchAccountStatement,
    { isLoading, isFetching, data: accountStatement },
  ] = useLazyGetAccountStatementQuery();

  const { isLoading: isSingleAccountLoading, data: singleAccount } =
    useGetSingleAccountQuery(Number(id));

  const [downloadExcel] = useLazyGetAccountStatementExcelQuery();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Account_Statement_${dayjs()}`,
    pageStyle: landscapePageStyle,
  });

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  useEffect(() => {
    const body = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      date_range: `?from_date=${from}&to_date=${to}`,
      id: Number(id),
    };
    fetchAccountStatement(body);
  }, [pagination]);

  const onFinish = (values: IAccountStateFormType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    setFrom(setFrom_date);
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setTo(setTo_date);

    const body: IAccountStateFormType = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      date_range: `?from_date=${dayjs(values.date_range[0]).format(
        'YYYY-MM-DD'
      )}&to_date=${dayjs(values.date_range[1]).format('YYYY-MM-DD')}`,
      id: Number(id),
    };
    fetchAccountStatement(body);
  };

  const LastPage = Math.ceil(
    Number(accountStatement?.count || 0) / (pagination?.pageSize || 0)
  );

  // pagination
  const tablePagination =
    accountStatement?.count !== undefined && accountStatement?.count < 20
      ? false
      : {
          ...pagination,
          total: accountStatement?.count,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '200', '500'],
          onChange: handlePaginationChange,
        };

  // @----//-----------//------------HANDLE PRINT---------//------------------//---
  const accountStatements = accountStatement?.data ? (
    <>
      <Row justify={'space-between'} style={{}}>
        <Col>
          <Title level={5}>
            Bank Statement of: {singleAccount?.data?.account_name}
          </Title>
        </Col>
        <Col>
          <Title level={5}>
            Avaiable Balance:{' '}
            {Fixed2(singleAccount?.data?.amount).toLocaleString()}{' '}
            {currency?.toUpperCase()}
          </Title>
        </Col>
      </Row>

      {accountStatement?.data ? (
        <>
          {
            <Table
              size='small'
              bordered
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              rowKey={(e) => e.serial}
              columns={ListOfAccountsStatementColumn({ pagination })}
              dataSource={accountStatement?.data.ledgers}
              loading={{
                spinning: isLoading || isFetching,
                indicator: loadingIndicator,
              }}
              pagination={tablePagination}
              summary={(_) => {
                if (pagination?.current === LastPage)
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={4}>
                        <div className=''>
                          <Typography.Text strong>Total: </Typography.Text>
                        </div>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={1} colSpan={2}>
                        <div style={{ textAlign: 'right', marginRight: 20 }}>
                          <Typography.Text>
                            {Fixed2(
                              accountStatement.data?.summary_amount.total_dr
                            )}
                          </Typography.Text>
                        </div>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={2} colSpan={2}>
                        <div style={{ textAlign: 'left', marginLeft: 20 }}>
                          <Typography.Text>
                            {Fixed2(
                              accountStatement.data?.summary_amount.total_cr
                            )}
                          </Typography.Text>
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} colSpan={3} />
                    </Table.Summary.Row>
                  );
              }}
            />
          }
        </>
      ) : (
        ''
      )}
    </>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={accountStatements}
        printRef={componentRef}
        title={{ info_title: '', title: 'Statement' }}
      />
    </div>
  );

  return (
    <Style>
      <BreadCrumb arrOfOption={['Accounts', 'Account Statement']} />

      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Space>
            <Link to='/accounts'>
              <Button type='primary'>
                <ArrowLeftOutlined />
                Return to Accounts List
              </Button>
            </Link>

            <Button type='primary' onClick={handlePrint}>
              <PrinterFilled />
              Print
            </Button>

            <Button
              type='primary'
              onClick={() =>
                downloadExcel({
                  excelName: `Account statement of ${singleAccount?.data?.account_name}`,
                  from_date: from,
                  to_date: to,
                  id: id!,
                })
              }
            >
              <FileTextOutlined />
              Excel Report
            </Button>
          </Space>
        </Col>

        <Col>
          <Form
            onFinish={onFinish}
            layout='vertical'
            form={form}
            style={{ marginTop: 10 }}
          >
            <Row>
              <Space>
                <Form.Item
                  label='Date Range'
                  name='date_range'
                  required
                  rules={[
                    {
                      required: true,
                      message: 'date is required',
                    },
                  ]}
                >
                  <RangePicker presets={rangePresets} format={'DD-MM-YYYY'} />
                </Form.Item>
                <Form.Item label=' '>
                  <Button type={'primary'} htmlType='submit'>
                    Search
                  </Button>
                </Form.Item>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>

      {/* @STATEMENT CONTENTS */}
      {accountStatements}
      {/* @PRINTABLE CONTENT */}
      {print_content}
    </Style>
  );
};

export default AccountStatement;
const Style = styled.div``;
