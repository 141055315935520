import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { IActivityDataType } from '../types/activityLogsTypes';
import { Typography } from 'antd';

const ActivityClientColumn = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<IActivityDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
        1 +
        index,
      width: 50,
    },

    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 50,
    },
    {
      title: 'Action',
      dataIndex: 'audit_action',
      key: 'audit_action',
      width: 50,
      render: (_, data) =>
        data.audit_action == 'update' ? (
          <Typography.Text type='success'>{data?.audit_action}</Typography.Text>
        ) : data?.audit_action == 'delete' ? (
          <Typography.Text type='danger'>{data?.audit_action}</Typography.Text>
        ) : (
          data?.audit_action
        ),
    },

    {
      title: 'Descriptions',
      dataIndex: 'audit_content',
      key: 'audit_content',
      width: 50,
    },

    {
      title: 'Date',
      dataIndex: 'audit_action_date_time',
      key: 'audit_action_date_time',
      width: 50,
      render: (_, record) => {
        return `${dayjs(record.audit_action_date_time).format('DD MMM YYYY')}`;
      },
      align: 'center',
    },

    {
      title: 'Time',
      dataIndex: 'audit_action_date_time',
      key: 'audit_action_date_time',
      width: 50,
      render: (_, record) => {
        return `${dayjs(record.audit_action_date_time).format('hh:mm A')}`;
      },
      align: 'center',
    },
  ];
};

export default ActivityClientColumn;
