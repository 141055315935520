import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { IGetAppConfig, IUpdateAppConfig } from '../type/appConfig_Type';

const tags = 'APPCONFIG';

export const appConfigEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAppConfig: build.query<HTTPResponse<IGetAppConfig>, void>({
      query: (arg) => ({
        url: `configuration/office/app-config`,
      }),
      providesTags: () => [tags],
    }),

    postEditAppConfig: build.mutation<HTTPResponse<string>, IGetAppConfig>({
      query: (body) => ({
        url: `configuration/office/app-config`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [tags],
    }),

    postEditAppConfigSignature: build.mutation<HTTPResponse<string>, FormData>({
      query: (body) => ({
        url: `configuration/office/app-config/signature`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [tags],
    }),
  }),
});

export const {
  usePostEditAppConfigMutation,
  useGetAppConfigQuery,
  useLazyGetAppConfigQuery,
  usePostEditAppConfigSignatureMutation,
} = appConfigEndpoints;
