import { Tabs } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router";
import InvoiceNotFound from "../../../../auth/pages/InvoiceNotFound";
import InvoiceActivityLogs from "../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs";
import CommonViewInvoice from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import TabDetailsView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView";
import TabPaymentView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView";
import ViewInvoiceHeader from "../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { useGetViewInvoiceNonComQuery } from "../../api/endpoints/airTicketNonCommissionEndpoints";
import ViewAirticketChildren from "../../components/ViewAirticketChildren";
import AirTicketFlightAndPaxView from "../../../Invoice_Air_Ticket/Components/AirTicketFlightAndPaxView";
import { IPermission } from "../../../../common/types/commonTypes";

type Props = {
  permission?: IPermission;
};
const ViewInvoiceNonCom = ({ permission }: Props) => {
  const invoice_id = Number(useParams().id);

  // GET INVOICE AIRTICKET DETAILS
  const {
    data: invoices,
    isFetching,
    isLoading,
    isError,
  } = useGetViewInvoiceNonComQuery(invoice_id);

  const invoiceData = invoices?.data;

  const invoice_category_id = invoiceData?.invoice_category_id;

  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = useState<
    "viewInvoice" | "costDetails" | "payments" | "activity"
  >("viewInvoice");

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case "viewInvoice":
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case "costDetails":
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case "payments":
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case "activity":
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  if (isError) return <InvoiceNotFound />;

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />

      {isLoading && <LoadingIndicator />}

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint("viewInvoice");
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint("costDetails");
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint("payments");
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint("activity");
              break;

            default:
              break;
          }
        }}
        type="card"
        style={{ marginTop: "15px" }}
        items={[
          {
            label: "Invoice",
            key: "1",
            children: invoiceData && (
              <CommonViewInvoice
                invoiceData={invoiceData}
                viewInvoicePrintableRef={viewInvoicePrintableRef}
                heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                permission={permission}
                children={
                  <AirTicketFlightAndPaxView
                    flights={invoiceData.flights}
                    paxDetails={invoiceData.pax_details}
                    airticketDetails={invoiceData.airticket_information}
                    heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                    invoice_category_id={invoice_category_id}
                  />
                }
              />
            ),
          },
          {
            label: "Details",
            key: "2",
            children: (
              <TabDetailsView
                invoiceDetails={invoiceData}
                children={
                  <ViewAirticketChildren invoiceDetails={invoiceData} />
                }
                viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
              />
            ),
          },
          {
            label: "Payments",
            key: "3",
            children: (
              <TabPaymentView
                invoiceDetails={invoiceData}
                viewInvoicePaymentsRef={viewInvoicePaymentsRef}
              />
            ),
          },
          {
            label: "Activity Log",
            key: "4",
            children: (
              <InvoiceActivityLogs
                id={invoice_id}
                viewInvoiceAcivityRef={viewInvoiceAcivityRef}
              />
            ),
          },
        ]}
      ></Tabs>
    </>
  );
};

export default ViewInvoiceNonCom;
