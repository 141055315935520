import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { IMoneyReceiptPost } from '../../../common/types/commonInterfaces';
import postDateFormat from '../../../common/utils/postDateFormat';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import InvoiceOtherAddMoney, {
  InvoiceDisPrevRadioButton,
} from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useGetInvoiceNoQuery,
  usePostInvoiceVisaMutation,
} from '../Api/Endpoints/invoiceVisaEndpoints';
import BillingFormList from '../Components/BillingFormList';
import PassportInfo from '../Components/PassportInfo';
import {
  IBillingInfo,
  InvoiceVisaFormValue,
  InvoiceVisaPost,
} from '../Type/invoiceVisa.interface';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import { FormatDateV1 } from '../../../common/utils/common.utils';

type Props = {};

const NewInvoiceVisa = (props: Props) => {
  const [form] = Form.useForm();
  const { data: getInvoiceNo, isLoading } = useGetInvoiceNoQuery('IV');
  const invoice_net_total = useWatch(['invoice_net_total'], form);
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);
  const billingForm: IBillingInfo[] = useWatch(['billing_information'], form);
  const [approveAmount, setApproveAmount] = useState<number>(0);

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: getInvoiceNo?.data,
      invoice_sales_date: dayjs(),
      billing_delivery_date: dayjs(),
      billing_status: 'Pending',
      invoice_show_prev_due: 0,
      invoice_show_discount: 1,
    });
  }, [isLoading, getInvoiceNo]);

  // ==================== form handle ===========================
  const [
    postInvoiceVisa,
    {
      isSuccess,
      isError,
      isLoading: postLoading,
      error: postError,
      data: InvoiceVisa,
    },
  ] = usePostInvoiceVisaMutation();

  const [checkCreditLimit, { isLoading: limitLoading }] =
    useCheckCreditLimitMutation();

  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const onFinish = async (values: InvoiceVisaFormValue) => {
    const money_receipt: IMoneyReceiptPost = {
      receipt_total_amount: values.receipt_total_amount,
      receipt_payment_type: values.receipt_payment_type,
      account_id: values.account_id,
      charge_amount: values.charge_amount,
      cheque_bank_name: values.cheque_bank_name,
      cheque_number: values.cheque_number,
      cheque_withdraw_date: postDateFormat(values.cheque_withdraw_date),
      receipt_money_receipt_no: values.receipt_money_receipt_no,
      receipt_note: values.receipt_note,
      trans_no: values.trans_no,
      receipt_payment_date: postDateFormat(
        values.receipt_payment_date
      ) as string,
    };
    const passport_information = values.passport_information?.map((el: any) => {
      delete el?.passport_email;
      delete el?.passport_name;
      delete el?.passport_passport_no;
      delete el?.dialCode;
      delete el?.passport_mobile_no;
      delete el?.passport_date_of_expire;
      delete el?.passport_date_of_issue;
      delete el?.passport_date_of_birth;
      return {
        passport_id: el.passport_id,
      };
    });

    const body: InvoiceVisaPost = {
      ...values,
      invoice_due_date: FormatDateV1(values.invoice_due_date),
      invoice_sales_date: FormatDateV1(values.invoice_sales_date),
      passport_information,
      invoice_created_by: user?.user_id as number,
      money_receipt,
    };
    checkCreditLimit({
      amount: body.invoice_net_total,
      combClient: body.invoice_combclient_id,
    }).then(async (res: any) => {
      if (res?.data?.data === 1) {
        await postInvoiceVisa(body).then((res: any) => {
          if (res?.data?.data?.invoice_id)
            updateAndPrev
              ? navigate(`/visa/details/${res.data.data.invoice_id}`, {
                  state: location.pathname,
                })
              : navigate('/visa');
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    const finding = [...errorInfo?.errorFields]?.find((item) =>
      item?.name?.includes('passport_information')
    );
    if (finding) {
      message.error('Please add passport information!');
      return;
    }
    message.error('Submit failed!');
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice visa added successfully!');
    }
    if (isError) {
      message.error('Thare was an error');
    }
  }, [postLoading]);

  useEffect(() => {
    if (billingForm) {
      let updatedApproveAmount = invoice_net_total;
      billingForm.map((item: IBillingInfo) => {
        if (item && item.billing_status !== 'Approved') {
          updatedApproveAmount -= Number(item.billing_subtotal);
        }
      });
      setApproveAmount(updatedApproveAmount);
    }
  }, [billingForm, invoice_net_total]);

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice (Visa)']} />

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        form={form}
      >
        {(isLoading || postLoading) && <LoadingIndicator />}
        <InvoiceHeaderForm
          form={form}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
        />
        <PassportInfo form={form} />
        <BillingFormList form={form} conditionalCostVendor />

        <InvoiceVisaSubTotalForm form={form} />
        <InvoiceOtherAddMoney form={form} netTotal={approveAmount || 0} />
        <InvoiceDisPrevRadioButton form={form} style={{ marginTop: -30 }} />

        {/* @VISA CREATE BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limitLoading)}
              label='CREATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limitLoading)}
              label='CREATE & PREVIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default NewInvoiceVisa;
/*
Add approve amount limit on money receipt create -> 2023/11/08
@Author Sabbir hosain <sabbir.m360ict@gmail.com>
*/
