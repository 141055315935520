import { Card, Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import { Fixed2 } from '../../../../../common/utils/common.utils';
import { DateInput, FormButton, FormInput } from '../../../FormItem/FormItems';
import LoadingIndicator from '../../../spinner/LoadingIndicator';
import InvoiceVoidInputs from './InvoiceVoidInputs';
import {
  IPostVoid,
  useLazyGetDetailsForVoidQuery,
  usePostVoidMutation,
} from './VoidApiEndpoints';

type Props = {
  invoice_category_id?: number;
  invoice_id: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TabInvoiceVoid = ({
  invoice_category_id,
  invoice_id,
  setIsModalOpen,
}: Props) => {
  const user = useAppSelector((state: RootState) => state.user);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [getDetails, { isFetching, isLoading, data }] =
    useLazyGetDetailsForVoidQuery();

  const [postVoid, { isError, isSuccess, isLoading: PostLoading, error }] =
    usePostVoidMutation();
  const backendError: any = error;

  const voidDetails = data?.data;

  useEffect(() => {
    if (invoice_id) getDetails(String(invoice_id));
  }, [invoice_id]);

  const onFinish = async (values: Submit) => {
    const body: IPostVoid = {
      client_charge: values.client_charge,
      invoice_vendors: values.invoice_vendors.map((item) => ({
        comb_vendor: item.comb_vendor,
        vendor_charge: item.vendor_charge,
      })),
    };
    await postVoid({ body: body, id: invoice_id });
  };

  const vendorInfo = useWatch('invoice_vendors', form);

  const formattedTicketInfo =
    voidDetails?.vendors?.length &&
    voidDetails.vendors?.map((item) => {
      return {
        vendor_name: item.vendor_name,
        comb_vendor: item.comb_vendor,
        cost_price: Fixed2(item.cost_price),
        airticket_ticket_no: item.airticket_ticket_no,
      };
    });

  //   CLIENT AND VENDOR TOTAL REFUND AMOUNT
  useEffect(() => {
    if (vendorInfo) {
      form.setFieldsValue({
        vendor_total_void: vendorInfo?.reduce(
          (a: any, b: any) => a + Fixed2(b?.vendor_charge),
          0
        ),
      });
    }
  }, [vendorInfo]);

  useEffect(() => {
    if (voidDetails) {
      form.setFieldsValue({
        client_name: voidDetails?.client_name,
        invoice_no: voidDetails?.invoice_no,
        net_total: Fixed2(voidDetails?.net_total),
        void_date: dayjs(),
      });

      form.setFieldValue('invoice_vendors', formattedTicketInfo);
    }
  }, [voidDetails]);

  useEffect(() => {
    if (isError) {
      message.error(backendError.data?.message || 'Something went wrong');
    } else if (isSuccess) {
      setIsModalOpen(false);
      message.success('Void successfully done');
      navigate(-1);
    }
  }, [isError, isSuccess]);

  return (
    <>
      {(isLoading || isFetching) && <LoadingIndicator />}
      <Form layout='vertical' labelAlign='left' onFinish={onFinish} form={form}>
        <Card size='small' style={{ width: '100%' }}>
          <Row gutter={20}>
            <FormInput label='Client' name={'client_name'} disabled size={6} />

            <FormInput
              label='Client Price'
              name={'net_total'}
              readonly
              size={6}
            />

            <FormInput
              label='Invoice No.'
              name={'invoice_no'}
              readonly
              size={6}
            />

            <DateInput
              label='Void Date.'
              name={'void_date'}
              size={6}
              required
            />

            {/* Nest Form.List */}
            <Col lg={24}>
              <Form.Item>
                <Form.List name={['invoice_vendors']}>
                  {(subFields, subOpt) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 12,
                      }}
                    >
                      {subFields?.map((subField, index) => (
                        <InvoiceVoidInputs
                          key={index}
                          subField={subField}
                          subOpt={subOpt}
                          form={form}
                        />
                      ))}
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </Col>

            {/* CLIENT REFUND INFORMATION */}
            <FormInput
              label='Client Void Amount'
              name={'client_charge'}
              size={12}
            />

            {/* VENDOR REFUND INFORMATION */}
            <FormInput
              name={'vendor_total_void'}
              label='Vendor Void Amount'
              size={12}
              readonly
            />
          </Row>
          <FormButton
            label='Submit'
            loading={isLoading || isFetching || PostLoading}
          />
        </Card>
      </Form>
    </>
  );
};

export default TabInvoiceVoid;

export const showModal = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(true);
};

export const handleOk = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

export const handleCancel = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

interface Submit {
  client_name: string;
  net_total: number;
  invoice_no: string;
  client_charge: number;
  void_date: Date;
  invoice_vendors: InvoiceVendor[];
  vendor_total_void: number;
}

interface InvoiceVendor {
  vendor_name: string;
  comb_vendor: string;
  cost_price: number;
  airticket_ticket_no: string;
  vendor_charge: number;
}
