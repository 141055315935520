import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IAllInvoices } from '../../../common/types/commonInterfaces';
import { IPermission, crud } from '../../../common/types/commonTypes';
import {
  Fixed2,
  TableParams,
  rangePresets,
  separateClient,
} from '../../../common/utils/common.utils';
import InvoiceOtherAddMoneyModal from '../../../modules/Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import Reload from '../reload/Reload';
import { loadingIndicator } from '../spinner/LoadingIndicator';
import AddPartialCostPayment from './PartialCostPayment/AddPartialCostPayment';

type Props = {
  data?: IAllInvoices[];
  trashData?: IAllInvoices[] | undefined;
  setIsTrash: React.Dispatch<React.SetStateAction<boolean>>;
  viewLink: string;
  editLink: string;
  createLink: string;
  permission: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
  isTrash: boolean;
  handleOnDelete: (id: number, index: number) => void;
  setDataSource: React.Dispatch<
    React.SetStateAction<IAllInvoices[] | undefined>
  >;
  InvocieModalTitle?: string | undefined;
  dataSource?: IAllInvoices[];
  isLoading: boolean;
  refetch: ({ query }: { query?: string }) => void;
  setQuery: React.Dispatch<React.SetStateAction<string>>; // query change to fetch data
  setCount: React.Dispatch<React.SetStateAction<number>>; // pagination set total count
  count: number; // pagination total count
  setDate: React.Dispatch<React.SetStateAction<never[]>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  deleteLoading: boolean;
  columnIndex: number | null;
};

const InvoiceListHeader = ({
  data,
  viewLink,
  editLink,
  createLink,
  permission,
  InvocieModalTitle,
  isTrash,
  handleOnDelete,
  setDataSource,
  dataSource,
  isLoading,
  addMRPermission,
  refetch,
  count,
  setQuery,
  setDate,
  setSearch,
  columnIndex,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState<IAllInvoices>();
  const location = useLocation();
  const [tableParams, setTableParams] = React.useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });
  React.useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  const ModalOperation = (data: IAllInvoices) => {
    setInvoiceInfo(data);
    setOpenModal(!openModal);
  };

  const columns: ColumnsType<IAllInvoices> = [
    {
      title: 'SL.',
      width: 55,
      render: (_, data, index) => (
        <>
          {((tableParams?.pagination?.current || 1) - 1) *
            (tableParams?.pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 120,
    },
    {
      title: 'Sales Date',
      key: 'invoice_date',
      dataIndex: 'invoice_date',

      render: (_, data) => (
        <>{moment(data?.invoice_date).format('DD MMM YYYY')} </>
      ),
      width: 135,
      align: 'center',
    },
    {
      title: !isMoallem ? 'Client Name' : 'Moallem',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        return (
          <Link
            to={
              record.invoice_client_id
                ? `/reports/client_ledger?client_id=${record?.invoice_client_id}`
                : `/combineClients/details/${record.invoice_combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.mobile ? ' ' + record.mobile : ''}
            </span>
          </Link>
        );
      },
      width: 150,
    },
    {
      title: 'Sales Price',
      dataIndex: 'net_total',
      key: 'net_toal',
      width: 120,
      align: 'center',
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
      render: (_, record) => Number(record?.net_total),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'center',
      width: 120,
      render(value) {
        return Fixed2(value);
      },
    },
    {
      title: 'Due Amount',
      align: 'center',
      width: 120,
      render(value, record, index) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {Number(record?.net_total) -
              Number(record?.invclientpayment_amount)}
          </span>
        ) : (
          <Tag color='success'>PAID</Tag>
        );
      },
    },

    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num
            ?.split(',')
            .slice(0, 3)
            .map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          {data?.money_receipt_num?.split(',').length > 3 && <span>...</span>}
        </span>
      ),
    },

    {
      title: 'Sales by',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 150,
    },

    {
      title: 'Action',
      key: 'operation',
      width: 200,

      render: (_, data, index) => {
        return (
          <Space size='small'>
            <Button
              disabled={!permission?.['read:any']}
              size='small'
              type='primary'
            >
              <Link
                to={viewLink + '/' + data?.invoice_id}
                state={location.pathname}
              >
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Link>
            </Button>

            {permission?.[crud.update] &&
              !data?.invoice_is_refund &&
              !Fixed2(data.airticket_partial_cost) && (
                <Link
                  to={editLink + '/' + data?.invoice_id}
                  state={location.pathname}
                >
                  <Button size='small' type='primary'>
                    Edit
                  </Button>
                </Link>
              )}

            {permission?.['delete:any'] &&
              !Number(data?.invclientpayment_amount || 0) &&
              !data?.invoice_is_refund &&
              !Fixed2(data.airticket_partial_cost) && (
                <>
                  {permission?.['delete:any'] && (
                    <Button
                      size='small'
                      type='primary'
                      danger
                      loading={columnIndex === index && true}
                    >
                      <Popconfirm
                        onConfirm={() =>
                          handleOnDelete(data?.invoice_id, index)
                        }
                        title='Sure to delete?'
                      >
                        <Typography style={{ color: '#ffffff' }}>
                          Delete
                        </Typography>
                      </Popconfirm>
                    </Button>
                  )}
                </>
              )}

            {addMRPermission &&
              !data?.invoice_is_refund &&
              Number(data?.net_total) !==
                Number(data?.invclientpayment_amount) && (
                <Button
                  disabled={
                    Number(data?.net_total) <=
                    Number(data?.invclientpayment_amount)
                  }
                  onClick={(e) => ModalOperation(data)}
                  size='small'
                  type='primary'
                >
                  Money Receipt
                </Button>
              )}

            {data.invoice_category_id === 2 ? (
              <AddPartialCostPayment invoice_id={data.invoice_id} />
            ) : null}
          </Space>
        );
      },
    },
  ];

  if (location.pathname === '/invoiceother')
    columns.splice(8, 0, {
      title: 'Passport No',
      dataIndex: 'passport_no',
      key: 'passport_no',
      width: 100,
      render: (_, data) => (
        <span>
          {data?.passport_no?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    });

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setQuery(`page=1&size=20`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setQuery(`page=1&size=20`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <>
      {invoiceInfo?.invoice_id && (
        <InvoiceOtherAddMoneyModal
          invoice_id={invoiceInfo?.invoice_id}
          InvocieModalTitle={InvocieModalTitle}
          disabled={false}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}

      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Col span={12}>
          <Row gutter={10}>
            {permission?.['create:any'] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={createLink}>
                  <Button
                    type='primary'
                    icon={<ArrowLeftOutlined />}
                    style={{ width: '100%' }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <Col>
                <Reload
                  refetch={() => {
                    refetch({});
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowKey={(e) => Math.random()}
        rowClassName={(record) =>
          record?.invoice_is_refund ? 'refund-row-style' : ''
        }
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
        columns={columns}
        scroll={{ x: true }}
        dataSource={dataSource}
        pagination={count > 20 ? tableParams.pagination : false}
        onChange={(args1) => {
          setTableParams((prev) => ({ ...prev, pagination: args1 }));
          setQuery &&
            args1.current &&
            args1?.pageSize &&
            setQuery(`page=${args1?.current}&size=${args1?.pageSize}`);
        }}
      />
    </>
  );
};

export default InvoiceListHeader;

export const isMoallem =
  location.pathname === '/invoiceumrah' ||
  location.pathname === '/invoicehajj' ||
  location.pathname === '/hajjpreregistration';

export function removeSpaceBeforeWord(inputString: string) {
  let words = inputString.split(' ');
  let result = words.filter((word) => word !== '').join(' ');
  return result;
}
