import { ColumnsType } from 'antd/lib/table';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { ISalesManWiseCL } from '../endpoints/SalesManWiseClientDueEndpoints';

const SalesManWiseClientDueColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<ISalesManWiseCL> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },

    {
      title: 'Total Sales',
      dataIndex: 'total_sales',
      key: 'total_sales',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Total Discount',
      dataIndex: 'total_discount',
      key: 'total_discount',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Cleint Price',
      dataIndex: 'total_client_price',
      key: 'total_client_price',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Client Payment',
      dataIndex: 'total_client_payment',
      key: 'total_client_payment',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Client Due',
      dataIndex: 'total_client_due',
      key: 'total_client_due',
      render: (curr) => Fixed2(curr),
    },
  ];
};

export default SalesManWiseClientDueColumn;
