import '../style/layoutStyle.css';

import {
  DatabaseOutlined,
  MenuOutlined,
  PicCenterOutlined,
  PieChartOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  StockOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  faArrowsLeftRight,
  faBed,
  faBowlRice,
  faBriefcase,
  faBus,
  faCity,
  faClover,
  faCreditCard,
  faDollar,
  faEarthAsia,
  faFileArrowDown,
  faFileInvoice,
  faFileInvoiceDollar,
  faKaaba,
  faMessage,
  faMoneyBill,
  faMountainSun,
  faNewspaper,
  faObjectGroup,
  faPassport,
  faPeopleArrowsLeftRight,
  faPeopleGroup,
  faPeopleLine,
  faPersonShelter,
  faPlane,
  faPlaneCircleCheck,
  faPlaneDeparture,
  faReceipt,
  faSubtract,
  faTicketSimple,
  faTruckPlane,
  faUser,
  faUserGroup,
  faUsersRays,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Layout, Menu, MenuProps, MenuRef, SiderProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLazyRecruitmentTokenQuery } from '../../app/baseQuery';
import { useAppSelector } from '../../app/hooks';
import { RCM_URL } from '../../app/utils/request';
import { moduleType, selectUser } from '../../auth/states/userSlice';
import { rolePermissionType } from '../../common/types/commonTypes';
import {
  getImageUrl,
  getListPermission,
} from '../../common/utils/common.utils';
import {
  Configuration_Roles,
  Report_Roles,
} from '../../modules/Configuration/Users/types/role.enums';
import DateTimeWidget from '../../modules/Dashboard/Components/DateTimeWidget';
import '../style/layoutStyle.css';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    label,
    key,
    icon,
    children,
  } as MenuItem;
}

interface DataObject {
  children?: DataObject[] | null;
  icon: string;
  key: string;
  label: any;
}

function findObjectWithKey(
  data: DataObject[],
  path: {
    pathname: string;
    state?: string;
  },
  parentIndices: string[] = []
): string[] | null {
  for (let i = 0; i < data?.length; i++) {
    if (data[i] === null) {
      continue;
    }
    const object = data[i];

    if (object?.key === path.pathname || object?.key === path?.state) {
      return [...parentIndices, object?.key];
    }

    if (object.children && Array.isArray(object.children)) {
      const childIndices = findObjectWithKey(object.children, path, [
        ...parentIndices,
        object.key,
      ]);

      if (childIndices) {
        return childIndices;
      }
    }
  }
  return null;
}

function flattenArray(arr: DataObject[]): DataObject[] {
  return arr.reduce((result: DataObject[], obj: DataObject) => {
    result.push(obj);

    if (obj?.children && Array.isArray(obj?.children)) {
      result.push(...flattenArray(obj.children));
    }

    return result;
  }, []);
}

const { Sider } = Layout;

const Sidebar = ({
  collapsed,
  setCollapsed,
  setWidth,
  width,
}: {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  width: number;
}) => {
  // APP CONFIG
  const appConfig = useAppSelector((state) => state.configSlice);
  const isIataPanel = appConfig && appConfig?.tac_airticket_type === 'IATA';

  const iconStyle: React.CSSProperties = {
    fontSize: 12,
  };

  const [isbreakPoint, setIsBreakPoint] = useState(true);

  const props: SiderProps = {
    collapsible: false,
    collapsed,
    onCollapse: (value) => setCollapsed(value),
    ...(isbreakPoint && { collapsedWidth: 0 }),
    breakpoint: 'md',
    onBreakpoint: (broken) => {
      setIsBreakPoint(broken);
    },
  };

  const user = useAppSelector(selectUser);

  const MODULE_TYPE = user?.organization_info.org_module_type || 'TRABILL';

  const permission: rolePermissionType | undefined =
    user?.role_permissions &&
    JSON.parse(user?.role_permissions)[user.role_name];
  //module wise permissions
  const modules = user?.modules;

  const isAdmin = user?.user_role !== 'EMPLOYEE' ? ['*'] : undefined;

  // --------------------- GET Recruitment Token ------------------

  const [getRCMToken, { data: rcmToken }] = useLazyRecruitmentTokenQuery();
  useEffect(() => {
    if (user?.user_role === 'SUPER_ADMIN' && hasPermission('recruitment')) {
      getRCMToken();
    }
  }, [user]);

  const hasPermission = (module: moduleType) => {
    return modules?.includes(module);
  };

  function isRIncludedInRoleResources(permission: any) {
    const rKeys: any = Object.keys(permission);
    return rKeys.some((key: any) => Report_Roles.hasOwnProperty(key));
  }

  const isIncludedPermissionReport =
    permission && isRIncludedInRoleResources(permission);

  function isIncludeConfigPermission(permission: any) {
    const rKeys: any = Object.keys(permission);
    return rKeys.some((key: any) => Configuration_Roles.hasOwnProperty(key));
  }

  const isIncludedPermissionConfig =
    permission && isIncludeConfigPermission(permission);

  // AIRTICKET
  const airTicket = getItem(
    'Invoice (Air Ticket)',
    'Invoice (Air Ticket)',
    <FontAwesomeIcon style={iconStyle} icon={faPlane} />,
    [
      permission?.invoice_airticket?.['create:any']
        ? getItem(
            <Link to='/airticket/add'>New Invoice (Air Ticket)</Link>,
            '/airticket/add',
            <PlusOutlined />
          )
        : null,
      getListPermission(permission?.invoice_airticket)
        ? getItem(
            <Link to='/airticket'>View Invoice (Air Ticket)</Link>,
            '/airticket',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // NON COMMISSION
  const nonCommission = getItem(
    'Invoice (Non commission)',
    'Invoice (Non commission)',
    <FontAwesomeIcon style={iconStyle} icon={faPlane} />,
    [
      permission?.invoice_non_commission?.['create:any']
        ? getItem(
            <Link to='/noncomairticket/add'>New Invoice Non Commission</Link>,
            '/noncomairticket/add',
            <PlusOutlined />
          )
        : null,
      getListPermission(permission?.invoice_non_commission)
        ? getItem(
            <Link to='/noncomairticket'>View Non Commission</Link>,
            '/noncomairticket',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // REISSUE
  const reissue = getItem(
    'Re Issue- Air Ticket',
    'Re Issue- Air Ticket',
    <FontAwesomeIcon style={iconStyle} icon={faPlane} />,
    [
      permission?.invoice_reissue?.['create:any']
        ? getItem(
            <Link to='/reissueairticket/newclient'>
              New Invoice (Re Issue)
            </Link>,
            '/reissueairticket/newclient',
            <PlusOutlined />
          )
        : null,
      getListPermission(permission?.invoice_reissue)
        ? getItem(
            <Link to='/reissueairticket'>View Invoice (Re Issue)</Link>,
            '/reissueairticket',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // INVOICE OTHER
  const invoiceOther = getItem(
    'Invoice(Other)',
    'Invoice(Other)',
    <FontAwesomeIcon style={iconStyle} icon={faFileInvoice} />,
    [
      permission?.invoice_other?.['create:any']
        ? getItem(
            <Link to='/invoiceother/add'>New Invoice (Other)</Link>,
            '/invoiceother/add',
            <PlusOutlined />
          )
        : null,

      getListPermission(permission?.invoice_other)
        ? getItem(
            <Link to='/invoiceother'>View Invoice (Other)</Link>,
            '/invoiceother',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // INVOICE VISA
  const invoiceVisa = getItem(
    'Invoice(Visa)',
    'Invoice(Visa)',
    <FontAwesomeIcon style={iconStyle} icon={faFileInvoice} />,
    [
      permission?.invoice_visa?.['create:any']
        ? getItem(
            <Link to='/visa/add'>New Invoice (Visa)</Link>,
            '/visa/add',
            <PlusOutlined />
          )
        : null,
      getListPermission(permission?.invoice_visa)
        ? getItem(
            <Link to='/visa'>View Invoice (Visa)</Link>,
            '/visa',
            <MenuOutlined />
          )
        : null,
      getListPermission(permission?.invoice_visa) &&
      hasPermission('visa_process')
        ? getItem(
            <Link to='/visa-process'>Visa Process</Link>,
            '/visa-process',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // TOUR PACKAGE
  const tourPackage = getItem(
    'Invoice(Tour Package)',
    'Invoice(Tour Package)',
    <FontAwesomeIcon style={iconStyle} icon={faEarthAsia} />,
    [
      permission?.invoice_tour_package?.['create:any']
        ? getItem(
            <Link to='/tour/add'>Create Invoice</Link>,
            '/tour/add',
            <PlusOutlined />
          )
        : null,
      getListPermission(permission?.invoice_tour_package)
        ? getItem(
            <Link to='/tour-invoice/all'>View Invoice</Link>,
            '/tour-invoice/all',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // Combined Invoice Hajj
  const invoiceHajj = getItem(
    'Hajj',
    'Hajj',
    <FontAwesomeIcon style={iconStyle} icon={faKaaba} />,
    [
      hasPermission('invoice_hajj_pre_reg')
        ? getItem(
            'Invoice (Hajj Pre Reg:)',
            'Invoice (Hajj Pre Reg:)',
            <FontAwesomeIcon style={iconStyle} icon={faKaaba} />,
            [
              permission?.invoice_hajj_pre_reg?.['create:any']
                ? getItem(
                    <Link to='/hajjpreregistration/add'>New Invoice</Link>,
                    '/hajjpreregistration/add',
                    <PlusOutlined />
                  )
                : null,
              getListPermission(permission?.invoice_hajj_pre_reg)
                ? getItem(
                    <Link to='/hajjpreregistration'>View Invoice</Link>,
                    '/hajjpreregistration',
                    <MenuOutlined />
                  )
                : null,
            ]
          )
        : null,
      hasPermission('invoice_hajj')
        ? getItem(
            'Invoice(Hajj)',
            'Invoice(Hajj)',
            <FontAwesomeIcon style={iconStyle} icon={faKaaba} />,
            [
              getItem(
                <Link to='/invoicehajj/add'>New Invoice</Link>,
                '/invoicehajj/add',
                <PlusOutlined />
              ),
              getListPermission(permission?.invoice_hajj)
                ? getItem(
                    <Link to='/invoicehajj'>View Invoice </Link>,
                    '/invoicehajj',
                    <MenuOutlined />
                  )
                : null,
            ]
          )
        : null,

      hasPermission('invoice_hajj_pre_reg')
        ? getItem(
            <Link to='/hajj-reg'>Hajj Registration</Link>,
            '/hajj-reg',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // HAJJI MANAGEMENT
  const hajjiManagement = getItem(
    'Hajji Management',
    'Hajji Management',
    <FontAwesomeIcon style={iconStyle} icon={faKaaba} />,
    [
      permission?.hajji_management_client_to_client?.['create:any']
        ? getItem(
            <Link to='/haji/transfer/client_to_client'>
              Moallem to Moallem transfer
            </Link>,
            '/haji/transfer/client_to_client',
            <MenuOutlined />
          )
        : null,

      getListPermission(permission?.hajji_management_client_to_client)
        ? getItem(
            <Link to='/haji/transfer/client_list'>
              Transfer List (Moallem to Moallem)
            </Link>,
            '/haji/transfer/client_list',
            <MenuOutlined />
          )
        : null,
      permission?.hajji_management_group_to_group?.['create:any']
        ? getItem(
            <Link to='/haji/transfer/group_to_group'>
              Group to Group Transfer
            </Link>,
            '/haji/transfer/group_to_group',
            <MenuOutlined />
          )
        : null,

      getListPermission(permission?.hajji_management_group_to_group)
        ? getItem(
            <Link to='/haji/transfer/group_list'>
              Transfer List (Group to Group)
            </Link>,
            '/haji/transfer/group_list',
            <MenuOutlined />
          )
        : null,

      getListPermission(permission?.hajji_management_transfer_inout)
        ? getItem(
            <Link to='/haji/transfer/in'>Transfer In</Link>,
            '/haji/transfer/in',
            <MenuOutlined />
          )
        : null,
      permission?.hajji_management_transfer_inout
        ? getItem(
            <Link to='/haji/transfer/out_list'>Transfer Out</Link>,
            '/haji/transfer/out_list',
            <MenuOutlined />
          )
        : null,
      permission?.hajji_management_cancel_pre_reg
        ? getItem(
            <Link to='/haji/transfer/pre_registration_cancel_list'>
              Cancel Pre Registration
            </Link>,
            '/haji/transfer/pre_registration_cancel_list',
            <MenuOutlined />
          )
        : null,

      permission?.hajji_management_cancel_pre_reg
        ? getItem(
            <Link to='/haji/transfer/hajj_registration_cancel_list'>
              Cancel Hajj Registration
            </Link>,
            '/haji/transfer/hajj_registration_cancel_list',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // INVOICE UMMRAH
  const invoiceUmmrah = getItem(
    'Invoice(Umrah)',
    'Invoice(Umrah)',
    <FontAwesomeIcon style={iconStyle} icon={faKaaba} />,
    [
      permission?.invoice_ummrah?.['create:any']
        ? getItem(
            <Link to='/invoiceumrah/add'>New Invoice</Link>,
            '/invoiceumrah/add',
            <PlusOutlined />
          )
        : null,

      getListPermission(permission?.invoice_ummrah)
        ? getItem(
            <Link to='/invoiceumrah'>View Invoice </Link>,
            '/invoiceumrah',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // REFUND
  const refund = getItem(
    'Refund',
    'Refund',
    <FontAwesomeIcon style={iconStyle} icon={faArrowsLeftRight} />,
    [
      permission?.refund_airticket
        ? getItem(
            'Airticket Refund',
            'Airticket Refund',
            <FontAwesomeIcon
              className='sidebar-icon'
              icon={faArrowsLeftRight}
            />,
            [
              getItem(
                <Link to='/refund/airticket'>Create AirTicket Refund</Link>,
                '/refund/airticket',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/refund/history/airticket'>
                  View AirTicket Refund
                </Link>,
                '/refund/history/airticket',
                <MenuOutlined />
              ),
            ]
          )
        : null,
      permission?.refund_other_invoice
        ? getItem(
            'Others Refund',
            'Others Refund',
            <FontAwesomeIcon
              className='sidebar-icon'
              icon={faArrowsLeftRight}
            />,
            [
              getItem(
                <Link to='/refund/others'>Others Refund</Link>,
                '/refund/others',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/refund/history/others'>Others Refund History</Link>,
                '/refund/history/others',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      permission?.refund_tour_package
        ? getItem(
            'Tour Package Refund',
            'Tour Package Refund',
            <FontAwesomeIcon
              className='sidebar-icon'
              icon={faArrowsLeftRight}
            />,

            [
              getItem(
                <Link to='/refund/tour-package'>
                  Create Tour Package Refund
                </Link>,
                '/refund/tour-package',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/refund/history/tour-package'>
                  View Tour Package Refund
                </Link>,
                '/refund/history/tour-package',
                <MenuOutlined />
              ),
            ]
          )
        : null,
      getItem(
        'Partial Refund',
        'Partial Refund',
        <FontAwesomeIcon className='sidebar-icon' icon={faArrowsLeftRight} />,

        [
          getItem(
            <Link to='/refund/partial'>Create Partial Refund</Link>,
            '/refund/partial',
            <MenuOutlined />
          ),
          getItem(
            <Link to='/refund/history/partial'>View Partial Refund</Link>,
            '/refund/history/partial',
            <MenuOutlined />
          ),
        ]
      ),
    ]
  );

  // QUOTATION
  const quotation = getItem(
    'Quotation',
    'Quotation',
    <FontAwesomeIcon style={iconStyle} icon={faFileInvoiceDollar} />,
    [
      permission?.quotation?.['create:any']
        ? getItem(
            <Link to='/quotation/add'>New Quotation</Link>,
            '/quotation/add',
            <PlusOutlined />
          )
        : null,

      getListPermission(permission?.quotation)
        ? getItem(
            <Link to='/quotation'>View Quotation</Link>,
            '/quotation',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // MONEY RECEIPT
  const moneyReceipt = getItem(
    'Money Receipt',
    'Money Receipt',
    <FontAwesomeIcon style={iconStyle} icon={faMoneyBill} />,
    [
      permission?.money_receipt
        ? getItem(
            <Link to='/moneyreceipt'>Invoice Money Receipt</Link>,
            '/moneyreceipt',
            <MenuOutlined />
          )
        : null,

      permission?.money_receipt_advr
        ? getItem(
            <Link to='/moneyreceipt/advance'>Advance Return</Link>,
            '/moneyreceipt/advance',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // CHEQUES
  const cheques = getItem(
    <Link to='/cheques'>Cheque Management</Link>,
    '/cheques',
    <FontAwesomeIcon style={iconStyle} icon={faMoneyBill} />
  );

  // PAYROLL
  const payroll = getItem(
    'Payroll',
    'Payroll',
    <FontAwesomeIcon style={iconStyle} icon={faUser} />,
    [
      permission?.payroll
        ? getItem(
            <Link to='/payroll'>Payroll</Link>,
            '/payroll',
            <MenuOutlined />
          )
        : null,
      permission?.payroll
        ? getItem(
            <Link to='/payroll/advance'>Employee Advance</Link>,
            '/payroll/advance',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // AGENTS

  const agent = getItem(
    'Agents',
    'Agents',
    <FontAwesomeIcon style={iconStyle} icon={faUser} />,
    [
      permission?.agents
        ? getItem(
            <Link to='/agents/profile'>Agent Profile</Link>,
            '/agents/profile',
            <MenuOutlined />
          )
        : null,
      permission?.money_receipt
        ? getItem(
            <Link to='/moneyreceipt/agent'>Agent Payment</Link>,
            '/moneyreceipt/agent',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // CLIENTS
  const clients = getItem(
    'Clients',
    'Clients',
    <FontAwesomeIcon style={iconStyle} icon={faUser} />,
    [
      permission?.clients
        ? getItem(
            <Link to='/clients'>Client</Link>,
            '/clients',
            <FontAwesomeIcon style={iconStyle} icon={faUser} />
          )
        : null,

      permission?.combined_clients
        ? getItem(
            <Link to='/combineds'>Combined Client</Link>,
            '/combineds',
            <FontAwesomeIcon style={iconStyle} icon={faUserGroup} />
          )
        : null,
    ]
  );

  // VENDORS
  const vendors = getItem(
    'Vendor',
    'Vendor',
    <FontAwesomeIcon style={iconStyle} icon={faUser} />,
    [
      permission?.vendors?.['read:any']
        ? getItem(
            <Link to='/vendors'>Vendors</Link>,
            '/vendors',
            <MenuOutlined />
          )
        : null,

      permission?.vendors_payment?.['read:any']
        ? getItem(
            <Link to='/vendors/payment'>Vendors Payment</Link>,
            '/vendors/payment',
            <MenuOutlined />
          )
        : null,
      permission?.vendor_advr
        ? getItem(
            <Link to='/vendors/advance'>Advance Return</Link>,
            '/vendors/advance',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // ACCOUNTS
  const accounts = getItem(
    'Accounts',
    'Accounts',
    <FontAwesomeIcon style={iconStyle} icon={faCreditCard} />,
    [
      permission?.account_opening_balance
        ? getItem(
            <Link to='/accounts/bill-adjustment'>Bill Adjustment</Link>,
            '/accounts/bill-adjustment',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts'>Add/List of Accounts</Link>,
            '/accounts',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts/transactions'>Transaction History</Link>,
            '/accounts/transactions',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts/balancestatus'>Balance Status</Link>,
            '/accounts/balancestatus',
            <MenuOutlined />
          )
        : null,
      permission?.account_balance_transfer
        ? getItem(
            <Link to='/accounts/balance_transfer'>Balance Transfer</Link>,
            '/accounts/balance_transfer',
            <MenuOutlined />
          )
        : null,
      permission?.account_non_invoice_income
        ? getItem(
            <Link to='/accounts/noninvoice'>Non-Invoice Income</Link>,
            '/accounts/noninvoice',
            <MenuOutlined />
          )
        : null,
      permission?.account_investments
        ? getItem(
            <Link to='/accounts/investments'>Investments</Link>,
            '/accounts/investments',
            <MenuOutlined />
          )
        : null,
      permission?.accounts_module
        ? getItem(
            <Link to='/accounts/incentive'>Incentive Income</Link>,
            '/accounts/incentive',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // LOAN MANAGEMENT
  const loanManagement = getItem(
    'Loan & Received Investments',
    'Loan & Received Investments',
    <FontAwesomeIcon style={iconStyle} icon={faObjectGroup} />,
    [
      permission?.loan_management_authority
        ? getItem(
            <Link to='/loan/authority'>Authority</Link>,
            '/loan/authority',
            <FontAwesomeIcon style={iconStyle} icon={faObjectGroup} />
          )
        : null,

      permission?.loan_management_loan
        ? getItem(
            <Link to='/loan'>Loan Information</Link>,
            '/loan',
            <FontAwesomeIcon style={iconStyle} icon={faObjectGroup} />
          )
        : null,

      permission?.loan_management_receive
        ? getItem(
            <Link to='/loan/receive'>Received Investment</Link>,
            '/loan/receive',
            <FontAwesomeIcon style={iconStyle} icon={faObjectGroup} />
          )
        : null,
      permission?.loan_management_payment
        ? getItem(
            <Link to='/loan/payment'>Loan & Investment Payment</Link>,
            '/loan/payment',
            <FontAwesomeIcon style={iconStyle} icon={faObjectGroup} />
          )
        : null,
    ]
  );

  // EXPENCE
  const expence = getItem(
    'Expense',
    'Expense',
    <FontAwesomeIcon style={iconStyle} icon={faSubtract} />,
    [
      permission?.expense_head
        ? getItem(
            <Link to='/head'>Expense Head</Link>,
            '/head',
            <FontAwesomeIcon style={iconStyle} icon={faReceipt} />
          )
        : null,
      permission?.expense?.['create:any']
        ? getItem(
            <Link to='/expense/add'>Add Expense</Link>,
            '/expense/add',
            <PlusOutlined />
          )
        : null,
      permission?.expense?.['read:any']
        ? getItem(
            <Link to='/expense'>Expense History</Link>,
            '/expense',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // PASSPORT MANAGEMENT
  const passportManagement = getItem(
    'Passport MGT',
    'Passport MGT',
    <FontAwesomeIcon style={iconStyle} icon={faPassport} />,
    [
      permission?.passport_management
        ? getItem(
            <Link to='/passport/add'>Add Passport</Link>,
            '/passport/add',
            <MenuOutlined />
          )
        : null,

      permission?.passport_management
        ? getItem(
            <Link to='/passport'>List of All Passport</Link>,
            '/passport',
            <MenuOutlined />
          )
        : null,
    ]
  );

  // REPORTS
  const report = getItem(
    'Reports',
    'Reports',
    <FontAwesomeIcon style={iconStyle} icon={faNewspaper} />,
    [
      permission?.report_ledgers
        ? getItem(
            'Ledgers',
            'Ledgers',
            <FontAwesomeIcon style={iconStyle} icon={faFileArrowDown} />,
            [
              getItem(
                <Link to='/reports/client_ledger'>Client Ledger</Link>,
                '/reports/client_ledger',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/vendor_ledger'>Vendor Ledger</Link>,
                '/reports/vendor_ledger',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/combined_ledger'>Combined Ledgers</Link>,
                '/reports/combined_ledger',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/agent_reports'>Agent Ledger</Link>,
                '/reports/agent_reports',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      permission?.report_total_due_advance
        ? getItem(
            'Total Due/Advance',
            'total_due_advance',
            <FontAwesomeIcon style={iconStyle} icon={faDollar} />,
            [
              getItem(
                <Link to='/reports/total_due_advance_client'>Clients</Link>,
                '/reports/total_due_advance_client',

                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/total_due_advance_vendor'>Vendors</Link>,
                '/reports/total_due_advance_vendor',
                <MenuOutlined />
              ),

              getItem(
                <Link to='/reports/total_due_advance_combinedclients'>
                  Combined Clients
                </Link>,
                '/reports/total_due_advance_combinedclients',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/total_due_advance_agents'>Agents</Link>,
                '/reports/total_due_advance_agents',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      permission?.sales_report
        ? getItem('Sales Report', 'Sales Report', <StockOutlined />, [
            getItem(
              <Link to='/reports/daily_sales_report'>Sales Report</Link>,
              '/reports/daily_sales_report',
              <MenuOutlined />
            ),

            getItem(
              <Link to='/reports/monthly_sales_and_earning'>
                Sales & Earning
              </Link>,
              '/reports/monthly_sales_and_earning',
              <MenuOutlined />
            ),

            getItem(
              <Link to='/reports/airline_wise_sales_report'>
                Airline Wise Sales
              </Link>,
              '/reports/airline_wise_sales_report',
              <MenuOutlined />
            ),

            getItem(
              <Link to='/reports/sales_report_item_and_salesman'>
                Salesman & Product
              </Link>,
              '/reports/sales_report_item_and_salesman',
              <MenuOutlined />
            ),

            getItem(
              <Link to='/reports/client_wise_collection_and_sales_Report'>
                Sales & Collection
              </Link>,
              '/reports/client_wise_collection_and_sales_Report',
              <MenuOutlined />
            ),
            permission?.vendor_wise_purchase_payment
              ? getItem(
                  <Link to='/reports/vendor_wise_purchase_and_payment'>
                    Purchase & Payment
                  </Link>,
                  '/reports/vendor_wise_purchase_and_payment',
                  <MenuOutlined />
                )
              : null,
            getItem(
              <Link to='/reports/sales_man_collection_report'>
                Salesman's Wise Collection
              </Link>,
              '/reports/sales_man_collection_report',
              <MenuOutlined />
            ),
            getItem(
              <Link to='/reports/total_collection_payment_report'>
                Daily Sales & Purchase
              </Link>,
              '/reports/total_collection_payment_report',
              <MenuOutlined />
            ),

            getItem(
              <Link to='/reports/sales-man-wise-client-due'>
                Salesman-Wise Client Due
              </Link>,
              '/reports/sales-man-wise-client-due',
              <MenuOutlined />
            ),
          ])
        : null,

      permission?.profit_loss_report
        ? getItem(
            'Profit Loss',
            'Profit Loss',
            <FontAwesomeIcon style={iconStyle} icon={faNewspaper} />,
            [
              getItem(
                <Link to='/reports/over_all_profit_loss'>
                  Over All Profit / Loss
                </Link>,
                '/reports/over_all_profit_loss',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/visa_wise_profit'>
                  Visa Wise Profit / Loss
                </Link>,
                '/reports/visa_wise_profit',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/group-wise-profit-loss'>
                  Group Wise Profit / Loss
                </Link>,
                '/reports/group-wise-profit-loss',
                <MenuOutlined />
              ),

              getItem(
                <Link to='/reports/ticket_wise'>
                  Ticket Wise Profit / Loss
                </Link>,
                '/reports/ticket_wise',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      // EXPENSE
      permission?.expense_report
        ? getItem(
            'Expense Report',
            'Expense Report',
            <FontAwesomeIcon style={iconStyle} icon={faNewspaper} />,
            [
              getItem(
                <Link to='/reports/employee_expense_report'>
                  Salaries Expense
                </Link>,
                '/reports/employee_expense_report',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/head_wise_expense_report'>
                  Office Expense
                </Link>,
                '/reports/head_wise_expense_report',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      // PASSPORT
      permission?.passport_report
        ? getItem(
            'Passport Report',
            'Passport Report',
            <FontAwesomeIcon style={iconStyle} icon={faPassport} />,
            [
              getItem(
                <Link to='/reports/passport_status_report'>
                  Passport Status Report
                </Link>,
                '/reports/passport_status_report',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/passport_wise_report'>
                  Passport Wise Report
                </Link>,
                '/reports/passport_wise_report',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      // PASSENGER
      (hasPermission('invoice_hajj') ||
        hasPermission('hajji_management') ||
        hasPermission('invoice_ummrah')) &&
      permission?.passenger_list_report
        ? getItem(
            'Passenger List',
            'Passenger List',
            <FontAwesomeIcon style={iconStyle} icon={faUserGroup} />,

            [
              getItem(
                <Link to='/reports/client_wise_passenger_list'>
                  Client wise Passenger List
                </Link>,
                '/reports/client_wise_passenger_list',
                <MenuOutlined />
              ),
              getItem(
                <Link to='/reports/group_wise_passenger_list'>
                  Group wise Passenger List
                </Link>,
                '/reports/group_wise_passenger_list',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      // AIR TICKET REPORT
      permission?.report_module
        ? getItem(
            'Air ticket',
            'Air ticket',
            <FontAwesomeIcon style={iconStyle} icon={faPlaneDeparture} />,
            [
              permission?.report_module && isIataPanel
                ? getItem(
                    <Link to='/reports/air-ticket-summary'>
                      Air Ticket Summary
                    </Link>,
                    '/reports/air-ticket-summary',
                    <MenuOutlined />
                  )
                : null,

              permission?.report_module
                ? getItem(
                    <Link to='/reports/air-ticket-details'>
                      Air Ticket Details
                    </Link>,
                    '/reports/air-ticket-details',
                    <MenuOutlined />
                  )
                : null,

              permission?.report_module
                ? getItem(
                    <Link to='/reports/tax_report'>Tax Report</Link>,
                    '/reports/tax_report',
                    <MenuOutlined />
                  )
                : null,
              getItem(
                <Link to='/reports/ait_report'>AIT Report</Link>,
                '/reports/ait_report',
                <MenuOutlined />
              ),

              getItem(
                <Link to='/reports/client-ait-report'>Client AIT Report</Link>,
                '/reports/client-ait-report',
                <MenuOutlined />
              ),

              getItem(
                <Link to='/reports/GDS_Report'>GDS Report</Link>,
                '/reports/GDS_Report',
                <MenuOutlined />
              ),
            ]
          )
        : null,

      permission?.report_module
        ? getItem('Others', 'Others', <PicCenterOutlined />, [
            permission?.client_discount
              ? getItem(
                  <Link to='/reports/client_discount_note'>
                    Client Discount
                  </Link>,
                  '/reports/client_discount_note',
                  <MenuOutlined />
                )
              : null,

            permission?.report_module
              ? getItem(
                  <Link to='/report/journey-date-wise-due-report'>
                    Journey Date Wise Due
                  </Link>,
                  '/report/journey-date-wise-due-report',
                  <MenuOutlined />
                )
              : null,
            permission?.journey_date_wise_report
              ? getItem(
                  <Link to='/reports/journey_date_wise_clients'>
                    Journey Date Wise Clients
                  </Link>,
                  '/reports/journey_date_wise_clients',
                  <MenuOutlined />
                )
              : null,

            permission?.country_wise_report
              ? getItem(
                  <Link to='/reports/country_wise_report'>
                    Country Wise Report
                  </Link>,
                  '/reports/country_wise_report',
                  <MenuOutlined />
                )
              : null,

            permission?.accounts_report
              ? getItem(
                  <Link to='/reports/accounts_report'>Accounts Report</Link>,
                  '/reports/accounts_report',
                  <MenuOutlined />
                )
              : null,

            permission?.payroll
              ? getItem(
                  <Link to='/reports/payroll_reports'>Payroll Report</Link>,
                  '/reports/payroll_reports',
                  <MenuOutlined />
                )
              : null,
            hasPermission('loan_management_module')
              ? getItem(
                  <Link to='/reports/loan_report'>Loan Report</Link>,
                  '/reports/loan_report',
                  <MenuOutlined />
                )
              : null,
            getItem(
              <Link to='/reports/transaction_charge_report'>
                Transaction Charge Report
              </Link>,
              '/reports/transaction_charge_report',
              <MenuOutlined />
            ),
            permission?.refund_report
              ? getItem(
                  <Link to='/reports/refund_report'>Refund Report</Link>,
                  '/reports/refund_report',
                  <MenuOutlined />
                )
              : null,

            getItem(
              <Link to='/reports/summary_report'>
                Summary (Daily&Monthly) Report
              </Link>,
              '/reports/summary_report',
              <MenuOutlined />
            ),

            hasPermission('invoice_hajj_pre_reg')
              ? getItem(
                  <Link to='/reports/pre_registration_list'>
                    Pre Registration Report
                  </Link>,
                  '/reports/pre_registration_list',
                  <MenuOutlined />
                )
              : null,

            permission?.audit_trail
              ? getItem(
                  <Link to='/reports/void'>Void List</Link>,
                  '/reports/void',
                  <MenuOutlined />
                )
              : null,

            permission?.user_login_history
              ? getItem(
                  <Link to='/reports/user_login_history'>
                    User Login History
                  </Link>,
                  '/reports/user_login_history',
                  <MenuOutlined />
                )
              : null,

            permission?.audit_trail
              ? getItem(
                  <Link to='/reports/activity_logs'>Audit Trail</Link>,
                  '/reports/activity_logs',
                  <MenuOutlined />
                )
              : null,
          ])
        : null,
    ]
  );

  // CONFIGURATION
  const configuration = getItem(
    'Configuration',
    'Configuration',
    <SettingOutlined />,
    [
      user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionReport
        ? getItem(
            <Link to='/app-configuration'>App Config</Link>,
            '/app-configuration',
            <MenuOutlined></MenuOutlined>
          )
        : null,

      user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionReport
        ? getItem(
            <Link to='/profile-setting'>Profile Setting</Link>,
            '/profile-setting',
            <MenuOutlined></MenuOutlined>
          )
        : null,

      permission?.client_category
        ? getItem(
            <Link to='/clients/categories'>Client Category</Link>,
            '/clients/categories',
            <MenuOutlined></MenuOutlined>
          )
        : null,

      permission?.airports
        ? getItem(
            <Link to='/airports'>Airports</Link>,
            '/airports',
            <FontAwesomeIcon style={iconStyle} icon={faPlaneDeparture} />
          )
        : null,

      permission?.airline
        ? getItem(
            <Link to='/airline'>Airline</Link>,
            '/airline',
            <FontAwesomeIcon
              className='sidebar-icon'
              icon={faPlaneCircleCheck}
            />
          )
        : null,

      permission?.products
        ? getItem(
            <Link to='/products'>Products</Link>,
            '/products',
            <MenuOutlined />
          )
        : null,
      permission?.visa_type
        ? getItem(
            <Link to='/visa/type/new'>Visa Type</Link>,
            '/visa/type/new',
            <MenuOutlined></MenuOutlined>
          )
        : null,
      permission?.departments
        ? getItem(
            <Link to='/departments'>Departments</Link>,
            '/departments',
            <MenuOutlined></MenuOutlined>
          )
        : null,
      permission?.room_types
        ? getItem(
            <Link to='/roomtypes'>Room Types</Link>,
            '/roomtypes',
            <FontAwesomeIcon style={iconStyle} icon={faBed} />
          )
        : null,

      MODULE_TYPE !== 'REC'
        ? getItem(
            <Link to='/transporttypes'>Transport Types</Link>,
            '/transporttypes',
            <FontAwesomeIcon style={iconStyle} icon={faTruckPlane} />
          )
        : null,
      permission?.designations
        ? getItem(
            <Link to='/designation/all_designation'>Designation</Link>,
            '/designation/all_designation',
            <MenuOutlined></MenuOutlined>
          )
        : null,
      permission?.employee
        ? getItem(
            <Link to='/employee'>Employee</Link>,
            '/employee',
            <FontAwesomeIcon style={iconStyle} icon={faPeopleGroup} />
          )
        : null,

      isAdmin
        ? getItem('Users', 'user', <UserOutlined></UserOutlined>, [
            getItem(
              <Link to='/user/view'>View Users</Link>,
              '/user/view',
              <UserSwitchOutlined />
            ),

            getItem(
              <Link to='/role/view'>View Roles</Link>,
              '/role/view',
              <UsergroupAddOutlined></UsergroupAddOutlined>
            ),
          ])
        : null,

      hasPermission('invoice_tour_package')
        ? getItem(
            'Tour Itinerary',
            'tour',
            <FontAwesomeIcon style={iconStyle} icon={faClover} />,
            [
              getItem(
                <Link to='/tour/groups'>Tour Groups</Link>,
                '/tour/groups',
                <FontAwesomeIcon style={iconStyle} icon={faUsersRays} />
              ),
              getItem(
                <Link to='/tour/ticket'>Ticket</Link>,
                '/tour/ticket',
                <FontAwesomeIcon
                  className='sidebar-icon'
                  icon={faTicketSimple}
                />
              ),
              getItem(
                <Link to='/tour/guide'>Guides</Link>,
                '/tour/guide',
                <UsergroupAddOutlined></UsergroupAddOutlined>
              ),
              getItem(
                <Link to='/tour/transport'>Transports</Link>,
                '/tour/transport',
                <FontAwesomeIcon style={iconStyle} icon={faBus} />
              ),
              getItem(
                <Link to='/tour/foods'>Foods</Link>,
                '/tour/foods',
                <FontAwesomeIcon style={iconStyle} icon={faBowlRice} />
              ),
              getItem(
                <Link to='/tour/accommodation'>Accommodations</Link>,
                '/tour/accommodation',
                <FontAwesomeIcon
                  className='sidebar-icon'
                  icon={faPersonShelter}
                />
              ),
              getItem(
                <Link to='/tour/cities'>Cities</Link>,
                '/tour/cities',
                <FontAwesomeIcon style={iconStyle} icon={faCity} />
              ),
              getItem(
                <Link to='/tour/places'>Places</Link>,
                '/tour/places',
                <FontAwesomeIcon
                  className='sidebar-icon'
                  icon={faMountainSun}
                />
              ),
              getItem(
                <Link to='/tour/other-transport'>Other Transport</Link>,
                '/tour/other-transport',
                <FontAwesomeIcon style={iconStyle} icon={faBus} />
              ),
            ]
          )
        : null,
      permission?.passport_status
        ? getItem(
            <Link to='/passport/status'>Passport Status</Link>,
            '/passport/status',
            <FontAwesomeIcon style={iconStyle} icon={faPassport} />
          )
        : null,
      permission?.groups
        ? getItem(
            <Link to='/group'>Groups</Link>,
            '/group',
            <FontAwesomeIcon style={iconStyle} icon={faPeopleLine} />
          )
        : null,
      permission?.maharam
        ? getItem(
            <Link to='/maharam'>Maharam</Link>,
            '/maharam',
            <FontAwesomeIcon
              className='sidebar-icon'
              icon={faPeopleArrowsLeftRight}
            />
          )
        : null,
      permission?.agency
        ? getItem(
            <Link to='/agency'>Agency</Link>,
            '/agency',
            <MenuOutlined></MenuOutlined>
          )
        : null,

      permission?.companies
        ? getItem(
            <Link to='/companies'>Companies</Link>,
            '/companies',
            <FontAwesomeIcon style={iconStyle} icon={faBriefcase} />
          )
        : null,

      user && permission?.database668
        ? getItem(
            <Link to='/db-backup'>Database Backup</Link>,
            '/db-backup',
            <DatabaseOutlined />
          )
        : null,
    ]
  );

  const recruitment = getItem(
    <a
      href={`${RCM_URL}/login?rcm_token=${rcmToken?.data?.trd_recruit_token}`}
      target='_blank'
    >
      Recruitment
    </a>,
    '/recruitment',
    <FontAwesomeIcon style={iconStyle} icon={faPeopleGroup} />
  );

  const feedback = getItem(
    <Link to='/feedback'>Feedback</Link>,
    '/feedback',
    <FontAwesomeIcon style={iconStyle} icon={faMessage} />
  );

  const items: MenuItem[] = [
    getItem(<Link to='/'>Dashboard</Link>, '/', <PieChartOutlined />),

    hasPermission('invoice_airticket') && permission?.invoice_airticket
      ? airTicket
      : null,
    hasPermission('invoice_non_commission') &&
    permission?.invoice_non_commission
      ? nonCommission
      : null,
    hasPermission('invoice_reissue') && permission?.invoice_reissue
      ? reissue
      : null,
    hasPermission('invoice_other') && permission?.invoice_other
      ? invoiceOther
      : null,
    hasPermission('invoice_visa') && permission?.invoice_visa
      ? invoiceVisa
      : null,
    hasPermission('invoice_tour_package') && permission?.invoice_tour_package
      ? tourPackage
      : null,
    hasPermission('invoice_hajj') && permission?.invoice_hajj
      ? invoiceHajj
      : null,
    hasPermission('hajji_management') && permission?.hajji_management
      ? hajjiManagement
      : null,
    hasPermission('invoice_ummrah') && permission?.invoice_ummrah
      ? invoiceUmmrah
      : null,
    hasPermission('refund_module') && permission?.refund_module ? refund : null,
    hasPermission('money_receipt') && permission?.money_receipt
      ? moneyReceipt
      : null,
    hasPermission('accounts_module') && permission?.accounts_module
      ? accounts
      : null,
    hasPermission('cheque_management') && permission?.cheque_management
      ? cheques
      : null,
    hasPermission('payroll') && permission?.payroll ? payroll : null,
    hasPermission('expense') && permission?.expense ? expence : null,
    hasPermission('loan_management_module') &&
    permission?.loan_management_module
      ? loanManagement
      : null,
    hasPermission('clients') && permission?.clients ? clients : null,
    hasPermission('vendors') && permission?.vendors ? vendors : null,
    hasPermission('agents') && permission?.agents ? agent : null,
    hasPermission('quotation') && permission?.quotation ? quotation : null,
    hasPermission('passport_management') && permission?.passport_management
      ? passportManagement
      : null,

    user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionReport
      ? report
      : null,

    user?.user_role === 'SUPER_ADMIN' &&
      hasPermission('recruitment') &&
      recruitment,

    user?.user_role === 'SUPER_ADMIN' || isIncludedPermissionConfig
      ? configuration
      : null,
    feedback,
  ].filter((item) => Boolean(item)) as MenuItem[];

  const [sortedItems, setSortedItems] = useState<MenuItem[] | null>(items);

  const [searchText, setSearchText] = useState<string | undefined>();

  const handleSidebarSort = (e: any) => {
    const searchText: string = e?.target?.value;
    setSearchText(searchText);
    const sidebarAllFlat = flattenArray(items as DataObject[]);
    const searchResult = sidebarAllFlat.filter(
      (item) =>
        ((item &&
          item?.key
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())) ||
          item?.label?.props?.children
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toLocaleLowerCase())) &&
        !item.children
    );

    if (searchResult.length) {
      setSortedItems(searchResult);
    } else {
      setSortedItems(null);
    }
  };

  const location = useLocation();
  const [currentSelection, setCurrentSelection] = useState([location.pathname]);
  const [openKeys, setOpenKeys] = useState(['']);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (items.map((item) => item?.key).indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const indices = findObjectWithKey(items as DataObject[], {
      pathname: location.pathname,
      state: location?.state,
    });
    setOpenKeys(indices || []);
    setCurrentSelection(indices || []);
  }, [location.pathname]);

  // ============= HANDLE WIDTH ================
  const handleResize = (e: any) => {
    setWidth(e.clientX || 245);
  };

  const handleResizeEnd = () => {
    window.removeEventListener('mousemove', handleResize);
    window.removeEventListener('mouseup', handleResizeEnd);
  };

  const handleResizeStart = (event: any) => {
    window.addEventListener('mousemove', handleResize);
    window.addEventListener('mouseup', handleResizeEnd);
  };

  const menuRef = useRef<MenuRef>(null);

  // @this block only for resize bar height control.
  const [barHeight, setBarHeight] = useState<number | string>('100%');
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (menuRef.current) {
        setBarHeight(
          menuRef.current.menu?.list?.offsetHeight
            ? menuRef.current.menu?.list?.offsetHeight
            : '100%'
        );
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [menuRef, openKeys, currentSelection, searchText]);

  const noncollapseFunc = () => {
    setCollapsed(true);
  };

  const collapseFunc = () => {
    setCollapsed(false);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    setWidth(201);
  };

  useEffect(() => {
    if (width <= 180) {
      noncollapseFunc();
    } else if (width >= 181) {
      collapseFunc();
      if (width >= 450) {
        setWidth(450);
      }
    }
  }, [width]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Sider
      theme='light'
      collapsed={collapsed}
      collapsible
      onCollapse={() => {
        handleCollapse();
        if (menuRef.current) {
          setBarHeight(
            menuRef.current.menu?.list?.offsetHeight
              ? menuRef.current.menu?.list?.offsetHeight
              : '100%'
          );
        }
      }}
      width={width}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        userSelect: 'none',
        transition: 'all 0s',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`siderDesign ${isHovered ? 'sidebar-hovered' : ''}`}
      {...props}
    >
      <div
        className='resize-handle'
        style={{
          cursor: 'ew-resize',
          height: barHeight,
          position: 'absolute',
          right: 0,
          top: 0,
          width: 4,
        }}
        onMouseDown={handleResizeStart}
      />
      {/* Date and time */}

      {!collapsed ? (
        <>
          <DateTimeWidget />
          <div
            style={{
              padding: '10px 10px',
              position: 'sticky',
              zIndex: 9,
              top: 0,
            }}
          >
            <Input
              autoFocus
              className='search_feild'
              placeholder='Sidebar items search here...'
              suffix={<SearchOutlined />}
              onChange={handleSidebarSort}
            />
          </div>
        </>
      ) : (
        <Link to='/'>
          <img
            src={getImageUrl(user?.organization_info.org_logo)}
            alt={user?.organization_info.org_name || 'Trabill'}
            style={{
              background: '#fff',
              borderRadius: 5,
              padding: '5px 8px',
              width: 60,
              margin: '10px 6px',
            }}
          />
        </Link>
      )}

      <Menu
        inlineIndent={10}
        ref={menuRef}
        items={
          searchText && searchText !== ' '
            ? sortedItems?.length
              ? sortedItems
              : [getItem('Not found', 'Not found')]
            : items
        }
        mode='inline'
        openKeys={openKeys}
        selectedKeys={currentSelection}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/']}
        style={{ paddingBottom: '40px' }}
      />
    </Sider>
  );
};
export default Sidebar;

export const iconStyle: React.CSSProperties = {
  fontSize: 12,
};
