import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IVendorLedger } from '../types/vendorLedgerTypes';

export const VendorLedgerUtils = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IVendorLedger> => {
  return [
    {
      title: 'SL.',
      align: 'center',
      fixed: 'left',

      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Trxn Date',
      key: 'vtrxn_created_at',
      fixed: 'left',
      render: (_, record) => {
        return `${dayjs(record.vtrxn_created_at).format('DD MMM YYYY')}`;
      },
      align: 'center',
    },

    {
      title: 'Particulars',
      dataIndex: 'vtrxn_particular_type',
      key: 'vtrxn_particular_type',
      render: (_, data) => (
        <span>
          {data.vtrxn_particular_type?.split('\n').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Voucher No.',
      dataIndex: 'vtrxn_voucher',
      key: 'vtrxn_voucher',
    },

    {
      title: 'Pax Name',
      dataIndex: 'vtrxn_pax',
      key: 'vtrxn_pax',
      render: (_, data) => (
        <span>
          {data?.vtrxn_pax?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Ticket No',
      dataIndex: 'vtrxn_airticket_no',
      key: 'vtrxn_airticket_no',
      render: (_, data) => (
        <span>
          {data?.vtrxn_airticket_no?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'PNR',
      dataIndex: 'vtrxn_pnr',
      key: 'vtrxn_pnr',
      render: (_, data) => (
        <span>
          {data?.vtrxn_pnr?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Route',
      dataIndex: 'vtrxn_route',
      key: 'vtrxn_route',
      align: 'center',
    },

    {
      title: 'Pay Type',
      dataIndex: 'vtrxn_pay_type',
      key: 'vtrxn_pay_type',
      align: 'center',
    },
    {
      title: 'Debit',
      key: 'vtrxn_amount',
      align: 'right',
      render: (_, data) => (
        <>
          {data.vtrxn_type === 'DEBIT' && (
            <p>
              <span style={{ color: 'red' }}>{Number(data.vtrxn_amount)}</span>
            </p>
          )}
        </>
      ),
    },
    {
      title: 'Credit',
      key: 'vtrxn_amount',
      align: 'right',
      render: (_, data) => (
        <>
          {data.vtrxn_type === 'CREDIT' && (
            <p>
              <span style={{ color: 'green' }}>
                {Number(data.vtrxn_amount)}
              </span>
            </p>
          )}
        </>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'vtrxn_lbalance',
      key: 'vtrxn_lbalance',
      align: 'right',
      sorter: (a, b) => Number(a.vtrxn_lbalance) - Number(b.vtrxn_lbalance),
      render: (amount) => (
        <p style={{ color: parseInt(amount) > 0 ? 'green' : 'red' }}>
          {Number(amount) === 0 ? '' : Number(amount) > 0 ? 'Adv ' : 'Due '}
          {Fixed2(Math.abs(amount))}
        </p>
      ),
    },

    {
      title: 'Note',
      dataIndex: 'vtrxn_note',
      key: 'vtrxn_note',
      render: (column, data) => (
        <span>
          {data?.vtrxn_note?.split('\n')?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Created Date',
      key: 'vtrxn_created_date',
      render: (_, record) => {
        return `${dayjs(record.vtrxn_created_date).format('DD MMM YYYY')}`;
      },
      align: 'center',
    },
  ];
};
