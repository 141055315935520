import { Descriptions, Typography } from 'antd';
import moment from 'moment';
import { IAirTicketInfo } from '../../common/types/commonInterfaces';
import './invoiceAirTicketView.css';

type Props = {
  airTicketInfo: IAirTicketInfo[];
};

const InoviceAirticketView = ({ airTicketInfo }: Props) => {
  return (
    <div className='air-ticket-view-container'>
      <Typography.Title level={5} className='text-center border'>
        INVOICE DETAILS
      </Typography.Title>

      {airTicketInfo.map((item, index) => (
        <div key={index}>
          <Descriptions
            size='small'
            column={3}
            title={
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
                className='title'
              >
                <h4 className='title'>
                  TICKET NO : {item.airticket_ticket_no}
                </h4>
                <h4 className='title'>VENDOR : {item.vendor_name}</h4>
              </div>
            }
            bordered
          >
            <Descriptions.Item label='Vendor' span={2}>
              {item.vendor_name}
            </Descriptions.Item>

            <Descriptions.Item label='Gross Fare'>
              {item.airticket_gross_fare}
            </Descriptions.Item>

            <Descriptions.Item label='Airline' span={2}>
              {item.airline_name}
            </Descriptions.Item>
            <Descriptions.Item label='Base Fare'>
              {item.airticket_base_fare}
            </Descriptions.Item>

            <Descriptions.Item label='BD'>
              {item.airticket_bd_charge}
            </Descriptions.Item>

            <Descriptions.Item label='UT'>
              {item.airticket_ut_charge}
            </Descriptions.Item>

            <Descriptions.Item label='Tax'>
              {item.airticket_tax}
            </Descriptions.Item>

            <Descriptions.Item label='XT'>
              {item.airticket_xt_charge}
            </Descriptions.Item>

            <Descriptions.Item label='ES'>
              {item.airticket_es_charge}
            </Descriptions.Item>

            <Descriptions.Item label='Commission'>
              {item.airticket_commission_percent_total}
            </Descriptions.Item>

            <Descriptions.Item label='Extra Fee'>
              {item.airticket_extra_fee}
            </Descriptions.Item>

            <Descriptions.Item label='Vat'>
              {item.airticket_vat}
            </Descriptions.Item>

            <Descriptions.Item label='AIT'>
              {item.airticket_ait}
            </Descriptions.Item>

            <Descriptions.Item label='Other Bonus'>
              {item.airticket_other_bonus_total}
            </Descriptions.Item>

            <Descriptions.Item label='Total Discount'>
              {item.airticket_discount_total}
            </Descriptions.Item>

            <Descriptions.Item label='Taxes Commission'>
              {item.airticket_total_taxes_commission}
            </Descriptions.Item>

            <Descriptions.Item label='Segment'>
              {item.airticket_segment}
            </Descriptions.Item>

            <Descriptions.Item label='Issue Date'>
              {moment(item.airticket_issue_date).format('DD/MMM/YYYY')}
            </Descriptions.Item>

            <Descriptions.Item label='Net Commission'>
              {item.airticket_net_commssion}
            </Descriptions.Item>

            <Descriptions.Item label='GDS'>
              {item.airticket_gds_id}
            </Descriptions.Item>

            <Descriptions.Item label='Journey Date'>
              {moment(item.airticket_journey_date).format('DD/MMM/YYYY')}
            </Descriptions.Item>

            <Descriptions.Item style={{ color: 'blue' }} label='Client Price'>
              {item.airticket_client_price}
            </Descriptions.Item>

            <Descriptions.Item label='Class'>
              {item.airticket_classes}
            </Descriptions.Item>

            <Descriptions.Item label='Return Date'>
              {moment(item.airticket_return_date).format('DD/MMM/YYYY')}
            </Descriptions.Item>

            <Descriptions.Item
              style={{ color: 'tomato' }}
              label='Purchase Price'
            >
              {item.airticket_purchase_price}
            </Descriptions.Item>

            <Descriptions.Item label='PNR'>
              {item.airticket_pnr}
            </Descriptions.Item>

            <Descriptions.Item label='Ticket Type'>
              {item.airticket_ticket_type}
            </Descriptions.Item>

            <Descriptions.Item style={{ color: 'green' }} label='Profit'>
              {item.airticket_profit}
            </Descriptions.Item>

            <Descriptions.Item label='Routes'>
              {item.airticket_route_or_sector}
            </Descriptions.Item>
          </Descriptions>
        </div>
      ))}
    </div>
  );
};

export default InoviceAirticketView;
