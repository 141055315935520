import { Button, Col, Drawer, Form, Radio, Row, Select } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import Accounts_Opening_Balance_Add_Modal from '../../modals/Opening_Balance/Accounts/Accounts_Opening_Balance_Add_Modal';
import Client_Opening_Balance_Add_Modal from '../../modals/Opening_Balance/Clients/Client_Opening_Balance_Add_Modal';
import Combine_Opening_Balance_Add_Modal from '../../modals/Opening_Balance/Combine/Combine_Opening_Balance_Add_Modal';
import Vendor_Opening_Balance_Add_Modal from '../../modals/Opening_Balance/Vendors/Vendor_Opening_Balance_Add_Modal';
import All_opening_Balance from './All_opening_Balance';

type Props = { permission?: IPermission };

function OpeningBalance({ permission }: Props) {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState<string>('all');
  const [drawer, setDrawer] = useState<string | undefined>();

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  return (
    <Style>
      <BreadCrumb arrOfOption={['Bill Adjustment']} reloaderSize='small' />
      <Drawer
        title={
          drawer === '1' ? (
            'Add Accounts Bill Adjustment'
          ) : drawer === '2' ? (
            'Add Clients Bill Adjustment'
          ) : drawer === '3' ? (
            'Add Vendors Bill Adjustment'
          ) : drawer === '4' ? (
            'Add Combined Bill Adjustment'
          ) : (
            <></>
          )
        }
        width={window.innerWidth <= 576 ? 300 : 700}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={onCancel}>Cancel</Button>}
      >
        {drawer === '1' ? (
          <Accounts_Opening_Balance_Add_Modal setOpen={setOpen} />
        ) : drawer === '2' ? (
          <Client_Opening_Balance_Add_Modal setOpen={setOpen} />
        ) : drawer === '3' ? (
          <Vendor_Opening_Balance_Add_Modal setOpen={setOpen} />
        ) : drawer === '4' ? (
          <Combine_Opening_Balance_Add_Modal setOpen={setOpen} />
        ) : (
          <></>
        )}
      </Drawer>
      <Row justify={'space-between'} gutter={[10, 10]}>
        <Col>
          <Radio.Group buttonStyle='solid'>
            <Radio.Button
              value='1'
              onClick={(e: any) => {
                setOpen(!open);
                setDrawer(e.target.value);
              }}
            >
              {' '}
              Accounts
            </Radio.Button>
            <Radio.Button
              value='2'
              onClick={(e: any) => {
                setOpen(!open);
                setDrawer(e.target.value);
              }}
            >
              {' '}
              Clients
            </Radio.Button>
            <Radio.Button
              value='3'
              onClick={(e: any) => {
                setOpen(!open);
                setDrawer(e.target.value);
              }}
            >
              {' '}
              Vendors
            </Radio.Button>
            <Radio.Button
              value='4'
              onClick={(e: any) => {
                setOpen(!open);
                setDrawer(e.target.value);
              }}
            >
              {' '}
              Combined
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Select
            defaultValue='all'
            style={{ width: 300 }}
            onChange={(e) => setOpenList(e)}
            options={[
              { value: 'all', label: 'All' },
              { value: 'account', label: ' Accounts ' },
              { value: 'client', label: 'Clients' },
              { value: 'vendor', label: 'Vendors ' },
              { value: 'combined', label: 'Combined' },
            ]}
          />
        </Col>
      </Row>

      <div style={{ marginTop: '30px' }}>
        {openList && <All_opening_Balance openList={openList} />}
      </div>
    </Style>
  );
}

export default OpeningBalance;

const Style = styled.div``;
