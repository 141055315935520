import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import { Fixed2 } from '../../../common/utils/common.utils';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import NumToWord from '../../../components/number_to_words/NumToWord';
import { useLazyGetForEditPayrollQuery } from '../api/endpoints/payrollEndpoints';

type Props = {};
const { Text } = Typography;
export default function PayrollView({}: Props) {
  const printRef = React.useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const [getForEditPayroll, { data: viewPayrollData }] =
    useLazyGetForEditPayrollQuery();

  const payrollData = viewPayrollData?.data;
  let totalDeduction = 0;
  payrollData?.payroll_deductions?.map((item: any) => {
    if (item && item.pd_amount) {
      totalDeduction += Number(item.pd_amount);
    }
  });
  const printTime = moment().format('ll LTS');

  useEffect(() => {
    if (id) {
      getForEditPayroll(Number(id));
    }
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: () => {
      // return `@page { margin: 0.5in }`;
      return `
        @page {
          margin: 0;
        }
        @media print {          
          .print-header,
          .print-footer {
            display: none;
          }
        }
      `;
    },
  });

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  return (
    <>
      <BreadCrumb arrOfOption={['Payroll']} />
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Space style={{ marginBottom: '1rem' }}>
          <Link to='/payroll'>
            <Button type='primary'>
              <ArrowLeftOutlined />
              Return to Payroll List
            </Button>
          </Link>
          <Button type='primary' onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
        </Space>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
        >
          <div
            ref={printRef}
            style={{
              width: '8.27in',
              minHeight: '11.50in',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '2rem',
              paddingBottom: '0.5rem',
              borderRadius: '.3rem',
            }}
          >
            <div style={{ position: 'relative' }}>
              <InvoiceHeader />

              <TitleCenter title='Payroll' />

              {/* END HEADER */}
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    marginLeft: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography.Text className='changeFont'>
                    <b>Employee Name:</b> {payrollData?.employee_full_name}
                  </Typography.Text>
                  <Typography.Text className='changeFont'>
                    <b>Designation:</b> {payrollData?.designation_name}
                  </Typography.Text>
                  <Typography.Text className='changeFont'>
                    <b> Cell No:</b> {payrollData?.employee_mobile}
                  </Typography.Text>
                  {payrollData?.employee_address && (
                    <Typography.Text className='changeFont'>
                      <b> Address:</b> {payrollData?.employee_address}
                    </Typography.Text>
                  )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {payrollData?.payroll_vouchar_no && (
                    <Typography.Text className='changeFont'>
                      <b> Voucher No.:</b> {payrollData?.payroll_vouchar_no}
                    </Typography.Text>
                  )}

                  {payrollData?.payroll_salary_month && (
                    <Typography.Text className='changeFont'>
                      <b> Salary of month.:</b>{' '}
                      {dayjs(payrollData?.payroll_salary_month).format(
                        'MMMM-YYYY'
                      )}
                    </Typography.Text>
                  )}
                </div>
              </Row>
              {/* END EMPLOYEE DETAILS */}
              <div style={{ marginTop: '40px' }}>
                <Table
                  size='small'
                  bordered
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  style={{ backgroundColor: 'white' }}
                  pagination={false}
                  columns={[
                    {
                      title: 'SL.',
                      key: 'serial_number',
                      dataIndex: 'serial_number',
                      render(value, record, index) {
                        return index + 1;
                      },
                      align: 'center',
                    },
                    {
                      title: 'Date',
                      dataIndex: 'payroll_date',
                      render: (_, __) => (
                        <>{dayjs(__.payroll_date).format('DD-MM-YYYY')}</>
                      ),
                    },
                    {
                      title: 'Pay. Method',
                      key: 'payroll_pay_method',
                      dataIndex: 'payroll_pay_method',
                    },

                    {
                      title: 'Account Name',
                      dataIndex: 'account_name',
                    },
                    {
                      title: 'Base Salary',
                      dataIndex: 'payroll_salary',
                      key: 'payroll_salary',
                      render: (_, data) => <>{Number(data.payroll_salary)}</>,
                    },
                    {
                      title: 'Attendance',
                      dataIndex: 'payroll_attendance',
                      key: 'payroll_attendance',
                    },
                    {
                      title: 'Gross Salary',
                      dataIndex: 'gross_salary',
                      key: 'gross_salary',
                      render: (_, data) => <>{Number(data.gross_salary)}</>,
                    },
                    {
                      title: 'Total Salary',
                      dataIndex: 'payroll_net_amount',
                      key: 'payroll_net_amount',
                      render: (_, data) => (
                        <>{Number(data.payroll_net_amount)}</>
                      ),
                    },

                    {
                      title: 'Note',
                      dataIndex: 'payroll_note',
                    },
                  ]}
                  dataSource={payrollData ? [payrollData] : []}
                />
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Table
                    size='small'
                    bordered
                    className='invoiceBillingTable'
                    rowClassName={'invoiceBillingTd'}
                    locale={{ emptyText: ' ' }}
                    style={{
                      backgroundColor: 'white',
                      fontSize: '12px',
                      minWidth: '4in',
                      marginTop: '0.5in',
                      color: 'white',
                    }}
                    pagination={false}
                    columns={[
                      {
                        title: 'SL',
                        render: (_, item, index) => <> {index + 1} </>,
                      },
                      {
                        title: 'Category',
                        align: 'left',
                      },
                      {
                        title: 'Amount',
                      },
                      {
                        title: 'Cut',
                      },
                      {
                        title: 'Note',
                      },
                    ]}
                    summary={() => {
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {1}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <Text>Gross Salary</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              {Number(payrollData?.gross_salary)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            {/* <Table.Summary.Cell index={1} align='left' /> */}
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {2}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align='left'>
                              <Text>Mobile Bill</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              {Number(payrollData?.payroll_mobile_bill)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {3}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align='left'>
                              <Text className='changeFont'>Feed Allowance</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_food_bill)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {4}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              className='changeFont'
                              index={1}
                              align='left'
                            >
                              Performance Bonus
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_bonus)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={0}
                              className='changeFont'
                            >
                              {5}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              Sales Commission
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_commission)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {6}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              Festival Bonus
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_fastival_bonus)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {7}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              Travel Allowance
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_ta)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {8}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              Health Allowance
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_health)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {9}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              Incentive
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_incentive)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {10}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              House Rent
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Number(payrollData?.payroll_accommodation)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {11}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              Others
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className='changeFont'
                            >
                              {Fixed2(payrollData?.payroll_other1) +
                                Fixed2(payrollData?.payroll_other2) +
                                Fixed2(payrollData?.payroll_other3)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          {payrollData?.payroll_deductions && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                {12}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                align='left'
                                className='changeFont'
                              >
                                Deductions
                              </Table.Summary.Cell>

                              <Table.Summary.Cell
                                index={1}
                              ></Table.Summary.Cell>
                              <Table.Summary.Cell index={1} align='left'>
                                <Text type='danger' className='changeFont'>
                                  {Number(totalDeduction || 0)}
                                </Text>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell
                                index={1}
                                align='left'
                                className='changeFont'
                              >
                                {payrollData?.payroll_deduction_reason}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          )}

                          {Number(payrollData?.payroll_profit_share) > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                {12}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                align='left'
                                className='changeFont'
                              >
                                Profit Share
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1}>
                                {Number(payrollData?.payroll_profit_share)}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} align='left'>
                                <Text
                                  type='danger'
                                  className='changeFont'
                                ></Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                              ></Table.Summary.Cell>
                            </Table.Summary.Row>
                          )}

                          {payrollData?.payroll_advance && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                {12}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                align='left'
                                className='changeFont'
                              >
                                Advance Salary
                              </Table.Summary.Cell>

                              <Table.Summary.Cell
                                index={1}
                                align='left'
                              ></Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                className='changeFont'
                              >
                                <Text type='danger' className='changeFont'>
                                  {Number(payrollData?.payroll_advance || 0)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                align='left'
                              ></Table.Summary.Cell>
                            </Table.Summary.Row>
                          )}

                          {payrollData?.payroll_provident && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                {13}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                align='left'
                                className='changeFont'
                              >
                                Provident Fund
                              </Table.Summary.Cell>

                              <Table.Summary.Cell
                                index={1}
                                align='left'
                              ></Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                className='changeFont'
                              >
                                <Text type='danger' className='changeFont'>
                                  {Number(payrollData?.payroll_provident || 0)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={1}
                                align='left'
                              ></Table.Summary.Cell>
                            </Table.Summary.Row>
                          )}

                          <Table.Summary.Row style={{ background: '#E3E9EB' }}>
                            <Table.Summary.Cell
                              index={0}
                              colSpan={2}
                              className='changeFont'
                            >
                              Total:
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              align='left'
                              className='changeFont'
                            >
                              {Fixed2(payrollData?.gross_salary) +
                                Fixed2(payrollData?.payroll_mobile_bill) +
                                Fixed2(payrollData?.payroll_food_bill) +
                                Fixed2(payrollData?.payroll_bonus) +
                                Fixed2(payrollData?.payroll_commission) +
                                Fixed2(payrollData?.payroll_profit_share) +
                                Fixed2(payrollData?.payroll_other1) +
                                Fixed2(payrollData?.payroll_other2) +
                                Fixed2(payrollData?.payroll_other3) +
                                Fixed2(
                                  payrollData?.payroll_fastival_bonus ||
                                    0 + Fixed2(payrollData?.payroll_ta)
                                ) +
                                Fixed2(payrollData?.payroll_health) +
                                Fixed2(payrollData?.payroll_incentive) +
                                Fixed2(payrollData?.payroll_accommodation)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <Text type='danger' className='changeFont'>
                                {Number(payrollData?.payroll_advance || 0) +
                                  Number(totalDeduction || 0) +
                                  Number(payrollData?.payroll_provident || 0)}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row style={{ background: '#E3E9EB' }}>
                            <Table.Summary.Cell
                              index={0}
                              colSpan={2}
                              className='changeFont'
                            >
                              Net Total (Amount - Cut):
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              className='changeFont'
                              index={1}
                              align='left'
                              colSpan={2}
                            >
                              {Number(payrollData?.payroll_net_amount)}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell
                              colSpan={2}
                              index={1}
                              align='left'
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </div>

                <Typography
                  style={{
                    fontSize: '12px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    textAlign: 'right',
                    marginTop: '5px',
                  }}
                >
                  {payrollData && (
                    <NumToWord
                      number={Number(payrollData?.payroll_net_amount)}
                    />
                  )}
                </Typography>
              </div>
            </div>

            {/* END TABLE CONTENTS */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0px 30px 40px 30px',
              }}
            >
              <p
                style={{
                  borderTop: '1px dashed black',
                  paddingTop: '5px',
                  fontSize: '12px',
                }}
              >
                Employee Signature
              </p>

              <Typography.Text
                style={{
                  marginTop: '10px',
                  fontSize: '10px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {printTime}
              </Typography.Text>

              <p
                style={{
                  borderTop: '1px dashed black',
                  paddingTop: '5px',
                  fontSize: '12px',
                }}
              >
                Authorized Signature
              </p>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </>
  );
}
