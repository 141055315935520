import { ColumnsType } from 'antd/lib/table';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { IAllInvoices } from '../../../../../common/types/commonInterfaces';
import { Fixed2, FormatDate } from '../../../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../../../layout/components/SearchInvoices';

export const clientInvoiceUtil = (): ColumnsType<IAllInvoices> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      render: (_, data, index) => <>{index + 1}</>,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => (
        <Link
          to={
            '../' +
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },

    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'net_toal',
      align: 'center',
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
      render: (_, record) => Number(record?.net_total),
    },
    {
      title: 'Received',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'center',
      render(value, record, index) {
        return Fixed2(value);
      },
    },
    {
      title: 'Due Amount',
      align: 'center',
      render(value, record, index) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {Number(record?.net_total) -
              Number(record?.invclientpayment_amount)}
          </span>
        ) : (
          <Tag color='success'>PAID</Tag>
        );
      },
    },

    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },

    {
      title: 'Sales by',
      dataIndex: 'sales_by',
      key: 'sales_by',
    },

    {
      title: 'Sales Date',
      key: 'invoice_date',

      render: (_, data) => <>{FormatDate(data.invoice_date)}</>,
      align: 'center',
    },
  ];
};
