import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import InvoiceActivityLogs from '../../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs';
import CommonViewInvoice from '../../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import TabDetailsView from '../../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';
import TabHajjRefundDetails from '../../../../../components/common/Invoice/ViewInvoiceInfo/TabHajjRefundDetails';
import TabPaymentView from '../../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView';
import ViewInvoiceHeader from '../../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import ViewInvoiceHajjBilling from '../../Components/ViewInvoiceHajjBilling';
import ViewInvoiceHajjDetails from '../../Components/ViewTabDetailsInvoiceHajj';
import { useGetViewInvoiceHajjQuery } from '../../api/endpoints/invoicehajjEndpoints';
import { IPermission } from '../../../../../common/types/commonTypes';

type Props = {
  permission?: IPermission;
};
const ViewInvoiceHajj = ({ permission }: Props) => {
  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);
  // GET INVOICE AIRTICKET DETAILS
  const {
    data: invoices,
    isFetching,
    isLoading,
  } = useGetViewInvoiceHajjQuery(invoice_id);
  const invoiceData = invoices?.data;
  const invoice_category_id = invoiceData?.invoice_category_id;
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewRefundDetailsRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const isViewRefundDetails = [3, 26, 31].includes(invoice_category_id || 0);

  const [selectedForPrint, setSelectedForPrint] = useState<
    'viewInvoice' | 'costDetails' | 'payments' | 'activity' | 'refund_details'
  >('viewInvoice');

  let selectedPrintableRef = viewInvoicePrintableRef;
  switch (selectedForPrint) {
    case 'viewInvoice':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case 'costDetails':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case 'payments':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case 'activity':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;

    case 'refund_details':
      selectedPrintableRef = viewRefundDetailsRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
        
      />

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint('viewInvoice');
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint('costDetails');
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint('payments');
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint('activity');
              break;
            case 5:
              setSelectedForPrint && setSelectedForPrint('refund_details');
              break;

            default:
              break;
          }
        }}
        type='card'
        style={{ marginTop: '15px' }}
        items={[
          {
            label: 'Invoice',
            key: '1',
            children: isLoading ? (
              <LoadingIndicator />
            ) : (
              invoiceData && (
                <CommonViewInvoice
                  invoiceData={invoiceData}
                  viewInvoicePrintableRef={viewInvoicePrintableRef}
                  heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                  permission={permission}
                  children={
                    <ViewInvoiceHajjBilling
                      billing_information={invoiceData.billing_information}
                      haji_information={invoiceData.haji_information}
                      hotel_information={invoiceData.hotel_information}
                      transport_information={invoiceData.transport_information}
                    />
                  }
                />
              )
            ),
          },
          {
            label: 'Details',
            key: '2',
            children: invoiceData && (
              <TabDetailsView
                invoiceDetails={invoiceData}
                children={
                  <ViewInvoiceHajjDetails invoiceDetails={invoiceData} />
                }
                viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
              />
            ),
          },
          {
            label: 'Payments',
            key: '3',
            children: (
              <TabPaymentView
                invoiceDetails={invoiceData}
                viewInvoicePaymentsRef={viewInvoicePaymentsRef}
              />
            ),
          },
          {
            label: 'Activity Log',
            key: '4',
            children: (
              <InvoiceActivityLogs
                id={invoice_id}
                viewInvoiceAcivityRef={viewInvoiceAcivityRef}
              />
            ),
          },
          isViewRefundDetails
            ? {
                label: 'Refund Details',
                key: '5',
                children: invoiceData && (
                  <TabHajjRefundDetails
                    viewInvoicePaymentsRef={viewRefundDetailsRef}
                  />
                ),
              }
            : {
                label: 'Refund Details',
                key: '5',
                disabled: true,
              },
        ]}
      ></Tabs>
    </>
  );
};

export default ViewInvoiceHajj;
