import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons/lib/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Form,
  Row,
} from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Fixed2 } from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetExcelOverAllProfitLossQuery,
  useLazyGetOverAllProfitlossQuery,
} from '../endpoints/profitLossEndpoints';
import { IProfitLossFormData } from '../types/overAllprofitLossTypes';

const Over_All_Profit_Loss = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const location = useLocation();

  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [fetchExcel] = useLazyGetExcelOverAllProfitLossQuery();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: '',
  });

  const [fetchOverAllProfitLoss, { data, isLoading, isFetching }] =
    useLazyGetOverAllProfitlossQuery();

  const overAllProfit = data?.data;
  const toDayDate = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    const date_range = `from_date=${toDayDate}&to_date=${toDayDate}`;
    const body: any = {
      date_range: date_range,
    };
    fetchOverAllProfitLoss(body);
    setExcelQuery({
      query: date_range,
    });

    setParamData({
      date_range: date_range,
      id: '',
    });
  }, []);

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get('daily');

    const monthFormDate = searchParams.get('from_date');
    const monthToDate = searchParams.get('to_date');

    const yearFormDate = searchParams.get('y_from_date');
    const yearToDate = searchParams.get('y_to_date');

    if (dailyValue) {
      onFinish({
        date_range: [dailyValue, dailyValue],
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue('date_range', [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      onFinish({
        date_range: [monthFormDate, monthToDate],
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue('date_range', [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      onFinish({
        date_range: [yearFormDate, yearToDate],
      });
    }
  }, [location.search]);

  const onFinish = async (values: IProfitLossFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: any = {
      date_range: date_range,
    };
    setExcelQuery({
      query: date_range,
    });
    setDate_range_value(date_range);
    setParamData({
      date_range: date_range,
      id: '',
    });
    await fetchOverAllProfitLoss(body);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Profit_Loss_${date_range_value}`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <ConfigProvider>
        <Card>
          <FormHeaderTitle title='Gross Profit/Loss' />
          <Descriptions size='small' column={1} bordered>
            <DescriptionsItem
              label={
                <Link
                  to={`../reports/daily_sales_report?${paramData?.date_range}`}
                >
                  Sales Amount
                </Link>
              }
            >
              {overAllProfit?.total_sales_price}
            </DescriptionsItem>
            <DescriptionsItem
              label={
                <Link
                  to={`../reports/vendor_wise_purchase_and_payment?${paramData?.date_range}`}
                >
                  Purchase Price
                </Link>
              }
            >
              {overAllProfit?.total_cost_price}
            </DescriptionsItem>
            <DescriptionsItem
              label={
                <Link to={'../reports/monthly_sales_and_earning'}>
                  Sales Profit
                </Link>
              }
            >
              {Fixed2(overAllProfit?.total_sales_profit)}
            </DescriptionsItem>
            <DescriptionsItem label={'Refund Profit'}>
              {Fixed2(overAllProfit?.total_refun_profit)}
            </DescriptionsItem>
            <DescriptionsItem
              label={
                <Link to={'../tour-invoice/all'}>Tour Package Profit</Link>
              }
            >
              {overAllProfit?.tour_profit}
            </DescriptionsItem>
            <DescriptionsItem label='Service Charge'>
              {overAllProfit?.service_charge || 0}
            </DescriptionsItem>
            <DescriptionsItem label='Gross Profit/Loss'>
              {Fixed2(overAllProfit?.gross_profit_loss)}
            </DescriptionsItem>
            <DescriptionsItem
              label={<Link to={'../reports/void'}>Void Charge</Link>}
            >
              {Fixed2(overAllProfit?.void_profit_loss)}
            </DescriptionsItem>
          </Descriptions>
        </Card>
      </ConfigProvider>

      {/* Incentive Income */}

      {overAllProfit && (
        <ConfigProvider>
          <Card style={{ marginTop: '1rem' }}>
            <FormHeaderTitle title='Incentive Income' />
            <Descriptions size='small' column={1} bordered>
              <DescriptionsItem
                label={
                  <Link to={'../accounts/incentive'}>Incentive Income </Link>
                }
              >
                {Number(overAllProfit?.total_incentive_income) || 0}
              </DescriptionsItem>

              <DescriptionsItem
                label={
                  <Link to={'../accounts/noninvoice'}>Non Invoice Income</Link>
                }
              >
                {Number(overAllProfit?.non_invoice) || 0}
              </DescriptionsItem>
            </Descriptions>
          </Card>
        </ConfigProvider>
      )}

      {/* Expense */}
      <ConfigProvider>
        <Card style={{ marginTop: '1rem' }}>
          <FormHeaderTitle title='Expense' />
          <Descriptions size='small' column={1} bordered>
            <DescriptionsItem
              label={
                <Link
                  to={`../expense?${
                    date_range_value
                      ? date_range_value
                      : `from_date=${toDayDate}&to_date=${toDayDate}`
                  }`}
                >
                  Office Expense
                </Link>
              }
            >
              {Number(overAllProfit?.expense_total) || 0}
            </DescriptionsItem>

            <DescriptionsItem label={<Link to={'../payroll'}>Salaries</Link>}>
              {Number(overAllProfit?.total_employee_salary) || 0}
            </DescriptionsItem>
            <DescriptionsItem
              label={<Link to={'../moneyreceipt/agent'}>Agent Payment</Link>}
            >
              {Number(overAllProfit?.aget_payment) || 0}
            </DescriptionsItem>
            <DescriptionsItem label={'Client & Money Receipt Discount'}>
              {Number(overAllProfit?.total_discount) || 0}
            </DescriptionsItem>
            <DescriptionsItem
              label={
                <Link
                  to={`/reports/transaction_charge_report?${paramData?.date_range}`}
                >
                  Bank Charge
                </Link>
              }
            >
              {Number(overAllProfit?.online_charge) || 0}
            </DescriptionsItem>
            <DescriptionsItem
              label={
                <Link to={`/reports/ait_report?${paramData?.date_range}`}>
                  Vendor Ait
                </Link>
              }
            >
              {Number(overAllProfit?.vendor_ait) || 0}
            </DescriptionsItem>
            <DescriptionsItem label='Total Expenses'>
              {Number(overAllProfit?.overall_expense) || 0}
            </DescriptionsItem>
          </Descriptions>
        </Card>
      </ConfigProvider>

      {/* Net Profit/Loss */}
      <ConfigProvider>
        <Card style={{ marginTop: '1rem' }}>
          <FormHeaderTitle title='Net Profit/Loss' />
          <Descriptions
            size='small'
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <DescriptionsItem label='Total Gross Profit/Loss'>
              {overAllProfit?.total_gross_profit_loss}
            </DescriptionsItem>
            <DescriptionsItem label='Total Expense'>
              {overAllProfit?.overall_expense}
            </DescriptionsItem>
            <DescriptionsItem
              style={{
                fontWeight: 'bold',
                color:
                  overAllProfit?.net_profit_loss &&
                  overAllProfit?.net_profit_loss > 0
                    ? 'green'
                    : 'red',
              }}
              label='Net Profit/Loss'
            >
              {Math.abs(overAllProfit?.net_profit_loss || 0).toFixed(2)}
            </DescriptionsItem>
          </Descriptions>
        </Card>
        {(isLoading || isFetching) && <LoadingIndicator />}
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'OverAll Profit Loss' }}
      />
    </div>
  );
  return (
    <div>
      <BreadCrumb
        arrOfOption={['Report', 'ProfitLoss', 'OverAll Profit Loss']}
      />

      <Row justify={'space-between'}>
        <Col lg={18}>
          <Form layout='vertical' form={form} onFinish={onFinish}>
            <ReportsHeader
              size={8}
              required
              date_range_value={date_range_value}
              date_range
            />
          </Form>
        </Col>

        <Col lg={6}>
          <Button type='primary' style={{ margin: 15 }} onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
          <Button
            type='primary'
            onClick={() => {
              fetchExcel({
                query: excelQuery.query,
              });
            }}
          >
            <FileTextOutlined />
            Excel Report
          </Button>
        </Col>
      </Row>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
};

export default Over_All_Profit_Loss;
