import { UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  FormButton,
  FormInputItem,
  FormInputPassword,
  MobileInput,
} from '../../../../components/common/FormItem/FormItems';
import EmailWithAddon from '../components/EmailWithAddon';
import { SelectRoles } from '../components/SelectRoles';
import {
  useCreateUserMutation,
  useLazyCheckAgencyUsernameQuery,
} from '../endpoints/userEndpoints';
import { IUserFormData } from '../types/user.types';

type Props = {};

export default function AddUserAccount({}: Props) {
  const [usernameIsExist, { data: usernameIsExisting }] =
    useLazyCheckAgencyUsernameQuery();

  const handleUserChange = (e: any) => {
    let timeoutId: NodeJS.Timeout;

    const userName = e.target.value;
    timeoutId = setTimeout(() => {
      usernameIsExist(userName).then(() => {
        form.validateFields(['user_username']);
      });
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [selectedAddonAfter, setSelectedAddonAfter] = useState('@gmail.com');

  const [open, setOpen] = useState(false);

  const [addUser, { isLoading, isSuccess, isError }] = useCreateUserMutation();

  const showDrawer = () => {
    setOpen(true);
    form.resetFields();
    setSelectedAddonAfter('@gmail.com');
    form.setFieldValue('user_dial_code', '+88');
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    const body: IUserFormData = {
      ...values,
      user_email:
        email.includes('undefined') || !email.split('@')[0] ? undefined : email,
    };
    addUser(body);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill up the required fields');
  };

  useEffect(() => {
    if (isSuccess) {
      onCancel();
      setSelectedAddonAfter('@gmail.com');
      navigate('/user/view');
    }
  }, [isLoading]);

  useEffect(() => {
    form.setFieldValue('user_dial_code', '+88');
  }, []);

  return (
    <>
      <Button
        type='primary'
        onClick={showDrawer}
        icon={<UserAddOutlined />}
        loading={isLoading}
      >
        Add New User
      </Button>
      <Drawer
        title='Create a new user'
        width={window.innerWidth <= 576 ? 300 : 700}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={onCancel}>Cancel</Button>}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label='First Name' style={{ marginBottom: 0 }}>
                <Form.Item
                  name={'user_first_name'}
                  rules={[{ required: true }]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                  }}
                >
                  <Input placeholder='First name' />
                </Form.Item>
                <Form.Item
                  name={'user_last_name'}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    margin: '0 8px',
                  }}
                >
                  <Input placeholder='Last name' />
                </Form.Item>
              </Form.Item>
            </Col>

            <FormInputItem
              required
              name='user_username'
              label='User Name'
              onKeyUp={(e: any) => handleUserChange(e)}
              rules={[
                usernameIsExisting
                  ? {
                      validator: async (_, value) => {
                        if (!usernameIsExisting?.data) {
                          return Promise.reject('Username already exists!');
                        }
                        return Promise.resolve();
                      },
                    }
                  : {},
                {
                  pattern: /^[^\s]+$/,
                  message: 'Username cannot contain spaces',
                },
              ]}
              size={12}
            />

            <EmailWithAddon
              label='Email'
              name='user_email'
              setEmail={setEmail}
              form={form}
              required
              selectedAddonAfter={selectedAddonAfter}
              setSelectedAddonAfter={setSelectedAddonAfter}
            />

            <MobileInput
              label='Mobile'
              name='user_mobile'
              size={12}
              countryCode
              countryCodeFormName={'user_dial_code'}
            />

            <SelectRoles
              name='user_role_id'
              label='User Role'
              required
              size={12}
            />

            <FormInputPassword
              name='password'
              label='Password'
              required
              size={12}
            />

            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                name='confirm'
                label='Confirm Password'
                labelCol={{ span: 12 }}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Password confirmation does not match.')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Confirm user password' />
              </Form.Item>
            </Col>
          </Row>

          <FormButton label='Submit' loading={isLoading} />
        </Form>
      </Drawer>
    </>
  );
}
