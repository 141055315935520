import { Col, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  DateInput,
  FormInput,
  FormInputItem,
  NumberInput,
  NumberInputCurrencyFormat,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectAirport,
  SelectOptionsInput,
  SelectProductWiseVendors,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllAirlinesQuery } from '../../Configuration/airline/Endpoints/airlineEndpoints';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyTicketIsExistQuery } from '../../Invoice_Air_Ticket/api/endpoints/airticketInvoiceEndpoints';
import { SeperateValueObject } from '../../Invoice_Air_Ticket/types/invoiceAirTicketTypes';
import { IReissueFormSubmit } from '../Type/InoviceReissue.interface';

type Props = {
  form: FormInstance<any>;
  handleCalculation: () => void;
  return_date_hide?: boolean;
  invoices: IReissueFormSubmit[];
  editIndex?: number;
  commissionAmount?: number;
};

export default function InvReIssueTicketInfo({
  form,
  handleCalculation,
  return_date_hide,
  invoices,
  editIndex,
  commissionAmount,
}: Props) {
  const [ticketIsExist, { data: ticketIsExisting }] =
    useLazyTicketIsExistQuery();
  const [handleTicketNo, setHandleTicketNo] = useState<string>('');
  const airelineData = useGetAllAirlinesQuery();
  const aireline = airelineData?.data?.data;
  const airLineId: string[] = [];
  const airlineTitleAndId: SeperateValueObject[] = [];
  aireline?.forEach((el) => {
    airlineTitleAndId.push({ id: el.airline_id, title: el.airline_name });
    airLineId.push(String(el.airline_id));
  });
  useEffect(() => {
    let airticket_airline_id;
    if (airlineTitleAndId.length) {
      // if (airLineId.includes(handleTicketNo)) {
      const airlineFind = airlineTitleAndId.find(
        (item) => item.title.split('-').slice(-1)[0] === handleTicketNo
      );
      if (airlineFind?.id) {
        airticket_airline_id = Number(airlineFind?.id);
      } else {
        airticket_airline_id = form.getFieldValue('airticket_airline_id');
      }
    } else {
      airticket_airline_id = undefined;
    }
    form.setFieldsValue({ airticket_airline_id });
  }, [handleTicketNo]);

  useEffect(() => {
    const ticketFetcing = setTimeout(async () => {
      if (handleTicketNo) {
        await ticketIsExist(handleTicketNo);
        const ticket = form.getFieldValue('airticket_ticket_no');
        if (ticket) {
          form.validateFields(['airticket_ticket_no']);
        }
      }
    }, 800);
    return () => clearTimeout(ticketFetcing);
  }, [handleTicketNo]);
  const [vendorSelectsInstanct, setInstanceVendorSelect] = useState<number>();
  useEffect(() => {
    if (vendorSelectsInstanct) {
      form.setFieldValue(
        'airticket_comvendor',
        'vendor-' + vendorSelectsInstanct
      );
    }
  }, [vendorSelectsInstanct]);

  //

  const appConfig = useAppSelector((state) => state.configSlice);
  const isIata = appConfig && appConfig?.tac_airticket_type === 'IATA';

  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Ticket Details 🎟️' />

      <Row gutter={[20, 0]}>
        <Col lg={18}>
          <Row gutter={[5, 0]}>
            <FormInput
              onChange={setHandleTicketNo}
              label='Ticket No:'
              name='airticket_ticket_no'
              required
              size={6}
              rules={[
                {
                  validator: async (_, value) => {
                    if (
                      (editIndex || editIndex === 0) &&
                      invoices?.length &&
                      value
                    ) {
                      if (invoices?.length && value) {
                        const find = invoices.find(
                          (item) =>
                            item?.airticket_ticket_no === value &&
                            invoices[editIndex].airticket_ticket_no !== value
                        )?.airticket_ticket_no;
                        if (find) {
                          return Promise.reject(
                            'Ticket already exist in list!'
                          );
                        }
                      }
                      if (invoices[editIndex].airticket_ticket_no === value) {
                        return Promise.resolve();
                      }
                    } else if (invoices?.length && value) {
                      const find = invoices.find(
                        (item) => item?.airticket_ticket_no === value
                      )?.airticket_ticket_no;
                      if (find) {
                        return Promise.reject('Ticket already exist in list!');
                      }
                    } else if (ticketIsExisting?.data && value) {
                      return Promise.reject('Ticket already exist!');
                    } else return Promise.resolve();
                  },
                },
              ]}
            />
            <NumberInputCurrencyFormat
              label='Client Price:'
              name='airticket_client_price'
              required
              size={6}
              mdSize={8}
              smSize={12}
              onChange={() => {
                handleCalculation();
              }}
              maxChar={14}
            />

            {isIata && (
              <>
                <NumberInputCurrencyFormat
                  label='Penalties'
                  required
                  name='airticket_penalties'
                  placeholder='Penalties price here'
                  size={6}
                  mdSize={8}
                  min='0'
                  maxChar={14}
                />
                <NumberInputCurrencyFormat
                  required
                  label='Fare Difference'
                  name='airticket_fare_difference'
                  placeholder='Fare difference here'
                  size={6}
                  mdSize={8}
                  min='0'
                  maxChar={14}
                />
                <NumberInputCurrencyFormat
                  required
                  label={'Commission = ' + commissionAmount + ' /-'}
                  name='airticket_commission_percent'
                  placeholder='Fare commission here'
                  size={6}
                  mdSize={8}
                  min='0'
                  max={10}
                  maxChar={1}
                />
              </>
            )}

            <NumberInputCurrencyFormat
              readOnly={isIata}
              required={!isIata}
              label={isIata ? 'Gross Fare' : 'Purchase Price'}
              name='airticket_purchase_price'
              size={6}
              mdSize={8}
              smSize={12}
              onChange={() => {
                handleCalculation();
              }}
              maxChar={14}
            />

            <SelectAirline
              label={'Airline'}
              name='airticket_airline_id'
              required
              size={6}
            />

            <SelectProductWiseVendors
              setInstanceVendorSelect={setInstanceVendorSelect}
              label='Vendor:'
              name='airticket_comvendor'
              required
              size={6}
              productId={108}
              dependencies={['invoice_combclient_id']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value: string) {
                    if (getFieldValue('invoice_combclient_id') === value) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: "Client and vendor can't be same!",
                }),
              ]}
            />

            <NumberInput
              label='Extra Fee: '
              name='airticket_extra_fee'
              size={6}
              onChange={() => {
                handleCalculation();
              }}
              min='0'
              maxChar={10}
            />

            <NumberInput
              readOnly
              label='AIT'
              name='airticket_ait'
              size={6}
              min='0'
              maxChar={10}
            />

            <SelectAirport
              label='Route/Sector :'
              name='airticket_route_or_sector'
              mode='multiple'
              size={6}
              form={form}
              fullNamePath={'airticket_route_or_sector'}
            />
            <FormInputItem label='PNR :' name='airticket_pnr' size={6} />
            <DateInput
              label='Issue Date :'
              name='airticket_issue_date'
              size={6}
            />
            <DateInput
              label='Journey Date :'
              name='airticket_journey_date'
              size={6}
            />

            {!return_date_hide && (
              <DateInput
                label='Return Date :'
                name='airticket_return_date'
                size={6}
              />
            )}

            <SelectOptionsInput
              label='Select airticket class'
              name='airticket_classes'
              options={[
                'Economy',
                'Premium Economy',
                'Business',
                'First Class',
              ]}
              size={6}
            />
          </Row>
        </Col>
        <Col
          lg={6}
          style={{
            border: '1px solid lightgray',
            padding: '0px 5px',
            borderRadius: '10px',
          }}
        >
          <Row gutter={[5, 0]}>
            <NumberInput
              readOnly
              label='Client Price:'
              name='clientPriceSet'
              placeholder='Client price here'
              size={24}
              mdSize={8}
              smSize={12}
              min='0'
              maxChar={14}
            />

            <NumberInput
              readOnly
              label='Actual Purchase Price :'
              name='purchasePriceSet'
              placeholder='purchase price here'
              size={24}
              mdSize={8}
              min='0'
              maxChar={14}
            />
            <NumberInput
              readOnly
              label='Profit :'
              name='airticket_profit'
              placeholder='Profit here'
              size={24}
              mdSize={8}
              min='0'
              maxChar={14}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
