import { Tabs } from 'antd';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import InvoiceActivityLogs from '../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs';
import CommonViewInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import TabDetailsView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';

import TabPaymentView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView';
import ViewInvoiceHeader from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import { useGetViewInvoiceVisaQuery } from '../../Api/Endpoints/invoiceVisaEndpoints';
import InvoiceVisaBilling from '../../Components/InvoiceVisaBilling';

import InvoiceVisaTabDetails from '../../Components/InvoiceVisaTabDetails';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { IPermission } from '../../../../common/types/commonTypes';

type Props = {
  permission?: IPermission;
};
const ViewInvoiceVisa = ({ permission }: Props) => {
  const invoice_id = Number(useParams().id);

  const {
    data: invoices,
    isFetching,
    isLoading,
  } = useGetViewInvoiceVisaQuery(invoice_id);
  const invoiceData = invoices?.data;

  const invoice_category_id = invoiceData?.invoice_category_id;

  const viewInvoicePrintableRef = useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = useRef<HTMLDivElement>(null);

  const header_ref = useRef<HTMLDivElement>(null);
  const signiture_ref = useRef<HTMLDivElement>(null);
  const subtotal_ref = useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = useState<
    'viewInvoice' | 'costDetails' | 'payments' | 'activity'
  >('viewInvoice');

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case 'viewInvoice':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case 'costDetails':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case 'payments':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case 'activity':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />

      {isFetching || isLoading ? <LoadingIndicator /> : ''}

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint('viewInvoice');
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint('costDetails');
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint('payments');
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint('activity');
              break;

            default:
              break;
          }
        }}
        type='card'
        style={{ marginTop: '15px' }}
        items={[
          {
            label: 'Invoice',
            key: '1',
            children: invoiceData && (
              <CommonViewInvoice
                invoiceData={invoiceData}
                viewInvoicePrintableRef={viewInvoicePrintableRef}
                heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                permission={permission}
                children={
                  <InvoiceVisaBilling
                    billing_information={invoiceData.billing_information}
                    passport_information={invoiceData.passport_information}
                  />
                }
              />
            ),
          },
          {
            label: 'Details',
            key: '2',
            children: invoiceData && (
              <TabDetailsView
                invoiceDetails={invoiceData}
                children={
                  <InvoiceVisaTabDetails invoiceDetails={invoiceData} />
                }
                viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
              />
            ),
          },
          {
            label: 'Payments',
            key: '3',
            children: (
              <TabPaymentView
                invoiceDetails={invoiceData}
                viewInvoicePaymentsRef={viewInvoicePaymentsRef}
              />
            ),
          },
          {
            label: 'Activity Log',
            key: '4',
            children: (
              <InvoiceActivityLogs
                id={invoice_id}
                viewInvoiceAcivityRef={viewInvoiceAcivityRef}
              />
            ),
          },
        ]}
      ></Tabs>
    </>
  );
};

export default ViewInvoiceVisa;
