import { Col, DatePicker, Form, Row, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import { useGetAllAgentProfileQuery } from '../../modules/Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import FormHeaderTitle from '../../modules/Invoice(Visa)/Components/FormHeaderTitle';
import ActivityLogOption from '../../modules/Reports/Activity_Log/components/ActivityLogOption';
import TrashListOption from '../../modules/Reports/TrashList/components/TrashListOption';
import { IProps } from '../utils/componentTypes';
import { FormButton, FormInput } from './FormItem/FormItems';
import {
  SelectAccountList,
  // SelectAccountList,
  SelectAgent,
  SelectAirline,
  SelectClientCategory,
  SelectClients,
  SelectClientsOnly,
  SelectCombined,
  SelectCountry,
  SelectEmployee,
  SelectExpenseHead,
  SelectExpenseSubHead,
  SelectHajjigroup,
  SelectOptionsInput,
  SelectPassportStatus,
  SelectProduct,
  SelectTicketNo,
  SelectUsers,
  SelectVendors,
  SelectVendorsOnly,
  SelectVisa,
  SelectVisaType,
} from './FormItem/SelectCustomFeilds';
import { rangePresets } from '../../common/utils/common.utils';
const ReportsHeader = ({
  setCombId,
  showAll,
  vendorID,
  showSelectCombined,
  onYearChange,
  showSelectGroup,
  onDateChange,
  onMonthChange,
  reports_search,
  reports_title,
  date_range,
  date_select,
  month_select,
  year_select,
  search_placeholder,
  showSelectClients,
  showSelectClientsOnly,
  showSelectHead,
  showSelecSubHead,
  showSelectVendors,
  showSelectVendorOnly,
  showSelectAgents,
  showSelectEmployee,
  showSelectMarketing,
  reports_search_pass,
  showCountry,
  showSelectTicket,
  showSelectClientCategory,
  showGds,
  showVisaType,
  showAirline,
  showPassPortStatus,
  showSelectProduct,
  showSelectTrashList,
  showSelectUser,
  required,
  showActvityLogs,
  showTrashList,
  showYear,
  showSelectAccountReportList,
  setSubHeadId,
  size,
  disabled,
  selectVisa,
  selectAccountList,
}: IProps) => {
  // SET AGENT PERCENTAGE VALUE
  const { data } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = data?.data;

  return (
    <ReportsStyle>
      <div className=' p-5'>
        {reports_title && <FormHeaderTitle title={reports_title as string} />}

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} align='middle'>
          {showSelectClientsOnly && (
            <SelectClientsOnly
              disabled={disabled}
              required
              name='client_id'
              size={size || 6}
              label='Select Client'
              offDropDown
            />
          )}

          {selectVisa && <SelectVisa showAll />}
          {selectAccountList && <SelectAccountList showAll />}
          {showSelectClients && (
            <SelectClients
              name='invoice_combclient_id'
              label='Select Client'
              required
              placeholder='Select client'
              size={6}
              showAll
              offDropDown
            />
          )}
          {showSelectClientCategory && (
            <SelectClientCategory
              required
              name='category_id'
              size={6}
              label='Select Client Category'
              placeholder='Select Client Category'
              showAll
            />
          )}
          {showSelectCombined && (
            <SelectCombined
              required
              name='client_id'
              size={setCombId ? 9 : 6}
              disabled={disabled}
              label='Select Client'
              placeholder='Select Client'
              setCombId={setCombId}
            />
          )}

          {showYear && (
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name='possible_year' label='Year' required>
                <DatePicker picker='year' />
              </Form.Item>
            </Col>
          )}
          {showSelectGroup && (
            <SelectHajjigroup
              required
              name='group_id'
              size={6}
              label='Group'
              placeholder='Select Group'
            />
          )}
          {showTrashList && <TrashListOption />}
          {showActvityLogs && <ActivityLogOption />}
          {showSelectUser && (
            <SelectUsers
              required
              name='user_id'
              size={6}
              label='Select User'
              placeholder='Select User'
            />
          )}
          {showSelectVendorOnly && (
            <SelectVendorsOnly
              required={required}
              name='vendor_id'
              size={6}
              label='Select Vendor'
              placeholder='Select Vendor'
              vendorID={vendorID}
            />
          )}

          {showSelectVendors && (
            <SelectVendors
              required={required}
              name='invoice_combvendor_id'
              size={6}
              showAll
              label='Select Vendor'
              placeholder='Select Vendor'
            />
          )}
          {showSelectAgents && (
            <SelectAgent
              required={required}
              name='agent_id'
              size={6}
              label='Select Agents'
              getAllAgentProfile={getAllAgentProfile || []}
            />
          )}

          {showPassPortStatus && (
            <SelectPassportStatus
              required
              showAll
              name='status_name'
              label='Select Passport Status'
              size={6}
            />
          )}

          {showSelectTicket && (
            <SelectTicketNo
              required
              name='ticket_id'
              label='Select Ticket'
              showAll
              size={6}
            />
          )}

          {showGds && (
            <Col span={6} xs={24} sm={24} md={24} lg={8}>
              <Form.Item name='gds' label='GDS' required>
                <Select allowClear placeholder='Select GDS'>
                  <Select.Option value='all'>All</Select.Option>
                  <Select.Option value='Galileo'>Galileo</Select.Option>
                  <Select.Option value='Sabre'>Sabre</Select.Option>
                  <Select.Option value='Amadeus'>Amadeus</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}

          {showSelectTrashList && (
            <SelectOptionsInput
              label='Select Trash'
              name='trash_list'
              options={[
                'Invoice',
                'Client',
                'Vendor',
                'Money Reciept',
                'Expense',
                'Quotation',
                'Other Refund',
              ]}
              size={12}
            />
          )}
          {showSelectAccountReportList && (
            <SelectOptionsInput
              required
              label='Select Account Report'
              name='account_report_list'
              options={[
                'getAllReport',
                'getNonInvoiceReport',
                'getInvestmentReport',
              ]}
              size={12}
            />
          )}

          {showSelectProduct && (
            <SelectProduct
              required
              size={6}
              name='product_id'
              placeholder='select Item'
              label='Select Product'
              showAll
            />
          )}

          {showSelectHead && (
            <SelectExpenseHead
              required
              name='head_expense'
              size={6}
              label='Select Head Expense'
              placeholder='Select Head of Expense'
              showAll
            />
          )}
          {showSelecSubHead && (
            <SelectExpenseSubHead
              setSubHeadId={setSubHeadId}
              name='expenseSubHead'
              size={6}
              showAll
              required
              label='Select Sub Head Expense'
              placeholder='Select Sub Head of Expense'
            />
          )}
          {showSelectEmployee && (
            <SelectEmployee
              required
              offDropDown
              showAll
              name='employee_id'
              size={5}
              label='Select Employee'
              placeholder='Select Employee'
            />
          )}
          {showSelectMarketing && (
            <SelectExpenseSubHead
              name='marketing-source'
              size={8}
              label='Select marketing source'
              placeholder='Select marketing source'
            />
          )}

          {showCountry && (
            <SelectCountry
              showAll
              required
              name='country_id'
              label='Select Country'
            ></SelectCountry>
          )}
          {showAirline && (
            <SelectAirline
              name='airline_id'
              label='Select Airline'
              required
            ></SelectAirline>
          )}

          {showVisaType && (
            <SelectVisaType name='visawise-profitloss' label='Select Visa' />
          )}

          {reports_search && (
            <Col span={8} xs={24} sm={24} md={12} lg={8}>
              <Form.Item label={'Search Client'}>
                <Input placeholder={search_placeholder} />
              </Form.Item>
            </Col>
          )}
          {reports_search_pass && (
            <FormInput
              required
              name={'passport_no'}
              label={'Search Passport'}
            />
          )}

          {date_range && (
            <Col
              span={8}
              xs={24}
              sm={24}
              md={12}
              lg={size || (setCombId && 10) || 5}
              style={{ marginRight: 15 }}
            >
              <Form.Item
                label='Date Range'
                name='date_range'
                rules={[
                  { required: required, message: ` Date Range is required!` },
                ]}
              >
                <DatePicker.RangePicker
                  presets={rangePresets}
                  style={{ width: '100%' }}
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                  // onChange={onDateRangeChange}
                />
              </Form.Item>
            </Col>
          )}

          {date_select && (
            <Col>
              <Form.Item label='Select Date' required name='date'>
                <DatePicker onChange={onDateChange} format={'DD-MM-YYYY'} />
              </Form.Item>
            </Col>
          )}
          {month_select && (
            <Col>
              <Form.Item label='Select Month' required name='month'>
                <DatePicker picker='month' onChange={onMonthChange} />
              </Form.Item>
            </Col>
          )}
          {year_select && (
            <Col span={6} xs={24} sm={24} md={24} lg={4}>
              <Form.Item label='Select Year' name='year' required>
                <DatePicker picker='year' onChange={onYearChange} />
              </Form.Item>
            </Col>
          )}
          <FormButton label='Search' icon textAlign='left' />
        </Row>
      </div>
    </ReportsStyle>
  );
};

export default ReportsHeader;

const ReportsStyle = styled.div``;
