import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, FormInstance, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormListFieldData } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectOptionsInput,
  SelectPassport,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import BillingIsDeleted from '../../Invoice(Visa)/Components/BillingIsDeleted';
import { useLazyGetSinglePassportQuery } from '../../Passport_MGT/Api/Endpoints/PassportEndpoints';
import { AirTicket_ticket_information } from '../types/invoiceAirTicketTypes';

type Props = {
  form: FormInstance<any>;
  index: number;
  field: FormListFieldData;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  ticketInfo?: AirTicket_ticket_information[];
  formName: string;
  length: number;
};

export default function AirticketPaxPassportDetailsSub({
  form,
  index,
  field,
  add,
  remove,
  ticketInfo,
  formName,
  length,
}: Props) {
  const { airticketInvoiceId, nonComissionEditId, reIssueEditId } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [instancPassportSelect, setInstancPassportSelect] = useState<any>('');

  // Handle IS DELETED LOGIC
  const is_deleted: 0 | 1 = useWatch(
    ['pax_passport', index, 'is_deleted'],
    form
  );
  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);

  // GET Passport Details
  const passPortId = useWatch(
    [formName || 'passport_information', index, 'passport_id'],
    form
  );
  const [getSinglePassport, { data: singlePassport }] =
    useLazyGetSinglePassportQuery();

  useEffect(() => {
    if (passPortId) getSinglePassport(passPortId, true);
  }, [passPortId]);

  // set form value by Passport Id and setField undefined when passportId is undefined
  useEffect(() => {
    if (passPortId) {
      form.setFields([
        {
          name: [formName || 'passport_information', index, 'passport_name'],
          value: singlePassport?.data?.name,
        },

        {
          name: [
            formName || 'passport_information',
            index,
            'passport_person_type',
          ],
          value: singlePassport?.data?.passport_person_type,
        },

        {
          name: [formName || 'passport_information', index, 'passport_email'],
          value: singlePassport?.data?.email,
        },
        {
          name: [
            formName || 'passport_information',
            index,
            'passport_mobile_no',
          ],
          value:
            singlePassport?.data?.mobile_no?.split('-')[1] ||
            singlePassport?.data?.mobile_no,
        },
        {
          name: [
            formName || 'passport_information',
            index,
            'passport_date_of_birth',
          ],
          value: singlePassport?.data?.date_of_birth
            ? dayjs(singlePassport?.data?.date_of_birth)
            : undefined,
        },
        {
          name: [
            formName || 'passport_information',
            index,
            'passport_date_of_issue',
          ],
          value: singlePassport?.data?.date_of_issue
            ? dayjs(singlePassport?.data?.date_of_issue)
            : undefined,
        },
        {
          name: [
            formName || 'passport_information',
            index,
            'passport_date_of_expire',
          ],
          value: singlePassport?.data?.date_of_expire
            ? dayjs(singlePassport?.data?.date_of_expire)
            : undefined,
        },
      ]);
    }
  }, [singlePassport, passPortId]);

  const onClear = () => {
    form.setFields([
      {
        name: [formName || 'passport_information', index, 'passport_name'],
        value: undefined,
      },
      {
        name: [formName || 'passport_information', index, 'passport_email'],
        value: undefined,
      },
      {
        name: [
          formName || 'passport_information',
          index,
          'passport_person_type',
        ],
        value: undefined,
      },
      {
        name: [formName || 'passport_information', index, 'passport_mobile_no'],
        value: undefined,
      },
      {
        name: [
          formName || 'passport_information',
          index,
          'passport_date_of_birth',
        ],
        value: undefined,
      },
      {
        name: [
          formName || 'passport_information',
          index,
          'passport_date_of_issue',
        ],
        value: undefined,
      },
      {
        name: [
          formName || 'passport_information',
          index,
          'passport_date_of_expire',
        ],
        value: undefined,
      },
    ]);
  };

  useEffect(() => {
    if (instancPassportSelect) {
      form.setFields([
        {
          name: [formName || 'passport_information', index, 'passport_id'],
          value: instancPassportSelect,
        },
      ]);
    }
  }, [instancPassportSelect]);
  return (
    <Row
      style={
        is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              opacity: 0.5,
              borderBottom: '1px solid white',
            }
          : {}
      }
      gutter={[10, 0]}
    >
      {index > 0 && <Divider />}
      <SelectPassport
        name={[index, 'passport_id']}
        label='Passport No'
        size={4}
        placeholder='select passport'
        onClear={onClear}
        disabled={disabled}
        setInstancPassportSelect={setInstancPassportSelect}
        instancPassportSelect={instancPassportSelect}
      />

      <FormInputItem
        name={[index, 'passport_name']}
        label='Name'
        size={4}
        disabled={disabled}
      />
      <SelectOptionsInput
        options={['Adult', 'Child', 'Infant']}
        name={[index, 'passport_person_type']}
        label='Pax Type'
        size={4}
      />
      <FormInputItem
        label='Contact No.'
        name={[index, 'passport_mobile_no']}
        size={4}
        rules={[
          { min: 10, message: 'Invalid mobile no.' },
          { max: 15, message: 'Invalid mobile no.' },
        ]}
      />
      <FormInputItem
        name={[index, 'passport_email']}
        label='Email'
        size={4}
        inputType={'email'}
      />
      <DateInput
        name={[index, 'passport_date_of_birth']}
        label='Date of birth'
        size={4}
        disabled
      />
      <DateInput
        name={[index, 'passport_date_of_issue']}
        label='Date of issue'
        size={4}
        disabled
      />
      <DateInput
        name={[index, 'passport_date_of_expire']}
        label='Date of expire'
        size={4}
        disabled
      />
      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[field.name, 'is_deleted']} />
      <Row justify={'center'} align={'middle'} gutter={12}>
        <Col style={{ marginTop: 30 }}>
          <Form.Item label={false}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'pax_passport',
                  field.name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['pax_passport', field.name, 'is_deleted'],
                  Number(!prev)
                );
                if (airticketInvoiceId || nonComissionEditId || reIssueEditId) {
                  if (
                    ticketInfo &&
                    ticketInfo[index]?.pax_passport?.length &&
                    index >= ticketInfo[index]?.pax_passport?.length
                  )
                    remove(field.name);
                } else {
                  remove(field.name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>

        {length === index + 1 && (
          <Col style={{ marginTop: 30 }}>
            <Form.Item label={false}>
              <Button type='primary' onClick={() => add()}>
                <PlusOutlined />
              </Button>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Row>
  );
}
