import { Col, Divider, Form, FormInstance, InputNumber, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { DateInput, FormInput } from '../../FormItem/FormItems';

type Props = {
  name: number;
  form: FormInstance<any>;
};

const PartialPaymentInputField = ({ name, form }: Props) => {
  const purchase_price = useWatch(
    ['partial_cost', name, 'airticket_purchase_price'],
    form
  );

  const partial_cost = useWatch(
    ['partial_cost', name, 'airticket_partial_cost'],
    form
  );

  const maxAmount = Fixed2(purchase_price) - Fixed2(partial_cost);

  return (
    <>
      <Row gutter={[5, 5]}>
        <Divider>Ticket No - ({name + 1})</Divider>
        <FormInput name={[name, 'airticket_id']} hidden />
        <FormInput name={[name, 'comb_vendor']} hidden />

        <FormInput readonly name={[name, 'vendor_name']} label='Vendor Name' />

        <FormInput
          readonly
          name={[name, 'airticket_ticket_no']}
          label='Ticket No'
        />
        <FormInput readonly name={[name, 'airticket_profit']} label='Profit' />
        <FormInput
          readonly
          name={[name, 'airticket_purchase_price']}
          label='Purchase Price'
        />

        <FormInput
          readonly
          name={[name, 'airticket_client_price']}
          label='Client Price'
        />
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={12} md={16} lg={12}>
          <Form.Item
            name={[name, 'partial_cost_amount']}
            label={'Add Partial Cost'}
          >
            <InputNumber style={{ width: '100%' }} min={1} />
          </Form.Item>
        </Col>

        {/* <Col span={12}>
          <DateInput label='Date' name={[name, 'airticket_date']} size={24} />
        </Col> */}
      </Row>
    </>
  );
};

export default PartialPaymentInputField;
