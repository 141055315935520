import { ColumnsType } from 'antd/lib/table';
import { Input, Form } from 'antd';
import { ITourClientInfo } from '../../RefundTypes/RefundTypes';

export const TourClientRefundUtils = (
  onTableClientChargeRefundChange: Function,
  name: string
): ColumnsType<ITourClientInfo> => {
  
  return [
    {
      title: 'Client Refund Charge',
      key: 'client_refund_charge',
      dataIndex: 'client_refund_charge',
      render: (text, record, index) => (
        <Form.Item
          name={[name, index, 'client_refund_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (value > Number(record?.client_refund_amount || 0)) {
                  return Promise.reject(
                    'Refund charge cannot be greater than return price'
                  );
                }
                if (value < 1) {
                  return Promise.reject('Refund charge cannot be less than 1');
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableClientChargeRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_refund_charge !== prevRecord.client_refund_charge;
      },
    },

    {
      title: 'Sales Price',
      dataIndex: 'client_refund_amount',
      key: 'client_sales_amount',
    },
    {
      title: 'Refund Amount',
      dataIndex: 'client_refund_amount',
      key: 'client_refund_amount',
    },

    {
      title: 'Vendor Return Amount',
      dataIndex: 'client_return_amount',
      key: 'client_return_amount',
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_return_amount !== prevRecord.client_return_amount;
      },
    },
  ];
};
