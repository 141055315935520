import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  theme,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import { Fixed2 } from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  billingInfoColumn,
  TableType,
} from '../../../../components/common/Invoice/InvoiceCostSubtotal';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { useGetSingleLoanQuery } from '../../Api/Endpoints/LoanEndpoints';
import {
  ViewLoanList,
  viewLoanPaymentItem,
} from '../../Utils/Loan/ViewLoanList';

export const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '0px auto',
};

function ViewLoan() {
  const { id } = useParams();
  const { data: singleLoan, isLoading } = useGetSingleLoanQuery(Number(id));

  const data = singleLoan?.data?.data;
  const items = singleLoan?.data?.items;

  const printTime = moment().format('ll LTS');

  const [amount, setAmount] = useState<number>();
  const [name, setName] = useState<string>();
  const [date, setDate] = useState<string>();
  useEffect(() => {
    if (data?.loan_payable_amount) {
      setAmount(Number(data?.loan_payable_amount));
      setName(data?.loan_name);
      setDate(dayjs(data?.loan_date).format('DD MMM YYYY'));
    }
    if (data?.loan_receivable_amount) {
      setAmount(Number(data?.loan_receivable_amount));
      setName(data?.loan_name);
      setDate(dayjs(data?.loan_date).format('DD MMM YYYY'));
    }
  }, [singleLoan?.data]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Loan_${name}_${date}`,
  });
  const { Text, Title } = Typography;

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  const loan_due = Number(data?.loan_due_amount);

  const total_payment =
    Number(data?.loan_payable_amount || data?.loan_receivable_amount) -
    loan_due;
  const billingInfoData: TableType[] = [
    {
      key: '1',
      name: 'Loan Total',
      value: Fixed2(
        data?.loan_payable_amount || data?.loan_receivable_amount
      ).toLocaleString(),
    },
    {
      key: '2',
      name: 'Payment Total',
      value: total_payment.toLocaleString(),
    },
    {
      key: '3',
      name: `${loan_due > 0 ? 'Loan Due' : 'Loan Status'}`,
      value: `${loan_due > 0 ? loan_due.toLocaleString() : 'PAID'}`,
    },
  ];

  return (
    <div>
      <BreadCrumb arrOfOption={['Dashboard', 'Loan Management', 'View Loan']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/loan'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Loan List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div ref={componentRef}>
          <Card style={a4sizeStyle}>
            <InvoiceHeader />

            <TitleCenter title='Loan info' />

            <Row justify={'end'} align='middle'>
              <Col>
                {data?.loan_vouchar ? (
                  <Typography
                    style={{
                      fontSize: '12px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Vouchar No : {data?.loan_vouchar || '----'}
                  </Typography>
                ) : null}

                <Text
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Date :
                  {dayjs(data?.loan_date && data?.loan_date).format(
                    'DD MMM YYYY'
                  )}
                </Text>
              </Col>
            </Row>

            <div style={{ minHeight: '720px' }}>
              {data?.loan_type &&
              items?.length &&
              ['TAKING', 'GIVING'].includes(data?.loan_type) ? (
                <>
                  <Typography.Title level={5} style={{ marginTop: 15 }}>
                    {data?.loan_type === 'TAKING'
                      ? 'PAYMENT INFORMATION'
                      : 'RECEIVED INFORMATION'}
                  </Typography.Title>
                  <Table
                    size='small'
                    bordered
                    rowKey={(e) => e.airticket_ticket_no}
                    className='invoiceBillingTable'
                    rowClassName={'invoiceBillingTd'}
                    dataSource={items}
                    columns={viewLoanPaymentItem}
                    pagination={false}
                  />
                </>
              ) : (
                <></>
              )}

              <>
                <Typography.Title
                  level={5}
                  style={{ marginBottom: '10px', marginTop: '20px' }}
                >
                  LOAN INFORMATION
                </Typography.Title>
                <Table
                  size='small'
                  bordered
                  columns={ViewLoanList()}
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  pagination={false}
                  dataSource={data && [data]}
                  loading={{ spinning: isLoading, indicator: loadingIndicator }}
                />
              </>

              <Row justify='space-between' align={'middle'}>
                <Col style={{ maxWidth: '360px' }}>
                  {data?.loan_note ? (
                    <Typography.Text className='changeFont'>
                      Note: {data?.loan_note}
                    </Typography.Text>
                  ) : (
                    <></>
                  )}
                </Col>

                <Col style={{}}>
                  <Table
                    size='small'
                    bordered
                    rowKey={(e) => e.key}
                    className='invoiceBillingTable'
                    rowClassName={'invoiceBillingTd'}
                    dataSource={billingInfoData.filter((item) => item?.value)}
                    columns={billingInfoColumn}
                    showHeader={false}
                    pagination={false}
                    style={{ width: '100%', margin: '10px 0' }}
                  />

                  <Typography.Text strong className='changeFont'>
                    <NumToWord number={Number(amount || 0)} />
                  </Typography.Text>
                </Col>
              </Row>
            </div>

            {/* ================== signature ===================== */}
            <Row
              className='signature'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '40px',
              }}
            >
              <Typography.Text
                className='changeFont'
                style={{ borderTop: '1px solid black', color: 'black' }}
              >
                Received By
              </Typography.Text>
              <Typography.Text
                className='changeFont'
                style={{ borderTop: '1px solid black', color: 'black' }}
              >
                Prepared By
              </Typography.Text>
              <Typography.Text
                className='changeFont'
                style={{ borderTop: '1px solid black', color: 'black' }}
              >
                Approved By
              </Typography.Text>
            </Row>
            <Row justify={'center'} style={{ marginTop: '25px' }}>
              <Typography.Text className='changeFont'>
                {printTime}
              </Typography.Text>
            </Row>
          </Card>
        </div>
      </ConfigProvider>
    </div>
  );
}

export default ViewLoan;
