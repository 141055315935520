import { ColumnType } from 'antd/es/table';
import React from 'react';
import { IPersialRefundBodyType } from '../../../RefundTypes/RefundTypes';

export const PartialRefundClientUtils =
  (): ColumnType<IPersialRefundBodyType>[] => {
    return [
      {
        title: 'Refund Charge',
        key: 'prfnd_charge_amount',
        dataIndex: 'prfnd_charge_amount',
        render: (prfnd_charge_amount: string) => {
          return <div>{Number(prfnd_charge_amount) || 0}</div>;
        },
        align: 'center',
      },
      {
        title: 'Refund Amount',
        key: 'prfnd_total_amount',
        dataIndex: 'prfnd_total_amount',
        render: (prfnd_total_amount: string) => {
          return <div>{Number(prfnd_total_amount) || 0}</div>;
        },
        align: 'center',
      },
    ];
  };
