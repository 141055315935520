import { Image, Typography } from 'antd';
import moment from 'moment';
import { useAppSelector } from '../../app/hooks';
import { getImageUrl } from '../utils/common.utils';

type Props = {
  style?: React.CSSProperties | undefined;
};

const printTime = moment().format('ll LTS');

export const InvoiceFooter = ({ style }: Props) => {
  const appConfig = useAppSelector((state) => state.configSlice);

  const { tac_inv_cs, tac_inv_as, tac_signtr, tac_sig_url } = appConfig;

  return (
    <>
      <div
        className='signature-container'
        style={
          style || {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '1in',
          }
        }
      >
        <Typography.Text
          className='client-signature'
          style={{
            marginLeft: '10px',
            borderTop: '1px dashed gray',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          {tac_inv_cs || 'Customer Signature'}
        </Typography.Text>

        <Typography.Text
          style={{
            marginTop: '10px',
            fontSize: '10px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          {printTime}
        </Typography.Text>

        <div className='authority-signature' style={{ position: 'relative' }}>
          <Typography.Text
            style={{
              marginRight: '10px',
              borderTop: '1px dashed gray',
              fontFamily: "'Source Sans Pro', sans-serif",
            }}
          >
            {tac_inv_as || 'Authority Signature'}
          </Typography.Text>
          {tac_signtr === 1 && (
            <div
              style={{
                position: 'absolute',
                bottom: 30,
                right: 15,
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            >
              <Image
                preview={false}
                src={getImageUrl(tac_sig_url)}
                width={120}
              ></Image>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
