import { Col, Form, InputNumber, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  FormInputItem,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { useGetAccountByTypeQuery } from '../../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../../vendor/components/AccountLastBalanceInput';
import { useCreateLoanMutation } from '../../Api/Endpoints/LoanEndpoints';
import SelectAuthority from '../../Components/SelectAuthority';
import { LoanFormDataType, loan_type_options } from '../../LoanTypes/LoanTypes';

type Props = {};

const AddLoanModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const isChanged = useRef(false);

  const [paymentType, setPaymentType] = useState<string>();
  const [loanType, setLoanType] = useState<string>('TAKING');
  const [accountID, setAccountID] = useState<number>();
  const [loanAmount, setLoanAmount] = useState<number | null>();
  const [interest, setInterest] = useState<number | null>(0);
  const [pay_recieve_amount, setPay_recieve_amount] = useState<number>();
  const [installment, setInstallment] = useState<string>();
  const [installmentType, setInstallmentType] = useState<string>();
  const [duration, setDuration] = useState<number | null>();
  const [per_installment, setPer_installment] = useState<number>();

  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>();
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<number>();

  const navigate = useNavigate();

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );
  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  //calculate payable/receivable amount
  useEffect(() => {
    if (interest != undefined && loanAmount != undefined) {
      setPay_recieve_amount((interest / 100) * loanAmount + loanAmount);
      form.setFieldValue(
        'pay_amount',
        (interest / 100) * loanAmount + loanAmount
      );
      form.setFieldValue(
        'receive_amount',
        (interest / 100) * loanAmount + loanAmount
      );
    }
  }, [loanAmount, interest]);

  //calculate per month/daily installment amount
  useEffect(() => {
    if (pay_recieve_amount != undefined && duration != undefined) {
      setPer_installment(pay_recieve_amount / duration);
      form.setFieldValue(
        'installment_per_month',
        pay_recieve_amount / duration
      );
      form.setFieldValue('installment_per_day', pay_recieve_amount / duration);
    }
  }, [duration, pay_recieve_amount]);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }

    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });
      setBalance(undefined);
      return;
    }
  }, [paymentMethod]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  useEffect(() => {
    if (account) {
      fetchBalance(Number(account));
    }
  }, [account]);

  const [addLoan, { isLoading, isError, isSuccess }] = useCreateLoanMutation();

  const handleSubmit = async (values: LoanFormDataType) => {
    const body: LoanFormDataType = {
      ...values,
      note: values.note,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      withdraw_date: dayjs(values.withdraw_date).format('YYYY-MM-DD'),
      pay_amount:
        loanType == 'TAKING' || loanType == 'ALREADY_TAKEN'
          ? (pay_recieve_amount as number)
          : undefined,
      receive_amount:
        loanType == 'GIVING' || loanType == 'ALREADY_GIVEN'
          ? (pay_recieve_amount as number)
          : undefined,
      installment_per_day:
        installmentType == 'DAILY' ? per_installment : undefined,
      installment_per_month:
        installmentType == 'MONTHLY' ? per_installment : undefined,
      loan_created_by: user?.user_id as number,
    };

    await addLoan(body).then((res: any) => {
      const LoadId = res?.data?.loanId;
      if (LoadId) {
        navigate(`/loan/view/${LoadId}`, { state: location.pathname });
      }
    });

    if (isSuccess) {
      setInstallment('NO');
      setInterest(0);
      setDuration(undefined);
      setPay_recieve_amount(undefined);
      setPaymentType(undefined);
      setLoanAmount(undefined);
      form.setFieldValue('receive_amount', undefined);
      form.setFieldValue('pay_amount', undefined);
      form.resetFields();
      form.setFieldValue('date', dayjs());
    }
  };

  const loan_type = useWatch('type', form);

  return (
    <Form form={form} layout='vertical' onFinish={handleSubmit}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <SelectAuthority />
        <FormInputItem
          label='Loan Name'
          name='name'
          required
          size={12}
          mdSize={12}
          smSize={24}
        />
        <Col span={6} xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            initialValue={'TAKING'}
            name='type'
            label='Loan Type'
            rules={[{ required: true, message: 'Loan Type is required!!' }]}
          >
            <Select
              allowClear
              placeholder='Select Payment Method'
              style={{ width: '100%' }}
              onChange={(e: string) => {
                form.validateFields(['amount']);
                setLoanType(e);
              }}
            >
              {loan_type_options.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6} xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label='Loan Amount'
            name='amount'
            rules={[
              { required: true, message: 'Loan Amount is required!!' },
              {
                validator(_, value) {
                  const balance =
                    accountBalance?.data && accountBalance?.data.amount;

                  if (value && balance) {
                    if (balance < value && loan_type == 'GIVING') {
                      return Promise.reject('Not available balance!!');
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <InputNumber
              placeholder='Enter your Loan Amount'
              min={0}
              style={{ width: '100%' }}
              onChange={(e: number | null) => {
                setLoanAmount(Number(e));
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label='Interest(%)'
            name='interest_percent'
            initialValue={0}
          >
            <InputNumber
              style={{ width: '100%' }}
              onChange={(e: number | null) => {
                setInterest(Number(e));
              }}
            />
          </Form.Item>
        </Col>

        {loanType == 'TAKING' || loanType == 'ALREADY_TAKEN' ? (
          <Col span={6} xs={24} sm={24} md={12} lg={12}>
            <Form.Item label='Payable Amount' name='pay_amount'>
              <InputNumber
                readOnly
                placeholder='Payable Amount'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col span={6} xs={24} sm={24} md={12} lg={12}>
            <Form.Item label='Receivable Amount' name='receive_amount'>
              <InputNumber disabled style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        )}
        <Col span={6} xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            name='installment'
            label='Installment'
            initialValue='NO'
            rules={[{ required: true, message: 'Installment is required!!' }]}
          >
            <Select
              allowClear
              placeholder='Select Installment'
              style={{ width: '100%' }}
              onChange={(e: string) => setInstallment(e)}
            >
              <Select.Option key={'YES'} value={'YES'}>
                YES
              </Select.Option>
              <Select.Option key={'NO'} value={'NO'}>
                NO
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {installment == 'YES' && (
          <>
            <Col span={6} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name='installment_type'
                label='Installment Type'
                rules={[
                  {
                    required: true,
                    message: `Installment Type is required!`,
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder='Select Installment Type'
                  style={{ width: '100%' }}
                  onChange={(e: string) => setInstallmentType(e)}
                >
                  <Select.Option key={'MONTHLY'} value={'MONTHLY'}>
                    MONTHLY
                  </Select.Option>
                  <Select.Option key={'DAILY'} value={'DAILY'}>
                    DAILY
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label='Duration'
                name='installment_duration'
                rules={[
                  {
                    required: true,
                    message: `Duration is required!`,
                  },
                ]}
              >
                <InputNumber
                  placeholder='Enter you duration'
                  style={{ width: '100%' }}
                  onChange={(e: number | null) => {
                    setDuration(Number(e));
                  }}
                />
              </Form.Item>
            </Col>

            {installmentType == 'MONTHLY' ? (
              <Col span={6} xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label='Per Month'
                  name='installment_per_month'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    precision={2}
                    disabled
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col span={6} xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label='Per Day'
                  name='installment_per_day'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    disabled
                    precision={2}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
          </>
        )}

        <SelectPaymentMethod
          required
          name='payment_type'
          label='Payment Method '
          onChange={(value: number) => {
            setPayMethodCatId(value);
            setPaymentMethod(value);
            if (!isChanged.current) isChanged.current = true;
          }}
          cheque
          size={12}
          mdSize={12}
        />
        {Number(payMethodCatId) === 4 && (
          <>
            <FormInputItem
              label='Cheque No'
              name='cheque_no'
              required
              size={12}
              mdSize={12}
            />

            <DateInput
              name='withdraw_date'
              label='Withdraw Date'
              required
              size={12}
              mdSize={12}
            />

            <FormInputItem
              name={'bank_name'}
              label='Bank Name'
              required
              onChange={(value) => setAccount(String(value))}
              size={12}
              mdSize={12}
            />
          </>
        )}
        {Number(payMethodCatId) !== 4 && (
          <>
            <AccountLastBalanceInput
              accountsLastBalance={accounts ? accounts.data : []}
              name='account_id'
              label='Account:'
              // disabled={paymentMethod || reason === 'EDIT' ? false : true}
              onSelect={(value: number) => {
                setAccount(String(value));
              }}
              required
              size={12}
              mdSize={12}
            />
          </>
        )}
        {Number(payMethodCatId) === 3 && (
          <>
            <FormInputItem
              name={'charge_amount'}
              label='Transaction Charge :'
              size={12}
              mdSize={12}
            />

            <FormInputItem
              label='Receipt/Trans No:'
              name='trans_no'
              size={12}
              mdSize={12}
            />
          </>
        )}

        {Number(payMethodCatId) !== 4 && (
          <Col span={6} xs={24} sm={24} md={12} lg={12}>
            <Form.Item label='Available Balance'>
              <InputNumber
                style={{ width: '100%' }}
                readOnly
                placeholder='Available Balance'
                value={balance}
              />
            </Form.Item>
          </Col>
        )}

        <DateInput label='Date' name='date' size={12} smSize={24} mdSize={12} />
        <TextAreaInput label='Note' name='note' size={24} />
      </Row>
      <FormButton label='Create Loan' loading={isLoading} />
    </Form>
  );
};

export default AddLoanModal;
