import { Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectAirport,
  SelectAirportV1,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetItineraryVendorsInfoQuery } from '../../../Configuration/Tour/Ticket/endpoints/tourTicketEndpoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { SelectTourItinerary } from '../Components/SelectTourItinerary';
import { useParams } from 'react-router';

type Props = {
  form: FormInstance<any>;
  tourGuide?: boolean;
  tourTicket?: boolean;
  invComClientInfo?: string;
  isCosting?: boolean;
};

const GuideAndTicketForm = ({
  form,
  tourGuide,
  tourTicket,
  invComClientInfo,
  isCosting,
}: Props) => {
  const { id } = useParams();
  //this state for setField vendor name
  const [instanceVendorTicket, setInstanceVendorTicket] = useState<number>();
  const [instanceVendorGuide, setInstanceVendorGuide] = useState<number>();
  const [instanceGuideSelect, setInstanceGuideSelect] = useState<number>();
  /* if any field select other field are auto required */
  const ticket_no = useWatch(['ticket_no'], form);
  const ticket_pnr = useWatch(['ticket_pnr'], form);
  const ticket_route = useWatch(['ticket_route'], form);
  const ticket_journey_date = useWatch(['ticket_journey_date'], form);
  const ticket_return_date = useWatch(['ticket_return_date'], form);
  const ticket_airline_id = useWatch(['ticket_airline_id'], form);
  const ticket_cost_price = useWatch(['ticket_cost_price'], form);
  const ticket_comvendor_id = useWatch(['ticket_comvendor_id'], form);

  const requiredField =
    ticket_no ||
    ticket_pnr ||
    ticket_route ||
    ticket_journey_date ||
    ticket_return_date ||
    ticket_return_date ||
    ticket_airline_id ||
    ticket_cost_price ||
    ticket_comvendor_id;

  const guide_itinerary_ids = useWatch(['guide_itinerary_id'], form);
  const guide_cost_price = useWatch(['guide_cost_price'], form);
  const guide_comvendor_id = useWatch(['guide_comvendor_id'], form);
  const guide_description = useWatch(['guide_description'], form);

  const guideRequired =
    guide_itinerary_ids ||
    guide_cost_price ||
    guide_description ||
    guide_comvendor_id;

  setTimeout(() => {
    if (!requiredField) {
      form.validateFields([
        ['ticket_no'],
        ['ticket_pnr'],
        ['ticket_route'],
        ['ticket_journey_date'],
        ['ticket_return_date'],
        ['ticket_return_date'],
        ['ticket_airline_id'],
        ['ticket_cost_price'],
        ['ticket_comvendor_id'],
      ]);
    }

    if (!guideRequired) {
      form.validateFields([
        ['guide_itinerary_id'],
        ['guide_cost_price'],
        ['guide_comvendor_id'],
        ['guide_description'],
      ]);
    }
  });

  // itinerary (TourGuideId get the vendor information then vendorId finds the vendor who match this VendorId and sets its costPrices )
  const [getItineraryVendorsInfo, { data: vendors, isFetching, isSuccess }] =
    useLazyGetItineraryVendorsInfoQuery();
  const [vendorGuideId, setGuideVendorId] = useState<number>();
  const [itineraryGuideId, setItineraryGuideId] = useState<
    number | undefined
  >();
  useEffect(() => {
    //this for when crevate tour food,its not work for editing
    if (!id) {
      const selectVendor = vendors?.data?.find(
        (item) => item.itnrvendor_vendor_id === vendorGuideId
      );
      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['guide_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['guide_cost_price'],
            value: undefined,
          },
        ]);
      }
    }

    //this only work for when editing
    if (
      (id && guide_comvendor_id && guide_itinerary_ids && itineraryGuideId) ||
      (id && guide_comvendor_id && guide_itinerary_ids && vendorGuideId)
    ) {
      const selectVendor = vendors?.data?.find(
        (item) =>
          item.itnrvendor_vendor_id ===
          Number(guide_comvendor_id?.toString().split('-')[1])
      );

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['guide_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['guide_cost_price'],
            value: undefined,
          },
        ]);
      }
    }
  }, [
    guide_itinerary_ids,
    guide_comvendor_id,
    vendors?.data,
    vendorGuideId,
    itineraryGuideId,
  ]);
  /*cost price set according to tour Guide and Guide vendor base */
  useEffect(() => {
    if (guide_itinerary_ids) {
      getItineraryVendorsInfo(guide_itinerary_ids);
    }
  }, [guide_itinerary_ids]);
  //setfield vendor name both ticket and guide
  useEffect(() => {
    if (instanceVendorTicket) {
      form.setFieldValue(
        'ticket_comvendor_id',
        'vendor-' + instanceVendorTicket
      );
    }
  }, [instanceVendorTicket]);

  useEffect(() => {
    if (instanceVendorGuide) {
      form.setFieldValue('guide_comvendor_id', 'vendor-' + instanceVendorGuide);
    } else if (instanceGuideSelect) {
      form.setFieldValue('guide_itinerary_id', instanceGuideSelect);
    }
  }, [instanceVendorGuide]);

  return (
    <>
      {tourTicket || tourGuide || !isCosting ? (
        <div className='border p-5 mt-5'>
          {/* TICKET */}
          <FormHeaderTitle title='Ticket & Guide Information 🗂️' />

          {!isCosting || tourTicket ? (
            <Row gutter={[10, 0]}>
              <FormInputItem
                required={requiredField}
                name={['ticket_no']}
                label='Ticket No'
                size={3}
              />
              <FormInputItem name={['ticket_pnr']} label='PNR' size={3} />

              <SelectAirport
                mode='multiple'
                label='Route'
                name={['ticket_route']}
                fullNamePath={['ticket_route']}
                size={3}
                form={form}
              />

              <DateInput
                name={['ticket_journey_date']}
                label='	Journey Date'
                size={3}
              />
              <DateInput
                name={['ticket_return_date']}
                label='Return Date'
                size={3}
              />
              <SelectAirline
                name={['ticket_airline_id']}
                label='Airline'
                size={3}
              />

              <NumberInput
                label='Cost Price'
                name={['ticket_cost_price']}
                size={3}
                required={requiredField}
                maxChar={14}
                minChar={0}
              />
              <SelectVendors
                label='Vendor'
                size={3}
                name={'ticket_comvendor_id'}
                required={requiredField}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value: string) {
                      if (getFieldValue('invoice_combclient_id')) {
                        if (getFieldValue('invoice_combclient_id') === value) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      }
                      if (invComClientInfo !== undefined) {
                        if (invComClientInfo === value) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      }
                      return Promise.resolve();
                    },
                    message: "Client and vendor can't be same!",
                  }),
                ]}
                setInstanceVendorSelect={setInstanceVendorTicket}
              />
            </Row>
          ) : null}
          {/* guide */}
          {!isCosting || tourGuide ? (
            <Row gutter={[10, 0]}>
              <SelectTourItinerary
                label='guide'
                name={'guide_itinerary_id'}
                isRequired={guideRequired}
                onChange={setItineraryGuideId}
                setInstanceTransportSelect={setInstanceGuideSelect}
              />
              <FormInputItem
                label='Description'
                name={['guide_description']}
                size={6}
              />

              <NumberInput
                label='Cost Price'
                name={['guide_cost_price']}
                size={6}
                required={guideRequired}
                maxChar={14}
                minChar={0}
              />
              <SelectVendors
                label='Vendor'
                size={6}
                name={'guide_comvendor_id'}
                required={guideRequired}
                onChange={(e) =>
                  setGuideVendorId(Number(e?.toString().split('-')[1]))
                }
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value: string) {
                      if (getFieldValue('invoice_combclient_id')) {
                        if (getFieldValue('invoice_combclient_id') === value) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      }
                      if (invComClientInfo !== undefined) {
                        if (invComClientInfo === value) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      }
                      return Promise.resolve();
                    },
                    message: "Client and vendor can't be same!",
                  }),
                ]}
                setInstanceVendorSelect={setInstanceVendorGuide}
              />
            </Row>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default GuideAndTicketForm;
