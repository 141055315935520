import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { CollectionDatum } from '../types/clientWiseCollectionAndSalesReportTypes';
import { Link } from 'react-router-dom';

//collection utils
export const ClientWiseCollectionUtils = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<CollectionDatum> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Payment Date',
      key: 'receipt_payment_date',
      render: (_, record) => {
        return `${dayjs(record.receipt_payment_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Money Receipt No.',
      dataIndex: 'receipt_vouchar_no',
      key: 'receipt_vouchar_no',
    },
    {
      title: 'Particular',
      dataIndex: 'trxntype_name',
      key: 'trxntype_name',
    },

    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',

      render: (_, record) => (
        <Link
          to={
            record.receipt_client_id
              ? `/clients/details/client-${record.receipt_client_id}`
              : `/combineClients/details/${record.receipt_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: 'Collection Amount',
      dataIndex: 'receipt_total_amount',
      key: 'receipt_total_amount',
      align: 'right',
      render: (_, record) => Number(record.receipt_total_amount),
    },
  ];
};

//sales utils
export const ClientWiseSalesUtils = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<any> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Invoice Date',
      key: 'invoice_create_date',
      render: (_, record) => {
        return `${dayjs(record.sales_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: 'PAX Name',
      dataIndex: 'pax_name',
      key: 'pax_name',
    },
    {
      title: 'Ticket No.',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
    },

    {
      title: 'Net Total',
      dataIndex: 'sales_price',
      key: 'sales_price',
      align: 'right',
      render: (_, record) => Number(record.sales_price),
    },
  ];
};
