import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { renderField } from '../../../components/common/renderField';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useGetBillInfoQuery,
  useGetSingleQuotationQuery,
} from '../Api/Endpoints/QuotationEndpoints';
import { IQuotationBillInfo } from '../QuotationTypes/QuotationTypes';
import { BillingInfoUtils } from '../Utils/BillingInfoUtils';
import QuotationSubTotal from './QuotationSubTotal';

function NewViewQuotationDetails() {
  const { id } = useParams();

  const [date, setDate] = useState<string>();

  const { data: singleQuotation, isFetching: fetchIsLoading } =
    useGetSingleQuotationQuery(Number(id));
  const { Text } = Typography;
  const printTime = dayjs().format('MMMM Do YYYY, h:mm:ss a');
  const { data: billInfo } = useGetBillInfoQuery(Number(id), {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IQuotationBillInfo[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });

  useEffect(() => {
    if (singleQuotation?.data) {
      setDate(
        dayjs(singleQuotation?.data.quotation_date).format('DD MMM YYYY')
      );
    }
  }, [singleQuotation?.data]);

  const user = useAppSelector((state) => state.user);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Quotation_${name}_${date}`,
  });

  const {
    subtotal: quotation_sub_total,
    quotation_net_total,
    quotation_discount_total,
  } = singleQuotation?.data || {};

  //   =============== style==============

  const invoiceViewStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(211, 211, 211, 0.2)',
    minHeight: '100vh',
    padding: '20px 0',
  };
  const a4sizeStyle: React.CSSProperties = {
    minHeight: '11.5in',
    width: '8.27in',
    fontSize: '11px',
    background: '#fff',
    margin: '0px auto',
  };

  // ============ invoice header address

  return (
    <div>
      <BreadCrumb arrOfOption={['Quotation', 'View Quotation']} />

      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/quotation'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Quotation List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ViewInvoiceStyle style={invoiceViewStyle}>
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <div ref={componentRef}>
            <div style={{ ...a4sizeStyle, position: 'relative' }}>
              <InvoiceHeader />

              <TitleCenter title='quotation' />

              <Row justify={'space-between'} align='middle' className='p-10'>
                <Col>
                  {renderField('Name', singleQuotation?.data?.client_name)}
                  {renderField(
                    'Address',
                    singleQuotation?.data?.client_address
                  )}
                  {renderField('Mobile', singleQuotation?.data?.client_mobile)}
                  {renderField('Email', singleQuotation?.data?.client_email)}
                </Col>

                <Col className='p-10'>
                  {renderField(
                    'Quotation Date',
                    singleQuotation?.data?.quotation_date,
                    true
                  )}

                  {renderField(
                    'Sales By',
                    singleQuotation?.data?.user_full_name
                  )}

                  {renderField('Prepared By', user?.user_full_name, true)}
                </Col>
              </Row>

              <div
                style={{
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '8in',
                }}
              >
                <div
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  <Typography.Title
                    level={5}
                    style={{
                      marginBottom: '5px',
                    }}
                  >
                    QUOTATION INFO
                  </Typography.Title>
                  <Table
                    size='small'
                    className='invoiceBillingTable'
                    rowClassName={'invoiceBillingTd'}
                    bordered
                    columns={BillingInfoUtils}
                    dataSource={billInfo}
                    pagination={false}
                    loading={{
                      spinning: fetchIsLoading,
                      indicator: <LoadingIndicator />,
                    }}
                  />

                  <Flex justify='end'>
                    <QuotationSubTotal
                      discount={quotation_discount_total}
                      net_total={quotation_net_total}
                      subtotal={quotation_sub_total}
                    />
                  </Flex>

                  {singleQuotation?.data?.quotation_note ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: singleQuotation?.data?.quotation_note,
                      }}
                    />
                  ) : undefined}
                </div>

                <div>
                  <Row
                    className='signature'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                    }}
                  >
                    <Typography.Text
                      style={{ borderTop: '1px solid black', color: 'black' }}
                    >
                      Customer Signature
                    </Typography.Text>
                    <Typography.Text
                      style={{ borderTop: '1px solid black', color: 'black' }}
                    >
                      Authority Signature
                    </Typography.Text>
                  </Row>

                  <Row justify='center'>
                    <Text style={{ textAlign: 'center' }}>{printTime}</Text>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </ConfigProvider>
      </ViewInvoiceStyle>
    </div>
  );
}

export default NewViewQuotationDetails;
const ViewInvoiceStyle = styled.div``;
