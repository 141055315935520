import { Row } from 'antd';
import {
  FormInput,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';

type Props = {
  isAgentComission: boolean;
};

function InvoiceReIssueSubTotal({ isAgentComission }: Props) {
  return (
    <Row gutter={2}>
      <NumberInput
        min='0'
        size={12}
        label={'Discount'}
        name={'invoice_discount'}
        maxChar={10}
      />

      <NumberInput
        size={12}
        label={'Service Charge'}
        name={'invoice_service_charge'}
        min='0'
        maxChar={10}
      />

      <NumberInput
        min='0'
        size={12}
        label='Vat / Tax'
        name='invoice_vat'
        maxChar={10}
      />
      <NumberInput
        size={12}
        label='Net total'
        name={'invoice_net_total'}
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        size={12}
        label='Agent Commission'
        name={'invoice_agent_com_amount'}
        min='0'
        maxChar={10}
        disabled={isAgentComission}
      />
      <FormInput size={12} label='Reference' name='invoice_reference' />
      <TextAreaInput size={24} label='Note' name='invoice_note' />
    </Row>
  );
}

export default InvoiceReIssueSubTotal;
