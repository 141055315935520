import { ConfigProvider, Typography, theme } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import {
  IHajjRefund,
  IHajjRefundItem,
} from '../../../../modules/Hajj/Invoice_Hajj/Types/InvoiceHajjTypes';
import { useHajjRefundDetailsQuery } from '../../../../modules/Hajj/Invoice_Hajj/api/endpoints/invoicehajjEndpoints';
import { invoiceViewStyle } from './CommonViewInvoice';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {
  viewInvoicePaymentsRef: React.RefObject<HTMLDivElement>;
};

const TabHajjRefundDetails = ({ viewInvoicePaymentsRef }: Props) => {
  const { id } = useParams();

  const { data, isLoading } = useHajjRefundDetailsQuery(id, {
    skip: id ? false : true,
  });

  const refundData: ColumnsType<IHajjRefund> = [
    {
      title: 'Sl',

      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: 'Date',
      dataIndex: 'refund_date',
      key: 'refund_date',
      render: (refund_date) => dayjs(refund_date).format('DD-MM-YYYY'),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Voucher',
      dataIndex: 'refund_voucher',
      key: 'refund_voucher',
    },
    {
      title: 'Client Total Refund',
      dataIndex: 'refund_client_total',
      key: 'refund_client_total',
      render: (refund_client_total) => Fixed2(refund_client_total),
    },
    {
      title: 'C. Refund Type',
      dataIndex: 'refund_client_type',
      key: 'refund_client_type',
    },

    {
      title: 'Vendor Total',
      dataIndex: 'refund_vendor_total',
      key: 'refund_vendor_total',
      render: (refund_vendor_total) => Fixed2(refund_vendor_total),
    },
  ];

  const refundItems: ColumnsType<IHajjRefundItem> = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },

    {
      title: 'Quantity',
      dataIndex: 'ritem_quantity',
      key: 'ritem_quantity',
    },
    {
      title: 'Unit Price',
      dataIndex: 'ritem_unit_price',
      key: 'ritem_unit_price',
      render: (ritem_unit_price) => Fixed2(ritem_unit_price),
    },
    {
      title: 'Client Charge',
      dataIndex: 'ritem_client_charge',
      key: 'ritem_client_charge',
      render: (ritem_client_charge) => Fixed2(ritem_client_charge),
    },

    {
      title: 'Client Refund',
      dataIndex: 'ritem_client_refund',
      key: 'ritem_client_refund',
      render: (ritem_client_refund) => Fixed2(ritem_client_refund),
    },
    {
      title: 'Cost',
      dataIndex: 'ritem_cost_price',
      key: 'ritem_cost_price',
      render: (ritem_cost_price) => Fixed2(ritem_cost_price),
    },

    {
      title: 'Vendor Charge',
      dataIndex: 'ritem_vendor_charge',
      key: 'ritem_vendor_charge',
      render: (ritem_vendor_charge) => Fixed2(ritem_vendor_charge),
    },

    {
      title: 'Vendor Refund',
      dataIndex: 'ritem_vendor_refund',
      key: 'ritem_vendor_refund',
      render: (ritem_vendor_refund) => Fixed2(ritem_vendor_refund),
    },
  ];

  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            width: '8.27in',
            height: '11.69in',
            background: '#fff',
            padding: '10px',
          }}
          ref={viewInvoicePaymentsRef}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginTop: 50,
            }}
          >
            <div>
              <Typography.Title level={5} className='text-center border'>
                Refund Data Details
              </Typography.Title>

              <Table
                size='small'
                bordered
                rowKey={(e) => e.refund_id}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={data?.data?.refund}
                columns={refundData}
                pagination={false}
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <Typography.Title level={5} className='text-center border'>
                Refund Items Details
              </Typography.Title>

              <Table
                size='small'
                bordered
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={data?.data?.refundItems}
                columns={refundItems}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TabHajjRefundDetails;
