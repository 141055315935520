import { ColumnsType } from 'antd/lib/table';
import { Button, Col, Popconfirm, Row, Typography, message } from 'antd';
import { IGetAllOpeningBalance } from '../../AccountsTypes/AccountsTypes';
import { useDeleteOpeningBalanceMutation } from '../../Api/Endpoints/OpeningBalanceEndponts';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../app/hooks';
import { useEffect } from 'react';

export const ListOfAllOpeningBalanceColumn = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IGetAllOpeningBalance> => {
  const [deleteOpeningBalance, { isError, isSuccess }] =
    useDeleteOpeningBalanceMutation();
  const { user } = useAppSelector((state) => state);
  const handleOnDelete = (op_id: number) => {
    deleteOpeningBalance({
      id: op_id,
      body: { delete_by: user?.user_id as number },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      message.success('Accounts Opening Balance Deleted');
    }
  }, [isSuccess]);
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'op_created_date',
      dataIndex: 'op_created_date',
      render: (_, record) =>
        record.op_created_date
          ? dayjs(record.op_created_date).format('DD-MMM-YYYY')
          : '',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 300,
      render: (_, record) => (
        <>
          {record.client_name ? (
            <Row justify={'space-between'}>
              {' '}
              <Col>{record.client_name}</Col> <Col>(Client) </Col>{' '}
            </Row>
          ) : record.combine_name ? (
            <Row justify={'space-between'}>
              {' '}
              <Col>{record.combine_name}</Col> <Col>(Combined) </Col>{' '}
            </Row>
          ) : record.vendor_name ? (
            <Row justify={'space-between'}>
              {' '}
              <Col>{record.vendor_name}</Col> <Col>(Vendor) </Col>{' '}
            </Row>
          ) : record.account_name ? (
            <Row justify={'space-between'}>
              {' '}
              <Col>{record.account_name}</Col> <Col>(Account) </Col>{' '}
            </Row>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Transaction Type',
      key: 'op_trxn_type',
      dataIndex: 'op_trxn_type',
    },

    {
      title: 'Amount',
      dataIndex: 'op_amount',
      key: 'op_amount',
      render: (_, record) => Number(record.op_amount),
    },

    {
      title: 'Note',
      dataIndex: 'op_note',
      key: 'op_note',
      width: 'auto',
    },
    {
      title: 'Action',
      dataIndex: 'added_by',
      key: 'added_by',
      render: (_, data) => {
        return (
          <>
            <Button size='small' type='primary' danger>
              <Popconfirm
                onConfirm={() => handleOnDelete(data.op_id)}
                title='Sure to delete?'
              >
                <Typography style={{ color: '#ffffff' }}>Delete</Typography>
              </Popconfirm>
            </Button>
          </>
        );
      },
    },
  ];
};
