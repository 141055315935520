import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { IAirTicketInfo } from '../../../common/types/commonInterfaces';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { Fixed2 } from '../../../common/utils/common.utils';

type Props = { tickets?: IAirTicketInfo[]; category_id: number };

const NonCommissionCostInfo = ({ tickets, category_id }: Props) => {
  const flightsColumn: ColumnsType<IAirTicketInfo> = [
    {
      title: 'Sl',
      align: 'center',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Ticket No.',
      align: 'center',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'Pax Name',
      align: 'center',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'PNR',
      align: 'center',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },

    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      align: 'center',
    },

    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
      align: 'center',
    },

    {
      title: 'Issue Date',
      render: (_, data) => (
        <>{dayjs(data?.airticket_issue_date).format('DD MMM YYYY')}</>
      ),
      key: 'airticket_issue_date',
      align: 'center',
    },

    {
      title: 'Extra Fee',
      dataIndex: 'airticket_extra_fee',
      key: 'airticket_extra_fee',
      align: 'center',
    },

    {
      title: 'Sold Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
    },

    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      align: 'right',
    },

    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      align: 'right',
      render: (value) => (
        <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
          {Number(value || 0)}
        </span>
      ),
    },
  ];

  const getSoldPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_client_price || 0), 0)
      : 0;
  };
  const getPurchasePrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_purchase_price || 0), 0)
      : 0;
  };
  const getProfitPrice = (data?: IAirTicketInfo[]) => {
    return data
      ? data.reduce((a, b) => a + Number(b.airticket_profit || 0), 0)
      : 0;
  };

  return (
    <div className='mt-20'>
      <FormHeaderTitle title='COST INFORMATION' />
      <Table
        rowKey={(e) => e.airticket_ticket_no}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={tickets}
        columns={flightsColumn}
        bordered
        summary={() => {
          const totalSold = getSoldPrice(tickets);
          const totalPurchese = getPurchasePrice(tickets);
          const totalProfit = getProfitPrice(tickets);
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={Math.floor(flightsColumn.length) - 3}
                >
                  <Typography
                    style={{
                      textAlign: 'right',
                      marginRight: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    Total:
                  </Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography
                    style={{
                      textAlign: 'right',
                    }}
                  >{`${Fixed2(totalSold)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography
                    style={{
                      textAlign: 'right',
                    }}
                  >{`${Fixed2(totalPurchese)}`}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Typography
                    style={{
                      textAlign: 'right',
                    }}
                  >{`${Fixed2(totalProfit)}`}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        pagination={false}
      />
    </div>
  );
};

export default NonCommissionCostInfo;
