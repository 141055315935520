import { ColumnsType } from 'antd/es/table';
import { IVisaProcessingType } from '../types/visa_processingType';
import dayjs from 'dayjs';
import { Button, Image } from 'antd';
import { setModal } from '../../../common/slices/commonSlice';
import { useAppDispatch } from '../../../app/hooks';

type IProps = {
  pagination: {
    current: number;
    pageSize: number;
  };
  settvw_id: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const VisaProcessingColumn = ({
  pagination,
  settvw_id,
}: IProps): ColumnsType<IVisaProcessingType> => {
  const dispatch = useAppDispatch();
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
      fixed: 'left',
      width: 55,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 100,
      align: 'center',
    },
    {
      title: 'Passenger Name',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
      width: 100,
      align: 'left',
    },
    {
      title: 'Passport Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
      width: 100,
      align: 'left',
    },
    {
      title: 'Quantity of Pass.',
      dataIndex: 'tvw_passport_quantity',
      key: 'tvw_passport_quantity',
      width: 100,
      align: 'center',
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      width: 100,
      align: 'left',
    },
    {
      title: 'Visa Delivered',
      dataIndex: 'tvw_visa_deliver_date',
      key: 'tvw_visa_deliver_date',
      width: 100,
      align: 'center',
      render: (_, value) =>
        value?.tvw_visa_deliver_date &&
        dayjs(value?.tvw_visa_deliver_date).format('YYYY-MMM-DD'),
    },
    {
      title: 'Visa Expired',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 100,
      align: 'center',
      render: (_, value) =>
        value?.tvw_visa_expire_date &&
        dayjs(value.tvw_visa_expire_date).format('YYYY-MMM-DD'),
    },
    {
      title: 'Visa Status',
      dataIndex: 'tvw_status',
      key: 'tvw_status',
      width: 100,
      align: 'center',
    },
    {
      title: 'Assign By',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
      width: 100,
      align: 'left',
    },
    {
      title: 'Remarks',
      dataIndex: 'tvw_remarks',
      key: 'tvw_remarks',
      width: 100,
      align: 'left',
    },
    {
      title: 'Doc',
      dataIndex: 'doc',
      key: 'doc',
      width: 100,
      align: 'center',
      render: (_, value) =>
        value.tvw_doc && <Image src={value.tvw_doc} width={50} />,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      fixed: 'right',
      render: (_, record, index) => (
        <Button
          size='small'
          type='primary'
          onClick={() => {
            settvw_id(record.tvw_id);
            dispatch(setModal(true));
          }}
        >
          Edit
        </Button>
      ),
    },
  ];
};

export default VisaProcessingColumn;
