import { FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useRef } from 'react';
import { useGetAccountByTypeQuery } from '../../modules/vendor/api/endpoints/vendorPaymentEndpoints';
import LoadingIndicator from '../../components/common/spinner/LoadingIndicator';
import SelectPaymentMethod from '../../components/common/paymentMethod/SelectPaymentMethod';
import AccountLastBalanceInput from '../../modules/vendor/components/AccountLastBalanceInput';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../components/common/FormItem/FormItems';

type Props = {
  form: FormInstance<any>;
  pay_type_name?: string;
  pay_acc_id_name?: string;
  trans_charge_name?: string;
  trans_no_name?: string;
  cheque_withdraw_date_name?: string;
  cheque_bank_name?: string;
  cheque_no_name?: string;
  disablePayMethod?: boolean;
  cheque_status_name?: string;
};

const SelectPayment = ({
  form,
  pay_acc_id_name = 'mr_acc_id',
  pay_type_name = 'mr_pay_type',
  trans_charge_name = 'mr_trxn_charge',
  trans_no_name = 'mr_trxn_no',
  cheque_withdraw_date_name = 'cheque_withdraw_date',
  cheque_bank_name = 'cheque_bank_name',
  cheque_no_name = 'cheque_number',
  disablePayMethod,
  cheque_status_name = 'cheque_status',
}: Props) => {
  const payMethodCatId = useWatch(pay_type_name, form);
  const account = useWatch(pay_acc_id_name, form);

  const isChanged = useRef(false);

  // Get account under payment type
  const {
    data: accounts,
    isLoading,
    refetch: refetchAccount,
  } = useGetAccountByTypeQuery(Number(payMethodCatId), {
    skip: !Number(payMethodCatId),
  });

  const account_last_balance =
    accounts?.data &&
    accounts?.data.find((item) => item.account_id === account);

  const acc_id = useWatch(pay_acc_id_name, form);

  useEffect(() => {
    form.setFieldValue(cheque_status_name, 'pending');
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      available_balance: account_last_balance?.last_balance,
    });
  }, [account_last_balance, account, acc_id]);

  useEffect(() => {
    if (isChanged.current) {
      form.resetFields([
        'account_id',
        'available_balance',
        'mr_acc_id',
        pay_acc_id_name,
      ]);
    }
  }, [payMethodCatId]);

  return (
    <div>
      {isLoading && <LoadingIndicator />}

      <SelectPaymentMethod
        size={24}
        mdSize={24}
        required
        name={pay_type_name}
        label='Payment Method '
        onChange={(value: number) => {
          if (!isChanged.current) isChanged.current = true;
        }}
        cheque
        disabled={disablePayMethod || false}
      />

      {Number(payMethodCatId) !== 4 && (
        <>
          <AccountLastBalanceInput
            size={24}
            mdSize={24}
            required
            accountsLastBalance={accounts ? accounts.data : []}
            name={pay_acc_id_name}
            label='Account:'
          />

          <NumberInput
            label='Available Balance'
            name='available_balance'
            color={undefined}
            readOnly
            min='0'
            size={24}
            mdSize={24}
            smSize={24}
            maxChar={14}
            minChar={0}
          />
        </>
      )}

      {Number(payMethodCatId) === 4 && (
        <>
          <FormInputItem
            size={24}
            mdSize={24}
            smSize={24}
            label='Cheque No'
            name={cheque_no_name}
            required
          />

          <FormInputItem
            size={24}
            mdSize={24}
            smSize={24}
            label='Cheque Status'
            name={cheque_status_name}
            hidden
          />

          <DateInput
            name={cheque_withdraw_date_name}
            label='Withdraw Date'
            required
            size={24}
            mdSize={24}
            smSize={24}
          />

          <FormInputItem
            name={cheque_bank_name}
            label='Bank Name'
            required
            size={24}
            mdSize={24}
            smSize={24}
          />
        </>
      )}

      {Number(payMethodCatId) === 3 && (
        <>
          <FormInputItem
            size={24}
            mdSize={24}
            smSize={24}
            label='Trans No:'
            name={trans_no_name}
          />
          <NumberInput
            label='Trx Charge'
            name={trans_charge_name}
            size={24}
            mdSize={24}
            smSize={24}
          />
        </>
      )}
    </div>
  );
};

export default SelectPayment;
