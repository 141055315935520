import { api } from '../../../app/baseQuery';
import { HTTPResponse } from '../../../common/types/commonTypes';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../modules/Reports/Api/constants';
import {
  Icheque,
  ICollCheques,
  IDueInvoice,
  IJourneyNotification,
  IPassExpire,
  IVisaDelivery,
  IVisaStatusUp,
} from '../Interfaces/Notification.interface';

export const NotificationConst = 'notification';

export const notificationEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // new api for all notifications---------------------------

    getAllExpiresPassportNotifications: build.query<
      HTTPResponse<IPassExpire>,
      void
    >({
      query: (body) => ({
        url: `/notification/exp-passport`,
      }),
      providesTags: () => [NotificationConst],
    }),
    getAllCollectionChequeNotifications: build.query<
      HTTPResponse<ICollCheques>,
      { cheque_status: string }
    >({
      query: ({ cheque_status }) => ({
        url: `/notification/collect-cheque?status=${cheque_status}`,
      }),
      providesTags: () => [NotificationConst],
    }),
    getAllPendingPaymentChequeNotifications: build.query<
      HTTPResponse<any>,
      { cheque_status: string }
    >({
      query: ({ cheque_status }) => ({
        url: `/notification/payment-cheque?status=${cheque_status}`,
      }),
      providesTags: () => [NotificationConst],
    }),
    getAllDueInvoiceNotifications: build.query<
      HTTPResponse<IDueInvoice[]>,
      { current: number; pageSize: number } | void
    >({
      query: (arg) => ({
        url: `/notification/due-invoice?page=${arg?.current}&size=${arg?.pageSize}`,
      }),
      providesTags: () => [
        NotificationConst,
        'DUE_INVOICE_NOTIFICATION',
        'invoices',
      ],
    }),
    getAllVisaDelivaryNotifications: build.query<
      HTTPResponse<IVisaDelivery>,
      void
    >({
      query: (body) => ({
        url: `/notification/visa-delivary`,
      }),
      providesTags: () => [NotificationConst],
    }),
    // Update Payment Cheque Status

    updatePayChequeStatus: build.mutation<HTTPResponse<void>, Icheque>({
      query: (body) => ({
        url: `/notification/cheque-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: NotificationConst }],
    }),
    // Update Collection Cheque Status

    updateCollectionChequeStatus: build.mutation<HTTPResponse<void>, Icheque>({
      query: (body) => ({
        url: `/notification/collection-cheque-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: NotificationConst }],
    }),

    // update invoice visa status
    visaStatusUpdate: build.mutation<HTTPResponse<void>, IVisaStatusUp>({
      query: ({ body, billing_id, status }) => ({
        url: `/invoice-visa/status/${billing_id}?status=${status}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        { type: NotificationConst },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        'invoice-visa',
      ],
    }),
    // scanner
    convertPdfToText: build.mutation<
      HTTPResponse<{
        full_data: string;
        transactions: {
          date: string;
          detail: string;
          paidIn: number;
          paidOut: number;
          paymentType: string;
        }[];
      }>,
      any
    >({
      query: (body) => ({
        url: `/notification/upload-pdf`,
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: [
        { type: NotificationConst },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    JourneyDateNotification: build.query<
      HTTPResponse<IJourneyNotification[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/notification/journey-date?page=${arg?.current}&size=${arg?.pageSize}`,
        method: 'GET',
      }),
      providesTags: ['invoices', 'notifications'],
    }),
  }),
});

export const {
  useGetAllCollectionChequeNotificationsQuery,
  useGetAllDueInvoiceNotificationsQuery,
  useGetAllExpiresPassportNotificationsQuery,
  useGetAllPendingPaymentChequeNotificationsQuery,
  useGetAllVisaDelivaryNotificationsQuery,

  //------------------
  useUpdatePayChequeStatusMutation,
  useUpdateCollectionChequeStatusMutation,
  useVisaStatusUpdateMutation,
  useConvertPdfToTextMutation,

  useJourneyDateNotificationQuery,
} = notificationEndpoints;
