import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useLazyGetSingleAgentProfileQuery } from '../../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';

import { useWatch } from 'antd/es/form/Form';
import { useReactToPrint } from 'react-to-print';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectAgent } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetAgentsLedgerReportbyIDQuery,
  useLazyGetExcelQuery,
} from '../endpoints/ledgerEndpoints';
import { AgentReportsDataUtils } from '../utils/AgentReportsDataUtils';
import { landscapePageStyle } from './ClientLedgers';
import { Fixed2, rangePresets } from '../../../../common/utils/common.utils';
type Props = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Agent_Reports({}: Props) {
  const query = useQuery();
  const queryId = query.get('agent_id');

  useEffect(() => {
    if (queryId) {
      form.setFieldValue('agent_id', Number(queryId));
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
      form.submit();
    }
  }, [queryId]);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  const [fetchExcel] = useLazyGetExcelQuery();

  const [
    fetchagentsLedger,
    { data: agentsLedger, isLoading: agentsLoading, isFetching, isSuccess },
  ] = useLazyGetAgentsLedgerReportbyIDQuery();

  const [
    fetchSingleAgentsDetails,
    { data: agentsDetails, isLoading: isVendorLoading },
  ] = useLazyGetSingleAgentProfileQuery();

  const agentsInfoData = [
    {
      key: '1',
      name: 'Name',
      value: agentsDetails?.data?.length && agentsDetails?.data[0].agent_name,
    },
    {
      key: '2',
      name: 'Email',
      value: agentsDetails?.data?.length && agentsDetails?.data[0].agent_email,
    },
    {
      key: '3',
      name: 'Mobile',
      value: agentsDetails?.data?.length && agentsDetails?.data[0].agent_mobile,
    },
  ];

  const vendorColumn = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '150px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '120px',
    },
  ];

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);

  const agentField = useWatch('agent_id', form);

  useEffect(() => {
    if (agentField) {
      fetchSingleAgentsDetails(agentField);
    }
  }, [agentField]);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: ` Agent_Reports`,
    pageStyle: landscapePageStyle,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const LastPage = Math.ceil(
    Number(agentsLedger?.count || 0) / (pagination?.pageSize || 0)
  );

  // -------//-----------//-------HANDLE PRINT-----------//--------------//----
  const ledger_content = agentsLedger?.count ? (
    <>
      <Typography.Text style={{ margin: 10 }}>
        You have total: {agentsLedger.count} rows
      </Typography.Text>
      <Table
        size='small'
        bordered
        rowKey={(e) => e.agtrxn_id}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={agentsLedger?.data?.ledgers}
        columns={AgentReportsDataUtils({ pagination })}
        // scroll={{ x: true }}
        loading={{
          spinning: agentsLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          agentsLedger?.count !== undefined && agentsLedger?.count < 20
            ? false
            : {
                ...pagination,
                total: agentsLedger?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        summary={(_) => {
          if (pagination?.current === LastPage)
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  <div className=''>
                    <Typography.Text strong>Total: </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={2}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ color: 'red' }}>
                      {Fixed2(agentsLedger.data?.summary_amount.total_dr)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2} colSpan={2}>
                  <div style={{ textAlign: 'left' }}>
                    <Typography.Text style={{ color: 'green', marginLeft: 2 }}>
                      {Fixed2(agentsLedger.data?.summary_amount.total_cr)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={3} />
              </Table.Summary.Row>
            );
        }}
      />{' '}
    </>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const agent_info = agentsDetails?.data?.length
    ? agentsDetails?.data[0]
    : undefined;
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={ledger_content}
        printRef={componentRef}
        extraInfo={{
          address: '',
          email: (agent_info && agent_info?.agent_email) || '',
          mobile: (agent_info && agent_info?.agent_mobile) || '',
          name: (agent_info && agent_info?.agent_name) || '',
        }}
        title={{ info_title: 'Agent Details', title: 'Agent Reports' }}
      />
    </div>
  );
  // @Handle pagination
  const handleSubmit = (values: any) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    fetchagentsLedger({
      agent_id: values.agent_id,
      dateRange: date_range,
      page: pagination.current,
      size: pagination.pageSize,
    });
    setExcelQuery({
      Id: values.agent_id,
      query: `?${date_range}&page=${pagination.current}&size=${pagination.pageSize}`,
    });
  };

  return (
    <div>
      {!id && <BreadCrumb arrOfOption={['Report', 'Agent Reports']} />}
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (agentsLedger?.count)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: 'agent_excel_report',
                excelName: 'Agent_ledger',
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col lg={20} xl={16}>
          <Form layout='vertical' form={form} onFinish={handleSubmit}>
            <Row gutter={[10, 10]}>
              <SelectAgent
                name='agent_id'
                size={12}
                mdSize={8}
                smSize={8}
                xlSize={5}
                label='Select Agents'
                offDropDown
                required
              />

              <Col sm={10} md={6} lg={6} xl={6}>
                <Form.Item
                  label='Date Range'
                  name='date_range'
                  rules={[
                    { required: true, message: ` Date Range is required!` },
                  ]}
                >
                  <DatePicker.RangePicker
                     presets={rangePresets}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>

              <Col sm={2} md={2} lg={2} xl={2}>
                <FormButton
                  label='Search'
                  icon
                  loading={agentsLoading || isFetching}
                />
              </Col>
            </Row>
          </Form>
        </Col>

        {agentsDetails?.data && (
          <Col lg={8} xl={8} className='p-5'>
            <FormHeaderTitle title='Agent Reports Details' />
            <Table
              className='invoiceBillingTable'
              bordered
              rowClassName={'invoiceBillingTd'}
              showHeader={false}
              size='small'
              pagination={
                agentsInfoData.length >= 20
                  ? {
                      defaultPageSize: 20,
                      showSizeChanger: true,
                      pageSizeOptions: ['20', '50', '100', '200', '500'],
                      pageSize: 20,
                    }
                  : false
              }
              columns={vendorColumn}
              dataSource={agentsInfoData}
              locale={{ emptyText: ' ' }}
              loading={{
                spinning: isVendorLoading,
                indicator: loadingIndicator,
              }}
            />
          </Col>
        )}
      </Row>

      {/* AGENT INFO */}
      <div className='p-5 my-20'>{ledger_content}</div>

      {/* @PRINT COMPONENT */}
      {print_content}
    </div>
  );
}

export default Agent_Reports;
