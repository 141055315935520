import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { DAILYSALESREPORT } from '../../Api/constants';
import {
  IDailySalesReportDataType,
  IDailySalesReportList,
} from '../types/DailySalesReportTypes';

export const DailySalesReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getDailySalesReport: build.query<
      HTTPResponse<IDailySalesReportList>,
      IDailySalesReportDataType
    >({
      query: ({
        current,
        pageSize,
        comb_client,
        from_date,
        to_date,
        employee_id,
        product_id,
      }) => ({
        url: `/report/daily-sales-report?from_date=${from_date}&to_date=${to_date}&page=${current}&size=${pageSize}`,
        method: 'POST',
        body: { comb_client, employee_id, product_id },
      }),
      providesTags: [{ type: 'DailySalesReport', id: DAILYSALESREPORT }],
    }),

    getExcelDailySalesReport: build.query<
      any,
      { from_date: string; to_date: string }
    >({
      query: ({ from_date, to_date }) => ({
        url: `/report/daily-sales-report/excel?from_date=${from_date}&to_date=${to_date}`,
        responseHandler: async (response) => {
          const filename = `SalesReport.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});
export const {
  useGetDailySalesReportQuery,
  useLazyGetDailySalesReportQuery,
  useLazyGetExcelDailySalesReportQuery,
} = DailySalesReportEndpoints;
