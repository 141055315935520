import { Button, Col, Form, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { IProps } from '../../../../../Client/Agents_Profile/types/agentProfileTypes';
import { useAppDispatch } from '../../../../../../app/hooks';
import { useSelector } from 'react-redux';
import { useLazyGetAllAccountsByTypeQuery } from '../../../../Api/Endpoints/accountsEndpoints';
import {
  useEditIncentiveIncomeMutation,
  useLazyGetViewIncentiveIncomeQuery,
} from '../../endpoints/incentiveIncomeEndpoints';
import {
  IAccountIncentiveIncome,
  IAccountIncentiveIncomeEditFormData,
  IaccountsByMethod,
} from '../../../../AccountsTypes/AccountsTypes';
import { selectUser } from '../../../../../../auth/states/userSlice';
import { setModal } from '../../../../../../common/slices/commonSlice';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../../../components/common/spinner/LoadingIndicator';
import Select_Vendor from '../../../../components/Select_Vendor';
import { SelectOptionsInput } from '../../../../../../components/common/FormItem/SelectCustomFeilds';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../../../components/common/paymentMethod/SelectPaymentMethod';

type Props = {
  id?: number;
};

function Incentive_Income_Edit_Modal({ id }: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [adjustBill, setAdjustBill] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onselect = (value: string) => {
    setAdjustBill(value);
  };
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const isChanged = useRef(false);

  const user = useSelector(selectUser);

  const [fetchData, { data, isLoading: dataLoading, isSuccess: dataSuccess }] =
    useLazyGetViewIncentiveIncomeQuery();

  const info = data?.data && data.data[0];

  useEffect(() => {
    if (info || dataSuccess) {
      form.setFieldsValue({
        vendor_id: info?.incentive_vendor_id,
        adjust_with_bill: info?.incentive_adjust_bill,
        amount: info?.incentive_amount,
        date: dayjs(info?.incentive_created_date),
        incentive_note: info?.incentive_note,
        type_id: info?.incentive_account_category_id,
        account_id: info?.incentive_account_id,
      });
      if (info?.incentive_adjust_bill == 'NO') {
        setAdjustBill('NO');
      } else {
        setAdjustBill('YES');
      }
      if (info?.incentive_account_category_id) {
        setPaymentMethod(info?.incentive_account_category_id.toString());
      }
    }
  }, [info, dataSuccess]);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });

      return;
    }
  }, [paymentMethod]);

  const [editIncentive, { isSuccess }] = useEditIncentiveIncomeMutation();

  const handleSubmit = async (values: IAccountIncentiveIncomeEditFormData) => {
    const body: IAccountIncentiveIncomeEditFormData = {
      ...values,
      incentive_id: Number(info?.incentive_id),
      date: dayjs(values.date).format('YYYY-MM-DD'),
      incentive_created_by: user?.user_id as number,
    };
    await editIncentive(body);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const showModal = () => {
    setIsModalOpen(true);
    fetchData(Number(id));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type='primary' size='small' onClick={showModal}>
        Edit
      </Button>

      <Modal
        title='Edit Vendor Incentive Income'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          {dataLoading && <LoadingIndicator />}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Select_Vendor disabled />

            <SelectOptionsInput
              name='adjust_with_bill'
              label='Adjust With Bill'
              options={['YES', 'NO']}
              size={12}
              onChange={(e) => onselect(e.toString())}
            />

            <NumberInput
              name='amount'
              label='Amount'
              required
              size={12}
              min='0'
              maxChar={14}
            />
            {adjustBill == 'NO' && (
              <>
                <SelectPaymentMethod
                  name='type_id'
                  label='Payment Method'
                  size={12}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isChanged.current) isChanged.current = true;
                  }}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name='account_id'
                    label='Account'
                    rules={[
                      {
                        required: true,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod, index) => {
                          return (
                            <Select.Option key={index} value={item.account_id}>
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <DateInput name='date' label='Date' size={12} />
            <TextAreaInput name='incentive_note' label='Note' size={24} />
          </Row>

          <FormButton label='Edit Incentive Income' />
        </Form>
      </Modal>
    </>
  );
}

export default Incentive_Income_Edit_Modal;
