import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Space, TimePicker } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormListFieldData } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectSingleAirport,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { AirTicket_ticket_information } from '../types/invoiceAirTicketTypes';

type Props = {
  index: number;
  field: FormListFieldData;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  ticketInfo?: AirTicket_ticket_information[];
  length: number;
};

const formItemLayout = {
  labelcol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrappercol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrappercol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

export default function Flight_Details_List_Info({
  index,
  field,
  add,
  remove,
  form,
  ticketInfo,
  length,
}: Props) {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const { airticketInvoiceId, nonComissionEditId } = useParams();

  const fltdetails_from_airport_id = useWatch(
    ['flight_details', field.name, 'fltdetails_from_airport_id'],
    form
  );
  const fltdetails_to_airport_id = useWatch(
    ['flight_details', field.name, 'fltdetails_to_airport_id'],
    form
  );
  const fltdetails_airline_id = useWatch(
    ['flight_details', field.name, 'fltdetails_airline_id'],
    form
  );
  const fltdetails_flight_no = useWatch(
    ['flight_details', field.name, 'fltdetails_flight_no'],
    form
  );
  const fltdetails_fly_date = useWatch(
    ['flight_details', field.name, 'fltdetails_fly_date'],
    form
  );
  const fltdetails_departure_time = useWatch(
    ['flight_details', field.name, 'fltdetails_departure_time'],
    form
  );
  const fltdetails_arrival_time = useWatch(
    ['flight_details', field.name, 'fltdetails_arrival_time'],
    form
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      fltdetails_from_airport_id ||
      fltdetails_to_airport_id ||
      fltdetails_airline_id ||
      fltdetails_flight_no ||
      fltdetails_fly_date ||
      fltdetails_departure_time ||
      fltdetails_arrival_time
    ) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      timeoutId = setTimeout(() => {
        form.validateFields([
          ['flight_details', field.name, 'fltdetails_from_airport_id'],
          ['flight_details', field.name, 'fltdetails_to_airport_id'],
          ['flight_details', field.name, 'fltdetails_airline_id'],
          ['flight_details', field.name, 'fltdetails_flight_no'],
          ['flight_details', field.name, 'fltdetails_fly_date'],
          ['flight_details', field.name, 'fltdetails_departure_time'],
          ['flight_details', field.name, 'fltdetails_arrival_time'],
        ]);
      });
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    fltdetails_from_airport_id,
    fltdetails_to_airport_id,
    fltdetails_airline_id,
    fltdetails_flight_no,
    fltdetails_fly_date,
    fltdetails_departure_time,
    fltdetails_arrival_time,
  ]);

  const [disabled, setDisabled] = useState(false);
  const is_deleted: 0 | 1 = useWatch(
    ['flight_details', index, 'is_deleted'],
    form
  );

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);

  return (
    <Row
      align={'middle'}
      key={index}
      gutter={[10, 0]}
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
    >
      <SelectSingleAirport
        name={[field.name, 'fltdetails_from_airport_id']}
        label='From:'
        required={isRequired}
        size={6}
        form={form}
      />
      <SelectSingleAirport
        name={[field.name, 'fltdetails_to_airport_id']}
        label='To:'
        required={isRequired}
        size={6}
        form={form}
      />
      <SelectAirline
        name={[field.name, 'fltdetails_airline_id']}
        label='Airline:'
        required={isRequired}
        size={6}
      />

      <FormInputItem
        label='Flight No:'
        name={[field.name, 'fltdetails_flight_no']}
        size={6}
        required={isRequired}
      />

      <DateInput
        label='Fly Date:'
        name={[field.name, 'fltdetails_fly_date']}
        size={6}
        required={isRequired}
      />

      <Col span={6} xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          className='commonBox'
          label='Departure Time:'
          name={[field.name, 'fltdetails_departure_time']}
          colon={false}
        >
          <TimePicker style={{ width: '100%' }}></TimePicker>
        </Form.Item>
      </Col>

      <Col span={6} xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          className='commonBox'
          label='Arrival Time:'
          name={[field.name, 'fltdetails_arrival_time']}
          colon={false}
        >
          <TimePicker style={{ width: '100%' }}></TimePicker>
        </Form.Item>
      </Col>

      <Col lg={1} style={{ marginTop: 30 }}>
        <Space direction='horizontal'>
          <Form.Item label={false}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'flight_details',
                  field.name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['flight_details', field.name, 'is_deleted'],
                  Number(!prev)
                );
                if (airticketInvoiceId || nonComissionEditId) {
                  if (
                    ticketInfo &&
                    ticketInfo[index]?.flight_details?.length &&
                    index >= (ticketInfo[index]?.flight_details?.length || 0)
                  )
                    remove(field.name);
                } else {
                  remove(field.name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
          {length === index + 1 && (
            <Form.Item label={false}>
              <Button type='primary' onClick={() => add()}>
                <PlusOutlined />
              </Button>
            </Form.Item>
          )}
        </Space>
      </Col>
    </Row>
  );
}
