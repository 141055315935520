import { Col, Input, Row, Table, Typography } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportWithPaginationQuery } from '../endpoints/airportEndpoints';
import { AirportDataType } from '../types/AirportTypes';
import { airportColumns } from '../utils/utils';

const Airport = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [editInfo, setEditInfo] = useState<AirportDataType | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const user = useSelector(selectUser);
  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    isFetching,
    data: airport,
    refetch,
  } = useGetAllAirportWithPaginationQuery(
    { ...pagination, search: search },
    {
      selectFromResult: (cache) => {
        const data: any = cache.data?.data;
        const dataToReturn: AirportDataType[] = [];
        const airportNameList: string[] = [];
        const total = cache.data?.count;

        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            airportNameList.push(element.airline_airport);
            dataToReturn.push({
              ...element,
              key: i + 1,
              airline_created_by: user?.user_id as number,
            });
          }
        }
        return { ...cache, data: { dataToReturn, airportNameList, total } };
      },
    }
  );

  return (
    <>
      <PageStyle>
        <BreadCrumb
          arrOfOption={['Configuration', 'Airport']}
          refetch={refetch}
          reloaderSize='small'
        />

        <Row justify={'center'}>
          <Col span={13}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <Typography.Title level={4}>Airport List</Typography.Title>
              </Col>
              <Col>
                <Input.Search
                  allowClear
                  onSearch={setSearch}
                  style={{ margin: '10px 0px' }}
                  placeholder='Search Airport...'
                />
              </Col>
            </Row>

            <Table
              size='small'
              bordered
              pagination={{
                ...pagination,
                total: airport.total,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }}
              columns={airportColumns({
                setEditInfo,
                showModal,
                permission,
                pagination,
              })}
              dataSource={airport.dataToReturn}
              scroll={{ x: true }}
              loading={{
                spinning: isLoading || isFetching,
                indicator: <LoadingIndicator />,
              }}
            />
          </Col>
        </Row>
      </PageStyle>
    </>
  );
};

export default Airport;

const PageStyle = styled.div``;
