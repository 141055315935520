import { FormListFieldData, Row } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import {
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectOptionsInput,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetItineraryVendorsInfoQuery } from '../../../Configuration/Tour/Ticket/endpoints/tourTicketEndpoints';
import { SelectTourItinerary } from '../Components/SelectTourItinerary';
import { IInvoiceTourFood } from '../../Types/InvoiceTourTypes';
import { useParams } from 'react-router';

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  invComClientInfo: string | undefined;
  index: number;
  tourFood: IInvoiceTourFood[] | undefined;
};

const FoodForm = ({
  field,
  form,
  invComClientInfo,
  index,
  tourFood,
}: Props) => {
  //setField vendor name
  const [instanceVendorSelect, setInstanceVendorSelect] = useState<number>();
  const [instanceFoodSelect, setInstanceFoodSelect] = useState<number>();
  const { id } = useParams();
  /* if any field select other field are auto required */
  const food_itinerary_id: number | undefined = useWatch(
    ['tourFoods', field.name, 'food_itinerary_id'],
    form
  );
  const food_cost_price = useWatch(
    ['tourFoods', field.name, 'food_cost_price'],
    form
  );
  const food_comvendor_id = useWatch(
    ['tourFoods', field.name, 'food_comvendor_id'],
    form
  );
  const food_description = useWatch(
    ['tourFoods', field.name, 'food_description'],
    form
  );

  const food_cost_price_edit = form.getFieldValue([
    'tourFoods',
    field.name,
    'food_cost_price_edit',
  ]);

  const food_menu = useWatch(['tourFoods', field.name, 'food_menu'], form);
  const food_place = useWatch(['tourFoods', field.name, 'food_place'], form);
  const food_time = useWatch(['tourFoods', field.name, 'food_time'], form);
  const requiredField =
    food_comvendor_id ||
    food_cost_price ||
    food_description ||
    food_itinerary_id ||
    food_menu ||
    food_place ||
    food_time;

  const [isRequired, setIsRequired] = useState<boolean>(false);
  useEffect(() => {
    if (food_itinerary_id) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  }, [food_itinerary_id]);

  // itinerary
  const [itineraryId, setItineraryId] = useState<number | undefined>();
  const [vendorId, setVendorId] = useState<number>();

  const [getItineraryVendorsInfo, { data: vendors, isFetching, isSuccess }] =
    useLazyGetItineraryVendorsInfoQuery();

  useEffect(() => {
    if (tourFood?.length) {
      setItineraryId(food_itinerary_id);
    } else if (food_comvendor_id) {
      setVendorId(Number(food_comvendor_id?.toString().split('-')[1]));
    }
  }, [id, vendors?.data]);

  useEffect(() => {
    //this for when create tour food,its not work for editing
    if (!id) {
      const selectVendor = vendors?.data?.find(
        (item) => item.itnrvendor_vendor_id === vendorId
      );

      console.log(selectVendor);

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourFoods', field.name, 'food_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        // form.setFields([
        //   {
        //     name: ['tourFoods', field.name, 'food_cost_price'],
        //     value: undefined,
        //   },
        // ]);
      }
    }

    //this only work for when editing
    if (
      (id && food_comvendor_id && food_itinerary_id && itineraryId) ||
      (id && food_comvendor_id && food_itinerary_id && vendorId)
    ) {
      const selectVendor = vendors?.data?.find(
        (item) =>
          item.itnrvendor_vendor_id ===
          Number(food_comvendor_id?.toString().split('-')[1])
      );

      if (!food_cost_price_edit && selectVendor) {
        return form.setFields([
          {
            name: ['tourFoods', field.name, 'food_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      }

      if (food_cost_price_edit) {
        return form.setFields([
          {
            name: ['tourFoods', field.name, 'food_cost_price'],
            value: Number(food_cost_price_edit),
          },
        ]);
      }

      form.setFields([
        {
          name: ['tourFoods', field.name, 'food_cost_price'],
          value: undefined,
        },
      ]);

      // if (!food_cost_price_edit && selectVendor?.itnrvendor_cost_price) {
      //   form.setFields([
      //     {
      //       name: ['tourFoods', field.name, 'food_cost_price'],
      //       value: Number(selectVendor?.itnrvendor_cost_price),
      //     },
      //   ]);
      // } else {
      //   form.setFields([
      //     {
      //       name: ['tourFoods', field.name, 'food_cost_price'],
      //       value: food_cost_price_edit || undefined,
      //     },
      //   ]);
      // }
    }
  }, [
    food_itinerary_id,
    food_comvendor_id,
    vendors?.data,
    vendorId,
    itineraryId,
  ]);

  useEffect(() => {
    if (food_itinerary_id) {
      getItineraryVendorsInfo(Number(food_itinerary_id));
    }
  }, [food_itinerary_id]);
  //setField vendor name
  useEffect(() => {
    if (instanceVendorSelect) {
      form.setFieldValue(
        ['tourFoods', field.name, 'food_comvendor_id'],
        'vendor-' + instanceVendorSelect
      );
    } else if (instanceFoodSelect) {
      form.setFieldValue(
        ['tourFoods', field.name, 'food_itinerary_id'],
        instanceFoodSelect
      );
    }
  }, [instanceVendorSelect, instanceFoodSelect]);
  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const food_is_deleted: 0 | 1 = useWatch(
    ['tourFoods', index, 'food_is_deleted'],
    form
  );
  useEffect(() => {
    if (food_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [food_is_deleted]);

  return (
    <Row
      style={
        food_is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }
          : { display: 'flex', alignItems: 'center', width: '100%' }
      }
      gutter={[5, 5]}
    >
      <SelectTourItinerary
        label='food'
        name={[field.name, 'food_itinerary_id']}
        onChange={setItineraryId}
        form={form}
        isRequired={requiredField}
        size={4}
        setInstanceTransportSelect={setInstanceFoodSelect}
      />

      <FormInputItem
        label='Description'
        name={[field.name, 'food_description']}
        size={4}
      />

      <NumberInput
        label='Cost Price'
        name={[field.name, 'food_cost_price']}
        size={4}
        required={requiredField}
        min={'0'}
        maxChar={14}
      />
      <FormInputItem
        label='Food Place'
        name={[field.name, 'food_place']}
        size={4}
      />
      <SelectOptionsInput
        label='Meal Time'
        name={[field.name, 'food_menu']}
        options={['Dinner', 'Lunch', 'Breakfast', 'Supper']}
        size={3}
      />

      <SelectVendors
        label='Vendor'
        size={4}
        name={[field.name, 'food_comvendor_id']}
        onChange={(e) => setVendorId(Number(e?.toString().split('-')[1]))}
        required={requiredField}
        dependencies={['invoice_combclient_id']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (getFieldValue('invoice_combclient_id')) {
                if (getFieldValue('invoice_combclient_id') === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              return Promise.resolve();
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        setInstanceVendorSelect={setInstanceVendorSelect}
      />
    </Row>
  );
};

export default FoodForm;
