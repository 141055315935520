import { Col, Form, Row, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import PassportInfo from '../../Invoice(Visa)/Components/PassportInfo';
import {
  useEditInvoiceOtherMutation,
  useGetInvoiceOtherDetailsQuery,
} from '../Api/invoiceOtherEndpoints';
import HotelInformation from '../Components/HotelInformation';
import TicketInformation from '../Components/TicketInformation';
import TransportInformation from '../Components/TransportInformation';
import {
  IInvoiceOtherFormattedValueType,
  IOtherInvoiceFormValue,
} from '../Type/Invoce.other.interface';
import { useAppSelector } from '../../../app/hooks';
import { InvoiceDisPrevRadioButton } from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import { FormatDateV1 } from '../../../common/utils/common.utils';

type Props = {};

const EditInvoice_Other = (props: Props) => {
  // get preivoius invoice
  const id = Number(useParams().id);
  const { data, isLoading } = useGetInvoiceOtherDetailsQuery(id);
  const invoiceDetails = data?.data;
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  // ============= set total billing
  const [form] = Form.useForm();

  // EDIT QUERY FOR INVOICE OTHER
  const [
    editInvoiceOther,
    { isError, isSuccess, isLoading: postLoading, error },
  ] = useEditInvoiceOtherMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  const user = useSelector(selectUser);
  // FORM SUBMIT SUCCESSFULLY
  const onFinish = async (values: IOtherInvoiceFormValue) => {
    const passport_informations = values?.passport_information?.map((item) => {
      return {
        is_deleted: item.is_deleted,
        passport_email: item.passport_email ? item.passport_email : undefined,
        passport_date_of_birth: FormatDateV1(item.passport_date_of_birth),
        passport_date_of_issue: FormatDateV1(item.passport_date_of_issue),
        passport_date_of_expire: FormatDateV1(item.passport_date_of_expire),
        passport_mobile_no: item.passport_mobile_no
          ? item.passport_mobile_no
          : undefined,
        passport_name: item.passport_name ? item.passport_name : undefined,
        passport_passport_no: item.passport_passport_no
          ? item.passport_passport_no
          : undefined,

        passport_id: item.passport_id ? item.passport_id : undefined,
        passport_visiting_country: item.passport_visiting_country
          ? item.passport_visiting_country
          : undefined,
      };
    });

    const passport_information =
      Object.keys(
        values.passport_information.length && values.passport_information[0]
      ).length !== 0
        ? passport_informations
        : undefined;
    const ticketInfos = values.ticketInfo.map((item) => {
      return {
        ticket_is_deleted: item.ticket_is_deleted,
        ticket_journey_date: FormatDateV1(item.ticket_journey_date),
        ticket_return_date: FormatDateV1(item.ticket_return_date),
        ticket_airline_id: item.ticket_airline_id
          ? item.ticket_airline_id
          : undefined,
        ticket_no: item.ticket_no ? item.ticket_no : undefined,
        ticket_pnr: item.ticket_pnr ? item.ticket_pnr : undefined,
        ticket_reference_no: item.ticket_reference_no
          ? item.ticket_reference_no
          : undefined,
        ticket_route: item?.ticket_route ? item.ticket_route : undefined,
      };
    });
    const ticketInfo =
      Object.keys(values.ticketInfo.length && values.ticketInfo[0]).length !== 0
        ? ticketInfos
        : undefined;

    const hotel_informations = values.hotel_information.map((item) => {
      return {
        ...item,
        hotel_name: item.hotel_name || undefined,
        hotel_check_out_date: FormatDateV1(item.hotel_check_out_date),
        hotel_reference_no: item.hotel_reference_no || undefined,
        hotel_room_type_id: item.hotel_room_type_id || undefined,
        hotel_check_in_date: FormatDateV1(item.hotel_check_in_date),
      };
    });
    const hotel_information =
      Object.keys(
        values.hotel_information.length && values.hotel_information[0]
      ).length !== 0
        ? hotel_informations
        : undefined;

    const transport_informations = values.transport_information.map((item) => {
      return {
        ...item,
        transport_is_deleted: item.transport_is_deleted,
        transport_pickup_time: item.transport_pickup_time
          ? dayjs(item.transport_pickup_time).format()
          : undefined,
        transport_dropoff_time: item.transport_dropoff_time
          ? dayjs(item.transport_dropoff_time).format()
          : undefined,
        transport_dropoff_place: item.transport_dropoff_place
          ? item.transport_dropoff_place
          : undefined,
        transport_pickup_place: item.transport_pickup_place
          ? item.transport_pickup_place
          : undefined,
        transport_reference_no: item.transport_reference_no
          ? item.transport_reference_no
          : undefined,
        transport_type_id: item.transport_type_id
          ? item.transport_type_id
          : undefined,
      };
    });
    const transport_information =
      Object.keys(
        values.transport_information.length && values.transport_information[0]
      ).length !== 0
        ? transport_informations
        : undefined;

    values.billing_information = values?.billing_information?.map(
      (item, index) => ({
        ...item,
        billing_id: invoiceDetails?.billing_information[index]?.billing_id,
      })
    );
    const body: IInvoiceOtherFormattedValueType = {
      ...values,
      invoice_due_date: FormatDateV1(values.invoice_due_date),
      invoice_sales_date: FormatDateV1(values.invoice_sales_date),
      passport_information: passport_information,
      ticketInfo: ticketInfo,
      hotel_information: hotel_information,
      transport_information: transport_information,
      invoice_created_by: user?.user_id as number,
    };

    body.passport_information = body?.passport_information?.map(
      (item, index) => ({
        ...item,
        other_pass_id:
          invoiceDetails?.passport_information[index]?.other_pass_id,
      })
    );
    body.ticketInfo = body?.ticketInfo?.map((item, index) => ({
      ...item,
      ticket_id: invoiceDetails?.ticketInfo[index]?.ticket_id,
    }));
    body.hotel_information = body?.hotel_information?.map((item, index) => ({
      ...item,
      hotel_id: invoiceDetails?.hotel_information[index]?.hotel_id,
    }));
    body.transport_information = body?.transport_information?.map(
      (item, index) => ({
        ...item,
        transport_id:
          invoiceDetails?.transport_information[index]?.transport_id,
      })
    );

    if (id) {
      const prevNetTotal = invoiceDetails?.invoice_net_total || 0;
      const currentNetTotal = body?.invoice_net_total;
      if (prevNetTotal >= currentNetTotal) {
        await editInvoiceOther({ body, id: id }).then((res: any) => {
          if (res && id && isSuccess)
            navigate(`/invoiceother/details/${id}`, {
              state: location.pathname,
            });
        });
      } else {
        checkCreditLimit({
          amount: currentNetTotal - prevNetTotal,
          combClient: body?.invoice_combclient_id,
        }).then(async (res: any) => {
          if (res?.data?.data === 1) {
            await editInvoiceOther({ body, id: id }).then((res: any) => {
              if (res && id && isSuccess)
                navigate(`/invoiceother/details/${id}`, {
                  state: location.pathname,
                });
            });
          } else {
            message.error(res?.data?.data);
          }
        });
      }
    }
  };

  // NEVIGATE TO ALL INVOICES
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice other edited successfully!');
      updateAndPrev
        ? navigate(`/invoiceother/details/${id}`)
        : navigate('/invoiceother');
    }
    if (isError) {
      message.error('Thare was an error');
    }
  }, [postLoading]);

  // SUBMIT FAILD
  const onFinishFailed = (errorInfo: any) => {
    message.error('Submit failed!');
  };

  useEffect(() => {
    form.setFieldsValue({
      invoice_combclient_id: invoiceDetails?.invoice_combclient_id,
      invoice_agent_id: invoiceDetails?.invoice_agent_id,
      invoice_sales_man_id: invoiceDetails?.invoice_sales_man_id,
      invoice_discount: invoiceDetails?.invoice_discount,
      invoice_no: invoiceDetails?.invoice_no,
      invoice_note: invoiceDetails?.invoice_note,
      invoice_reference: invoiceDetails?.invoice_reference,
      invoice_vat: invoiceDetails?.invoice_vat,
      invoice_service_charge: invoiceDetails?.invoice_service_charge,
      invoice_due_date: invoiceDetails?.invoice_due_date
        ? dayjs(invoiceDetails?.invoice_due_date)
        : undefined,
      invoice_sales_date: invoiceDetails?.invoice_sales_date
        ? dayjs(invoiceDetails?.invoice_sales_date)
        : undefined,
      invoice_show_discount: invoiceDetails?.invoice_show_discount,
      invoice_show_prev_due: invoiceDetails?.invoice_show_prev_due,
    });
  }, [invoiceDetails]);

  // SET FORM LIST
  const hotelInfoData = invoiceDetails?.hotel_information?.map((item) => {
    return {
      ...item,
      hotel_check_in_date: item.hotel_check_in_date
        ? dayjs(item.hotel_check_in_date)
        : undefined,
      hotel_check_out_date: item.hotel_check_out_date
        ? dayjs(item.hotel_check_out_date)
        : undefined,
    };
  });
  const passportInfoData = invoiceDetails?.passport_information?.map((item) => {
    return {
      ...item,
      passport_date_of_birth: item.passport_date_of_birth
        ? dayjs(item.passport_date_of_birth)
        : undefined,
      passport_date_of_expire: item.passport_date_of_expire
        ? dayjs(item.passport_date_of_expire)
        : undefined,
      passport_date_of_issue: item.passport_date_of_issue
        ? dayjs(item.passport_date_of_issue)
        : undefined,
    };
  });
  const ticketInfoData = invoiceDetails?.ticketInfo?.map((item) => {
    const abc: any = Number(item?.ticket_route);
    return {
      ...item,
      ticket_journey_date: item.ticket_journey_date
        ? dayjs(item.ticket_journey_date)
        : undefined,
      ticket_return_date: item.ticket_return_date
        ? dayjs(item.ticket_return_date)
        : undefined,
      ticket_route: item?.ticket_route
        ? item.ticket_route /* getItaCodeByAirportIds([abc] as string[], selectAirport) */
        : undefined,
      ticket_route_prev: item?.ticket_route
        ? item.ticket_route /* getItaCodeByAirportIds([abc] as string[], selectAirport) */
        : undefined,
    };
  });
  const transportInfo = invoiceDetails?.transport_information?.map((item) => {
    return {
      ...item,
      transport_dropoff_time: item.transport_dropoff_time
        ? dayjs(item.transport_dropoff_time)
        : undefined,
      transport_pickup_time: item.transport_pickup_time
        ? dayjs(item.transport_pickup_time)
        : undefined,
    };
  });

  useEffect(() => {
    form.setFields([
      {
        name: 'hotel_information',
        value: hotelInfoData?.length ? hotelInfoData : [{}],
      },
      {
        name: 'passport_information',
        value: passportInfoData?.length ? passportInfoData : [{}],
      },
      {
        name: 'ticketInfo',
        value: ticketInfoData?.length ? ticketInfoData : [{}],
      },
      {
        name: 'transport_information',
        value: transportInfo?.length ? transportInfo : [{}],
      },
      {
        name: 'billing_information',
        value: invoiceDetails?.billing_information?.length
          ? invoiceDetails?.billing_information.map((item) => ({
              ...item,
              billing_product_id: item?.billing_product_id,
              billing_vendor_id: item?.billing_vendor_id,
            }))
          : [{}],
      },
    ]);
  }, [invoiceDetails]);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice (Other) ', 'edit']} />
      {(isLoading || postLoading) && <LoadingIndicator />}
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        form={form}
      >
        <InvoiceHeaderForm
          isEdit
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        <PassportInfo form={form} />

        {/* <PassportInformation
          passport_information={passportInfoData}
          form={form}
        /> */}
        <TicketInformation
          form={form}
          ticket_information={invoiceDetails?.ticketInfo}
        />
        <HotelInformation
          form={form}
          hotel_info={invoiceDetails?.hotel_information}
        />
        <TransportInformation
          form={form}
          trans_info={invoiceDetails?.transport_information}
        />
        <InvoiceBillingInfo
          form={form}
          billing_information={invoiceDetails?.billing_information}
          conditionalCostVendor
        />

        <InvoiceVisaSubTotalForm form={form} />

        <InvoiceDisPrevRadioButton form={form} style={{ marginTop: 20 }} />

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={postLoading}
              label='UPDATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={postLoading}
              label='UPDATE & VIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </ClientsStyle>
  );
};

export default EditInvoice_Other;

const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
`;
