import { Form, Modal, Row, Spin, Typography, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { setModal } from '../../../common/slices/commonSlice';
import { removeUndefinedAndNull } from '../../../common/utils/removeUndefinedAndNull';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import {
  useLazyGetWorkFlowPassportQuery,
  usePostVisaprocessMutation,
} from '../api/visaProcessingEndpoints';
import { IVisaAssignType } from '../types/visa_processingType';
import VisaAssignFormItem from './VisaAssignFormItem';

type IProps = {
  invoiceId: number | undefined;
};
const VisaAssign = ({ invoiceId }: IProps) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const modalSlice = useSelector((state: RootState) => state.commonSlice);

  const [postVisaProcess, { data, isSuccess, isLoading, isError }] =
    usePostVisaprocessMutation();

  const [
    getPass,
    {
      isFetching: passFetch,
      isLoading: passLoad,
      isSuccess: passSucc,
      data: passData,
    },
  ] = useLazyGetWorkFlowPassportQuery();

  useEffect(() => {
    if (invoiceId) getPass({ id: invoiceId });
  }, [modalSlice.isModal]);

  const onFinish = (values: { assign_form_item: IVisaAssignType[] }) => {
    const formDataArray = values.assign_form_item.map((item, index) => {
      const { doc, ...all } = item as IVisaAssignType;
      const formattedData = removeUndefinedAndNull({
        ...all,
        index: index,
        tvw_invoice_id: invoiceId,
        tvw_visa_deliver_date:
          item.tvw_visa_deliver_date &&
          dayjs(item.tvw_visa_deliver_date).format('YYYY-MM-DD'),
        tvw_visa_expire_date:
          item.tvw_visa_expire_date &&
          dayjs(item.tvw_visa_expire_date).format('YYYY-MM-DD'),
        tvw_remarks: item.tvw_remarks,
      });

      return formattedData;
    });

    const formData = new FormData();
    formData.append('invoices', JSON.stringify(formDataArray));

    values.assign_form_item.forEach((item, index) => {
      formData.append(`doc[${index}]`, item.doc[0].originFileObj);
    });

    postVisaProcess(formData);
  };

  useEffect(() => {
    if (passSucc) {
      form.setFieldsValue({
        assign_form_item: passData?.data?.map((item) => ({
          tvw_passport_id: item.passport_id,
        })),
      });
    }
  }, [passSucc]);

  useEffect(() => {
    if (isSuccess) {
      message.success('Employee Assigned successfully Done');
      dispatch(setModal(false));
      form.resetFields();
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Modal
        title='Select Assign Employee'
        open={modalSlice.isModal}
        onCancel={() => dispatch(setModal(false))}
        footer={null}
      >
        {passLoad || passFetch ? (
          <Row
            justify={'center'}
            align={'middle'}
            style={{ margin: '5px 0px' }}
          >
            <Spin />
          </Row>
        ) : (
          <Form form={form} onFinish={onFinish} layout='vertical'>
            {!passData?.data?.length ? (
              <Typography.Text>No passport selected</Typography.Text>
            ) : undefined}
            <Form.List name={'assign_form_item'}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <VisaAssignFormItem name={name} key={key} form={form} />
                  ))}
                </>
              )}
            </Form.List>
            {passData?.data?.length ? (
              <FormButton block textAlign='left' label='Submit' />
            ) : undefined}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default VisaAssign;
