import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Form, Input, Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllBalanceTransferQuery } from '../../Api/Endpoints/balanceTransferEndpoints';
import Add_Balance_Transfer_Modal from '../../modals/Balance_Transfer/Add_Balance_Transfer_Modal';
import { ListOfBalanceTransferColumn } from '../../utils/Balance_Transfer/ListOfBalanceTransferColumn';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import { rangePresets } from '../../../../common/utils/common.utils';

const Balance_Transfer = ({ permission }: perProps) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [form] = Form.useForm();
  const [checkButtonState, setCheckButtonState] = useState(false);
  const dispatch = useAppDispatch();
  const showModal = () => {
    dispatch(setModal(true));
  };
  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const { data, isLoading, isError, isFetching, refetch } =
    useGetAllBalanceTransferQuery({
      ...pagination,
      from: date[0] || '',
      to: date[1] || '',
      search,
    });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };
  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Accounts', 'List of Balance Transfer']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }} justify={'space-between'}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          {permission?.['create:any'] && (
            <Col>
              <Button type='primary' onClick={showModal}>
                <PlusOutlined />
                Add Balance Transfer
              </Button>
            </Col>
          )}
        </Row>
        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {checkButtonState && (
        <Alert
          style={{ marginTop: '1rem' }}
          message='Viewing Trashed Balance Transfer'
          type='error'
        />
      )}
      <Row align={'middle'} justify={'space-between'}>
        <Col>
          <CommonTableHeader
            title='Balance Transfer'
            element={<Add_Balance_Transfer_Modal />}
          />
        </Col>
        <Col></Col>
      </Row>
      <Table
        size='small'
        bordered
        rowKey={(e) => e.btransfer_id}
        columns={ListOfBalanceTransferColumn({
          permission,
          pagination,
          checkButtonState,
        })}
        dataSource={data?.data}
        scroll={{ x: true }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default Balance_Transfer;
