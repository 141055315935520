import { message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { usePostGrouptoGroupMutation } from '../../API/Endpoints/HajjiManageMentEndspont';
import GrouptoGroupForm from '../../Components/GrouptoGroupCom/GrouptoGroupForm';
import { IPostGroupToGroup } from '../../Types/HajjiManagement.interface';

type Props = {};

const AddGroupToGroup = (props: Props) => {
  const user = useSelector(selectUser);

  const [form] = useForm();

  // =========== form submit
  const [postGroupToGroup, { error, isError, isSuccess, isLoading }] =
    usePostGrouptoGroupMutation();
  const onFinish = (values: IPostGroupToGroup) => {
    values.gtransfer_created_by = user?.user_id;
    postGroupToGroup(values);
  };

  // =========== nevigate to view client to client
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Group to Group added successfully!');
      navigate('/haji/transfer/group_list');
    }
    if (isError) {
      message.error('There was an error');
    }
  }, [isLoading]);

  if (isLoading) {
    return loadingIndicator;
  }
  return (
    <div>
      <BreadCrumb arrOfOption={['Create Transfer', 'Group to group']} />
      <GrouptoGroupForm onFinish={onFinish} form={form} />
    </div>
  );
};

export default AddGroupToGroup;
