import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetReceiveQuery } from '../../Api/Endpoints/ReceiveEndpoints';
import { ViewLoanReceiveDataType } from '../../LoanTypes/LoanTypes';
import AddReceiveModal from '../../Modals/Receive/AddReceiveModal';
import EditReceiveModal from '../../Modals/Receive/EditReceiveModal';
import { ViewReceiveList } from '../../Utils/Receive/ViewReceiveList';
import { rangePresets } from '../../../../common/utils/common.utils';

const ReceiveList = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<ViewLoanReceiveDataType | null>(
    null
  );

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    data: receive,
    isLoading,
    isFetching,
    refetch,
  } = useGetReceiveQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Loan Management', 'Receive List']}
        refetch={() => refetch()}
        reloaderSize='small'
      />
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        {permission?.[crud.create] && (
          <Button type='primary' onClick={showModal}>
            <PlusOutlined />
            Add Receive
          </Button>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>
      <CommonTableHeader
        title=''
        modalTitle={editInfo ? 'Edit Receive' : 'Add Receive'}
        element={
          editInfo ? (
            <EditReceiveModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <AddReceiveModal />
          )
        }
        setNull={setEditInfo}
      />

      <Table
        rowKey={(e) => e.received_id}
        size='small'
        bordered
        columns={ViewReceiveList({
          setEditInfo,
          showModal,
          permission,
          pagination,
        })}
        scroll={{ x: true }}
        pagination={
          receive?.count !== undefined && receive?.count < 20
            ? false
            : {
                ...pagination,
                total: receive?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        dataSource={receive?.data}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ReceiveList;
