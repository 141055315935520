import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Descriptions,
  Row,
  Space,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useReactToPrint } from 'react-to-print';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { useLazyGetSingleAgentMoneyReceiptQuery } from '../api/endpoints/AgentMoneyReceiptEndPoint';
import { ReceiptHeader } from '../../../../common/Invoice/InvoiceHeader';

type Props = {};
const Agent_Money_Receipt_View = (props: Props) => {
  const { id } = useParams();
  const [singleMoneyReceipts, { data: singleMoneyReceipt, isSuccess }] =
    useLazyGetSingleAgentMoneyReceiptQuery();

  // const moneyreceipt=singleMoneyReceipt?.data[0]
  useEffect(() => {
    singleMoneyReceipts(Number(id));
  }, [id]);
  const [date, setDate] = useState<string>();
  const [receipt_total_amount, setReceipt_total_amount] = useState<number>();

  useEffect(() => {
    if (singleMoneyReceipt?.data) {
      setDate(
        dayjs(singleMoneyReceipt.data.receipt_payment_date).format(
          'DD MMM YYYY'
        )
      );
      if (singleMoneyReceipt.data.receipt_total_amount) {
        setReceipt_total_amount(
          Number(singleMoneyReceipt.data.receipt_total_amount)
        );
      }
    }
  }, [singleMoneyReceipt]);

  const customercomponentRef = useRef(null);
  const cashiercomponentRef = useRef(null);

  const handleCashierPrint = useReactToPrint({
    content: () => cashiercomponentRef.current,
    documentTitle: `MoneyReceipt_cashier_${date}`,
  });
  return (
    <div>
      <BreadCrumb arrOfOption={['Money Receipt', 'View Money Receipt']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/moneyreceipt/agent'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Agent Money Receipt List
          </Button>
        </Link>
        <Button type='primary' onClick={handleCashierPrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: '9in',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              border: '1px solid lightgray',
            }}
          >
            <div
              ref={cashiercomponentRef}
              style={{
                padding: '0.3in',
                width: '8.27in',
                minHeight: '11.69in',
              }}
            >
              <div style={{ margin: '25px 0px', padding: '25px 0px' }}>
                <ReceiptHeader />
                <Typography.Text strong className='mt-5'>
                  Money Receipt No :
                  {singleMoneyReceipt?.data?.receipt_money_receipt_no}
                </Typography.Text>{' '}
                <br />
                <Typography.Text strong className='mt-5'>
                  Voucher No : {singleMoneyReceipt?.data?.receipt_vouchar_no}
                  {singleMoneyReceipt?.data?.receipt_money_receipt_no}
                </Typography.Text>
                <Row justify='center' style={{ margin: '20px 0px' }}>
                  <Typography.Title underline level={5}>
                    Money Receipt (Agent Copy)
                  </Typography.Title>
                </Row>
                <Row style={{ marginBottom: '.5rem' }}>
                  <Typography.Text>
                    Received with thanks from Mrs./Ms/Mr
                  </Typography.Text>
                  <Typography.Text
                    strong
                    underline
                    style={{ marginLeft: '.3rem' }}
                  >
                    {singleMoneyReceipt?.data?.agent_name}
                  </Typography.Text>
                </Row>
                <Row gutter={[6, 0]}>
                  <Col lg={24}>
                    <Descriptions size='small'>
                      <Descriptions.Item label='Payment Type'>
                        <strong>
                          {' '}
                          {singleMoneyReceipt?.data?.acctype_name}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Total Received Amount'>
                        <strong>
                          {' '}
                          {singleMoneyReceipt?.data?.receipt_total_amount}
                        </strong>
                      </Descriptions.Item>

                      <Descriptions.Item>
                        <strong>
                          {receipt_total_amount ? (
                            <NumToWord number={Number(receipt_total_amount)} />
                          ) : (
                            ''
                          )}
                        </strong>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography.Text strong>Note :</Typography.Text>
                  </Col>
                </Row>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                  style={{ marginTop: '5rem' }}
                  justify='space-between'
                >
                  <Col span={6} xs={8} sm={8} md={8} lg={5} offset={1}>
                    <div
                      style={{
                        background: 'black',
                        margin: '0px',
                        width: '127px',
                        height: '1px',
                      }}
                    />
                    <Typography.Text strong>Customer Signature</Typography.Text>
                  </Col>
                  <Col span={6} xs={8} sm={8} md={8} lg={5}>
                    <div
                      style={{
                        background: 'black',
                        margin: '0px',
                        width: '113px',
                        height: '1px',
                      }}
                    />
                    <Typography.Text strong>Cashier Signature</Typography.Text>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  margin: '25px 0px',
                  borderTop: '1px dashed black',
                  padding: '45px 0px',
                }}
              >
                <Row justify='space-between'>
                  <ReceiptHeader />
                  <Typography.Text strong className='mt-5'>
                    Money Receipt No :
                    {singleMoneyReceipt?.data?.receipt_money_receipt_no}
                  </Typography.Text>
                </Row>

                <Row justify='center' style={{ margin: '20px 0px' }}>
                  <Typography.Title underline level={5}>
                    Money Receipt (Office Copy)
                  </Typography.Title>
                </Row>

                <Row style={{ marginBottom: '.5rem' }}>
                  <Typography.Text>
                    Received with thanks from Mrs./Ms/Mr
                  </Typography.Text>
                  <Typography.Text
                    strong
                    underline
                    style={{ marginLeft: '.3rem' }}
                  >
                    {singleMoneyReceipt?.data?.agent_name}
                  </Typography.Text>
                </Row>

                <Row gutter={[10, 0]}>
                  <Col lg={24}>
                    <Descriptions size='small'>
                      <Descriptions.Item label='Payment Type'>
                        <strong>
                          {' '}
                          {singleMoneyReceipt?.data?.acctype_name}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item label='Total Received Amount'>
                        <strong>
                          {' '}
                          {singleMoneyReceipt?.data?.receipt_total_amount}
                        </strong>
                      </Descriptions.Item>

                      <Descriptions.Item>
                        <strong>
                          {receipt_total_amount ? (
                            <NumToWord
                              number={Number(receipt_total_amount || 0)}
                            />
                          ) : (
                            ''
                          )}
                        </strong>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography.Text strong>Note :</Typography.Text>
                    {/* <Typography.Text underline>
                        {singleMoneyReceipt?.data?.receipt_note}
                      </Typography.Text> */}
                  </Col>
                </Row>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                  style={{ marginTop: '5rem' }}
                  justify='space-between'
                >
                  <Col span={6} xs={8} sm={8} md={8} lg={5} offset={1}>
                    <div
                      style={{
                        background: 'black',
                        margin: '0px',
                        width: '127px',
                        height: '1px',
                      }}
                    />
                    <Typography.Text strong>Customer Signature</Typography.Text>
                  </Col>

                  <Col span={6} xs={8} sm={8} md={8} lg={5}>
                    <div
                      style={{
                        background: 'black',
                        margin: '0px',
                        width: '113px',
                        height: '1px',
                      }}
                    />
                    <Typography.Text strong>Cashier Signature</Typography.Text>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default Agent_Money_Receipt_View;
