import { Col, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../../common/utils/ToasterNotification';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { setAccountModal } from '../../../AccountsState/accountSlice';
import {
  IAccountOpeningBalanceFormData,
  IaccountsByMethod,
} from '../../../AccountsTypes/AccountsTypes';
import { useCreateAccountsOpeningBalanceMutation } from '../../../Api/Endpoints/OpeningBalanceEndponts';
import { useLazyGetAllAccountsByTypeQuery } from '../../../Api/Endpoints/accountsEndpoints';

type Props = { setOpen: React.Dispatch<React.SetStateAction<boolean>> };

const Accounts_Opening_Balance_Add_Modal = ({ setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const isChanged = useRef(false);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });

      return;
    }
  }, [paymentMethod]);

  const [addAccountOpeningBalance, { isError, isLoading, isSuccess }] =
    useCreateAccountsOpeningBalanceMutation();

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    } else if (isError) {
      toasterNotification('error', 'Something went wrong');
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  const handleSubmit = async (values: IAccountOpeningBalanceFormData) => {
    const body: IAccountOpeningBalanceFormData = {
      ...values,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      account_created_by: user?.user_id as number,
      amount: values.amount ? values.amount : 0,
    };
    await addAccountOpeningBalance(body);
    form.resetFields();
    dispatch(setAccountModal(false));
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout='vertical'>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <SelectPaymentMethod
          name='type_id'
          label='Payment Method'
          size={12}
          onChange={(value: string) => {
            setPaymentMethod(value);
            if (!isChanged.current) isChanged.current = true;
          }}
        />
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name='account_id'
            label='Account'
            rules={[
              {
                required: true,
                message: `Account is required!`,
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder={'Select Account'}
              optionFilterProp='children'
            >
              {accountsByPayment?.data?.map(
                (item: IaccountsByMethod, index) => {
                  return (
                    <Select.Option key={index} value={item.account_id}>
                      {`${item.account_name}`}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item label='Payment Type' name='transaction_type'>
            <Select
              options={[
                { value: 'DEBIT', label: 'DEBIT' },
                { value: 'CREDIT', label: 'CREDIT' },
              ]}
              placeholder='Select Payment Type'
            />
          </Form.Item>
        </Col>
        <NumberInput
          name='amount'
          label='Amount'
          size={12}
          min='0'
          maxChar={14}
        />

        <DateInput name='date' label='Date' size={12} />

        <TextAreaInput name='note' label='Note' size={24} />

        <FormButton label='Add Accounts Bill Adjustment' loading={isLoading} />
      </Row>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default Accounts_Opening_Balance_Add_Modal;
