import { Card, List } from 'antd';
import { useAppSelector } from '../../../app/hooks';
import { Fixed2 } from '../../../common/utils/common.utils';
import { useMonthReportQuery } from '../Api/Endpoints/DashboardEndpoints';
import DashboardTitle from './DashboardTitle';
import { Link } from 'react-router-dom';

const dayjs = require('dayjs');
// Get the current date
const currentDate = dayjs();
// Subtract one day from the current date to get to the last day of the current month
const lastDayOfCurrentMonth = currentDate.subtract(1, 'day');
// Get the first day of the current month
const firstDayOfCurrentMonth = lastDayOfCurrentMonth.startOf('month');
const getInvoiceDetails = (item: string) => {
  if (item === 'AIR TICKET') {
    return `/reports/air-ticket-summary?from_date=${dayjs(
      firstDayOfCurrentMonth
    ).format('YYYY-MM-DD')}&to_date=${dayjs().format('YYYY-MM-DD')}`;
  } else if (item === 'OTHERS') {
    return '/invoiceother';
  } else if (item === 'VISA') {
    return '/visa';
  } else if (item === 'TOUR PACKAGE') {
    return '/tour-invoice/all';
  } else if (item === 'HAJJ & PRE REG') {
    return '/hajjpreregistration';
  } else if (item === 'RECEIVED') {
    return '/moneyreceipt';
  } else if (item === 'PAYMENTS') {
    return '/vendors/payment';
  }
};
export type IDarkMode = {
  isDarkMode: boolean;
};

const InvoiceDetails = ({ isDarkMode }: IDarkMode) => {
  const { data, isLoading } = useMonthReportQuery();

  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );

  const currentMonth = new Date().getMonth();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle title='INVOICE DETAILS' />
      <div
        className='custom-scrollbar'
        style={{
          height: 290,
          overflow: 'auto',
          padding: '5px 20px',
        }}
      >
        <List
          itemLayout='horizontal'
          dataSource={data?.data}
          renderItem={(item) => (
            <List.Item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                padding: '10px',
              }}
            >
              <List.Item.Meta
                title={
                  <Link
                    to={getInvoiceDetails(item.key_name) || ''}
                    style={{ color: '#1F8BCB' }}
                  >
                    {item.key_name}
                  </Link>
                }
                description={<span>{monthNames[currentMonth]}</span>}
              />
              <div style={{ color: '#6c757d', flex: 0.8 }}>
                <div>
                  Sales: {Fixed2(item.amount).toLocaleString()}{' '}
                  {currency?.toUpperCase()}
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default InvoiceDetails;
