import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../auth/states/userSlice';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormMobileNumberItem,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import CountryName from '../../../helpers/CountryName';
import { useGetAllProductsQuery } from '../../Configuration/Products/endpoints/productsEndpoints';
import { ProductDataType } from '../../Configuration/Products/types/productTypes';
import { useAddVendorMutation } from '../api/endpoints/vendorEndpoints';
import { useGetAccountByTypeQuery } from '../api/endpoints/vendorPaymentEndpoints';
import VendorProducts from '../components/common/VendorProducts';
import { IVendorFormData } from '../types/vendor.interfaces';

type Props = {};

const AddVendor = (props: Props) => {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);

  const isIATA =
    useAppSelector((state) => state.configSlice.tac_airticket_type) === 'IATA';

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );

  const navigate = useNavigate();
  const vendor_opening_balance_pay_type: 'Due' | 'Advance' = useWatch(
    ['vendor_opening_balance_pay_type'],
    form
  );

  const [option, setOption] = useState('');
  const creditLimit = (value: string) => {
    setOption(value);
  };

  const user = useSelector(selectUser);

  const [selectProducts, setSelectProducts] = useState<number[]>([]);
  const { data: products } = useGetAllProductsQuery();

  const [addVendor, { isSuccess, isError, isLoading }] = useAddVendorMutation();
  // hendle add vendor--------------
  const vendorStartDate = useWatch('vendor_start_date', form);

  useEffect(() => {
    if (vendorStartDate) {
      form.setFieldValue(
        'vendor_end_date',
        dayjs(vendorStartDate).add(1, 'year')
      );
    }
  }, [vendorStartDate]);
  const handleAddVendor = async (values: IVendorFormData) => {
    if (selectProducts.length) {
      values.vendor_registration_date = dayjs(
        values.vendor_registration_date
      ).format('YYYY-MM-DD');
      values.vendor_created_by = user?.user_id as number;
      values.vendor_products_id = selectProducts;

      if (values.vendor_start_date) {
        values.vendor_start_date = dayjs(values.vendor_start_date).format(
          'YYYY-MM-DD'
        );
        values.vendor_end_date = dayjs(values.vendor_end_date).format(
          'YYYY-MM-DD'
        );
      }
      addVendor(values);
    } else message.error('Products is required!');
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Vendor has been added!');
      navigate('/vendors');
    }
    if (isError) {
      message.error('Vendor can not added!');
    }
  }, [isLoading]);

  // set form value
  useEffect(() => {
    form.setFieldsValue({
      vendor_registration_date: dayjs(),
      dailCode: CountryName[19].dialCode,
    });
  }, []);

  return (
    <>
      <BreadCrumb arrOfOption={['Vendors', 'Add New Vendor']} />
      <Col lg={8} style={{ marginBottom: '1rem' }}>
        <Link to='/vendors'>
          <Button type='primary' icon={<ArrowLeftOutlined />}>
            Return to Vendor List
          </Button>
        </Link>
      </Col>
      <div className='p-5 '>
        <Form form={form} layout='vertical' onFinish={handleAddVendor}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <FormInputItem name='vendor_name' label='Name' required size={6} />
            <FormInputItem
              name='vendor_email'
              label='Email'
              size={6}
              inputType='email'
            />
            <FormMobileNumberItem size={6} />
            <NumberInput
              label='Fixed advance:'
              name='vendor_fixed_advance'
              size={6}
              min='0'
              maxChar={14}
              minChar={0}
            />
            <DateInput
              name={'vendor_registration_date'}
              label='Date: '
              required
              size={6}
            />
            {/* Add Opening Balance */}
            <SelectOptionsInput
              name={'vendor_opening_balance_pay_type'}
              label='Opening Balance Type: '
              options={['Due', 'Advance']}
              size={6}
            />
            {
              <NumberInput
                name={'vendor_opening_balance'}
                label='Opening Balance : '
                size={6}
              />
            }

            {/* {vendor_opening_balance_pay_type === 'Advance' && (
              <>
                <SelectPaymentMethod
                  size={3}
                  required
                  name='opening_payment_type'
                  label='Payment Method'
                  onChange={(value: number) => {
                    setPayMethodCatId(value);
                  }}
                />
                <AccountLastBalanceInput
                  size={3}
                  required
                  accountsLastBalance={accounts ? accounts.data : []}
                  name='opening_account_id'
                  label='Account:'
                />
              </>
            )} */}

            <FormInputItem label='Address' name='vendor_address' size={6} />
            {/* Add Vendor Credit Limit */}

            <NumberInput
              name={'vendor_credit_limit'}
              label='Credit Limit : '
              placeholder={'Credit Limit'}
              size={6}
            />

            {isIATA && (
              <NumberInput
                name={'vendor_bank_guarantee'}
                label='Vendor Bank Guarantee'
                placeholder={'Bank Guarantee'}
                size={6}
              />
            )}

            {isIATA && (
              <DateInput
                name={'vendor_start_date'}
                label='Vendor Start Date'
                size={6}
              />
            )}
            {isIATA && (
              <DateInput
                name={'vendor_end_date'}
                label='Vendor End Date'
                size={6}
              />
            )}
            <VendorProducts
              products={products?.data as ProductDataType[]}
              setSelectProducts={(value) => setSelectProducts(value)}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          </Row>
          <FormButton label='Add vendor' loading={isLoading} />
        </Form>

        {isLoading && <LoadingIndicator />}
      </div>
    </>
  );
};

export default AddVendor;
