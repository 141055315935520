import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { getTableColumnsWithoutNull } from '../../../../common/utils/common.utils';
import { generatePagination } from '../../../../common/utils/generatePagination';
import { useDashboardAccDetailsQuery } from '../../Api/Endpoints/DashboardEndpoints';
import { IDashboardAccDetails } from '../../interfaces/dashboard.interfaces';

const AccountHistoryDetails = ({ modalTitle }: { modalTitle: string }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const typeNum = modalTitle === 'Cash' ? 1 : modalTitle === 'Bank' ? 2 : 3;
  const { data } = useDashboardAccDetailsQuery({
    accType: typeNum,
    pagination,
  });

  // TABLE ROW
  const columns: ColumnsType<IDashboardAccDetails> = [
    {
      title: 'SL',
      dataIndex: 'SL',
      key: 'SL',
      align: 'center',
      render: (_, value, index) => (pagination.current - 1) * 20 + (index + 1),
    },

    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },

    {
      title: 'Bank Name',
      dataIndex: 'account_bank_name',
      key: 'account_bank_name',
    },
    {
      title: 'Account No.',
      dataIndex: 'account_number',
      key: 'account_number',
    },

    {
      title: 'Total Payment',
      dataIndex: 'total_payment',
      key: 'total_payment',
    },
    {
      title: 'Total Expense',
      dataIndex: 'total_expense',
      key: 'total_expense',
    },
    {
      title: 'Total Payroll',
      dataIndex: 'total_payroll',
      key: 'total_payroll',
    },
    {
      title: 'Total Received',
      dataIndex: 'total_received',
      key: 'total_received',
    },
    {
      title: 'Balance',
      dataIndex: 'accbalance_amount',
      key: 'accbalance_amount',
      align: 'center',
      render: (_, record) => Number(record.account_lbalance),
    },
  ];

  const filteredColumns = getTableColumnsWithoutNull(data?.data, columns);

  return (
    <>
      <Table
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        size='small'
        bordered
        pagination={generatePagination(data?.count, setPagination, pagination)}
        columns={filteredColumns}
        dataSource={data?.data}
        scroll={{ x: true }}
      ></Table>
    </>
  );
};

export default AccountHistoryDetails;
