import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { message } from 'antd';
import { Mutex } from 'async-mutex';
import { getSessionId, reqWithAuth, setSessionId, setToken } from '..';
import { IRcmType, TUser, logout } from '../auth/states/userSlice';
import { HTTPResponse } from '../common/types/commonTypes';
import { expire } from '../common/utils/constants';
import { accountsTags } from '../modules/Accounts/Api/Constants/constants';
import { airtickethalfrefundTags } from '../modules/AirTicket_Half_Refund/api/constants/Constants';
import { chequeTags } from '../modules/Cheque_Management/api/Constants/constants';
import { clientTags } from '../modules/Client/Client/api/constants/constants';
import { configurationTags } from '../modules/Configuration/api/constants/constants';
import { expenseTags } from '../modules/Expense/Api/Constants/Constants';
import { invoiceHajjTags } from '../modules/Hajj/Invoice_Hajj/api/constants/constants';
import { hajjiManagementTags } from '../modules/Hajji Management/API/Constants/constants';
import { InvoiceOtherTags } from '../modules/Invoice(Other)/Api/Constants/Constants';
import { invoiceVisaTags } from '../modules/Invoice(Visa)/Api/Constants/constants';
import { invoiceUmrahTags } from '../modules/InvoiceUmrah/api/constants/constants';
import { invoiceAirTicketTags } from '../modules/Invoice_Air_Ticket/api/constants/constants';
import { invoiceHajjiPreRegTag } from '../modules/Invoice_Hajj_Pre_Reg/API/Constants/constants';
import { invoiceNonComissionTags } from '../modules/Invoice_Non_Comission/api/constants/constants';
import { invoiceReIssueTags } from '../modules/Invoice_Re_Issue/api/constants/constants';
import { loanTags } from '../modules/Loan_Management/Api/Constants/constants';
import { agentMoneyReceiptTags } from '../modules/Money_Receipt/Agent_Money_Receipt/api/constants/constants';
import { passportTags } from '../modules/Passport_MGT/Api/Constants/Constants';
import { payrollTags } from '../modules/Payroll/api/constants/constants';
import { quotationTags } from '../modules/Quotation/Api/Constants/Constants';
import { refundTags } from '../modules/Refund/Api/Constants/Contstants';
import { reportsTags } from '../modules/Reports/Api/constants';
import { smsTags } from '../modules/SMS System/Api/Constants/Constants';
import { vendorTags } from '../modules/vendor/api/constants/constants';

const mutex = new Mutex();

type Res = {
  success: true;
  token: { accessToken: string; refreshToken: string };
  user: TUser;
  session_id: string;
};

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const tokenRoutes = ['/auth/login', '/auth/startup/token'];
  const rawUrl = typeof args !== 'string' ? args.url : args;

  const url = new URL(rawUrl, process.env.REACT_APP_BASE_URL);

  if (tokenRoutes.includes(url.pathname)) {
    await mutex.waitForUnlock();

    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const { data, error, meta } = await reqWithAuth({ args, api });

      if (!error && data) {
        const { session_id, token, user } = data as Res;
        const { accessToken, refreshToken } = token;

        const tokenCreds = {
          accessToken,
          refreshToken,
        };

        setToken(tokenCreds);

        setSessionId(session_id);

        release();

        return { data: user, error, meta };
      } else {
        api.dispatch(logout());
        release();
      }
    }

    const error: FetchBaseQueryError = {
      error:
        'Cannot Send any request for token while there is one request on the flight',
      status: 'CUSTOM_ERROR',
    };

    return { error };
  }

  await mutex.waitForUnlock();

  let result = await reqWithAuth({
    api,
    args,
    extraOptions,
    authorization: 'access_token',
    customHeader: {
      session_id: getSessionId(),
    },
  });

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const request = {
        url: `/auth/refresh/token?session_id=${getSessionId()}`,
        method: 'POST',
      };

      const { data, error } = await reqWithAuth({
        api,
        authorization: 'refresh_token',
        extraOptions,
        args: request,
      });

      if (!error && data) {
        const { session_id, token } = data as Omit<Res, 'user'>;
        const { accessToken, refreshToken } = token;

        const tokenCreds = {
          accessToken,
          refreshToken,
        };

        setToken(tokenCreds);
        setSessionId(session_id);

        result = await reqWithAuth({
          api,
          args,
          extraOptions,
          authorization: 'access_token',
          customHeader: {
            session_id: getSessionId(),
          },
        });
      } else {
        api.dispatch(logout());
      }

      release();
    } else {
      await mutex.waitForUnlock();

      result = await reqWithAuth({
        api,
        args,
        extraOptions,
        authorization: 'access_token',
        customHeader: {
          session_id: getSessionId(),
        },
      });
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: expire.default,
  reducerPath: 'AccountsApi',
  endpoints: (build) => ({
    getInitialToken: build.mutation<TUser, string>({
      query: (session_id) => ({
        url: `/auth/startup/token?session_id=${session_id}`,
        method: 'POST',
      }),
    }),

    getOTP: build.query<HTTPResponse<TUser>, string>({
      query: (email) => ({
        url: `/auth/forgot-password?email=${email}`,
      }),
    }),

    verifyOTP: build.mutation<
      HTTPResponse<TUser>,
      {
        body: { otp?: string; email: string; password?: string };
      }
    >({
      query: ({ body }) => ({
        url: `/auth/forgot-password?email=${body.email}`,
        method: 'PUT',
        body: body,
      }),
    }),

    changePassword: build.mutation<
      HTTPResponse<TUser>,
      {
        body: { otp?: string; email: string; password?: string };
      }
    >({
      query: ({ body }) => ({
        url: `/auth/forgot-password?email=${body.email}`,
        method: 'PUT',
        body: body,
      }),
    }),

    login: build.mutation<TUser, { username: string; password: string }>({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('User name or password is incorrect!');
        }
      },
    }),

    recruitmentToken: build.query<HTTPResponse<IRcmType>, void>({
      query: () => ({
        url: `admin-panel/recruit-token`,
        method: 'GET',
      }),
    }),
  }),

  tagTypes: [
    ...accountsTags,
    ...chequeTags,
    ...clientTags,
    ...configurationTags,
    ...invoiceAirTicketTags,
    ...invoiceNonComissionTags,
    ...invoiceVisaTags,
    ...invoiceReIssueTags,
    ...loanTags,
    ...vendorTags,
    ...InvoiceOtherTags,
    ...quotationTags,
    ...passportTags,
    ...invoiceHajjiPreRegTag,
    ...hajjiManagementTags,
    ...invoiceHajjTags,
    ...invoiceUmrahTags,
    ...expenseTags,
    ...smsTags,
    ...refundTags,
    ...reportsTags,
    ...payrollTags,
    ...airtickethalfrefundTags,
    ...agentMoneyReceiptTags,
  ],
});

export const {
  useGetInitialTokenMutation,
  useLoginMutation,
  useLazyGetOTPQuery,
  useVerifyOTPMutation,
  useChangePasswordMutation,
  useLazyRecruitmentTokenQuery,
} = api;
