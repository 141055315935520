import 'antd/dist/reset.css';
import { Navigate, Route } from 'react-router';
import { BrowserRouter as Router, Routes } from 'react-router-dom';

import PermissionRoute from '../../auth/components/PermissionRoute';
import Login from '../../auth/pages/Login';
import NotFound from '../../auth/pages/NotFound';
import { crud, rolePermissionType } from '../../common/types/commonTypes';
import { getListPermission } from '../../common/utils/common.utils';
import {
  CollectionPendingCheque,
  PaymentPendingCheque,
} from '../../components/notificatioin/Pages/CollectionPendingChque';
import DueInvoice from '../../components/notificatioin/Pages/DueInvoice';
import ExpirePassports from '../../components/notificatioin/Pages/ExpirePassports';
import VisaDelivery from '../../components/notificatioin/Pages/VisaDelivery';
import LayoutContainer from '../../layout/pages/LayoutContainer';
import Cheque_Management from '../../modules/Cheque_Management/pages/Cheque_Management';
import AddAgentProfile from '../../modules/Client/Agents_Profile/Pages/AddAgentProfile';
import AgentsProfile from '../../modules/Client/Agents_Profile/Pages/AgentsProfile';
import EditAgentProfile from '../../modules/Client/Agents_Profile/Pages/EditAgentProfile';
import ClientDetails from '../../modules/Client/Client/components/ClientDetails/ClientDetails';
import ClientEdit from '../../modules/Client/Client/pages/ClientEdit';
import Clients from '../../modules/Client/Client/pages/ClientsList';
import CreateClient from '../../modules/Client/Client/pages/CreateClient';
import CombineClientDetails from '../../modules/Client/combined_clients/components/CombineClientsDetails/CombineClientDetails';
import CombineClientHome from '../../modules/Client/combined_clients/pages/CombineClientHome';
import NewClientCombine from '../../modules/Client/combined_clients/pages/NewClientCombine';
import Db_backup from '../../modules/DB_Backup/Pages/Db_backup';
import AddExpense from '../../modules/Expense/Pages/AddExpense';
import EditExpense from '../../modules/Expense/Pages/EditExpense';
import ExpenseHistory from '../../modules/Expense/Pages/ExpenseHistory';
import ViewExpense from '../../modules/Expense/Pages/ViewExpense';
import Feedback from '../../modules/Feedback/pages/Feedback';

import ListAuthority from '../../modules/Loan_Management/Pages/Authority/ListAuthority';
import LoanList from '../../modules/Loan_Management/Pages/Loan/LoanList';
import ViewLoan from '../../modules/Loan_Management/Pages/Loan/ViewLoan';
import PaymentList from '../../modules/Loan_Management/Pages/Payment/PaymentList';
import ViewPayment from '../../modules/Loan_Management/Pages/Payment/ViewPayment';
import ReceiveList from '../../modules/Loan_Management/Pages/Receive/ReceiveList';
import ViewReceive from '../../modules/Loan_Management/Pages/Receive/ViewReceive';
import Agent_Money_Receipt from '../../modules/Money_Receipt/Agent_Money_Receipt/pages/Agent_Money_Receipt';
import Agent_Money_Receipt_View from '../../modules/Money_Receipt/Agent_Money_Receipt/pages/Agent_Money_Receipt_View';
import ListofAgentMoneyReceipt from '../../modules/Money_Receipt/Agent_Money_Receipt/pages/ListofAgentMoneyReceipt';
import AddAdvanceReturnMoneyReceipt from '../../modules/Money_Receipt/Pages/AdvanceReturn/AddAdvanceReturnMoneyReceipt';
import MoneyReceiptAdvance from '../../modules/Money_Receipt/Pages/AdvanceReturn/AdvanceReturnMoneyReceipt';
import EditAdvanceReturnMR from '../../modules/Money_Receipt/Pages/AdvanceReturn/EditAdvanceReturnMR';
import ListofMoneyReceipt from '../../modules/Money_Receipt/Pages/MoneyReceipt/ListofMoneyReceipt';

import Expire from '../../auth/pages/Expire';
import { TState, moduleType } from '../../auth/states/userSlice';
import Money_Receipt_View from '../../modules/Money_Receipt/Pages/MoneyReceipt/Money_Receipt_View';
import New_Money_Receipt from '../../modules/Money_Receipt/Pages/MoneyReceipt/New_Money_Receipt';
import AddPassport from '../../modules/Passport_MGT/Pages/AddPassport';
import All_Passport from '../../modules/Passport_MGT/Pages/All_Passport';
import EditPassport from '../../modules/Passport_MGT/Pages/EditPassport';
import Payroll from '../../modules/Payroll/Pages/Payroll';
import PayrollView from '../../modules/Payroll/Pages/PayrollView';
import AddEmployeeModalContent from '../../modules/Payroll/components/AddPayrollContent';
import NewQuotation from '../../modules/Quotation/Pages/NewQuotation';
import NewViewQuotationDetails from '../../modules/Quotation/Pages/NewViewQuotationDetails';
import QuotationEdit from '../../modules/Quotation/Pages/QuotationEdit';
import ViewQuotation from '../../modules/Quotation/Pages/QuotationList';

import SMSLog from '../../modules/SMS System/Pages/SMSLog';
import SendSMS from '../../modules/SMS System/Pages/SendSMS';

import Journey_Wise_Notification from '../../components/notificatioin/Pages/Journey_Wise_Notification';
import AdvanceMoneyReturnView from '../../modules/Money_Receipt/Pages/AdvanceReturn/AdvanceMoneyReturnView';
import { AccountRoutes } from './Account.routes';
import { ConfigurationRoutes } from './Configuration.routes';
import { getDashboardRoute } from './Dashboard.routes';
import { InvoicesRoutes } from './Invoices.routes';
import { RefundRoutes } from './Refund.routes';
import { ReportRoutes } from './Report.routes';
import { VendorRoutes } from './Vendor.routes';
import PayrollAdvance from '../../modules/Payroll/Pages/PayrollAdvance';
import PayrollAdvanceView from '../../modules/Payroll/Pages/PayrollAdvanceView';

type Props = {
  user: TState;
  permission: rolePermissionType | undefined;
};

export const CustomRoutes = ({ user, permission }: Props) => {
  const MODULE_TYPE = user?.organization_info?.org_module_type || 'TRABILL';
  const isAdmin = user?.user_role !== 'EMPLOYEE' ? ['*'] : undefined;

  const hasPermission = (module: moduleType) => {
    return user?.modules?.includes(module);
  };

  // EXPIRED OR DEACTIVATE USER
  if (user && user?.user_role === 'DEACTIVATE') {
    return <Expire />;
  }

  return (
    <Router>
      <Routes>
        <Route path='/login' element={user ? <Navigate to='/' /> : <Login />} />

        <Route element={<LayoutContainer />}>
          {getDashboardRoute(permission, user)}
          {ReportRoutes(permission, user)}
          {ConfigurationRoutes(permission, isAdmin)}
          {VendorRoutes(permission, hasPermission)}
          {InvoicesRoutes(permission, hasPermission)}
          {RefundRoutes(permission, hasPermission)}
          {AccountRoutes(permission, hasPermission)}

          <>
            <Route
              path='/cheque/moneyreceipt'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<PaymentPendingCheque />}
                />
              }
            />
            <Route
              path='/cheque/moneyreceipt-collection'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<CollectionPendingCheque />}
                />
              }
            />

            <Route
              path='/expire-passport'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<ExpirePassports />}
                />
              }
            />
            <Route
              path='/due-invoice'
              element={
                <PermissionRoute permission={['*']} element={<DueInvoice />} />
              }
            />

            <Route
              path='/journey-notification'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<Journey_Wise_Notification />}
                />
              }
            />
            <Route
              path='/visa-delivery'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<VisaDelivery visaType='Delivery' />}
                />
              }
            />
            <Route
              path='/visa-pending'
              element={
                <PermissionRoute
                  permission={['*']}
                  element={<VisaDelivery visaType='Pending' />}
                />
              }
            />
          </>

          {/* QUOTATION */}
          <>
            <Route
              path='/quotation/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('quotation') &&
                    permission?.quotation?.[crud.create]
                  }
                  element={<NewQuotation />}
                />
              }
            />
            <Route
              path='/quotation'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('quotation') &&
                    permission?.quotation?.[crud.read]
                  }
                  element={<ViewQuotation permission={permission?.quotation} />}
                />
              }
            />
            <Route
              path='/quotation/details/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('quotation') &&
                    permission?.quotation?.[crud.read]
                  }
                  element={<NewViewQuotationDetails />}
                />
              }
            />
            <Route
              path='/quotation/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('quotation') &&
                    permission?.quotation?.[crud.update]
                  }
                  element={<QuotationEdit />}
                />
              }
            />
          </>

          {/* EXPENSE */}
          <>
            <Route
              path='/expense/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') &&
                    permission?.expense?.[crud.create]
                  }
                  element={<AddExpense permission={permission?.expense} />}
                />
              }
            />
            <Route
              path='/expense'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') && permission?.expense?.[crud.read]
                  }
                  element={<ExpenseHistory permission={permission?.expense} />}
                />
              }
            />
            <Route
              path='/expense/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') && permission?.expense?.[crud.read]
                  }
                  element={<ViewExpense />}
                />
              }
            />
            <Route
              path='/expense/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('expense') &&
                    permission?.expense?.[crud.update]
                  }
                  element={<EditExpense />}
                />
              }
            />
          </>

          {/* SMS SYSTEM MODULES */}
          <>
            <Route
              path='/sms'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('sms_system') &&
                    permission?.sms_system?.[crud.create]
                  }
                  element={<SendSMS permission={permission?.sms_system} />}
                />
              }
            />

            <Route
              path='/sms/sms_log'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('sms_system') &&
                    permission?.sms_system?.['read:any']
                  }
                  element={<SMSLog permission={permission?.sms_system} />}
                />
              }
            />
          </>

          {/* CHEQUE MANAGEMENT */}
          <>
            <Route
              path='/cheques'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('cheque_management') &&
                    permission?.cheque_management?.[crud.read]
                  }
                  element={<Cheque_Management />}
                />
              }
            />
          </>

          {/* MONEY RECIPET */}
          <>
            <Route
              path='/moneyreceipt/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.create]
                  }
                  element={<New_Money_Receipt reason='ADD_NEW' />}
                />
              }
            />
            <Route
              path='/agent_money_receipt/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.create]
                  }
                  element={<Agent_Money_Receipt />}
                />
              }
            />
            <Route
              path='/moneyreceipt'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    getListPermission(permission?.money_receipt)
                  }
                  element={
                    <ListofMoneyReceipt
                      permission={permission?.money_receipt}
                    />
                  }
                />
              }
            />
            <Route
              path='/moneyreceipt/edit/:receipt_id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.update]
                  }
                  element={<New_Money_Receipt reason='EDIT' />}
                />
              }
            />
            <Route
              path='/moneyreceipt/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.read]
                  }
                  element={<Money_Receipt_View />}
                />
              }
            />
            <Route
              path='/money-receipt/agents-commission-by-recept-id/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt?.[crud.read]
                  }
                  element={<Agent_Money_Receipt_View />}
                />
              }
            />
            <Route
              path='/moneyreceipt/advance'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    getListPermission(permission?.money_receipt_advr)
                  }
                  element={
                    <MoneyReceiptAdvance
                      permission={permission?.money_receipt_advr}
                    />
                  }
                />
              }
            />

            <Route
              path='/moneyreceipt/advance/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    getListPermission(permission?.money_receipt_advr)
                  }
                  element={<AdvanceMoneyReturnView />}
                />
              }
            />
            <Route
              path='/moneyreceipt/advance/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt_advr?.[crud.create]
                  }
                  element={<AddAdvanceReturnMoneyReceipt />}
                />
              }
            />

            <Route
              path='/moneyreceipt/advance/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('money_receipt') &&
                    permission?.money_receipt_advr?.[crud.update]
                  }
                  element={<EditAdvanceReturnMR />}
                />
              }
            />
            <Route
              path='/moneyreceipt/agent'
              element={
                <PermissionRoute
                  permission={
                    (MODULE_TYPE === 'REC' || hasPermission('money_receipt')) &&
                    getListPermission(permission?.money_receipt)
                  }
                  element={
                    <ListofAgentMoneyReceipt
                      permission={permission?.money_receipt}
                    />
                  }
                />
              }
            />
          </>

          {/* CLIENTS */}
          <>
            <Route
              path='/clients'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') &&
                    getListPermission(permission?.clients)
                  }
                  element={<Clients permission={permission?.clients} />}
                />
              }
            />
            <Route
              path='/clients/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') &&
                    permission?.clients?.[crud.create]
                  }
                  element={<CreateClient />}
                />
              }
            />
            <Route
              path='/clients/details/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') && permission?.clients?.[crud.read]
                  }
                  element={<ClientDetails />}
                />
              }
            />

            <Route
              path='/clients/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('clients') &&
                    permission?.clients?.[crud.update]
                  }
                  element={<ClientEdit />}
                />
              }
            />
            {/* agents */}
            <Route
              path='/agents/profile'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('agents') &&
                    getListPermission(permission?.agents)
                  }
                  element={<AgentsProfile permission={permission?.agents} />}
                />
              }
            />

            <Route
              path='/agents/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('agents') &&
                    getListPermission(permission?.agents)
                  }
                  element={<AddAgentProfile permission={permission?.agents} />}
                />
              }
            />

            <Route
              path='/agents/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('agents') &&
                    getListPermission(permission?.agents)
                  }
                  element={<EditAgentProfile permission={permission?.agents} />}
                />
              }
            />
          </>

          {/* COMBINE CLIENTS */}
          <>
            <Route
              path='/combineds'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('combined_clients') &&
                    getListPermission(permission?.combined_clients)
                  }
                  element={
                    <CombineClientHome
                      permission={permission?.combined_clients}
                    />
                  }
                />
              }
            />
            <Route
              path='/combine_client/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('combined_clients') &&
                    permission?.combined_clients?.[crud.create]
                  }
                  element={<NewClientCombine reason='ADD' />}
                />
              }
            />
            <Route
              path='/combineClients/details/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('combined_clients') &&
                    permission?.combined_clients?.[crud.read]
                  }
                  element={<CombineClientDetails />}
                />
              }
            />
            <Route
              path='/combine_client/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('combined_clients') &&
                    permission?.combined_clients?.[crud.update]
                  }
                  element={<NewClientCombine reason='EDIT' />}
                />
              }
            />
          </>

          {/* LOAN MANAGEMENT */}
          <>
            <Route
              path='/loan/authority'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    getListPermission(permission?.loan_management_authority)
                  }
                  element={
                    <ListAuthority
                      permission={permission?.loan_management_authority}
                    />
                  }
                />
              }
            />

            <Route
              path='/loan'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    getListPermission(permission?.loan_management_loan)
                  }
                  element={
                    <LoanList permission={permission?.loan_management_loan} />
                  }
                />
              }
            />
            <Route
              path='/loan/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    permission?.loan_management_loan?.[crud.read]
                  }
                  element={<ViewLoan />}
                />
              }
            />

            <Route
              path='/loan/payment'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    getListPermission(permission?.loan_management_payment)
                  }
                  element={
                    <PaymentList
                      permission={permission?.loan_management_payment}
                    />
                  }
                />
              }
            />
            <Route
              path='/loan/payment/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    permission?.loan_management_payment?.[crud.read]
                  }
                  element={<ViewPayment />}
                />
              }
            />

            <Route
              path='/loan/receive'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    getListPermission(permission?.loan_management_receive)
                  }
                  element={
                    <ReceiveList
                      permission={permission?.loan_management_receive}
                    />
                  }
                />
              }
            />
            <Route
              path='/loan/receive/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('loan_management_module') &&
                    permission?.loan_management_receive?.[crud.read]
                  }
                  element={<ViewReceive />}
                />
              }
            />
          </>

          <>
            {/* PAYROLL */}
            <Route
              path='/payroll'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('payroll') &&
                    getListPermission(permission?.payroll)
                  }
                  element={<Payroll permission={permission?.payroll} />}
                />
              }
            />
            <Route
              path='/payroll/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('payroll') &&
                    getListPermission(permission?.payroll)
                  }
                  element={
                    <AddEmployeeModalContent
                      editOrAdd={{ reason: 'ADD' }}
                      getForEditData={undefined}
                    />
                  }
                />
              }
            />
            <Route
              path='/payroll/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('payroll') && permission?.payroll?.[crud.read]
                  }
                  element={<PayrollView />}
                />
              }
            />

            <Route
              path='/payroll/advance'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('payroll') &&
                    getListPermission(permission?.payroll)
                  }
                  element={<PayrollAdvance permission={permission?.payroll} />}
                />
              }
            />
            <Route
              path='/payroll/advance/view/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('payroll') &&
                    getListPermission(permission?.payroll)
                  }
                  element={<PayrollAdvanceView />}
                />
              }
            />
          </>

          {/* PASSPORT MANAGEMENT */}
          <>
            <Route
              path='/passport/add'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('passport_management') &&
                    permission?.passport_management?.[crud.create]
                  }
                  element={<AddPassport />}
                />
              }
            />

            <Route
              path='/passport/edit/:id'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('passport_management') &&
                    permission?.passport_management?.[crud.update]
                  }
                  element={<EditPassport />}
                />
              }
            />
            <Route
              path='/passport'
              element={
                <PermissionRoute
                  permission={
                    hasPermission('passport_management') &&
                    getListPermission(permission?.passport_management)
                  }
                  element={
                    <All_Passport
                      permission={permission?.passport_management}
                    />
                  }
                />
              }
            />
          </>

          <Route
            path='/feedback'
            element={
              <PermissionRoute permission={['*']} element={<Feedback />} />
            }
          ></Route>
          <Route
            path='/db-backup'
            element={
              <PermissionRoute
                permission={getListPermission(permission?.database668)}
                element={<Db_backup />}
              />
            }
          ></Route>
        </Route>

        {/* </Route> */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
};
