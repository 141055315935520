import { List, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { Fixed2 } from '../../../../common/utils/common.utils';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import InvoiceTourCostTotal from './InvoiceTourCostTotal';

const billingColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Product',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'Pax Name',
    dataIndex: 'billing_pax_name',
    key: 'billing_pax_name',
    align: 'center',
  },
  {
    title: 'Total Pax',
    dataIndex: 'billing_total_pax',
    key: 'billing_total_pax',
    align: 'right',
  },

  {
    title: 'No. of Room',
    dataIndex: 'billing_numof_room',
    key: 'billing_numof_room',
    width: '100px',
    align: 'right',
  },
  {
    title: 'Total Sales',
    dataIndex: 'billing_total_sales',
    key: 'billing_total_sales',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Total Cost',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Profit',
    dataIndex: 'billing_profit',
    key: 'billing_profit',
    align: 'right',
    render: (value) => (
      <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
        {Number(value || 0)}
      </span>
    ),
  },
  {
    title: 'Billing Date',
    render: (_, data) => (
      <>{dayjs(data?.billing_create_date).format('DD MMM YYYY')}</>
    ),
    key: 'billing_total_sales',
    align: 'right',
  },
];
const tourInvoiceColumn: ColumnsType<any> = [
  {
    title: 'Itinerary',
    dataIndex: 'itinerary_particular',
    key: 'itinerary_particular',
  },
  {
    title: 'Package',
    dataIndex: 'passport_name',
    key: 'passport_name',
  },
  {
    title: 'Description',
    dataIndex: 'transport_cost_price',
    key: 'transport_cost_price',
    align: 'left',
  },
  {
    title: 'Cost Price',
    dataIndex: 'coast_price',
    key: 'vendor_name',
    align: 'right',
    render(value, record, index) {
      return Number(value || 0);
    },
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
  },
];
let billing_column = billingColumn.filter(
  (col: any) =>
    col.dataIndex !== 'billing_cost_price' && col.dataIndex !== 'billing_profit'
);
let table = tourInvoiceColumn.filter(
  (col: any) => col.dataIndex !== 'coast_price'
);

type Props = {
  invoiceInformation: IViewInvoiceDetails;
  offSubCoastSubtotal?: boolean;
  conditionalyRender?: boolean;
};
const ViewInvocieTourDetails = ({
  invoiceInformation,
  offSubCoastSubtotal,
  conditionalyRender,
}: Props) => {
  const {
    tourAccms,
    billing_information,
    tourGuide,
    tourOtherTrans,
    tourTicket,
    tourTransports,
  } = invoiceInformation;

  return (
    <>
      {invoiceInformation?.tourFoods.length ||
      tourTransports.length ||
      tourAccms.length ||
      tourGuide.length ||
      tourTransports.length ||
      tourOtherTrans.length ||
      tourTicket.length ? (
        <>
          <div>
            <Typography.Title level={5}>Food Items : </Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourFoods}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.food_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.food_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Typography.Title level={5}>Accommodation Items :</Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourAccms}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.accm_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.accm_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Typography.Title level={5}>Ticket Items :</Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourTicket}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.ticket_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.ticket_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Typography.Title level={5}>Transport Items :</Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourTransports}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.transport_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.transport_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Typography.Title level={5}>Transport Items :</Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourTransports}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.transport_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.transport_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Typography.Title level={5}>
              Other Transport Items :
            </Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourOtherTrans}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.other_trans_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.other_trans_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>

          <div>
            <Typography.Title level={5}>
              Other Transport Items :
            </Typography.Title>
            <List
              itemLayout='vertical'
              dataSource={invoiceInformation?.tourGuide}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '2px',
                    marginBottom: '10px',
                    padding: '5px',
                    display: 'flex',
                  }}
                >
                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Itinerary:</span>{' '}
                    {item.itinerary_particular}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Description:</span>{' '}
                    {item.guide_description}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'> Cost Price: </span>
                    {Fixed2(item.guide_cost_price)}
                  </Typography.Text>

                  <Typography.Text style={{ flex: 1 }}>
                    <span className='bold'>Vendor: </span> {item.vendor_name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>
        </>
      ) : (
        ''
      )}
      {
        <div className='mt-5'>
          <FormHeaderTitle title='BILLING INFO' />
          <Table
            size='small'
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={billing_information}
            columns={conditionalyRender ? billing_column : billingColumn}
            bordered
            pagination={false}
          />
        </div>
      }
      {!offSubCoastSubtotal && (
        <InvoiceTourCostTotal invoice_info={invoiceInformation} />
      )}
    </>
  );
};

export default ViewInvocieTourDetails;
