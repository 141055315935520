import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { moduleType, selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import {
  Fixed2,
  rangePresets,
  separateClient,
  TableParams,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import Reload from '../../../../components/common/reload/Reload';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useVisaStatusUpdateMutation } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import InvoiceOtherAddMoneyModal from '../../../Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import VisaAssign from '../../../visa_processing/components/VisaAssign';
import {
  useDeleteInvoiceVisaMutation,
  useGetAllInvoiceVisaQuery,
} from '../../Api/Endpoints/invoiceVisaEndpoints';
type Props = {
  permission?: IPermission;
  addMRPermission: ['*'] | undefined;
};

const InvoiceVisaLists = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [query, setQuery] = useState<string>('');
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const [fetchVisaStatus] = useVisaStatusUpdateMutation();

  // LINKS
  const createLink = '/visa/add';
  const viewLink = '/visa/details';
  const editLink = '/visa/edit';

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const {
    data,
    isFetching,
    isLoading,
    refetch: getAllInvoices,
  } = useGetAllInvoiceVisaQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );

  const [
    deleteInvoice,
    { isError, isSuccess, isLoading: deleteLoading, error },
  ] = useDeleteInvoiceVisaMutation();
  const customError: any = error;

  // DELETE HANDLER
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id as number,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Invoice Deleted Successfully!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error(customError?.data?.message);
    }
  }, [isSuccess, isError]);

  const [invoiceId, setInvoiceId] = useState<number>();
  const modules = user?.modules;
  const hasPermission = (module: moduleType) => {
    return modules?.includes(module);
  };
  const columns: ColumnsType<IAllInvoices> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((tableParams?.pagination?.current || 1) - 1) *
            (tableParams?.pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
      fixed: 'left',
      width: 55,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 100,
      align: 'center',
    },
    {
      title: 'Sales Date',
      key: 'invoice_date',
      align: 'center',
      render: (_, data) => (
        <>{moment(data.invoice_date).format('DD MMM YYYY')} </>
      ),
      sorter: (a, b) =>
        moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
      width: 120,
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        return (
          <Link
            to={
              record.invoice_client_id
                ? `/reports/client_ledger?client_id=${record?.invoice_client_id}`
                : `/combineClients/details/${record.invoice_combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.mobile ? ' ' + record.mobile : ''}
            </span>
          </Link>
        );
      },
      width: 150,
    },

    {
      title: 'PP No / Name',
      dataIndex: 'pp_or_name',
      key: 'pp_or_name',
      width: 130,
    },
    {
      title: 'Sales Price',
      dataIndex: 'net_total',
      key: 'net_total',
      align: 'center',
      width: 100,
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
      render: (_, record) => Number(record.net_total),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'center',
      width: 120,
      render(value, record, index) {
        return Fixed2(value);
      },
    },
    {
      title: 'Due Amount',
      align: 'center',
      width: 120,
      render(value, record, index) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {Number(record?.net_total) -
              Number(record?.invclientpayment_amount)}
          </span>
        ) : (
          <Tag color='success'>PAID</Tag>
        );
      },
    },
    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item: any, index: any) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Sales By',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 120,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 180,
      fixed: 'right',
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={viewLink + '/' + data.invoice_id}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}
          {permission?.['update:any'] &&
            !Number(data?.invclientpayment_amount || 0) && (
              <Link
                to={editLink + '/' + data.invoice_id}
                state={location.pathname}
              >
                <Button size='small' type='primary'>
                  Edit
                </Button>
              </Link>
            )}

          {permission?.['delete:any'] &&
            !Number(data?.invclientpayment_amount || 0) && (
              <Button
                size='small'
                type='primary'
                danger
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleOnDelete(data.invoice_id, index)}
                  title='Sure to delete?'
                >
                  Delete
                </Popconfirm>
              </Button>
            )}

          {addMRPermission && (
            <InvoiceOtherAddMoneyModal
              InvocieModalTitle={'Invoice Visa'}
              invoice_id={data.invoice_id}
              disabled={
                Number(data.net_total) === Number(data.invclientpayment_amount)
              }
            />
          )}
        </Space>
      ),
    },
  ];

  if (hasPermission('visa_process')) {
    const assignByColumn = {
      title: 'Assign By',
      dataIndex: 'assign_by',
      key: 'assign_by',
      width: 120,
      render: (_: any, record: IAllInvoices) =>
        record?.have_workflow === 'false' ? (
          <Button
            size='small'
            type='primary'
            onClick={() => {
              setInvoiceId(record.invoice_id);
              dispatch(setModal(true));
            }}
          >
            Assign By
          </Button>
        ) : (
          <>
            <Button disabled size='small' type='primary'>
              Assign By
            </Button>
          </>
        ),
    };

    // Find the index of the 'Action' column
    const actionColumnIndex = columns.findIndex(
      (column) => column.key === 'operation'
    );

    // Insert the 'Assign By' column before the 'Action' column
    if (actionColumnIndex !== -1) {
      columns.splice(actionColumnIndex, 0, assignByColumn);
    }
  }

  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
    setDataSource(data?.data);
  }, [isFetching, date]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllInvoices();
  };

  // Perform Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setQuery(`page=1&size=20`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setQuery(`page=1&size=20`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setSearch(removeSpaceBeforeWord(e.target.value));
  };
  return (
    <>
      {/* BREADCRUMB */}
      <BreadCrumb arrOfOption={['Invoice', 'Visa']} />

      {/* HEADER */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Col span={12}>
          <Row gutter={10}>
            {permission?.['create:any'] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={createLink}>
                  <Button
                    type='primary'
                    icon={<ArrowLeftOutlined />}
                    style={{ width: '100%' }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <Col>
                <Reload
                  refetch={() => {
                    refetch({});
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* TABLE */}
      <Table
        size='small'
        bordered
        rowKey={(e) => e.invoice_id}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: true }}
        pagination={count > 20 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) => {
          setTableParams((prev) => ({ ...prev, pagination: args1 }));
          setQuery &&
            args1.current &&
            args1?.pageSize &&
            setQuery(`page=${args1?.current}&size=${args1?.pageSize}`);
        }}
      />

      {(isLoading || isFetching) && <LoadingIndicator />}
      {invoiceId && <VisaAssign invoiceId={invoiceId} />}
    </>
  );
};

export default InvoiceVisaLists;
