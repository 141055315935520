import { Col, Form, Select } from 'antd';
import { useGetAuthorityQuery } from '../Api/Endpoints/AuthorityEndpoints';

type Props = {
  disabled?: boolean;
};

function SelectAuthority({ disabled }: Props) {
  const { data: authority } = useGetAuthorityQuery();

  return (
    <Col span={6} xs={24} sm={24} md={12} lg={12}>
      <Form.Item
        name='authority_id'
        label='Loan Authority'
        rules={[{ required: true, message: 'Loan Authority is required!!' }]}
      >
        <Select
          disabled={disabled || false}
          allowClear
          placeholder='Select Loan Authority'
          style={{ width: '100%' }}
        >
          {authority?.data?.map((item, index) => {
            return (
              <Select.Option key={index} value={item.authority_id}>
                {item.authority_name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
}

export default SelectAuthority;
