import { ColumnsType } from 'antd/lib/table/interface';
import { IAitReportDataType } from '../types/aitReportTypes';
import dayjs from 'dayjs';

const AitReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IAitReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Sales Date',
      key: 'date',
      render: (_, record) => {
        return `${dayjs(record.airticket_sales_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },

    {
      title: 'Ait Amount',
      dataIndex: 'airticket_ait',
      key: 'airticket_ait',
      align: 'right',
      render: (_, record) => Number(record?.airticket_ait),
    },
    {
      title: 'Commission',
      dataIndex: 'airticket_net_commssion',
      key: 'airticket_net_commssion',
      align: 'right',
    },
  ];
};

export default AitReportColumn;
