import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

export const userProfileEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getProfileDetails: build.query<HTTPResponse<userProfileDetails>, void>({
      query: () => ({
        url: `/admin-panel/agency_profile`,
      }),
      providesTags: ['profile-details'],
    }),

    updateProfileDetails: build.mutation<
      HTTPResponse<void>,
      { body: userProfileDetails }
    >({
      query: ({ body }) => ({
        url: `/admin-panel/agency_profile`,
        body: body,
        method: 'PUT',
      }),
      invalidatesTags: ['profile-details'],
    }),

    updateProfileLogo: build.mutation<
      HTTPResponse<void>,
      { body: FormData; id: string | number }
    >({
      query: ({ body, id }) => ({
        url: `/admin-panel/logo/${id}`,
        body: body,
        method: 'PUT',
      }),
      invalidatesTags: ['profile-details'],
    }),
  }),
});

export const {
  useGetProfileDetailsQuery,
  useUpdateProfileDetailsMutation,
  useUpdateProfileLogoMutation,
} = userProfileEndpoints;

export interface userProfileDetails {
  org_id?: number;
  org_name: string;
  org_owner_full_name: string;
  org_owner_email: string;
  org_logo?: string;
  org_address1: string;
  org_address2: string;
  org_dial_code?: string;
  org_mobile_number: string;
  org_facebook: string;
  org_website: string;
  org_extra_info: string;
}
