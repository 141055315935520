import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Image,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BreadCrumb from "../../../../src/components/common/breadCrumb/BreadCrumb";
import {
  InvoiceHeader,
  TitleCenter,
} from "../../../common/Invoice/InvoiceHeader";
import { a4sizeStyle } from "../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import { portraitPageStyle } from "../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import NumToWord from "../../../components/number_to_words/NumToWord";
import { useGetSingleExpenseQuery } from "../Api/Endpoints/ExpenseEndpoints";
import { IExpenseInfo } from "../ExpenseTypes/Expensetypes";
import { useAppSelector } from "../../../app/hooks";
import { storageBaseUrl } from "../../../common/utils/common.utils";

function ViewExpense() {
  const { Text } = Typography;
  const { id } = useParams();
  const {
    data: singleExpense,
    isLoading,
    isFetching,
  } = useGetSingleExpenseQuery(Number(id));

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    documentTitle: `Expense_${name}_${
      singleExpense &&
      dayjs(singleExpense?.data?.expense_date).format("DD MMM YYYY")
    }`,
    pageStyle: portraitPageStyle,
  });

  const columns: ColumnsType<IExpenseInfo> = [
    {
      title: "SL.",
      key: "key",
      dataIndex: "key",
    },
    {
      title: "Vouchar No.",
      key: "expense_vouchar_no",
      dataIndex: "expense_vouchar_no",
    },
    {
      title: "Head Name",
      dataIndex: "head_name",
      key: "head_name",
    },

    {
      title: "Pay Type",
      dataIndex: "expense_pay_type",
      key: "expense_pay_type",
      render: (expense_pay_type) => {
        return (
          <>
            {expense_pay_type +
              (singleExpense?.data?.expense_cheque_no
                ? ` (${singleExpense.data.expense_cheque_no})`
                : "")}
          </>
        );
      },
    },

    {
      title: "Pay Account",
      dataIndex: "account_name",
      key: "account_name",
    },

    {
      title: "Bank Name",
      dataIndex: "expcheque_bank_name",
      key: "expcheque_bank_name",
    },

    {
      title: "Total Amount",
      dataIndex: "expdetails_amount",
      key: "expdetails_amount",
      align: "right",
      render: (expdetails_amount) => {
        return <>{Number(expdetails_amount) || 0}</>;
      },
    },
  ];

  const appConfig = useAppSelector((state) => state.configSlice);

  const { tac_inv_cs, tac_inv_as, tac_signtr, tac_sig_url } = appConfig;

  useHotkeys("ctrl + p", (e) => {
    e.preventDefault();
    handlePrint();
  });

  return (
    <>
      <BreadCrumb arrOfOption={["Dashboard", "Expense", "View Expense"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Link to="/expense">
          <Button type="primary">
            <ArrowLeftOutlined />
            Return to Expense List
          </Button>
        </Link>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Card
          hoverable={false}
          ref={componentRef}
          style={{ ...a4sizeStyle, maxHeight: "11in" }}
        >
          {isLoading || isFetching ? <LoadingIndicator /> : ""}
          <InvoiceHeader />

          <Row justify={"end"} align="middle">
            <Col>
              {singleExpense?.data?.expense_vouchar_no && (
                <Typography className="changeFont">
                  Vouchar No : {singleExpense?.data?.expense_vouchar_no}
                </Typography>
              )}
              <Text
                style={{
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Date :
                {dayjs(singleExpense?.data?.expense_date).format("DD MMM YYYY")}
              </Text>
            </Col>
          </Row>

          <TitleCenter title="Expense" />
          <div>
            <Table
              size="small"
              bordered
              pagination={false}
              columns={columns}
              dataSource={singleExpense?.data?.expense_info}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              summary={(_) => {
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={2} colSpan={6}>
                      <Typography style={{ textAlign: "right" }}>
                        Total :
                      </Typography>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={3}>
                      <Row justify={"end"}>
                        <Typography.Text strong>
                          {Number(singleExpense?.data?.expense_total_amount) ||
                            0}
                        </Typography.Text>
                      </Row>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />

            <Row
              justify={"space-between"}
              align={"middle"}
              style={{
                margin: "25px 0",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Note : {singleExpense?.data?.expense_note}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {singleExpense && (
                  <NumToWord
                    number={Number(
                      singleExpense?.data?.expense_total_amount || 0
                    )}
                  />
                )}
              </Typography>
            </Row>
          </div>

          {/* ================== signature ===================== */}
          <Row
            className="signature"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "70px",
            }}
          >
            <Typography.Text
              style={{
                borderTop: "1px solid black",
                color: "black",
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Received By
            </Typography.Text>

            <Typography.Text
              style={{
                borderTop: "1px solid black",
                color: "black",
                fontSize: "12px",
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Manager Signature
            </Typography.Text>
            {/* <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Approved By
            </Typography.Text> */}

            <div
              className="authority-signature"
              style={{ position: "relative" }}
            >
              <Typography.Text
                style={{
                  marginRight: "10px",
                  borderTop: "1px solid gray",
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {"Approved By"}
              </Typography.Text>
              {tac_signtr === 1 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 25,
                    right: 10,
                    pointerEvents: "none",
                    userSelect: "none",
                  }}
                >
                  <Image
                    preview={false}
                    src={storageBaseUrl + tac_sig_url}
                    width={80}
                  ></Image>
                </div>
              )}
            </div>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
}

export default ViewExpense;
