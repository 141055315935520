import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { HTTPResponse, voidType } from '../../../../common/types/commonTypes';
import {
  IHajjTrackingNumber,
  IInvoicePreRegHajiInfo,
} from '../../../Invoice(Visa)/Type/invoiceVisa.interface';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  IPreRegEditEndPointDataType,
  IPreRegPostCreateEndPointsTypes,
} from '../../Types/InvoiceHajjiPre.Types';

export const invoiceHajjiEndspont = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllInvoiceHajjPreReg: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/invoice_hajj_pre?${query}`,
      }),
      providesTags: () => [
        'invoices',
        'invoice-hajji',
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // VIEW DETAILIS
    getViewInvoiceHajjPreReg: build.query<
      HTTPResponse<IViewInvoiceDetails>,
      number
    >({
      query: (id) => ({
        url: `/invoice_hajj_pre/view/${id}`,
      }),
      providesTags: () => ['invoice-hajji', 'invoices'],
    }),

    // DELETE AND RESTORE
    deleteInvoiceHajjPreReg: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/invoice_hajj_pre/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'invoice-hajji',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    getAllInvoiceHajjiInfo: build.query<
      HTTPResponse<IInvoicePreRegHajiInfo[]>,
      void
    >({
      query: () => ({
        url: '/invoice_hajj_pre/haji_info_by_tracking/',
      }),
      providesTags: () => [{ type: 'invoice-hajji' }],
    }),

    getAllHajjiTrackingNumber: build.query<
      HTTPResponse<IHajjTrackingNumber[]>,
      void
    >({
      query: () => ({
        url: '/hajji_management/tracking_no/hajj',
      }),
      providesTags: () => [{ type: 'invoice-hajji' }],
    }),

    getAllHajjiTrackingDetails: build.mutation<
      HTTPResponse<IHajjTrackingNumber[]>,
      string[]
    >({
      query: (body) => ({
        url: '/hajji_management/haji-info-by-traking_no',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'invoice-hajji' }],
    }),
    /**
     * GET INVOICE FOR EDIT
     *
     */
    getAllInvoiceHajjiForEdit: build.query<
      HTTPResponse<IPreRegEditEndPointDataType>,
      number
    >({
      query: (id) => ({
        url: `/invoice_hajj_pre/${id}`,
      }),
      providesTags: () => [{ type: 'invoice-hajji' }],
    }),

    /**
     * POST
     *
     */
    postInvoiceHajjiPre: build.mutation<
      HTTPResponse<void>,
      IPreRegPostCreateEndPointsTypes
    >({
      query: (body) => ({
        url: '/invoice_hajj_pre',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [
        { type: 'invoice-hajji' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
    /**
     * @Check_hajji_TrackingNo_and_SerialNo
     *
     */
    checkTrackingAndSerial: build.mutation<
      HTTPResponse<boolean>,
      { data_for: 'tracking' | 'serial'; value: string }
    >({
      query: (body) => ({
        url: '/invoice_hajj_pre/serial-tracking-isexist',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'invoice-hajji' }],
    }),
    /**
     * EDIT
     *
     */
    editInvoiceHajjiPre: build.mutation<
      HTTPResponse<void>,
      { body: IPreRegEditEndPointDataType; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/invoice_hajj_pre/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        { type: 'invoice-hajji' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    // Void Invoice Hajj Pre
    voidHajjPreInvoiceTicket: build.mutation<void, voidType>({
      query: (body) => ({
        url: `/invoice_hajj_pre/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'invoices',
        'invoice-hajji',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  usePostInvoiceHajjiPreMutation,
  useGetAllInvoiceHajjiForEditQuery,
  useEditInvoiceHajjiPreMutation,
  useCheckTrackingAndSerialMutation,
  useGetAllInvoiceHajjPreRegQuery,
  useLazyGetAllInvoiceHajjPreRegQuery,
  useDeleteInvoiceHajjPreRegMutation,
  useGetViewInvoiceHajjPreRegQuery,
  useGetAllInvoiceHajjiInfoQuery,
  useVoidHajjPreInvoiceTicketMutation,
  useGetAllHajjiTrackingNumberQuery,
  useGetAllHajjiTrackingDetailsMutation,
} = invoiceHajjiEndspont;
