import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { SelectVendors } from '../../../components/common/FormItem/SelectCustomFeilds';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  DateInput,
  FormInput,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { FormInstance } from 'antd/lib/form';
import { useParams } from 'react-router';
import { useLazyTicketIsExistQuery } from '../../Invoice_Air_Ticket/api/endpoints/airticketInvoiceEndpoints';

type Props = {
  form: FormInstance<any>;
};

const NewBillingInfoForm = ({ form }: Props) => {
  const [handleTicketNo, setHandleTicketNo] = useState<string>('');
  const [ticketIsExist, { data: ticketIsExisting }] =
    useLazyTicketIsExistQuery();

  useEffect(() => {
    const ticketFetcing = setTimeout(async () => {
      if (handleTicketNo) {
        await ticketIsExist(handleTicketNo);
        const ticket = form.getFieldValue('airticket_ticket_no');
        if (ticket) {
          form.validateFields(['airticket_ticket_no']);
        }
      }
    }, 800);
    return () => clearTimeout(ticketFetcing);
  }, [handleTicketNo]);

  const { id } = useParams();

  return (
    <div className='my-20 border p-5'>
      <FormHeaderTitle title={`${id ? 'Edit' : 'New'} Ticket & Billing Info`} />

      <Row gutter={[10, 0]}>
        <FormInput
          name={['airticket_ticket_no']}
          label='Ticket No'
          onChange={(value: string) => {
            setHandleTicketNo(value);
          }}
          size={4}
          required
          rules={[
            {
              validator: async (_, value) => {
                if (ticketIsExisting?.data && value) {
                  return Promise.reject();
                } else return Promise.resolve();
              },
              message: 'Ticket already exist!',
            },
          ]}
        />

        <SelectVendors
          name={['airticket_comvendor']}
          label='Vendor'
          size={4}
          required
        />

        <DateInput
          name={['airticket_journey_date']}
          label='Journey Date'
          size={4}
          required
        />

        <DateInput label='Return Date' name='airticket_return_date' size={4} />

        <NumberInput
          name={['airticket_client_charge']}
          label='Client Charge'
          size={4}
          min='0'
          maxChar={10}
          minChar={0}
        />
        <NumberInput
          name={['airticket_vendor_charge']}
          label='Vendor Charge'
          size={4}
          min='0'
          maxChar={10}
          minChar={0}
        />
        <TextAreaInput size={4} label='Note' name='invoice_note' />
        <NumberInput
          name={['airticket_service_charge']}
          label='Service Charge'
          size={4}
          min='0'
          maxChar={10}
          minChar={0}
        />

        <NumberInput
          name={['airticket_profit']}
          label='Profit'
          size={4}
          readOnly
          min='0'
          maxChar={14}
          minChar={0}
        />
      </Row>
    </div>
  );
};

export default NewBillingInfoForm;
