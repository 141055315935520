import {
  Alert,
  Button,
  Card,
  Descriptions,
  Form,
  Popconfirm,
  message,
} from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { FormInstance } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { useCreateOthersRefundClientMutation } from '../../Api/Endpoints/OthersRefundClientEndpoints';
import {
  IOthersRefundForm,
  IOthersRefundInfo,
} from '../../RefundTypes/RefundTypes';
import OtherRefundVendorInfo from '../Vendor/OtherRefundVendorInfo';
import OtherRefundClientInfo from './OtherRefundClientInfo';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { log } from 'console';

type Props = {
  client_id: string;
  client_name: string;
  invoice_id: number;
  form: FormInstance<any>;
  clientRefund?: number;
  clientReturnTotal?: number;
  vendorReturnTotal?: number;
  clientRefundChargeTotal?: number;
  clientLastBalance?: number;
  tableData?: IOthersRefundInfo[];
  refund_date: string;
  invoice_category_id: number;
  permission?: IPermission;
};

const OtherRefundClientPreviewForm = ({
  client_id,
  client_name,
  invoice_id,
  form,
  clientRefund,
  clientReturnTotal,
  vendorReturnTotal,
  clientRefundChargeTotal,
  clientLastBalance,
  tableData,
  refund_date,
  invoice_category_id,
  permission,
}: Props) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [addOthersRefund, { isError, isSuccess, isLoading }] =
    useCreateOthersRefundClientMutation();

  const onRefundFormFinish = async () => {
    const values: IOthersRefundForm = form.getFieldsValue();
    const voucher_no: string = 'OR_REF_' + dayjs().unix();
    const body: IOthersRefundForm = {
      ...values,
      voucher_no,
      created_by: user?.user_id as number,
      invoice_id: invoice_id,
      comb_client: client_id,
      date: dayjs(refund_date).format('YYYY-MM-DD'),
      client_refund_info: {
        ...values.client_refund_info,
        client_id,
        total_return_amount:
          values.client_refund_info.crefund_payment_type === 'MONEY_RETURN'
            ? values.client_refund_info.return_amount
            : 0,
        total_refund_amount: clientRefund as number,
        total_refund_charge: clientRefundChargeTotal as number,
        crefund_date: dayjs(refund_date).format('YYYY-MM-DD'),
      },
      vendor_refund_info: (tableData as IOthersRefundInfo[]).map(
        (item, index) => {
          return {
            ...item,
            ...values.vendor_refund_info[index],

            vrefund_bill_id: item.billing_id,
            vrefund_product_id: item.billing_product_id,
            vrefund_quantity: item.refund_quantity,
            comb_vendor_id: `${item.vendor_type}-${item?.billing_vendor_id}`,
            billing_remaining_quantity: item.billing_remaining_quantity,
            vrefund_charge: item.vendor_refund_charge,
            vrefund_amount: item.vendor_refund_amount,
            vrefund_invoice_category_id: invoice_category_id,

            vrefund_return_amount:
              values.vendor_refund_info[index].vrefund_payment_type ===
              'MONEY_RETURN'
                ? values.vendor_refund_info[index].return_amount
                : 0,
            vrefund_date: values.vendor_refund_info[index].date
              ? dayjs(values.vendor_refund_info[index].date).format(
                  'YYYY-MM-DD'
                )
              : dayjs().format('YYYY-MM-DD'),
          };
        }
      ),
    };

    if (values.client_refund_info.withdraw_date) {
      body.client_refund_info.withdraw_date = dayjs(
        values.client_refund_info.withdraw_date
      ).format('YYYY-MM-DD');
    }

    if (clientLastBalance && clientLastBalance < 0) {
      body.client_refund_info.crefund_payment_type = 'ADJUST';
    }
    body.vendor_refund_info.map((item) => {
      if (item.withdraw_date) {
        item.withdraw_date = dayjs(item.withdraw_date).format('YYYY-MM-DD');
      }
      delete item.key;
      delete item.vendor_refund_amount;
      delete item.vendor_refund_charge;
      delete item.vendor_refund_price;
      delete item.vendor_total_price;
      delete item.product_name;
      delete item.client_refund_price;
      delete item.client_total_price;
      delete item.billing_vendor_last_balance;
      delete item.billing_vendor_name;
      delete item.billing_unit_price;
      delete item.billing_subtotal;
      delete item.billing_cost_price;
      delete item.billing_create_date;
      delete item.billing_invoice_id;
      delete item.net_total;
      delete item.refund_quantity;

      delete item.billing_id;
      delete item.billing_product_id;
      delete item.billing_quantity;
    });

    await addOthersRefund(body);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Refund created successfully');
      navigate('/refund/history/others', { state: location.pathname });
    } else if (isError) {
      message.error('You have an error!');
    }
  }, [isLoading]);

  return (
    <>
      <Form form={form} layout='vertical'>
        <FormHeaderTitle title='Client Refund Information :' />
        <Card>
          <Descriptions
            size='small'
            bordered
            column={1}
            style={{ marginBottom: '1rem' }}
          >
            <DescriptionsItem label='Client Name'>
              {client_name}
            </DescriptionsItem>
            <DescriptionsItem label='Total Refund'>
              {clientRefund}
            </DescriptionsItem>
            <DescriptionsItem label='Refund Charge'>
              {clientRefundChargeTotal}
            </DescriptionsItem>
            <DescriptionsItem label='Net Refund Amount'>
              {clientReturnTotal}
            </DescriptionsItem>
            <DescriptionsItem label='Client Last Balance'>
              {clientLastBalance}
            </DescriptionsItem>
          </Descriptions>
          {form.getFieldValue([
            'client_refund_info',
            'crefund_payment_type',
          ]) === 'MONEY_RETURN' ? (
            <Alert
              message='Money Return can be manually refunded'
              style={{ marginBottom: '1rem' }}
            />
          ) : (
            <></>
          )}

          <OtherRefundClientInfo
            form={form}
            clientLastBalance={clientLastBalance}
            clientRefundTotal={clientReturnTotal}
            clientRefundChargeTotal={clientRefundChargeTotal}
          />
        </Card>
        <FormHeaderTitle title='Vendor Refund Information :' />
        <Card>
          {tableData?.map((item, index) => (
            <React.Fragment key={index}>
              <Descriptions
                size='small'
                bordered
                column={1}
                style={{ marginBottom: '1rem' }}
              >
                <DescriptionsItem label='Vendor Name'>
                  {item.billing_vendor_name}
                </DescriptionsItem>
                <DescriptionsItem label='Total Refund'>
                  {item.vendor_refund_amount}
                </DescriptionsItem>
                <DescriptionsItem label='Refund Charge'>
                  {item.vendor_refund_charge}
                </DescriptionsItem>
                <DescriptionsItem label='Net Refund Amount'>
                  {item.vendor_refund_price}
                </DescriptionsItem>
                <DescriptionsItem label='Vendor Last Balance'>
                  {Number(item.billing_vendor_last_balance)}
                </DescriptionsItem>
              </Descriptions>
              {form.getFieldValue([
                'vendor_refund_info',
                index,
                'vrefund_payment_type',
              ]) === 'MONEY_RETURN' ? (
                <Alert
                  message='Money Return can be manually refunded'
                  style={{ marginBottom: '1rem' }}
                />
              ) : (
                <></>
              )}
              <OtherRefundVendorInfo
                index={index}
                form={form}
                vendorLastBalance={Number(item.billing_vendor_last_balance)}
                vendorRefundTotal={item.vendor_refund_price}
                vendorcharge={item.vendor_refund_charge}
              />
            </React.Fragment>
          ))}
        </Card>

        {permission?.[crud.update] && (
          <Popconfirm
            title='Sure to Confirm Refund?'
            onConfirm={() => {
              onRefundFormFinish();
            }}
            okButtonProps={{ loading: isLoading }}
          >
            <Button
              style={{ marginTop: '1rem' }}
              type='primary'
              loading={isLoading}
              htmlType='submit'
            >
              Submit
            </Button>
          </Popconfirm>
        )}
      </Form>
    </>
  );
};

export default OtherRefundClientPreviewForm;
