import { Button, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Flight_Details_List_Info from './Flight_Details_List_Info';
import { AirTicket_ticket_information } from '../types/invoiceAirTicketTypes';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  form: FormInstance<any>;
  ticketInfo?: AirTicket_ticket_information[];
};

export default function AirticketFlightDetails({ form, ticketInfo }: Props) {
  return (
    <div className='border p-5 mt-10'>
      <FormHeaderTitle title='Flight Details (Optional) ✈️' />

      <Form.List name={['flight_details']} initialValue={[{}]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index, arr) => {
              return (
                <Flight_Details_List_Info
                  add={add}
                  field={field}
                  index={index}
                  remove={remove}
                  key={index}
                  form={form}
                  ticketInfo={ticketInfo}
                  length={arr.length}
                />
              );
            })}

            {fields.length == 0 && (
              <Row justify={'center'}>
                <Col span={8}>
                  <Form.Item label={[]}>
                    <Button onClick={() => add()} block icon={<PlusOutlined />}>
                      Add New Flight Details
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
}
