import { Col, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { useState } from 'react';
import FileUpload from '../../../../common/utils/FileUpload';
import {
  usePostEditAppConfigMutation,
  usePostEditAppConfigSignatureMutation,
} from '../endpoint/appConfigEndpoint';
import { IGetAppConfig } from '../type/appConfig_Type';
import { getImageUrl } from '../../../../common/utils/common.utils';

type Props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};

const AuthSignature = ({ configDetails, setConfigDetails }: Props) => {
  const [fileType, setFileType] = useState<RcFile>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [image, setImage] = useState<RcFile | undefined>();

  const [imageFile, setImageFile] = useState<string | undefined>('');

  const [postEditSignature] = usePostEditAppConfigSignatureMutation();

  const handleImageUpload = (img: any) => {
    const formData = new FormData();
    if (img) formData.append('tac_sig_url', img[0]?.originFileObj);

    postEditSignature(formData);
  };

  const defaultValue = configDetails?.tac_signtr;

  const [postEdit] = usePostEditAppConfigMutation();

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_signtr: e.target.value,
    };

    postEdit(body);
  };

  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={4}>Auto Signature:</Typography.Title>
          <Typography.Text type='secondary'>
            Useful for add automatic signature in invoices
          </Typography.Text>
        </Col>
        <Col>
          <Radio.Group onChange={onChange} value={defaultValue}>
            <Space direction='vertical'>
              <Radio value={0}>Auto Signature Off </Radio>
              <Radio value={1}>Auto Signature On</Radio>
            </Space>
            <div style={{ marginTop: 10 }}>
              <FileUpload
                noBorder
                size={24}
                msg='Upload Signature'
                onChange={(img) => handleImageUpload(img)}
                imagURL={getImageUrl(configDetails?.tac_sig_url)}
              />
            </div>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AuthSignature;
