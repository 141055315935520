import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { perProps } from '../../../common/types/commonTypes';
import {
  getTableColumnsWithoutNull,
  rangePresets,
} from '../../../common/utils/common.utils';
import { removeSpaceBeforeWord } from '../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllExpensesQuery } from '../Api/Endpoints/ExpenseEndpoints';
import { ExpenseUtils } from '../Utils/ExpenseUtils';
import dayjs from 'dayjs';

const ExpenseHistory = ({ permission }: perProps) => {
  const searchParams = new URLSearchParams(location.search);
  const [date, setDate] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (searchParams && location.search) {
      const formDate = searchParams.get('from_date');
      const toDate = searchParams.get('to_date');
      if (formDate && toDate) setDate([formDate, toDate]);
    }
  }, []);

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: expense,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllExpensesQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  // TABLE COLUMNS
  const columns = ExpenseUtils({ permission, pagination });
  const filterColumns = getTableColumnsWithoutNull(expense?.data, columns);

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Expense']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row justify={'space-between'}>
        <Col lg={4}>
          <Space style={{ marginBottom: '1rem' }}>
            {permission?.['create:any'] && (
              <Link to='/expense/add' state={location.pathname}>
                <Button type='primary'>
                  <PlusOutlined />
                  Add Expense
                </Button>
              </Link>
            )}
          </Space>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              {date[1]?.length ? (
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={'YYYY-MM-DD'}
                  onChange={handleDateRangeChange}
                  value={[
                    dayjs(date[0], 'YYYY-MM-DD'),
                    dayjs(date[1], 'YYYY-MM-DD'),
                  ]}
                />
              ) : (
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={'YYYY-MM-DD'}
                  onChange={handleDateRangeChange}
                />
              )}
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        rowKey={(e) => e.expense_id}
        size='small'
        bordered
        columns={filterColumns}
        dataSource={expense?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          expense?.count !== undefined && expense?.count < 20
            ? false
            : {
                ...pagination,
                total: expense?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </div>
  );
};

export default ExpenseHistory;
