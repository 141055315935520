import { Button, Col, Row, Typography } from 'antd';
import axios from 'axios';
import { useAppSelector } from '../../../app/hooks';
import { useMakeDbBackupMutation } from '../Api/Db_dowloadEndpoint';

type Props = {};

const Db_backup = (props: Props) => {
  const user = useAppSelector((state) => state.user);
  const [downloadDB] = useMakeDbBackupMutation();
  //   console.log(request);

  const config = {
    headers: {
      Authorization: 'Bearer your_token',
      'Content-Type': 'application/json',
    },
  };

  const handleDownload = () => {
    const downloadUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/admin-panel/db-backup`;

    axios({
      method: 'get',
      url: downloadUrl,
      responseType: 'blob',
      ...config,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'db_backup.zip');

        link.click();
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  };

  return (
    <Row style={{ height: '70vh' }} align={'middle'} justify={'center'}>
      <div>
        <Col>
          <Typography.Title level={4}>
            Your Database Ready For Download
          </Typography.Title>
        </Col>

        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button onClick={() => downloadDB('')} type='primary'>
            Download Now !!!
          </Button>
        </Col>
      </div>
    </Row>
  );
};

export default Db_backup;
