import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { ICombineClientLedger } from '../types/clientLedgerTypes';

const { Text } = Typography;

export const CombinedLedger = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<ICombineClientLedger> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
      width: 50,
    },
    {
      title: 'Trxn Date',
      key: 'comtrxn_create_at',
      render: (_, record) => {
        return `${dayjs(record.comtrxn_create_at).format('DD-MMM-YYYY')}`;
      },
      align: 'center',
      width: 80,
    },

    {
      title: 'Particulars',
      dataIndex: 'comtrxn_particular_type',
      key: 'comtrxn_particular_type',
      align: 'center',
      render: (comtrxn_particular_type) => (
        <Text style={{ textAlign: 'left', fontSize: 12 }}>
          {comtrxn_particular_type
            ?.split('\n')
            ?.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
        </Text>
      ),
      width: 120,
    },

    {
      title: 'Voucher No',
      dataIndex: 'comtrxn_voucher_no',
      key: 'comtrxn_voucher_no',
      align: 'center',
    },

    {
      title: 'Ticket No.',
      dataIndex: 'comtrxn_airticket_no',
      key: 'comtrxn_airticket_no',
      align: 'center',
      render: (curr) => {
        return (
          <span>
            {curr?.split(',')?.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </span>
        );
      },
    },

    {
      title: 'Pax Name',
      dataIndex: 'comtrxn_pax',
      key: 'comtrxn_pax',
      align: 'center',
      render: (curr) => {
        return (
          <span>
            {curr?.split(',')?.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </span>
        );
      },
    },

    {
      title: 'PNR',
      dataIndex: 'comtrxn_pnr',
      key: 'comtrxn_pnr',
      align: 'center',
    },

    {
      title: 'Route',
      dataIndex: 'comtrxn_route',
      align: 'center',
      key: 'comtrxn_route',
    },

    {
      title: 'Pay Type',
      dataIndex: 'comtrxn_pay_type',
      key: 'comtrxn_pay_type',
      align: 'center',
    },

    {
      title: 'Debit',
      align: 'center',
      dataIndex: 'debit_amount',
      key: 'debit_amount',
      render: (_, data) => (
        <>
          {data.comtrxn_type === 'DEBIT' && (
            <Typography.Text>
              <span style={{ color: 'red' }}>
                {Fixed2(data.comtrxn_amount)}
              </span>
            </Typography.Text>
          )}
        </>
      ),
    },
    {
      title: 'Credit',
      align: 'center',
      dataIndex: 'credit_amount',
      key: 'credit_amount',
      render: (_, data) => (
        <>
          {data.comtrxn_type === 'CREDIT' && (
            <Typography.Text>
              <span style={{ color: 'green' }}>
                {Fixed2(data.comtrxn_amount)}
              </span>
            </Typography.Text>
          )}
        </>
      ),
    },

    {
      title: 'Balance',
      align: 'center',
      dataIndex: 'comtrxn_lbalance',
      key: 'comtrxn_lbalance',
      // width: 'auto',
      sorter: (a, b) => Number(a.comtrxn_lbalance) - Number(b.comtrxn_lbalance),

      render: (amount) => (
        <>
          <Typography.Text
            style={{ color: parseInt(amount) > 0 ? 'green' : 'red' }}
          >
            {Number(amount) === 0 ? '' : Number(amount) > 0 ? 'Adv ' : 'Due '}
            {Fixed2(Math.abs(Number(amount)))}
          </Typography.Text>
        </>
      ),
    },

    {
      title: 'Note',
      dataIndex: 'comtrxn_note',
      key: 'comtrxn_note',
      align: 'center',
      render: (column, data) => (
        <span>
          {data?.comtrxn_note?.split('\n')?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },

    {
      title: 'Created Date',
      key: 'comtrxn_create_date',
      render: (_, record) => {
        return `${dayjs(record.comtrxn_create_date).format('DD-MMM-YYYY')}`;
      },
      align: 'center',
    },
  ];
};
