import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { IDateWiseExpenseDataType } from '../types/dateWiseExpenseReportType';

export const DateWiseExpenseColumn =
  (): ColumnsType<IDateWiseExpenseDataType> => {
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
      },
      {
        title: 'Date',
        dataIndex: 'expense_created_date',
        key: 'expense_created_date',
        render: (_, record) => {
          return `${moment(record.expense_created_date).format('DD MMM YYYY')}`;
        },
        align: 'center',
      },

      {
        title: 'Invoice No.',
        dataIndex: 'expense_vouchar_no',
        key: 'expense_vouchar_no',
        align: 'center',
      },
      {
        title: 'Account Name',
        dataIndex: 'account_name',
        key: 'account_name',
        align: 'center',
      },
      {
        title: 'Expense Type',
        dataIndex: 'head_expense',
        key: 'head_expense',
        align: 'center',
      },
      {
        title: 'Payment Type',
        dataIndex: 'expense_payment_type',
        key: 'expense_payment_type',
        align: 'center',
      },
      {
        title: 'Status',
        dataIndex: 'expcheque_status',
        key: 'expcheque_status',
        align: 'center',
      },
      {
        title: 'By',
        dataIndex: 'created_by',
        key: 'created_by',
        align: 'center',
      },

      {
        title: 'Amount',
        dataIndex: 'expense_total_amount',
        key: 'expense_total_amount',
        align: 'center',
        render: (_, record) => Number(record.expense_total_amount),
      },
    ];
  };
