import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row, Spin, Tabs, Tag } from 'antd';
import 'antd/dist/reset.css';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import { useGetSingleCombineClientsQuery } from '../../api/endpoints/clientCombineEndpoints';
import CombineClientDetailInvoice from './CombineClientDetailInvoice';
import CombineClientDetailPayment from './CombineClientDetailPayment';
import CombinedLedgerReport from '../../../../Reports/ledgers/pages/CombinedLedgerReport';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';

const CombineClientDetails = () => {
  const { id } = useParams();
  const { isLoading, data: combineClients } = useGetSingleCombineClientsQuery(
    Number(id)
  );

  return (
    <div>
      <div>
        <BreadCrumb arrOfOption={['View CombineClient Details']} />

        <Col style={{ marginBottom: '1rem' }}>
          <Link to='/combineds'>
            <Button type='primary'>
              <ArrowLeftOutlined />
              Return to CombineClient List
            </Button>
          </Link>
        </Col>
      </div>
      <div className='card-container'>
        <Tabs
          type='card'
          items={[
            {
              label: 'Details',
              key: 'Details',
              children: isLoading ? (
                <Row justify='center' align='middle'>
                  {/* <Spin size='large' /> */}
                  {loadingIndicator}
                </Row>
              ) : (
                <Descriptions
                  size='small'
                  title='CombineClient Info'
                  bordered
                  column={1}
                >
                  <DescriptionsItem label='Gender'>
                    {combineClients?.data?.combine_gender}
                  </DescriptionsItem>
                  <DescriptionsItem label='Name'>
                    {combineClients?.data?.combine_name}
                  </DescriptionsItem>
                  <DescriptionsItem label='Email'>
                    {combineClients?.data?.combine_email}
                  </DescriptionsItem>
                  <DescriptionsItem label='Mobile'>
                    {combineClients?.data?.combine_mobile}
                  </DescriptionsItem>
                  <DescriptionsItem label='Address'>
                    {combineClients?.data?.combine_address}
                  </DescriptionsItem>
                  <DescriptionsItem label='Designation'>
                    {combineClients?.data?.combine_designation}
                  </DescriptionsItem>
                  <DescriptionsItem label='Status'>
                    {
                      <Tag
                        color={
                          combineClients?.data?.combine_client_status === 0
                            ? 'red'
                            : 'green'
                        }
                        key={combineClients?.data?.combine_client_status}
                      >
                        {combineClients?.data?.combine_client_status === 0
                          ? 'Inactive'
                          : 'Active'}
                      </Tag>
                    }
                  </DescriptionsItem>
                </Descriptions>
              ),
            },
            {
              label: 'Invoice',
              key: 'Invoice',
              children: <CombineClientDetailInvoice id={id as string} />,
            },
            {
              label: 'Payments',
              key: 'Payments',
              children: <CombineClientDetailPayment id={id as string} />,
            },
            {
              label: 'Combined Ledger',
              key: 'Combined Ledger',
              children: <CombinedLedgerReport />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CombineClientDetails;
