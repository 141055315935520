import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetExcelsJourneyDateWiseQuery,
  useLazyGetJourneyDateWiseClientsQuery,
} from '../endpoints/JourneyDateWiseClientsEndpoints';
import { IJourneyDateWiseFormDataType } from '../types/JourneyDateWiseClientsTypes';
import JourneyDateWiseClientsColumn from '../utils/JourneyDateWiseClientsColumn';

const JourneyDateWiseClients = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelsJourneyDateWiseQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  const [fetchJourneyWiseClients, { data, isLoading, isFetching }] =
    useLazyGetJourneyDateWiseClientsQuery();

  const onFinish = async (values: IJourneyDateWiseFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      comb_client: values.invoice_combclient_id,
    };
    await fetchJourneyWiseClients({
      comb_client: body.comb_client,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.comb_client.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setParamData({
      date_range: body.date_range,
      id: '',
    });
  };
  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IJourneyDateWiseFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      comb_client: values.invoice_combclient_id,
    };

    fetchJourneyWiseClients({
      comb_client: body.comb_client,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.comb_client.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const toDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `from_date="${toDate}"&to_date="${toDate}"`;
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('invoice_combclient_id', 'all');
    const body = {
      date_range: date_range,
      comb_client: 'all',
    };
    fetchJourneyWiseClients({
      comb_client: body.comb_client,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.comb_client.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setParamData({
      date_range: body.date_range,
      id: '',
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Journey Date Wise Clients Report',
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider>
      <Table
        size='small'
        bordered
        columns={JourneyDateWiseClientsColumn(queryData, paramData)}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        dataSource={data?.data}
        pagination={count > 20 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (Agents)' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb
        arrOfOption={['Reports', 'Journey Date Wise Clients Report']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'journeyDateWiseClient',
              excelName: 'journeyDateWiseClient',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col>
            <Row gutter={16}>
              <SelectClients
                name='invoice_combclient_id'
                label='Select Client :'
                placeholder='Select client'
                offDropDown
                showAll
              />
              <Col span={6} xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label='Date Range'
                  name='date_range'
                  rules={[
                    { required: true, message: ` Date Range is required!` },
                  ]}
                >
                  <DatePicker.RangePicker
                     presets={rangePresets}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col lg={2}>
                <FormButton label='Search' icon textAlign='left' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <FormHeaderTitle title='Journey Date Wise Clients Report' />

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default JourneyDateWiseClients;
