import { ColumnsType } from 'antd/es/table';
import { IQuotationBillInfo } from '../QuotationTypes/QuotationTypes';
import { Fixed2 } from '../../../common/utils/common.utils';

export const BillingInfoUtils: ColumnsType<IQuotationBillInfo> = [
  {
    title: 'SL.',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Product',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: 'Description',
    dataIndex: 'billing_description',
    key: 'billing_description',
    width: 180,
    render: (curr) => {
      return <div dangerouslySetInnerHTML={{ __html: curr }} />;
    },
  },
  {
    title: 'Country',
    dataIndex: 'country_name',
    key: 'country_name',
  },

  {
    title: 'Quantity',
    dataIndex: 'billing_quantity',
    key: 'billing_quantity',
    align: 'center',
  },

  {
    title: 'Unit Price',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    align: 'center',
    render: (_, data) => Fixed2(data?.billing_unit_price),
  },
  {
    title: 'Total Price',
    key: 'total_price',
    align: 'center',
    render: (_, record) => {
      return `${record.billing_quantity * record.billing_unit_price}`;
    },
  },
];
