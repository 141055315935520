import { Alert, Col, Row } from "antd";
import { useEffect, useState } from "react";
import {
  useExpensesDataQuery,
  useGetDashboardSummaryQuery,
  useSalesReportQuery,
} from "../Api/Endpoints/DashboardEndpoints";
import Account_Details_Insight from "../Components/Account_Details_Insight";
import Account_Insight from "../Components/Account_Insight";
import Client_Vendor_Agent_Insight from "../Components/Client_Vendor_Agent_Insight";
import NoticeBar from "../Components/NoticeBar";
import BestClient from "../Components/BestClient";
import BestEmployees from "../Components/BestEmployees";
import DailyReport from "../Components/DailyReport";
import ExpenseTotal from "../Components/ExpenseTotal";
import InvoiceDetails from "../Components/InvoiceDetails";
import MonthlyReport from "../Components/MonthlyReport";
import ReportCardSkeleton from "../Components/ReportCardSkeleton";
import YearlyReport from "../Components/YearlyReport";
import "../style/dashboard.css";
import { useLottie } from "lottie-react";
import nightBanner from "../../../assets/moon.json";
import dayBanner from "../../../assets/sun.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { iconStyle } from "../../../layout/pages/Sidebar";

const Dashboard = () => {
  const { data: salesData, isLoading: reportCardLoading } =
    useSalesReportQuery();
  const { data, isLoading } = useGetDashboardSummaryQuery();
  const { data: expenseData, isLoading: expenseLoading } =
    useExpensesDataQuery();
  const expenses = expenseData?.data;
  const summary = data?.data;
  const accountBalInsight = summary?.currAccStatus;
  const totalClVnCm = summary?.account_status;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const dark = localStorage.getItem("theme");

  useEffect(() => {
    if (dark === "darkTheme") {
      setIsDarkMode(false);
    } else if (dark === "defaultTheme") {
      setIsDarkMode(true);
    }
  }, [dark]);

  const dAlert = localStorage.getItem("dAlert");
  const [alertShow, setAlertShow] = useState<boolean>(true);
  const options = {
    animationData: dark === "darkTheme" ? dayBanner : nightBanner,
    loop: true,
  };
  const { View } = useLottie(options);
  return (
    <div>
      <NoticeBar />
      {dAlert !== "false" && (
        <div
          style={{
            marginBottom: "10px",
            borderRadius: "50px",
            position: "relative",
          }}
        >
          {View}
          <div
            onClick={() => {
              localStorage.setItem("dAlert", "false"), setAlertShow(false);
            }}
            style={{
              position: "absolute",
              right: "0px",
              top: "-10px",
              color: "white",
              backgroundColor: "red",
              borderRadius: "2px",
              padding: "0px 2px",
              cursor: "pointer",
            }}
          >
            {" "}
            <FontAwesomeIcon style={iconStyle} icon={faClose} />
          </div>
        </div>
      )}

      <Row justify={"space-between"} gutter={[20, 40]}>
        {reportCardLoading ? (
          <ReportCardSkeleton />
        ) : (
          <>
            <Col xl={8} md={12} sm={12} xs={24}>
              <DailyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
            <Col xl={8} md={12} sm={12} xs={24}>
              <MonthlyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
            <Col xl={8} md={12} sm={12} xs={24}>
              <YearlyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
          </>
        )}

        {/* <VendorInfo isDarkMode={isDarkMode} /> */}

        <Col xl={8} md={12} sm={12} xs={24}>
          <Account_Details_Insight
            accountBalInsight={accountBalInsight}
            isDarkMode={isDarkMode}
            isLoading={isLoading}
          />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <Account_Insight isDarkMode={isDarkMode} />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <Client_Vendor_Agent_Insight
            totalClVnCm={totalClVnCm}
            isDarkMode={isDarkMode}
            isLoading={isLoading}
          />
        </Col>
        <Col xl={8} md={12} sm={12} xs={24}>
          <InvoiceDetails isDarkMode={isDarkMode} />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <ExpenseTotal
            expenses={expenses}
            expenseLoading={expenseLoading}
            isDarkMode={isDarkMode}
          />
        </Col>

        <BestClient isDarkMode={isDarkMode} />
        <BestEmployees isDarkMode={isDarkMode} />
      </Row>
    </div>
  );
};

export default Dashboard;
