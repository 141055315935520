import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectEmployee } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';

import { useLazyGetSalesManWiseClQuery } from '../endpoints/SalesManWiseClientDueEndpoints';
import salesManCommissionColumn from '../utils/SalesManWiseClientDueColumn';

const SalesManWiseClientDue = () => {
  const [form] = Form.useForm();

  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });

  const [fetchSalesManCommission, { data, isLoading, isFetching }] =
    useLazyGetSalesManWiseClQuery();

  const onFinish = async (values: any) => {
    const body = {
      employee_id: values.employee_id,
    };
    await fetchSalesManCommission({
      employee_id: body.employee_id,
      query: `?&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.employee_id.toString(),
      query: `?&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: data?.count,
    },
  });

  const LastPage = Math.ceil(
    Number(data?.count || 0) / (tableParams?.pagination?.pageSize || 0)
  );

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data?.count },
    }));
  }, [data?.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      employee_id: values.employee_id,
      date_range: date_range,
    };

    fetchSalesManCommission({
      employee_id: body.employee_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.employee_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `SalesMan Collection and Due`,
    pageStyle: `@page {
      size: A4;
      margin: 0;
    }
    
    body {
      font-family: Arial, sans-serif;
      font-size: 12px;
    }
    
    .print-content {
      padding: 2000cm;
    }
    
    .print-content .print-element {
      margin-right: 1cm; /* Add horizontal gap between elements */
    }
  `,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      pagination={
        data?.count !== undefined && data?.count > 20
          ? tableParams.pagination
          : false
      }
      columns={salesManCommissionColumn(queryData)}
      loading={{
        spinning: isLoading || isFetching,
        indicator: loadingIndicator,
      }}
      dataSource={data?.data}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
      scroll={{ x: true }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Sales Man Wise Client Due' }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Sales Man Wise Client Due']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'sales/salesManCollectionAndDue',
              excelName: 'sales_man_collection_report',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <SelectEmployee
            name='employee_id'
            size={5}
            label='Select sales by'
            placeholder='Select sales by'
            showAll
            offDropDown
          />

          <Col lg={2}>
            <FormButton
              label='Search'
              icon
              textAlign='left'
              loading={isLoading}
            />
          </Col>
        </Row>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      <div>
        <FormHeaderTitle title='Sales Man Wise Client Due' />
        {jsx_com}
      </div>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default SalesManWiseClientDue;
