import { Button, Popconfirm, Space, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteAgentProfileMutation } from '../api/endpoints/agentProfileEndPoints';
import { AgentProfileDataType } from '../types/agentProfileTypes';

export const columns = (
  setEditInfo: ISetState<AgentProfileDataType | null>,
  showModal: () => void,
  pagination: {
    current: number;
    pageSize: number;
  },
  permission?: IPermission,
  checkButtonState?: boolean
): ColumnsType<AgentProfileDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useAppSelector(selectUser);
  const user_id = user?.user_id;

  const [deleteAgentProfile, { isLoading, isSuccess, isError }] =
    useDeleteAgentProfileMutation();

  const handleAgentsDelete = async (
    values: AgentProfileDataType,
    index: number
  ) => {
    if (values.has_trxn === 'false') {
      setColumnIndex(index);
      await deleteAgentProfile({
        ...values,
        agent_deleted_by: user?.user_id as number,
      });
    } else {
      message.error("You can't delete with transactions!");
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  return [
    {
      title: 'Sl.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
      render: (_, data) => (
        <Link to={`/reports/agent_reports?agent_id=${data.agent_id}`}>
          {data.agent_name}
        </Link>
      ),
    },
    {
      title: 'Mobile',
      dataIndex: 'agent_mobile',
      key: 'agent_mobile',
    },
    {
      title: 'Email',
      dataIndex: 'agent_email',
      key: 'agent_email',
    },
    {
      title: 'Commision Rate %',
      dataIndex: 'agent_commission_rate',
      key: 'agent_commission_rate',
      align: 'center',
      render: (_, record) => Number(record.agent_commission_rate),
    },
    {
      title: 'Action',
      key: 'action',

      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['create:any'] && (
            <Link
              to={`/agents/edit/${record.agent_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>Edit </Typography>
              </Button>
            </Link>
          )}

          {!checkButtonState &&
          permission?.['delete:any'] &&
          record.has_trxn == 'false' ? (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleAgentsDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                <Typography style={{ color: '#ffffff' }}>Delete</Typography>
              </Button>
            </Popconfirm>
          ) : (
            ''
          )}
        </Space>
      ),
    },
  ];
};
