import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, InputNumber, Row, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { perProps } from '../../../common/types/commonTypes';
import FileUpload from '../../../common/utils/FileUpload';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormInputTransaction,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useLazyGetSingleAccountQuery } from '../../Accounts/Api/Endpoints/accountsEndpoints';
import { useGetAccountByTypeQuery } from '../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../vendor/components/AccountLastBalanceInput';
import { layout } from '../../vendor/pages/AdvanceReturnEdit';
import { useCreateExpenseMutation } from '../Api/Endpoints/ExpenseEndpoints';
import Expense_Info_FormList from '../Component/Expense_info_FormList';
import { IAmountTotal, IExpenseFormData } from '../ExpenseTypes/Expensetypes';

type Props = {};

const AddExpense = ({ permission }: perProps) => {
  const isChanged = useRef(false);
  const user = useSelector(selectUser);
  const [balance, setBalance] = useState<number>();

  const vouchar_no = dayjs().unix();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [paymentMethod, setPaymentMethod] = useState(0);
  const [account, setAccount] = useState<string>();
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>();

  const expense_price = Form.useWatch('expense_details', form);
  const transactionCharge = Form.useWatch('charge_amount', form);
  let totalPrice: number = 0;

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );

  useEffect(() => {
    if (expense_price) {
      expense_price.forEach((element: IAmountTotal) => {
        if (element && element.expdetails_amount) {
          totalPrice = totalPrice + element.expdetails_amount;
        }
        form.setFieldValue('expense_total_amount', totalPrice);
      });
    }
    if (transactionCharge) {
      form.setFieldValue(
        'expense_total_amount',
        totalPrice + Number(transactionCharge)
      );
    }
  });

  useEffect(() => {
    form.setFieldValue('expense_vouchar_no', 'EXP' + vouchar_no.toString());
  }, []);

  useEffect(() => {
    if (isChanged.current) {
      form.setFieldsValue({ expense_accounts_id: undefined });
      setBalance(undefined);
      return;
    }
  }, [paymentMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  useEffect(() => {
    if (account) {
      fetchBalance(Number(account));
    }
  }, [account]);

  // form submit handle
  const [addExpense, { data, isError, isSuccess, isLoading }] =
    useCreateExpenseMutation();

  useEffect(() => {
    form.setFieldValue('expense_date', dayjs());
  }, []);

  const onFinish = async (values: IExpenseFormData) => {
    const formData = new FormData();

    formData.append('expense_created_by', String(user?.user_id));
    formData.append('expense_payment_type', values?.expense_payment_type);
    formData.append(
      'expense_date',
      dayjs(values?.expense_date).format('YYYY-MM-DD')
    );

    if (values.expense_accounts_id) {
      formData.append(
        'expense_accounts_id',
        values.expense_accounts_id?.toString()
      );
    }

    formData.append(
      'expense_total_amount',
      values?.expense_total_amount.toString()
    );

    if (values?.expense_note) {
      formData.append('expense_note', values?.expense_note);
    }

    if (values.expcheque_withdraw_date) {
      formData.append(
        'expcheque_withdraw_date',
        dayjs(values.expcheque_withdraw_date).format('YYYY-MM-DD')
      );
    }

    if (values.expense_cheque_no) {
      formData.append('expense_cheque_no', values.expense_cheque_no);
    }

    if (values.expcheque_bank_name) {
      formData.append('expcheque_bank_name', values.expcheque_bank_name);
    }

    formData.append('expense_details', JSON.stringify(values?.expense_details));

    if (values.expense_voucher_url_1) {
      formData.append(
        'expense_voucher_url_1',
        values?.expense_voucher_url_1[0]?.originFileObj
      );
    }

    if (values.expense_voucher_url_2) {
      formData.append(
        'expense_voucher_url_2',
        values?.expense_voucher_url_2[0]?.originFileObj
      );
    }

    if (values.card_trans_no) {
      formData.append('card_trans_no', String(values.card_trans_no));
    }
    if (values.card_trans_charge_type) {
      formData.append(
        'card_trans_charge_type',
        String(values.card_trans_charge_type)
      );
    }
    if (values.card_trans_charge) {
      formData.append('card_trans_charge', String(values.card_trans_charge));
    }

    await addExpense(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Expense Created Successfully');
      navigate(`/expense/view/${data?.expenseId}`, {
        state: location.pathname,
      });
    } else if (isError) {
      message.error('Expense cannot added!');
    }
  }, [isLoading]);
  return (
    <div>
      <BreadCrumb arrOfOption={['Create Expense']} />
      {permission?.['read:any'] && (
        <Link to='/expense'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Expense List
          </Button>
        </Link>
      )}

      <Form
        {...layout}
        labelAlign='left'
        form={form}
        onFinish={onFinish}
        autoComplete='off'
      >
        {/* <Card style={{ marginTop: '0.5rem' }}> */}
        <div className='border p-5' style={{ marginTop: '0.5rem' }}>
          <Row justify={'center'}>
            <Card
              style={{
                background: 'transparent',
                width: '80%',
              }}
            >
              {/* Expense information */}

              <Expense_Info_FormList
                form={form}
                expenseInitialValues={undefined}
              />
            </Card>
          </Row>

          <Row
            style={{ padding: '10px', margin: '20px' }}
            gutter={[16, 16]}
            justify='space-between'
          >
            <Col lg={10} md={12} sm={24} xs={24}>
              <SelectPaymentMethod
                required
                name='expense_payment_type'
                label='Payment Method '
                onChange={(value: number) => {
                  setPayMethodCatId(value);
                  setPaymentMethod(value);
                  if (!isChanged.current) isChanged.current = true;
                }}
                cheque
                size={24}
              />
              {Number(payMethodCatId) === 4 && (
                <>
                  <FormInputItem
                    label='Cheque No'
                    name='expense_cheque_no'
                    required
                    size={24}
                  />

                  <DateInput
                    name='expcheque_withdraw_date'
                    label='Withdraw Date'
                    required
                    size={24}
                  />

                  <FormInputItem
                    name={'expcheque_bank_name'}
                    label='Bank Name'
                    required
                    // disabled={paymentMethod || reason === 'EDIT' ? false : true}
                    onChange={(value) => setAccount(String(value))}
                    size={24}
                  />
                </>
              )}
              {Number(payMethodCatId) !== 4 && (
                <Col span={6} xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Available Balance' required>
                    <InputNumber
                      style={{ width: '100%' }}
                      readOnly
                      value={balance}
                      placeholder='Select your account'
                    />
                  </Form.Item>
                </Col>
              )}
              {Number(payMethodCatId) === 3 && (
                <>
                  <FormInputItem
                    name={'charge_amount'}
                    label='Transaction Charge :'
                    size={24}
                  />

                  <FormInputItem
                    label='Receipt/Trans No:'
                    name='trans_no'
                    size={24}
                  />
                </>
              )}

              {Number(payMethodCatId) === 5 && (
                <>
                  <FormInputTransaction
                    chargeTypeName='card_trans_charge_type'
                    name='card_trans_charge'
                    label='Transaction charge '
                    size={24}
                  />
                  <FormInputItem
                    name='card_trans_no'
                    label='Receipt/Trans No : '
                    size={24}
                  />
                </>
              )}
              <DateInput
                label='Date'
                name='expense_date'
                required
                size={24}
                mdSize={24}
                smSize={24}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  gap: 10,
                }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    labelAlign='left'
                    name={'expense_voucher_url_1'}
                    valuePropName='upload_photo'
                  >
                    <FileUpload
                      size={24}
                      smSize={24}
                      mdSize={24}
                      name='expense_voucher_url_1'
                      msg='Voucher image 1'
                      noBorder
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
                  <Form.Item
                    name={'expense_voucher_url_2'}
                    valuePropName='upload_photo'
                  >
                    <FileUpload
                      size={24}
                      smSize={24}
                      mdSize={24}
                      name='expense_voucher_url_2'
                      msg='Voucher image 2'
                      noBorder
                    />
                  </Form.Item>
                </Col>
              </div>
            </Col>

            <Col lg={10} md={12} sm={24} xs={24}>
              {Number(payMethodCatId) !== 4 && (
                <>
                  <AccountLastBalanceInput
                    accountsLastBalance={accounts ? accounts.data : []}
                    name='expense_accounts_id'
                    label='Account:'
                    // disabled={paymentMethod || reason === 'EDIT' ? false : true}
                    onSelect={(value: number) => setAccount(String(value))}
                    required
                    size={24}
                  />
                </>
              )}
              <Col span={5} xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label='Total Amount'
                  name='expense_total_amount'
                  rules={[
                    { required: true, message: 'Amount is required' },
                    {
                      validator(_, value) {
                        if (value && balance) {
                          if (Number(balance) < value) {
                            return Promise.reject(
                              'Expense amount cannot be more than balance!!'
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    readOnly
                    style={{ width: '100%' }}
                    max={10}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <TextAreaInput label='Note' name='expense_note' size={24} />{' '}
            </Col>
          </Row>

          <FormButton label='Create Expense' loading={isLoading} />
        </div>

        {isLoading && <LoadingIndicator />}
      </Form>
    </div>
  );
};

export default AddExpense;
