import { List } from 'antd';
import { Fixed2, separateClient } from '../../../common/utils/common.utils';
import DashboardTitle from './DashboardTitle';
import { Link } from 'react-router-dom';

const BestClientMonth = ({ data, isDarkMode }: any) => {
  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle title='BEST CLIENT OF THE MONTH' />

      <div
        className='custom-scrollbar'
        style={{
          overflow: 'auto',
          padding: '10px 20px',
          height: 280,
        }}
      >
        <List
          itemLayout='horizontal'
          dataSource={data}
          renderItem={(item: any) => {
            const { client_id, combined_id } = separateClient(item.comb_client);

            return (
              <Link
                to={
                  client_id
                    ? `/clients/details/${item.comb_client}`
                    : `/combineClients/details/${combined_id}`
                }
              >
                <List.Item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    padding: '10px',
                  }}
                >
                  <List.Item.Meta
                    title={item.client_name}
                    description={`Last Balance: ${Fixed2(
                      item.client_last_balance
                    ).toLocaleString()}`}
                  />
                  <div style={{ color: '#6c757d', fontSize: 12, flex: 0.8 }}>
                    <div>
                      Total Sales:{' '}
                      {Fixed2(item.invoice_net_total).toLocaleString()}
                    </div>
                    <div>Mobile: {item.client_mobile}</div>
                    <div>Email: {item.client_email}</div>
                  </div>
                </List.Item>
              </Link>
            );
          }}
        />
      </div>
    </div>
  );
};

export default BestClientMonth;
