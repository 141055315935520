import { FormInstance } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  Fixed2,
  getItaCodeByAirportIds,
} from '../../../../common/utils/common.utils';
import { IAirport } from '../../../Configuration/airport/types/AirportTypes';
import {
  AirTicketInvoiceData,
  AirTicket_InvoicePostType,
  AirTicket_ticket_information,
} from '../../types/invoiceAirTicketTypes';

export const updateInvoiceAirticketAndPreview = (
  userInNumber: number,
  setIsPreviewModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  invoiceExtraValue: FormInstance<any>
) => {
  const { airticketInvoiceId } = useParams();
  const navigate = useNavigate();
  const extraData = invoiceExtraValue.getFieldsValue([
    'invoice_show_unit',
    'invoice_show_discount',
    'invoice_show_prev_due',
    'invoice_service_charge',
    'invoice_note',
  ]);
  const tempDataJSON = localStorage.getItem('tbl_airTicket_data_server');
  const tempDataParse: AirTicket_InvoicePostType =
    tempDataJSON && JSON.parse(tempDataJSON);

  const invoice_info_temp = tempDataParse.invoice_info;

  if (tempDataParse && airticketInvoiceId) {
    const tempData: AirTicket_InvoicePostType = {
      invoice_info: {
        invoice_combclient_id: invoice_info_temp.invoice_combclient_id,
        invoice_created_by: userInNumber,
        invoice_no: invoice_info_temp.invoice_no,
        invoice_note: extraData.invoice_note || undefined,
        invoice_sales_date: dayjs(invoice_info_temp.invoice_sales_date).format(
          'DD-MM-YYYY'
        ),
        invoice_sales_man_id: Fixed2(invoice_info_temp.invoice_sales_man_id),
        invoice_service_charge: Fixed2(extraData.invoice_service_charge) || 0,
        invoice_show_prev_due: extraData.invoice_show_prev_due,
        invoice_show_unit: extraData.invoice_show_unit,
        invoice_show_discount: extraData.invoice_show_discount,

        invoice_net_total:
          getSubTotalAirTicket(tempDataParse.ticketInfo) +
          (Fixed2(extraData.invoice_service_charge) || 0),
        invoice_sub_total: getSubTotalAirTicket(tempDataParse.ticketInfo),
        invoice_total_profit: getSubTotalAirTicket(tempDataParse.ticketInfo),
        invoice_total_vendor_price: getTotalVendorPriceInvoice(
          tempDataParse.ticketInfo
        ),
      },
      ticketInfo: tempDataParse.ticketInfo.map((values) => {
        return {
          ticket_details: {
            airticket_gross_fare: Fixed2(
              values.ticket_details.airticket_gross_fare
            ),
            airticket_base_fare: Fixed2(
              values.ticket_details.airticket_base_fare
            ),
            airticket_ait: Fixed2(values.ticket_details.airticket_ait),
            airticket_ait_from: values.ticket_details.airticket_ait_from,
            airticket_commission_percent: Fixed2(
              values.ticket_details.airticket_commission_percent
            ),
            airticket_commission_percent_total: Fixed2(
              values.ticket_details.airticket_commission_percent_total
            ),

            airticket_airline_id: Fixed2(
              values.ticket_details.airticket_airline_id
            ),
            airticket_client_price:
              Fixed2(values.ticket_details.airticket_client_price) || 0,
            airticket_extra_fee:
              Fixed2(values.ticket_details.airticket_extra_fee) || 0,
            airticket_issue_date: values.ticket_details.airticket_issue_date
              ? dayjs(
                  values.ticket_details.airticket_issue_date,
                  'DD-MM-YYYY'
                ).format('DD-MM-YYYY')
              : undefined,
            airticket_journey_date: values.ticket_details.airticket_journey_date
              ? dayjs(
                  values.ticket_details.airticket_journey_date,
                  'DD-MM-YYYY'
                ).format('DD-MM-YYYY')
              : undefined,
            airticket_pnr: values.ticket_details?.airticket_pnr
              ? values.ticket_details.airticket_pnr
              : undefined,
            airticket_profit: Fixed2(values.ticket_details.airticket_profit),
            airticket_purchase_price: Fixed2(
              values.ticket_details.airticket_purchase_price
            ),
            airticket_return_date: values.ticket_details.airticket_return_date
              ? dayjs(
                  values.ticket_details.airticket_return_date,
                  'DD-MM-YYYY'
                ).format('DD-MM-YYYY')
              : undefined,
            airticket_route_or_sector:
              values.ticket_details?.airticket_route_or_sector,
            airticket_ticket_no: values.ticket_details.airticket_ticket_no,
            airticket_comvendor: values.ticket_details.airticket_comvendor,
            airticket_discount_total: Fixed2(
              values.ticket_details.airticket_discount_total
            ),
            airticket_discount_type:
              values.ticket_details.airticket_discount_type,
            airticket_other_bonus_total: Fixed2(
              values.ticket_details.airticket_other_bonus_total
            ),
            airticket_other_bonus_type:
              values.ticket_details.airticket_other_bonus_type,

            // difference with nonComission
            // invoice_sales_date: Date | string,
            airticket_tax:
              Fixed2(values.ticket_details.airticket_tax) || undefined,
            airticket_net_commssion: values.ticket_details
              .airticket_net_commssion
              ? Fixed2(values.ticket_details.airticket_net_commssion)
              : 0,
            airticket_gds_id: values.ticket_details.airticket_gds_id,
            airticket_segment: values.ticket_details.airticket_segment
              ? Fixed2(values.ticket_details.airticket_segment)
              : undefined,
            airticket_other_expense:
              Fixed2(values.ticket_details.airticket_other_expense) ||
              undefined,
            airticket_vat: values.ticket_details.airticket_vat
              ? Fixed2(values.ticket_details.airticket_vat)
              : undefined,
            airticket_bd_charge: Fixed2(
              values?.ticket_details?.airticket_bd_charge
            ),
            airticket_es_charge: Fixed2(
              values?.ticket_details?.airticket_es_charge
            ),
            airticket_ut_charge: Fixed2(
              values?.ticket_details?.airticket_ut_charge
            ),
            airticket_xt_charge: Fixed2(
              values?.ticket_details?.airticket_xt_charge
            ),
          },
          pax_passport: values?.pax_passport?.map((item) => ({
            passport_name: item?.passport_name,
            passport_person_type: item?.passport_person_type,
            passport_mobile_number: item?.passport_mobile_no,
            passport_mobile_no: item?.passport_mobile_no,
            passport_email: item?.passport_email || undefined,
            passport_date_of_birth: item?.passport_date_of_birth
              ? dayjs(item.passport_date_of_birth, 'DD-MM-YYYY').format(
                  'DD-MM-YYYY'
                )
              : undefined,
            passport_date_of_expire: item.passport_date_of_expire
              ? dayjs(item.passport_date_of_expire, 'DD-MM-YYYY').format(
                  'DD-MM-YYYY'
                )
              : undefined,
            passport_date_of_issue: item.passport_date_of_issue
              ? dayjs(item.passport_date_of_issue, 'DD-MM-YYYY').format(
                  'DD-MM-YYYY'
                )
              : undefined,
            passport_nid_no: String(item.passport_nid_no) || '',
            passport_passport_no: item.passport_passport_no || '',
          })),
          flight_details:
            values.flight_details &&
            values.flight_details.map((el) => {
              return {
                fltdetails_from_airport_id: el.fltdetails_from_airport_id,
                fltdetails_to_airport_id: el.fltdetails_to_airport_id,
                fltdetails_airline_id: Number(el.fltdetails_airline_id),
                fltdetails_flight_no: Number(el.fltdetails_flight_no),
                fltdetails_fly_date: el.fltdetails_fly_date
                  ? dayjs(el.fltdetails_fly_date).format('DD-MM-YYYY')
                  : undefined,
                fltdetails_departure_time: el.fltdetails_departure_time
                  ? dayjs(el.fltdetails_departure_time, 'HH:mm:ss').format(
                      'HH:mm:ss'
                    )
                  : undefined,
                fltdetails_arrival_time: el.fltdetails_arrival_time
                  ? dayjs(el.fltdetails_arrival_time, 'HH:mm:ss').format(
                      'HH:mm:ss'
                    )
                  : undefined,
              };
            }),
        };
      }),
    };
    setIsPreviewModalVisible(true);
    axios
      .patch(
        `/invoice-air-ticket/update-invioce/${airticketInvoiceId}`,
        tempData
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('tbl_airTicket_data_server');
          toasterNotification('success', 'Invoice Updated');
          localStorage.removeItem('tbl_atk_iid');
          navigate('/airticket');
        }
      })
      .catch((err) => toasterNotification('error', err.message));
  }
};

export const getSubTotalAirTicket = (
  allInvoices: AirTicket_ticket_information[]
) => {
  let subTotal = 0;
  allInvoices.forEach((el) => {
    subTotal = subTotal + Fixed2(el.ticket_details.airticket_profit);
  });
  return subTotal;
};

export const getTotalVendorPriceInvoice = (
  allNonComInvoices: AirTicket_ticket_information[]
) => {
  let vendorTotal = 0;
  allNonComInvoices.forEach((el) => {
    vendorTotal =
      vendorTotal + Fixed2(el.ticket_details.airticket_purchase_price);
  });
  return vendorTotal;
};

export const getEditDataToEditableFormat = (
  invoices: AirTicket_InvoicePostType,
  selectAirport?: IAirport[]
) => {
  const data: AirTicketInvoiceData[] = invoices?.ticketInfo?.map((item) => {
    const invoiceInfo = invoices.invoice_info;

    return {
      airticket_is_refund: item?.ticket_details?.airticket_is_refund,
      invoice_combclient_id: invoiceInfo.invoice_combclient_id,
      invoice_walking_customer_name: invoiceInfo.invoice_walking_customer_name,
      invoice_sales_man_id: invoiceInfo.invoice_sales_man_id,
      invoice_no: invoiceInfo.invoice_no,
      // invoiseDate: string;
      invoice_sales_date: dayjs(invoiceInfo.invoice_sales_date),
      invoice_agent_id: invoiceInfo.invoice_agent_id || undefined,
      agent_commission_rate: invoiceInfo.invoice_agent_com_amount || undefined,
      // end top section

      airticket_ticket_no: item.ticket_details.airticket_ticket_no,
      airticket_gross_fare: Fixed2(item.ticket_details.airticket_gross_fare),
      airticket_base_fare: Fixed2(item.ticket_details.airticket_base_fare),
      airticket_comvendor: item.ticket_details.airticket_comvendor,
      airticket_airline_id: item?.ticket_details?.airticket_airline_id,
      airticket_commission_percent:
        item?.ticket_details?.airticket_commission_percent,

      // if commission set manually then we set this value named airticket_commission_percent_from_server
      airticket_commission_percent_from_server:
        item?.ticket_details?.airticket_commission_percent,

      airticket_ait: Fixed2(item?.ticket_details?.airticket_ait),
      airticket_ait_from: item?.ticket_details?.airticket_ait_from,
      airticket_tax: Fixed2(item?.ticket_details?.airticket_tax) || undefined,
      airticket_commission_percent_total: Fixed2(
        item?.ticket_details?.airticket_commission_percent_total
      ),
      airticket_commission_percent_total_server: Fixed2(
        item?.ticket_details?.airticket_commission_percent_total
      ),
      airticket_discount_type: item?.ticket_details?.airticket_discount_type,
      airticket_discount_total:
        Fixed2(item?.ticket_details?.airticket_discount_total) || undefined,
      airticket_extra_fee:
        Fixed2(item?.ticket_details?.airticket_extra_fee) || undefined,
      airticket_other_bonus_total:
        Fixed2(item?.ticket_details?.airticket_other_bonus_total) || undefined,
      airticket_other_bonus_type:
        item?.ticket_details?.airticket_other_bonus_type,
      airticket_other_expense:
        Fixed2(item?.ticket_details?.airticket_other_expense) || undefined,
      airticket_vat: Fixed2(item?.ticket_details?.airticket_vat) || undefined,
      invoice_created_by: invoiceInfo.invoice_created_by,

      //   readonly values
      airticket_client_price: Fixed2(
        item?.ticket_details?.airticket_client_price
      ),
      airticket_purchase_price: Fixed2(
        item?.ticket_details?.airticket_purchase_price
      ),
      airticket_profit: Fixed2(item?.ticket_details?.airticket_profit),
      //   end readonly values

      airticket_route_or_sector: item?.ticket_details?.airticket_route_or_sector
        ?.length
        ? getItaCodeByAirportIds(
            [...item?.ticket_details?.airticket_route_or_sector] as string[],
            selectAirport
          )
        : undefined,
      airticket_route_or_sector_id:
        item?.ticket_details?.airticket_route_or_sector,

      airticket_pnr: item?.ticket_details?.airticket_pnr || undefined,
      airticket_net_commssion:
        Fixed2(item?.ticket_details?.airticket_net_commssion) || undefined,

      airticket_issue_date: item?.ticket_details?.airticket_issue_date
        ? dayjs(item?.ticket_details?.airticket_issue_date)
        : undefined,
      airticket_journey_date: item?.ticket_details?.airticket_journey_date
        ? dayjs(item?.ticket_details?.airticket_journey_date)
        : undefined,
      airticket_return_date: item?.ticket_details?.airticket_return_date
        ? dayjs(item?.ticket_details?.airticket_return_date)
        : undefined,

      airticket_gds_id: item?.ticket_details?.airticket_gds_id,
      airticket_segment: item?.ticket_details?.airticket_segment,
      airticket_classes: item?.ticket_details?.airticket_classes,
      airticket_ticket_type: item?.ticket_details?.airticket_ticket_type,

      // end ticket details

      // start pax and passport details
      pax_passport: item?.pax_passport?.length
        ? item?.pax_passport?.map((item) => ({
            ...item,
            passport_date_of_birth: item.passport_date_of_birth
              ? dayjs(item.passport_date_of_birth)
              : undefined,
            passport_date_of_expire: item.passport_date_of_expire
              ? dayjs(item.passport_date_of_expire)
              : undefined,
            passport_date_of_issue: item.passport_date_of_issue
              ? dayjs(item.passport_date_of_issue)
              : undefined,
            dailCode: item.passport_mobile_no
              ? item.passport_mobile_no.split('-')[0]
              : undefined,
            passport_mobile_no: item.passport_mobile_no
              ? item.passport_mobile_no.split('-')[1]
              : undefined,
          }))
        : [{}],
      // end pax and passport details

      // start Flight Details
      flight_details: item?.flight_details?.length
        ? item?.flight_details?.map((item) => ({
            ...item,
            fltdetails_arrival_time: item.fltdetails_arrival_time
              ? dayjs(item.fltdetails_arrival_time, 'HH:mm:ss')
              : undefined,
            fltdetails_departure_time: item.fltdetails_departure_time
              ? dayjs(item.fltdetails_departure_time, 'HH:mm:ss')
              : undefined,
            fltdetails_fly_date: item.fltdetails_fly_date
              ? dayjs(item.fltdetails_fly_date)
              : undefined,
            fltdetails_from_airport_id: item?.fltdetails_from_airport_id
              ? item?.fltdetails_from_airport_id
              : undefined,
            fltdetails_to_airport_id: item?.fltdetails_to_airport_id
              ? item?.fltdetails_to_airport_id
              : undefined,
          }))
        : [{}],

      // extra values
      invoice_show_unit: invoiceInfo?.invoice_show_unit,
      invoice_show_discount: invoiceInfo?.invoice_show_discount,
      invoice_show_prev_due: invoiceInfo?.invoice_show_prev_due,
      invoice_service_charge:
        Fixed2(invoiceInfo?.invoice_service_charge) || undefined,
      invoice_reference: invoiceInfo.invoice_reference || undefined,
      invoice_note: invoiceInfo?.invoice_note || undefined,

      invoice_discount: Fixed2(invoiceInfo?.invoice_discount) || undefined,
      invoice_vat: Fixed2(invoiceInfo?.invoice_vat) || undefined,

      airticket_bd_charge:
        Fixed2(item?.ticket_details?.airticket_bd_charge) || undefined,
      airticket_xt_charge:
        Fixed2(item?.ticket_details?.airticket_xt_charge) || undefined,
      airticket_ut_charge:
        Fixed2(item?.ticket_details?.airticket_ut_charge) || undefined,
      airticket_es_charge:
        Fixed2(item?.ticket_details?.airticket_es_charge) || undefined,

      airticket_e5_charge:
        Fixed2(item?.ticket_details?.airticket_e5_charge) || undefined,
      airticket_g4_charge:
        Fixed2(item?.ticket_details?.airticket_g4_charge) || undefined,
      airticket_ow_charge:
        Fixed2(item?.ticket_details?.airticket_ow_charge) || undefined,
      airticket_p7_charge:
        Fixed2(item?.ticket_details?.airticket_p7_charge) || undefined,
      airticket_p8_charge:
        Fixed2(item?.ticket_details?.airticket_p8_charge) || undefined,
      airticket_pz_charge:
        Fixed2(item?.ticket_details?.airticket_pz_charge) || undefined,
      airticket_qa_charge:
        Fixed2(item?.ticket_details?.airticket_qa_charge) || undefined,
      airticket_r9_charge:
        Fixed2(item?.ticket_details?.airticket_r9_charge) || undefined,
    };
  });

  return data;
};
