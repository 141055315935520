import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import { getAirportByItaCode } from '../../../common/utils/common.utils';
import postDateFormat from '../../../common/utils/postDateFormat';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceMoneyReceipt, {
  InvoiceDisPrevRadioButton,
} from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import NewAddUmrahFormHotelInfo from '../Components/NewAddUmrahFormHotelInfo';
import NewAddUmrahFormTop from '../Components/NewAddUmrahFormTop';
import NewAddUmrahFromPassengerInfo from '../Components/NewAddUmrahFromPassengerInfo';
import {
  InvoiceUmrahFormatedDataType,
  InvoiceUmrahType,
} from '../Types/InvoiceUmrahTypes';
import { useInvoiceUmrahPostMutation } from '../api/endpoints/invoiceUmrahEndpoints';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';

type Props = {};
const NewInvoiceAddUmrah = ({}: Props) => {
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  // INVOICE UMRAH POST
  const [
    invoiceUmrahPost,
    { isSuccess: invoiceUmrahPostIsSuccess, isLoading: postLoading },
  ] = useInvoiceUmrahPostMutation();
  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (invoiceUmrahPostIsSuccess) {
      toasterNotification('success', 'Successfully Added');
      form.resetFields();
      timeoutId = setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // navigate('/invoiceumrah');
        invoiceNoRefetch();
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [invoiceUmrahPostIsSuccess]);

  const onFinish = (values: InvoiceUmrahType) => {
    const formatedData: InvoiceUmrahFormatedDataType = {
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_haji_group_id: values.invoice_haji_group_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_service_charge: values.invoice_service_charge,
      invoice_vat: values.invoice_vat,
      invoice_agent_id: values?.invoice_agent_id,
      invoice_agent_com_amount: values?.invoice_agent_com_amount,
      invoice_no: values.invoice_no,
      invoice_sales_date: dayjs(values.invoice_sales_date).format('YYYY-MM-DD'),
      invoice_due_date: values.invoice_due_date
        ? dayjs(values.invoice_due_date).format('YYYY-MM-DD')
        : undefined,
      invoice_no_passenger: values.invoice_no_passenger,
      receipt_payment_type: values.receipt_payment_type,
      invoice_sub_total: values.invoice_sub_total,
      invoice_discount: values.invoice_discount,
      invoice_net_total: values.invoice_net_total,
      invoice_note: values.invoice_note,
      invoice_created_by: user?.user_id as number,

      invoice_show_prev_due: values.invoice_show_prev_due,
      invoice_show_discount: values.invoice_show_discount,

      passenget_info: values.passenget_info?.map((el: any) => {
        delete el?.passport_email;
        delete el?.passport_name;
        delete el?.dialCode;
        delete el?.passport_mobile_no;
        delete el?.passport_date_of_expire;
        delete el?.passport_date_of_issue;

        return {
          ...el,
          passenger_tracking_number: el.passenger_tracking_number,
          passenger_passport_id: el.passenger_passport_id,

          ticket_route: el?.ticket_route
            ? (getAirportByItaCode(
                el?.ticket_route as string,
                selectAirport
              ) as string[])
            : undefined,
        };
      }),

      hotel_information:
        values.hotel_information && values.hotel_information[0].hotel_name
          ? values.hotel_information?.map((el: any) => {
              delete el.hotel_room_type;
              return {
                ...el,
                hotel_check_in_date: postDateFormat(el.hotel_check_in_date),
                hotel_check_out_date: postDateFormat(el.hotel_check_out_date),
              };
            })
          : undefined,
      billing_information: values.billing_information.map((el: any) => {
        delete el?.billing_total_cost_price;
        return {
          ...el,
        };
      }),

      money_receipt: values?.receipt_payment_type
        ? {
            receipt_total_amount: values.receipt_total_amount,
            receipt_payment_type: values.receipt_payment_type,
            account_id: values.account_id,
            charge_amount: values.charge_amount,
            cheque_bank_name: values.cheque_bank_name,
            cheque_number: values.cheque_number,
            cheque_withdraw_date: dayjs(values.cheque_withdraw_date).format(
              'YYYY-MM-DD'
            ),
            receipt_money_receipt_no: values.receipt_money_receipt_no,
            receipt_note: values.receipt_note,
            trans_no: values.trans_no,
            receipt_payment_date: dayjs(values.receipt_payment_date).format(
              'YYYY-MM-DD'
            ),
          }
        : undefined,
    };

    checkCreditLimit({
      amount: formatedData.invoice_net_total,
      combClient: formatedData.invoice_combclient_id.toString(),
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        invoiceUmrahPost({ body: formatedData }).then((res: any) => {
          if (res.data.data.invoice_id)
            updateAndPrev
              ? navigate(`/invoiceumrah/details/${res.data.data.invoice_id}`, {
                  state: location,
                })
              : navigate('/invoiceumrah');
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };
  const onFinishFailed = (values: any) => {
    message.error(values.errorFields.length + ' Required fields!');
  };

  const {
    data: invoiceNo,
    refetch: invoiceNoRefetch,
    isLoading,
  } = useGetInvoiceNoQuery('IU');

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [location]);

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
      invoice_show_prev_due: 0,
      invoice_show_discount: 1,
    });
  }, [invoiceNo]);

  const invoice_net_total = useWatch('invoice_net_total', form);

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice (Umrah)']} />

      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {postLoading || isLoading ? <LoadingIndicator /> : ''}

        <NewAddUmrahFormTop
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        <NewAddUmrahFromPassengerInfo form={form} />
        <NewAddUmrahFormHotelInfo form={form} />
        <InvoiceBillingInfo
          form={form}
          setProductId={118}
          conditionalCostVendor
        />

        <InvoiceVisaSubTotalForm form={form} />

        <InvoiceMoneyReceipt
          form={form}
          netTotal={Number(invoice_net_total || 0)}
        />

        <InvoiceDisPrevRadioButton form={form} style={{ marginTop: -30 }} />

        {/* @AIRTICKET EIDT BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={updateAndPrev && (postLoading || limiLoading)}
              label='CREATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (postLoading || limiLoading)}
              label='CREATE & PREVIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NewInvoiceAddUmrah;
