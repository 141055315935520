import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

export interface IClientAitReportDataType {
  airticket_id: number;
  airticket_sales_date: string;
  airticket_client_id: string;
  airticket_combined_id: string;
  invoice_no: string;
  invoice_id: string;
  invoice_category_id: number;
  client_name: string;
  airticket_ticket_no: string;
  airticket_gross_fare: string;
  airticket_ait: string;
  airticket_client_price: string;
}

export const ClientAitReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all AitReport
    getClientAitReport: build.query<
      HTTPResponse<IClientAitReportDataType[]>,
      {
        client_id: string;
        query: string;
      }
    >({
      query: ({ client_id, query }) => ({
        url: `/report/client-ait/${client_id}${query}`,
      }),
      // providesTags: [{ type: 'GDSReport', id: AITREPORT }],
    }),
  }),
});

export const { useLazyGetClientAitReportQuery } = ClientAitReportEndpoints;
