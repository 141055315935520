import React from 'react';
import { useBestemployeeQuery } from '../Api/Endpoints/DashboardEndpoints';
import { Col, Row } from 'antd';
import BestEmployeeMonth from './BestEmployeeMonth';
import BestEmployeeYear from './BestEmployeeYear';

const BestEmployees = ({ isDarkMode }: any) => {
  const { data } = useBestemployeeQuery();
  return (
    <>
      <Col xs={24} sm={24} md={24} xl={8}>
        <BestEmployeeMonth data={data?.data?.yearly} isDarkMode={isDarkMode} />
      </Col>
      <Col xs={24} sm={24} md={24} xl={8}>
        <BestEmployeeYear data={data?.data?.yearly} isDarkMode={isDarkMode} />
      </Col>
    </>
  );
};

export default BestEmployees;
