import { Button, message, Popconfirm, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import {
  crud,
  IPermission,
  ISetState,
} from '../../../../common/types/commonTypes';
import { useDeleteAirportMutation } from '../endpoints/airportEndpoints';
import { AirportDataType } from '../types/AirportTypes';

type Props = {
  setEditInfo: ISetState<AirportDataType | null>;
  showModal: () => void;
  pagination: {
    current: number;
    pageSize: number;
  };
  permission?: IPermission;
};

export const airportColumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<AirportDataType> => {
  const user = useSelector(selectUser);

  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const handleClick = (record: AirportDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteAirport, { isError, isSuccess, isLoading }] =
    useDeleteAirportMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Airport Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: AirportDataType, index: number) => {
    setColumnIndex(index);
    await deleteAirport({
      airline_id: values.airline_id as number,
      deleted_by: user?.user_id as number,
    });
  };
  return [
    {
      title: 'Sl.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Country Name',
      dataIndex: 'country_name',
      key: 'key',
    },
    {
      title: 'Airport Name',
      dataIndex: 'airline_airport',
      key: 'airline_airport',
    },
    {
      title: 'Airport Code',
      dataIndex: 'airline_iata_code',
      key: 'airline_iata_code',
    },
    // {
    //   title: 'Action',
    //   key: 'action',

    //   render: (_, record, index) => (
    //     <Space size='small'>
    //       {permission?.[crud.update] && record.agency_id && (
    //         <Button
    //           size='small'
    //           type='primary'
    //           onClick={() => handleClick(record)}
    //         >
    //           Edit
    //         </Button>
    //       )}

    //       {permission?.[crud.delete] && record.agency_id ? (
    //         <Popconfirm
    //           title='Sure to delete?'
    //           onConfirm={() => handleDelete(record, index)}
    //         >
    //           <Button
    //             size='small'
    //             type='primary'
    //             danger
    //             loading={columnIndex === index && true}
    //           >
    //             Delete
    //           </Button>
    //         </Popconfirm>
    //       ) : (
    //         ''
    //       )}
    //     </Space>
    //   ),
    // },
  ];
};
