import { Button, Drawer, Form, message, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useCreatePayrollAdvanceMutation } from '../api/endpoints/AdvancePayrollEndpoints';
import { IPayrollAdvanceCreate } from '../types/PayrollTypes';
import PayrollAdvanceForm from './PayrollAdvanceForm';

type Props = {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
};

const AddEmployeeAdvance = ({ drawerOpen, setDrawerOpen }: Props) => {
  const [form] = useForm();

  const [createPayAdv, { isLoading, isSuccess, isError }] =
    useCreatePayrollAdvanceMutation();

  useEffect(() => {
    form.setFieldsValue({ tea_date: dayjs() });
  }, []);

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  const handleFinished = (e: IPayrollAdvanceCreate) => {
    createPayAdv(e);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Payroll advance added successfully');
      onClose();
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Drawer
      width={450}
      title='Add Payroll Advance'
      onClose={onClose}
      open={drawerOpen}
    >
      <Form form={form} layout='vertical' onFinish={handleFinished}>
        <PayrollAdvanceForm form={form} />

        <Row justify={'end'}>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              {'Submit'}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddEmployeeAdvance;
