import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
} from '../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import { useCreateQuotationMutation } from '../Api/Endpoints/QuotationEndpoints';
import Billing_Info_FormList from '../Components/Billing_Info_FormList';
import {
  IQuotationAddFormData,
  QuotationBillType,
} from '../QuotationTypes/QuotationTypes';
import JoditEditor from 'jodit-react';

type Props = {};

const NewQuotation = (props: Props) => {
  const { data } = useGetInvoiceNoQuery('QT');
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [discount, setDiscount] = useState<number | null>();
  const billing_price = Form.useWatch('bill_info', form);
  const editor = useRef(null);
  const [content, setContent] = useState('');

  let totalPrice: number = 0;

  useEffect(() => {
    if (billing_price) {
      billing_price.forEach((element: QuotationBillType, index: number) => {
        let tempTotalPrice = 0;
        if (element) {
          tempTotalPrice = element.quantity * element.unit_price;
          totalPrice = totalPrice + tempTotalPrice;
        }
      });
    }
  });

  useEffect(() => {
    form.setFieldValue('q_number', data?.data);
  }, [data]);

  useEffect(() => {
    if (totalPrice || totalPrice === 0) {
      form.setFieldValue('sub_total', totalPrice);
    }
    if (!discount && totalPrice) {
      form.setFieldValue('net_total', totalPrice);
    }
    if (discount && totalPrice) {
      form.setFieldValue('net_total', totalPrice - discount);
    }
  }, [billing_price, discount]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  const [addQuotation, { isError, isLoading }] = useCreateQuotationMutation();

  const onFinish = async (values: IQuotationAddFormData) => {
    const body: IQuotationAddFormData = {
      ...values,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      created_by: user?.user_id as number,
      discount: values.discount == null ? 0 : values.discount,
      note: values.note == null ? '' : values.note,
    };

    const res = await addQuotation(body).unwrap();
    if (res.data?.quotationId) {
      navigate(`/quotation/details/${res.data?.quotationId}`, {
        state: location.pathname,
      });
    } else {
      navigate('/quotation');
    }
  };

  const color = localStorage.getItem('theme') as 'defaultTheme' | 'darkTheme';

  const config = {
    theme: color === 'darkTheme' ? 'default' : 'dark',
    height: 400,
    style: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    toolbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#000000',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    statusbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Create Quotation']} />

      <Space>
        <Link to='/quotation'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Quotation List
          </Button>
        </Link>
      </Space>

      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        style={{ marginTop: 40 }}
      >
        <Card className='border'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <SelectClients
              name='client_id'
              label='Select Client'
              required
              size={8}
            />
            <Col span={8} xs={24} sm={24} md={24} lg={8}>
              <Form.Item label='Quotation No' name='q_number'>
                <Input readOnly />
              </Form.Item>
            </Col>

            <DateInput
              smSize={24}
              mdSize={24}
              label='Quotation Date'
              name='date'
              required
            />
          </Row>
        </Card>
        <Billing_Info_FormList form={form} billInitialValues={undefined} />
        <div className='mb-10 border p-10'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col span={5} xs={24} sm={24} md={24} lg={6}>
              <Form.Item
                label='Sub Total'
                name='sub_total'
                rules={[{ required: true, message: 'Sub Total is required' }]}
              >
                <InputNumber
                  readOnly
                  placeholder='Sub Total'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={5} xs={24} sm={24} md={24} lg={6}>
              <Form.Item
                label='Discount'
                name='discount'
                rules={[
                  {
                    validator(_, value) {
                      if (value) {
                        if (totalPrice < value) {
                          return Promise.reject(
                            'Discount cannot be more than sub total'
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  onChange={(e: number | null) => {
                    setDiscount(Number(e));
                  }}
                  placeholder='Discount'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={5} xs={24} sm={24} md={24} lg={6}>
              <Form.Item label='Net Total' name='net_total'>
                <InputNumber
                  readOnly
                  placeholder='Net Total'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={5} xs={24} sm={24} md={24} lg={6}>
              <Form.Item label='Due Amount' name='due_amount'>
                <InputNumber
                  readOnly
                  placeholder='Due Amount'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col span={10} xs={24} sm={24} md={24} lg={12}>
              <Form.Item label='Note' name='note'>
                <JoditEditor
                  config={config}
                  ref={editor}
                  value={content}
                  onBlur={(newContent) => {
                    setContent(newContent);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <FormButton label='Create Quotation' loading={isLoading} />
        </div>
      </Form>

      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default NewQuotation;
