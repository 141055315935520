import { Button, Col, Form, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  DateInput,
  FormInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import { SelectPassport } from '../../../components/common/FormItem/SelectCustomFeilds';
import { useCheckTrackingAndSerialMutation } from '../../Invoice_Hajj_Pre_Reg/API/Endpoints/invoiceHajjPreRegEndspoint';

import { useLazyGetSinglePassportQuery } from '../../Passport_MGT/Api/Endpoints/PassportEndpoints';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import AddTicketInfo from '../Modals/AddTicketInfo';

type Props = {
  name: number;
  form: FormInstance<any>;
  index: number;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
};

export default function PassengerInfoList({
  name,
  form,
  index,
  add,
  remove,
}: Props) {
  const { id } = useParams();

  const instancePassportSelect = useWatch(
    ['passenget_info', name, 'passenger_passport_id'],
    form
  );

  const passPortId = useWatch(
    ['passenget_info', name, 'passenger_passport_id'],
    form
  );

  const passport_passport_no = useWatch(
    ['passenget_info', name, 'passport_passport_no'],
    form
  );
  
  const [isTrackingNumber, setIsTrackingNumber] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (passport_passport_no || passport_passport_no === 0) {
      setIsTrackingNumber(true);
    } else {
      setIsTrackingNumber(false);
    }
  }, [passport_passport_no]);

  const [getSinglePassport, { data: singlePassport }] =
    useLazyGetSinglePassportQuery();

  useEffect(() => {
    if (passPortId) getSinglePassport(passPortId, true);
  }, [passPortId]);

  // set form value
  useEffect(() => {
    if (passPortId) {
      form.setFields([
        {
          name: ['passenget_info', name, 'passport_name'],
          value: singlePassport?.data?.name,
        },
        {
          name: ['passenget_info', name, 'passport_email'],
          value: singlePassport?.data?.email,
        },
        {
          name: ['passenget_info', name, 'passport_mobile_no'],
          value:
            singlePassport?.data?.mobile_no?.split('-')[1] ||
            singlePassport?.data?.mobile_no,
        },
        {
          name: ['passenget_info', name, 'passport_date_of_birth'],
          value: singlePassport?.data?.date_of_birth
            ? dayjs(singlePassport?.data?.date_of_birth)
            : undefined,
        },
        {
          name: ['passenget_info', name, 'passport_date_of_issue'],
          value: singlePassport?.data?.date_of_issue
            ? dayjs(singlePassport?.data?.date_of_issue)
            : undefined,
        },
        {
          name: ['passenget_info', name, 'passport_date_of_expire'],
          value: singlePassport?.data?.date_of_expire
            ? dayjs(singlePassport?.data?.date_of_expire)
            : undefined,
        },
      ]);
    }
  }, [singlePassport]);

  // ============= tracking no
  const [checkValue, setCheckValue] = useState<string>();
  const [checkedValue, { data }] = useCheckTrackingAndSerialMutation();

  useEffect(() => {
    const timerOut = setTimeout(() => {
      checkValue && checkedValue({ data_for: 'tracking', value: checkValue });
    }, 800);
    return () => clearTimeout(timerOut);
  }, [checkValue]);

  useEffect(() => {
    form.setFields([
      {
        name: ['passenget_info', name, 'passenger_passport_id'],
        value: instancePassportSelect,
      },
    ]);
  }, [instancePassportSelect]);

  return (
    <Row gutter={[5, 5]}>
      <FormInputItem
        size={3}
        name={[name, 'passenger_tracking_number']}
        label='Tracking Number:'
        onChange={(value) => setCheckValue(value.toString())}
        validateStatus={data?.data}
        help='Already exist.'
      />
      <SelectPassport
        name={[name, 'passenger_passport_id']}
        label='Passport No'
        size={3}
      />
      <FormInputItem
        name={[name, 'passport_name']}
        label='Name'
        size={3}
        readOnly
      />
      <FormInput
        label='Mobile:'
        number
        size={3}
        name={[name, 'passport_mobile_no']}
        readonly
      />

      <FormInputItem
        name={[name, 'passport_email']}
        label='Email'
        size={3}
        readOnly
      />

      <DateInput
        name={[name, 'passport_date_of_birth']}
        label='Date of birth'
        size={2}
        disabled
      />
      <DateInput
        name={[name, 'passport_date_of_issue']}
        label='Date of issue'
        size={2}
        disabled
      />
      <DateInput
        name={[name, 'passport_date_of_expire']}
        label='Date of expire'
        size={2}
        disabled
      />

      <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <Form.Item label={' '}>
          <AddTicketInfo form={form} index={index} name={name} />
        </Form.Item>
      </Col>

      <Form.Item label=' '>
        {index === 0 ? (
          <Col lg={1}>
            <Form.Item label={false}>
              <Button type='primary' onClick={() => add()}>
                <PlusOutlined />
              </Button>
            </Form.Item>
          </Col>
        ) : (
          <Col lg={1}>
            <Button type='primary' danger onClick={() => remove(name)}>
              <MinusCircleOutlined />
            </Button>
          </Col>
        )}
      </Form.Item>
    </Row>
  );
}
