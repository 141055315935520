import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import {
  ILoanDeleteRestoreBody,
  PaymentEditFormData,
  PaymentFormData,
  PaymentLoans,
  SinglePaymentDataType,
  ViewLoanPaymentDataType,
} from '../../LoanTypes/LoanTypes';
import { AUTHORITY, PAYMENT } from '../Constants/constants';

export const PaymentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all payments

    getPayments: build.query<
      HTTPResponse<ViewLoanPaymentDataType[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/loan-management/payment?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: [{ type: 'Payment', id: PAYMENT }],
    }),

    // get single payment by loan-id
    getSinglePayment: build.query<
      HTTPResponse<SinglePaymentDataType[]>,
      number
    >({
      query: (id) => ({ url: `/loan-management/payment/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Payment', id: id } as const,
        'LOAN-PAYMENT',
      ],
    }),

    // get all payment loans
    getPaymentAuthority: build.query<HTTPResponse<PaymentLoans[]>, number>({
      query: (id) => ({ url: `/loan-management/payment/loans/${id}` }),
      providesTags: [
        { type: 'Authority', id: AUTHORITY },
        { type: 'Payment', id: PAYMENT },
      ],
    }),

    //create payment loan
    createPayment: build.mutation<void, PaymentFormData>({
      query: (body) => ({
        url: '/loan-management/payment',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Payment Created Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Payment', id: PAYMENT },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //edit payment loan
    editPayment: build.mutation<void, PaymentEditFormData>({
      query: (body) => ({
        url: `/loan-management/payment/${body.payment_id}`,
        method: 'PATCH',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Payment Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Payment', id: PAYMENT },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
        'LOAN-PAYMENT',
      ],
    }),

    //delete payments
    deletePayments: build.mutation<
      void,
      { body: ILoanDeleteRestoreBody; id: number }
    >({
      query: (body) => ({
        url: `/loan-management/payment/${body.id}`,
        method: 'DELETE',
        body: body.body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'Payment Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Payment', id: PAYMENT },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),
  }),
});

export const {
  useLazyGetPaymentsQuery,
  useGetPaymentsQuery,
  useGetSinglePaymentQuery,
  useLazyGetSinglePaymentQuery,
  useLazyGetPaymentAuthorityQuery,
  useCreatePaymentMutation,
  useEditPaymentMutation,
  useDeletePaymentsMutation,
} = PaymentEndpoints;
