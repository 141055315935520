import React, { useEffect, useState } from 'react';
import { IPermission } from '../../../../common/types/commonTypes';
import { useGetAllPartialRefundQuery } from '../../Api/Endpoints/PartialRefundEndpoints';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { Button, Col, DatePicker, Descriptions, Input, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import { PartialRefundUtils } from '../../Utils/PartialRefundUtils/PartialRefundUtils';
import DescriptionsItem from 'antd/es/descriptions/Item';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { rangePresets } from '../../../../common/utils/common.utils';

type Props = { permission?: IPermission };
const PartialRefundHistory = ({ permission }: Props) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch: RefetchAgain,
  } = useGetAllPartialRefundQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [search, date]);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Refund', 'AirTicket Refund']}
        refetch={() => RefetchAgain()}
        reloaderSize='small'
      />

      <Row
        gutter={{ lg: 10, md: 24, sm: 16, xs: 8 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Link to='/refund/partial'>
          <Button type='primary'>
            <PlusOutlined /> Add Pertial Refund
          </Button>{' '}
        </Link>
        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='Search Refunds...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        columns={PartialRefundUtils({ permission, pagination })}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        expandable={{
          rowExpandable: (record) => true,
          expandedRowRender: (record: any, index) => {
            return (
              <Descriptions size='small' bordered column={3}>
                {record.vendor_refund_info.map((item: any) => {
                  return (
                    <React.Fragment key={index}>
                      <DescriptionsItem label='Vendor Name :'>
                        {item.vendor_name}
                      </DescriptionsItem>
                      <DescriptionsItem label='Vendor Refund Amount :'>
                        {Number(item.vprfnd_return_amount)}
                      </DescriptionsItem>
                      <DescriptionsItem label='Vendor Charge Name :'>
                        {Number(item.vprfnd_charge_amount)}
                      </DescriptionsItem>
                    </React.Fragment>
                  );
                })}
              </Descriptions>
            );
          },
        }}
        rowKey={(record, index) => index || 0}
      />
      {(isLoading || isFetching) && <LoadingIndicator />}
    </div>
  );
};

export default PartialRefundHistory;
