import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { crud, IPermission } from '../../../common/types/commonTypes';
import { Fixed2, rangePresets } from '../../../common/utils/common.utils';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../components/common/Invoice/InvoiceListHeader';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import {
  useDeletePayrollAdvanceMutation,
  useGetPayrollAdvanceAdvanceQuery,
} from '../api/endpoints/AdvancePayrollEndpoints';
import AddEmployeeAdvance from '../components/AddEmployeeAdvance';
import EditEmployeeAdvance from '../components/EditEmployeeAdvance';
import { IPayrollAdvanceList } from '../types/PayrollTypes';
import { useNavigate } from 'react-router';

type Props = {
  permission: IPermission | undefined;
};

export interface IPayrollAdvanceInfo {
  open: boolean;
  id: string | number | undefined;
}

const PayrollAdvance = ({ permission }: Props) => {
  const navigate = useNavigate();
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [info, setInfo] = useState<IPayrollAdvanceInfo>({
    open: false,
    id: '',
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching, refetch } =
    useGetPayrollAdvanceAdvanceQuery({
      page: pagination.current,
      size: pagination.pageSize,
      search,
      from_date: date[0],
      to_date: date[1],
    });

  const [
    deletePayrollAdv,
    { isLoading: deleteLoading, isSuccess: deleteSucc, isError: deleteError },
  ] = useDeletePayrollAdvanceMutation();

  // Handle Search Operation
  const handleDateRangeChange = (_: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  const columns: ColumnsType<IPayrollAdvanceList> = [
    {
      title: 'SL',
      align: 'center',
      width: 100,
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'tea_date',
      dataIndex: 'tea_date',
      render: (curr) => dayjs(curr).format('DD-MMM-YYYY'),
    },
    {
      title: 'Employee Name',
      key: 'employee_full_name',
      dataIndex: 'employee_full_name',
    },
    {
      title: 'Month',
      key: 'tea_month',
      dataIndex: 'tea_month',
      render: (curr) => dayjs(curr).format('MMM / YYYY'),
    },

    {
      title: 'Advance Amount',
      key: 'tea_amount',
      dataIndex: 'tea_amount',
      render: (curr) => Fixed2(curr),
    },

    {
      title: 'Account Name',
      key: 'account_name',
      dataIndex: 'account_name',
    },
    {
      title: 'Note',
      key: 'tea_note',
      dataIndex: 'tea_note',
    },
    {
      title: 'Action',
      align: 'center',
      render: (_, rec) => {
        return (
          <Row gutter={6} justify={'center'}>
            <Col>
              <Button
                onClick={() =>
                  navigate(`/payroll/advance/view/${rec.tea_id}`, {
                    state: location.pathname,
                  })
                }
                size='small'
                type='primary'
              >
                View
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  setInfo((prev) => ({
                    ...prev,
                    id: rec.tea_id,
                    open: !prev.open,
                  }))
                }
                size='small'
                type='primary'
              >
                Edit
              </Button>
            </Col>
            <Col>
              {permission?.['delete:any'] && (
                <Popconfirm
                  title='Sure to delete?'
                  onConfirm={() => deletePayrollAdv({ id: rec.tea_id })}
                  okButtonProps={{ loading: deleteLoading }}
                >
                  <Button size='small' type='primary' danger>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (deleteSucc) {
      message.success('Payroll advance successfully deleted');
    } else if (deleteError) {
      message.error('Something went wrong');
    }
  }, [deleteSucc, deleteError]);

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Employee Advance']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        {permission?.[crud.create] && (
          <Button type='primary' onClick={(e) => setDrawerOpen(!drawerOpen)}>
            Add Employee Advance
          </Button>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <AddEmployeeAdvance
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
      />

      <EditEmployeeAdvance setInfo={setInfo} info={info} />
      <Row>
        <Col span={24}>
          <Table
            tableLayout='fixed'
            bordered
            columns={columns}
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            dataSource={data?.data}
            size='small'
            pagination={
              data?.count !== undefined && data?.count < 20
                ? false
                : {
                    ...pagination,
                    total: data?.count,
                    showSizeChanger: true,
                    pageSizeOptions: ['20', '50', '100', '200', '500'],
                    onChange: handlePaginationChange,
                  }
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default PayrollAdvance;
