import { Button, Col, Form, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../../../app/hooks';
import { selectUser } from '../../../../../../auth/states/userSlice';
import { setModal } from '../../../../../../common/slices/commonSlice';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../../../components/common/spinner/LoadingIndicator';
import {
  IAccountIncentiveIncomeFormData,
  IaccountsByMethod,
} from '../../../../AccountsTypes/AccountsTypes';
import { useLazyGetAllAccountsByTypeQuery } from '../../../../Api/Endpoints/accountsEndpoints';
import Select_Vendor from '../../../../components/Select_Vendor';
import { useCreateIncentiveIncomeMutation } from '../../endpoints/incentiveIncomeEndpoints';
import { IPermission, crud } from '../../../../../../common/types/commonTypes';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  type?: string;
  permission?: IPermission;
};

function Incentive_Income_Add_Modal({ type, permission }: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const [adjustBill, setAdjustBill] = useState<string>();
  const onselect = (value: string) => {
    setAdjustBill(value);
  };
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const isChanged = useRef(false);

  // Control Modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });

      return;
    }
  }, [paymentMethod]);
  const [addIncentive, { isError, isSuccess, isLoading, data }] =
    useCreateIncentiveIncomeMutation();

  const handleSubmit = async (values: IAccountIncentiveIncomeFormData) => {
    const body: IAccountIncentiveIncomeFormData = {
      ...values,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      incentive_created_by: user?.user_id as number,
    };

    await addIncentive(body);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/accounts/incentive/vendor/view/${data?.data}`, {
        state: location.pathname,
      });
      dispatch(setModal(false));
      form.resetFields();
      form.setFieldValue('date', dayjs());
    }
  }, [isError, isSuccess, isLoading, data]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setModal(false));
      form.resetFields();
    }
  }, [isSuccess]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);
  return (
    <>
      {permission?.[crud.create] && (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          onClick={showModal}
        >
          Vendor Incentive Income
        </Button>
      )}

      <Modal
        title='Add Vendor Incentive Income'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        confirmLoading={isLoading}
      >
        {isLoading ? <LoadingIndicator /> : ''}
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Select_Vendor />

            <Col
              span={6}
              xs={24}
              sm={24}
              md={24}
              lg={12}
            >
              <Form.Item
                name='adjust_with_bill'
                label='Adjust With Bill'
                rules={[
                  {
                    required: true,
                    message: `Adjust With Bill is required!`,
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={'Select Adjust With Bill'}
                  optionFilterProp='children'
                  onSelect={onselect}
                >
                  <Select.Option
                    key={1}
                    value={'YES'}
                  >
                    YES
                  </Select.Option>
                  <Select.Option
                    key={2}
                    value={'NO'}
                  >
                    NO
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <NumberInput
              min='0'
              name='amount'
              label='Amount'
              maxChar={14}
              required
              size={12}
            />
            {adjustBill == 'NO' && (
              <>
                <SelectPaymentMethod
                  name='type_id'
                  label='Payment Method'
                  size={12}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isChanged.current) isChanged.current = true;
                  }}
                />
                <Col
                  span={6}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                >
                  <Form.Item
                    name='account_id'
                    label='Account'
                    rules={[
                      {
                        required: true,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={item.account_id}
                            >
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <DateInput
              name='date'
              label='Date'
              size={12}
            />
            <TextAreaInput
              name='note'
              label='Note'
              size={24}
            />
          </Row>

          <FormButton
            loading={isLoading}
            label='Create Incentive Income'
          />
        </Form>
      </Modal>
    </>
  );
}

export default Incentive_Income_Add_Modal;
