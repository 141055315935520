import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import {
  IViewBillingInfo,
  IViewPassportInfo,
} from '../../../common/types/commonInterfaces';
import FormHeaderTitle from './FormHeaderTitle';
import dayjs from 'dayjs';
import { Alert } from 'antd';

type Props = {
  billing_information: IViewBillingInfo[];
  passport_information: IViewPassportInfo[];
};
const InvoiceVisaBilling = ({
  billing_information,
  passport_information,
}: Props) => {
  const passportColumn: ColumnsType<any> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Passport',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    {
      title: 'Email',
      dataIndex: 'passport_email',
      key: 'passport_email',
    },

    {
      title: 'Date of birth',
      key: 'passport_date_of_birth',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_birth
            ? dayjs(data?.passport_date_of_birth).format('D-MM-YYYY')
            : ''}{' '}
        </>
      ),
    },
    {
      title: 'Date of issue',
      key: 'passport_date_of_issue',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_issue
            ? dayjs(data?.passport_date_of_issue).format('D-MM-YYYY')
            : ''}{' '}
        </>
      ),
    },
    {
      title: 'Date of expire',
      key: 'passport_date_of_expire',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_expire
            ? dayjs(data?.passport_date_of_expire).format('D-MM-YYYY')
            : ''}{' '}
        </>
      ),
    },
  ];

  const billingColumn: ColumnsType<IViewBillingInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Product',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Visiting Country',
      dataIndex: 'country_name',
      key: 'country_name',
    },
    {
      title: 'Visa Type',
      dataIndex: 'type_name',
      key: 'type_name',
    },
    {
      title: 'Token No',
      dataIndex: 'billing_token',
      key: 'billing_token',
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'billing_status',
    //   key: 'billing_status',
    //   align: 'center',
    //   render(value, record, index) {
    //     return (
    //       <div
    //         style={{
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}
    //       >
    //         <Alert
    //           style={{ fontSize: 12, textAlign: 'center', height: '25px' }}
    //           message={value}
    //           type={
    //             value === 'Approved'
    //               ? 'success'
    //               : value === 'Rejected'
    //               ? 'error'
    //               : 'info'
    //           }
    //         />
    //       </div>
    //     );
    //   },
    // },
    ...(billing_information.some((item) => item?.billing_visa_no)
      ? [
          {
            title: 'Visa No.',
            dataIndex: 'billing_visa_no',
            key: 'billing_visa_no',
          },
        ]
      : []),
    ...(billing_information.some((item) => item?.billing_mofa_no)
      ? [
          {
            title: 'Mofa No.',
            dataIndex: 'billing_mofa_no',
            key: 'billing_mofa_no',
          },
        ]
      : []),
    ...(billing_information.some((item) => item?.billing_okala_no)
      ? [
          {
            title: 'Okala No.',
            dataIndex: 'billing_okala_no',
            key: 'billing_okala_no',
          },
        ]
      : []),
    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
      align: 'right',
    },

    {
      title: 'Unit price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      align: 'right',
    },
    {
      title: 'Sub total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      align: 'right',
    },
  ];
  return (
    <div>
      <>
        {passport_information?.length ? (
          <div className='mt-5'>
            <FormHeaderTitle title='PASSPORT INFO' />

            <Table
              size='small'
              bordered
              rowKey={(e) => e.passport_passport_no}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={passport_information}
              columns={passportColumn}
              pagination={false}
            />
          </div>
        ) : (
          <></>
        )}
        <FormHeaderTitle title='BILLING INFO' />
        <Table
          size='small'
          bordered
          rowKey={(e) => e.billing_delivery_date}
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          dataSource={billing_information}
          columns={billingColumn}
          pagination={false}
        />
      </>
    </div>
  );
};

export default InvoiceVisaBilling;
