import { api } from '../../../../app/baseQuery';
import { formatQueryParams } from '../../../../auth/utils/lib';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { EMPLOYEEEXPENSEREPORT } from '../../../Reports/Api/constants';
import {
  IPayrollAdvanceCreate,
  IPayrollAdvanceDetails,
  IPayrollAdvanceList,
  IPayrollAdvancePatch,
} from '../../types/PayrollTypes';

export const AdvancePayrollEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // POST
    createPayrollAdvance: build.mutation<void, IPayrollAdvanceCreate>({
      query: (body) => ({
        url: '/payroll/employee-advance',
        body: body,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Payroll_Advance' }, EMPLOYEEEXPENSEREPORT],
    }),

    patchPayrollAdvance: build.mutation<void, IPayrollAdvancePatch>({
      query: (body) => {
        const { tea_id, ...rest } = body;
        return {
          url: `/payroll/employee-advance/${tea_id}`,
          body: rest,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Payroll_Advance' }, EMPLOYEEEXPENSEREPORT],
    }),

    getPayrollAdvanceDetails: build.query<
      HTTPResponse<IPayrollAdvanceDetails>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: '/payroll/employee-advance/' + id,
      }),
      providesTags: [{ type: 'Payroll_Advance' }, EMPLOYEEEXPENSEREPORT],
    }),

    getPayrollAdvanceAdvance: build.query<
      HTTPResponse<IPayrollAdvanceList[]>,
      {
        page: number;
        size: number;
        search?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: (arg) => {
        const { page, size, ...rest } = arg;
        const formattedArg = formatQueryParams(rest);
        return {
          url: `/payroll/employee-advance?page=${page}&size=${size}&${formattedArg}`,
        };
      },

      providesTags: [{ type: 'Payroll_Advance' }, EMPLOYEEEXPENSEREPORT],
    }),

    deletePayrollAdvance: build.mutation<void, { id: number }>({
      query: ({ id }) => {
        return {
          url: `/payroll/employee-advance/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Payroll_Advance' }, EMPLOYEEEXPENSEREPORT],
    }),
  }),
});

export const {
  useCreatePayrollAdvanceMutation,
  useGetPayrollAdvanceAdvanceQuery,
  useGetPayrollAdvanceDetailsQuery,
  usePatchPayrollAdvanceMutation,
  useDeletePayrollAdvanceMutation,
} = AdvancePayrollEndpoints;
