import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

type Props = {};

export default function NewOrExistingBtn({}: Props) {
  const [value, setvalue] = useState<string>('newclient');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loc = location.pathname.split('/');
    setvalue(loc[loc?.length - 1]);
  }, [location]);

  return (
    <Radio.Group value={value} style={{ marginBottom: '20px' }}>
      <Radio.Button
        onClick={() => navigate('/reissueairticket/newclient')}
        value='newclient'
      >
        New Client
      </Radio.Button>
      <Radio.Button
        onClick={() =>
          navigate('/reissueairticket/existing', {
            state: '/reissueairticket/newclient',
          })
        }
        value='existing'
      >
        Existing Client
      </Radio.Button>
    </Radio.Group>
  );
}
