import { Button, Popconfirm, Space, Tag, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteEmployeeMutation } from '../endpoints/employeeEndpoints';
import { employeeDataType, IemployeeState } from '../types/employeeTypes';
import dayjs from 'dayjs';
import { FilterValue } from 'antd/es/table/interface';
import { DepartmentDataType } from '../../department/types/departmentTypes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<employeeDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
  filteredInfo: Record<string, FilterValue | null>;
  data: DepartmentDataType[];
  handleViewModal: (employee_id: string) => void;
};
export const columns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
  filteredInfo,
  data,
  handleViewModal,
}: Props): ColumnsType<employeeDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  /**
   * handle click
   */
  let filterDepartment: { text: string; value: string }[] = [];
  data.map((d) => {
    filterDepartment.push({
      text: d.department_name,
      value: d.department_name,
    });
  });

  const handleClick = (record: employeeDataType) => {
    setEditInfo(record);
    showModal();
  };
  const [deleteEmployee, { isError, isSuccess, isLoading }] =
    useDeleteEmployeeMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Employee Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: employeeDataType, index: number) => {
    setColumnIndex(index);
    await deleteEmployee({ ...values, deleted_by: user?.user_id });
  };
  return [
    {
      title: 'Sl.',
      dataIndex: 'key',
      key: 'key',

      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_full_name',
      key: 'name',
    },
    {
      title: 'Department',
      dataIndex: 'department_name',
      key: 'name',
      filters: filterDepartment,
      filteredValue: filteredInfo.name || null,
    },

    {
      title: 'Joining Date',
      key: 'employee_joining_date',

      render: (_, record) => {
        return record.employee_joining_date
          ? `${dayjs(record.employee_joining_date).format('DD MMM YYYY')}`
          : undefined;
      },
    },
    {
      title: 'Status',
      dataIndex: 'employee_status',
      key: 'name',

      render: (_, { employee_status }) => (
        <Tag
          color={employee_status === 0 ? 'red' : 'green'}
          key={employee_status}
        >
          {employee_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
    },

    {
      title: 'Action',
      key: 'action',

      render: (_, record, index) => (
        <Space size='small'>
          <Button
            size='small'
            type='primary'
            onClick={() => handleViewModal(String(record.employee_id))}
          >
            View
          </Button>
          {permission?.['update:any'] && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};

export const getEmployee = (state: IemployeeState, id: number) => {
  const employee = state.employees.find((item) => item.employee_id === id);

  return employee;
};

// Date convert
