import { useAppSelector } from '../../app/hooks';
import { ToWords } from 'to-words';

type Props = {
  number: number;
};

const NumToWord = ({ number }: Props) => {
  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const toWords = new ToWords({
    localeCode: 'en-BD',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        name: currencyName?.toLocaleUpperCase() || '',
        plural: currencyName?.toLocaleUpperCase() || '',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paisa',
          symbol: '',
        },
      },
    },
  });

  return <div>{`In Word: ${toWords.convert(number || 0)}`}</div>;
};

export default NumToWord;

export const NumToWord_raw = ({ number }: Props): any => {
  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const toWords = new ToWords({
    localeCode: 'en-BD',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
      currencyOptions: {
        name: currencyName?.toLocaleUpperCase() || '',
        plural: currencyName?.toLocaleUpperCase() || '',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paisa',
          symbol: '',
        },
      },
    },
  });

  return `${toWords.convert(number || 0)}`;
};
