import { Typography } from 'antd';
import dayjs from 'dayjs';

export const renderField = (
  label: string,
  value: string | number | any,
  isDate?: boolean
) => {
  return (
    value && (
      <Typography.Text
        style={{
          display: 'block',
          fontSize: '12px',
          fontFamily: "'Source Sans Pro', sans-serif",
        }}
      >
        <b>{label} :</b> {isDate ? dayjs(value).format('DD-MM-YYYY') : value}
      </Typography.Text>
    )
  );
};
