import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import {
  IPermission,
  ISetState,
  crud,
} from '../../../../common/types/commonTypes';
import { useDeletePaymentsMutation } from '../../Api/Endpoints/PaymentEndpoints';
import {
  ILoanDeleteRestoreBody,
  ViewLoanPaymentDataType,
} from '../../LoanTypes/LoanTypes';
import { useState } from 'react';

export const ViewPaymentList = ({
  setEditInfo,
  showModal,
  permission,
  pagination,
}: {
  setEditInfo: ISetState<ViewLoanPaymentDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<ViewLoanPaymentDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const setEditAccount = (value: ViewLoanPaymentDataType) => {
    setEditInfo(value);
    showModal();
  };
  const user = useSelector(selectUser);
  const [deletePayment, { isError, isLoading }] = useDeletePaymentsMutation();

  const handleDelete = async (
    values: ViewLoanPaymentDataType,
    index: number
  ) => {
    const body: ILoanDeleteRestoreBody = {
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deletePayment({ body: body, id: values.payment_id });
  };

  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'payment_date',
      render: (_, record) => {
        return `${dayjs(record.payment_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Loan Authority',
      dataIndex: 'authority_name',
      key: 'authority_name',
    },
    {
      title: 'Loan Name',
      dataIndex: 'loan_name',
      key: 'loan_name',
    },

    {
      title: 'Method',
      dataIndex: 'payment_type',
      key: 'payment_type',
      render: (curr) => {
        if (curr === 1) return 'CASH';
        if (curr === 2) return 'BANK';
        if (curr === 3) return 'MOBILE BANKING';
        if (curr === 4) return 'CHEQUE';
        if (curr === 5) return 'CREDIT CARD';
      },
    },
    {
      title: 'Details',
      dataIndex: 'pay_details',
      key: 'pay_details',

      render: (_, record) => {
        let color = record.pay_details === 'DEPOSIT' ? 'green' : 'red';
        function status(record: any) {
          if (
            record.pay_details == 'DEPOSIT' ||
            record.pay_details == 'PENDING' ||
            record.pay_details == 'BOUNCE'
          ) {
            return <span style={{ color }}> {record.pay_details} </span>;
          } else {
            return `${record.account_name}`;
          }
        }

        return status(record);
      },
    },

    {
      title: 'Amount',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (_, record) => Number(record.payment_amount),
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, record, index) => {
        const { payment_amount } = record;

        return (
          <Space size='small'>
            <Link
              to={`/loan/payment/view/${record.payment_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
            {permission?.[crud.update] && record.pay_details !== 'DEPOSIT' && (
              <Button
                size='small'
                type='primary'
                onClick={() => setEditAccount(record)}
              >
                Edit
              </Button>
            )}
            {permission?.[crud.delete] && (
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleDelete(record, index)}
                  title='Sure to delete?'
                >
                  Delete
                </Popconfirm>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
};
