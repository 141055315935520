import { message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { usePostHajjiManagementMutation } from '../../API/Endpoints/HajjiManageMentEndspont';
import ClientToClientForm from '../../Components/ClientToClientCom/ClientToClientForm';
import { IPosthajjiManagement } from '../../Types/HajjiManagement.interface';

const PostClientToClient = () => {
  const user = useSelector(selectUser);
  const [form] = useForm();

  const trackingNo = useWatch(['ctransfer_combclient_from'], form);
  // =========== form submit
  const [postHajjiManagement, { error, isError, isSuccess, isLoading }] =
    usePostHajjiManagementMutation();

  const onFinish = (values: IPosthajjiManagement) => {
    if (!trackingNo?.length) {
      return message.error('Pleace select a hajji passport by tracking number');
    }
    values.ctransfer_created_by = user?.user_id;
    postHajjiManagement(values);
  };

  // =========== nevigate to view client to client
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      toasterNotification('success', 'Hajji management added successfully!');
      navigate('/haji/transfer/client_list');
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <BreadCrumb arrOfOption={['Create Transfer', 'Client to client']} />
      <ClientToClientForm
        onFinish={onFinish}
        form={form}
        isLoading={isLoading}
      />
    </>
  );
};

export default PostClientToClient;
