import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, message, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import assyncWrapper from '../../../common/utils/assyncWrapper';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useEditVendorMutation,
  useGetSingleVendorQuery,
} from '../api/endpoints/vendorEndpoints';
import EditVendorsForm from './EditVendorsForm';
import { useWatch } from 'antd/es/form/Form';
import { useAppSelector } from '../../../app/hooks';

const EditVendor = () => {
  const [selectProducts, setSelectProducts] = useState<number[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { data: vendor, isLoading } = useGetSingleVendorQuery(Number(id));
  const user = useSelector(selectUser);

  const [updateVendor, { isLoading: isUpdating, isError, isSuccess }] =
    useEditVendorMutation();
  const preSelectedvproduct_product_id = vendor?.data?.vendor_products?.map(
    (item) => item?.vproduct_product_id
  );
  const handleEditVendor = async () => {
    assyncWrapper(async () => {
      const values = await form.getFieldsValue();
      (values.vendor_email = values.vendor_email
        ? values.vendor_email
        : undefined),
        (values.vendor_updated_by = user?.user_id),
        (values.vendor_registration_date = (
          values.vendor_registration_date as dayjs.Dayjs
        ).format('YYYY-MM-DD'));
      values.vendor_products_id = selectProducts.length
        ? selectProducts
        : preSelectedvproduct_product_id;
      await updateVendor({ id: Number(id), values });
    });
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Vendor has been updated!');
      navigate('/vendors');
    }
  }, [isUpdating]);
  // hendle add vendor--------------
  const vendorStartDate = useWatch('vendor_start_date', form);

  useEffect(() => {
    if (vendorStartDate) {
      form.setFieldValue(
        'vendor_end_date',
        dayjs(vendorStartDate).add(1, 'year')
      );
    }
  }, [vendorStartDate]);
  return (
    <Style>
      <BreadCrumb arrOfOption={['Vendors', 'Edit  Vendor']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/vendors'>
          <Button type='primary' icon={<ArrowLeftOutlined />}>
            Return Vendor List
          </Button>
        </Link>
      </Space>
      <Card>
        <Form
          form={form}
          onFinish={handleEditVendor}
          style={{ marginTop: '0.5rem' }}
          layout='vertical'
        >
          <EditVendorsForm
            form={form}
            vendor={vendor}
            setSelectProducts={setSelectProducts}
            isLoading={isLoading || isUpdating}
          />
        </Form>
      </Card>
      {(isLoading || isUpdating) && <LoadingIndicator />}
    </Style>
  );
};

export default EditVendor;
const Style = styled.div``;
