import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Row, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { pagination } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazySearchRefundInfoQuery } from '../../Api/Endpoints/OthersRefundClientEndpoints';
import OtherRefundClientPreviewForm from '../../Components/Client/OtherRefundClientPreviewForm';
import { SelectOthersRefundClientID } from '../../Components/SelectOtherRefundClient';
import {
  IOthersRefundForm1,
  IOthersRefundInfo,
} from '../../RefundTypes/RefundTypes';
import { OthersRefundClientInfoUtils } from '../../Utils/OthersClientUtils/OthersRefundClientCreateUtils/OthersRefundClientInfoUtils';

type Props = { permission?: IPermission };

const OtherRefundClient = ({ permission }: Props) => {
  let temp_client_total_price = 0;
  let temp_client_total_refund = 0;
  let temp_client_total_return = 0;
  let temp_client_total_charge = 0;
  let temp_vendor_total_price = 0;
  let temp_vendor_total_refund = 0;
  let temp_vendor_total_return = 0;
  let temp_vendor_total_charge = 0;

  const [clientId, setClientId] = useState<string>();
  const [invoice_id, setInvoice_id] = useState<number>(0);
  const [clLastBalance, setClLastBalance] = useState<number>();
  const [client_name, setClientName] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [OthersRefundIsVisible_Invoice, setOthersRefundIsVisible_Invoice] =
    useState<boolean>(true);
  const [othersRefund_charge, setOthersRefund_charge] =
    useState<boolean>(false);
  const [
    othersRefundIsVisible_clientRefund,
    setOthersRefundIsVisible_clientRefund,
  ] = useState<boolean>(false);
  const [client_total_charge, setClientTotal_charge] = useState<number>(0);
  const [vendor_total_charge, setVendorTotal_charge] = useState<number>(0);
  // const [price_total, setPrice_total] = useState<number>(0);
  const [client_refund_total, setClientRefund_total] = useState<number>(0);
  const [vendor_refund_total, setVendorRefund_total] = useState<number>(0);
  const [client_return_total, setClientReturn_total] = useState<number>(0);
  const [vendor_return_total, setVendorReturn_total] = useState<number>(0);
  // const [net_total, setNet_total] = useState<number>(0);
  const [tableData, setTableData] = useState<IOthersRefundInfo[]>([]);
  let invoice_category_id: number = 0;
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [clientForm] = Form.useForm();
  const [
    fetchOthersRefundInfo,
    { data: refundInfo, isLoading, isUninitialized },
  ] = useLazySearchRefundInfoQuery({
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IOthersRefundInfo[] = [];
      if (data) {
        for (let i = 0; i < data.billing_info.length; i++) {
          const element = data.billing_info[i];
          invoice_category_id = data.invoice_category_id;
          dataToReturn.push({
            ...element,
            key: i + 1,
            client_total_price:
              element.billing_remaining_quantity * element.billing_unit_price,
            vendor_total_price:
              element.billing_remaining_quantity * element.billing_cost_price,
            refund_quantity: 0,
            client_refund_charge: 0,
            vendor_refund_charge: 0,
            client_refund_amount: 0,
            vendor_refund_amount: 0,
            client_refund_price: 0,
            vendor_refund_price: 0,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });

  useEffect(() => {
    setTableData(refundInfo);
  }, [refundInfo.length]);

  useEffect(() => {
    form.setFieldValue('others_invoice_id', undefined);
  }, [clientId]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  const onTableQtyRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.refund_quantity = Number(value);
      if (newItem.billing_unit_price != undefined) {
        newItem.client_refund_amount =
          newItem.refund_quantity * newItem.billing_unit_price;
      }
      if (newItem.billing_unit_price != undefined) {
        newItem.vendor_refund_amount =
          newItem.refund_quantity * newItem.billing_cost_price;
      }

      newItem.client_refund_price =
        newItem.client_refund_amount - newItem.client_refund_charge;
      newItem.vendor_refund_price =
        newItem.vendor_refund_amount - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };
  const onTableClientChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.client_refund_charge = Number(value);
      newItem.client_refund_price =
        newItem.client_refund_amount - newItem.client_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };
  const onTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_refund_price =
        newItem.vendor_refund_amount - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  const onFinishForm1 = async (values: IOthersRefundForm1) => {
    setDate(values.date);
    const body: IOthersRefundForm1 = {
      ...values,
    };
    setInvoice_id(values.others_invoice_id);
    isUninitialized && fetchOthersRefundInfo(body.others_invoice_id, true);
    setOthersRefundIsVisible_Invoice(false);
    setOthersRefund_charge(true);
  };

  const onTableFormFinish = async () => {
    setOthersRefund_charge(false);
    setOthersRefundIsVisible_clientRefund(true);
  };

  useEffect(() => {
    tableData?.forEach((item) => {
      temp_client_total_charge =
        temp_client_total_charge + item.client_refund_charge;
      temp_client_total_refund =
        temp_client_total_refund + item.client_refund_amount;
      temp_client_total_return =
        temp_client_total_return + item.client_refund_price;
      temp_vendor_total_charge =
        temp_vendor_total_charge + item.vendor_refund_charge;
      temp_vendor_total_refund =
        temp_vendor_total_refund + item.vendor_refund_amount;
      temp_vendor_total_return =
        temp_vendor_total_return + item.vendor_refund_price;
    });

    setClientTotal_charge(temp_client_total_charge);
    setVendorTotal_charge(temp_vendor_total_charge);
    // setPrice_total(temp_client_total_price);
    setClientRefund_total(temp_client_total_refund);
    setVendorRefund_total(temp_vendor_total_refund);
    setClientReturn_total(temp_client_total_return);
    setVendorReturn_total(temp_vendor_total_return);
    // setNet_total(
    //   temp_client_total_price - temp_client_total_charge
    // );
  }, [tableData, othersRefund_charge]);

  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'Create Others Refund']} />
      <Link to='/refund/history/others'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Other Refund List
        </Button>
      </Link>
      {OthersRefundIsVisible_Invoice && (
        <Form layout='vertical' form={form} onFinish={onFinishForm1}>
          <Card>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <SelectClients
                name='client_id'
                label='Select Client'
                required
                size={8}
                onChange={(e) => setClientId(e as string)}
                offDropDown
              />
              <SelectOthersRefundClientID
                name='others_invoice_id'
                label='Select Others Refund Invoice'
                clientID={clientId as string}
                size={8}
                required
                setClLastBalance={setClLastBalance}
                setClientName={setClientName}
              />
              <DateInput label='Refund Date' name='date' size={3} />
            </Row>
            {permission?.[crud.read] && (
              <FormButton label='Next' textAlign='left' />
            )}
          </Card>
        </Form>
      )}
      {othersRefund_charge && (
        <Form layout='vertical' form={tableForm} onFinish={onTableFormFinish}>
          <FormHeaderTitle title='Others Refund Information' />
          <Table
            columns={OthersRefundClientInfoUtils(
              onTableQtyRefundChange,
              onTableClientChargeRefundChange,
              onTableVendorChargeRefundChange
            )}
            bordered
            dataSource={tableData}
            scroll={{ x: true }}
            loading={{
              spinning: isLoading,
              indicator: loadingIndicator,
            }}
            size='small'
            pagination={pagination(tableData.length)}
            summary={(record) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={11}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Typography.Text strong>
                        {client_refund_total}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      {vendor_refund_total}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Typography.Text strong>
                        {client_return_total}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Typography.Text strong>
                        {vendor_return_total}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />

          {permission?.[crud.read] && (
            <FormButton label='Next' style={{ marginTop: '1rem' }} />
          )}
        </Form>
      )}
      {othersRefundIsVisible_clientRefund && (
        <>
          <OtherRefundClientPreviewForm
            form={clientForm}
            client_id={clientId as string}
            client_name={client_name}
            invoice_id={invoice_id}
            clientRefund={client_refund_total}
            clientRefundChargeTotal={client_total_charge}
            clientReturnTotal={client_return_total}
            vendorReturnTotal={vendor_return_total}
            tableData={tableData}
            refund_date={date as string}
            permission={permission}
            clientLastBalance={clLastBalance}
            invoice_category_id={invoice_category_id}
          />
        </>
      )}
      {/* {othersRefundIsVisible_vendorRefund && (
        <>
          <FormHeaderTitle title='Vendor Refund Information :' />
          <Descriptions bordered column={1}>
            <DescriptionsItem label='Total Refund'>
              {refund_total}
            </DescriptionsItem>
            <DescriptionsItem label='Refund Charge'>
              {total_charge}
            </DescriptionsItem>
            <DescriptionsItem label='Return Amount'>
              {return_total}
            </DescriptionsItem>
            <DescriptionsItem label='Vendor Last Balance'>
              {vendorLastBalance}
            </DescriptionsItem>
          </Descriptions>
          <OtherRefundVendorPreviewForm
            form={vendorForm}
            vendor_id={vendorId}
            invoice_id={invoice_id}
            vendorRefundTotal={return_total}
            vendorRefundChargeTotal={total_charge}
            vendorLastBalance={vendorLastBalance}
            tableData={tableData}
          />
        </>
      )} */}
    </div>
  );
};

export default OtherRefundClient;
