import { Col, Form, InputNumber, message, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetClLastBalanceQuery } from '../../../Client/Client/api/endpoints/clientEndpoints';
import PayMethodAndAcc from '../../../vendor/components/common/PayMethodAndAcc';
import { layout } from '../../../vendor/pages/AdvanceReturnAdd';
import { presentBalAndColorType } from '../../../vendor/types/vendor.interfaces';
import {
  useEditAdvanceReturnMRMutation,
  useGetAdvrDataForEditQuery,
} from '../../api/endpoints/moneyReceiptEndpoints';
import { IMoneyReceiptAdvanceReturn } from '../../Types/MoneyReceiptTypes';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {};
const EditAdvanceReturnMR = (props: Props) => {
  // ============== @ GET PREVIOUS DATA @ ======================
  const id = Number(useParams().id);

  const { data: invoiceDetails, isSuccess: getForEditFetchSuccess } =
    useGetAdvrDataForEditQuery(id);

  const prevData = invoiceDetails?.data;

  const setPresetnBalAndColor: presentBalAndColorType = (balance, color) => {
    setPresentBalanceLabel(balance);
    setColor(color);
  };

  const [color, setColor] = useState<'green' | 'red' | undefined>(undefined);
  const isChanged = useRef(false);

  const [presentBalanceLabel, setPresentBalanceLabel] =
    useState<string>('Present balance');

  const aviableBalanceStyle: React.CSSProperties = {
    width: '100%',
    color,
    border: color === 'red' ? '0.5px solid red' : '',
  };

  // ===================== @ SET PREVIOUS DATA TO FORM @ ========================
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [payMethodId, setPayMethodId] = useState<number>(1);
  const [accountsBalanced, setAccountBalanced] = useState<number>();

  useEffect(() => {
    form.setFieldValue('vpay_availeable_balance', accountsBalanced);
  }, [accountsBalanced]);

  const accountId = useWatch('account_id', form);
  const paymentType = useWatch('advr_payment_type', form);

  const today = new Date().toISOString().slice(0, 10);
  useEffect(() => {
    form.setFieldsValue({
      advr_payment_date: dayjs(prevData?.advr_payment_date),
      advr_combclient: prevData?.advr_combclient,
      advr_payment_type: prevData?.advr_payment_type,
      account_id: prevData?.advr_account_id,
      advr_amount: Number(prevData?.advr_amount),
      advr_note: prevData?.advr_note,
      cheque_no: prevData?.cheque_number,
      vpcheque_withdraw_date: dayjs(
        prevData?.cheque_withdraw_date || today,
        'YYYY-MM-DD'
      ),
      bank_name: prevData?.cheque_bank_name,
      trans_no: prevData?.advr_trxn_no,
      transaction_charge: prevData?.advr_trxn_charge,

      card_trans_charge: Fixed2(prevData?.card_trans_charge),
      card_trans_charge_type: prevData?.card_trans_charge_type,
      card_trans_no: prevData?.card_trans_no,
    });

    setPayMethodId(prevData?.advr_payment_type as number);
  }, [prevData]);

  const [post, { isError, isSuccess, isLoading, error }] =
    useEditAdvanceReturnMRMutation();

  const onFinish = async () => {
    const {
      advr_payment_date,
      advr_combclient,
      advr_payment_type,
      account_id,
      advr_amount,
      trans_no,
      advr_note,
      cheque_no,
      vpcheque_withdraw_date,
      bank_name,
      transaction_charge,
      card_trans_charge,
      card_trans_charge_type,
      card_trans_no,
    } = form.getFieldsValue();

    let postData: IMoneyReceiptAdvanceReturn = {
      advr_combclient,
      advr_payment_type,
      advr_account_id: account_id,
      advr_amount,
      advr_payment_date: advr_payment_date?.format('YYYY-MM-DD'),
      advr_note,
      advr_created_by: user?.user_id,
    };

    if (advr_payment_type === 3) {
      postData = {
        ...postData,
        advr_trxn_charge: transaction_charge,
        advr_trxn_no: trans_no,
      };
    }

    if (advr_payment_type === 4) {
      postData = {
        ...postData,
        cheque_number: cheque_no,
        cheque_withdraw_date: vpcheque_withdraw_date?.format('YYYY-MM-DD'),
        cheque_bank_name: bank_name,
      };
    }
    if (advr_payment_type === 5) {
      postData = {
        ...postData,
        card_trans_charge: card_trans_charge,
        card_trans_charge_type: card_trans_charge_type,
        card_trans_no: card_trans_no,
      };
    }

    post({ body: postData, id });

    form.resetFields();
  };

  type Edata = {
    message: string;
    status: number;
    success: boolean;
    type: string;
  };

  let Emessage: string;

  if (error) {
    if ('status' in error) {
      Emessage = (error.data as Edata).message;
    }
  }

  // ================== notification =========================
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Advance return has been updated!');
      navigate('/moneyreceipt/advance');
    }
    if (isError) {
      message.error(Emessage);
    }
  }, [isLoading]);

  // GET CLIENT LAST BALANCE

  const [getClientLastBalance, { data: clientLBlance }] =
    useLazyGetClLastBalanceQuery();

  useEffect(() => {
    if (prevData?.advr_combclient !== undefined) {
      getClientLastBalance(prevData?.advr_combclient);
    }
  }, [prevData?.advr_combclient]);

  // SET CLIENT LAST BALANCE
  useEffect(() => {
    form?.setFieldsValue({
      clientAviableBalance: clientLBlance?.data?.client_last_balance,
    });

    if (clientLBlance) {
      setPresetnBalAndColor('Advance', undefined);
    }
    if (
      clientLBlance?.data?.client_last_balance &&
      clientLBlance?.data?.client_last_balance < 0
    ) {
      setPresetnBalAndColor('Due', 'red');
    }
  }, [clientLBlance]);

  const advance_amount = useWatch('clientAviableBalance', form);
  const available_balance = useWatch('vpay_availeable_balance', form);

  const disableSubmit = () => {
    if (Number(advance_amount) >= 0 && Number(available_balance) >= 0) {
      return false;
    }

    return true;
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Return Advance Payment']} />
      <div className='border p-10'>
        <Form
          layout='horizontal'
          style={{ marginTop: '1rem' }}
          onFinish={onFinish}
          form={form}
          labelAlign='left'
          {...layout}
        >
          <Row gutter={[16, 16]} justify='space-between'>
            <Col lg={11} md={24} sm={24} xs={24}>
              <SelectClients
                name={'advr_combclient'}
                label='Select Client'
                placeholder='Select a client'
                required
                size={24}
                disabled
              />

              <PayMethodAndAcc
                payMethodId={payMethodId}
                setPayMethodId={setPayMethodId}
                setAccountBalanced={setAccountBalanced}
                accountId={accountId}
                size={24}
                showAvailableBalanceRightSide
                form={form}
              />

              <NumberInput
                maxAmount={Number(accountsBalanced || 0)}
                label='Advance amount'
                name='advr_amount'
                required
                min='0'
                mdSize={24}
                smSize={24}
                maxChar={14}
                minChar={0}
                size={24}
              />
            </Col>

            <Col lg={11} md={24} sm={24} xs={24}>
              <Form.Item
                name={'clientAviableBalance'}
                label={presentBalanceLabel}
              >
                <InputNumber
                  style={aviableBalanceStyle}
                  readOnly
                  defaultValue={0}
                />
              </Form.Item>

              {Number(payMethodId) !== 4 && (
                <NumberInput
                  label='Available Balance'
                  name='vpay_availeable_balance'
                  color={undefined}
                  readOnly
                  min='0'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  maxChar={14}
                  minChar={0}
                />
              )}

              <DateInput
                name='advr_payment_date'
                label='Return date'
                required
                size={24}
                mdSize={24}
                smSize={24}
              />

              <TextAreaInput label='Return note' name='advr_note' size={24} />

              <FormButton label='Update' disabled={disableSubmit()} />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default EditAdvanceReturnMR;
