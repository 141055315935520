import { TabPaneProps, Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import InvoiceNotFound from '../../../../auth/pages/InvoiceNotFound';
import { IViewInvoiceDetails } from '../../../../common/types/commonInterfaces';
import InvoiceActivityLogs from '../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs';
import CommonViewInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import TabDetailsView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';
import TabPaymentView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView';
import ViewInvoiceHeader from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import AirTicketFlightAndPaxView from '../../../Invoice_Air_Ticket/Components/AirTicketFlightAndPaxView';
import ViewAirticketChildren from '../../../Invoice_Non_Comission/components/ViewAirticketChildren';
import { useGetViewInvoiceReissueQuery } from '../../api/endpoints/reIssueAirTicketInvoiceEndpoints';
import TabRefundDetails from '../../../../components/common/Invoice/ViewInvoiceInfo/TabRefundDetails';
import { IPermission } from '../../../../common/types/commonTypes';

export interface Tab extends Omit<TabPaneProps, 'tab'> {
  key: string;
  label: React.ReactNode;
}

type Props = {
  permission?: IPermission;
};
const ViewInvoiceReissue = ({ permission }: Props) => {
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewRefundDetailsRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = React.useState<
    'viewInvoice' | 'costDetails' | 'payments' | 'activity' | 'refund_details'
  >('viewInvoice');

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case 'viewInvoice':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case 'costDetails':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case 'payments':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case 'activity':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;

    case 'refund_details':
      selectedPrintableRef = viewRefundDetailsRef;
      break;

    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);

  const {
    data: { data: invoiceData } = {},
    isLoading,
    isError,
  } = useGetViewInvoiceReissueQuery(invoice_id);

  const { invoice_category_id } = invoiceData || {};

  const isViewRefundDetails = [3, 26, 31].includes(invoice_category_id || 0);

  const items: Tab[] = [
    {
      label: 'Invoice',
      key: '1',
      children: invoiceData && (
        <CommonViewInvoice
          heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
          invoiceData={invoiceData}
          viewInvoicePrintableRef={viewInvoicePrintableRef}
          permission={permission}
          children={
            <AirTicketFlightAndPaxView
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              flights={invoiceData.flights}
              paxDetails={invoiceData.pax_details}
              airticketDetails={invoiceData.airticket_information}
            />
          }
        />
      ),
    },
    {
      label: 'Details',
      key: '2',
      children: invoiceData && (
        <TabDetailsView
          children={<ViewAirticketChildren invoiceDetails={invoiceData} />}
          invoiceDetails={invoiceData}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },

    {
      label: 'Activity Log',
      key: '4',
      children: (
        <InvoiceActivityLogs
          id={invoice_id}
          viewInvoiceAcivityRef={viewInvoiceAcivityRef}
        />
      ),
    },
  ];

  if (invoiceData?.payments) {
    items.push({
      label: 'Payments',
      key: '3',
      children: invoiceData && (
        <TabPaymentView
          invoiceDetails={invoiceData as IViewInvoiceDetails}
          viewInvoicePaymentsRef={viewInvoicePaymentsRef}
        />
      ),
    });
  }

  if (invoiceData?.invoice_is_refund) {
    items.push({
      label: 'Refund Details',
      key: '5',
      children: invoiceData && (
        <TabRefundDetails viewInvoicePaymentsRef={viewRefundDetailsRef} />
      ),
    });
  }

  if (isError) return <InvoiceNotFound />;

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id as number}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />
      {isLoading && <LoadingIndicator />}
      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint('viewInvoice');
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint('costDetails');
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint('payments');
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint('activity');
              break;
            case 5:
              setSelectedForPrint && setSelectedForPrint('refund_details');
              break;
            default:
              break;
          }
        }}
        type='card'
        style={{ marginTop: '15px' }}
        items={items.sort((a, b) => Number(a.key) - Number(b.key))}
      ></Tabs>
    </>
  );
};

export default ViewInvoiceReissue;
