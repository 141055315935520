import { useState } from 'react';
import Daily_Summary_Report from '../Daily_Summary/pages/Daily_Summary_Report';
import Monthly_Summary from '../Monthly_Summary/pages/Monthly_Summary';
const SummaryReport = () => {
  const [value, setValue] = useState(1);
  return (
    <>
      {value === 1 ? (
        <Daily_Summary_Report setValue={setValue} value={value} />
      ) : (
        <Monthly_Summary setValue={setValue} value={value} />
      )}
    </>
  );
};

export default SummaryReport;
