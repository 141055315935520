import React, { useEffect } from 'react';
import { Col, Form, Select } from 'antd';
import { commonProps } from '../../../common/types/commonTypes';
import { useLazyGetAllTicketsByIDQuery } from '../Api/Endpoints/AirTicketRefundEndpoints';

type PropsType = {
  clientID: string;
  setOthersRefund?: (arg: string) => void;
  setClLastBalance?: (arg: number) => void;
  setClientName?: (arg: string) => void;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
} & commonProps;

export const SelectAirTicketRefundInvoice = ({
  name,
  label,
  size,
  required,
  disabled,
  onChange,
  clientID,
}: PropsType) => {
  // ================= airTicketInvoices children
  const [fetchAirTicketInvoice, { data: airTicketInvoices }] =
    useLazyGetAllTicketsByIDQuery();

  useEffect(() => {
    clientID && fetchAirTicketInvoice(clientID);
  }, [clientID]);

  const selectAirTicketInvoice = airTicketInvoices?.data;

  const uniqueInvoices: any = [];
  const seenIds = new Set();

  selectAirTicketInvoice?.forEach((item) => {
    const invoiceId = item.invoice_id;
    if (!seenIds.has(invoiceId)) {
      uniqueInvoices.push(item);
      seenIds.add(invoiceId);
    }
  });

  const airTicketInvoice: React.ReactNode[] = [];

  if (uniqueInvoices) {
    for (let i = 0; i < uniqueInvoices.length; i++) {
      airTicketInvoice.push(
        <Select.Option
          key={uniqueInvoices[i].invoice_id}
          value={uniqueInvoices[i].invoice_id}
        >
          {`${uniqueInvoices[i].invoice_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: 'Air Ticket Invoice is required',
          },
        ]}
      >
        <Select
          allowClear
          disabled={disabled}
          placeholder={'Select IAT Invoice'}
          showSearch
          onChange={onChange}
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {airTicketInvoice}
        </Select>
      </Form.Item>
    </Col>
  );
};
