import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { COMBINE_CLIENT } from '../../../Client/combined_clients/api/endpoints/clientCombineEndpoints';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import {
  IAllVendors,
  IEditVendor,
  ISingleVendor,
  ISingleVendorDetails,
  IVendorDetails,
  IVendorFormData,
  IVendorsAndCombined,
  IVendorsAndCombinedInterface,
  IVendorStatus,
} from '../../types/vendor.interfaces';

export const vendorEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    deleteVendor: build.mutation<
      HTTPResponse<void>,
      { id: number; vendor_deleted_by?: number }
    >({
      query: (arg) => ({
        url: `/vendors/vendor/delete/${arg.id}`,
        method: 'DELETE',
        body: { vendor_deleted_by: arg.vendor_deleted_by },
      }),
      invalidatesTags: [
        { type: 'Vendors', id: 'VENDOR' },
        COMBINE_CLIENT,
        DASHBOARD,
      ],
    }),
    /**
     * get all vendors
     *
     */
    getVendors: build.query<
      HTTPResponse<IAllVendors[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/vendors?page=${arg.current}&size=${arg.pageSize}&search=${
          arg.search || ''
        }`,
      }),
      providesTags: [
        { type: 'Vendors', id: 'VENDOR' },
        { type: 'Payments', id: 'VENDOR_PAYMENT' },
        COMBINE_CLIENT,
      ],
    }),

    /**
     * get a vendor excel report
     */
    getVendorExcelReport: build.query<
      HTTPResponse<any>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/report/vendor-all?page=${arg.current}&size=${
          arg.pageSize
        }&search=${arg.search || ''}`,
        responseHandler: async (response) => {
          const filename = `vendors.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),

    getAllVendors: build.query<HTTPResponse<IAllVendors[]>, string>({
      query: (search) => ({
        url: `/vendors/all-vendors?search=${search ? search : ''}`,
      }),
      providesTags: [
        { type: 'Vendors', id: 'VENDOR' },
        { type: 'Payments', id: 'VENDOR_PAYMENT' },
        COMBINE_CLIENT,
      ],
    }),

    /**
     * get all vendors and combined
     *
     */
    getAllVendorsAndCombined: build.query<
      HTTPResponse<IVendorsAndCombined[]>,
      void
    >({
      query: () => ({ url: '/vendors/all-vendors-combined' }),
      providesTags: ['Vendors', 'VENDOR', COMBINE_CLIENT],
    }),

    getAllVenAndComSearch: build.query<
      HTTPResponse<IVendorsAndCombined[]>,
      string
    >({
      query: (search) => ({
        url: `/vendors/all-vendors-combined?search=${search}`,
      }),
      providesTags: ['Vendors', 'VENDOR', COMBINE_CLIENT],
    }),

    getProductWiseVendorsAndCombined: build.query<
      HTTPResponse<IVendorsAndCombinedInterface[]>,
      number
    >({
      query: (id) => ({ url: `/vendors/all-vendors-combined/${id}` }),
      providesTags: ['Vendors', 'VENDOR', COMBINE_CLIENT],
    }),

    getPaymentMethod: build.query<
      HTTPResponse<{ acctype_id: number; acctype_name: string }[]>,
      void
    >({
      query: () => ({
        url: '/vendors/get-payment-method',
      }),
      providesTags: [{ type: 'Payments', id: 'PAYMENT_METHOD' }],
    }),

    /**
     * get a single vendor
     */
    getSingleVendor: build.query<HTTPResponse<ISingleVendor>, number>({
      query: (arg) => ({
        url: `/vendors/for-edit/${arg}`,
      }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [{ type: 'Vendors', id: id } as const],
    }),
    getVendorDetails: build.query<HTTPResponse<ISingleVendorDetails>, number>({
      query: (id) => ({
        url: `/vendors/vendor/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Payments', id: 'GET_VENDOR_PAYMENT' }],
    }),

    /**
     * get single vendor and combine balance
     */

    getSingleVendorCombineAmount: build.query<
      HTTPResponse<IVendorDetails>,
      string
    >({
      query: (id) => ({
        url: `/vendors/vendors-lastbalance/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Payments', id: 'GET_VENDOR_PAYMENT' }],
    }),

    /**
     * update vendor activity status
     */
    updateVendorStatus: build.mutation<HTTPResponse<void>, IVendorStatus>({
      query: (body) => ({
        url: `/vendors/update-status/${body.id}`,
        method: 'PATCH',
        body: {
          vendor_activity_status: body.status,
          updated_by: body.updated_by,
        },
      }),
      onQueryStarted: async ({ id, status }, { dispatch, queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          const vendorDraft = (
            draft: MaybeDrafted<HTTPResponse<IAllVendors[]>>
          ) => {
            if (draft.data) {
              let index = draft.data.findIndex(
                (item: any) => item.vendor_id === id
              );

              draft.data[index] = {
                ...draft.data[index],
                vendor_activity_status: status as any,
              };
            }
          };

          const vendorAction = vendorEndpoints.util.updateQueryData(
            'getAllVendors',
            '',
            vendorDraft
          );

          dispatch(vendorAction);
        });
      },
      invalidatesTags: [
        'Dashboard',
        { type: 'Vendors', id: 'LOGS' },
        { type: 'Vendors', id: 'VENDOR' },
        COMBINE_CLIENT,
      ],
    }),

    /**
     * all a vendor
     */
    addVendor: build.mutation<
      HTTPResponse<{ vendor_id: number }>,
      IVendorFormData
    >({
      query: (body) => ({
        url: '/vendors/add',
        method: 'POST',
        body,
      }),

      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await assyncWrapper(async () => {
          const { data } = await queryFulfilled;

          const vendorDraft = (
            draft: MaybeDrafted<HTTPResponse<IAllVendors[]>>
          ) => {
            if (draft.data) {
              const {
                vendor_name,
                vendor_email,
                vendor_mobile,
                vendor_opening_balance,
                vendor_opening_balance_pay_type,
              } = arg;

              const openBalance =
                vendor_opening_balance_pay_type === 'Advance'
                  ? (vendor_opening_balance as number)
                  : vendor_opening_balance_pay_type === 'PrevDue'
                  ? -(vendor_opening_balance as number)
                  : 0;

              const dataToInsert = {
                vendor_id: data.data?.vendor_id as number,
                vendor_name,
                vendor_email,
                vendor_mobile,
                vendor_lbalance: openBalance,
                vendor_activity_status: 1,
              };

              draft.data.unshift(dataToInsert as IAllVendors);
            }
          };

          const vendorAction = vendorEndpoints.util.updateQueryData(
            'getAllVendors',
            '',
            vendorDraft
          );

          dispatch(vendorAction);
        });
      },
      invalidatesTags: [
        { type: 'Vendors', id: 'LOGS' },
        { type: 'Vendors', id: 'VENDOR' },
        COMBINE_CLIENT,
      ],
    }),

    /**
     * update a vendor
     */
    editVendor: build.mutation<
      HTTPResponse<void>,
      { id: number; values: IEditVendor }
    >({
      query: (body) => ({
        url: `/vendors/edit/${body.id}`,
        method: 'PUT',
        body: body.values,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          const vendorDraft = (
            draft: MaybeDrafted<HTTPResponse<IAllVendors[]>>
          ) => {
            if (draft.data) {
              const { vendor_name, vendor_email, vendor_mobile } = arg.values;

              const dataToUpdate = {
                vendor_name,
                vendor_email,
                vendor_mobile,
              };

              let index = draft.data?.findIndex(
                (item) => item.vendor_id === arg.id
              );

              draft.data[index] = {
                ...draft.data[index],
                ...dataToUpdate,
              };
            }
          };

          const updateAction = vendorEndpoints.util.updateQueryData(
            'getAllVendors',
            '',
            vendorDraft
          );

          dispatch(updateAction);
        });
      },
      invalidatesTags: (_res, _err, { id }) => [
        { type: 'Vendors', id },
        { type: 'Vendors', id: 'LOGS' },
        { type: 'Vendors', id: 'VENDOR' },
        COMBINE_CLIENT,
      ],
    }),
  }),
});

export const {
  useGetPaymentMethodQuery,
  useGetAllVendorsQuery, // getAll vendor and search parameter
  useLazyGetAllVendorsQuery, // get all vendor and search parameter for Select
  useGetVendorsQuery,
  useGetSingleVendorQuery,
  useLazyGetSingleVendorQuery,
  useUpdateVendorStatusMutation,
  useAddVendorMutation,
  useEditVendorMutation,
  useDeleteVendorMutation,
  useLazyGetSingleVendorCombineAmountQuery,
  useLazyGetVendorExcelReportQuery,
  useGetAllVendorsAndCombinedQuery,
  useLazyGetAllVenAndComSearchQuery,

  useGetAllVenAndComSearchQuery,

  useLazyGetProductWiseVendorsAndCombinedQuery,
  useGetProductWiseVendorsAndCombinedQuery,
  useLazyGetVendorDetailsQuery,
} = vendorEndpoints;
