import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import TourBillingInfo from '../Components/Components/TourBillingInfo';
import TourHeaderForm from '../Components/InvoiceTourHeader/TourHeaderForm';
import {
  useLazyGetInvoiceTourEditByIdQuery,
  useUpdateInvoiceTourMutation,
} from '../Endpoints/GenerateInvoiceEndpoints';
import AddCostingTour from '../Modals/AddCostingTour';
import {
  IInvoiceTourBilling,
  IInvoiceTourPostEditDataType,
  IInvoiceTourPostEditFromDataType,
} from '../Types/InvoiceTourTypes';
import {
  Fixed2,
  getAirportByItaCode,
  getItaCodeByAirportIds,
} from '../../../common/utils/common.utils';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { SelectAirport } from '../../../components/common/FormItem/SelectCustomFeilds';

type Props = {};
const EditInvoiceTour = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const user = useSelector(selectUser);
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const [
    updateTour,
    { isSuccess: isSuccessUpdate, isError: isErrorUpdate, error, isLoading },
  ] = useUpdateInvoiceTourMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;
  const [
    getInvoiceTourById,
    { data: tourEditData, isLoading: fetchIsLoading },
  ] = useLazyGetInvoiceTourEditByIdQuery();

  const onFinish = (values: IInvoiceTourPostEditFromDataType) => {
    const tourBilling = values.tourBilling.map((el: IInvoiceTourBilling) => {
      return {
        is_deleted: el.is_deleted,
        billing_product_id: el.billing_product_id,
        billing_pax_name: el.billing_pax_name,
        billing_total_sales: el.billing_total_sales,
        billing_cost_price: el.billing_cost_price,
        billing_profit: el.billing_profit,
        billing_total_pax: el.billing_total_pax,
        billing_country_id: el.billing_country_id,
        billing_numof_room: el.billing_numof_room,
      };
    });
    const body: IInvoiceTourPostEditDataType = {
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_sales_date: dayjs(values.invoice_sales_date).format('YYYY-MM-DD'),
      invoice_due_date: dayjs(values.invoice_due_date).format('YYYY-MM-DD'),
      invoice_no: values.invoice_no,
      itour_group_id: values.itour_group_id,
      itour_day: values.itour_day,
      itour_night: values.itour_night,
      invoice_agent_id: values.invoice_agent_id,
      invoice_sub_total: values.invoice_sub_total,
      invoice_discount: values.invoice_discount,
      invoice_service_charge: values.invoice_service_charge,
      invoice_vat: values.invoice_vat,
      invoice_net_total: values.invoice_net_total,
      invoice_agent_com_amount: values.invoice_agent_com_amount,
      invoice_client_previous_due: values.invoice_client_previous_due,
      client_present_balance: values.client_present_balance,
      invoice_note: values.invoice_note,
      invoice_reference: values.invoice_reference,

      tourTransports: values.tourTransports[0].transport_itinerary_id
        ? values.tourTransports.map((item) => ({
            ...item,
            transport_is_deleted: item.transport_is_deleted,
            transport_cost_price: Number(item.transport_cost_price || 0),
          }))
        : [],
      tourFoods: values.tourFoods[0].food_itinerary_id
        ? values.tourFoods.map((item) => ({
            ...item,
            food_is_deleted: item.food_is_deleted,
            food_menu: item.food_menu || undefined,
            food_cost_price: Number(item.food_cost_price || 0),
          }))
        : [],
      tourAccms: values.tourAccms[0].accm_itinerary_id
        ? values.tourAccms.map((item) => ({
            ...item,
            accm_description: item.accm_description || undefined,
            accm_is_deleted: item.accm_is_deleted,
            accm_cost_price: Number(item.accm_cost_price || 0),
          }))
        : [],
      tourOtherTrans: values.tourOtherTrans[0].other_trans_itinerary_id
        ? values.tourOtherTrans.map((item) => ({
            ...item,
            other_trans_description: item.other_trans_description || undefined,
            other_trans_is_deleted: item.other_trans_is_deleted,
            other_trans_cost_price: Number(item.other_trans_cost_price || 0),
          }))
        : [],

      ticket_no: values?.ticket_no,
      ticket_pnr: values?.ticket_pnr,
      ticket_route: values?.ticket_route
        ? (getAirportByItaCode(
            values?.ticket_route as string,
            selectAirport
          ) as string[])
        : undefined,
      ticket_journey_date: values?.ticket_journey_date,
      ticket_return_date: values?.ticket_return_date,
      ticket_airline_id: values?.ticket_airline_id,
      ticket_cost_price: values?.ticket_cost_price
        ? Number(values.ticket_cost_price)
        : undefined,
      ticket_comvendor_id: values.ticket_comvendor_id,

      guide_itinerary_id: values.guide_itinerary_id,
      guide_description: values.guide_description,
      guide_cost_price: values.guide_cost_price
        ? Number(values.guide_cost_price || 0)
        : undefined,
      guide_comvendor_id: values.guide_comvendor_id,

      itour_from_date: dayjs(values.tour_invoice_journey_date[0]).format(
        'YYYY-MM-DD'
      ),
      itour_to_date: dayjs(values.tour_invoice_journey_date[1]).format(
        'YYYY-MM-DD'
      ),
      invoice_created_by: user?.user_id as number,
      tourBilling: tourBilling,
    };

    body.tourBilling = body.tourBilling.map((item, index) => ({
      ...item,
      billing_id: tourEditData?.data?.tourBilling[index]?.billing_id,
    }));
    body.tourTransports = body.tourTransports.map((item, index) => ({
      ...item,
      transport_id: tourEditData?.data?.tourTransports[index]?.transport_id,
    }));
    body.tourFoods = body.tourFoods.map((item, index) => ({
      ...item,
      food_id: tourEditData?.data?.tourFoods[index]?.food_id,
    }));
    body.tourAccms = body.tourAccms.map((item, index) => ({
      ...item,
      accm_id: tourEditData?.data?.tourAccms[index]?.accm_id,
    }));
    body.tourOtherTrans = body.tourOtherTrans.map((item, index) => ({
      ...item,
      other_trans_id: tourEditData?.data?.tourOtherTrans[index]?.other_trans_id,
    }));

    if (id) {
      const currentNetTotal = Fixed2(body?.invoice_net_total);
      const prevNetTotal = Fixed2(tourEditData?.data?.invoice_net_total);
      if (prevNetTotal >= currentNetTotal) {
        updateTour({ body: body, id: Number(id) });
      } else {
        checkCreditLimit({
          amount: currentNetTotal - prevNetTotal,
          combClient: body.invoice_combclient_id,
        }).then((res: any) => {
          if (res?.data?.data === 1) {
            updateTour({ body: body, id: Number(id) });
          } else {
            message.error(res?.data?.data);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccessUpdate) {
      message.success('Invoice tour has been updated!');

      updateAndPrev
        ? navigate(`/invoiceTour/details/${id}`)
        : navigate('/tour-invoice/all');
    }
    if (isErrorUpdate) {
      message.error('tourInvoice can not update!');
    }
  }, [isSuccessUpdate, isErrorUpdate]);

  // ================== EDIT =====================

  useEffect(() => {
    if (id) {
      getInvoiceTourById(id);
    }
  }, [id]);

  useEffect(() => {
    if (tourEditData) {
      form.setFieldsValue({
        invoice_combclient_id: tourEditData?.data?.invoice_combclient_id,
        invoice_sales_man_id: tourEditData?.data?.invoice_sales_man_id,
        itour_group_id: tourEditData?.data?.itour_group_id,
        invoice_agent_id: tourEditData?.data?.invoice_agent_id,
        invoice_sub_total: tourEditData?.data?.invoice_sub_total,
        invoice_discount: tourEditData?.data?.invoice_discount,
        invoice_service_charge: tourEditData?.data?.invoice_service_charge,
        invoice_vat: tourEditData?.data?.invoice_vat,
        invoice_net_total: tourEditData?.data?.invoice_net_total,
        invoice_sales_date: tourEditData?.data?.invoice_sales_date
          ? dayjs(tourEditData?.data?.invoice_sales_date)
          : undefined,
        invoice_due_date: tourEditData?.data?.invoice_due_date
          ? dayjs(tourEditData?.data?.invoice_due_date)
          : undefined,
        itour_day: tourEditData?.data?.itour_day,
        itour_night: tourEditData?.data?.itour_night,
        tour_invoice_journey_date: [
          dayjs(tourEditData?.data?.itour_from_date),
          dayjs(tourEditData?.data?.itour_to_date),
        ],

        tourBilling: tourEditData?.data?.tourBilling,
        invoice_note: tourEditData?.data?.invoice_note || undefined,
        invoice_reference: tourEditData?.data?.invoice_reference || undefined,

        tourTransports: tourEditData.data?.tourTransports.map((item: any) => ({
          ...item,

          transport_picup_time: item.transport_picup_time
            ? dayjs(item.transport_picup_time)
            : null,
          transport_dropoff_time: item.transport_dropoff_time
            ? dayjs(item.transport_dropoff_time)
            : null,
        })),

        tourFoods: tourEditData.data?.tourFoods.map((item: any) => ({
          ...item,
          food_time: item.food_time ? dayjs(item.food_time) : undefined,
          food_cost_price_edit: Number(item.food_cost_price || 0),
        })),

        tourAccms: tourEditData.data?.tourAccms.map((item: any) => ({
          ...item,

          accm_checkin_date: item.accm_checkin_date
            ? dayjs(item.accm_checkin_date)
            : null,
          accm_checkout_date: item.accm_checkout_date
            ? dayjs(item.accm_checkout_date)
            : null,
        })),
        tourOtherTrans: tourEditData.data?.tourOtherTrans,

        ...tourEditData.data?.tourGuide,
        ...tourEditData.data?.tourTicket,
        ticket_journey_date: tourEditData.data?.tourTicket?.ticket_journey_date
          ? dayjs(tourEditData.data?.tourTicket.ticket_journey_date)
          : undefined,
        ticket_return_date: tourEditData.data?.tourTicket?.ticket_return_date
          ? dayjs(tourEditData.data?.tourTicket?.ticket_return_date)
          : undefined,
        ticket_route: tourEditData.data?.tourTicket?.ticket_route?.length
          ? getItaCodeByAirportIds(
              [...tourEditData.data?.tourTicket.ticket_route] as string[],
              selectAirport
            )
          : undefined,
      });
    }
  }, [tourEditData, id]);

  const billingInfo: IInvoiceTourBilling[] = useWatch(['tourBilling'], form);

  //set subtotal
  useEffect(() => {
    form.setFieldValue(
      'invoice_sub_total',
      billingInfo?.reduce(
        (a, b) =>
          a + (b?.is_deleted === 1 ? 0 : Fixed2(b?.billing_total_sales || 0)),
        0
      ) || 0
    );
  }, [billingInfo]);

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice', 'Tour Edit']} />

      {/* EDIT DATA FETCH LOADING */}
      {fetchIsLoading ? <LoadingIndicator /> : ''}

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <TourHeaderForm
          form={form}
          isEdit
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
        />
        <AddCostingTour form={form} invoiceTourDetails={tourEditData?.data} />
        <TourBillingInfo
          form={form}
          billInitialValues={undefined}
          tourBillingsLength={tourEditData?.data?.tourBilling?.length}
        />
        <InvoiceVisaSubTotalForm form={form} />

        {/* <InvoiceMoneyReceipt form={form} netTotal={invoice_net_total || 0} /> */}

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={isLoading}
              label='UPDATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={isLoading}
              label='UPDATE & VIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditInvoiceTour;
