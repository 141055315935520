import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { GDSREPORT } from '../../Api/constants';
import {
  IGDSReportFromDataType,
  IGDSReportsDataType,
} from '../types/GdsReportType';

export const GdsReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all GDSReport
    getGDSReport: build.query<
      HTTPResponse<IGDSReportsDataType[]>,
      IGDSReportFromDataType
    >({
      query: (body) => ({
        url: `/report/gds-report/${body.gds}?${body.date_range}`,
      }),
      providesTags: [{ type: 'GDSReport', id: GDSREPORT }],
    }),
  }),
});

export const { useLazyGetGDSReportQuery } = GdsReportEndpoints;
