import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewInvoiceDetails,
} from '../../../common/types/commonInterfaces';
import {
  HotelInformation,
  TicketInfo,
} from '../../Invoice(Other)/Type/Invoce.other.interface';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { IIUPassengerInfo } from '../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types';
import CostViewSubTotalUmrah from './CostViewSubTotalUmrah';

type Props = {
  invoiceDetails: IViewInvoiceDetails;
};

const hotelColumn: ColumnsType<HotelInformation> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Hotel Name',
    dataIndex: 'hotel_name',
    key: 'hotel_name',
    align: 'center',
  },
  {
    title: 'Check In Date',
    align: 'center',
    render: (_, data) => (
      <>
        {' '}
        {data?.hotel_check_in_date &&
          dayjs(data?.hotel_check_in_date).format('D/MM/YYYY')}{' '}
      </>
    ),
    key: 'hotel_check_in_date',
    // render: (_, data) => dayjs(data.hotel_check_in_date).format('DD-MM-YYYY'),
  },
  {
    title: 'Check Out Date',
    align: 'center',
    render: (_, data) => (
      <>
        {' '}
        {data?.hotel_check_out_date &&
          dayjs(data?.hotel_check_out_date).format('D/MM/YYYY')}{' '}
      </>
    ),
    key: 'hotel_check_out_date',
    // render: (_, data) => dayjs(data.hotel_check_out_date).format('DD-MM-YYYY'),
  },
  {
    title: 'Room Type',
    dataIndex: 'hotel_room_type',
    key: 'hotel_room_type',
    align: 'center',
  },
];
const billingColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Product',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'pax_name',
    dataIndex: 'pax_name',
    key: 'pax_name',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'billing_quantity',
    key: 'billing_quantity',
    align: 'right',
  },
  {
    title: 'Unit price',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    width: '150px',
    align: 'right',
  },
  {
    title: 'Cost price',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    width: '150px',
    align: 'right',
  },
  {
    title: 'Total Sales',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    width: '150px',
    align: 'right',
    render: (text, record) => {
      const quantity = record.billing_quantity;
      const unitPrice = record.billing_unit_price;
      if (quantity != null) {
        const totalPrice = quantity * unitPrice;
        return <span>{totalPrice}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    title: 'Total Cost',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    width: '150px',
    align: 'right',
    render: (text, record) => {
      const quantity = record.billing_quantity;
      const costPrice = record.billing_cost_price;
      if (costPrice != null) {
        const totalPrice = quantity * costPrice;
        return <span>{totalPrice}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    title: 'Profit',
    dataIndex: 'billing_profit',
    key: 'billing_profit',
    width: '150px',
    align: 'right',
    render: (value) => (
      <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
        {Number(value || 0)}
      </span>
    ),
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    align: 'center',
  },
];
const ticketColumn: ColumnsType<TicketInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Ticket No',
    dataIndex: 'ticket_no',
    key: 'ticket_no',
  },
  { title: 'PNR', dataIndex: 'ticket_pnr', key: 'ticket_pnr' },
  {
    title: 'Route',
    dataIndex: 'airline_iata_code',
    key: 'airline_iata_code',
  },
  {
    title: 'Airline',
    dataIndex: 'airline_name',
    key: 'airline_name',
  },
  {
    title: 'Reference No.',
    dataIndex: 'ticket_reference_no',
    key: 'ticket_reference_no',
  },
  {
    title: 'Journey date',
    key: 'ticket_journey_date',
    render: (_, data) => (
      <>
        {' '}
        {data?.ticket_journey_date &&
          dayjs(data?.ticket_journey_date).format('D/MM/YYYY')}{' '}
      </>
    ),
  },
  {
    title: 'Return date',
    key: 'ticket_return_date',
    render: (_, data) => (
      <>
        {' '}
        {data?.ticket_return_date &&
          dayjs(data?.ticket_return_date).format('D/MM/YYYY')}{' '}
      </>
    ),
  },
];
const passengerColumn: ColumnsType<IIUPassengerInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Passport',
    dataIndex: 'passport_passport_no',
    key: 'passport_passport_no',
  },
  { title: 'Name', dataIndex: 'passport_name', key: 'passport_name' },
  {
    title: 'Mobile',
    dataIndex: 'passport_mobile_no',
    key: 'passport_mobile_no',
  },
  {
    title: 'Email',
    key: 'passport_email',

    dataIndex: 'passport_email',
  },
  {
    title: 'Date of birth',

    key: 'passport_date_of_birth',
    render: (_, data) => (
      <>
        {' '}
        {data?.passport_date_of_birth &&
          dayjs(data?.passport_date_of_birth).format('D/MM/YYYY')}{' '}
      </>
    ),
  },
  {
    title: 'Date of issue',
    key: 'passport_date_of_issue',
    render: (_, data) => (
      <>
        {' '}
        {data?.passport_date_of_issue &&
          dayjs(data?.passport_date_of_issue).format('D/MM/YYYY')}{' '}
      </>
    ),
  },
  {
    title: 'Date of expire',
    key: 'passport_date_of_expire',
    render: (_, data) => (
      <>
        {' '}
        {data?.passport_date_of_expire &&
          dayjs(data?.passport_date_of_expire).format('D/MM/YYYY')}{' '}
      </>
    ),
  },
];

const ViewUmrahTavDetails = ({ invoiceDetails }: Props) => {
  return (
    <>
      {invoiceDetails?.passenger_info?.length ? (
        <>
          <FormHeaderTitle title='PASSENGER INFO' />
          <Table
            size='small'
            rowKey={(e) => e.passenger_tracking_number}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails.passenger_info}
            columns={passengerColumn}
            bordered
            pagination={false}
            locale={{ emptyText: ' ' }}
            style={{ marginBottom: '1rem' }}
          />
        </>
      ) : (
        <></>
      )}

      {invoiceDetails?.ticket_information?.length ? (
        <>
          <FormHeaderTitle title='TICKET INFO' />
          <Table
            size='small'
            rowKey={(e) => e.ticket_no as string}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.ticket_information}
            columns={ticketColumn}
            bordered
            pagination={false}
            locale={{ emptyText: ' ' }}
          />
        </>
      ) : (
        <></>
      )}

      {invoiceDetails?.hotel_information?.length ? (
        <>
          <FormHeaderTitle title='HOTEL INFO' />
          <Table
            size='small'
            rowKey={(e) => e.hotel_name as string}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.hotel_information}
            columns={hotelColumn}
            bordered
            pagination={false}
            locale={{ emptyText: ' ' }}
          />
        </>
      ) : (
        <></>
      )}

      {invoiceDetails?.billing_information && (
        <>
          <FormHeaderTitle title='BILLING INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.product_name}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.billing_information}
            columns={billingColumn}
            pagination={false}
            style={{ width: '8.27in' }}
            locale={{ emptyText: ' ' }}
          />
        </>
      )}
      <CostViewSubTotalUmrah invoice_info={invoiceDetails} />
    </>
  );
};

export default ViewUmrahTavDetails;
