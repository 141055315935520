import { Key } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { IEmployeeExpenseReportDataType } from '../types/EmployeeExpenseReportTypes';

export const EmployeeExpenseReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IEmployeeExpenseReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (_, record) => {
        return `${dayjs(record.created_date).format('DD MMM YYYY')}`;
      },
      align: 'left',
    },
    {
      title: 'Employe Name',
      dataIndex: 'employes_name',
      key: 'employes_name',
      align: 'left',
    },

    {
      title: 'Basic Salary',
      dataIndex: 'employes_salary',
      key: 'employes_salary',
      align: 'right',
      render: (_, data) => Number(data.employes_salary),
    },
    {
      title: 'Other Allowance',
      dataIndex: 'payroll_other_aloowance',
      key: 'payroll_other_aloowance',
      align: 'right',
      render: (_, data) => Number(data.payroll_other_aloowance),
    },

    {
      title: 'Net Amount',
      dataIndex: 'payroll_net_amount',
      key: 'payroll_net_amount',
      align: 'right',
      render: (_, data) => Number(data.payroll_net_amount),
    },

    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      align: 'left',
    },
  ];
};
