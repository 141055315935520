import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  message,
} from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { setModal } from '../../../../common/slices/commonSlice';
import BloodSelect from '../components/BloodSelect';
import DepartmentSelect from '../components/DepartmentSelect';
import DesignationSelect from '../components/DesignationSelect';
import { useCreateEmployeeMutation } from '../endpoints/employeeEndpoints';
import { IEmployeeFormData } from '../types/employeeTypes';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import postDateFormat from '../../../../common/utils/postDateFormat';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const EmployeeAddModal = ({
  setClick,
  setInstanceSalesEmpSelect,
  setIsModalOpen,
}: {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setInstanceSalesEmpSelect?:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
  setClick?: (arg: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [form] = Form.useForm();

  const [addEmployee, { data, isError, isSuccess, isLoading }] =
    useCreateEmployeeMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Employee Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleOnSubmit = async (values: IEmployeeFormData) => {
    const body: IEmployeeFormData = {
      ...values,
      employee_created_by: user?.user_id as number,
      employee_birth_date: postDateFormat(values.employee_birth_date),
      employee_apppoint_date: postDateFormat(values.employee_apppoint_date),
      employee_joining_date: postDateFormat(values.employee_joining_date),
    };
    setClick && setClick(false);
    await addEmployee(body);
    dispatch(setModal(false));
    setIsModalOpen && setIsModalOpen(false);
    form.resetFields();
  };

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

  useEffect(() => {
    form.setFieldValue('employee_joining_date', dayjs());
  }, []);
  //   //
  useEffect(() => {
    if (data) {
      setInstanceSalesEmpSelect &&
        setInstanceSalesEmpSelect(data?.data?.employee_id);
    }
  }, [data]);

  return (
    <>
      <Form
        labelAlign='left'
        {...layout}
        onFinish={handleOnSubmit}
        form={form}
        validateMessages={validateMessages}
      >
        <Form.Item
          name='employee_card_id'
          label='ID Card No:'
          rules={[
            { required: true, message: 'Please input Employee Id!' },
            { max: 30, message: 'Maximum 30 characters' },
          ]}
        >
          <Input placeholder='Employee ID Card No' />
        </Form.Item>

        <Form.Item
          name='employee_full_name'
          label='Employee Name :'
          rules={[
            { required: true, message: 'Please input Employee Name!' },
            { max: 45, message: 'Maximum 45 characters' },
          ]}
        >
          <Input placeholder='Employee Name' />
        </Form.Item>

        <DepartmentSelect />
        <DesignationSelect />
        <BloodSelect />
        <Form.Item required label='Salary' name='employee_salary'>
          <InputNumber
            placeholder='Salary'
            style={{ width: '100%' }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
        </Form.Item>

        <Form.Item label='Commission' name='employee_commission'>
          <InputNumber
            placeholder='Commission'
            style={{ width: '100%' }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
        </Form.Item>
        <Form.Item
          name='employee_email'
          label='Email :'
          rules={[{ type: 'email', message: 'Please input valid email' }]}
        >
          <Input placeholder='Email' />
        </Form.Item>
        <Form.Item
          name='employee_mobile'
          label='Mobile :'
          rules={[
            { required: true, message: 'Please input Employee Mobile!' },
            {
              message: 'Invalid number format',
              validator: (_, value) => {
                if (
                  !value ||
                  (/^\+?[0-9+]+$/.test(value) &&
                    value.length >= 10 &&
                    value.length <= 16)
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    'Invalid number format. Must be between 10 and 16 characters.'
                  );
                }
              },
            },
          ]}
        >
          <Input placeholder='Mobile' />
        </Form.Item>
        <Form.Item name='employee_birth_date' label='Birth Date :'>
          <DatePicker format={'DD-MM-YYYY'} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='employee_apppoint_date' label='Appointment Date :'>
          <DatePicker format={'DD-MM-YYYY'} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='employee_joining_date' label='Joining Date :'>
          <DatePicker format={'DD-MM-YYYY'} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='employee_address' label='Address :'>
          <TextArea placeholder='Address' maxLength={55} />
        </Form.Item>
        <Form.Item
          name='employee_status'
          label='Status :'
          rules={[{ required: true, message: 'Please input status!' }]}
          initialValue={1}
        >
          <Radio.Group>
            <Radio value={1}>Active</Radio>
            <Radio value={0}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default EmployeeAddModal;
