import { Table } from 'antd';
import { useState } from 'react';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAllInvoiceClientQuery } from '../../api/endpoints/clientEndpoints';
import { clientInvoiceUtil } from '../../utils/utils_tsx/clientInvoiceUtil';

type Props = { id: string };

const ClientDetailInvoice = ({ id }: Props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading } = useGetAllInvoiceClientQuery({
    ...pagination,
    id,
  });

  return (
    <div>
      <Table
        size='small'
        bordered
        columns={clientInvoiceUtil()}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ClientDetailInvoice;
