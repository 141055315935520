import { Button, Space, Switch, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Popconfirm from 'antd/lib/popconfirm';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../../common/types/commonTypes';
import {
  useDeleteCombineClientOneMutation,
  useUpdateCombineClientStatusMutation,
} from '../../api/endpoints/clientCombineEndpoints';
import {
  ICombineClientView,
  ICombineStatus,
} from '../../types/combineClientTypes';
import { MessageOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
type Props = {
  permission?: IPermission;
  checkButtonState?: boolean;
  setEditInfo: React.Dispatch<React.SetStateAction<ICombineClientView | null>>;
  handleModal: () => void;
  pagination: {
    current: number;
    pageSize: number;
  };
};
export default function viewCombineColumn({
  permission,
  checkButtonState,
  setEditInfo,
  handleModal,
  pagination,
}: Props): ColumnsType<ICombineClientView> {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const handleClick = (record: ICombineClientView) => {
    setEditInfo(record);
    handleModal();
  };

  const [changeStatusId, setChangeStatusId] = useState<number>();
  const [updateCombineStatus, { isLoading: statusIsLoading }] =
    useUpdateCombineClientStatusMutation();

  const checkStatus = async (e: ICombineStatus) => {
    const body = {
      ...e,
      combine_client_status: e.combine_client_status ? 0 : 1,
      updated_by: user?.user_id,
    };
    await updateCombineStatus(body);
  };

  const user = useSelector(selectUser);
  const [
    handleClientDelete,
    { isError: isErrorDelete, isSuccess: isSuccessDelete, isLoading },
  ] = useDeleteCombineClientOneMutation();

  useEffect(() => {
    if (isSuccessDelete) {
      setColumnIndex(null);
    } else if (isErrorDelete) {
      setColumnIndex(null);
    }
  }, [isSuccessDelete, isErrorDelete]);
  return [
    {
      title: 'Sl.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'combine_name',
      key: 'combine_name',
      render: (_, data) => (
        <Link to={`/reports/combined_ledger?combine_id=${data.combine_id}`}>
          {data.combine_name}
        </Link>
      ),
    },

    {
      title: 'Contact',
      dataIndex: 'combine_mobile',
      key: 'combine_id',
    },

    {
      title: 'Email',
      dataIndex: 'combine_email',
      key: 'combine_id',
    },

    {
      title: 'Present Due/Advance',
      dataIndex: 'client_last_balance',
      key: 'client_last_balance',
      render: (_, record) => {
        const lastBalance = Number(record.combine_lastbalance_amount || 0);
        return (
          <>
            {lastBalance > 0 && (
              <>
                <div>
                  Advance :{' '}
                  <span style={{ color: 'green' }}>{lastBalance}</span>
                </div>
              </>
            )}
            {lastBalance === 0 && (
              <>
                <div>Balance : {lastBalance}</div>
              </>
            )}
            {lastBalance < 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  Due : <span style={{ color: 'red' }}>{lastBalance * -1}</span>
                </div>

                <Button
                  type='text'
                  size='small'
                  onClick={() => {
                    handleClick(record);
                  }}
                >
                  <MessageOutlined />
                </Button>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_id',
    },
    {
      title: 'Status',
      dataIndex: 'client_activity_status',
      key: 'client_activity_status',
      render: (_, { combine_client_status, combine_id }) => (
        <>
          <Popconfirm
            title={`${
              combine_client_status ? 'Sure to Inactive?' : 'Sure to active?'
            }`}
            onConfirm={() => {
              setChangeStatusId(combine_id);
              checkStatus({ combine_client_status, combine_id });
            }}
          >
            <Switch
              checkedChildren='Active'
              loading={changeStatusId === combine_id && statusIsLoading}
              unCheckedChildren='Inactive'
              checked={combine_client_status}
              style={{
                backgroundColor: combine_client_status ? 'green' : '#ff4d4f',
              }}
            />
          </Popconfirm>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => {
        const { combine_lastbalance_amount: client_last_balance } = record;
        return (
          <Space size='small'>
            {!checkButtonState && permission?.['read:any'] && (
              <>
                <Link
                  to={`/combineClients/details/${record.combine_id}`}
                  state={location.pathname}
                >
                  <Button size='small' type='primary'>
                    <Typography style={{ color: '#ffffff' }}>View</Typography>
                  </Button>
                </Link>
                {permission?.[crud.update] && (
                  <Link
                    to={`/combine_client/edit/${record.combine_id}`}
                    state={location.pathname}
                  >
                    <Button size='small' type='primary'>
                      <Typography style={{ color: '#ffffff' }}>Edit</Typography>
                    </Button>
                  </Link>
                )}

                {permission?.[crud.delete] && record.has_trxn === 'false' && (
                  <Popconfirm
                    title='Sure to delete?'
                    onConfirm={() => {
                      handleClientDelete({
                        body: { combine_update_by: user?.user_id as number },
                        id: String(record?.combine_id),
                      });
                      setColumnIndex(index);
                    }}
                  >
                    <Button
                      danger
                      size='small'
                      type='primary'
                      loading={columnIndex === index && true}
                    >
                      <Typography style={{ color: '#ffffff' }}>
                        Delete
                      </Typography>
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Space>
        );
      },
    },
  ];
}
