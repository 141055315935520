import { Divider, Typography } from 'antd';

type Props = { title: string; margintop?: number };

function FormHeaderTitle({ title, margintop }: Props) {
  const handleClick = () => {};

  return (
    <Divider orientation='left' plain>
      <Typography.Title onClick={handleClick} level={5}>
        {title}
      </Typography.Title>
    </Divider>
  );
}

export default FormHeaderTitle;
