import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import moment from 'moment';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { IViewBalanceTransfer } from '../../AccountsTypes/AccountsTypes';
import { useGetViewBalanceTransferQuery } from '../../Api/Endpoints/balanceTransferEndpoints';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
const ViewBalanceTransfer = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetViewBalanceTransferQuery(
    Number(id)
  );
  const { Text, Title } = Typography;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `BalanceTranfer`,
    pageStyle: '',
  });
  const a4sizeStyle: React.CSSProperties = {
    minHeight: '11.5in',
    width: '8.27in',
    fontSize: '11px',
    background: '#fff',
    margin: '0 auto',
  };
  const column: ColumnsType<IViewBalanceTransfer> = [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => index + 1,
    },

    {
      title: 'Date',
      key: 'btransfer_date',
      dataIndex: 'btransfer_date',
      render: (curr) => {
        return `${dayjs(curr).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Transfer From',
      dataIndex: 'from_account_name',
      key: 'from_account_name',
    },
    {
      title: 'Transfer To',
      dataIndex: 'to_account_name',
      key: 'to_account_name',
    },

    {
      title: 'Amount',
      dataIndex: 'btransfer_amount',
      key: 'btransfer_amount',
      render: (_, record) => Number(record.btransfer_amount),
    },
  ];

  return (
    <>
      <BreadCrumb
        arrOfOption={['Dashboard', 'Accounts', 'View BalanceTransfer']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/accounts/balance_transfer'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to BalanceTransfer List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Card hoverable ref={componentRef} style={a4sizeStyle}>
          <InvoiceHeader />

          <TitleCenter title='Balance Transfer' />
          <Row justify={'end'}>
            <Col>
              <Typography.Title
                style={{
                  fontSize: '13px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
                level={5}
              ></Typography.Title>

              <Typography
                style={{
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Account : {data?.data && data?.data[0]?.from_account_name}
              </Typography>

              {
                /* singleExpense?.data?.expense_cheque_no &&  */ <Typography
                  style={{
                    fontSize: '12px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Voucher : {data?.data && data?.data[0]?.voucher_no}
                </Typography>
              }
            </Col>
          </Row>
          <div style={{ minHeight: '600px' }}>
            <Table
              size='small'
              bordered
              pagination={false}
              columns={column}
              dataSource={data?.data}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              style={{ marginTop: '20px' }}
              loading={{
                spinning: isLoading || isFetching,
                indicator: <LoadingIndicator />,
              }}
            />

            <Row
              justify={'space-around'}
              align={'middle'}
              style={{
                margin: '25px 0',
              }}
            >
              <Typography
                style={{
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                Note :{' '}
                {data?.data &&
                data?.data[0]?.btransfer_note &&
                data?.data[0]?.btransfer_note !== 'undefined'
                  ? data?.data[0]?.btransfer_note
                  : ''}
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {data?.data && (
                  <NumToWord
                    number={Number(data?.data[0]?.btransfer_amount || 0)}
                  />
                )}
              </Typography>
            </Row>
          </div>
          {/* ================== signature ===================== */}
          <Row
            className='signature'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '120px',
            }}
          >
            <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Received By
            </Typography.Text>
            <Row justify={'center'}>
              <Typography.Text
                style={{
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {moment().format('ll LTS')}
              </Typography.Text>
            </Row>
            <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Approved By
            </Typography.Text>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

export default ViewBalanceTransfer;
