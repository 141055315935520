import { Col, Divider, Form, Row, Select } from 'antd';
import FileUpload from '../../../common/utils/FileUpload';
import {
  DateInput,
  FormInputItem,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectCountry,
  SelectEmployee,
  SelectPassport,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { FormInstance } from 'antd/lib';

type Props = {
  name: number;
  form: FormInstance<any>;
};

const VisaAssignFormItem = ({ name, form }: Props) => {
  return (
    <>
      <Divider orientation='left'>Passport No: {name + 1}</Divider>
      <Row gutter={5}>
        <SelectEmployee
          name={[name, 'tvw_assign_by']}
          label='Select Employee'
          required
          size={8}
          offDropDown
        />
        <SelectPassport
          name={[name, 'tvw_passport_id']}
          label='Passport No'
          size={8}
          placeholder='select passport'
        />
        <FormInputItem
          name={[name, 'tvw_passport_quantity']}
          label='Quantity'
          size={8}
          placeholder='passport quantity'
        />
        <SelectCountry
          label='Country'
          name={[name, 'tvw_country_id']}
          size={6}
        />
        <DateInput
          label=' Delivery Date'
          name={[name, 'tvw_visa_deliver_date']}
          size={6}
        />
        <DateInput
          label=' Expire Date'
          name={[name, 'tvw_visa_expire_date']}
          size={6}
        />

        <Col span={6} xs={24} sm={12} md={8} lg={6}>
          <Form.Item label='Status' name={[name, 'tvw_status']}>
            <Select
              placeholder={'Select employee'}
              showSearch
              allowClear
              style={{ padding: '0', margin: '0', border: '0' }}
              options={[
                {
                  label: 'Pending',
                  value: 'PENDING',
                },
                {
                  label: 'Approved',
                  value: 'APPROVED',
                },
                {
                  label: 'Delivered',
                  value: 'DELIVERED',
                },
                {
                  label: 'Rejected',
                  value: 'REJECTED',
                },
              ]}
            />
          </Form.Item>
        </Col>

        <TextAreaInput
          row={2}
          size={12}
          label='Remarks'
          name={[name, 'tvw_remarks']}
        />
        <Col span={6} xs={24} sm={12} md={8} lg={12}>
          <Form.Item
            label='Upload Docs'
            name={[name, 'doc']}
            valuePropName='payroll_image_url'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name={'doc'}
              msg='Doc image'

              // imagURL={getForEditData?.data?.payroll_image_url}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default VisaAssignFormItem;
