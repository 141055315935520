import { ColumnsType } from 'antd/lib/table';
import { ISMSDataType } from '../SMSTypes/SMSTypes';
import dayjs from 'dayjs';
import useGetSearchColumnProps from '../../../common/hooks/SearchData';

export const SMSLogUtils = (): ColumnsType<ISMSDataType> => {
  const searchColumnProps = useGetSearchColumnProps<ISMSDataType>();
  return [
    {
      title: 'SL.',
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Date',
      key: 'smslog_create_date',
      render: (_, record) => {
        return `${dayjs(record.smslog_create_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Name',
      dataIndex: 'client_name',
      key: 'client_name',
      ...searchColumnProps('client_name'),
    },
    {
      title: 'Mobile',
      dataIndex: 'smslog_mobile',
      key: 'smslog_mobile',
      ...searchColumnProps('smslog_mobile'),
    },
    {
      title: 'Type',
      dataIndex: 'smslog_for',
      key: 'smslog_for',
    },
    {
      title: 'Message',
      dataIndex: 'smslog_content',
      key: 'smslog_content',
    },
    {
      title: 'Status',
      dataIndex: 'smslog_delivery_status',
      key: 'smslog_delivery_status',
    },
  ];
};
