import { Card, List, Skeleton } from 'antd';
import { Fixed2 } from '../../../common/utils/common.utils';
import { IcurrAccStatus } from '../interfaces/dashboard.interfaces';
import DashboardTitle from './DashboardTitle';
import { IDarkMode } from './InvoiceDetails';
import { Link } from 'react-router-dom';

type Props = {
  accountBalInsight: IcurrAccStatus[] | undefined;
  isLoading: boolean;
};

const Account_Details_Insight = ({
  accountBalInsight,
  isDarkMode,
  isLoading,
}: Props & IDarkMode) => {
  if (isLoading) {
    return (
      <Card style={{ height: '100%' }}>
        <Skeleton title={false} paragraph={{ rows: 8 }} active />
      </Card>
    );
  }
  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle title='ACCOUNT DETAILS' link='/accounts' />

      <div
        className='custom-scrollbar'
        style={{
          height: 290,
          overflow: 'auto',
          padding: '10px 20px',
        }}
      >
        <List
          itemLayout='horizontal'
          dataSource={accountBalInsight}
          renderItem={(item) => (
            <Link to={`/accounts/account_statement/${item.account_id}`}>
              <List.Item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'justify-between',
                  marginBottom: '10px',
                  boxShadow:
                    'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                  flex: 1,
                  padding: '10px',
                }}
              >
                <List.Item.Meta
                  style={{ flex: 1, flexGrow: 1 }}
                  title={
                    <h4
                      style={{ color: '#1F8BCB', textTransform: 'capitalize' }}
                    >
                      {item.account_name}
                    </h4>
                  }
                  description={
                    <>
                      <span className='d-block'>
                        {`Balance: ${Fixed2(item.balance).toLocaleString()}`}
                      </span>
                      <span>Bank Name: {item.bank_name || 'N/A'}</span>
                    </>
                  }
                />
                <div
                  style={{
                    fontSize: 12,
                    color: '#747264',
                    flex: 1,
                    textAlign: 'right',
                  }}
                >
                  <p>Account No: {item.account_no || 'N/A'}</p>
                  <p>Routing No: {item.account_routing_no || 'N/A'}</p>

                  <p>Branch: {item.branch || 'N/A'}</p>
                </div>
              </List.Item>
            </Link>
          )}
        />
      </div>
    </div>
  );
};

export default Account_Details_Insight;
