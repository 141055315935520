import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IJourneyDateWiseDue } from '../Interfaces/AirTicketReport.interfaces';

export const JourneyDateWiseDueColumn = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IJourneyDateWiseDue> => {
  return [
    {
      title: 'SL.',
      key: 'invoice_id',
      width: 70,
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Sales Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (curr) => {
        return `${dayjs(curr).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Journey Date',
      dataIndex: 'airticket_journey_date',
      key: 'airticket_journey_date',
      render: (curr) => {
        if (curr) return `${dayjs(curr).format('DD MMM YYYY')}`;
        return 'N/A';
      },
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (curr, rec) => {
        return (
          <Link
            to={`../${
              findInvoiceRoute(rec.invoice_category_id) +
              '/' +
              'details' +
              '/' +
              rec.invoice_id
            }`}
          >
            {curr}
          </Link>
        );
      },
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      render: (curr: string) => (
        <span>
          {curr?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },

    {
      title: 'Sales Amount',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      align: 'right',

      render: (curr) => {
        return <> {Number(Fixed2(curr)).toLocaleString()} </>;
      },
    },

    {
      title: 'Collect Amount',
      dataIndex: 'total_paid',
      key: 'total_paid',
      align: 'right',

      render: (curr) => {
        return <> {Number(Fixed2(curr)).toLocaleString()} </>;
      },
    },
    {
      title: 'Due Amount',
      dataIndex: 'total_due',
      key: 'total_due',
      align: 'right',

      render: (curr, rec) => {
        return <>{Fixed2(curr).toLocaleString()}</>;
      },
    },
  ];
};
