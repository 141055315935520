import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission } from '../../../../common/types/commonTypes';
import { useDeleteAirticketRefundMutation } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import {
  IAirTicketRefundDataType,
  IRefundDeleteRestoreBody,
} from '../../RefundTypes/RefundTypes';

export const AirTicketViewUtils = ({
  permission,
  pagination,
  checkButtonState,
}: {
  permission?: IPermission;
  checkButtonState?: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAirTicketRefundDataType> => {
  const user = useSelector(selectUser);
  const [deleteAirticketRefund, { isLoading, isSuccess, isError }] =
    useDeleteAirticketRefundMutation();

  const handleRefundDelete = async (
    values: IAirTicketRefundDataType,
    index: number
  ) => {
    const body: IRefundDeleteRestoreBody = {
      refund_id: values.atrefund_id,
      deleted_by: user?.user_id as number,
    };

    await deleteAirticketRefund(body);
  };

  return [
    {
      title: 'SL.',
      align: 'center',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Created Date',
      dataIndex: 'atrefund_create_date',
      key: 'atrefund_create_date',
      align: 'center',
      render: (_, data) => (
        <>{dayjs(data.atrefund_create_date).format('DD MMM YYYY')} </>
      ),
    },
    {
      title: 'Voucher No',
      dataIndex: 'atrefund_vouchar_number',
      key: 'atrefund_vouchar_number',
      align: 'center',
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Refund charge',
      dataIndex: 'crefund_charge_amount',
      key: 'crefund_charge_amount',
      align: 'center',
      render: (crefund_charge_amount) => {
        return <>{Number(crefund_charge_amount) || 0}</>;
      },
    },

    {
      title: 'Refund profit',
      dataIndex: 'crefund_profit',
      key: 'crefund_profit',
      align: 'center',
      render: (crefund_profit) => {
        return <>{Number(crefund_profit) || 0}</>;
      },
    },

    {
      title: 'Type',
      dataIndex: 'crefund_payment_type',
      key: 'crefund_payment_type',
      align: 'center',
    },
    {
      title: 'Note',
      dataIndex: 'atrefund_note',
      key: 'atrefund_note',
    },

    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['read:any'] && !checkButtonState && (
            <Link
              to={`/refund/airticket/details/${record.atrefund_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.['delete:any'] && !checkButtonState && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleRefundDelete(record, index)}
              okButtonProps={{ loading: isLoading }}
            >
              <Button size='small' type='primary' danger>
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
