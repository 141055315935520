import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  Fixed2,
  getAirportByItaCode,
  getItaCodeByAirportIds,
} from '../../../../common/utils/common.utils';
import postDateFormat from '../../../../common/utils/postDateFormat';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import InvoiceSubTotal from '../../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportQuery } from '../../../Configuration/airport/endpoints/airportEndpoints';
import { useGetInvoiceNoQuery } from '../../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import { IHotel_information } from '../../../InvoiceUmrah/Types/InvoiceUmrahTypes';
import NewAddHajjFormTop from '../Components/NewAddHajjFormTop';
import NewAddHajjFormTransportInfo from '../Components/NewAddHajjFormTransportInfo';
import NewAddHajjPilgrimsInfo from '../Components/NewAddHajjPilgrimsInfo';
import NewAddhajjFormHotelInfo from '../Components/NewAddhajjFormHotelInfo';
import {
  IBilling_information,
  IPilgrims_information,
  ITransport_information,
  InvoiceHajjEndPointType,
  InvoiceHajjType,
} from '../Types/InvoiceHajjTypes';
import {
  useInvoiceHajjEditMutation,
  useLazyInvoiceHajjGetForEditQuery,
} from '../api/endpoints/invoicehajjEndpoints';
import { useCheckCreditLimitMutation } from '../../../Client/Client/api/endpoints/clientEndpoints';
import { InvoiceDisPrevRadioButton } from '../../../../components/common/Invoice/InvoiceMoneyReceipt';

type Props = {};
const EditInvoceHajj = ({}: Props) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const { id } = useParams();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const [
    invoiceHajjGetForEdit,
    {
      isSuccess: hajjEditIsSuccessFetch,
      isLoading: hajjEditIsLoading,
      error: hajjEditError,
      data: invoiceHajjEditingDataTemp,
    },
  ] = useLazyInvoiceHajjGetForEditQuery();
  const edit_data: InvoiceHajjType | undefined =
    invoiceHajjEditingDataTemp?.data;

  const [
    invoiceHajjEdit,
    {
      isError: invoiceHajjEditIsError,
      isSuccess: invoiceHajjEditIsSuccess,
      error: invoiceHajjEditError,
      isLoading: editLoading,
    },
  ] = useInvoiceHajjEditMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  useEffect(() => {
    if (id) {
      invoiceHajjGetForEdit({ id: Number(id) });
    }
  }, [id]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (invoiceHajjEditIsSuccess) {
      toasterNotification('success', 'Successfully Updated');
      navigate(`/invoicehajj/details/${id}`, {
        state: '/invoicehajj',
      });
      form.resetFields();
      timeoutId = setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/invoicehajj');
      }, 100);
    } else if (invoiceHajjEditIsError) {
      message.error("Some things went's to wrong");
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [invoiceHajjEditIsSuccess, invoiceHajjEditIsError]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [location]);

  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  //CREATE INVOCIE HAJJ AND EDIT
  const onFinish = (values: InvoiceHajjType) => {
    const formatedData: InvoiceHajjEndPointType = {
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_haji_group_id: values.invoice_haji_group_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_no: values.invoice_no,
      invoice_vat: values.invoice_vat,
      invoice_sales_date: dayjs(values.invoice_sales_date).format('YYYY-MM-DD'),
      invoice_due_date: dayjs(values.invoice_due_date).format('YYYY-MM-DD'),
      invoice_hajj_routes: getAirportByItaCode(
        values?.invoice_hajj_routes as string,
        selectAirport
      ) as number[],
      invoice_agent_id: values?.invoice_agent_id,
      invoice_agent_com_amount: values?.invoice_agent_com_amount,
      hajj_total_pax: values.hajj_total_pax,
      invoice_sub_total: values.invoice_sub_total,
      invoice_discount: values.invoice_discount,
      invoice_service_charge: values.invoice_service_charge,
      invoice_net_total: values.invoice_net_total,
      invoice_note: values.invoice_note,
      invoice_reference: values.invoice_reference,
      invoice_created_by: user?.user_id as number,
      invoice_hajj_session: dayjs(values.invoice_hajj_session).format('YYYY'),
      invoice_show_discount: values.invoice_show_discount,
      invoice_show_prev_due: values.invoice_show_prev_due,

      pilgrims_information: values?.pilgrims_information
        ?.map((el: IPilgrims_information) => {
          const anyOneExist =
            el.haji_info_passport_id ||
            el.hajiinfo_dob ||
            el.hajiinfo_gender ||
            el.hajiinfo_mobile ||
            el.hajiinfo_name ||
            el.hajiinfo_nid ||
            el.hajiinfo_serial ||
            el.hajiinfo_tracking_number;
          if (!anyOneExist) {
            return undefined;
          }
          return {
            ...el,
            hajiinfo_mobile: el.hajiinfo_mobile
              ? el.dialCode + '-' + el.hajiinfo_mobile?.substring(1)
              : undefined,
            hajiinfo_dob: postDateFormat(el.hajiinfo_dob),
            ticket_route: el?.ticket_route?.length
              ? (getAirportByItaCode(
                  el?.ticket_route as string,
                  selectAirport
                ) as string[])
              : undefined,
          };
        })
        .filter((item) => item) as IPilgrims_information[] | [],

      hotel_information: values.hotel_information[0]?.hotel_name
        ? values.hotel_information?.map((el: IHotel_information, i) => {
            return {
              ...el,
              hotel_check_in_date: postDateFormat(el.hotel_check_in_date),
              hotel_check_out_date: postDateFormat(el.hotel_check_out_date),
              hotel_id: edit_data?.hotel_information[i]?.hotel_id,
            };
          })
        : undefined,
      transport_info:
        JSON.stringify(values.transport_information[0]) !== '{}'
          ? values.transport_information?.map(
              (el: ITransport_information, i) => {
                return {
                  ...el,
                  transport_dropoff_place: el.transport_dropoff_place
                    ? el.transport_dropoff_place
                    : undefined,
                  transport_pickup_place: el.transport_pickup_place
                    ? el.transport_pickup_place
                    : undefined,
                  transport_type_id: el.transport_type_id
                    ? el.transport_type_id
                    : undefined,
                  transport_dropoff_time: el.transport_dropoff_time
                    ? el.transport_dropoff_time
                    : undefined,
                  transport_pickup_time: el.transport_pickup_time
                    ? el.transport_pickup_time
                    : undefined,
                  transport_id:
                    edit_data?.transport_information[i]?.transport_id,
                };
              }
            )
          : undefined,
      billing_information: values.billing_information.map(
        (el: IBilling_information, index) => {
          delete el?.billing_total_cost_price;
          return {
            ...el,
            billing_id: edit_data?.billing_information[index]?.billing_id,
          };
        }
      ),
      money_receipt: values.receipt_payment_type
        ? {
            receipt_total_amount: values.receipt_total_amount,
            receipt_payment_type: values.receipt_payment_type,
            account_id: values.account_id,
            charge_amount: values.charge_amount,
            cheque_bank_name: values.cheque_bank_name,
            cheque_number: values.cheque_number,
            cheque_withdraw_date: dayjs(values.cheque_withdraw_date).format(
              'YYYY-MM-DD'
            ),
            receipt_money_receipt_no: values.receipt_money_receipt_no,
            receipt_note: values.receipt_note,
            trans_no: values.trans_no,
            receipt_payment_date: dayjs(values.receipt_payment_date).format(
              'YYYY-MM-DD'
            ),
          }
        : undefined,
    };

    const prevNetTotal = edit_data?.invoice_net_total || 0;
    const currentNetTotal = formatedData.invoice_net_total;
    if (prevNetTotal >= currentNetTotal) {
      invoiceHajjEdit({ body: formatedData, id: id }).then((res: any) => {
        if (res.data.data.invoice_id)
          updateAndPrev
            ? navigate(`/invoicehajj/details/${res.data.data.invoice_id}`, {
                state: location,
              })
            : navigate('/invoicehajj');
      });
    } else {
      checkCreditLimit({
        amount: currentNetTotal - prevNetTotal,
        combClient: formatedData.invoice_combclient_id.toString(),
      }).then((res: any) => {
        if (res?.data?.data === 1) {
          invoiceHajjEdit({ body: formatedData, id: id }).then((res: any) => {
            if (res.data.data.invoice_id)
              updateAndPrev
                ? navigate(`/invoicehajj/details/${res.data.data.invoice_id}`, {
                    state: location,
                  })
                : navigate('/invoicehajj');
          });
        } else {
          message.error(res?.data?.data);
        }
      });
    }
  };

  const onFinishFailed = (values: any) => {
    toasterNotification(
      'error',
      values.errorFields.length + ' Required fields!'
    );
  };
  const [form] = Form.useForm();
  const { data: invoiceNo, refetch: invoiceNoRefetch } =
    useGetInvoiceNoQuery('IH');

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: invoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [invoiceNo]);

  useEffect(() => {
    form.setFieldsValue({
      invoice_show_prev_due: edit_data?.invoice_show_prev_due,
      invoice_show_discount: edit_data?.invoice_show_discount,
      invoice_combclient_id: edit_data?.invoice_combclient_id,
      invoice_agent_id: edit_data?.invoice_agent_id,
      invoice_haji_group_id: edit_data?.invoice_haji_group_id,
      invoice_sales_man_id: edit_data?.invoice_sales_man_id,
      invoice_no: edit_data?.invoice_no,
      invoice_vat: Fixed2(edit_data?.invoice_vat || 0),
      invoice_sales_date: dayjs(edit_data?.invoice_sales_date),
      invoice_due_date: edit_data?.invoice_due_date
        ? dayjs(edit_data?.invoice_due_date)
        : undefined,
      invoice_hajj_routes: edit_data?.invoice_hajj_routes?.length
        ? getItaCodeByAirportIds(
            edit_data?.invoice_hajj_routes as number[],
            selectAirport
          )
        : undefined,
      invoice_sub_total: Fixed2(edit_data?.invoice_sub_total),
      invoice_discount: Fixed2(edit_data?.invoice_discount),
      invoice_service_charge: Fixed2(edit_data?.invoice_service_charge),
      invoice_net_total: Fixed2(edit_data?.invoice_net_total),
      invoice_note: edit_data?.invoice_note || undefined,
      invoice_reference: edit_data?.invoice_reference || undefined,
      invoice_created_by: edit_data?.invoice_created_by,
      invoice_hajj_session: dayjs(edit_data?.invoice_hajj_session + '', 'YYYY'),
      hajj_total_pax: edit_data?.hajj_total_pax,
      hotel_information: edit_data?.hotel_information?.length
        ? edit_data?.hotel_information.map((el) => {
            return {
              ...el,
              hotel_check_in_date: el.hotel_check_in_date
                ? dayjs(el.hotel_check_in_date)
                : undefined,
              hotel_check_out_date: el.hotel_check_out_date
                ? dayjs(el.hotel_check_out_date)
                : undefined,
            };
          })
        : [{}],
      transport_information: edit_data?.transport_information.length
        ? edit_data?.transport_information?.map((el) => {
            return {
              transport_type_id: el.transport_type_id,
              transport_pickup_place: el.transport_pickup_place,

              transport_dropoff_time: el.transport_dropoff_time
                ? dayjs(el.transport_dropoff_time, 'HH:mm:ss')
                : undefined,
              transport_dropoff_place: el.transport_dropoff_place,

              transport_pickup_time: el.transport_pickup_time
                ? dayjs(el.transport_pickup_time, 'HH:mm:ss')
                : undefined,
            };
          })
        : [{}],

      billing_information: edit_data?.billing_information?.length
        ? edit_data?.billing_information.map((el, index) => {
            return {
              billing_product_id: el.billing_product_id,
              billing_id: edit_data?.billing_information[index].billing_id,
            };
          })
        : [{}],
    });

    form.setFieldsValue({
      billing_information: edit_data?.billing_information?.length
        ? edit_data?.billing_information.map((el) => {
            return {
              billing_product_id: el.billing_product_id,
              pax_name: el.pax_name,
              billing_description: el.billing_description,
              billing_quantity: el.billing_quantity,
              billing_subtotal: el.billing_subtotal,
              billing_unit_price: el.billing_unit_price,
              billing_profit: el.billing_profit,
              billing_cost_price: el.billing_cost_price,
              billing_comvendor: el.billing_comvendor,
            };
          })
        : [{}],
    });

    form.setFieldsValue({
      pilgrims_information: edit_data?.pilgrims_information.length
        ? edit_data?.pilgrims_information?.map((el) => {
            return {
              ...el,
              hajiinfo_name: el.hajiinfo_name,
              hajiinfo_gender: el?.hajiinfo_gender
                ? el?.hajiinfo_gender.toUpperCase()
                : undefined,
              hajiinfo_mobile: el?.hajiinfo_mobile
                ? el?.hajiinfo_mobile.split('-')[1]
                : undefined,
              hajiinfo_dob: el.hajiinfo_dob
                ? dayjs(el.hajiinfo_dob)
                : undefined,
              hajiinfo_serial: el.hajiinfo_serial,
              hajiinfo_nid: el.hajiinfo_nid,
              hajiinfo_tracking_number: el?.hajiinfo_tracking_number,
              haji_info_passport_id: el.haji_info_passport_id,
              ticket_journey_date: el?.ticket_journey_date
                ? dayjs(el?.ticket_journey_date)
                : undefined,
              ticket_return_date: el?.ticket_return_date
                ? dayjs(el?.ticket_return_date)
                : undefined,
              ticket_route: el?.ticket_route?.length
                ? getItaCodeByAirportIds(
                    el?.ticket_route as string[],
                    selectAirport
                  )
                : undefined,
              ticket_route_id: el?.ticket_route,
            };
          })
        : [{}],
    });

    form.setFieldsValue({
      ticketInfo: edit_data?.ticketInfo?.length
        ? edit_data.ticketInfo?.map((el) => {
            return {
              ticket_no: el.ticket_no,
              ticket_pnr: el?.ticket_pnr,
              ticket_airline_id: el?.ticket_airline_id,
              ticket_reference_no: el?.ticket_reference_no,
              ticket_journey_date: el?.ticket_journey_date
                ? dayjs(el?.ticket_journey_date)
                : undefined,
              ticket_return_date: el?.ticket_return_date
                ? dayjs(el?.ticket_return_date)
                : undefined,
              ticket_route: edit_data?.invoice_hajj_routes?.length
                ? getItaCodeByAirportIds(
                    edit_data?.invoice_hajj_routes as number[],
                    selectAirport
                  )
                : undefined,
            };
          })
        : [{}],
    });
  }, [hajjEditIsSuccessFetch, edit_data]);

  return (
    <>
      {editLoading && <LoadingIndicator />}
      <BreadCrumb arrOfOption={['Invoice Hajj']} />
      {/*  */}
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <NewAddHajjFormTop
          form={form}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
        />

        <NewAddHajjPilgrimsInfo form={form} />
        <NewAddhajjFormHotelInfo form={form} edit_data={edit_data} />
        <NewAddHajjFormTransportInfo form={form} edit_data={edit_data} />
        <InvoiceBillingInfo
          form={form}
          setProductId={116}
          billing_information={edit_data?.billing_information}
          conditionalCostVendor
        />
        <InvoiceSubTotal form={form} />

        <InvoiceDisPrevRadioButton form={form} style={{ marginTop: 30 }} />

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (editLoading || limiLoading)}
              label='UPDATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (editLoading || limiLoading)}
              label='UPDATE & VIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
      {editLoading && <LoadingIndicator />}
    </>
  );
};

export default EditInvoceHajj;
