import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { IPreregistrationFormData } from '../types/preRegistration';

import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useGetPreRegistrationMutation,
  useLazyGetExcelsPreRegistrationQuery,
} from '../endpoints/preRegistrationEndpoints';
import { preRegistrationColumn } from '../utils/preRegistrationColumn';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';

const PreRegistrationReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelsPreRegistrationQuery();
  const [excelQuery, setExcelQuery] = useState<{
    year: string;
    query: string;
  }>({
    year: '',
    query: '',
  });
  useEffect(() => {
    form.setFieldValue('possible_year', dayjs());
  }, []);
  const [fetchPreRegiData, { isLoading, data }] =
    useGetPreRegistrationMutation();

  const onFinish = async (values: IPreregistrationFormData) => {
    const year = dayjs(values.possible_year).format('YYYY');
    await fetchPreRegiData({
      possible_year: year,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      year: year,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 1, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IPreregistrationFormData = form.getFieldsValue();
    const year = dayjs(values.possible_year).format('YYYY');
    fetchPreRegiData({
      possible_year: year,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      year: year,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Pre_Registration Report`,
  });

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Pre Registration Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              year: excelQuery.year,
              query: excelQuery.query,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={[10, 0]}>
          <Col xs={3} sm={3} md={3} lg={3}>
            <Form.Item name='possible_year' label='Year' required>
              <DatePicker picker='year' />
            </Form.Item>
          </Col>
          <Col>
            <FormButton label='Search' icon textAlign='left' />
          </Col>
        </Row>
      </Form>

      {
        <div ref={componentRef}>
          <FormHeaderTitle title='Pre Registration Report' />
          <ConfigProvider
            theme={{
              algorithm: theme.defaultAlgorithm,
            }}
          >
            <Table
              size='small'
              bordered
              pagination={count > 20 ? tableParams.pagination : false}
              columns={preRegistrationColumn()}
              dataSource={data?.data}
              scroll={{ x: true }}
              loading={{ spinning: isLoading, indicator: loadingIndicator }}
              onChange={(args1, args2, args3) =>
                handleTableChange({
                  args: {
                    pagination: args1,
                    filters: args2,
                    sorter: args3,
                  },
                  states: {
                    refetch,
                    setQueryData,
                    setTableParams,
                    tableParams,
                  },
                })
              }
            />
          </ConfigProvider>
        </div>
      }
      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {/* {print_content} */}
    </>
  );
};

export default PreRegistrationReport;
