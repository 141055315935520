import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  FormButton,
  FormInputItem,
  MobileInput,
} from '../../../../components/common/FormItem/FormItems';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { SelectRoles } from '../components/SelectRoles';
import {
  useGetUserByIdQuery,
  useLazyCheckAgencyUsernameQuery,
  useUpdateUserMutation,
} from '../endpoints/userEndpoints';
import { IUserFormData } from '../types/user.types';

const { Option } = Select;

type Props = {
  userId: number;
};

export default function EditUserAccount({ userId }: Props) {
  const user = useGetUserByIdQuery(userId);
  const userData = user.data?.data;

  const [usernameIsExist, { data: usernameIsExisting }] =
    useLazyCheckAgencyUsernameQuery();

  const handleUserChange = (e: any) => {
    let timeoutId: NodeJS.Timeout;

    const userName = e.target.value;
    timeoutId = setTimeout(() => {
      usernameIsExist(userName).then(() => {
        form.validateFields(['user_username']);
      });
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [updateUser, { isLoading, isSuccess, isError }] =
    useUpdateUserMutation();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...userData,
      user_email: userData?.user_email,
    });
  }, [userData, userId, user]);

  const onFinish = async (values: any) => {
    const body: IUserFormData = {
      ...values,
      user_email: values?.user_email ? values?.user_email : undefined,
    };

    await updateUser({ body, id: userId.toString() });
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill up the required fields');
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      onCancel();
      navigate('/user/view');
    } else if (isError) {
      message.error('An error occurred. Please try again later.');
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      {isLoading && loadingIndicator}
      <Button type='primary' size='small' onClick={showDrawer}>
        Edit
      </Button>
      <Drawer
        title='Update user information'
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={onCancel}>Cancel</Button>}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label='Full Name' style={{ marginBottom: 0 }}>
                <Form.Item
                  name={'user_first_name'}
                  rules={[{ required: true }]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                  }}
                >
                  <Input placeholder='First name' />
                </Form.Item>
                <Form.Item
                  name={'user_last_name'}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    margin: '0 8px',
                  }}
                >
                  <Input placeholder='Last name' />
                </Form.Item>
              </Form.Item>
            </Col>

            <FormInputItem
              required
              onKeyUp={(e: any) => handleUserChange(e)}
              name='user_username'
              label='User Name'
              size={12}
              rules={[
                usernameIsExisting
                  ? {
                      validator: async (_, value) => {
                        if (!usernameIsExisting?.data) {
                          return Promise.reject('Username already exists!');
                        }
                        return Promise.resolve();
                      },
                    }
                  : {},
                {
                  pattern: /^[^\s]+$/,
                  message: 'Username cannot contain spaces',
                },
              ]}
            />

            <Col span={12}>
              <Form.Item
                label='Email'
                name={'user_email'}
                rules={[{ required: true, message: 'Please enter email' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  // addonAfter='@gmail.com'
                  placeholder='Please enter email'
                />
              </Form.Item>
            </Col>

            <MobileInput
              label='Mobile'
              name='user_mobile'
              size={12}
              countryCode
              countryCodeFormName={'user_dial_code'}
            />

            <SelectRoles
              name='user_role_id'
              label='User Role'
              required
              size={12}
            />

            <Divider>Change Password</Divider>

            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label='Current Password'
                name='current_password'
                rules={[
                  {
                    min: 6,
                    message: 'Password must be at least 6 characters long!',
                  },
                ]}
              >
                <Input.Password
                  autoComplete='new-password'
                  placeholder='Enter your current password'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label='New Password'
                name='password'
                rules={[
                  {
                    min: 6,
                    message: 'Password must be at least 6 characters long!',
                  },
                ]}
              >
                <Input.Password
                  autoComplete='new-password'
                  placeholder='Enter your new password'
                />
              </Form.Item>
            </Col>
          </Row>

          <FormButton label='Update' />
        </Form>
      </Drawer>
    </>
  );
}
