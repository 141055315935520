import { Col, Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { useEffect } from 'react';
import { commonProps } from '../../../common/types/commonTypes';
import { useLazyGetAllTicketsByIDQuery } from '../Api/Endpoints/AirTicketRefundEndpoints';

type PropsType = {
  clientID?: string;
  mode?: 'multiple' | 'tags' | undefined;
  setTicketNumbers?: (arg: string[]) => void;
  invoiceId?: number;
} & commonProps;

export const SelectTicketByClientID = ({
  name,
  label,
  size,
  required,
  disabled,
  clientID,
  invoiceId,
  mode,
  setTicketNumbers,
}: PropsType) => {
  // ================= TicketbyClientID children
  const [fetchTicket, { data: ticketData }] = useLazyGetAllTicketsByIDQuery();

  useEffect(() => {
    clientID && fetchTicket(clientID as string);
  }, [clientID]);

  const selectTicketData = ticketData?.data;
  const filteredData = selectTicketData?.filter(
    (item) => item?.invoice_id === invoiceId
  );

  let ticketDataChildren: React.ReactNode[] = [];

  if (filteredData) {
    for (let i = 0; i < filteredData.length; i++) {
      ticketDataChildren.push(
        <Select.Option
          key={filteredData[i].ticket_no}
          value={filteredData[i].ticket_no}
        >
          {`${filteredData[i].ticket_no}`}
        </Select.Option>
      );
    }
  }

  useEffect(() => {
    ticketDataChildren = [];
  }, [invoiceId]);

  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: 'Select Ticket No. is required' },
        ]}
      >
        <Select
          allowClear
          disabled={disabled}
          placeholder={'Select Ticket No.'}
          showSearch
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(e: string[]) => {
            setTicketNumbers && setTicketNumbers(e);
          }}
          mode={mode}
        >
          {ticketDataChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
