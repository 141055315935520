import { Card, Skeleton, Typography } from 'antd';
import Chart from 'react-apexcharts';
import { useAppSelector } from '../../../app/hooks';
import { IExpensesData } from '../interfaces/dashboard.interfaces';
import DashboardTitle from './DashboardTitle';
import { IDarkMode } from './InvoiceDetails';

type Props = {
  expenses: IExpensesData | undefined;
  expenseLoading: boolean;
};

const ExpenseTotal = ({
  expenses,
  expenseLoading,
  isDarkMode,
}: Props & IDarkMode) => {
  const currency = useAppSelector(
    (state) =>
      state.user?.organization_info?.org_currency?.toLocaleUpperCase() || ''
  );

  const accExpenses = expenses?.accountWiseExpense || [];

  const labelsName = accExpenses?.map((item) => item?.account_name);

  const seriesData = accExpenses?.map(
    (item) => item?.account_total_expenses_today
  );

  const todayExpenseData = {
    options: {
      labels: labelsName,
      colors: [
        '#40A2E3',
        '#378CE7',
        '#9B4444',
        '#B2B377',
        '#747264',
        '#50727B',
        '#FAA300',
      ],
    },
    series: seriesData || [0],
  };

  if (expenseLoading) {
    return (
      <Card style={{ height: '100%' }}>
        <Skeleton title={false} paragraph={{ rows: 10 }} active />
      </Card>
    );
  }

  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle title='EXPENSES' link='/expense' />

      <div style={{ padding: '0px 20px' }}>
        <div className='dash_expense'>
          <div className='dash_expense_box'>
            <Typography>Daily Expense</Typography>
            <Typography>
              {expenses?.today_total || 0} {currency}
            </Typography>
          </div>
          <div className='dash_expense_box'>
            <Typography>Monthly Expense</Typography>
            <Typography>
              {expenses?.this_month_total || 0} {currency}
            </Typography>
          </div>
          <div className='dash_expense_box'>
            <Typography>Yearly Expense</Typography>
            <Typography>
              {expenses?.this_year_total || 0} {currency}
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: '10px' }}>
          <Chart
            options={todayExpenseData?.options}
            series={todayExpenseData?.series}
            type='donut'
            height={200}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpenseTotal;
