import { ColumnsType } from 'antd/lib/table';

import { Button, Popconfirm, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import {
  IRefundDeleteRestoreBody,
  ITourRefundHistoryType,
} from '../../RefundTypes/RefundTypes';

import { useDeleteToursRefundClientMutation } from '../../Api/Endpoints/TourPackageRefundEndpoints';

import { IPermission, crud } from '../../../../common/types/commonTypes';

export const TourRefundClientViewUtils = ({
  permission,
  pagination,
}: {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<ITourRefundHistoryType> => {
  const user = useSelector(selectUser);
  const [deleteRefund, { isLoading, isSuccess, isError }] =
    useDeleteToursRefundClientMutation();

  const handleRefundDelete = async (
    values: ITourRefundHistoryType,
    index: number
  ) => {
    const body: IRefundDeleteRestoreBody = {
      refund_id: values.refund_id,
      deleted_by: user?.user_id as number,
    };

    await deleteRefund(body);
  };

  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'refund_create_date',
      align: 'center',
      render: (_, record) => {
        return `${dayjs(record.create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher_no',
      key: 'voucher_no',
      align: 'center',
    },

    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      align: 'center',
    },

    {
      title: 'Amount',
      dataIndex: 'refund_amount',
      key: 'refund_amount',
      align: 'center',
      render: (_, record) => Number(record.refund_amount),
    },
    {
      title: 'Type',
      dataIndex: 'payment_type',
      key: 'payment_type',
      align: 'center',
    },

    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => {
        return (
          <Space size='small'>
            {permission?.['read:any'] && (
              <Link
                to={`/refund/client/details/${record.refund_id}`}
                state={location.pathname}
              >
                <Button size='small' type='primary'>
                  <Typography style={{ color: '#ffffff' }}>View</Typography>
                </Button>
              </Link>
            )}

            {permission?.[crud.delete] && (
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleRefundDelete(record, index)}
                okButtonProps={{ loading: isLoading }}
              >
                <Button size='small' type='primary' danger>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
};
