import { Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { crud, perProps } from '../../../../common/types/commonTypes';
import { visaDataType } from '../types/visaTypes';
import { columns } from '../utils/utils';
import { useGetAllVisaWithPagQuery } from '../endpoints/visaEndpoints';
import EditVisaTypeModal from '../modals/editVisaTypeModal';
import AddVisaModal from '../modals/AddVisaModal';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';

type Props = {};

const VisaType = ({ permission }: perProps) => {
  const [editInfo, setEditInfo] = useState<visaDataType | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const dispatch = useAppDispatch();

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: visa,
    refetch,
  } = useGetAllVisaWithPagQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: visaDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, count } };
    },
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Visa Types']}
        refetch={refetch}
        reloaderSize='small'
      />

      <CommonTableHeader
        width
        align
        title='Visa Types'
        button_text='Add New Visa'
        modalTitle={editInfo ? 'Edit Visa' : 'Add New Visa'}
        permission={permission?.[crud.create]}
        element={
          editInfo ? (
            <EditVisaTypeModal info={editInfo} setNull={setEditInfo} />
          ) : (
            <AddVisaModal />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          style={{ width: 900 }}
          bordered
          pagination={
            visa.count !== undefined && visa.count < 20
              ? false
              : {
                  ...pagination,
                  total: visa.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
          }
          columns={columns({ setEditInfo, showModal, permission, pagination })}
          dataSource={visa.dataToReturn}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default VisaType;
