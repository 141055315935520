import { CloseOutlined } from '@ant-design/icons';
import { Divider, FormListOperation, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  FormInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectVendors } from '../../../../components/common/FormItem/SelectCustomFeilds';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
};

const InvoiceAirTicketInputItems = ({ subField, subOpt, form }: Props) => {
  return (
    <Row className='border' key={subField.key} gutter={10}>
      <Divider>Refund - {subField.key + 1}</Divider>
      <FormInput
        hidden
        name={[subField.name, 'airticket_id']}
        label='Airticket Id'
        readonly
        size={6}
      />

      <SelectVendors
        name={[subField.name, 'comb_vendor']}
        label='Vendor'
        disabled
        size={6}
      />

      <FormInput
        name={[subField.name, 'airticket_purchase_price']}
        label='Purchase Price'
        readonly
        size={6}
      />
      <NumberInput
        name={[subField.name, 'airticket_tax']}
        label='Air ticket Tax'
        size={6}
        readOnly
      />
      <NumberInput
        name={[subField.name, 'refund_tax_amount']}
        label='Refund Tax'
        size={5}
        required
      />

      <CloseOutlined
        onClick={() => {
          subOpt.remove(subField.name);
        }}
      />
    </Row>
  );
};

export default InvoiceAirTicketInputItems;
