import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IOnlineChargeReportDataTypes } from '../types/onlineTransactionCharge';

export const OnlineChargeUtils = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IOnlineChargeReportDataTypes> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'ctrxn_date',
      render: (_, record) => {
        return `${dayjs(record.charge_created_date).format('DD MMM YYYY')}`;
      },
      align: 'center',
    },

    {
      title: 'Voucher No',
      dataIndex: 'charge_voucher_no',
      key: 'charge_voucher_no',
    },
    {
      title: 'From Account',
      dataIndex: 'from_acc_name',
      key: 'from_acc_name',
    },
    {
      title: 'To Account',
      dataIndex: 'to_acc_name',
      key: 'to_acc_name',
    },

    {
      title: 'Purpose',
      dataIndex: 'charge_purpose',
      key: 'charge_purpose',
    },
    {
      title: 'Note',
      dataIndex: 'charge_note',
      key: 'charge_note',
      render: (curr) => {
        if (curr !== 'undefined' || curr === undefined) return curr;
      },
    },

    {
      title: 'Charge Amount',
      dataIndex: 'charge_amount',
      key: 'charge_amount',
      render: (_, data) => Number(data.charge_amount),
      align: 'right',
    },
  ];
};
