import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IVendorPayment } from '../types/VendorWisePurchase_PaymentTypes';

export const VendorWisePaymentUtils = (): ColumnsType<IVendorPayment> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => <>{index + 1}</>,
    },
    {
      title: 'Payment Date',
      key: 'payment_date',
      dataIndex: 'payment_date',
      render: (curr) => {
        return `${dayjs(curr).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      dataIndex: 'vendor_name',
    },

    {
      title: 'Voucher No',
      key: 'vouchar_no',
      dataIndex: 'vouchar_no',
      // render: (_, data) => {
      //   const { invendorpay_vpay_id, vouchar_no } = data;

      //   return (
      //     <>
      //       <Link to={`../vendors/payment/${invendorpay_vpay_id}`}>
      //         {vouchar_no}
      //       </Link>
      //     </>
      //   );
      // },
    },
    {
      title: 'Invoice No.',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      // render: (_, data) => {
      //   const { invoice_no, invoice_category_id, invoice_id } = data;

      //   return (
      //     <>
      //       <Link
      //         to={`../${
      //           findInvoiceRoute(invoice_category_id) +
      //           '/' +
      //           'details' +
      //           '/' +
      //           invoice_id
      //         }`}
      //       >
      //         {invoice_no}
      //       </Link>
      //     </>
      //   );
      // },
    },

    {
      title: 'Cost',
      key: 'cost_price',
      dataIndex: 'cost_price',
      render: (curr) => Fixed2(curr),
    },
    {
      title: 'Payment',
      key: 'payment_amount',
      dataIndex: 'payment_amount',
      render: (curr) => Fixed2(curr),
    },
  ];
};
