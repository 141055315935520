import { FormInstance } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useEffect } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { AirTicketInvoiceData } from "../../types/invoiceAirTicketTypes";
import AirticketTicketDetails from "./AirticketTicketDetails";
import AirticketTicketDetails_Ait1_Tax1_Extra_Fields from "./AirticketTicketDetails_Ait1_Tax1_Extra_Fields";

type Props = {
  form: FormInstance<any>;
  invoices: AirTicketInvoiceData[];
  editIndex?: number;
  isLoading?: boolean;
};

export default function AgencyWiseGet_AirticketTicketDetails({
  form,
  invoices,
  editIndex,
  isLoading,
}: Props) {
  let airticketTicketDetail = (
    <AirticketTicketDetails
      form={form}
      invoices={invoices}
      editIndex={editIndex}
      isLoading={isLoading}
    />
  );

  const airticket_ait = useWatch("airticket_ait", form);
  const airticket_commission_percent_total = useWatch(
    "airticket_commission_percent_total",
    form
  );

  const final_net_commission =
    Fixed2(airticket_commission_percent_total) - Fixed2(airticket_ait);

  useEffect(() => {
    if (final_net_commission) {
      form.setFieldsValue({ airticket_net_commssion: final_net_commission });
    }
  }, [final_net_commission]);

  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_ait_cal } = appConfig;

  switch (tac_ait_cal) {
    case "DEFAULT":
      airticketTicketDetail = (
        <AirticketTicketDetails
          form={form}
          invoices={invoices}
          editIndex={editIndex}
          isLoading={isLoading}
        />
      );
      break;

    case "TAX_1_EXTRA_FIELD":
      airticketTicketDetail = (
        <AirticketTicketDetails_Ait1_Tax1_Extra_Fields
          form={form}
          invoices={invoices}
          editIndex={editIndex}
        />
      );
      break;

    default:
      break;
  }

  return airticketTicketDetail;
}
