import { PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { crud, perProps } from '../../../common/types/commonTypes';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import {
  useDeleteAdvanceReturnMutation,
  useGetAllAdvanceReturnQuery,
} from '../api/endpoints/advanceEndpoints';
import { IGetAdvanceReturn } from '../types/vendor.interfaces';
import { Fixed2, pagination } from '../../../common/utils/common.utils';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import dayjs from 'dayjs';

const AdvanceReturn = ({ permission }: perProps) => {
  const {
    isLoading,
    data: advanceReturn,
    refetch,
  } = useGetAllAdvanceReturnQuery();
  const user = useSelector(selectUser);
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [deleteAdvanceReturn, { isError, isSuccess, isLoading: deleting }] =
    useDeleteAdvanceReturnMutation();

  const handleDelete = (id: number, index: number) => {
    const body = { id, deleted_by: user?.user_id as number };
    setColumnIndex(index);
    deleteAdvanceReturn(body);
  };

  // ================== notification =========================
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      toasterNotification('success', 'Advance return has been deleted!');
    }
    if (isError) {
      setColumnIndex(null);
      toasterNotification('error', 'Advance return can not deleted now!');
    }
  }, [isError, isSuccess]);

  const columns: ColumnsType<IGetAdvanceReturn> = [
    {
      title: 'SL.',
      key: 'key',
      render(value, record, index) {
        ++index;
        return index;
      },
    },
    {
      title: 'Return Date',
      dataIndex: 'return_date',
      key: 'return_date',
      align: 'left',
      render(value, record, index) {
        return value ? dayjs(value).format('DD-MM-YYYY') : '';
      },
    },
    {
      title: 'Vouchar No',
      dataIndex: 'advr_vouchar_no',
      key: 'advr_vouchar_no',
      align: 'left',
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      align: 'left',
      render: (_, data) => (
        <Link to={`/reports/vendor_ledger?vendor_id=${data.advr_vendor_id}`}>
          {data.vendor_name}
        </Link>
      ),
    },

    {
      title: 'Advance Amount',
      dataIndex: 'advr_amount',
      key: 'advr_amount',
      align: 'right',
      render(value, record, index) {
        return Fixed2(value);
      },
    },
    {
      title: 'Return Note',
      dataIndex: 'advr_note',
      key: 'advr_note',
      align: 'center',
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, data, index) => (
        <Space size='small'>
          {permission?.[crud.update] && (
            <Link to={`/vendors/advance/edit/${data.advr_id}`}>
              <Button size='small' type='primary'>
                Edit
              </Button>
            </Link>
          )}

          {permission?.['read:any'] && (
            <Link to={`/vendors/advance/view/${data.advr_id}`}>
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}
          {permission?.[crud.delete] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#e90800', border: 'none' }}
              loading={columnIndex === index && true}
            >
              <Popconfirm
                onConfirm={() => handleDelete(data.advr_id, index)}
                title='Sure to delete?'
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <BreadCrumb
        arrOfOption={['List of Advance Return']}
        refetch={refetch}
        reloaderSize='small'
      />
      {permission?.[crud.create] && (
        <Link to='/vendors/advance/add'>
          <Button type='primary' style={{ marginBottom: '0.5rem' }}>
            <PlusOutlined />
            Create New
          </Button>
        </Link>
      )}

      <Table
        bordered
        size='small'
        rowKey={(e) => e.advr_id}
        columns={columns}
        pagination={pagination(advanceReturn?.data?.length)}
        dataSource={advanceReturn?.data}
        scroll={{ x: true }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </>
  );
};

export default AdvanceReturn;
