import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Row,
  Table,
} from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllInvoiceHajjRegQuery } from '../API/Endpoints/HajjRegEndspoint';
import { Hajj_Reg_Col } from '../Columns/Hajj_Reg_Col';

type Props = {
  permission?: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
};

const Hajj_Reg = ({ permission, addMRPermission }: Props) => {
  // HANDLE PAGINATION
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const [date, setDate] = useState<string | string[]>('');

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // GET ALL DATA
  const { data, isLoading: isLoadingData } = useGetAllInvoiceHajjRegQuery({
    ...pagination,
    dateYear: date,
  });
  const allData = data?.data;

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(dateString);
  };

  return (
    <div>
      <BreadCrumb arrOfOption={['Hajj', 'Hajji Registration']} />
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 12 }}
        style={{ marginBottom: '25px' }}
      >
        {permission?.['create:any'] && (
          <Col lg={3}>
            <Link to={'/hajjpreregistration/add'}>
              <Button
                type='primary'
                icon={<ArrowLeftOutlined />}
                style={{ width: '100%' }}
              >
                Create
              </Button>
            </Link>
          </Col>
        )}

        <Row gutter={[12, 12]}>
          <Col>
            <Form.Item name='month'>
              <DatePicker
                style={{ width: 250 }}
                onChange={onChange}
                picker='month'
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>

      <Table
        size='small'
        rowKey={(e) => Math.random()}
        rowClassName={(record) =>
          record?.haji_info_status === 'canceled' ? 'refund-row-style' : ''
        }
        bordered
        columns={Hajj_Reg_Col({ pagination, permission })}
        scroll={{ x: true }}
        dataSource={allData}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
      {isLoadingData && <LoadingIndicator />}
    </div>
  );
};

export default Hajj_Reg;
