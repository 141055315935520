import Table, { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { FormatDate } from '../../../common/utils/common.utils';
import BreadCrumb from '../../common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../common/spinner/LoadingIndicator';
import { useJourneyDateNotificationQuery } from '../Api/NotificationEndpoints';
import { IJourneyNotification } from '../Interfaces/Notification.interface';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../layout/components/SearchInvoices';

type Props = {};

const Journey_Wise_Notification = (props: Props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const {
    data: journeyNotification,
    isLoading,
    isFetching,
  } = useJourneyDateNotificationQuery({ ...pagination });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const column: ColumnsType<IJourneyNotification> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',

      render: (_, data) => {
        const { invoice_no, invoice_category_id, airticket_invoice_id } = data;

        const cat_id = Number(invoice_category_id);

        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(cat_id) +
                '/' +
                'details' +
                '/' +
                airticket_invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Ticket No ',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },

    {
      title: 'Journey Date',
      key: 'airticket_journey_date',
      render: (_, data) => <> {FormatDate(data.airticket_journey_date)} </>,
    },
    {
      title: 'Return Date',
      key: 'airticket_return_date',
      render: (_, data) => <> {FormatDate(data?.airticket_return_date)} </>,
    },
  ];

  return (
    <div>
      <BreadCrumb arrOfOption={['Due Invoice']} />
      <Table
        size='small'
        bordered
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        dataSource={journeyNotification?.data}
        columns={column}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={
          journeyNotification?.count !== undefined &&
          journeyNotification?.count < 20
            ? false
            : {
                ...pagination,
                total: journeyNotification?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </div>
  );
};

export default Journey_Wise_Notification;
