import { Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { useEffect } from 'react';
import { FormInput, NumberInput, TextAreaInput } from '../FormItem/FormItems';
import { InvoiceDisPrevRadioButton } from './InvoiceMoneyReceipt';

type Props = {
  form: FormInstance<any>;
};

const InvoiceSubTotal = ({ form }: Props) => {
  const billingForm = useWatch(['billing_information'], form);

  const invoice_sub_total = useWatch(['invoice_sub_total'], form);
  const discount: number = useWatch(['invoice_discount'], form);
  const serviceCharge: number = useWatch(['invoice_service_charge'], form);
  const vat: number = useWatch(['invoice_vat'], form);
  const billing_cost_price = useWatch(['billing_cost_price'], form);

  useEffect(() => {
    form?.setFieldValue(
      'invoice_net_total',
      (Number(invoice_sub_total) || 0) -
        (Number(discount) || 0) +
        (Number(vat) || 0) +
        (Number(serviceCharge) || 0)
    );
  }, [billingForm, invoice_sub_total, discount, vat, serviceCharge]);

  return (
    <Row gutter={[10, 0]} className='p-5 border'>
      <NumberInput
        size={3}
        label='Sub Total'
        name='invoice_sub_total'
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        min='0'
        size={3}
        label={'Discount'}
        name={'invoice_discount'}
        maxChar={10}
      />

      <NumberInput
        size={3}
        label={'Service Charge'}
        name={'invoice_service_charge'}
        min='0'
        maxChar={10}
      />

      <NumberInput
        min='0'
        size={3}
        label='Vat / Tax'
        name='invoice_vat'
        maxChar={10}
      />
      <NumberInput
        size={3}
        label='Net Total'
        name={'invoice_net_total'}
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        size={3}
        label='Agent Commission'
        name={'invoice_agent_com_amount'}
        min='0'
        maxChar={10}
      />
      <NumberInput
        size={3}
        label='Invoice Due'
        name={'invoice_client_previous_due'}
        readOnly
        min='0'
        maxChar={14}
      />
      <NumberInput
        size={3}
        label='Present Balance'
        name={'client_present_balance'}
        readOnly
        min='0'
        maxChar={14}
      />
      <TextAreaInput size={3} label='Note' name='invoice_note' />
      <FormInput size={3} label='Reference' name='invoice_reference' />
    </Row>
  );
};

export default InvoiceSubTotal;
