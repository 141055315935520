import {
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Divider,
  theme,
  Timeline,
  Typography,
} from 'antd';

import dayjs from 'dayjs';
import LoadingIndicator from '../../spinner/LoadingIndicator';
import { invoiceViewStyle } from './CommonViewInvoice';
import { useGetInvoiceActivityLogQuery } from '../../../../modules/Invoice_Air_Ticket/api/endpoints/airticketInvoiceEndpoints';
import { InvoiceTaxRefund } from '../../../../common/types/commonInterfaces';
import { Fixed2 } from '../../../../common/utils/common.utils';
type Props = {
  id: number;
  viewInvoiceAcivityRef: React.RefObject<HTMLDivElement>;
  taxInfo: InvoiceTaxRefund | undefined;
};

function RefundTaxInfoDetails({ id, viewInvoiceAcivityRef, taxInfo }: Props) {
  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div
          style={{
            width: '8.27in',
            height: '11.69in',
            background: '#fff',
            paddingTop: '10vh',
          }}
          ref={viewInvoiceAcivityRef}
        >
          <Typography.Title style={{ textAlign: 'center' }} underline level={5}>
            Refund Tax Details Information
          </Typography.Title>
          <Col style={{ maxWidth: '8in', padding: 10 }}>
            <Descriptions bordered size='small' column={2}>
              <Descriptions.Item label='Client Refund Type'>
                {taxInfo?.client_refund_type}
              </Descriptions.Item>
              <Descriptions.Item label='Vendor Refund Type'>
                {taxInfo?.vendor_refund_type}
              </Descriptions.Item>
              <Descriptions.Item label='Client Total Tax Refund'>
                {Fixed2(taxInfo?.client_total_tax_refund)}
              </Descriptions.Item>
              <Descriptions.Item label='Vendor Total Tax Refund'>
                {taxInfo?.vendor_total_tax_refund}
              </Descriptions.Item>
              <Descriptions.Item label='Created At'>
                {taxInfo?.created_at}
              </Descriptions.Item>
              <Descriptions.Item label='Client Account'>
                {taxInfo?.client_account}
              </Descriptions.Item>
              <Descriptions.Item label='Vendor Account'>
                {taxInfo?.vendor_account || 'N/A'}
              </Descriptions.Item>
            </Descriptions>

            <Divider />

            <Typography.Title
              style={{ textAlign: 'center' }}
              underline
              level={5}
            >
              Ticket Information
            </Typography.Title>
            {taxInfo?.ticket_info?.map((ticket, index) => (
              <div key={index}>
                <h3>Ticket #{index + 1}</h3>
                <Descriptions bordered size='small'>
                  <Descriptions.Item label='Air Ticket No'>
                    {ticket.airticket_ticket_no}
                  </Descriptions.Item>
                  <Descriptions.Item label='Refund Tax'>
                    {Fixed2(ticket.refund_tax_amount)}
                  </Descriptions.Item>
                  <Descriptions.Item label='Vendor Name'>
                    {ticket.vendor_name}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ))}
          </Col>
        </div>
      </ConfigProvider>
    </div>
  );
}

export default RefundTaxInfoDetails;
