import { Card, Skeleton, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Iaccount_status } from '../interfaces/dashboard.interfaces';
import DashboardTitle from './DashboardTitle';
import { IDarkMode } from './InvoiceDetails';

type Props = {
  totalClVnCm: Iaccount_status[] | undefined;
  isLoading: boolean;
};

const Client_Vendor_Agent_Insight = ({
  totalClVnCm,
  isDarkMode,
  isLoading,
}: Props & IDarkMode) => {
  if (isLoading) {
    return (
      <Card style={{ height: '100%' }}>
        <Skeleton title={false} paragraph={{ rows: 8 }} active />
      </Card>
    );
  }

  return (
    <div className='dashboard-box-shape' style={{ height: 350 }}>
      <DashboardTitle title="CLIENT'S & VENDOR'S DETAILS" />

      <div
        className='custom-scrollbar'
        style={{
          overflow: 'auto',
          padding: '10px 20px',
        }}
      >
        {totalClVnCm?.map((item: any, index: number) => (
          <div
            key={index}
            className={`dash_client ${isDarkMode ? 'dash_client-dark' : ''}`}
          >
            <div className='client_dash_box'>
              <Link to={'/' + item.type}>
                <Typography.Text
                  style={{
                    color: '#1F8BCB',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {item?.type}
                </Typography.Text>
              </Link>
            </div>
            <div className='client_dash_box'>
              <Typography.Text>Total: {item.total}</Typography.Text>
              <Typography.Text>Active: {item.active}</Typography.Text>
              <Typography.Text>Inactive: {item.inactive}</Typography.Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Client_Vendor_Agent_Insight;
