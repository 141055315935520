import { ColumnsType } from 'antd/lib/table';
import { IAirTicketInfoByNo } from '../../../RefundTypes/RefundTypes';

export const ClientRefundChargeUtils = (): ColumnsType<IAirTicketInfoByNo> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },

    {
      title: 'Sell Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render: (_, data) => Number(data.airticket_client_price),
    },

    {
      title: 'Refund Charge From Client',
      key: 'client_charge',
      dataIndex: 'client_charge',
    },
  ];
};
