import { api } from '../../../../app/baseQuery';
import {
  IAllInvoices,
  IPaginationSD,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  IReissueTicketInfo,
  ISubmitReIssueRefund,
  getForViewAllReIssueInvoiceType,
  nonCommitionInvoicePostType,
} from '../../../Invoice_Non_Comission/types/nonInvoiceAirTicketTypes';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';

import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  IInvoice_info_Existing,
  IReissueRefundDetails,
} from '../../Type/InoviceReissue.interface';
import { RE_ISSUE_AIRTICKET_ID } from '../constants/constants';

export const reIssueAirTicketInvoiceEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getViewInvoiceReissue: build.query<
      HTTPResponse<IViewInvoiceDetails>,
      number
    >({
      query: (id) => ({
        url: `/reissue/view/${id}`,
      }),
      providesTags: () => ['ReIssueAirTicket', 'invoices'],
    }),

    getAllReIssueInvoice: build.query<
      HTTPResponse<getForViewAllReIssueInvoiceType[]>,
      void
    >({
      query: () => ({ url: '/invoice-air-ticket/all_by_cate/3' }),
      providesTags: [
        {
          type: 'ReIssueAirTicket',
          id: RE_ISSUE_AIRTICKET_ID,
        },
      ],
    }),

    getSingleReIssueInvoice: build.query<
      HTTPResponse<nonCommitionInvoicePostType>, //using noncomission type for similar fields
      number
    >({
      query: (id) => ({
        url: `/reissue/${id}`,
      }),
      providesTags: [{ type: 'ReIssueAirTicket', id: RE_ISSUE_AIRTICKET_ID }],
    }),

    // REISSUE TICKET INFO FOR REFUND REISSUE
    getReissueTicketInfo: build.query<
      HTTPResponse<IReissueTicketInfo[]>,
      number
    >({
      query: (id) => ({
        url: `/reissue/ticket-info/${id}`,
      }),
    }),

    getExistingReIssueInvoice: build.query<
      HTTPResponse<IInvoice_info_Existing>, //using noncomission type for similar fields
      number
    >({
      query: (id) => ({
        url: `/reissue/${id}`,
      }),
      providesTags: [{ type: 'ReIssueAirTicket', id: RE_ISSUE_AIRTICKET_ID }],
    }),

    // GET ALL DATA,TRASH
    getAllInvoicesReissue: build.query<
      HTTPResponse<IAllInvoices[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/reissue?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => ['invoices', 'ReIssueAirTicket'],
    }),

    // DELETE
    deleteReissue: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/reissue/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'ReIssueAirTicket',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
    //create data
    postInvoiceReIssueAirticket: build.mutation<
      HTTPResponse<{ invoice_id: number; update_invoice_no: string }>,
      any
    >({
      query: (body) => ({
        url: '/reissue',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'invoices',
        'ReIssueAirTicket',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: MONEY_RECEIPT_TAG },
      ],
    }),
    //update
    updateInvoiceReIssueAirticket: build.mutation<
      HTTPResponse<{ invoice_id: number; update_invoice_no: string }>,
      { id: string; body: any }
    >({
      query: ({ id, body }) => ({
        url: `/reissue/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'invoices',
        'ReIssueAirTicket',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //RE-ISSUE REFUND
    reIssueRefund: build.mutation<HTTPResponse<void>, ISubmitReIssueRefund>({
      query: (body) => ({
        url: `/reissue/refund`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [
        'invoices',
        'ReIssueAirTicket',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //RE-ISSUE REFUND DETAILS
    reIssueRefundDetails: build.query<
      HTTPResponse<IReissueRefundDetails>,
      string | undefined
    >({
      query: (id) => ({
        url: `/reissue/refund/${id}`,
        method: 'GET',
      }),
      providesTags: [
        'invoices',
        'ReIssueAirTicket',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  useGetAllReIssueInvoiceQuery,
  useGetSingleReIssueInvoiceQuery,
  useGetExistingReIssueInvoiceQuery,
  useLazyGetSingleReIssueInvoiceQuery,
  useGetAllInvoicesReissueQuery,
  useLazyGetAllInvoicesReissueQuery,
  useDeleteReissueMutation,
  useGetViewInvoiceReissueQuery,
  usePostInvoiceReIssueAirticketMutation,
  useUpdateInvoiceReIssueAirticketMutation,
  useGetReissueTicketInfoQuery,
  useReIssueRefundMutation,
  useReIssueRefundDetailsQuery,
} = reIssueAirTicketInvoiceEndpoints;
