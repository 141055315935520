import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  IPassportDataType,
  ISinglePassport,
} from '../../PassportTypes/PassportTypes';
import { PASSPORT } from '../Constants/Constants';

export const passportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all passports
    getAllPassport: build.query<
      HTTPResponse<IPassportDataType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `/passport-management?page=${arg.current}&size=${
          arg.pageSize
        }&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}&filter=${
          arg.filter === 'all' ? '' : arg.filter
        }`,
      }),
      providesTags: [{ type: 'Passport', id: PASSPORT }],
    }),

    // Select passports with Search
    selectPassport: build.query<HTTPResponse<IPassportDataType[]>, string>({
      query: (search) => ({
        url: `passport-management/view-all?search=${search}`,
      }),
      providesTags: [{ type: 'Passport', id: PASSPORT }],
    }),

    //get single passport
    getSinglePassport: build.query<HTTPResponse<ISinglePassport>, number>({
      query: (id) => ({ url: `/passport-management/${id}` }),
      providesTags: [{ type: 'Passport', id: PASSPORT }],
    }),

    // get passport file
    getAllPassportFile: build.query<HTTPResponse<Blob>, string>({
      query: (fileName) => ({
        url: `/passport-management/files/${fileName}`,
        mode: 'no-cors',
        headers: {
          'Content-Type': 'image/webp',
        },
      }),
      providesTags: [{ type: 'Passport', id: PASSPORT }],
    }),

    //create passport
    createPassport: build.mutation<HTTPResponse<{ data: number }>, FormData>({
      query: (body) => ({
        url: '/passport-management',
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Passport', id: PASSPORT }],
    }),

    //edit passport
    editPassport: build.mutation<void, { id: number; formData: FormData }>({
      query: (body) => ({
        url: `/passport-management/${body.id}`,
        method: 'PATCH',
        body: body.formData,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Passport Edited Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Passport', id: PASSPORT }],
    }),
    deletePassport: build.mutation<void, { id: number }>({
      query: (body) => ({
        url: `/passport-management/${body.id}`,
        method: 'DELETE',
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Passport has been deleted');
        });
      },
      invalidatesTags: () => [{ type: 'Passport', id: PASSPORT }],
    }),
    //get single passport
    checkExistPassport: build.query<HTTPResponse<boolean>, number | string>({
      query: (id) => ({ url: `/passport-management/is-unique-pass-no/${id}` }),
      providesTags: [{ type: 'Passport', id: PASSPORT }],
    }),

    passportExcelDownload: build.query<void, IPaginationSD>({
      query: (arg) => ({
        url: `/passport-management/excel?page=${arg.current}&size=${
          arg.pageSize
        }&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}&filter=${
          arg.filter === 'all' ? '' : arg.filter
        }`,
        responseHandler: async (response) => {
          const filename = `passport_list.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetAllPassportQuery,
  useGetSinglePassportQuery,
  useLazyGetSinglePassportQuery,
  useGetAllPassportFileQuery,
  useCreatePassportMutation,
  useEditPassportMutation,
  useLazyCheckExistPassportQuery,
  useLazySelectPassportQuery,
  useSelectPassportQuery,
  useDeletePassportMutation,
  useLazyPassportExcelDownloadQuery,
} = passportEndpoints;
