import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import {
  FormatDateV1,
  getAirportByItaCode,
} from '../../../common/utils/common.utils';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceMoneyReceipt from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import TourBillingInfo from '../Components/Components/TourBillingInfo';
import TourHeaderForm from '../Components/InvoiceTourHeader/TourHeaderForm';
import { useCreateInvoiceTourMutation } from '../Endpoints/GenerateInvoiceEndpoints';
import AddCostingTour from '../Modals/AddCostingTour';
import {
  IInvoiceTourBilling,
  IInvoiceTourPost,
  IInvoiceTourPostEndPoint,
} from '../Types/InvoiceTourTypes';

type Props = {};

const NewInvoiceTourPackage = (props: Props) => {
  const [form] = Form.useForm();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const billingInfo: IInvoiceTourBilling[] = useWatch(['tourBilling'], form);

  const totalSalesPrice =
    billingInfo?.reduce((a, b) => a + Number(b?.billing_total_sales || 0), 0) ||
    0;

  //set subtotal
  useEffect(() => {
    form.setFieldValue('invoice_sub_total', totalSalesPrice);
  }, [totalSalesPrice]);

  // submit create form
  const user = useSelector(selectUser);

  const [createTour, { isError, error, isSuccess, isLoading }] =
    useCreateInvoiceTourMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  const handleCreateForm = async (values: IInvoiceTourPost) => {
    const money_receipt = values.account_id
      ? {
          receipt_total_amount: values.receipt_total_amount,
          receipt_payment_type: values.receipt_payment_type,
          account_id: values.account_id,
          receipt_money_receipt_no: values.receipt_money_receipt_no,
          receipt_note: values.receipt_note,
          receipt_payment_date: FormatDateV1(values.receipt_payment_date),
        }
      : undefined;

    const tourBilling = values.tourBilling.map((el: IInvoiceTourBilling) => {
      return {
        billing_product_id: el.billing_product_id,
        billing_pax_name: el.billing_pax_name,
        billing_total_sales: el.billing_total_sales,
        billing_cost_price: el.billing_cost_price,
        billing_profit: el.billing_profit,
        billing_total_pax: el.billing_total_pax,
        billing_country_id: el.billing_country_id,
        billing_numof_room: el.billing_numof_room,
      };
    });
    const body: IInvoiceTourPostEndPoint = {
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_sales_date: FormatDateV1(values.invoice_sales_date),
      invoice_due_date: FormatDateV1(values.invoice_due_date),
      invoice_no: values.invoice_no,
      itour_group_id: values.itour_group_id,
      itour_day: values.itour_day,
      itour_night: values.itour_night,
      invoice_agent_id: values.invoice_agent_id,
      invoice_sub_total: values.invoice_sub_total,
      invoice_discount: values.invoice_discount,
      invoice_service_charge: values.invoice_service_charge,
      invoice_vat: values.invoice_vat,
      invoice_net_total: values.invoice_net_total,
      invoice_agent_com_amount: values.invoice_agent_com_amount,
      invoice_client_previous_due: values.invoice_client_previous_due,
      client_present_balance: values.client_present_balance,
      invoice_note: values.invoice_note,
      invoice_reference: values.invoice_reference,
      tourTransports: values.tourTransports[0].transport_itinerary_id
        ? values.tourTransports
        : [],
      tourFoods: values.tourFoods[0].food_itinerary_id ? values.tourFoods : [],
      tourAccms: values.tourAccms[0].accm_itinerary_id ? values.tourAccms : [],
      tourOtherTrans: values.tourOtherTrans[0].other_trans_itinerary_id
        ? values.tourOtherTrans
        : [],

      ticket_no: values?.ticket_no,
      ticket_pnr: values?.ticket_pnr,
      ticket_route: values?.ticket_route
        ? (getAirportByItaCode(
            values?.ticket_route as string,
            selectAirport
          ) as string[])
        : undefined,
      ticket_journey_date: FormatDateV1(values?.ticket_journey_date),
      ticket_return_date: FormatDateV1(values?.ticket_return_date),
      ticket_airline_id: values?.ticket_airline_id,
      ticket_cost_price: values.ticket_cost_price
        ? Number(values.ticket_cost_price)
        : undefined,
      ticket_comvendor_id: values.ticket_comvendor_id,

      guide_itinerary_id: values.guide_itinerary_id,
      guide_description: values.guide_description,
      guide_cost_price: values.guide_cost_price,
      guide_comvendor_id: values.guide_comvendor_id,

      itour_from_date: FormatDateV1(values.tour_invoice_journey_date[0]),
      itour_to_date: FormatDateV1(values.tour_invoice_journey_date[1]),

      money_receipt: money_receipt,
      invoice_created_by: user?.user_id as number,

      tourBilling: tourBilling,
    };

    checkCreditLimit({
      amount: body.invoice_net_total,
      combClient: body.invoice_combclient_id,
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        createTour(body).then((res: any) => {
          if (res?.data?.data?.invoice_id)
            updateAndPrev
              ? navigate(`/invoiceTour/details/${res.data.data.invoice_id}`, {
                  state: location.pathname,
                })
              : navigate('/tour-invoice/all', { state: location.pathname });
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice tour has been created!');
      // navigate("/tour-invoice/all");
    }
    if (isError) {
      message.error('Thare was an error!');
    }
  }, [isLoading]);

  const invoice_net_total = useWatch(['invoice_net_total'], form);

  return (
    <>
      <div>
        <BreadCrumb arrOfOption={['Invoice', 'Tour Create']} />

        <Form
          layout='vertical'
          form={form}
          onFinish={handleCreateForm}
          onFinishFailed={() =>
            message.error('Pleace fill up all require field')
          }
        >
          <TourHeaderForm
            form={form}
            invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
            invoice_net_totalFieldName={['invoice_net_total']}
          />
          <AddCostingTour form={form} />
          <TourBillingInfo form={form} billInitialValues={undefined} />
          <InvoiceVisaSubTotalForm form={form} />
          <InvoiceMoneyReceipt form={form} netTotal={invoice_net_total || 0} />

          {/* @AIRTICKET EIDT BUTTON */}
          <Row gutter={[10, 10]}>
            <Col>
              <FormButton
                loading={!updateAndPrev && (isLoading || limiLoading)}
                label='CREATE'
                onClick={() => {
                  setUpdateAndPrev(false);
                }}
              />
            </Col>
            <Col>
              <FormButton
                loading={updateAndPrev && (isLoading || limiLoading)}
                label='CREATE & PREVIEW'
                onClick={() => {
                  setUpdateAndPrev(true);
                }}
              />
            </Col>
          </Row>
        </Form>
      </div>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default NewInvoiceTourPackage;
