import { Col, Input, Row, Table, Typography } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirlinesWithPaginationQuery } from '../Endpoints/airlineEndpoints';
import { AirlineDataType } from '../types/AirlinesTypes';
import { airlinesColumns } from '../utils/utils';

const Airline = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<AirlineDataType | null>(null);
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: airline,
    isFetching,
    refetch,
  } = useGetAllAirlinesWithPaginationQuery(
    { ...pagination, search },
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache.data?.count;

        const dataToReturn: AirlineDataType[] = [];
        const airLineNameList: string[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            airLineNameList.push(element.airline_name);
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: { dataToReturn, airLineNameList, count } };
      },
    }
  );

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Airline']}
        refetch={refetch}
        reloaderSize='small'
      />

      <Row justify={'center'}>
        <Col span={13}>
          <Row align={'middle'} justify={'space-between'}>
            <Col>
              <Typography.Title level={4}>Airline List</Typography.Title>
            </Col>
            <Col>
              <Input.Search
                allowClear
                onSearch={setSearch}
                style={{ margin: '10px 0px' }}
                placeholder='Search Airline...'
              />
            </Col>
          </Row>
          <Table
            size='small'
            bordered
            style={{ width: 900 }}
            pagination={
              airline?.count !== undefined && airline?.count < 20
                ? false
                : {
                    ...pagination,
                    total: airline?.count,
                    showSizeChanger: true,
                    pageSizeOptions: ['20', '50', '100', '200', '500'],
                    onChange: handlePaginationChange,
                  }
            }
            columns={airlinesColumns({
              setEditInfo,
              showModal,
              permission,
              pagination,
            })}
            dataSource={airline.dataToReturn}
            scroll={{ x: true }}
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Airline;
