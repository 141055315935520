import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  FormListFieldData,
  InputNumber,
  Row,
} from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from 'react';
import { NumberInput } from '../../../components/common/FormItem/FormItems';
import {
  SelectCountry,
  SelectProduct,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { IBillInfo } from '../QuotationTypes/QuotationTypes';
import { StoreValue } from 'antd/es/form/interface';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
type Props = {
  vendorField?: boolean;
  field: FormListFieldData;
  form: FormInstance<any>;
  billInitialValues?: IBillInfo[];
  add: (defaultValue?: StoreValue, insertIndex?: number) => void;
  remove: (index: number | number[]) => void;
  index: number;
  total: number;
  description?: string | undefined;
};

const Billing_Info = ({
  field,
  form,
  billInitialValues,
  vendorField,
  remove,
  add,
  index,
  total,
  description,
}: Props) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [unitPrice, setUnitPrice] = useState<number>();
  const [qty, setQty] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();

  useEffect(() => {
    if (billInitialValues && billInitialValues[field.name]) {
      setUnitPrice(
        billInitialValues && billInitialValues[field.name].unit_price
      );
      setQty(billInitialValues && billInitialValues[field.name].quantity);
    }
  }, [billInitialValues]);

  useEffect(() => {
    if (qty !== undefined && unitPrice !== undefined) {
      setTotalPrice(qty * unitPrice);
    } else if (qty) {
      setTotalPrice(undefined);
      setUnitPrice(undefined);
    } else if (unitPrice) {
      setTotalPrice(undefined);
      setQty(undefined);
    } else if (billInitialValues && billInitialValues[field.name]) {
      setTotalPrice(billInitialValues[field.name].total_price);
    }
  }, [form.getFieldValue('bill_info')]);

  useEffect(() => {
    form.setFields([
      {
        name: ['bill_info', field.name, 'total_price'],
        value: totalPrice,
      },
    ]);
  }, [form.getFieldValue('bill_info')]);

  const color = localStorage.getItem('theme') as 'defaultTheme' | 'darkTheme';

  const config = {
    theme: color === 'darkTheme' ? 'default' : 'dark',
    height: 200,
    style: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    toolbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#000000',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    statusbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
  };

  useEffect(() => {
    form.setFields([
      {
        name: ['bill_info', field.name, 'description'],
        value: content,
      },
    ]);
  }, [content]);

  useEffect(() => {
    if (description) {
      setContent(description);
    }
  }, [description]);

  return (
    <>
      {index !== 0 ? (
        <Divider>Billing Information: SL ({index + 1})</Divider>
      ) : undefined}

      <Row className='p-10' gutter={12}>
        <SelectProduct
          label='Product'
          name={[field.name, 'product_id']}
          size={5}
          placeholder='Select Product'
          required
        />
        <SelectCountry
          label='Country'
          name={[field.name, 'billing_country_id']}
          size={5}
        />

        <NumberInput
          label='Unit Price'
          name={[field.name, 'unit_price']}
          onChange={(e) => {
            setUnitPrice(Number(e));
          }}
          size={5}
          required
          min='0'
          maxChar={14}
          minChar={0}
        />

        <Col span={4} xs={12} sm={12} md={12} lg={5}>
          <Form.Item
            label='Quantity'
            name={[field.name, 'quantity']}
            rules={[
              { required: true, message: 'Quantity is required' },
              {
                validator(_, value) {
                  const roundedValue = Math.round(value);
                  if (value) {
                    if (roundedValue !== value) {
                      return Promise.reject('Quantity cannot be decimal!!');
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <InputNumber
              onChange={(e) => {
                setQty(Number(e));
              }}
              placeholder='Quantity'
              style={{ width: '100%' }}
              min={0}
            />
          </Form.Item>
        </Col>

        <NumberInput
          label='Total Price'
          name={[field.name, 'total_price']}
          size={4}
          readOnly
          min='0'
          maxChar={14}
          minChar={0}
        />

        <Col span={4} xs={12} sm={12} md={12} lg={18}>
          <JoditEditor
            config={config}
            ref={editor}
            value={content}
            onBlur={(newContent) => {
              setContent(newContent);
            }}
          />
        </Col>

        <Col span={18}>
          <Flex justify='end' gap={10} style={{ marginTop: 10 }}>
            {total === index + 1 ? (
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => add()}
              >
                Add More Billing
              </Button>
            ) : (
              ''
            )}

            {index !== 0 ? (
              <Button
                type='primary'
                icon={<MinusCircleOutlined />}
                danger
                onClick={() => remove(field.name)}
              >
                Remove
              </Button>
            ) : (
              ''
            )}
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default Billing_Info;
