import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { message } from 'antd';
import { api } from '../../../../../app/baseQuery';
import { IAllInvoices } from '../../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import { expire } from '../../../../../common/utils/constants';
import { MONEY_RECEIPT_TAG } from '../../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { COMBINE_CLIENT } from '../../../combined_clients/api/endpoints/clientCombineEndpoints';
import {
  ClientDataType,
  IClientAddFormData,
  IClientAndCombined,
  IClientDetailRefundData,
  IClientEditFormData,
  IClientMoneyReceiptData,
  IClientPassportData,
  IClientQuotationData,
  IPrevClient,
  ISingleClient,
} from '../../types/clientInterfaces';

export const clientEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getClients: build.query<
      HTTPResponse<ClientDataType[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/client/all?&page=${arg.current}&size=${arg.pageSize}&search=${
          arg.search || ''
        }`,
      }),
      providesTags: () => [
        MONEY_RECEIPT_TAG,
        'Dashboard',
        { type: 'Clients', id: 'CLIENT' },
        'Clients',
      ],
    }),

    getClientExcelData: build.query<
      HTTPResponse<any>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/report/client-all?&page=${arg.current}&size=${
          arg.pageSize
        }&search=${arg.search || ''}`,
        responseHandler: async (response) => {
          const filename = `client.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
    getAllClients: build.query<
      HTTPResponse<ClientDataType[]>,
      { search?: string }
    >({
      query: ({ search }) => ({
        url: `/client/all${search ? `?search=${search}` : ''}`,
      }),
      providesTags: () => [
        MONEY_RECEIPT_TAG,
        'Dashboard',
        { type: 'Clients', id: 'CLIENT' },
        'Clients',
      ],
    }),

    getClientsBySearch: build.query<HTTPResponse<ClientDataType[]>, string>({
      query: (search) => ({
        url: `/client/view-all${search ? `?search=${search}` : ''}`,
      }),
      providesTags: () => [
        MONEY_RECEIPT_TAG,
        'Dashboard',
        { type: 'Clients', id: 'CLIENT' },
        'Clients',
      ],
    }),

    getAllCombClients: build.query<HTTPResponse<IClientAndCombined[]>, string>({
      query: (search) => ({
        url: `/client/all-clients-combined${search ? `?search=${search}` : ''}`,
      }),
      providesTags: () => [
        MONEY_RECEIPT_TAG,
        'Dashboard',
        { type: 'Clients', id: 'CLIENT' },
        'Clients',
      ],
    }),

    getAllClientsAndCombined: build.query<
      HTTPResponse<IClientAndCombined[]>,
      void
    >({
      query: () => ({ url: '/client/all-clients-combined' }),

      providesTags: () => [
        { type: 'Clients', id: 'CLIENT' },
        'Money_receipt',
        'Dashboard',
        { type: 'invoices' },
        COMBINE_CLIENT,
      ],
    }),
    getSingleClients: build.query<HTTPResponse<ISingleClient>, string | string>(
      {
        query: (id) => ({ url: `/client/${id}` }),
        keepUnusedDataFor: expire.min,
        providesTags: (_res, _err, id) => [
          { type: 'Clients', id: id } as const,
        ],
      }
    ),

    getClLastBalance: build.query<HTTPResponse<IPrevClient>, string>({
      query: (id) => ({ url: `/client/clients-lastbalance/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [{ type: 'Clients', id: id } as const],
    }),
    getTrashedClients: build.query<HTTPResponse<ClientDataType[]>, void>({
      query: () => ({ url: '/client/deleted-clients' }),
      providesTags: () => [{ type: 'Clients', id: 'CLIENT' }],
    }),

    getAllInvoiceClient: build.query<
      HTTPResponse<IAllInvoices[]>,
      { id: string; current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/client/client-invoice/${arg.id}?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Clients', id: 'CLIENT' }],
    }),

    getAllMoneyReceiptClient: build.query<
      HTTPResponse<IClientMoneyReceiptData[]>,
      string
    >({
      query: (id) => ({ url: `/client/client-money-receipts/${id}` }),
      providesTags: () => [{ type: 'Clients', id: 'CLIENT' }],
    }),

    getAllQuotationClient: build.query<
      HTTPResponse<IClientQuotationData[]>,
      string
    >({
      query: (id) => ({ url: `/client/client-quotations/${id}` }),
      providesTags: () => [{ type: 'Clients', id: 'CLIENT' }],
    }),

    getAllPassportClient: build.query<
      HTTPResponse<IClientPassportData[]>,
      number
    >({
      query: (id) => ({ url: `/client/client-passport/${id}` }),
      providesTags: () => [{ type: 'Clients', id: 'CLIENT' }],
    }),

    activateClient: build.mutation<
      HTTPResponse<void>,
      { client_id: number; status: 'active' | 'inactive'; created_by: number }
    >({
      query: (arg) => ({
        url: `/client/activate/${arg.client_id}`,
        body: arg,
        method: 'PATCH',
      }),
      invalidatesTags: () => [
        'Dashboard',
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Clients', id: 'LOGS' },
      ],
    }),

    addClient: build.mutation<
      HTTPResponse<{
        client_id: number;
        client_entry_id: string;
        category_prefix: string;
      }>,
      { values: IClientAddFormData; user_full_name?: string }
    >({
      query: (body) => ({
        url: '/client/create',
        method: 'POST',
        body: body.values,
      }),

      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        assyncWrapper(async () => {
          const { data } = await queryFulfilled;
          message.success('Client Added Successfully');
          const clientDraft = (
            draft: MaybeDrafted<HTTPResponse<ClientDataType[]>>
          ) => {
            if (draft.data) {
              const {
                client_name,
                email,
                mobile,
                opening_balance,
                opening_balance_type,
                company_name,
              } = arg.values;
              const openBalance =
                opening_balance_type === 'advance'
                  ? (opening_balance as number)
                  : opening_balance_type === 'due'
                  ? -(opening_balance as number)
                  : 0;

              const parsedMobile = mobile.map((item) => {
                return [item.dialCode, item.number];
              });

              const dataToInsert = {
                client_id: data.data?.client_id as number,
                client_name: company_name || client_name,
                email: email,
                mobile: JSON.stringify(parsedMobile),
                client_activity_status: 1,
                client_last_balance: openBalance,
                created_by: arg.user_full_name as string,
                client_entry_id: data.data?.client_entry_id as string,
                category_prefix: data.data?.category_prefix as string,
              };

              draft.data.unshift(dataToInsert as ClientDataType);
            }
          };

          const clientAction = clientEndpoints.util.updateQueryData(
            'getAllClients',
            {},
            clientDraft
          );
          dispatch(clientAction);
        });
      },
      invalidatesTags: [
        { type: 'Clients', id: 'LOGS' },
        { type: 'Clients', id: 'CLIENT' },
      ],
    }),

    editClient: build.mutation<
      HTTPResponse<void>,
      { id: string; values: IClientEditFormData }
    >({
      query: (body) => ({
        url: `/client/${body.id}`,
        method: 'PATCH',
        body: body.values,
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Client Edited Successfully');

          const clientDraft = (
            draft: MaybeDrafted<HTTPResponse<ClientDataType[]>>
          ) => {
            if (draft.data) {
              const { client_name, email, company_name, mobile } = arg.values;
              const parsedMobile: [string, string][] = [];

              mobile.forEach((item) =>
                parsedMobile.push([item.dialCode, item.number])
              );

              const dataToUpdate = {
                client_name: company_name || client_name,
                email,
                mobile: JSON.stringify(parsedMobile),
              };
              let index = draft.data?.findIndex(
                (item) => item.client_id.toString() === arg.id
              );
              draft.data[index] = { ...draft.data[index], ...dataToUpdate };
            }
          };

          const editAction = clientEndpoints.util.updateQueryData(
            'getAllClients',
            {},
            clientDraft
          );

          dispatch(editAction);
        });
      },
      invalidatesTags: (_res, _err, { id }) => [
        { type: 'Clients', id },
        { type: 'Clients', id: 'LOGS' },
        { type: 'Clients', id: 'CLIENT' },
      ],
    }),

    deleteClient: build.mutation<HTTPResponse<void>, ClientDataType>({
      query: (body) => ({
        url: `/client/${body.client_id}`,
        method: 'DELETE',
        body: body,
      }),

      onQueryStarted: (body, { dispatch, queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Client Deleted Successfully');

          const clientDraft = (
            draft: MaybeDrafted<HTTPResponse<ClientDataType[]>>
          ) => {
            if (draft.data) {
              let index = draft.data.findIndex(
                (item) => item.client_id === body.client_id
              );

              if (body.client_last_balance == 0) {
                draft.data.splice(index, 1);
              }
            }
          };
          const clientAction = clientEndpoints.util.updateQueryData(
            'getAllClients',
            {},
            clientDraft
          );
          dispatch(clientAction);

          /**
           *
           * trashed clients
           */
          const trashedClientDraft = (
            draft: MaybeDrafted<HTTPResponse<ClientDataType[]>>
          ) => {
            if (draft.data) {
              const dataToInsert: ClientDataType = {
                ...body,
              };

              draft.data.unshift(dataToInsert);
            }
          };
          const trashedClientAction = clientEndpoints.util.updateQueryData(
            'getTrashedClients',
            undefined,
            trashedClientDraft
          );
          dispatch(trashedClientAction);
        });
      },
      invalidatesTags: (_res, _err, values) => [
        { type: 'Clients', id: values.client_id },
        { type: 'Clients', id: 'LOGS' },
        'Clients',
      ],
    }),

    getAllClientDetailRefund: build.query<
      HTTPResponse<IClientDetailRefundData>,
      string
    >({
      query: (id) => ({ url: `/client/client-refund/${id}` }),
      providesTags: () => [{ type: 'Clients', id: 'CLIENT' }],
    }),
    checkCreditLimit: build.mutation<
      HTTPResponse<1 | string>,
      {
        amount: number;
        combClient: string;
      }
    >({
      query: (body) => ({
        url: `client/check-credit-limit`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetClientsQuery,
  useLazyGetClientsQuery, // clients with pagination
  useGetAllClientsQuery, // all clients with search
  useLazyGetAllClientsQuery, // all clients with search
  useGetSingleClientsQuery,
  useLazyGetSingleClientsQuery,
  useGetAllInvoiceClientQuery,
  useGetAllMoneyReceiptClientQuery,
  useGetAllQuotationClientQuery,
  useGetAllPassportClientQuery,
  useAddClientMutation,
  useDeleteClientMutation,
  useEditClientMutation,
  useGetTrashedClientsQuery,
  useLazyGetTrashedClientsQuery,
  useLazyGetClientExcelDataQuery,
  useGetAllClientDetailRefundQuery,
  useGetClLastBalanceQuery,
  useLazyGetClLastBalanceQuery,
  useActivateClientMutation,
  useGetAllClientsAndCombinedQuery,
  useLazyGetAllCombClientsQuery, // Get all client and combine client by search

  useGetAllCombClientsQuery,

  useLazyGetClientsBySearchQuery, // Get only client by search
  useCheckCreditLimitMutation, //   @CHECK CREDIT LIMIT OF CLIENT OR COMB CLIENT
} = clientEndpoints;
