import { ColumnsType } from 'antd/lib/table';
import { IAirTicketRefundVendorDetails } from '../../../RefundTypes/RefundTypes';

export const AirTicketRefundVendorSingleViewUtils =
  (): ColumnsType<IAirTicketRefundVendorDetails> => {
    return [
      {
        title: 'Ticket No',
        dataIndex: 'airticket_ticket_no',
        key: 'airticket_ticket_no',
        render: (airticket_ticket_no) => {
          return <div>{airticket_ticket_no}</div>;
        },
        align: 'center',
      },

      {
        title: 'PNR',
        dataIndex: 'airticket_pnr',
        key: 'airticket_pnr',
        render: (airticket_pnr) => {
          return <div>{airticket_pnr}</div>;
        },
        align: 'center',
      },
      {
        title: 'Refund Amount',
        key: 'vrefund_total_amount',
        dataIndex: 'vrefund_total_amount',
        render: (vrefund_total_amount) => {
          return <div>{Number(vrefund_total_amount) || 0}</div>;
        },
        align: 'center',
      },

      {
        title: 'Refund Charge',
        key: 'vrefund_charge_amount',
        dataIndex: 'vrefund_charge_amount',
        render: (vrefund_charge_amount) => {
          return <div>{Number(vrefund_charge_amount) || 0}</div>;
        },
        align: 'center',
      },

      {
        title: 'Return Amount',
        key: 'vrefund_return_amount',
        dataIndex: 'vrefund_return_amount',
        render: (vrefund_return_amount) => {
          return <div>{Number(vrefund_return_amount) || 0}</div>;
        },
        align: 'center',
      },
    ];
  };
