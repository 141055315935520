import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { HTTPResponse, voidType } from '../../../../common/types/commonTypes';
import { NotificationConst } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import {
  IInvoiceUmrahRefundBillInfo,
  IInvoiceUmrahRefundInfo,
  IPostInvoiceUmrahRefund,
  InvoiceUmrahFormatedDataType,
  InvoiceUmrahType,
} from '../../Types/InvoiceUmrahTypes';
import { INVOICE_UMRAH } from '../constants/constants';

export const invoiceUmrahEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllInvoiceUmmrah: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (query) => ({
        url: `/invoice-ummrah?${query}`,
      }),

      providesTags: () => [
        { type: INVOICE_UMRAH },
        { type: 'invoices' },
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // VIEW DETAILIS
    getViewInvoiceUmmrah: build.query<
      HTTPResponse<IViewInvoiceDetails>,
      number
    >({
      query: (id) => ({
        url: `/invoice-ummrah/view/${id}`,
      }),
      providesTags: () => ['invoice-other', 'invoices'],
    }),

    // DELETE AND RESTORE
    deleteInvoiceUmmrah: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/invoice-ummrah/${id}`,
        method: 'DELETE',
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: [
        'invoices',
        'invoice-other',
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    // invoice hajj post
    invoiceUmrahPost: build.mutation<
      HTTPResponse<void>,
      { body: InvoiceUmrahFormatedDataType }
    >({
      query: ({ body }) => ({
        url: `/invoice-ummrah`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: INVOICE_UMRAH },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    invoiceUmrahEdit: build.mutation<
      HTTPResponse<void>,
      { body: InvoiceUmrahFormatedDataType; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/invoice-ummrah/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        { type: INVOICE_UMRAH },
        { type: 'invoices' },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    invoiceUmrahGetForEdit: build.query<
      HTTPResponse<InvoiceUmrahType>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/invoice-ummrah/${id}`,
      }),
      providesTags: () => [{ type: INVOICE_UMRAH }],
    }),

    // Void Invoice Visa
    voidInvoiceUmrah: build.mutation<void, voidType>({
      query: (body) => ({
        url: `/invoice-ummrah/void/${body.id}`,
        method: 'PUT',
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Some things went to wrong');
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Void Successfully');
      },

      invalidatesTags: [
        'invoices',
        INVOICE_UMRAH,
        NotificationConst,
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    // Invoice Umrah Refund Endpoints --------

    invoiceUmrahRefundBillInfo: build.query<
      HTTPResponse<IInvoiceUmrahRefundBillInfo[]>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/invoice-ummrah/billing_info/${id}`,
      }),
      providesTags: () => [{ type: INVOICE_UMRAH }],
    }),

    postInvoiceUmrahRefund: build.mutation<
      HTTPResponse<void>,
      IPostInvoiceUmrahRefund
    >({
      query: (body) => ({
        url: `/invoice-ummrah/refund`,
        body: body,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: INVOICE_UMRAH }],
    }),

    getInvoiceUmrahRefundInfo: build.query<
      HTTPResponse<IInvoiceUmrahRefundInfo>,
      { id?: string }
    >({
      query: ({ id }) => ({
        url: `/invoice-ummrah/refund/${id}`,
      }),
      providesTags: () => [{ type: INVOICE_UMRAH }],
    }),
  }),
});

export const {
  useInvoiceUmrahPostMutation,
  useInvoiceUmrahEditMutation,
  useLazyInvoiceUmrahGetForEditQuery,
  useGetAllInvoiceUmmrahQuery,
  useLazyGetAllInvoiceUmmrahQuery,
  useGetViewInvoiceUmmrahQuery,
  useDeleteInvoiceUmmrahMutation,
  useVoidInvoiceUmrahMutation,
  useInvoiceUmrahRefundBillInfoQuery,
  usePostInvoiceUmrahRefundMutation,
  useGetInvoiceUmrahRefundInfoQuery,
} = invoiceUmrahEndpoints;
