import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IVendorPurchase } from '../types/VendorWisePurchase_PaymentTypes';

export const VendorWisePurchaseUtils = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IVendorPurchase> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Created Date',
      key: 'sales_date',
      render: (_, record) => {
        return `${dayjs(record.create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Sales Date',
      key: 'sales_date',
      render: (_, record) => {
        return `${dayjs(record.sales_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Invoice No.',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;

        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },

    {
      title: 'Ticket No',
      key: 'airticket_ticket_no',
      dataIndex: 'airticket_ticket_no',
    },
    {
      title: 'Voucher No',
      key: 'vouchar_no',
      dataIndex: 'vouchar_no',

      render: (_, data) => {
        const { invendorpay_vpay_id, vouchar_no } = data;

        return (
          <>
            <Link to={`../vendors/payment/${invendorpay_vpay_id}`}>
              {vouchar_no}
            </Link>
          </>
        );
      },
    },

    {
      title: 'Vendor Name',
      key: 'vendor_name',
      dataIndex: 'vendor_name',
    },

    {
      title: 'Purchase Amount',
      key: 'cost_price',
      dataIndex: 'cost_price',
      align: 'right',
      render: (cost_price) => {
        return <> {Number(Fixed2(cost_price))} </>;
      },
    },
  ];
};
