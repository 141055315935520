import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { TState } from '../../auth/states/userSlice';
import { rolePermissionType } from '../../common/types/commonTypes';
import Dashboard from '../../modules/Dashboard/Pages/Dashboard';
import DashboardForOthersUser from '../../modules/Dashboard/Pages/DashboardForOthersUser';
// import IataDashboard from '../../modules/Dashboard/Pages/IataDashboard';
import { useAppSelector } from '../hooks';

// DASHBOARD
export const getDashboardRoute = (
  permission: rolePermissionType | undefined,
  user: TState
) => {
  const appConfig = useAppSelector((state) => state.configSlice);

  const dashboard = <Dashboard />;

  return (
    <Route
      path='/'
      element={
        <PermissionRoute
          permission={['*']}
          element={
            permission?.dashboard || user?.user_role === 'SUPER_ADMIN' ? (
              dashboard
            ) : (
              <DashboardForOthersUser />
            )
          }
        />
      }
    />
  );
};
