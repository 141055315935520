import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Space, Table, theme } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetCleintWisePassengerQuery } from '../endpoints/clientWisePassengerEndpoints';
import { IClientWiseFormDataType } from '../types/clientWisePassengerTypes';
import { clientWisePassColumn } from '../utils/clientWisePassColumn';
type Props = {};

interface DataType {
  key: React.Key;
  passport_no: string;
  name: string;
  mobile_no: number;
  date_of_birth: string;
  date_of_expire: string;
  date_of_issue: string;
  email: string;
}

const ClientWisePassengerList = (props: Props) => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });

  const [fetchClientWisePassenger, { data, isLoading }] =
    useLazyGetCleintWisePassengerQuery();
  const onFinish = async (values: IClientWiseFormDataType) => {
    await fetchClientWisePassenger({
      invoice_combclient_id: values.invoice_combclient_id,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: values.invoice_combclient_id.toString(),
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IClientWiseFormDataType = form.getFieldsValue();
    fetchClientWisePassenger({
      invoice_combclient_id: values.invoice_combclient_id,
      query: `${query}`,
    });

    setExcelQuery({
      Id: values.invoice_combclient_id.toString(),
      query: `${query}`,
    });
  };
  //pagination--end---
  useEffect(() => {
    form.setFieldValue('invoice_combclient_id', 'all');
    fetchClientWisePassenger({
      invoice_combclient_id: 'all',
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: 'all',
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Client_WisePassengerList`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Table
        scroll={{ x: true }}
        bordered
        size='small'
        columns={clientWisePassColumn(queryData)}
        dataSource={data?.data}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        pagination={count > 20 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (Agents)' }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Client wise Passenger List']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'passenger/clientWisePassengerList',
              excelName: 'client_wise_passenger_list',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          showSelectClients
          reports_title='Client wise Passenger List'
        />
      </Form>
      {
        <div>
          <FormHeaderTitle title='Client wise Passenger List' />

          {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
          {jsx_com}
        </div>
      }

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default ClientWisePassengerList;
