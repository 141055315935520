import { FormInstance } from 'antd/es/form/Form';
import { Form } from 'antd';
import { IAirTicketInfoByNo } from '../../RefundTypes/RefundTypes';
import React from 'react';
import AirticketClientInfo from './AirticketClientInfo';

type Props = {
  form: FormInstance<any>;
  airticketClientInitialValues?: IAirTicketInfoByNo[];
  clientRefundTotal?: number;
  clientRefundChargeTotal?: number;
};

const AirticketClientRefund = ({
  airticketClientInitialValues,
  form,
  clientRefundTotal,
  clientRefundChargeTotal,
}: Props) => {


  return (
    <Form.List
      name='client_refund_info'
      initialValue={[{ airticketClientInitialValues }]}
    >
      {(fields, { add, remove }) =>
        fields.map((field, index) => {
          return (
            <React.Fragment key={index}>
              <AirticketClientInfo
                form={form}
                field={field}
                clientRefundTotal={clientRefundTotal}
                clientRefundChargeTotal={clientRefundChargeTotal}
                airticketClientInitialValues={airticketClientInitialValues}
              />
            </React.Fragment>
          );
        })
      }
    </Form.List>
  );
};

export default AirticketClientRefund;
