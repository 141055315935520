import { api } from '../../../app/baseQuery';
import { HTTPResponse } from '../../../common/types/commonTypes';
import {
  IVisaAssignType,
  IVisaProcessingType,
  IWorkflowPassRes,
} from '../types/visa_processingType';

export const visa_process = 'visa-process';
export const visaProcessingEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL
    getAllVisaprocess: build.query<
      HTTPResponse<IVisaProcessingType[]>,
      {
        country: string;
        employee: string;
        status: string;
      }
    >({
      query: ({ country, employee, status }) => ({
        url: `/invoice-visa/workflow?country_id=${country}&employee_id=${employee}&status=${status}`,
      }),
      providesTags: () => [visa_process],
    }),

    // VIEW DETAILIS
    getViewVisaprocess: build.query<HTTPResponse<IVisaProcessingType>, number>({
      query: (id) => ({
        url: `/invoice-visa/workflow/${id}`,
      }),
      providesTags: () => [visa_process],
    }),

    editVisaprocess: build.mutation<
      HTTPResponse<void>,
      { id: number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/invoice-visa/workflow/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [visa_process],
    }),
    postVisaprocess: build.mutation<HTTPResponse<void>, FormData>({
      query: (body) => ({
        url: `/invoice-visa/workflow`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [visa_process],
    }),
    getWorkFlowPassport: build.query<
      HTTPResponse<IWorkflowPassRes[]>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/invoice-visa/invoice-passport/${id}`,
        method: 'GET',
      }),
      // invalidatesTags: [visa_process],
    }),
  }),
});

export const {
  useGetAllVisaprocessQuery,
  useEditVisaprocessMutation,
  useGetViewVisaprocessQuery,
  usePostVisaprocessMutation,
  useLazyGetWorkFlowPassportQuery,
} = visaProcessingEndpoints;
