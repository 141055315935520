import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { EdataType, IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteAdvrMutation,
  useGetAllAdvanceReturnMRQuery,
} from '../../api/endpoints/moneyReceiptEndpoints';
import ViewAdvanceModal from '../../Components/ViewAdvanceModal';
import { IGetAllAdvanceReturn } from '../../Types/MoneyReceiptTypes';
import { rangePresets } from '../../../../common/utils/common.utils';

type Props = { permission?: IPermission };

const AdvanceReturnMoneyReceipt = ({ permission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const navigate = useNavigate();

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data,
    isLoading: getAllIsLoading,
    isFetching,
    refetch,
  } = useGetAllAdvanceReturnMRQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  const allAdvanceReturn = data?.data;
  const [dataSource, setDataSource] = useState(allAdvanceReturn);

  useEffect(() => {
    setDataSource(allAdvanceReturn);
  }, [allAdvanceReturn]);

  // ============== @ HANDLE DELETE @ ======================
  const user = useSelector(selectUser);
  const [deleteAdvr, { isError, isSuccess, error, isLoading }] =
    useDeleteAdvrMutation();
  const handleDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteAdvr({ id, userId: user?.user_id as number });
  };

  let Emessage: string;

  if (error) {
    if ('status' in error) {
      Emessage = (error.data as EdataType).message;
    }
  }

  // ================== notification =========================
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Advance return has been deleted!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error(Emessage);
    }
  }, [isSuccess, isError]);

  // ================== @ VIEW MODAL @ ================
  const [viewModalData, setViewModalData] = useState<IGetAllAdvanceReturn>();
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };

  const handleView = (data: IGetAllAdvanceReturn) => {
    setOpen(true);
    setViewModalData(data);
  };

  const columns: ColumnsType<IGetAllAdvanceReturn> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Return Date',
      key: 'advr_payment_date',

      render: (_, data) => (
        <>{dayjs(data.advr_payment_date).format('DD-MM-YYYY')}</>
      ),
    },

    {
      title: 'Voucher No',
      key: 'advr_vouchar_no',
      dataIndex: 'advr_vouchar_no',
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },

    {
      title: 'Payment Type',
      dataIndex: 'advr_pay_type',
      key: 'advr_pay_type',
    },

    {
      title: 'Payment Details',
      dataIndex: 'cheque_status_or_account_name',
      key: 'cheque_status_or_account_name',

      render: (_, record) => {
        let color =
          record.cheque_status_or_account_name === 'DEPOSIT' ? 'green' : 'red';
        function status(record: any) {
          if (
            record.cheque_status_or_account_name == 'DEPOSIT' ||
            record.cheque_status_or_account_name == 'PENDING' ||
            record.cheque_status_or_account_name == 'BOUNCE'
          ) {
            return (
              <span style={{ color }}>
                {' '}
                {record.cheque_status_or_account_name}{' '}
              </span>
            );
          } else {
            return `${record.cheque_status_or_account_name}`;
          }
        }

        return status(record);
      },
    },

    {
      title: 'Advance Amount',
      dataIndex: 'advr_amount',
      key: 'advr_amount',
      render: (_, data) => Number(data.advr_amount),
    },

    {
      title: 'Return Note',
      dataIndex: 'advr_note',
      key: 'advr_note',
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, data, index) => {
        const { cheque_status_or_account_name } = data;

        return (
          <Space size='small'>
            {permission?.['read:any'] && (
              <Button
                size='small'
                type='primary'
                // onClick={() => handleView(data)}
                onClick={() =>
                  navigate(`/moneyreceipt/advance/view/${data.advr_id}`, {
                    state: location.pathname,
                  })
                }
              >
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            )}

            {permission?.['update:any'] &&
              cheque_status_or_account_name !== 'DEPOSIT' && (
                <Link
                  to={`/moneyreceipt/advance/edit/${data.advr_id}`}
                  state={location.pathname}
                >
                  <Button size='small' type='primary'>
                    Edit
                  </Button>
                </Link>
              )}
            {permission?.['delete:any'] &&
              cheque_status_or_account_name !== 'DEPOSIT' && (
                <Button
                  size='small'
                  type='primary'
                  danger
                  loading={columnIndex === index && true}
                >
                  <Popconfirm
                    onConfirm={() => handleDelete(data.advr_id, index)}
                    title='Sure to delete?'
                  >
                    Delete
                  </Popconfirm>
                </Button>
              )}
          </Space>
        );
      },
    },
  ];

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['List of Advance Return']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      {isLoading && <LoadingIndicator />}

      <ViewAdvanceModal
        open={open}
        onCancel={handleCancel}
        advrData={viewModalData}
      />

      <Row
        justify={'space-between'}
        gutter={[5, { xs: 8, sm: 16, md: 24, lg: 24 }]}
      >
        <Col>
          {permission?.['create:any'] && (
            <Link to='/moneyreceipt/advance/add' state={location.pathname}>
              <Button style={{ marginBottom: '0.5rem' }} type='primary'>
                <PlusOutlined />
                Add Advance Return
              </Button>
            </Link>
          )}
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                 presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        rowKey={(e) => e?.advr_id}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: true }}
        loading={{
          spinning: getAllIsLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </div>
  );
};

export default AdvanceReturnMoneyReceipt;
