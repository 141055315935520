import { Col, Form, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormInputTransaction,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { useGetAccountByTypeQuery } from '../../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../../vendor/components/AccountLastBalanceInput';
import { useGetAuthorityQuery } from '../../Api/Endpoints/AuthorityEndpoints';
import { useLazyGetSingleLoanQuery } from '../../Api/Endpoints/LoanEndpoints';
import {
  useCreatePaymentMutation,
  useLazyGetPaymentAuthorityQuery,
} from '../../Api/Endpoints/PaymentEndpoints';
import { PaymentFormData } from '../../LoanTypes/LoanTypes';

type Props = {};

const AddPaymentModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [authority_id, set_Authority_id] = useState<number>();
  const [loan_id, set_Loan_id] = useState<number>();
  const [due, setDue] = useState<number>();

  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>();
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<number>();

  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );
  const isChanged = useRef(false);
  const { data: authority } = useGetAuthorityQuery();

  const [fetchLoansbyID, { data: loansByID }] =
    useLazyGetPaymentAuthorityQuery();
  useEffect(() => {
    if (authority_id) {
      fetchLoansbyID(Number(authority_id));
    }
  }, [authority_id]);
  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });
      setBalance(undefined);
      return;
    }
  }, [paymentMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    if (account) {
      fetchBalance(Number(account));
    }
  }, [account]);

  const [fetchSingleLoan, { data: loanDetails }] = useLazyGetSingleLoanQuery();

  useEffect(() => {
    if (loan_id) {
      fetchSingleLoan(loan_id);
    }
  }, [loan_id]);

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);

  useEffect(() => {
    form.setFieldValue('payment_date', dayjs());
  }, []);

  useEffect(() => {
    if (loanDetails?.data) {
      form.setFieldValue(
        'payable_amount',
        loanDetails.data.data && loanDetails.data.data.loan_payable_amount
      );
      form.setFieldValue('due_amount', loanDetails.data.data.loan_due_amount);
      setDue(Number(loanDetails.data.data.loan_due_amount));
    }
  }, [loanDetails]);

  const [addPayment, { isSuccess, isLoading }] = useCreatePaymentMutation();

  const onFinish = async (values: PaymentFormData) => {
    const body: PaymentFormData = {
      ...values,
      payment_date: dayjs(values.payment_date).format('YYYY-MM-DD'),
      withdraw_date: dayjs(values.withdraw_date).format('YYYY-MM-DD'),
      created_by: user?.user_id as number,
    };

    await addPayment(body);
  };

  useEffect(() => {
    if (isSuccess) {
      setBalance(undefined);
      set_Loan_id(undefined);
      dispatch(setModal(false));
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      {isLoading && <LoadingIndicator />}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name='authority_id'
            label='Loan Authority'
            rules={[
              { required: true, message: 'Loan Authority is required!!' },
            ]}
          >
            <Select
              allowClear
              placeholder='Select Loan Authority'
              style={{ width: '100%' }}
              onSelect={(e: number) => {
                set_Authority_id(e);
              }}
            >
              {authority?.data?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.authority_id}>
                    {item.authority_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name='loan_id'
            label='Select Loan'
            rules={[{ required: true, message: 'Loan is required!!' }]}
          >
            <Select
              allowClear
              placeholder='Select Loan'
              style={{ width: '100%' }}
              onSelect={(e: number) => {
                set_Loan_id(e);
              }}
            >
              {loansByID?.data?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.loan_id}>
                    {item.loan_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <SelectPaymentMethod
          required
          name='payment_type'
          label='Payment Method '
          onChange={(value: number) => {
            setPayMethodCatId(value);
            setPaymentMethod(value);
            if (!isChanged.current) isChanged.current = true;
          }}
          cheque
          size={12}
        />
        {Number(payMethodCatId) === 4 && (
          <>
            <FormInputItem
              label='Cheque No'
              name='cheque_no'
              required
              size={12}
            />

            <DateInput
              name='withdraw_date'
              label='Withdraw Date'
              required
              size={12}
            />

            <FormInputItem
              name={'bank_name'}
              label='Bank Name'
              required
              onChange={(value) => setAccount(String(value))}
              size={12}
            />
          </>
        )}
        {Number(payMethodCatId) !== 4 && (
          <>
            <AccountLastBalanceInput
              accountsLastBalance={accounts ? accounts.data : []}
              name='account_id'
              label='Account:'
              // disabled={paymentMethod || reason === 'EDIT' ? false : true}
              onSelect={(value: number) => setAccount(String(value))}
              required
              size={12}
            />
          </>
        )}
        {Number(payMethodCatId) === 5 && (
          <>
            <FormInputTransaction
              chargeTypeName='card_trans_charge_type'
              name='card_trans_charge'
              label='Transaction charge '
              size={12}
            />
            <FormInputItem
              name='card_trans_no'
              label='Receipt/Trans No : '
              size={12}
            />
          </>
        )}

        {Number(payMethodCatId) === 3 && (
          <>
            <FormInputItem
              name={'charge_amount'}
              label='Transaction Charge :'
              size={12}
            />

            <FormInputItem
              label='Receipt/Trans No:'
              name='trans_no'
              size={12}
            />
          </>
        )}

        {Number(payMethodCatId) !== 4 && (
          <Col span={6} xs={24} sm={24} md={24} lg={12}>
            <Form.Item label='Available Balance' required>
              <InputNumber style={{ width: '100%' }} disabled value={balance} />
            </Form.Item>
          </Col>
        )}

        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item label='Payable Amount' name='payable_amount'>
            <InputNumber style={{ width: '100%' }} disabled />
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item label='Due Amount' name='due_amount'>
            <InputNumber style={{ width: '100%' }} disabled />
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            label='Payment Amount'
            name='amount'
            rules={[
              { required: true, message: 'Payment Amount is required!' },
              {
                validator: (_, value) => {
                  if (value && due) {
                    if (value > due) {
                      return Promise.reject('Amount cannot be more than due');
                    }
                    if (balance && value > balance) {
                      return Promise.reject('Insufficient Balance');
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <InputNumber
              placeholder='Enter your payment amount'
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <DateInput label='Date' name='payment_date' size={12} />
        <TextAreaInput label='Note' name='payment_note' size={24} />
      </Row>
      <FormButton label='Create Payment' loading={isLoading} />
    </Form>
  );
};

export default AddPaymentModal;
