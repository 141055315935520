import {
  Button,
  Popconfirm,
  Space,
  Tag,
  Table,
  message,
  Row,
  Col,
  Input,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { setModal } from '../../../../../common/slices/commonSlice';
import { perProps } from '../../../../../common/types/commonTypes';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../../components/common/CommonTableHeader';
import AccommodationEditModal from '../components/AccommodationEditModal';
import ViewVendors from '../components/ViewVendors';
import {
  useDeleteTourAccommodationMutation,
  useGetAllAccommodationWithPagQuery,
} from '../endpoints/accommodationEndpoints';
import CreateTourAccommodation from '../modal/CreateTourAccommodation';
import {
  IAccommodationGetChildDataType,
  IAccommodationGetDataType,
} from '../types/accommodationTypes';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';

const Accommodations = ({ permission }: perProps) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useSelector(selectUser);

  const [
    deleteTourAccommodation,
    { isError, isSuccess, isLoading: deleteLoading },
  ] = useDeleteTourAccommodationMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Tour Accommodation Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const searchColumnProps =
    useGetSearchColumnProps<IAccommodationGetDataType>();

  const handleDelete = async (
    values: IAccommodationGetDataType,
    index: number
  ) => {
    setColumnIndex(index);
    await deleteTourAccommodation({
      ...values,
      accommodation_created_by: user?.user_id,
    });
  };
  const [editInfo, setEditInfo] = useState<IAccommodationGetDataType | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const dispatch = useAppDispatch();
  const modalOpen = (e: IAccommodationGetDataType) => {
    setEditInfo(e);
    dispatch(setModal(true));
  };
  const [vendor, setVendors] = useState<IAccommodationGetChildDataType[]>([]);

  // ========== view vendors
  const handleViewVendors = (data: IAccommodationGetChildDataType[]) => {
    showModal();
    setVendors(data);
  };

  //get all accommodation
  const {
    isLoading,
    data: tourAccommodation,
    refetch,
  } = useGetAllAccommodationWithPagQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: IAccommodationGetDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, count } };
    },
  });

  const columns: ColumnsType<IAccommodationGetDataType> = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Hotel Name',
      dataIndex: 'accommodation_hotel_name',
      key: 'accommodation_hotel_name',
      ...searchColumnProps('accommodation_hotel_name'),
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      ...searchColumnProps('country_name'),
    },
    {
      title: 'City',
      dataIndex: 'city_name',
      key: 'city_name',
      ...searchColumnProps('city_name'),
    },
    {
      title: 'Vendor',
      dataIndex: 'itinerary_vendorss',
      key: 'itinerary_vendorss',
      align: 'center',
      render: (_, record) => {
        return (
          <Button
            size='small'
            type='primary'
            onClick={() => handleViewVendors(record?.itinerary_vendor)}
          >
            View Vendors
          </Button>
        );
      },
    },
    {
      title: 'Room Type',
      dataIndex: 'rtype_name',
      key: 'rtype_name',
      ...searchColumnProps('rtype_name'),
    },
    {
      title: 'Pax',
      dataIndex: 'accommodation_pax_name',
      key: 'accommodation_pax_name',
      ...searchColumnProps('accommodation_pax_name'),
    },
    {
      title: 'Create Date',
      dataIndex: 'accommodation_create_date',
      key: 'accommodation_create_date',
      render: (_, data) => {
        return (
          <> {dayjs(data.accommodation_create_date).format('YYYY-MM-DD')} </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'accommodation_status',
      key: 'accommodation_status',
      render: (_, { accommodation_status }) => (
        <Tag
          color={accommodation_status === 0 ? 'red' : 'green'}
          key={accommodation_status}
        >
          {accommodation_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'address',
      key: 'address',
      render: (_: any, record: any, index) => (
        <Space size='small'>
          {permission?.['update:any'] && (
            <Button
              size='small'
              type='primary'
              onClick={() => modalOpen(record)}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                size='small'
                type='primary'
                danger
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const showModals = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <ViewVendors
        setIsModalOpen={setIsModalOpen}
        showModal={showModals}
        isModalOpen={isModalOpen}
        vendors={vendor}
      />
      <BreadCrumb
        arrOfOption={['Configuration', 'Accommodations']}
        refetch={refetch}
        reloaderSize='small'
      />
      {/* create accommodation */}
      {permission?.['create:any'] && <CreateTourAccommodation />}

      <CommonTableHeader
        title='Accommodations'
        modalTitle='Edit Accommodation'
        modalWidth={1000}
        element={editInfo ? <AccommodationEditModal info={editInfo} /> : <></>}
      />

      <Table
        size='small'
        bordered
        pagination={
          tourAccommodation?.count !== undefined &&
          tourAccommodation?.count < 20
            ? false
            : {
                ...pagination,
                total: tourAccommodation?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        dataSource={tourAccommodation.dataToReturn}
        columns={columns}
        scroll={{ x: true }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </>
  );
};

export default Accommodations;
