import { Button, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';

import JoditEditor from 'jodit-react';
import { usePostEditAppConfigMutation } from '../endpoint/appConfigEndpoint';
import { IGetAppConfig } from '../type/appConfig_Type';

type Props = {
  configDetails: IGetAppConfig | undefined;
};

const InvoiceExtraInfo = ({ configDetails }: Props) => {
  const [postEdit, { isLoading }] = usePostEditAppConfigMutation();

  const color = localStorage.getItem('theme') as 'defaultTheme' | 'darkTheme';

  const editor = useRef(null);
  const [content, setContent] = useState('');

  const prevNote = configDetails?.tac_invoice_footer_note;

  useEffect(() => {
    if (prevNote) {
      setContent(prevNote);
    }
  }, [prevNote]);

  const handleSubmit = (e: any) => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_invoice_footer_note: content,
    };

    postEdit(body);
  };

  const config = {
    theme: color === 'darkTheme' ? 'default' : 'dark',
    height: 400,
    style: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    toolbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#000000',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    statusbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
  };

  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 35,
      }}
    >
      <Typography.Title level={3}>Invoice Footer Info:</Typography.Title>

      <JoditEditor
        config={config}
        ref={editor}
        value={content}
        onBlur={(newContent) => setContent(newContent)}
      />

      <Button
        style={{ float: 'right', marginTop: 20 }}
        onClick={handleSubmit}
        type='primary'
        loading={isLoading}
      >
        Save
      </Button>
    </div>
  );
};

export default InvoiceExtraInfo;
