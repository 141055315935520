import { Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllCompaniesWithPagQuery } from '../Endpoints/companiesEndpoints';
import CompanyAddModal from '../modals/AddNewCompanyModal';
import EditCompanyModal from '../modals/EditCompanyModal';
import { CompanyDataType } from '../types/companyTypes';
import { companiesColumns } from '../utils/utils';

const Companies = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<CompanyDataType | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    data: companies,
    refetch,
  } = useGetAllCompaniesWithPagQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: CompanyDataType[] = [];
      const companiesNameList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          companiesNameList.push(element.company_name);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, companiesNameList, count } };
    },
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Companies']}
        refetch={refetch}
        reloaderSize='small'
      />
      <CommonTableHeader
        title='Non-Invoice Companies'
        modalTitle={editInfo ? 'Edit Company' : 'Add New Company'}
        button_text='Add New Company'
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <EditCompanyModal
              companiesNameList={companies.companiesNameList}
              info={editInfo}
              setNull={setEditInfo}
            />
          ) : (
            <CompanyAddModal companiesNameList={companies.companiesNameList} />
          )
        }
        setNull={setEditInfo}
      />
      <div className='table'>
        <Table
          size='small'
          bordered
          pagination={
            companies?.count !== undefined && companies?.count < 20
              ? false
              : {
                  ...pagination,
                  total: companies?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
          }
          dataSource={companies.dataToReturn}
          columns={companiesColumns({
            setEditInfo,
            showModal,
            permission,
            pagination,
          })}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </div>
    </div>
  );
};

export default Companies;
