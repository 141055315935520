import { Button, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useState } from 'react';
import InvoiceOtherAddMoney from '../../../components/common/Invoice/InvoiceMoneyReceipt';

type Props = {
  client_price: number;
  billingForm: FormInstance<any>;
  disabled?: boolean;
};

export default function InvoiceMoneyReceiptModal({
  billingForm,
  client_price,
  disabled,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        type='dashed'
        disabled={disabled}
        style={{ marginTop: '10px' }}
        onClick={showModal}
      >
        Add Money Receipt
      </Button>
      <Modal
        title='Add Money Reciept'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
      >
        <InvoiceOtherAddMoney
          netTotal={client_price}
          form={billingForm}
          serviceChargeFieldName={['invoice_service_charge']}
        />
      </Modal>
    </>
  );
}
