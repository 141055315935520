import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { CLIENT_LEDGER } from '../../../Reports/Api/constants';
import {
  IClientInvoicesInfo,
  IGetAllAdvanceReturn,
  IGetInvoiceDueMoneyReceipt,
  IMoneyReceiptAdvanceReturn,
  IMoneyReceiptDetails,
  IMoneyReceiptPostType,
  IMoneyReceiptSingleDataType,
  IMoneyReceiptViewGetAllType,
  IPostInvoiceMoneyReceipt,
} from '../../Types/MoneyReceiptTypes';

export const MONEY_RECEIPT_TAG = 'Money_receipt';

export const moneyReceiptEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getClientInvoicesInfo: build.query<
      HTTPResponse<IClientInvoicesInfo>,
      { id: string; receipt_payment_to: 'INVOICE' | 'TICKET' | 'OVERALL' }
    >({
      query: ({ id, receipt_payment_to }) => ({
        url: `/money-receipt/${receipt_payment_to}/byclient/${id}`,
      }),
      providesTags: () => [{ type: MONEY_RECEIPT_TAG }],
    }),

    getClientInvoicesInfoEdit: build.query<
      HTTPResponse<IClientInvoicesInfo>,
      { id: string; receipt_payment_to: 'INVOICE' | 'TICKET' | 'OVERALL' }
    >({
      query: ({ id, receipt_payment_to }) => ({
        url: `/money-receipt/${receipt_payment_to}/byclient/for-edit/${id}`,
      }),
      providesTags: () => [{ type: MONEY_RECEIPT_TAG }],
    }),

    getAllMoneyReceipts: build.query<
      HTTPResponse<IMoneyReceiptViewGetAllType[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/money-receipt/all?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [{ type: MONEY_RECEIPT_TAG }],
    }),

    getAllTrashedMoneyReceipts: build.query<
      HTTPResponse<IMoneyReceiptViewGetAllType[]>,
      void
    >({
      query: () => ({
        url: `/money-receipt/all/trash`,
      }),
      providesTags: () => [{ type: MONEY_RECEIPT_TAG }],
    }),

    getForEdit: build.query<
      HTTPResponse<IMoneyReceiptPostType>,
      { receipt_id: string }
    >({
      query: ({ receipt_id }) => ({
        url: `/money-receipt/for-edit/${receipt_id}`,
      }),
      providesTags: () => [{ type: MONEY_RECEIPT_TAG }],
    }),

    deleteMoneyReturn: build.mutation<
      HTTPResponse<void>,
      { id: number; userId: number }
    >({
      query: ({ id, userId }) => ({
        url: `/money-receipt/delete/${id}`,
        body: { receipt_deleted_by: userId },
        method: 'DELETE',
      }),
      // onQueryStarted: async (arg, { queryFulfilled }) => {
      //   await assyncWrapper(async () => {
      //     await queryFulfilled;
      //     toasterNotification('success', 'Money Receipt Deleted Successfully');
      //   });
      // },
      invalidatesTags: () => [
        { type: MONEY_RECEIPT_TAG },
        CLIENT_LEDGER,
        'invoices',
      ],
    }),

    deleteAgentMoneyReceipt: build.mutation<
      HTTPResponse<void>,
      { id: number; body: { deleted_by: number } }
    >({
      query: ({ id, body }) => ({
        url: `/money-receipt/agent-money-receipt/${id}`,
        body: body,
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        { type: MONEY_RECEIPT_TAG },
        CLIENT_LEDGER,
        'invoices',
        { type: 'Money_receipt_advance' },
        { type: 'AGENT_RECEIPT', id: 'Agent_Money_receipt' },
      ],
    }),

    // get single money receipt
    getSingleMoneyReceipt: build.query<
      HTTPResponse<IMoneyReceiptSingleDataType>,
      number
    >({
      query: (id) => ({ url: `/money-receipt/view/${id}` }),
      providesTags: [{ type: MONEY_RECEIPT_TAG }],
    }),

    postMoneyReceipt: build.mutation<
      HTTPResponse<any>,
      /* IMoneyReceiptPostType */ FormData
    >({
      query: (body) => ({
        url: '/money-receipt/post',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: MONEY_RECEIPT_TAG },
        { type: 'notification' },
        CLIENT_LEDGER,
      ],
    }),

    editMoneyReceipt: build.mutation<
      HTTPResponse<void>,
      { body: FormData; id: string }
    >({
      query: ({ body, id }) => ({
        url: `/money-receipt/edit/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: MONEY_RECEIPT_TAG }, CLIENT_LEDGER],
    }),

    // =================== @ ADVANCE RETRURN @ ===========================
    postAdvanceReturnMR: build.mutation<
      HTTPResponse<void>,
      IMoneyReceiptAdvanceReturn
    >({
      query: (body) => ({
        url: '/money-receipt/advance/post',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Money_receipt_advance' }, CLIENT_LEDGER],
    }),

    editAdvanceReturnMR: build.mutation<
      HTTPResponse<void>,
      { body: IMoneyReceiptAdvanceReturn; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/money-receipt/advance/edit/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Money_receipt_advance' }, CLIENT_LEDGER],
    }),

    getAllAdvanceReturnMR: build.query<
      HTTPResponse<IGetAllAdvanceReturn[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/money-receipt/advance/all?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [{ type: 'Money_receipt_advance' }],
    }),

    getAdvrDataForEdit: build.query<
      HTTPResponse<IMoneyReceiptAdvanceReturn>,
      number
    >({
      query: (id) => ({
        url: `/money-receipt/advance/for-edit/${id}`,
      }),
      providesTags: () => [{ type: 'Money_receipt_advance' }],
    }),

    deleteAdvr: build.mutation<
      HTTPResponse<void>,
      { id: number; userId: number }
    >({
      query: ({ id, userId }) => ({
        url: `/money-receipt/advance/delete/${id}`,
        body: { advr_deleted_by: userId },
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Money_receipt_advance' }, CLIENT_LEDGER],
    }),

    //get money receipt invoiceDueById
    getMoneyReceiptInvocieDueById: build.query<
      HTTPResponse<IGetInvoiceDueMoneyReceipt>,
      number
    >({
      query: (id) => ({ url: `/money-receipt/due-invoice/${id}` }),
      providesTags: [{ type: MONEY_RECEIPT_TAG, id: 'invalidate_when_add' }],
    }),

    //post invoice Money Receipt
    postInvoiceMoneyReceipt: build.mutation<
      HTTPResponse<void>,
      { data: IPostInvoiceMoneyReceipt }
    >({
      query: ({ data }) => ({
        url: `/money-receipt/addInvoiceMoneyReceipt/${data.invoice_id}`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [
        MONEY_RECEIPT_TAG,
        'notification',
        'invoices_from_money_receipt',
        'NonComissionInvalidateFromPayroll',
        'ReIssueAirTicket',
        'invoices',
        'DUE_INVOICE_NOTIFICATION',
        { type: 'Clients', id: 'CLIENT' },
        CLIENT_LEDGER,
      ],
    }),

    getMoneyReceiptDetails: build.query<
      HTTPResponse<IMoneyReceiptDetails[]>,
      string
    >({
      query: (id) => ({ url: `/money-receipt/view-details/${id}` }),
      providesTags: [{ type: MONEY_RECEIPT_TAG }],
    }),
  }),
});

export const {
  useLazyGetClientInvoicesInfoQuery,
  useGetClientInvoicesInfoQuery,
  useGetClientInvoicesInfoEditQuery,
  usePostMoneyReceiptMutation,
  useGetAllMoneyReceiptsQuery,
  useLazyGetAllMoneyReceiptsQuery,
  useLazyGetAllTrashedMoneyReceiptsQuery,
  useGetSingleMoneyReceiptQuery,
  useLazyGetForEditQuery,
  useEditMoneyReceiptMutation,
  usePostAdvanceReturnMRMutation,
  useGetAllAdvanceReturnMRQuery,
  useLazyGetAllAdvanceReturnMRQuery,
  useGetAdvrDataForEditQuery,
  useLazyGetAdvrDataForEditQuery,
  useEditAdvanceReturnMRMutation,
  useDeleteAdvrMutation,
  useDeleteMoneyReturnMutation,
  useLazyGetMoneyReceiptInvocieDueByIdQuery,
  usePostInvoiceMoneyReceiptMutation,
  useDeleteAgentMoneyReceiptMutation,
  useGetMoneyReceiptDetailsQuery,
} = moneyReceiptEndpoints;
