import { message } from 'antd';
import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import {
  IAccountIncentiveIncome,
  IAccountIncentiveIncomeDelResBody,
  IAccountIncentiveIncomeEditFormData,
  IAccountIncentiveIncomeFormData,
  IViewAccountIncentiveIncome,
} from '../../../AccountsTypes/AccountsTypes';
import { INCENTIVE_INCOME } from '../../../Api/Constants/constants';

export const incentiveIncomeEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all incentive_income
    getAllIncentiveIncome: build.query<
      HTTPResponse<IAccountIncentiveIncome[]>,
      { query: string }
    >({
      query: ({ query }) => ({
        url: `/accounts/incentive-income${query ? query : ''}`,
      }),
      providesTags: () => [{ type: 'Incentive_Income', id: INCENTIVE_INCOME }],
    }),
    // get incentive_income by id
    getViewIncentiveIncome: build.query<
      HTTPResponse<IViewAccountIncentiveIncome[]>,
      number
    >({
      query: (body) => ({ url: `/accounts/incentive-income/${body}` }),

    }),
    // get all trashed incentive_income
    getAllTrashedIncentiveIncome: build.query<
      HTTPResponse<IAccountIncentiveIncome[]>,
      void
    >({
      query: () => ({ url: `/accounts/incentive-income/trash` }),
      providesTags: () => [{ type: 'Incentive_Income', id: INCENTIVE_INCOME }],
    }),

    //create incentive income
    createIncentiveIncome: build.mutation<
      HTTPResponse<number>,
      IAccountIncentiveIncomeFormData
    >({
      query: (body) => ({
        url: `/accounts/incentive-income`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Added Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),

    //edit incentive income
    editIncentiveIncome: build.mutation<
      void,
      IAccountIncentiveIncomeEditFormData
    >({
      query: (body) => ({
        url: `/accounts/incentive-income/${body.incentive_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),

    //delete incentive income
    deleteIncentiveIncome: build.mutation<
      void,
      IAccountIncentiveIncomeDelResBody
    >({
      query: (body) => ({
        url: `/accounts/incentive-income/${body.incentive_id}`,
        method: 'DELETE',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),

    //restore incentive income
    restoreIncentiveIncome: build.mutation<
      void,
      IAccountIncentiveIncomeDelResBody
    >({
      query: (body) => ({
        url: `/accounts/incentive-income/restore/${body.incentive_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Restored Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),
  }),
});

export const {
  useGetAllIncentiveIncomeQuery,
  useLazyGetAllIncentiveIncomeQuery,
  useLazyGetAllTrashedIncentiveIncomeQuery,
  useCreateIncentiveIncomeMutation,
  useEditIncentiveIncomeMutation,
  useDeleteIncentiveIncomeMutation,
  useRestoreIncentiveIncomeMutation,
  useGetViewIncentiveIncomeQuery,
  useLazyGetViewIncentiveIncomeQuery,
} = incentiveIncomeEndpoints;
