import { Form, message } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import { FormatDateV1 } from '../../../common/utils/common.utils';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceHeaderForm from '../../../components/common/Invoice/InvoiceHeaderForm';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import NewBillingInfoForm from '../Components/NewBillingInfoForm';
import { IExistingClients } from '../Components/PreviousTicketInfo';
import { IInvoice_info_Existing } from '../Type/InoviceReissue.interface';
import { useGetExistingReIssueInvoiceQuery } from '../api/endpoints/reIssueAirTicketInvoiceEndpoints';
import {
  useEditExistingClientMutation,
  useLazyGetExistingClientsQuery,
} from '../api/endpoints/reIssueExistingAirTicketEndpoints';

type Props = {};

const EditExistingReissue = (props: Props) => {
  const id = Number(useParams().id);
  const {
    data,
    isError: fetchingErr,
    isSuccess: getValueSuccess,
    isLoading: getValueLoading,
    refetch: getValueRefetch,
  } = useGetExistingReIssueInvoiceQuery(id);
  const invoice_info = data?.data;

  useEffect(() => {
    getValueRefetch();
  }, [id]);

  const [form] = useForm();

  if (fetchingErr) {
    return <>You have an error with fetching data!</>;
  }

  useEffect(() => {
    form.setFieldsValue({
      airticket_ticket_no: invoice_info?.airticket_ticket_no,
      invoice_combclient_id: invoice_info?.invoice_combclient_id,
      airticket_comvendor: invoice_info?.airticket_comvendor,
      invoice_sales_man_id: invoice_info?.invoice_sales_man_id,
      invoice_sales_date: invoice_info?.invoice_sales_date
        ? dayjs(invoice_info?.invoice_sales_date)
        : undefined,
      invoice_due_date: invoice_info?.invoice_due_date
        ? dayjs(invoice_info?.invoice_due_date)
        : undefined,
      airticket_profit: invoice_info?.airticket_profit,
      invoice_no: invoice_info?.invoice_no,
      airticket_journey_date: invoice_info?.airticket_journey_date
        ? dayjs(invoice_info?.airticket_journey_date)
        : undefined,
      airticket_return_date: invoice_info?.airticket_return_date
        ? dayjs(invoice_info?.airticket_return_date)
        : undefined,
      airticket_vendor_charge: invoice_info?.airticket_vendor_charge,
      airticket_client_charge: invoice_info?.airticket_client_charge,
      airticket_service_charge: invoice_info?.airticket_service_charge,
      invoice_note: invoice_info?.invoice_note,
    });

    if (invoice_info?.invoice_combclient_id) {
      existingClientData(invoice_info?.invoice_combclient_id as string);
    }
  }, [getValueSuccess]);

  // @Set form value
  const airticket_client_charge = useWatch(['airticket_client_charge'], form);
  const airticket_vendor_charge = useWatch(['airticket_vendor_charge'], form);
  const airticket_service_charge = useWatch(['airticket_service_charge'], form);
  useEffect(() => {
    form.setFieldsValue({
      airticket_profit:
        Number(airticket_client_charge || 0) -
        Number(airticket_vendor_charge || 0) +
        Number(airticket_service_charge || 0),
    });
  }, [
    airticket_client_charge,
    airticket_vendor_charge,
    airticket_service_charge,
  ]);

  // ================ existing clients data ==================
  const [existingClientData, { data: exClientsData, isLoading, isSuccess }] =
    useLazyGetExistingClientsQuery();
  const existingClient = exClientsData?.data;
  const existingTicketNo: { id: number; title: string }[] = [];
  if (isSuccess) {
    existingClient?.forEach((item) => {
      const data = {
        id: item?.airticket_id,
        title: item.airticket_ticket_no,
      };
      if (item?.airticket_id) {
        existingTicketNo.push(data);
      }
    });
  }

  // ======================= get form value ===================
  const [selectedClient, setSelectedClient] = useState<IExistingClients[]>([]);

  const handleSelectTicket = () => {
    const ticketNo = form.getFieldValue('ticket_no');
    const selectedTicket = existingClient?.find(
      (item) => item?.airticket_id === ticketNo
    );
    if (selectedTicket) {
      setSelectedClient([selectedTicket]);
    }
  };

  const [
    editExistingClient,
    {
      isLoading: postLoading,
      isError: postError,
      isSuccess: postSuccess,
      error,
    },
  ] = useEditExistingClientMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  const user = useSelector(selectUser);

  // @Form submit
  const onFinish = (values: any) => {
    const invoice_info_temp: IInvoice_info_Existing = {
      airticket_ticket_no: values?.airticket_ticket_no,
      invoice_combclient_id: values?.invoice_combclient_id,
      invoice_sales_man_id: values?.invoice_sales_man_id,
      invoice_sales_date: values?.invoice_sales_date,
      invoice_due_date: values?.invoice_due_date,
      invoice_note: values?.invoice_note || undefined,
      invoice_no: values?.invoice_no,
      invoice_created_by: user?.user_id as number,

      airticket_profit: values?.airticket_profit,
      airticket_comvendor: values?.airticket_comvendor,
      airticket_journey_date: FormatDateV1(values?.airticket_journey_date),
      airticket_return_date: FormatDateV1(values?.airticket_return_date),
      airticket_vendor_charge: values?.airticket_vendor_charge,
      airticket_client_charge: values?.airticket_client_charge,
      airticket_service_charge: values?.airticket_service_charge,
      airticket_existing_invoiceid: selectedClient[0]?.airticket_id,
    };

    const body = {
      invoice_info: invoice_info_temp,
      id: id,
    };

    if (id) {
      const prevNetTotal = invoice_info?.airticket_client_charge || 0;
      const currentNetTotal = invoice_info_temp?.airticket_client_charge;
      if (prevNetTotal >= currentNetTotal) {
        editExistingClient(body);
      } else {
        checkCreditLimit({
          amount: currentNetTotal - prevNetTotal,
          combClient: invoice_info_temp.invoice_combclient_id,
        }).then((res: any) => {
          if (res?.data?.data === 1) {
            editExistingClient(body);
          } else {
            message.error(res?.data?.data);
          }
        });
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Submit failed!');
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (postSuccess) {
      toasterNotification(
        'success',
        'Invoice airticket existing client updated successfully!'
      );
      navigate(`/reissueairticket/details/${id}`);
    }
    if (postError) {
      toasterNotification('error', 'Thare was an error');
    }
  }, [postLoading]);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice (Air Ticket)', 'Existing']} />

      <Form
        name='basic'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
        layout='vertical'
      >
        <InvoiceHeaderForm
          isEdit
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
          form={form}
        />

        <NewBillingInfoForm form={form} />

        <FormButton label='Update' />
      </Form>
    </ClientsStyle>
  );
};
export default EditExistingReissue;
const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .topLeftButton {
    margin-right: 0.5rem;
    margin-bottom: auto;
  }
`;
