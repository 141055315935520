import { ColumnType } from 'antd/es/table';
import React from 'react';
import {
  IPersialRefundBodyType,
  IPersialRefundVendorInfo,
} from '../../../RefundTypes/RefundTypes';

export const PartialRefundVendorUtils =
  (): ColumnType<IPersialRefundVendorInfo>[] => {
    return [
      {
        title: 'Refund Charge',
        key: 'vprfnd_charge_amount',
        dataIndex: 'vprfnd_charge_amount',
        render: (vprfnd_charge_amount: string) => {
          return <div>{Number(vprfnd_charge_amount) || 0}</div>;
        },
        align: 'center',
      },
      {
        title: 'Refund Amount',
        key: 'vprfnd_total_amount',
        dataIndex: 'vprfnd_total_amount',
        render: (vprfnd_total_amount: string) => {
          return <div>{Number(vprfnd_total_amount) || 0}</div>;
        },
        align: 'center',
      },
    ];
  };
