import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { InvoiceFooter } from '../../../../common/Invoice/InvoiceFooter';
import { Fixed2, pagination } from '../../../../common/utils/common.utils';
import {
  a4sizeStyle,
  invoiceViewStyle,
} from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { invoiceViewTitleStyle } from '../../../Money_Receipt/Components/MoneyReceiptDesign';
import { useGetSingleAirticketRefundQuery } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import ViewRefundHeader from '../../Components/ViewRefundHeader';
import { AirTicketRefundClientSingleViewUtils } from '../../Utils/AirTicket Utils/AirTicketRefundSingleUtils/AirTicketRefundClientSingleViewUtils';
import { AirTicketRefundVendorSingleViewUtils } from '../../Utils/AirTicket Utils/AirTicketRefundSingleUtils/AirTicketRefundVendorSingleViewUtils';

const AirticketRefundView = () => {
  const { id } = useParams();

  const { data: singleAirticketRefund, isLoading } =
    useGetSingleAirticketRefundQuery(Number(id));

  const airTicketInvoiceData = singleAirticketRefund?.data;

  const componentRefClient = useRef(null);
  const refs: React.MutableRefObject<HTMLDivElement[]> = useRef([]);
  const [index, setIndex] = useState<number>(0);

  const [date, setDate] = useState<string>();

  let client_total_refund_price = 0;
  let client_total_refund_charge = 0;
  let vendor_total_refund_price = 0;
  let vendor_total_refund_charge = 0;

  singleAirticketRefund?.data &&
    singleAirticketRefund?.data.refund_info.map((item: any) => {
      client_total_refund_price =
        client_total_refund_price + Number(item.crefund_total_amount);

      client_total_refund_charge =
        client_total_refund_charge +
        Number(item.rfitem_ticket_client_refund_charge);

      vendor_total_refund_price += Number(item.vrefund_total_amount);

      vendor_total_refund_charge =
        vendor_total_refund_charge + Number(item.vrefund_charge_amount);
    });

  useEffect(() => {
    if (singleAirticketRefund?.data) {
      setDate(
        dayjs(singleAirticketRefund?.data.atrefund_date).format('DD MMM YYYY')
      );
    }
  }, [singleAirticketRefund?.data]);

  const handleClientPrint = useReactToPrint({
    content: () => componentRefClient.current,
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      '@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }',
  });
  const handleVendorPrint = useReactToPrint({
    content: () => refs.current[index],
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      '@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }',
  });

  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'View AirTicket Refund Voucher']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/refund/history/airticket'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Refund List
          </Button>
        </Link>
      </Space>

      {/* CLIENT COPY */}

      <div style={invoiceViewStyle}>
        <div>
          <div style={flexBetween}>
            <h2 style={{ color: '#18b4e9' }}>CLIENT COPY</h2>

            <Button type='primary' onClick={handleClientPrint}>
              <PrinterOutlined />
              Print
            </Button>
          </div>

          <ConfigProvider
            theme={{
              algorithm: theme.defaultAlgorithm,
            }}
          >
            <Card style={a4sizeStyle}>
              <div ref={componentRefClient}>
                <ViewRefundHeader refund={singleAirticketRefund?.data} />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography.Title level={5} style={invoiceViewTitleStyle}>
                    Refund Voucher
                  </Typography.Title>
                  Client Copy
                </div>

                <Row justify={'space-between'} align='middle'>
                  <Col
                    style={{
                      color: '#fff',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Text className='changeFontBlock'>
                      <b>Client Name :</b>{' '}
                      {singleAirticketRefund?.data?.client_name}
                    </Typography.Text>

                    {singleAirticketRefund?.data?.client_mobile ? (
                      <Typography.Text className='changeFontBlock'>
                        <b>Mobile No :</b>{' '}
                        {singleAirticketRefund?.data?.client_mobile || ''}
                      </Typography.Text>
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Col
                    style={{
                      color: '#fff',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Title
                      style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                      level={5}
                    >
                      Refund Information:
                    </Typography.Title>

                    <Typography.Text className='changeFontBlock'>
                      <b>Voucher No :</b>{' '}
                      {singleAirticketRefund?.data?.atrefund_vouchar_number}
                    </Typography.Text>

                    <Typography.Text className='changeFontBlock'>
                      <b>Date :</b>{' '}
                      {dayjs(singleAirticketRefund?.data?.atrefund_date).format(
                        'DD MMM YYYY'
                      )}
                    </Typography.Text>
                  </Col>
                </Row>

                <Table
                  size='small'
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  bordered
                  columns={AirTicketRefundClientSingleViewUtils()}
                  dataSource={
                    airTicketInvoiceData ? [airTicketInvoiceData] : []
                  }
                  style={{ marginTop: '10%' }}
                  pagination={false}
                  rowKey={(record: any) => record.rfitem_ticket_no}
                />

                <div
                  style={{
                    margin: '30px 0',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      fontFamily: 'revert',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    <NumToWord
                      number={Fixed2(
                        airTicketInvoiceData?.crefund_return_amount
                      )}
                    />
                  </div>
                </div>

                <InvoiceFooter />
              </div>
            </Card>
          </ConfigProvider>
        </div>
      </div>

      {/* VENDOR COPY */}
      {singleAirticketRefund?.data &&
        singleAirticketRefund?.data.refund_info.map(
          (item: any, index: number) => {
            let vrefund_amount =
              Number(item.vrefund_total_amount) -
              Number(item.vrefund_charge_amount);

            return (
              <div style={invoiceViewStyle}>
                <div>
                  <div style={flexBetween}>
                    <h2 style={{ color: '#18b4e9' }}>VENDOR COPY</h2>
                    <Button
                      type='primary'
                      onClick={() => {
                        setIndex(index);
                        handleVendorPrint();
                      }}
                    >
                      <PrinterOutlined />
                      Print
                    </Button>
                  </div>

                  <ConfigProvider
                    key={index}
                    theme={{
                      algorithm: theme.defaultAlgorithm,
                    }}
                  >
                    <Card style={a4sizeStyle} key={index}>
                      <div
                        ref={(e: HTMLDivElement) => {
                          refs.current[index] = e;
                        }}
                      >
                        <ViewRefundHeader
                          refund={singleAirticketRefund?.data}
                        />

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyItems: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography.Title
                            level={5}
                            style={invoiceViewTitleStyle}
                          >
                            Refund Voucher
                          </Typography.Title>
                          Vendor Copy
                        </div>

                        <Row justify={'space-between'} align='middle'>
                          <Col
                            style={{
                              color: '#fff',
                              fontFamily: "'Source Sans Pro', sans-serif",
                            }}
                          >
                            <Typography.Text
                              style={{
                                display: 'block',
                                fontSize: '13px',
                                fontFamily: "'Source Sans Pro', sans-serif",
                              }}
                            >
                              <b>Vendor Name :</b> {item.comb_vendor}
                            </Typography.Text>
                          </Col>

                          <Col
                            style={{
                              color: '#fff',
                              fontFamily: "'Source Sans Pro', sans-serif",
                            }}
                          >
                            <Typography.Title
                              style={{
                                fontFamily: "'Source Sans Pro', sans-serif",
                              }}
                              level={5}
                            >
                              Refund Information:
                            </Typography.Title>

                            <Typography.Text
                              style={{
                                display: 'block',
                                fontSize: '13px',
                                fontFamily: "'Source Sans Pro', sans-serif",
                              }}
                            >
                              <b>Voucher No :</b>{' '}
                              {
                                singleAirticketRefund?.data
                                  ?.atrefund_vouchar_number
                              }
                            </Typography.Text>

                            <Typography.Text
                              style={{
                                display: 'block',
                                fontSize: '13px',
                                fontFamily: "'Source Sans Pro', sans-serif",
                              }}
                            >
                              <b>Date :</b> {date}
                            </Typography.Text>
                          </Col>
                        </Row>

                        <Table
                          size='small'
                          className='invoiceBillingTable'
                          rowClassName={'invoiceBillingTd'}
                          bordered
                          columns={AirTicketRefundVendorSingleViewUtils()}
                          dataSource={
                            singleAirticketRefund?.data && [
                              singleAirticketRefund?.data.refund_info[index],
                            ]
                          }
                          style={{ marginTop: '10%' }}
                          pagination={pagination(
                            singleAirticketRefund?.data &&
                              [singleAirticketRefund?.data.refund_info[index]]
                                .length
                          )}
                          rowKey={(record) => record.rfitem_ticket_no}
                        />

                        <div
                          style={{
                            margin: '30px 0',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              textAlign: 'right',
                              fontFamily: 'revert',
                              fontWeight: 'bold',
                              fontSize: 16,
                            }}
                          >
                            <NumToWord number={Fixed2(vrefund_amount)} />
                          </div>
                        </div>

                        <InvoiceFooter />
                      </div>
                    </Card>
                  </ConfigProvider>
                </div>
              </div>
            );
          }
        )}

      {isLoading && loadingIndicator}
    </div>
  );
};

export default AirticketRefundView;

export const flexBetween: React.CSSProperties = {
  width: '8.27in',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  padding: 5,
  borderRadius: 10,
};
