import { Col, ConfigProvider, theme, Timeline } from 'antd';

import dayjs from 'dayjs';
import LoadingIndicator from '../../spinner/LoadingIndicator';
import { invoiceViewStyle } from './CommonViewInvoice';
import { useGetInvoiceActivityLogQuery } from '../../../../modules/Invoice_Air_Ticket/api/endpoints/airticketInvoiceEndpoints';
type Props = {
  id: number;
  viewInvoiceAcivityRef: React.RefObject<HTMLDivElement>;
};

function InvoiceActivityLogs({ id, viewInvoiceAcivityRef }: Props) {
  const { data, isLoading } = useGetInvoiceActivityLogQuery(id);

  const activityLogData = data?.data ?? [];

  return (
    <div style={invoiceViewStyle}>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        {isLoading && <LoadingIndicator />}
        <div
          style={{
            width: '8.27in',
            height: '11.69in',
            background: '#fff',
            paddingTop: '10vh',
          }}
          ref={viewInvoiceAcivityRef}
        >
          <Col style={{ maxWidth: '8in' }}>
            <Timeline mode='alternate'>
              {activityLogData?.map((item) => {
                return (
                  <Timeline.Item
                    style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                    key={item.invoice_no}
                    label={dayjs(item.history_create_date).format(
                      'MMMM D, YYYY h:mm A'
                    )}
                  >
                    {`${item.invoicelog_content} by ${item.user_full_name}`}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Col>
        </div>
      </ConfigProvider>
    </div>
  );
}

export default InvoiceActivityLogs;
