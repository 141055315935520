import { Button, InputRef, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../components/common/spinner/LoadingIndicator';
import { ISearchType } from '../../modules/Dashboard/interfaces/dashboard.interfaces';

type Props = {
  searchResult: ISearchType[] | undefined;
  isLoading: boolean;
  handleOk: () => void;
};

interface resultType {
  invoice_id: number;
  invoice_no: string;
  invcat_title: string;
  invoice_category_id: number;
  client_name: string;
  client_mobile: string;
  airticket_ticket_no: string;
  passport_name: string;
  // invoice_net_total: number;
}

const SearchInvoices = ({ isLoading, searchResult, handleOk }: Props) => {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const columns: ColumnsType<ISearchType> = [
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Invoice Category',
      dataIndex: 'invcat_title',
      key: 'invcat_title',
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },

    {
      title: 'Sales Price',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
    },

    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },

    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Passport Name',
      dataIndex: 'pax_passname',
      key: 'pax_passname',
    },
    {
      title: 'Passport No',
      dataIndex: 'passport_no',
      key: 'passport_no',
    },

    {
      title: 'Action',
      align: 'end',
      render: (_: any, data: ISearchType) => {
        return (
          <div>
            {
              <Link
                onClick={() => handleOk()}
                to={
                  findInvoiceRoute(Number(data.invoice_category_id)) +
                  '/' +
                  'details' +
                  '/' +
                  data.invoice_id
                }
              >
                <Button size='small' type='primary'>
                  Details
                </Button>
              </Link>
            }
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {/* {isLoading && <LoadingIndicator />} */}

      <Table
        style={{ marginTop: '20px' }}
        columns={columns}
        // rowKey={(e) => e?.invoice_id}
        dataSource={searchResult}
        bordered
        pagination={false}
        loading={isLoading}
        size='small'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
      />
    </div>
  );
};

export default SearchInvoices;

export const findInvoiceRoute = (
  invoiceCategoryId: number
): string | undefined => {
  const idToRouteMap: { [key: number]: string } = {
    1: 'airticket',
    2: 'noncomairticket',
    3: 'reissueairticket',
    4: 'invoiceTour',
    5: 'invoiceother',
    10: 'visa',
    26: 'invoiceumrah',
    30: 'hajjpreregistration',
    31: 'invoicehajj',
  };

  return idToRouteMap[invoiceCategoryId];
};
