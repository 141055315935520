import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { CHEQUE_PAYMENT } from '../../../Cheque_Management/api/Constants/constants';
import {
  IAirTicketByID,
  IAirTicketform_1,
  IAirTicketInfoByNo,
  IAirticketLogsType,
  IAirTicketRefundDataType,
  IAirTicketRefundForm,
  IAirtTicketRefundSingleDataType,
  IRefundDeleteRestoreBody,
} from '../../RefundTypes/RefundTypes';
import { AIRTICKET_REFUND, Refund_LOGS } from '../Constants/Contstants';

export const airTicketRefundEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all tickets by client ID
    getAllTicketsByID: build.query<
      HTTPResponse<IAirTicketByID[]>,
      number | string
    >({
      query: (id) => ({ url: `refund/ticket-no/${id}` }),
      providesTags: [{ type: 'AirTicketRefund', id: AIRTICKET_REFUND }],
    }),

    //search ticket info by ticket no
    searchTicketNo: build.query<
      HTTPResponse<IAirTicketInfoByNo[]>,
      IAirTicketform_1
    >({
      query: (body) => ({
        url: '/refund/ticket-infos',
        method: 'POST',
        body,
      }),

      providesTags: () => [{ type: 'AirTicketRefund', id: AIRTICKET_REFUND }],
    }),

    // get all airTicket Refunds
    getAllAirTicketRefund: build.query<
      HTTPResponse<IAirTicketRefundDataType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `/refund/air-ticket-refund?${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
      }),
      providesTags: [{ type: 'AirTicketRefund', id: AIRTICKET_REFUND }],
    }),

    // get single airticket refund by id
    getSingleAirticketRefund: build.query<
      HTTPResponse<IAirtTicketRefundSingleDataType>,
      number
    >({
      query: (id) => ({ url: `/refund/air-ticket-refund/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'AirTicketRefund', id: id } as const,
      ],
    }),

    //get airticket refund logs
    getAirTicketLogs: build.query<HTTPResponse<IAirticketLogsType[]>, number>({
      query: (id) => ({ url: `/refund/air-ticket/logs/${id}` }),
      providesTags: [{ type: 'AirTicketRefund', id: Refund_LOGS }],
    }),

    // get all airTicket trashed Refunds
    getAllTrashedAirTicketRefund: build.query<
      HTTPResponse<IAirTicketRefundDataType[]>,
      void
    >({
      query: () => ({ url: `/refund/air-ticket/trashed` }),
      providesTags: [{ type: 'AirTicketRefund', id: AIRTICKET_REFUND }],
    }),

    //create airticket refund
    createAirTicketInfo: build.mutation<void, IAirTicketRefundForm>({
      query: (body) => ({
        url: '/refund/air-ticket-refund',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification(
            'success',
            'Air-Ticket Refund Created Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'AirTicketRefund', id: AIRTICKET_REFUND },
        { type: 'AirTicketRefund', id: Refund_LOGS },
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Vendors', id: 'VENDOR' },
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
      ],
    }),

    //delete airticket refund
    deleteAirticketRefund: build.mutation<void, IRefundDeleteRestoreBody>({
      query: (body) => ({
        url: `/refund/air-ticket-refund/${body.refund_id}`,
        method: 'DELETE',
        body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification(
            'success',
            'AirTicket Refund Deleted Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'AirTicketRefund', id: AIRTICKET_REFUND },
        { type: 'AirTicketRefund', id: Refund_LOGS },
        { type: 'Clients', id: 'CLIENT' },
        { type: 'Vendors', id: 'VENDOR' },
      ],
    }),
  }),
});

export const {
  useGetAllTicketsByIDQuery,
  useLazyGetAllTicketsByIDQuery,
  useGetSingleAirticketRefundQuery,
  useLazyGetAllTrashedAirTicketRefundQuery,
  useGetAllAirTicketRefundQuery,
  useLazyGetAllAirTicketRefundQuery,
  useGetAirTicketLogsQuery,
  useLazySearchTicketNoQuery,
  useCreateAirTicketInfoMutation,
  useDeleteAirticketRefundMutation,
} = airTicketRefundEndpoints;
