import { Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllGroupsWithPaginationQuery } from '../endpoints/groupEndpoints';
import AddNewGroup from '../modals/AddNewGroup';
import EditGroupModal from '../modals/EditGroup';
import { GroupDataType } from '../types/GroupsTypes';
import { groupCollumns } from '../utils/utils';

const Groups = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<GroupDataType | null>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    data: groups,
    isLoading,
    refetch,
  } = useGetAllGroupsWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: GroupDataType[] = [];
      const groupNameList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          groupNameList.push(element.group_name);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, groupNameList, count } };
    },
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Groups']}
        refetch={refetch}
        reloaderSize='small'
      />

      <CommonTableHeader
        width
        align
        modalTitle={editInfo ? 'Edit Group' : 'Add New Group'}
        title='Groups'
        button_text='Add New Group'
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <EditGroupModal
              info={editInfo}
              groupNameList={groups.groupNameList}
              setNull={setEditInfo}
            />
          ) : (
            <AddNewGroup groupNameList={groups.groupNameList} isShowGroupName />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          style={{ width: 900 }}
          bordered
          pagination={
            groups?.count !== undefined && groups?.count < 20
              ? false
              : {
                  ...pagination,
                  total: groups?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
          }
          columns={groupCollumns({
            setEditInfo,
            showModal,
            permission,
            pagination,
          })}
          dataSource={groups.dataToReturn}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
    </div>
  );
};

export default Groups;
