import { Col, Form, Row } from 'antd';
import {
  DateInput,
  FormInputItem,
  MobileInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import FileUpload from '../../../common/utils/FileUpload';

type Props = { scanCopy?: string; uploadPhoto?: string; uploadOthers?: string };

const EditPassportInfo = ({ uploadOthers, scanCopy, uploadPhoto }: Props) => {
  return (
    <>
      <FormInputItem
        label='Passport No'
        name='passport_no'
        rules={[
          { max: 15, message: 'Invalid passport no.' },
          { min: 8, message: 'Invalid passport no.' },
        ]}
        size={6}
        required
      />

      <SelectOptionsInput
        label='Select Pax Type:'
        name={['passport_person_type']}
        placeholder='Select Pax Type'
        options={['Adult', 'Child', 'Infant']}
        size={5}
      />
      <FormInputItem label='Name' name='name' size={6} required />
      <MobileInput
        label='Mobile'
        name={['mobile_no']}
        rules={[
          { required: true, message: 'Mobile is required' },
          { max: 15, message: 'Max 15 characters!' },
          {
            message: 'Only digits allowed',
            validator: (_, value) => {
              if (/^[0-9-+]*$/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Non-numeric values are not allowed');
              }
            },
          },
        ]}
        size={5}
        countryCode={false}
        countryCodeFormName={['country_code']}
      />
      <FormInputItem label='Email' name='email' size={6} />
      <FormInputItem
        label='Nid No'
        name={'nid'}
        size={5}
        rules={[
          { max: 17, message: 'Invalid nid no.' },
          { min: 10, message: 'Invalid nid no.' },
        ]}
      />

      <DateInput label='Date of Birth' name='date_of_birth' size={6} />
      <DateInput label='Date of Issue' name='date_of_issue' size={5} />
      <DateInput label='Date of Expire' name='date_of_expire' size={6} />

      <Row justify={'space-between'} style={{ width: '50%', padding: '10px' }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Form.Item
            label='Upload Passport (Max 1 MB)'
            name={'scan_copy'}
            valuePropName='scan_copy'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='scan_copy'
              msg='Click here to passport image'
              noBorder
              imagURL={scanCopy}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Form.Item
            label='Upload Photo (Max 1 MB)'
            name={'upload_photo'}
            valuePropName='upload_photo'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='upload_photo'
              msg='Click here to upload image'
              noBorder
              imagURL={uploadPhoto}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Form.Item
            label='Upload Others (Max 1 MB)'
            name={'upload_others'}
            valuePropName='upload_others'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='upload_others'
              msg='Click here to upload image'
              noBorder
              imagURL={uploadOthers}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default EditPassportInfo;
