import { Button, Modal } from 'antd';
import { useState } from 'react';
import EmployeeAddModal from './EmployeeAddModal';

const InstanceCreateEmployee = ({
  setClick,
  setInstanceSalesEmpSelect,
}: {
  setClick?: (arg: boolean) => void;
  setInstanceSalesEmpSelect:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setClick && setClick(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setClick && setClick(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClick && setClick(false);
  };
  return (
    <>
      <Button onClick={showModal} style={{ width: '100%' }}>
        Add Employee
      </Button>
      <Modal
        title='Add new employee'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <EmployeeAddModal
          setClick={setClick}
          setIsModalOpen={setIsModalOpen}
          setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
        />
      </Modal>
    </>
  );
};

export default InstanceCreateEmployee;
