import { ColumnsType } from 'antd/lib/table/interface';
import { IGDSReportsDataType } from '../types/GdsReportType';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const gdsReportColumn = (
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined
): ColumnsType<IGDSReportsDataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      width: 50,
    },
    {
      title: 'GDS Name',
      dataIndex: 'gds_name',
      key: 'gds_name',
      width: 50,
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      width: 50,
      render: (_, record) => (
        <Link
          to={`/reports/ticket_wise?${paramData?.date_range}&airticket_id=${record.airticket_id}`}
        >
          {record.airticket_ticket_no}
        </Link>
      ),
    },
    {
      title: 'Route',
      dataIndex: 'airline_iata_code',
      key: 'airline_iata_code',
      width: 50,
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
      width: 50,
    },
    {
      title: 'Fly Date',
      dataIndex: 'airticket_journey_date',
      key: 'airticket_journey_date',
      width: 50,
      render: (_, record) => {
        return `${dayjs(record.airticket_journey_date).format('DD MM YYYY')}`;
      },
    },
    {
      title: 'Total Segment',
      dataIndex: 'airticket_segment',
      key: 'airticket_segment',
      width: 50,
    },
  ];
};

export default gdsReportColumn;
