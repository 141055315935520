import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { IViewInvoiceDetails } from '../../../common/types/commonInterfaces';
import FormHeaderTitle from './FormHeaderTitle';
import CostViewSubTotal from '../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal';

const InvoiceVisaTabDetails = ({
  invoiceDetails,
}: {
  invoiceDetails: IViewInvoiceDetails | undefined;
}) => {
  const billingColumn: ColumnsType<any> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
      align: 'center',
    },
    {
      title: 'Billing Type',
      dataIndex: 'type_name',
      key: 'type_name',
      align: 'center',
    },
    {
      title: 'Product',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'billing_status',
      key: 'billing_status',
      align: 'center',

      render: (billing_status) => {
        function color() {
          if (billing_status == 'Pending' || billing_status == 'Rejected') {
            return <span style={{ color: 'red' }}>{billing_status}</span>;
          } else if (billing_status == 'Approved') {
            return <span style={{ color: 'Green' }}>{billing_status}</span>;
          }

          return billing_status;
        }

        return <>{color()}</>;
      },
    },

    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      align: 'center',
    },

    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
      align: 'center',
    },
    {
      title: 'Unit price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      align: 'right',
      render(value, record, index) {
        return Number(value || 0);
      },
    },
    {
      title: 'Cost price',
      dataIndex: 'billing_cost_price',
      key: 'billing_cost_price',
      align: 'right',
      render(value, record, index) {
        return Number(value || 0);
      },
    },

    {
      title: 'Total Sales',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      align: 'right',
      render(value, record, index) {
        return Number(value || 0);
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      align: 'right',
      render: (text, record) => {
        const { billing_quantity, billing_cost_price } = record;
        const totalCost = billing_quantity * billing_cost_price;
        return <span>{Number(totalCost)}</span>;
      },
    },
    {
      title: 'Profit',
      dataIndex: 'billing_profit',
      key: 'billing_profit',
      align: 'right',
      render: (value) => (
        <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
          {Number(value || 0)}
        </span>
      ),
    },
    {
      title: 'Delivery Date',
      dataIndex: 'billing_delivery_date',
      key: 'billing_delivery_date',
      align: 'center',

      render: (_, data) => (
        <>
          {' '}
          {data?.billing_delivery_date
            ? dayjs(data?.billing_delivery_date).format('D-MM-YYYY')
            : undefined}{' '}
        </>
      ),
    },
  ];
  const passportColumn: ColumnsType<any> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Passport',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    {
      title: 'Email',
      dataIndex: 'passport_email',
      key: 'passport_email',
    },

    {
      title: 'Date of birth',
      key: 'passport_date_of_birth',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_birth
            ? dayjs(data?.passport_date_of_birth).format('D-MM-YYYY')
            : ''}{' '}
        </>
      ),
    },
    {
      title: 'Date of issue',
      key: 'passport_date_of_issue',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_issue
            ? dayjs(data?.passport_date_of_issue).format('D-MM-YYYY')
            : ''}{' '}
        </>
      ),
    },
    {
      title: 'Date of expire',
      key: 'passport_date_of_expire',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_expire
            ? dayjs(data?.passport_date_of_expire).format('D-MM-YYYY')
            : ''}{' '}
        </>
      ),
    },
  ];

  return (
    <>
      {invoiceDetails?.passport_information?.length ? (
        <div className='mt-5'>
          <FormHeaderTitle title='PASSPORT INFO' />

          <Table
            size='small'
            bordered
            rowKey={(e) => e.passport_passport_no}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.passport_information}
            columns={passportColumn}
            pagination={false}
          />
        </div>
      ) : (
        <></>
      )}

      {
        <div className='mt-5'>
          <FormHeaderTitle title='BILLING INFO' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.billing_delivery_date}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.billing_information}
            columns={billingColumn}
            pagination={false}
          />
        </div>
      }
      <CostViewSubTotal invoice_info={invoiceDetails} />
    </>
  );
};

export default InvoiceVisaTabDetails;
