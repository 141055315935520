import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormButton,
} from '../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../components/common/FormItem/SelectCustomFeilds';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import {
  useEditQuotationMutation,
  useGetSingleQuotationQuery,
} from '../Api/Endpoints/QuotationEndpoints';
import Billing_Info_FormList from '../Components/Billing_Info_FormList';
import {
  IQuotationEditFormData,
  QuotationBillType,
} from '../QuotationTypes/QuotationTypes';
import JoditEditor from 'jodit-react';

interface IProps {}

const QuotationEdit = (props: IProps) => {
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: quotationData } = useGetSingleQuotationQuery(Number(id));
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const editor = useRef(null);
  const [content, setContent] = useState('');

  const [discount, setDiscount] = useState<number | null | undefined>(
    quotationData?.data?.quotation_discount_total
  );

  type TBillingPrice = {
    product_id: number;
    description: string;
    quantity: number;
    unit_price: number;
    total_price: number;
  }[];

  const billing_price = Form.useWatch<TBillingPrice | undefined>(
    'bill_info',
    form
  );

  useEffect(() => {
    if (billing_price) {
      let tempTotalPrice = 0;

      billing_price.forEach((element: QuotationBillType) => {
        if (element && element.quantity && element.unit_price) {
          tempTotalPrice += element.quantity * element.unit_price;
        }
      });

      setTotalPrice(tempTotalPrice);
    }
  }, [billing_price]);

  useEffect(() => {
    if (totalPrice) {
      form.setFieldValue('sub_total', totalPrice);
    }

    if (discount === null) {
      form.setFieldValue('net_total', totalPrice);
    }

    if (typeof discount === 'number') {
      form.setFieldValue('net_total', totalPrice - discount);
    }
  }, [billing_price, discount, totalPrice]);

  useEffect(() => {
    form.setFieldsValue({
      client_id: quotationData?.data?.client_id,
      q_number: quotationData?.data?.quotation_no,
      date: dayjs(quotationData?.data?.quotation_date),
      sub_total: quotationData?.data?.billing_subtotal,
      discount: quotationData?.data?.quotation_discount_total,
      net_total: quotationData?.data?.quotation_net_total,
      note: quotationData?.data?.quotation_note,
    });
    setDiscount(quotationData?.data?.quotation_discount_total);
  }, [quotationData]);

  const [editQuotation, { isLoading }] = useEditQuotationMutation();

  const onFinish = async (values: IQuotationEditFormData) => {
    const body: IQuotationEditFormData = {
      ...values,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      updated_by: user?.user_id as number,
      discount: values.discount == null ? 0 : values.discount,
      note: values.note == null ? '' : values.note,
    };

    await editQuotation({ id: Number(id), body });
    navigate(`/quotation/details/${id}`, { state: '/quotation' });
  };

  const color = localStorage.getItem('theme') as 'defaultTheme' | 'darkTheme';

  const config = {
    theme: color === 'darkTheme' ? 'default' : 'dark',
    height: 400,
    style: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    toolbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#000000',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
    statusbarStyle: {
      backgroundColor: color === 'darkTheme' ? '#ffffff' : '#121212',
      color: color === 'darkTheme' ? '#000000' : '#ffffff',
    },
  };

  return (
    <div>
      <BreadCrumb arrOfOption={['Edit Quotation']} />
      <Space>
        <Link to='/quotation'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Quotation List
          </Button>
        </Link>

        <Link to='/clients/add'>
          <Button type='primary'>Add Client</Button>
        </Link>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Card style={{ marginTop: '0.5rem' }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <SelectClients
              name='client_id'
              label='Select Client'
              required
              size={6}
            />
            <Button
              onClick={() => {
                navigate('/clients/add');
              }}
              style={{ marginTop: '30px' }}
              type='primary'
            >
              Add Client
            </Button>
            <Col span={8} xs={24} sm={24} md={24} lg={8}>
              <Form.Item label='Quotation No :' name='q_number'>
                <Input readOnly />
              </Form.Item>
            </Col>

            <DateInput label='Quotation Date :' name='date' required />
          </Row>
          {quotationData?.data?.billInfo ? (
            <Billing_Info_FormList
              form={form}
              billInitialValues={quotationData?.data?.billInfo}
            />
          ) : (
            loadingIndicator
          )}

          <div className='mb-10 border p-10'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={5} xs={24} sm={24} md={24} lg={6}>
                <Form.Item
                  label='Sub Total'
                  name='sub_total'
                  rules={[{ required: true, message: 'Sub Total is required' }]}
                >
                  <InputNumber disabled style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={5} xs={24} sm={24} md={24} lg={6}>
                <Form.Item
                  label='Discount'
                  name='discount'
                  rules={[
                    {
                      validator(_, value) {
                        if (value) {
                          if (totalPrice < value) {
                            return Promise.reject(
                              'Discount cannot be more than sub total'
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    onChange={(e: number | null) => {
                      setDiscount(Number(e));
                    }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={5} xs={24} sm={24} md={24} lg={6}>
                <Form.Item
                  label='Net Total'
                  name='net_total'
                  // rules={[{ required: true, message: 'Net Total is required' }]}
                >
                  <InputNumber disabled style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={5} xs={24} sm={24} md={24} lg={6}>
                <Form.Item label='Due Amount' name='due_amount'>
                  <InputNumber disabled style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={10} xs={24} sm={24} md={24} lg={12}>
                <Form.Item label='Note' name='note'>
                  <JoditEditor
                    config={config}
                    ref={editor}
                    value={content}
                    onBlur={(newContent) => {
                      setContent(newContent);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <FormButton label='Update Quotation' loading={isLoading} />
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default QuotationEdit;
