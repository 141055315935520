import { message } from "antd";
import { api } from "../../../../app/baseQuery";
import {
  IAllInvoices,
  IInvNonComPartialCost,
  IPostInvNonComPartialCost,
  IViewInvoiceDetails,
} from "../../../../common/types/commonInterfaces";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from "../../../Reports/Api/constants";
import {
  getForViewAllNonCommissionInvoiceType,
  nonCommitionInvoicePostType,
} from "../../types/nonInvoiceAirTicketTypes";
import { AIRTICKET_NON_COMISSION_LIST } from "../constants/constants";
import { MONEY_RECEIPT_TAG } from "../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints";
import asyncWrapper from "../../../../app/asyncWrapper";

export const airTicketNonCommissionEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAllNonComissionInvoice: build.query<
      HTTPResponse<getForViewAllNonCommissionInvoiceType[]>,
      void
    >({
      query: () => ({ url: "/invoice-air-ticket/all_by_cate/2" }),
      providesTags: [
        {
          type: "InvoiceNonComission",
          id: AIRTICKET_NON_COMISSION_LIST,
        },
      ],
    }),

    // POST INVOICE NON COMISSION
    postInvoiceNonComission: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      any
    >({
      query: (body) => ({
        url: "/invoice-non-commission",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "InvoiceNonComission",
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // EDIT INVOICE NON COMISSION
    editInvoiceNonComission: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      { body: any; id: string }
    >({
      query: ({ body, id }) => ({
        url: `/invoice-non-commission/${id}`,
        method: "PATCH",
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        asyncWrapper(async () => {
          await queryFulfilled;
        });
      },
      invalidatesTags: ["InvoiceNonComission", INVALIDATE_CLIENT_VENDOR_LEDGER],
    }),

    getViewInvoiceNonCom: build.query<
      HTTPResponse<IViewInvoiceDetails>,
      number
    >({
      query: (id) => ({
        url: `/invoice-non-commission/view/${id}`,
      }),
      providesTags: () => [
        "InvoiceNonComission",
        "invoices",
        "invoices_from_money_receipt",
      ],
    }),

    getSingleNonComissionInvoice: build.query<
      HTTPResponse<nonCommitionInvoicePostType>,
      Number
    >({
      query: (id) => ({
        url: `/invoice-non-commission/${id}`,
      }),
      providesTags: [
        { type: "InvoiceNonComission", id: AIRTICKET_NON_COMISSION_LIST },
      ],
    }),

    getAllNonCommission: build.query<HTTPResponse<IAllInvoices[]>, string>({
      query: (trash) => ({
        url: `/invoice-non-commission?${trash || ""}`,
      }),
      providesTags: () => [
        "invoices",
        "InvoiceHajj",
        "InvoiceNonComission",
        "NonComissionInvalidateFromPayroll",
      ],
    }),
    // DELETE AND RESTORE
    deleteInvoiceNonComission: build.mutation<
      HTTPResponse<void>,
      { user_id?: number; id: number }
    >({
      query: ({ id, user_id }) => ({
        url: `/invoice-non-commission/${id}`,
        method: "DELETE",
        body: { invoice_has_deleted_by: user_id },
      }),
      invalidatesTags: ["invoices", INVALIDATE_CLIENT_VENDOR_LEDGER],
    }),

    // Void Non-Invoice AirTicket
    voidNonInvAirTicket: build.mutation<
      void,
      {
        body: {
          invoice_has_deleted_by: number | undefined;
          void_charge: number;
          id: number;
        };
      }
    >({
      query: ({ body }) => ({
        url: `/invoice-non-commission/void/${body.id}`,
        method: "PUT",
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error("Some things went to wrong");
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success("Void Successfully");
      },

      invalidatesTags: [
        "invoices",
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        "InvoiceNonComission",
      ],
    }),

    getNonCommissionPartialCost: build.query<
      HTTPResponse<IInvNonComPartialCost[]>,
      string | number
    >({
      query: (id) => ({
        url: `/invoice-non-commission/partial-cost/${id}`,
      }),
      providesTags: () => [
        "invoices",
        "PartialCost",
        "InvoiceHajj",
        "InvoiceNonComission",
        "NonComissionInvalidateFromPayroll",
      ],
    }),

    postNonCommissionPartialCost: build.mutation<
      HTTPResponse<void>,
      { body: IPostInvNonComPartialCost[]; id: string | number }
    >({
      query: ({ body, id }) => ({
        url: `/invoice-non-commission/partial-cost/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: () => ["PartialCost"],
    }),
  }),
});

export const {
  useGetAllNonComissionInvoiceQuery,
  useGetSingleNonComissionInvoiceQuery,
  useLazyGetSingleNonComissionInvoiceQuery,
  useGetAllNonCommissionQuery,
  useLazyGetAllNonCommissionQuery,
  useGetViewInvoiceNonComQuery,
  usePostInvoiceNonComissionMutation,
  useEditInvoiceNonComissionMutation,
  useDeleteInvoiceNonComissionMutation,
  useVoidNonInvAirTicketMutation,
  useLazyGetNonCommissionPartialCostQuery,
  usePostNonCommissionPartialCostMutation,
} = airTicketNonCommissionEndpoints;
