import { Col, Form, Row, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import {
  DateInput,
  FormButton,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  IAccountCategoryPaymentType,
  ICreateAccountFormData,
} from '../../AccountsTypes/AccountsTypes';
import {
  useCreateAccountMutation,
  useGetAccountsCategoryTypeQuery,
} from '../../Api/Endpoints/accountsEndpoints';

const AccountsAddModal = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [paymentMethod, setPaymentMethod] = useState<number | undefined>(1);

  const [addAccount, { isLoading, isSuccess, isError }] =
    useCreateAccountMutation();

  const onFinish = async (values: ICreateAccountFormData) => {
    values.opening_balance = values?.opening_balance || 0;

    setPaymentMethod(1);
    const body: ICreateAccountFormData = {
      ...values,
      account_created_by: user?.user_id as number,
    };
    await addAccount(body);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setModal(false));
      form.resetFields();
    }
  }, [isSuccess]);

  // ===============get payment method
  const { data: accountTypePaymentMethod } = useGetAccountsCategoryTypeQuery();
  useEffect(() => {
    if (isSuccess) {
      message.success('Account Created Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  return (
    <div>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={6} xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name='account_acctype_id'
              label='Account Type'
              rules={[
                { required: true, message: 'Please Select Account Type!!' },
              ]}
            >
              <Select
                placeholder='Select Account Type'
                style={{ width: '100%' }}
                onChange={(e: number) => setPaymentMethod(e)}
                allowClear
              >
                {accountTypePaymentMethod?.data?.map(
                  (item: IAccountCategoryPaymentType) => {
                    return (
                      <Select.Option
                        key={item.acctype_id}
                        value={item.acctype_id}
                      >
                        {item.acctype_name}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </Col>
          <FormInputItem
            label='Account Name'
            name='account_name'
            required
            size={24}
          />
          {paymentMethod === 2 && (
            <>
              <FormInputItem
                label='Account number'
                name='account_number'
                required
                size={24}
                maxLength={27}
              />
              <FormInputItem
                label='Bank name'
                name='account_bank_name'
                required
                size={24}
              />
              <FormInputItem
                label='Branch'
                name='account_branch_name'
                required
                size={24}
              />
              <FormInputItem
                label='Routing No'
                name='account_routing_no'
                size={24}
              />
            </>
          )}
          {paymentMethod === 3 && (
            <>
              <FormInputItem
                placeholder='Enter Your Account Number'
                label='Account number'
                name='account_number'
                maxLength={27}
                required
                size={24}
              />
            </>
          )}

          {paymentMethod === 5 && (
            <>
              <FormInputItem
                placeholder='Enter Card Number'
                label='Card number'
                name='card_number'
                required
                size={24}
              />

              <FormInputItem
                placeholder='Enter Card CSV'
                label='Card CSV'
                name='card_csv'
                size={24}
              />

              <DateInput
                placeholder='Enter Card Expiry Date'
                label='Card Expiry Date'
                name='card_expire_date'
                size={24}
              />
            </>
          )}

          <NumberInput
            min='0'
            name='opening_balance'
            label='Current Last Balance'
            size={24}
            maxChar={14}
          />

          <FormButton label={'Create account'} loading={isLoading} />
        </Row>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default AccountsAddModal;
