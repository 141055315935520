import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { IPermission } from '../../../../common/types/commonTypes';
import InvoiceActivityLogs from '../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs';
import CommonViewInvoice from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import TabDetailsView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView';
import TabPaymentView from '../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView';
import TabUmrahRefundDetails from '../../../../components/common/Invoice/ViewInvoiceInfo/TabUmrahRefundDetails';
import ViewInvoiceHeader from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import ViewInvoiceUmrahBilling from '../../Components/ViewInvoiceUmrahBilling';
import ViewUmrahTavDetails from '../../Components/ViewUmrahTavDetails';
import { useGetViewInvoiceUmmrahQuery } from '../../api/endpoints/invoiceUmrahEndpoints';

type Props = {
  permission?: IPermission;
};
const InvoiceUmmrahView = ({ permission }: Props) => {
  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);
  // GET INVOICE AIRTICKET DETAILS
  const { data: invoices, isLoading } =
    useGetViewInvoiceUmmrahQuery(invoice_id);
  const invoiceData = invoices?.data;
  const invoice_category_id = invoiceData?.invoice_category_id;
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewRefundDetailsRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = useState<
    '1' | '2' | '3' | '4' | '5'
  >();

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case '1':
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case '2':
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case '3':
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case '4':
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    case '5':
      selectedPrintableRef = viewRefundDetailsRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />

      <Tabs
        style={{ marginTop: '10px' }}
        onChange={(e) => {
          switch (e) {
            case '1':
              setSelectedForPrint && setSelectedForPrint('1');
              break;
            case '2':
              setSelectedForPrint && setSelectedForPrint('2');
              break;
            case '3':
              setSelectedForPrint && setSelectedForPrint('3');
              break;
            case '4':
              setSelectedForPrint && setSelectedForPrint('4');
              break;
            case '5':
              setSelectedForPrint && setSelectedForPrint('5');
              break;
            default:
              break;
          }
        }}
        type='card'
        items={[
          {
            label: 'Invoice',
            key: '1',
            children: isLoading ? (
              <LoadingIndicator />
            ) : (
              invoiceData && (
                <CommonViewInvoice
                  invoiceData={invoiceData}
                  viewInvoicePrintableRef={viewInvoicePrintableRef}
                  heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
                  children={
                    <ViewInvoiceUmrahBilling invoiceDetails={invoiceData} />
                  }
                  permission={permission}
                />
              )
            ),
          },
          {
            label: 'Details',
            key: '2',
            children: invoiceData && (
              <TabDetailsView
                invoiceDetails={invoiceData}
                children={<ViewUmrahTavDetails invoiceDetails={invoiceData} />}
                viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
              />
            ),
          },
          {
            label: 'Payments',
            key: '3',
            children: (
              <>
                <TabPaymentView
                  invoiceDetails={invoiceData}
                  viewInvoicePaymentsRef={viewInvoicePaymentsRef}
                />
              </>
            ),
          },
          {
            label: 'Activity Log',
            key: '4',
            children: (
              <>
                <InvoiceActivityLogs
                  id={invoice_id}
                  viewInvoiceAcivityRef={viewInvoiceAcivityRef}
                />
              </>
            ),
          },

          {
            label: 'Refund Details',
            key: '5',
            children: invoiceData && (
              <TabUmrahRefundDetails
                viewInvoicePaymentsRef={viewRefundDetailsRef}
              />
            ),
          },
        ]}
      ></Tabs>
    </>
  );
};

export default InvoiceUmmrahView;
