import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { Fixed2 } from '../../../common/utils/common.utils';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceMoneyReceiptModal from '../../../components/common/Invoice/InvoiceMoneyReceiptModal';
import { useGetAllAgentProfileQuery } from '../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import { getAgentCommissionRate } from '../../Invoice_Air_Ticket/utils/ts/getAgentCommissionRate';
import { usePostInvoiceNonComissionMutation } from '../api/endpoints/airTicketNonCommissionEndpoints';
import { INonComFormSubmit } from '../types/nonInvoiceAirTicketTypes';
import { nonComFormatForPost } from '../utils/getFormatForPost';
import InvoiceNonComSubTotal from './InvoiceNonComSubTotal';
import InvPostIsDiscountIsPrevDue from '../../Invoice_Air_Ticket/Components/InvPostIsDiscountIsPrevDue';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';

type Props = {
  invoices: INonComFormSubmit[];
  setInvoices: React.Dispatch<React.SetStateAction<INonComFormSubmit[]>>;
  form: FormInstance<any>;
  setEditIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  editIndex?: number;
};

export default function PostOverviewNonCom({
  invoices,
  setInvoices,
  form,
  setEditIndex,
  editIndex,
}: Props) {
  // @STATES
  const [billingForm] = Form.useForm();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  // @POST API
  const [
    postInvoiceNonComission,
    { isError, isLoading, isSuccess, data: postIdData },
  ] = usePostInvoiceNonComissionMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  // @AIRPORT DATA
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  // // @VARIABLES
  const client_price = invoices.reduce(
    (a, b) => a + Fixed2(b?.clientPriceSet),
    0
  );
  const agent_profit = invoices.reduce(
    (a, b) => a + Fixed2(b?.airticket_profit),
    0
  );
  const purchase_price = invoices.reduce(
    (a, b) => a + Fixed2(b?.airticket_purchase_price),
    0
  );
  const profit = invoices.reduce((a, b) => a + Fixed2(b?.airticket_profit), 0);

  // // @HANDLE COPY
  const handleCopy = (index: number) => {
    form.setFieldsValue(invoices[index]);
    setEditIndex(undefined);
    message.info('Copyed');
  };

  // // @HANDLE EDIT
  const handleEdit = (index: number) => {
    // form.setFieldsValue(invoices[index]);

    form.setFieldsValue({
      ...invoices[index],
      airticket_route_or_sector_reset: 'true',
    });
    setEditIndex(index);
  };

  // // @HANDLE DELETE
  const handleDelete = (index: number) => {
    setInvoices(invoices.filter((_, i) => i !== index));
    setEditIndex(undefined);
  };

  // @TABLE COLUMNS
  const column: ColumnsType<any> = [
    {
      width: 15,
      title: 'SL',
      render(_, __, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'Client Price',
      dataIndex: 'clientPriceSet',
      key: 'clientPriceSet',
      render(value, record, index) {
        return Fixed2(value);
      },
      align: 'right',
    },
    {
      title: 'Vendor Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render(value, record, index) {
        return Fixed2(value);
      },
      align: 'right',
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      render: (value) => (
        <span className={Number(value || 0) > 0 ? 'text-profit' : 'text-loss'}>
          {Number(value || 0)}
        </span>
      ),
      align: 'right',
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <div>
            <Button
              title={'Copy'}
              onClick={() => handleCopy(index)}
              size='small'
              type='text'
            >
              <CopyOutlined />
            </Button>
            <Button
              title={'Edit'}
              onClick={() => handleEdit(index)}
              size='small'
              type='text'
            >
              <EditOutlined />
            </Button>
            <Button
              title={'Delete'}
              onClick={() => handleDelete(index)}
              size='small'
              type='text'
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  // @HANDLE SUBMIT
  const handleSubmit = (data: any) => {
    const formatedData = nonComFormatForPost({
      invoices,
      billing_form: billingForm,
      user_id: user?.user_id as number,
      selectAirport,
    });

    checkCreditLimit({
      amount: formatedData.invoice_info.invoice_net_total,
      combClient: formatedData.invoice_info.invoice_combclient_id,
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        postInvoiceNonComission(formatedData);
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  // // @AFTER CREATE SHOW ALERT
  useEffect(() => {
    if (isSuccess && postIdData?.data) {
      message.success('Invoice Added Successfully');

      updateAndPrev
        ? navigate(`/noncomairticket/details/${postIdData?.data}`, {
            state: location?.pathname,
          })
        : navigate('/noncomairticket');
    }
  }, [isSuccess, postIdData]);

  // @SHOW ERROR
  useEffect(() => {
    if (isError) message.error('Something went wrong, please try again!');
  }, [isError]);

  // GET ALL AGENT
  const { data: agentData } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = agentData?.data;

  useEffect(() => {
    billingForm.setFieldValue('invoice_show_passport_details', 0);
    billingForm.setFieldValue('invoice_show_prev_due', 0);
    billingForm.setFieldValue('invoice_show_discount', 1);

    // @HANDLE AGENT COMISSION
    if (invoices[invoices?.length - 1]?.invoice_agent_id) {
      const agentCom =
        getAgentCommissionRate({
          allAgents: getAllAgentProfile,
          invoice_agent_id: invoices[invoices?.length - 1]?.invoice_agent_id,
        }) || 0;
      const commission = Fixed2((agentCom / 100) * (agent_profit || 0));

      billingForm.setFieldValue('invoice_agent_com_amount', commission);
    } else {
      billingForm.setFieldValue('invoice_agent_com_amount', undefined);
    }
  }, [invoices]);

  return (
    <Row gutter={[10, 0]}>
      <Table
        style={{ width: '100%' }}
        size='small'
        columns={column}
        dataSource={invoices.map((item, index) => ({
          ...item,
          key: index,
        }))}
        pagination={false}
        bordered
        rowClassName={(record, index) =>
          index === editIndex ? 'record-edit-active' : ''
        }
        summary={(_) => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} index={0}>
                <Typography style={{ textAlign: 'right' }}>Total : </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text style={{ textAlign: 'right' }}>
                  {Fixed2(client_price)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align='right'>
                <Typography.Text style={{ textAlign: 'right' }}>
                  {Fixed2(purchase_price)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text style={{ textAlign: 'right' }}>
                  {Fixed2(profit)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <Form
        form={billingForm}
        onFinish={handleSubmit}
        layout='vertical'
        style={{ marginTop: '50px' }}
        disabled={!invoices?.length}
      >
        <InvoiceNonComSubTotal
          form={billingForm}
          clientPriceSum={client_price}
        />
        <InvPostIsDiscountIsPrevDue billingForm={billingForm} />

        {/* ADD MONEY RECEIPT */}
        <InvoiceMoneyReceiptModal
          billingForm={billingForm}
          client_price={client_price}
          disabled={!invoices?.length}
        />

        {/* @AIRTICKET EIDT BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limiLoading)}
              disabled={!invoices?.length}
              label='CREATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limiLoading)}
              disabled={!invoices?.length}
              label='CREATE & PREVIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Row>
  );
}
