import { ColumnsType } from 'antd/lib/table';
import { Input, Form } from 'antd';
import { ITourAccmdnInfo } from '../../RefundTypes/RefundTypes';

export const TourAccomodationRefundUtils = (
  onTableVendorChargeRefundChange: Function,
  name: string
): ColumnsType<ITourAccmdnInfo> => {
  return [
    {
      title: 'Sl.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Accomodation Name',
      dataIndex: 'accommodation_hotel_name',
      key: 'accommodation_hotel_name',
    },

    {
      title: 'Vendor Refund Charge',
      key: 'vendor_refund_charge',
      dataIndex: 'vendor_refund_charge',
      render: (text, record, index) => (
        <Form.Item
          name={[name, index, 'vendor_refund_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (value > Number(record.accm_cost_price)) {
                  return Promise.reject(
                    'Refund charge cannot be greater than return price'
                  );
                }
                if (value < 1) {
                  return Promise.reject('Refund charge cannot be less than 1');
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableVendorChargeRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.vendor_refund_charge !== prevRecord.vendor_refund_charge;
      },
    },

    {
      title: 'Cost Price',
      dataIndex: 'accm_cost_price',
      key: 'accm_cost_price',
    },
    {
      title: 'Refund Amount',
      dataIndex: 'accm_cost_price',
      key: 'vendor_refund_amount',
    },

    {
      title: 'Vendor Return Amount',
      dataIndex: 'vendor_return_amount',
      key: 'vendor_return_amount',
      shouldCellUpdate: (record, prevRecord) => {
        return record.vendor_return_amount !== prevRecord.vendor_return_amount;
      },
    },
  ];
};
