import { LogoutOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Popconfirm, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { TUser } from '../../auth/states/userSlice';
import { ChangePassword } from '../../components/common/FormItem/ChangeUserPassword';
import { useNavigate } from 'react-router';
import { getImageUrl } from '../../common/utils/common.utils';

export const userContent = (
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  form: FormInstance<any>,
  handleLogout: () => void,
  user: TUser,
  handleCancel: () => void
) => {
  const agencyInfo = user?.organization_info;
  const navigate = useNavigate();

  const userContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography.Title level={5}>{agencyInfo?.org_name}</Typography.Title>
      <img
        width={100}
        style={{ background: '#fff', padding: 10, borderRadius: 5 }}
        src={getImageUrl(agencyInfo?.org_logo)}
        alt={agencyInfo?.org_name || 'Trabill'}
      />
      <Typography.Title level={5}>{user?.user_full_name}</Typography.Title>

      <Button
        style={{ marginTop: 10, overflow: 'hidden', width: '100%' }}
        size='small'
        type='default'
        onClick={() => {
          navigate('/profile-setting');
        }}
      >
        Profile Setting
      </Button>

      <Button
        style={{ marginTop: 10, overflow: 'hidden' }}
        size='small'
        type='default'
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Change Password
      </Button>
      <Popconfirm
        placement='bottom'
        onConfirm={() => handleLogout()}
        title='Sure to logout?'
      >
        <Button
          style={{ marginTop: 10, width: '100%' }}
          size='small'
          type='primary'
          danger
        >
          <Typography>
            <span style={{ color: 'white' }}>
              <LogoutOutlined /> Sign Out
            </span>
          </Typography>
        </Button>
      </Popconfirm>

      <Modal
        title='Change password'
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        footer={null}
        onCancel={handleCancel}
      >
        <ChangePassword setIsmodal={setIsModalOpen} />
      </Modal>
    </div>
  );

  return userContent;
};
