import { api } from '../../../app/baseQuery';

export const dB_backupEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    makeDbBackup: build.mutation({
      query: () => ({
        url: `/admin-panel/db-backup`,
        method: 'GET',
        responseHandler: async (response) => {
          const filename = `db_backup.zip`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
      }),
    }),
  }),
});

export const { useMakeDbBackupMutation } = dB_backupEndpoints;
