import { useAppSelector } from '../../app/hooks';
import { getImageUrl } from '../utils/common.utils';

type Props = {};

const WaterMark = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_wtr_mark } = appConfig;
  return (
    <div>
      {tac_wtr_mark !== 0 ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 10,
            right: 0,
            pointerEvents: 'none',
            opacity: 0.05,
            zIndex: 9,
            userSelect: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={
              getImageUrl(appConfig?.tac_wtr_mark_url) ||
              getImageUrl(orgInfo?.org_logo)
            }
            alt={orgInfo?.org_name}
            style={{ maxWidth: 500, userSelect: 'none', objectFit: 'cover' }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default WaterMark;

export const LandscapeWaterMark = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_wtr_mark } = appConfig;
  return (
    <div>
      {tac_wtr_mark !== 0 ? (
        <img
          src={
            getImageUrl(appConfig?.tac_wtr_mark_url) ||
            getImageUrl(orgInfo?.org_logo)
          }
          alt={orgInfo?.org_name}
          style={{ maxWidth: 600, userSelect: 'none' }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
