import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import {
  IOtherTaxReportDataType,
  ITaxReportDataType,
} from '../types/TaxReportTypes';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

export const TaxReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<ITaxReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (_, record) => {
        return `${dayjs(record.invoice_sales_date).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'invoice_vat',
      key: 'invoice_vat',
      render: (_, record) => Number(record.invoice_vat),
    },
  ];
};

export const OtherTaxReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IOtherTaxReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (_, record) => {
        return `${dayjs(record.invoice_sales_date).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'invoice_vat',
      key: 'invoice_vat',
      render: (_, record) => Number(record.airticket_tax),
    },
    {
      title: 'Tax1',
      dataIndex: 'airticket_tax1',
      key: 'airticket_tax1',
      render: (_, record) => Number(record.airticket_tax1),
    },
    {
      title: 'BD',
      dataIndex: 'airticket_bd_charge',
      key: 'airticket_bd_charge',
      render: (_, record) => Number(record.airticket_bd_charge),
    },
    {
      title: 'UT',
      dataIndex: 'airticket_ut_charge',
      key: 'airticket_ut_charge',
      render: (_, record) => Number(record.airticket_ut_charge),
    },
    {
      title: 'ES',
      dataIndex: 'airticket_es_charge',
      key: 'airticket_es_charge',
      render: (_, record) => Number(record.airticket_es_charge),
    },
    {
      title: 'XT',
      dataIndex: 'airticket_xt_charge',
      key: 'airticket_xt_charge',
      render: (_, record) => Number(record.airticket_xt_charge),
    },
    {
      title: 'OW',
      dataIndex: 'airticket_ow_charge',
      key: 'airticket_ow_charge',
      render: (_, record) => Number(record.airticket_ow_charge),
    },
    {
      title: 'QA',
      dataIndex: 'airticket_qa_charge',
      key: 'airticket_qa_charge',
      render: (_, record) => Number(record.airticket_qa_charge),
    },
    {
      title: 'PZ',
      dataIndex: 'airticket_pz_charge',
      key: 'airticket_pz_charge',
      render: (_, record) => Number(record.airticket_pz_charge),
    },
    {
      title: 'G4',
      dataIndex: 'airticket_g4_charge',
      key: 'airticket_g4_charge',
      render: (_, record) => Number(record.airticket_g4_charge),
    },
    {
      title: 'E5',
      dataIndex: 'airticket_e5_charge',
      key: 'airticket_e5_charge',
      render: (_, record) => Number(record.airticket_e5_charge),
    },
    {
      title: 'P7',
      dataIndex: 'airticket_p7_charge',
      key: 'airticket_p7_charge',
      render: (_, record) => Number(record.airticket_p7_charge),
    },
    {
      title: 'P8',
      dataIndex: 'airticket_p8_charge',
      key: 'airticket_p8_charge',
      render: (_, record) => Number(record.airticket_p8_charge),
    },
    {
      title: 'R9',
      dataIndex: 'airticket_r9_charge',
      key: 'airticket_r9_charge',
      render: (_, record) => Number(record.airticket_r9_charge),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => Number(record.total),
    },
  ];
};
