import { Card, Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../app/hooks';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
  FormInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectOptionsInput,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import {
  IReissueTicketInfo,
  ISubmitReIssueRefund,
} from '../../../Invoice_Non_Comission/types/nonInvoiceAirTicketTypes';
import {
  useGetReissueTicketInfoQuery,
  useReIssueRefundMutation,
} from '../../../Invoice_Re_Issue/api/endpoints/reIssueAirTicketInvoiceEndpoints';
import SelectPaymentTypeAndAccount from '../SelectPaymentTypeAndAccount';
import ReIssueInputItems from './ReIssueInputItems';

type Props = {
  form: FormInstance<any>;
  handleCancel: () => void;
  invoiceId: number;
  invoiceNo: string;
};

const ReIssueModalContent = ({
  form,
  handleCancel,
  invoiceId,
  invoiceNo,
}: Props) => {
  const params = useParams();
  const user = useAppSelector((state) => state.user);

  const [clientAccountSize, setClientAccountSize] = useState(12);
  const [vendorAccountSize, setVendorAccountSize] = useState(12);
  const [postRefund, { isSuccess, isError }] = useReIssueRefundMutation();
  const { data, isSuccess: prevDataSuccess } =
    useGetReissueTicketInfoQuery(invoiceId);

  // GET DATA FROM APIs
  const ticketInfo = data?.data as IReissueTicketInfo[];

  const formattedTicketInfo =
    ticketInfo?.length &&
    ticketInfo?.map((item) => {
      return {
        airticket_ticket_no: item.airticket_ticket_no,
        airticket_client_price: item.airticket_client_price,
        airticket_purchase_price: item.airticket_purchase_price,
        comb_vendor: item.comb_vendor,
        airticket_id: item.airticket_id,
      };
    });

  useEffect(() => {
    form.setFieldsValue({
      comb_client: ticketInfo?.length && ticketInfo[0]?.comb_client,
      invoice_no: invoiceNo,
      client_refund_type: 'Adjust',
      vendor_refund_type: 'Adjust',
      refund_date: dayjs(),
    });

    form.setFieldValue('ticket_info', formattedTicketInfo);
  }, [prevDataSuccess]);

  const ticketInfos = useWatch('ticket_info', form);

  //   CLIENT AND VENDOR TOTAL REFUND AMOUNT
  useEffect(() => {
    if (ticketInfos) {
      form.setFieldsValue({
        client_total_refund: ticketInfos?.reduce(
          (a: any, b: any) => a + Fixed2(b?.client_refund),
          0
        ),
        total_vendor_refund: ticketInfos?.reduce(
          (a: any, b: any) => a + Fixed2(b?.vendor_refund),
          0
        ),
      });
    }
  }, [ticketInfos]);

  const onFinish = (values: ISubmitReIssueRefund) => {
    const body = {
      created_by: user?.user_id,
      invoice_id: params.id,
      ...values,
    };
    postRefund(body);
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (isSuccess) {
      message.success('Refund has been successful');
      form.resetFields();
      handleCancel();
    } else if (isError) {
      message.error('Something happening wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Card size='small' style={{ width: '100%' }}>
        <Row gutter={20}>
          <SelectClients
            label='Client'
            name={'comb_client'}
            disabled
            size={8}
          />
          <FormInput
            label='Invoice No.'
            name={'invoice_no'}
            readonly
            size={8}
          />

          <DateInput
            label='Refund Date.'
            name={'refund_date'}
            size={8}
            required
          />

          {/* Nest Form.List */}
          <Col lg={24}>
            <Form.Item>
              <Form.List name={['ticket_info']}>
                {(subFields, subOpt) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 12,
                    }}
                  >
                    {subFields?.map((subField, index) => (
                      <ReIssueInputItems
                        key={index}
                        subField={subField}
                        subOpt={subOpt}
                        form={form}
                      />
                    ))}
                  </div>
                )}
              </Form.List>
            </Form.Item>
          </Col>

          {/* CLIENT REFUND INFORMATION */}
          <FormInput
            label='Total Client Refund'
            name={'client_total_refund'}
            size={clientAccountSize}
          />
          <SelectOptionsInput
            size={clientAccountSize}
            options={['Adjust', 'Return']}
            label='Client Refund Type'
            name={'client_refund_type'}
            onSelect={(value) =>
              setClientAccountSize(value === 'Adjust' ? 12 : 6)
            }
          />
          {clientAccountSize === 6 && (
            <>
              <SelectPaymentTypeAndAccount
                size={clientAccountSize}
                form={form}
                payMethodName='client_payment_method'
                accountName='client_payment_acc_id'
              />
            </>
          )}

          {/* VENDOR REFUND INFORMATION */}
          <FormInput
            name={'total_vendor_refund'}
            label='Total Vendor Refund'
            size={vendorAccountSize}
          />
          <SelectOptionsInput
            size={vendorAccountSize}
            options={['Adjust', 'Return']}
            label='Vendor Refund Type'
            name={'vendor_refund_type'}
            onSelect={(value) =>
              setVendorAccountSize(value === 'Adjust' ? 12 : 6)
            }
          />
          {vendorAccountSize === 6 && (
            <>
              <SelectPaymentTypeAndAccount
                size={vendorAccountSize}
                form={form}
                payMethodName='vendor_payment_method'
                accountName='vendor_payment_acc_id'
              />
            </>
          )}
        </Row>
        <FormButton label='Submit' />
      </Card>
    </Form>
  );
};

export default ReIssueModalContent;
