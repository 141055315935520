import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormInput,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectOptionsInput,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { IaccountsByMethod } from '../../../Accounts/AccountsTypes/AccountsTypes';
import { useLazyGetAllAccountsByTypeQuery } from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useCreatePartialRefundMutation,
  useLazyGetPartialRefundInfoQuery,
  useLazyGetRefundableInvoiceQuery,
  useLazyGetRefundableTicketsQuery,
} from '../../Api/Endpoints/PartialRefundEndpoints';
import {
  IPartialRefundFormItem,
  IPertialRefundSubmitBody,
  IPertialRefundTickets,
} from '../../RefundTypes/RefundTypes';

type Props = {};

const AddPartialRefunds = (props: Props) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const isVendorChanged = useRef(false);
  const [form] = Form.useForm();
  const [clientId, setClientId] = useState<string>();
  const [vendorId, setVendorId] = useState<string>();
  const [invoice, setInvoice] = useState();
  const [ticketId, setTicketId] = useState<number | null>();
  const [clientPayType, setClientPayType] = useState<string>('ADJUST');
  const [vendorPayType, setVendorPayType] = useState<string>('ADJUST');
  const [paymentMethod, setPaymentMethod] = useState<string>();

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  const [fetchClientDetails, { data: clientDate }] =
    useLazyGetRefundableInvoiceQuery();

  const [fetchTicketsByInvoice, { data: airtickes }] =
    useLazyGetRefundableTicketsQuery();

  const [getAllPartialRefund, { data: airticketInfo }] =
    useLazyGetPartialRefundInfoQuery();

  const [createPartialRefund, { isError, isLoading, isSuccess }] =
    useCreatePartialRefundMutation();

  useEffect(() => {
    if (clientId) {
      fetchClientDetails(clientId as string);
    }
  }, [clientId]);

  useEffect(() => {
    if (invoice) {
      fetchTicketsByInvoice(invoice);
    }

    form.setFieldsValue({ airticket_id: undefined });
  }, [invoice]);

  useEffect(() => {
    form.setFieldValue('refund_date', dayjs());
    form.setFieldValue('vendor_payment_type', 'ADJUST');
    form.setFieldValue('client_payment_type', 'ADJUST');
  }, []);

  const handleSubmit = () => {
    getAllPartialRefund({ airticket_id: ticketId, invoice_id: invoice });
  };

  useEffect(() => {
    if (ticketId === null) {
      form.setFieldValue('ticket_no', undefined);
      form.setFieldValue('refund_vendor_id', undefined);
      form.setFieldValue('vendor_total_amount', undefined);
      form.setFieldValue('client_total_amount', undefined);
    }
  }, []);

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (airticketInfo?.data) {
      const {
        airticket_id,
        airticket_ticket_no,
        invoice_no,
        client_name,
        client_price,
        vendor_price,
        vendor_name,
      } = airticketInfo?.data;
      let vendors;
      if (airticketInfo?.data?.vendor_id) {
        vendors = `vendor-${airticketInfo?.data?.vendor_id}`;
      } else if (airticketInfo?.data?.invoice_combined_id) {
        vendors = `combined-${airticketInfo?.data?.invoice_combined_id}`;
      }

      setVendorId(vendors);

      form.setFieldValue('ticket_no', airticket_ticket_no);
      form.setFieldValue('refund_vendor_id', vendor_name);
      form.setFieldValue('vendor_total_amount', Fixed2(vendor_price));
      form.setFieldValue('client_total_amount', Fixed2(client_price));
    }
  }, [airticketInfo]);

  const handleFinish = async (value: IPertialRefundSubmitBody) => {
    const {
      airticket_id,
      client_charge_amount,
      client_id,
      client_account_id,
      client_note,
      client_payment_type,
      client_return_amount,
      client_total_amount,
      refund_date,
      refund_vendor_id,
      vendor_account_id,
      ticket_no,
      prfnd_payment_method,
      vendor_charge_amount,
      vendor_payment_type,
      vprfnd_payment_method,
      vendor_return_amount,
      vendor_total_amount,
    } = value;

    const body: IPartialRefundFormItem = {
      invoice_id: Number(invoice),
      comb_client: clientId as string,
      created_by: user?.user_id as number,
      prfnd_account_id: client_account_id,
      prfnd_charge_amount: client_charge_amount as string,
      prfnd_return_amount: Fixed2(client_return_amount),
      prfnd_total_amount: client_total_amount,
      prfnd_profit_amount:
        Number(client_charge_amount || 0) - Number(vendor_charge_amount || 0),
      date: dayjs(refund_date).format('YYYY-MM-DD'),
      note: client_note,
      prfnd_payment_type:
        client_payment_type === 'ADJUST' ? 'ADJUST' : 'MONEY_RETURN',
      prfnd_payment_method: prfnd_payment_method,
      vendor_refund_info: [
        {
          vprfnd_airticket_id: airticketInfo?.data?.airticket_id as number,
          vprfnd_account_id: vendor_account_id,
          vprfnd_payment_type:
            vendor_payment_type === 'ADJUST' ? 'ADJUST' : 'MONEY_RETURN',
          vprfnd_charge_amount: vendor_charge_amount,
          vprfnd_return_amount: vendor_return_amount,
          vprfnd_total_amount:
            Number(vendor_return_amount || 0) +
            Number(vendor_charge_amount || 0),
          vprfnd_payment_method: vprfnd_payment_method,
          comb_vendor: vendorId as string,
        },
      ],
    };

    await createPartialRefund(body).then((res: any) => {
      if (res?.data?.refund_id) {
        navigate(`/refund/partial/view/${res?.data?.refund_id}`),
          { state: location.pathname };
      } else {
        message.error(res?.data);
      }
    });
  };

  const client_id = useWatch('client_id', form);

  useEffect(() => {
    if (client_id) {
      form.resetFields(['select_invoice_id', 'airticket_id']);
    }
  }, [client_id]);

  //

  // VENDOR CHARGE AMOUNT CALCULATION ......................
  const vendor_total_amount = useWatch('vendor_total_amount', form);
  const vendor_return_amount = useWatch('vendor_return_amount', form);

  const total_vendor_charge_amount =
    Fixed2(vendor_total_amount) - Fixed2(vendor_return_amount);

  useEffect(() => {
    if (vendor_return_amount > 0) {
      form.setFieldsValue({ vendor_charge_amount: total_vendor_charge_amount });
    } else {
      form.setFieldsValue({ vendor_charge_amount: undefined });
    }
  }, [total_vendor_charge_amount]);

  // CLIENT CHARGE AMOUNT CALCULATION ......................
  const client_total_amount = useWatch('client_total_amount', form);
  const client_return_amount = useWatch('client_return_amount', form);

  const total_client_charge_amount =
    Fixed2(client_total_amount) - Fixed2(client_return_amount);

  useEffect(() => {
    if (client_return_amount > 0) {
      form.setFieldsValue({ client_charge_amount: total_client_charge_amount });
    } else {
      form.setFieldsValue({ client_charge_amount: undefined });
    }
  }, [total_client_charge_amount]);

  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'Create Partial Refund']} />
      <Link to='/refund/history/partial'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to List
        </Button>
      </Link>

      <Form onFinish={handleFinish} layout='vertical' form={form}>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          className='border p-20'
          align={'middle'}
        >
          <SelectClients
            name='client_id'
            label='Select Client'
            required
            size={6}
            onChange={(e) => {
              setClientId(e as string);
            }}
            offDropDown
          />

          <Col md={6} xs={24}>
            <Form.Item
              label='Select Invoice'
              name={'select_invoice_id'}
              required={true}
            >
              <Select
                showSearch
                placeholder='Select Invoice'
                allowClear
                onSelect={(e) => setInvoice(e)}
              >
                {clientDate?.data?.map((item: IPertialRefundTickets) => {
                  return (
                    <Select.Option value={item.invoice_id} key={item.invoice_id + "" + item.invoice_no}>
                      {item.invoice_no}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={5} xs={24}>
            <Form.Item
              label='Select Ticket'
              name={'airticket_id'}
              required={true}
            >
              <Select
                dropdownMatchSelectWidth={150}
                showSearch
                placeholder='Select Tickets'
                allowClear
                onSelect={(e) => setTicketId(e)}
                onClear={() => setTicketId(null)}
              >
                {airtickes?.data?.map((item: IPertialRefundTickets, index) => {
                  return (
                    <Select.Option
                      value={item?.airticket_id}
                      key={String(index)}
                    >
                      {item.airticket_no}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <DateInput name={'refund_date'} size={5} label='Date' />

          <Col lg={2}>
            <Button
              onClick={handleSubmit}
              type='primary'
              disabled={ticketId ? false : true}
            >
              Search
            </Button>
          </Col>
        </Row>

        <div className='border p-5 my-20'>
          <FormHeaderTitle title='Vendor Info' />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <FormInput
              size={6}
              label='Ticket No.'
              name={'ticket_no'}
              required={airticketInfo?.data ? true : false}
            />
            <FormInput
              size={6}
              placeholder='Total Amout'
              label='Total Amount'
              name={'vendor_total_amount'}
              readonly={true}
              required={airticketInfo?.data ? true : false}
            />
            <FormInput
              size={6}
              placeholder='Return Amout'
              label='Return Amount'
              name={'vendor_return_amount'}
              required={airticketInfo?.data ? true : false}
              rules={[
                {
                  validator(rule, value, callback) {
                    if (Fixed2(vendor_total_amount) < Fixed2(value)) {
                      return Promise.reject(
                        "Return Amount can't be more than total amount"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            />
            <FormInput
              size={6}
              placeholder='Charge Amout'
              label='Charge Amount'
              name={'vendor_charge_amount'}
              required={airticketInfo?.data ? true : false}
            />
            <SelectVendors
              size={6}
              label='Vendor'
              name={'refund_vendor_id'}
              required={airticketInfo?.data ? true : false}
              disabled={true}
            />
            <SelectOptionsInput
              size={6}
              label='Pay Type'
              options={['ADJUST', 'MONEY RETURN']}
              name={'vendor_payment_type'}
              required={airticketInfo?.data ? true : false}
              onSelect={(e) => {
                setClientPayType(e);
              }}
            />

            {clientPayType !== 'ADJUST' && (
              <>
                <SelectPaymentMethod
                  name={'vprfnd_payment_method'}
                  label='Payment Method'
                  size={6}
                  required={airticketInfo?.data ? true : false}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isVendorChanged.current)
                      isVendorChanged.current = true;
                  }}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={6}>
                  <Form.Item
                    name={'vendor_account_id'}
                    label='Account'
                    rules={[
                      {
                        required: airticketInfo?.data ? true : false,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod) => {
                          return (
                            <Select.Option
                              key={item.account_id}
                              value={item.account_id}
                            >
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </div>

        <div className='border p-5 my-20'>
          <FormHeaderTitle title='Client Info' />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} align={'middle'}>
            <FormInput
              size={6}
              placeholder='Total Amout'
              label='Total Amount'
              name={'client_total_amount'}
              readonly={true}
              required={airticketInfo?.data ? true : false}
            />
            <FormInput
              size={6}
              placeholder='Return Amout'
              label='Return Amount'
              name={'client_return_amount'}
              required={airticketInfo?.data ? true : false}
            />
            <FormInput
              size={6}
              placeholder='Charge Amout'
              label='Charge Amount'
              name={'client_charge_amount'}
              required={airticketInfo?.data ? true : false}
            />
            <SelectOptionsInput
              size={6}
              label='Pay Type'
              options={['ADJUST', 'MONEY RETURN']}
              name={'client_payment_type'}
              required={airticketInfo?.data ? true : false}
              onSelect={(e) => {
                setVendorPayType(e);
              }}
            />

            {vendorPayType !== 'ADJUST' && (
              <>
                <SelectPaymentMethod
                  name={'prfnd_payment_method'}
                  label='Payment Method'
                  size={6}
                  required={airticketInfo?.data ? true : false}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isVendorChanged.current)
                      isVendorChanged.current = true;
                  }}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={6}>
                  <Form.Item
                    name={'client_account_id'}
                    label='Account'
                    rules={[
                      {
                        required: airticketInfo?.data ? true : false,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod) => {
                          return (
                            <Select.Option
                              key={item.account_id}
                              value={item.account_id}
                            >
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <TextAreaInput label='Note' size={6} name={'client_note'} />

            <Button loading={isLoading} htmlType='submit' type='primary'>
              Submit
            </Button>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default AddPartialRefunds;
