import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IAirlineWiseSalesReportTableData } from '../types/AirlineWiseSalesReportTypes';

const AirlineWiseSalesReportColumn = (
  queryData: {
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  },
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined
): ColumnsType<IAirlineWiseSalesReportTableData> => {
  return [
    {
      title: 'SL',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'airticket_created_date',
      key: 'airticket_created_date',
      render: (_, record) => {
        return `${dayjs(record.invoice_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',

      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Airline Name',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: 'Pax Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',

      render: (_, record) => (
        <Link
          to={`/reports/ticket_wise?${paramData?.date_range}&airticket_id=${record.airticket_id}`}
        >
          {' '}
          {record.airticket_ticket_no}
        </Link>
      ),
    },

    {
      title: 'Pnr',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },

    {
      title: 'Route',
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
    },

    {
      title: 'Client Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      align: 'right',
      render: (_, record) => Number(record.airticket_client_price),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      align: 'right',
      render: (_, record) => Number(record.airticket_purchase_price),
    },
    {
      title: 'Total Profit',
      dataIndex: 'total_profit',
      key: 'total_profit',
      align: 'right',
      render: (value) =>
        Number(value || 0) > 0 ? (
          <span className='text-profit'>{value}</span>
        ) : (
          <span className='text-profit'>{value}</span>
        ),
    },
  ];
};

export default AirlineWiseSalesReportColumn;
