import { Col, Form, FormListFieldData, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
  MobileInput,
} from '../../../components/common/FormItem/FormItems';
import { useLazyCheckExistPassportQuery } from '../Api/Endpoints/PassportEndpoints';
import { IPassport_info } from '../PassportTypes/PassportTypes';
import { SelectOptionsInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import FileUpload from '../../../common/utils/FileUpload';

const PassportInfo = ({
  field,
  form,
  passportInitialValues,
}: {
  field: FormListFieldData;
  form: FormInstance<any>;
  passportInitialValues?: IPassport_info[];
}) => {
  const [passcheck, setPasscheck] = useState<string | number>();

  const [fetchExistPassposrt, { data }] = useLazyCheckExistPassportQuery();

  useEffect(() => {
    if (passcheck)
      fetchExistPassposrt(passcheck).then(() => {
        form.validateFields([['passport_info', field.name, 'passport_no']]);
      });
  }, [passcheck]);

  return (
    <Row style={{ paddingLeft: '10px' }}>
      <Row gutter={24}>
        <FormInputItem
          label='Passport No.'
          name={[field.name, 'passport_no']}
          size={5}
          required
          help='Already exist.'
          setPasscheck={setPasscheck}
          rules={[
            { max: 15, message: 'Invalid passport no.' },
            { min: 8, message: 'Invalid passport no.' },
            {
              validator() {
                const isExist = data?.data;
                if (isExist === true) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Passport no. is exists');
                }
              },
            },
          ]}
        />

        <SelectOptionsInput
          label='Select Pax Type:'
          name={[field.name, 'passport_person_type']}
          placeholder='Select Pax Type'
          options={['Adult', 'Child', 'Infant']}
          size={5}
        />
        <FormInputItem
          label='Name'
          name={[field.name, 'name']}
          size={5}
          required
        />

        <MobileInput
          label='Mobile'
          name={[field.name, 'mobile_no']}
          rules={[
            { required: true, message: 'Mobile is required' },

            {
              message: 'Only digits allowed',
              validator: (_, value) => {
                if (/^[0-9-+]*$/.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Non-numeric values are not allowed');
                }
              },
            },
          ]}
          size={5}
        />
        <FormInputItem
          label='Email'
          name={[field.name, 'email']}
          size={5}
          inputType='email'
        />

        <FormInputItem
          label='Nid No.'
          name={[field.name, 'nid']}
          rules={[
            { max: 17, message: 'Invalid nid no.' },
            { min: 10, message: 'Invalid nid no.' },
          ]}
          size={5}
        />

        <DateInput
          label='Date of Birth'
          name={[field.name, 'date_of_birth']}
          size={5}
        />
        <DateInput
          label='Date of Issue'
          name={[field.name, 'date_of_issue']}
          size={5}
        />
        <DateInput
          label='Date of Expire'
          name={[field.name, 'date_of_expire']}
          size={5}
        />
      </Row>

      <Row style={{ width: '100%' }} justify={'space-between'}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Form.Item
            label='Upload Passport (Max 1 MB)'
            name={[field.name, 'scan_copy']}
            valuePropName='scan_copy'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='scan_copy'
              msg='Passport image'
              noBorder
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Form.Item
            label='Upload Photo (Max 1 MB)'
            name={[field.name, 'upload_photo']}
            valuePropName='upload_photo'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='upload_photo'
              msg='Profile image'
              noBorder
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Form.Item
            label='Upload Others (Max 1 MB)'
            name={[field.name, 'upload_others']}
            valuePropName='upload_others'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='upload_others'
              msg='Other image'
              noBorder
            />
          </Form.Item>
        </Col>
      </Row>
    </Row>
  );
};

export default PassportInfo;
