import { Input, Modal } from 'antd';
import { useState } from 'react';
import { useLazyGetSearchResultQuery } from '../../modules/Dashboard/Api/Endpoints/DashboardEndpoints';
import SearchInvoices from '../components/SearchInvoices';
import '../style/layoutStyle.css';

type Props = {};

const GlobalSearch = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // search data
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [fetchResult, { data: results, isLoading, isFetching }] =
    useLazyGetSearchResultQuery();

  const searchResult = results?.data;

  const handleSearch = async (e: any) => {
    e && showModal();
    const body = {
      name: searchQuery?.trim(),
    };

    if (searchQuery?.trim()) fetchResult(body);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Input.Search
        className='search_feild'
        placeholder='Write Text And Press Enter..'
        onChange={(e) => setSearchQuery(e.target.value)}
        onPressEnter={handleSearch}
        // allowClear
        loading={isLoading || isFetching}
        onSearch={handleSearch}
      />
      <Modal
        title={`Search results for: ${searchQuery || 'N/A'}`}
        open={isModalOpen}
        // open={true}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        <SearchInvoices
          isLoading={isLoading || isFetching}
          searchResult={searchResult}
          handleOk={handleOk}
        />
      </Modal>
    </>
  );
};

export default GlobalSearch;
